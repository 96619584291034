import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import CardSection from 'components/common/CardSection';
import EditModal from './EditModal';
import NoteDetailBody from '../ActivitiesNotesTab/NoteDetailBody';

const TaskDetail = () => {
  const editModalRef = useRef();

  const task = useSelector((state) => state.tasks.currentData);

  const openEditModal = () => {
    editModalRef.current && editModalRef.current.open(task);
  };

  return (
    <CardSection
      title="tasks.taskDetails"
      isEdit={!!task?.id}
      onClickButton={openEditModal}
    >
      <NoteDetailBody record={task} isTask />
      <EditModal ref={editModalRef} />
    </CardSection>
  );
};

export default TaskDetail;
