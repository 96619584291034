import {
  getCentresOfCompanyApi,
  getSummariesCentresOfCompanyApi,
} from 'api/companies';
import {
  getAllCustom,
  getSummariesCustom,
} from 'redux/crudCreatorCustom/actions';

export const MODEL_NAME = 'centresOfCompany';

export const getCentresOfCompany = getAllCustom(
  MODEL_NAME,
  getCentresOfCompanyApi,
  'companyId',
);

export const getSummariesCentresOfCompany = getSummariesCustom(
  MODEL_NAME,
  getSummariesCentresOfCompanyApi,
  'companyId',
);
