import React from 'react';
import PropTypes from 'prop-types';
import { getIdByUrl } from 'utils/tools';

import Create from 'containers/rest/Create';
import Form from '../components/Form';

const CentreServicesCreate = (props) => {
  const centreId = getIdByUrl({ resource: 'centres' }, props.location);

  const formatOnSubmit = (data) => ({
    centreId,
    ...data,
  });

  return (
    <Create
      {...props}
      resource="centreServices"
      formatOnSubmit={formatOnSubmit}
    >
      <Form />
    </Create>
  );
};

CentreServicesCreate.propTypes = {
  location: PropTypes.object,
};

export default CentreServicesCreate;
