import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { getATagHref } from 'utils/tools';
import {
  ABNIcon,
  ACNIcon,
  MousePointerPrimaryIcon,
  SalesStageIcon,
} from 'components/common/SVGIcon';
import { Tooltip } from 'antd';
import SalesStageDropdown from '../SalesStageDropdown';

const GeneralInfo = ({ record }) => (
  <div className="company-info">
    <div className="title-info mb-20">{i18next.t('companies.generalInfo')}</div>
    <div className="content-info-other">
      <div className="item-info">
        <SalesStageIcon className="flex-center" />
        <SalesStageDropdown
          saleStage={
            record?.saleStage?.name ||
            `${i18next.t('input.salesStage.placeholder')}`
          }
          id={record?.id}
        />
      </div>
      <div className="item-info">
        <ACNIcon className="flex-center" />
        <Tooltip title={i18next.t('providers.acnNumber')}>
          <span>
            {record?.australianCompanyNumber ||
              i18next.t('error.waitingUpdate')}
          </span>
        </Tooltip>
      </div>
      <div className="item-info">
        <ABNIcon className="flex-center" />
        <Tooltip title={i18next.t('providers.abnNumber')}>
          <span>
            {record?.australianBusinessNumber ||
              i18next.t('error.waitingUpdate')}
          </span>
        </Tooltip>
      </div>
      <div className="item-info value-info-item">
        <MousePointerPrimaryIcon className="flex-center" />
        <a
          href={getATagHref(record.website)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {record?.website || i18next.t('error.waitingUpdate')}
        </a>
      </div>
    </div>
  </div>
);

GeneralInfo.propTypes = {
  record: PropTypes.object,
};

export default GeneralInfo;
