import { Avatar, Progress, Tooltip } from 'antd';
import theme from 'configs/theme';
import React from 'react';
import PropTypes from 'prop-types';
import { ProgressWrapperStyles } from './styles';

const StatisticItem = ({ url, count, percent, searchName }) => (
  <ProgressWrapperStyles>
    <div className="top-search-item">
      {url && (
        <div>
          <Avatar src={url} size={48} className="search-img" />
        </div>
      )}
      <div className="top-search-title">{searchName}</div>
      <div className="progress">
        <Tooltip title={`${percent}%`} placement="topRight">
          <Progress
            percent={percent}
            strokeColor={theme.palette.primary}
            format={() => count}
          />
        </Tooltip>
      </div>
    </div>
  </ProgressWrapperStyles>
);

StatisticItem.propTypes = {
  url: PropTypes.string,
  percent: PropTypes.any,
  searchName: PropTypes.string,
  count: PropTypes.number,
};

export default StatisticItem;
