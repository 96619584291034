import React from 'react';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import i18next from 'i18next';

import EmptyData from 'components/common/EmptyData';
import AutomatedDetailStyles from './styles';
import AutomatedInfo from './AutomatedInfo';
import AutomatedPreview from './AutomatedPreview';
import { AutomatedMessageProvider } from './context';

const AutomatedDetail = () => {
  const currentCentre = useSelector((state) => state.centres.currentData) || {};
  const selectedId = useSelector(
    (state) => state.automatedResponses.currentData?.id,
  );

  return (
    <AutomatedDetailStyles className="automated-detail">
      {selectedId ? (
        <AutomatedMessageProvider>
          <Row>
            <Col md={14} xs={24}>
              <AutomatedInfo centreName={currentCentre?.name} />
            </Col>

            <Col md={10} xs={24}>
              <AutomatedPreview currentCentre={currentCentre} />
            </Col>
          </Row>
        </AutomatedMessageProvider>
      ) : (
        <EmptyData description={i18next.t('automatedResponses.noSelectItem')} />
      )}
    </AutomatedDetailStyles>
  );
};

export default AutomatedDetail;
