import React from 'react';
import RestInputItem from 'components/RestInput/RestInputItem';
import { InputNumber } from 'antd';
import RestSelect from 'components/RestInput/RestSelect';
import { useSelector } from 'react-redux';

const SuburbsForm = () => {
  const stateOfAustralia = useSelector(
    (state) => state.config.data?.stateOfAustralia,
  );

  return (
    <div>
      <RestInputItem
        required
        source="city"
        header="suburbs.title"
        placeholder="suburbs.title"
      />

      <RestSelect
        required
        source="state"
        header="input.state.name"
        placeholder="input.state.placeholder"
        resourceData={stateOfAustralia || []}
      />
      <RestInputItem
        required
        source="postCode"
        header="suburbs.postCode"
        placeholder="suburbs.postCode"
      />
      <RestInputItem
        required
        source="country"
        header="suburbs.country"
        placeholder="suburbs.country"
      />
      <RestInputItem
        ContentComponent={InputNumber}
        required
        source={['geolocation', 'latitude']}
        header="suburbs.latitude"
        placeholder="suburbs.latitude"
      />
      <RestInputItem
        ContentComponent={InputNumber}
        required
        source={['geolocation', 'longitude']}
        header="suburbs.longitude"
        placeholder="suburbs.longitude"
      />
    </div>
  );
};

export default SuburbsForm;
