import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Spin, List } from 'antd';
import { Waypoint } from 'react-waypoint';
import { useHistory, useParams } from 'react-router';

import {
  enabledLoadMoreInboxesSelector,
  getInboxesOfCentreLoadingSelector,
  getInboxesOfCentreSelector,
} from 'redux/inboxes/selectors';
import { clearInboxesListOfCentre } from 'redux/inboxes/actions';

import InboxListWrapper, { ListStyles } from './styles';
import Header from './Header';
import InboxItem from './InboxItem';

const InboxesList = ({ inboxId, retrieveList, getListAndSummary }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { modelDetail } = useParams();

  const [isDefaultInbox, setIsDefaultInbox] = useState(false);

  const loading = useSelector(getInboxesOfCentreLoadingSelector);

  const resourceData = useSelector(getInboxesOfCentreSelector) || [];

  const enabledLoadMore = useSelector(enabledLoadMoreInboxesSelector);

  const checkIsActiveInbox = useCallback((id) => inboxId === id, [inboxId]);

  useEffect(() => {
    if (
      modelDetail === 'enquiries' &&
      !isDefaultInbox &&
      !inboxId &&
      resourceData[0]?.id
    ) {
      history && history.replace(`?inboxId=${resourceData[0]?.id}`);
      setIsDefaultInbox(true);
    }
  }, [resourceData, inboxId, modelDetail]); // eslint-disable-line

  useEffect(() => {
    setIsDefaultInbox(false);
  }, [modelDetail]); // eslint-disable-line

  useEffect(
    () => () => {
      dispatch(clearInboxesListOfCentre());
    },
    [dispatch],
  );

  const handleEnterWaypoint = useCallback(() => {
    retrieveList({}, false);
  }, [retrieveList]);

  const handleClickInbox = useCallback(
    (id) => {
      history.push(`${history.location?.pathname}?inboxId=${id}`);
    },
    [history],
  );

  return (
    <InboxListWrapper>
      <Header getListAndSummary={getListAndSummary} />
      <ListStyles>
        <div className="inbox-list-data">
          <List
            itemLayout="horizontal"
            dataSource={resourceData}
            renderItem={(record) => (
              <InboxItem
                record={record}
                handleClickInbox={handleClickInbox}
                checkIsActiveInbox={checkIsActiveInbox}
              />
            )}
          />
          {loading && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Spin />
            </div>
          )}
          {enabledLoadMore && <Waypoint onEnter={handleEnterWaypoint} />}
        </div>
      </ListStyles>
    </InboxListWrapper>
  );
};

InboxesList.propTypes = {
  retrieveList: PropTypes.func,
  inboxId: PropTypes.string,
  getListAndSummary: PropTypes.func,
};

export default InboxesList;
