import React, { forwardRef, useState, useImperativeHandle } from 'react';
import { Form } from 'antd';
import ModalCustom from 'components/common/ModalCustom';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { createCategories } from 'redux/categories/actions';
import FormCategories from './FormCategories';

const CreateCategoryModal = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [visible, setVisible] = useState();

  const [loading, setLoading] = useState();

  const [parentCategory, setParentCategory] = useState();

  useImperativeHandle(
    ref,
    () => ({
      open: (data) => {
        setVisible(true);
        setParentCategory(data);
        form.setFieldsValue({ parentId: data?.id });
      },
    }),
    [form],
  );

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const onOk = () => {
    form.validateFields().then((values) => {
      setLoading(true);
      dispatch(createCategories({ data: values, parent: parentCategory }))
        .then(({ payload }) => {
          if (payload?.id) {
            onCancel();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <ModalCustom
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      title={i18next.t(
        parentCategory?.id
          ? 'categories.createSubCategoryTitle'
          : 'categories.createCategoryTitle',
      )}
      loading={loading}
    >
      <Form form={form}>
        <FormCategories parentCategory={parentCategory} />
      </Form>
    </ModalCustom>
  );
});

export default CreateCategoryModal;
