import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import EditButton from 'components/RestActions/EditButton';
import DeleteButton from 'components/RestActions/DeleteButton';
import { useDispatch } from 'react-redux';
import { delBrands } from 'redux/brands/actions';
import GroupActions from 'components/common/GroupActions';
import { PREFIX_URL } from 'configs/localData/constant';
import { getBrandsOfCompany } from 'redux/brandsOfCompany/actions';

const GroupActionsBrand = ({ id, record }) => {
  const { push } = useHistory();
  const dispatch = useDispatch();

  const successActionAfterDel = () => {
    record?.companyId &&
      dispatch(
        getBrandsOfCompany({
          id: record.companyId,
          options: {
            isRefresh: true,
            keepFilter: true,
          },
        }),
      );
  };

  const handleDeleteBrand = () => {
    dispatch(
      delBrands({
        data: { id },
        options: {
          prefixUrl: PREFIX_URL.superAdmin,
          successAction: successActionAfterDel,
        },
      }),
    );
  };

  const redirectCreateBrand = () => {
    push({ hash: `#brands/${id}/editOfCompany` });
  };

  return (
    <GroupActions>
      <EditButton onClickCustom={redirectCreateBrand} />
      <DeleteButton
        deleteItem={handleDeleteBrand}
        resource="brands"
        record={record}
      />
    </GroupActions>
  );
};

GroupActionsBrand.propTypes = {
  id: PropTypes.string,
  record: PropTypes.object,
};

export default GroupActionsBrand;
