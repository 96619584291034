import React from 'react';
import { isEmpty } from 'lodash-es';
import { formatLabelTable } from 'utils/textUtils';
import Edit from 'containers/rest/Edit';
import { LABEL_TABLE_KEY } from 'configs/localData/constant';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import Form from '../components/Form';

const ServiceTypesEdit = (props) => {
  const formatOnSubmit = ({ icon, ...values }) => ({
    ...values,
    ...(!isEmpty(icon) ? { icon: { key: icon.key, url: icon.url } } : null),
  });

  const appSettings = useSelector((state) => state.config?.data?.AppSetting);

  return (
    <Edit
      {...props}
      resource="serviceTypes"
      formatOnSubmit={formatOnSubmit}
      defaultOptions={{ isTrimStr: true, isGetConfig: true }}
      header={`${i18next.t('button.edit')} ${formatLabelTable(
        LABEL_TABLE_KEY.serviceType,
        appSettings,
      )}`}
    >
      <Form isEdit />
    </Edit>
  );
};

export default ServiceTypesEdit;
