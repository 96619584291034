import i18next from 'i18next';
import React from 'react';
import ProviderListSelect from './ProviderListSelect';

const ProviderForm = () => (
  <div>
    <div className="title-session">{i18next.t('users.selectProviders')}</div>
    <ProviderListSelect />
  </div>
);

export default ProviderForm;
