import { createSlice } from '@reduxjs/toolkit';
import {
  editCustomSuccess,
  getAllCustomFail,
  getAllCustomPending,
  getAllCustomSuccess,
  INITIAL_STATE_CUSTOM,
  resetResourceDataCustom,
} from 'redux/crudCreatorCustom/utils';
import { getBrandsOfCompany } from './actions';

export const initialState = {
  ...INITIAL_STATE_CUSTOM,
};

const { actions, reducer } = createSlice({
  name: 'BrandsOfCompany',
  initialState,
  reducers: {
    resetResourceFilterCentresOfProviders: resetResourceDataCustom,
    editBrandOfCompanySuccess: editCustomSuccess,
  },
  extraReducers: {
    [getBrandsOfCompany.pending]: getAllCustomPending,
    [getBrandsOfCompany.fulfilled]: getAllCustomSuccess,
    [getBrandsOfCompany.rejected]: getAllCustomFail,
  },
});

export const {
  resetResourceFilterCentresOfProviders,
  editBrandOfCompanySuccess,
} = actions;

export default reducer;
