import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';

import SummaryCard from 'components/common/SummaryCardCustom';
import {
  TotalPlaceIcon,
  TotalCentreIcon,
  TotalEarningIcon,
  TotalWaitlistValueIcon,
} from 'components/common/SVGIcon';

const SummariesCentre = ({ summaries = {} }) => {
  const data = [
    {
      title: 'summaries.totalCentres',
      count: summaries.totalCentres,
      IconComponent: TotalCentreIcon,
      isShowIconInfo: true,
    },
    {
      title: 'summaries.totalPlaces',
      count: summaries.totalPlaces,
      IconComponent: TotalPlaceIcon,
      isShowIconInfo: true,
    },
    {
      title: 'summaries.estEarningPotential',
      count: summaries.estimatedEarningPotentialValue,
      IconComponent: TotalEarningIcon,
      isMoney: true,
    },
    {
      title: 'summaries.waitlistValuePa',
      count: summaries.waitlistedValue,
      IconComponent: TotalWaitlistValueIcon,
      isMoney: true,
    },
  ];
  return (
    <Row gutter={[25, 25]} style={{ marginBottom: 30 }}>
      {data.map((item, index) => (
        <Col xl={6} lg={12} xs={24} key={String(index)}>
          <SummaryCard {...item} />
        </Col>
      ))}
    </Row>
  );
};

SummariesCentre.propTypes = {
  summaries: PropTypes.object,
};

export default SummariesCentre;
