import styled from 'styled-components';

const ShowStyles = styled.div`
  .box-detail-show {
    padding: 0;
    background: none;
  }
`;

export default ShowStyles;
