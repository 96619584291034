import React from 'react';

import { PropTypes } from 'prop-types';
import i18next from 'i18next';
import { Form, InputNumber } from 'antd';

import { getDiscountNumberRules } from 'utils/validateUtils';
import CompaniesModal from '.';

const EducatorAndStaffDiscountsModal = ({
  onCancel,
  visible,
  educatorDiscount,
  staffDiscount,
}) => (
  <CompaniesModal
    onCancel={onCancel}
    visible={visible}
    title={i18next.t('companies.editEducatorAndStaffDiscounts')}
  >
    <Form.Item
      style={{ fontWeight: 700 }}
      label={`${i18next.t('companies.educatorDiscount')} (%)`}
      shouldUpdate
      name="educatorDiscount"
      rules={getDiscountNumberRules(
        'input.staffAndEducatorDiscount.validateMsg.discountNumberMustBeInteger',
        'input.staffAndEducatorDiscount.validateMsg.invalidNumberDiscount',
        'companies.educatorDiscount',
      )}
      initialValue={Number(educatorDiscount)}
    >
      <InputNumber
        placeholder={`${i18next.t('companies.educatorDiscount')} (%)`}
      />
    </Form.Item>
    <Form.Item
      style={{ fontWeight: 700 }}
      shouldUpdate
      label={`${i18next.t('companies.staffDiscount')} (%)`}
      name="staffDiscount"
      rules={getDiscountNumberRules(
        'input.staffAndEducatorDiscount.validateMsg.discountNumberMustBeInteger',
        'input.staffAndEducatorDiscount.validateMsg.invalidNumberDiscount',
        'companies.staffDiscount',
      )}
      initialValue={Number(staffDiscount)}
    >
      <InputNumber
        placeholder={`${i18next.t('companies.staffDiscount')} (%)`}
      />
    </Form.Item>
  </CompaniesModal>
);
EducatorAndStaffDiscountsModal.propTypes = {
  onCancel: PropTypes.func,
  visible: PropTypes.bool,
  educatorDiscount: PropTypes.string,
  staffDiscount: PropTypes.string,
};
export default EducatorAndStaffDiscountsModal;
