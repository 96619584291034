import React from 'react';
import PublicLayout from '../../layout/PublicLayout';
import ResetPasswordContainer from '../../containers/ResetPassword';

const ResetPassword = () => (
  <PublicLayout>
    <ResetPasswordContainer />
  </PublicLayout>
);

export default ResetPassword;
