import React from 'react';
import PropTypes from 'prop-types';
import { Row, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';

const InfiniteScrollData = ({
  children,
  handleViewMore,
  loading,
  enableLoadMore,
}) => (
  <InfiniteScroll
    pageStart={0}
    loadMore={handleViewMore}
    hasMore={loading || enableLoadMore}
    useWindow={false}
    loader={
      <Row justify="center">
        <Spin spinning={loading} />
      </Row>
    }
  >
    {children}
  </InfiniteScroll>
);

InfiniteScrollData.propTypes = {
  children: PropTypes.node,
  handleViewMore: PropTypes.func,
  loading: PropTypes.bool,
  enableLoadMore: PropTypes.bool,
};

export default InfiniteScrollData;
