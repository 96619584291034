import React, { useState, useEffect } from 'react';
import { Form, AutoComplete } from 'antd';
import PropTypes from 'prop-types';
import { isEmpty, debounce } from 'lodash-es';
import I18n from 'i18next';
import usePlacesAutocomplete, {
  getLatLng,
  getGeocode,
} from 'use-places-autocomplete';
import { splitAddressFromGoogleMap } from 'utils/dataUtils';
import { PLACE_DEFAULT_OPTIONS } from 'configs/localData/constant';
import FormGoogleAutoCompleteStyles from './styles';

const FormGoogleAutocomplete = ({
  header,
  required,
  messageRequire,
  placeholder,
  wrapperCol,
  defaultValue,
  form,
  record,
  source,
  rules,
  onSelectAddress,
}) => {
  const [coordinates, setCoordinates] = useState({});
  useEffect(() => {
    if (!isEmpty(record) && isEmpty(coordinates)) {
      setCoordinates({
        lat: record?.geolocation?.latitude,
        lng: record?.geolocation?.longitude,
      });
      form.setFieldsValue({
        coordinates: {
          latitude: record?.geolocation?.latitude,
          longitude: record?.geolocation?.longitude,
        },
      });
    }
    // eslint-disable-next-line
  }, [record]);

  const {
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: PLACE_DEFAULT_OPTIONS,
  });

  const handleSearch = (e) => {
    setValue(e);
  };

  const handleSearchDebounce = debounce(handleSearch, 600);

  const handleSelect = async (e) => {
    try {
      const results = await getGeocode({ address: e });
      const coordinates = await getLatLng(results[0]);

      setCoordinates(coordinates);

      form.setFieldsValue({
        geolocation: {
          latitude: coordinates.lat,
          longitude: coordinates.lng,
        },
      });

      onSelectAddress({
        coordinates,
        address: results?.[0]?.formatted_address || e,
        addressObj: splitAddressFromGoogleMap(results[0].address_components),
      });
    } catch (error) {}
  };

  const renderSuggestions = data.map((suggestion) => ({
    value: suggestion.description,
  }));

  return (
    <FormGoogleAutoCompleteStyles>
      <Form.Item
        wrapperCol={wrapperCol}
        label={I18n.t(header)}
        name={source}
        initialValue={defaultValue}
        rules={[
          {
            required,
            message: I18n.t(messageRequire),
          },
          ...rules,
        ]}
      >
        <AutoComplete
          options={status === 'OK' ? renderSuggestions : []}
          onSearch={handleSearchDebounce}
          onSelect={handleSelect}
          placeholder={I18n.t(placeholder)}
        />
      </Form.Item>
    </FormGoogleAutoCompleteStyles>
  );
};

FormGoogleAutocomplete.propTypes = {
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  header: PropTypes.string,
  required: PropTypes.bool,
  messageRequire: PropTypes.string,
  placeholder: PropTypes.string,
  wrapperCol: PropTypes.object,
  form: PropTypes.object,
  record: PropTypes.object,
  defaultValue: PropTypes.string,
  rules: PropTypes.array,
  onSelectAddress: PropTypes.func,
};

FormGoogleAutocomplete.defaultProps = {
  required: true,
  messageRequire: 'input.address.validateMsg.required',
  wrapperCol: { span: 24 },
  rules: [],
  onSelectAddress: () => null,
};

export default FormGoogleAutocomplete;
