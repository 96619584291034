import React, { forwardRef, useState, useImperativeHandle } from 'react';
import { Form } from 'antd';
import i18next from 'i18next';
import { editCategories } from 'redux/categories/actions';
import { useDispatch } from 'react-redux';

import ModalCustom from 'components/common/ModalCustom';
import FormCategories from './FormCategories';

const EditCategoryModal = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [visible, setVisible] = useState();

  const [loading, setLoading] = useState();

  const [record, setRecord] = useState();

  useImperativeHandle(
    ref,
    () => ({
      open: (data) => {
        setVisible(true);
        setRecord(data);
        form.setFieldsValue(data);
      },
    }),
    [form],
  );

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const onOk = () => {
    form.validateFields().then((values) => {
      setLoading(true);
      dispatch(editCategories({ id: record?.id, data: values }))
        .then(({ payload }) => {
          if (payload?.id) {
            onCancel();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <ModalCustom
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      title={i18next.t(
        record?.parentId
          ? 'categories.editSubCategoryTitle'
          : 'categories.editCategoryTitle',
      )}
      loading={loading}
    >
      <Form form={form}>
        <FormCategories isEdit parentCategory={record?.parent} />
      </Form>
    </ModalCustom>
  );
});

export default EditCategoryModal;
