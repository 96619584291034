import React from 'react';
import PropTypes from 'prop-types';
import FilterCustom from 'components/common/FilterCustom';
import { useHistory } from 'react-router';
import { convertSearchUrl } from 'utils/tools';
import FilterCenterForm from './FilterCentreForm';

const FilterCentre = ({
  retrieveList,
  idProp = 'providerId',
  brandProps,
  cityProps,
}) => {
  const { push } = useHistory();

  const onSubmitFilter = (values) => {
    retrieveList({
      filter: {
        offset: 0,
        outsideFilter: values,
      },
      isRefresh: true,
      keepOrderBy: true,
    });

    push({
      search: `?${convertSearchUrl(values)}`,
    });
  };

  const onClearFilter = () => {
    retrieveList({
      filter: {
        offset: 0,
      },
      isRefresh: true,
      keepOrderBy: true,
    });
    push({ search: '?' });
  };

  return (
    <FilterCustom
      onSubmitFilter={onSubmitFilter}
      onClearFilter={onClearFilter}
      responsiveFilter={{
        xxl: 20,
        xl: 18,
        lg: 24,
        md: 24,
        xs: 24,
      }}
      responsiveAction={{
        xxl: 4,
        xl: 6,
        lg: 24,
        md: 24,
        xs: 24,
      }}
    >
      <FilterCenterForm
        retrieveList={retrieveList}
        idProp={idProp}
        brandProps={brandProps}
        cityProps={cityProps}
      />
    </FilterCustom>
  );
};

FilterCentre.propTypes = {
  retrieveList: PropTypes.func,
  idProp: PropTypes.string,
  brandProps: PropTypes.object,
  cityProps: PropTypes.object,
};

export default FilterCentre;
