import ModalCustom from 'components/common/ModalCustom';
import i18next from 'i18next';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import Form, { useForm } from 'antd/lib/form/Form';
import { useDispatch, useSelector } from 'react-redux';
import { createProviders } from 'redux/providers/actions';
import { PREFIX_URL_API } from 'configs/localData/constant';
import { getProvidersOfCompany } from 'redux/providersOfCompany/actions';
import { handleApiError } from 'utils/validateUtils';
import CreateProviderForm from './CreateProviderForm';

const CreateProviderModal = ({ visible, onCancel, getSummaries }) => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const currentData = useSelector((state) => state.companies.currentData) || {};

  useEffect(() => {
    form.setFieldsValue({
      companyId: currentData?.id,
    });
  }, [currentData, visible]); // eslint-disable-line

  const onOk = () => {
    form.validateFields().then((values) =>
      dispatch(
        createProviders({
          data: {
            companyId: currentData?.id,
            ...values,
          },
          options: {
            prefixUrl: PREFIX_URL_API,
          },
        }),
      )
        .then(({ payload: { data } }) => {
          handleApiError(data, form);
          if (data?.id) {
            handleCancel();
            dispatch(
              getProvidersOfCompany({
                id: currentData?.id,
                data: {},
                options: {
                  keepFilter: true,
                  isRefresh: true,
                  isOrderBy: false,
                },
              }),
            );
            getSummaries({ filter: {} });
          }
        })
        .catch(() => {}),
    );
  };

  const handleCancel = () => {
    onCancel();
    form.resetFields();
  };

  return (
    <ModalCustom
      visible={visible}
      onOk={onOk}
      title={`${i18next.t('providers.centresProviderModalTitle')}`}
      onCancel={handleCancel}
    >
      <RestInputContext.Provider value={{ form }}>
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          autoComplete="off"
          labelAlign="left"
          layout="vertical"
        >
          <CreateProviderForm />
        </Form>
      </RestInputContext.Provider>
    </ModalCustom>
  );
};

CreateProviderModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  getSummaries: PropTypes.func,
};

export default CreateProviderModal;
