import styled from 'styled-components';

const AssigneePopoverStyles = styled.div`
  width: 320px;
  .none-link-class {
    cursor: pointer !important;
  }
  .assignee-list-wrapper {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .assignee-item {
    padding: 6px 0px;
  }

  input,
  .ant-input-affix-wrapper {
    background: ${({ theme }) => theme.background.input};
    border: 1px solid ${({ theme }) => theme.background.input};
  }

  .ant-input-prefix .anticon {
    color: ${({ theme }) => theme.text.mainL5} !important;
  }

  .assignee-popover-footer {
    padding-top: 12px;
  }
`;

export default AssigneePopoverStyles;
