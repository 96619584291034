import styled from 'styled-components';

const BreadcrumbStyles = styled.div`
  .text-breadcrumb {
    font-size: 16px;
    font-weight: 500;
  }
  .ant-breadcrumb > span:last-child,
  .ant-breadcrumb > span:last-child a {
    color: ${({ theme }) => theme.palette.primary} !important;
  }
  a {
    text-decoration: unset !important;
  }
`;

export default BreadcrumbStyles;
