import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, InputNumber, Switch } from 'antd';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import { getSessionTypes } from 'redux/config/selectors';

import RestSelect from 'components/RestInput/RestSelect';
import RestInputItem from 'components/RestInput/RestInputItem';
import { formatLabelTable } from 'utils/textUtils';
import { LABEL_TABLE_KEY, PREFIX_URL } from 'configs/localData/constant';
import ReferenceInput from 'containers/rest/ReferenceInput';

const ServiceForm = ({ isEdit }) => {
  const SessionTypes = useSelector(getSessionTypes);

  const appSettings = useSelector((state) => state.config?.data?.AppSetting);

  return (
    <div>
      <ReferenceInput
        source="serviceTypeId"
        resource="serviceTypes"
        initialFilter={{ filter: { isActive: true }, orderBy: 'name' }}
      >
        <RestSelect
          required
          titleProp="name"
          valueProp="id"
          header={formatLabelTable(LABEL_TABLE_KEY.serviceType, appSettings)}
          placeholder={formatLabelTable(
            LABEL_TABLE_KEY.serviceType,
            appSettings,
          )}
        />
      </ReferenceInput>

      <ReferenceInput
        source="serviceAgeGroupId"
        resource="serviceAgeGroups"
        prefixUrl={PREFIX_URL.superAdmin}
        initialFilter={{ filter: { isActive: true }, orderBy: 'name' }}
      >
        <RestSelect
          required
          source="serviceAgeGroupId"
          header={formatLabelTable(LABEL_TABLE_KEY.ageGroup, appSettings)}
          placeholder={formatLabelTable(LABEL_TABLE_KEY.ageGroup, appSettings)}
          isShowTooltip
          titleProp="name"
          valueProp="id"
          isFilterOption={false}
        />
      </ReferenceInput>

      <RestInputItem
        header="centres.roomName"
        placeholder="centres.roomName"
        source="roomName"
        rules={[
          {
            max: 255,
            message: i18next.t('error.maxLength', { max: 255 }),
          },
        ]}
      />
      <RestInputItem
        header="centres.capacity"
        placeholder="centres.capacity"
        source="capacity"
        ContentComponent={InputNumber}
        min={0}
        ruleType="number"
      />
      <RestSelect
        required
        header={formatLabelTable(LABEL_TABLE_KEY.sessionType, appSettings)}
        placeholder={formatLabelTable(LABEL_TABLE_KEY.sessionType, appSettings)}
        source="sessionTypeId"
        resourceData={SessionTypes || []}
        valueProp="id"
        titleProp="name"
      />
      <RestInputItem
        required
        header="centres.costPerSession"
        placeholder="centres.costPerSession"
        source="costPerDay"
        ContentComponent={InputNumber}
        min={0}
        ruleType="number"
      />
      {isEdit && (
        <Row gutter={16}>
          <Col span={12}>
            <RestInputItem
              source="vacancy_cod"
              header="centreServices.vacancy"
              placeholder="centreServices.vacancy"
              ContentComponent={Switch}
              ruleType="boolean"
              valuePropName="checked"
            />
          </Col>
          <Col span={12}>
            <RestInputItem
              source="isActive"
              header="centreServices.isActive"
              placeholder="centreServices.isActive"
              ContentComponent={Switch}
              ruleType="boolean"
              valuePropName="checked"
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

ServiceForm.propTypes = {
  isEdit: PropTypes.bool,
};
export default ServiceForm;
