import styled from 'styled-components';

const SalesStageDropdownStyles = styled.div`
  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
    color: ${({ theme }) => theme.subscription.tagColor} !important;
    background: ${({ theme }) => theme.subscription.tagBg} !important;
    border-radius: 8px !important;
    font-size: 16px;
  }
  .ant-select-disabled {
    .ant-select-selector {
      .ant-select-selection-item {
        color: #b4bcc4;
      }
    }
  }
`;

export default SalesStageDropdownStyles;
