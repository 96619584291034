import React from 'react';
import Create from 'containers/rest/Create';
import { PREFIX_URL_API } from 'configs/localData/constant';
import i18next from 'i18next';
import Form from '../components/Form';

const SessionTypesCreate = (props) => (
  <Create
    {...props}
    resource="sessionTypes"
    defaultOptions={{
      isTrimStr: true,
      isGetConfig: true,
      prefixUrl: PREFIX_URL_API,
      customApiResource: 'session-types',
    }}
    header={i18next.t('button.addNewItem')}
    okText="button.save"
  >
    <Form />
  </Create>
);

export default SessionTypesCreate;
