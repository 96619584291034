import { createSelector } from 'reselect';
import { CRUDSelectors } from '../crudCreator/selectors';
import { RESOURCE } from './slice';

const getLatestEmailsSent = (state) => state.notifications?.latestEmailsSent;

export const getLatestEmailsSentSelector = createSelector(
  [getLatestEmailsSent],
  (data) => data?.results.map((item) => item?.email) || [],
);

export const notificationsSelectors = new CRUDSelectors(RESOURCE);
