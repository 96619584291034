import React from 'react';
import RestInputItem from 'components/RestInput/RestInputItem';
import { Col, Row } from 'antd';
import RestRangePickerInput from 'components/RestInput/RestRangePickerInput';

const Filter = () => (
  <Row gutter={16}>
    <Col lg={6} md={12} xs={12}>
      <RestInputItem
        source={['name', '$like']}
        placeholder="companies.name"
        isShowTooltip
      />
    </Col>

    <Col lg={6} md={12} xs={12}>
      <RestInputItem
        source={['outsideFilter', 'email']}
        placeholder="users.email"
        isShowTooltip
      />
    </Col>

    <Col lg={6} md={12} xs={12}>
      <RestInputItem
        source={['outsideFilter', 'phone']}
        placeholder="companies.phone"
        isShowTooltip
      />
    </Col>
    <Col lg={6} md={12} xs={12}>
      <RestRangePickerInput
        source="lastContact"
        placeholder="common.time"
        isShowTooltip
        sourceGt="lastContact.$gt"
        sourceLt="lastContact.$lt"
      />
    </Col>
  </Row>
);

export default Filter;
