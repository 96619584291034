import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { InputNumber, Switch } from 'antd';
import { SERVICE_AGE_GROUPS, VACANCY_COD } from 'configs/localData';

import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';

const CentreServicesForm = ({ isEdit, ...props }) => {
  const serviceTypes = useSelector((state) => state.config.data?.ServiceType);

  return (
    <div {...props}>
      <RestSelect
        required
        header="centreServices.service"
        placeholder="centreServices.service"
        source="serviceTypeId"
        resourceData={serviceTypes || []}
        valueProp="id"
        titleProp="name"
      />
      <RestSelect
        required
        header="centreServices.ageGroup"
        placeholder="centreServices.ageGroup"
        source="serviceAgeGroup"
        resourceData={SERVICE_AGE_GROUPS || []}
        valueProp="value"
        titleProp="text"
        formatText={(data) => i18next.t(data)}
      />
      <RestInputItem
        required
        source="costPerDay"
        header="centreServices.costPerDay"
        placeholder="centreServices.costPerDay"
        ContentComponent={InputNumber}
        min={0}
        ruleType="number"
      />
      {isEdit && (
        <>
          <RestSelect
            source="vacancy_cod"
            header="centreServices.vacancy"
            placeholder="centreServices.vacancy"
            resourceData={VACANCY_COD || []}
            valueProp="value"
            titleProp="text"
            formatText={(data) => i18next.t(data)}
            isShowSearch={false}
            ruleType="boolean"
            allowClear={false}
          />
          <RestInputItem
            source="isActive"
            header="centreServices.isActive"
            placeholder="centreServices.isActive"
            ContentComponent={Switch}
            ruleType="boolean"
            valuePropName="checked"
          />
        </>
      )}
    </div>
  );
};

CentreServicesForm.propTypes = {
  isEdit: PropTypes.bool,
};

export default CentreServicesForm;
