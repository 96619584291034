import React from 'react';
import Create from 'containers/rest/Create';
import Form from '../components/Form';

const CRMPackagesCreate = (props) => (
  <Create {...props} resource="CRMPackages">
    <Form />
  </Create>
);

export default CRMPackagesCreate;
