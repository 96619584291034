import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react';
import i18next from 'i18next';
import { take } from 'lodash-es';
import { Link } from 'react-router-dom';
import ModalCustom from 'components/common/ModalCustom';
import Button from 'antd/lib/button';
import { CentresUserModalStyles } from './styles';

const defaultPageSize = 10;

const CentresUserModal = forwardRef((_props, ref) => {
  const [visible, setVisible] = useState(false);
  const [user, setUser] = useState();

  const [data, setData] = useState();
  const [page, setPage] = useState(1);

  useImperativeHandle(
    ref,
    () => ({
      open: (data) => {
        setVisible(true);
        setUser(data);
        setPage(1);
      },
    }),
    [],
  );

  const onCancel = () => setVisible(false);

  useEffect(() => {
    setData(take(user?.centres, page * defaultPageSize));
  }, [user, page]);

  const enableLoadMore = data?.length < user?.centres?.length;

  const handleViewMore = () => {
    setPage(page + 1);
  };

  return (
    <ModalCustom
      visible={visible}
      title={`${i18next.t('users.centresUserModalTitle')} ${
        user?.firstName || ''
      } ${user?.lastName || ''}`}
      footer={null}
      onCancel={onCancel}
    >
      <CentresUserModalStyles>
        <div className="centres-list">
          {data?.map((centre, index) => (
            <Link
              to={`/centres/${centre?.id}/show`}
              key={String(index)}
              className="link-custom-primary centre-item flex-center-between"
            >
              {centre?.name}
            </Link>
          ))}
        </div>
        {enableLoadMore && (
          <Button
            className="view-more-button"
            type="link"
            onClick={handleViewMore}
          >
            {i18next.t('button.viewMore')}
          </Button>
        )}
      </CentresUserModalStyles>
    </ModalCustom>
  );
});

export default CentresUserModal;
