import { makeCRUDSlice } from 'redux/crudCreator';
import {
  MODEL_NAME,
  activitiesActions,
  getSummariesActivities,
} from './actions';

const slice = makeCRUDSlice(MODEL_NAME, activitiesActions, {
  [getSummariesActivities.fulfilled]: (state, { payload }) => {
    state.summaries = payload;
  },
});

export default slice.reducer;
