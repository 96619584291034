import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  clearCurrentInboxes,
  clearMessages,
  getByIdInboxes,
  getMessagesOfInbox,
} from 'redux/inboxes/actions';
import { getMessagesSelector, inboxesSelectors } from 'redux/inboxes/selectors';

import { PREFIX_URL_API } from 'configs/localData/constant';
import MessageDetailStyles from './styles';
import MessageHeader from './MessageHeader';
import MessageBody from './MessageBody';

const MessageDetail = ({ inboxId }) => {
  const dispatch = useDispatch();
  const currentMessage = useSelector(inboxesSelectors.getCurrentData) || {};

  const listMessages = useSelector(getMessagesSelector);
  useEffect(() => {
    if (inboxId) {
      dispatch(
        getByIdInboxes({
          data: { id: inboxId },
          options: { isRequestApi: true, prefixUrl: PREFIX_URL_API },
        }),
      );
    }
  }, [inboxId, dispatch]);

  useEffect(
    () => () => {
      dispatch(clearCurrentInboxes());
      dispatch(clearMessages());
    },
    [dispatch],
  );

  const retrieveList = useCallback(
    (data, isRefresh) =>
      new Promise((resolve) => {
        if (inboxId) {
          resolve(
            dispatch(
              getMessagesOfInbox({
                id: inboxId,
                data: { orderBy: '-createdAt', ...data },
                options: { isRefresh },
              }),
            ),
          );
        }
      }),
    [inboxId, dispatch],
  );

  return (
    <MessageDetailStyles>
      <MessageHeader
        user={currentMessage.user}
        enquiryType={currentMessage.enquiryType}
        id={inboxId}
        centreId={currentMessage.centreId}
        centreHasNewMessage={currentMessage.centreHasNewMessage}
        archivedAt={currentMessage.archivedAt}
        archivedReason={currentMessage.archivedReason}
      />
      <MessageBody
        listMessages={listMessages}
        retrieveList={retrieveList}
        inboxId={inboxId}
        centreId={currentMessage.centreId}
      />
    </MessageDetailStyles>
  );
};

MessageDetail.propTypes = {
  inboxId: PropTypes.string,
};

MessageDetail.propTypes = {
  inboxId: PropTypes.string,
};

export default MessageDetail;
