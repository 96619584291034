import React from 'react';
import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import DeleteButton from 'components/RestActions/DeleteButton';
import { PREFIX_URL_API } from 'configs/localData/constant';

const BusinessUsersList = (props) => (
  <List
    {...props}
    resource="businessUsers"
    hasSearch={false}
    defaultOptions={{ prefixUrl: PREFIX_URL_API }}
    widthTable="1400px"
    redirects={{
      edit: 'screen',
      create: 'screen',
    }}
  >
    <RestFieldItem source="firstName" header="businessUsers.firstName" />
    <RestFieldItem source="isBlocked" header="businessUsers.isBlocked" />
    <ActionGroup>
      <EditButton />
      <DeleteButton />
    </ActionGroup>
  </List>
);

export default BusinessUsersList;
