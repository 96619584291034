import FormItem from 'antd/lib/form/FormItem';
import Input from 'antd/lib/input';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import { ACTIVITY_PROVIDER_TYPES } from 'configs/localData';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import React from 'react';
import ActivitiesUploadForm from './ActivitiesUploadForm';
import AddAssignee from './AddAssignee';
import AddDueDate from './AddDueDate';
import { ActivityFormStyles } from './styles';

const FormActivity = ({ setIsDisabled, record }) => (
  <ActivityFormStyles className="form-custom-label">
    <RestSelect
      required
      header="activitiesAndNotes.activityType"
      placeholder="activitiesAndNotes.activityType"
      source="type"
      resourceData={ACTIVITY_PROVIDER_TYPES}
      valueProp="value"
      titleProp="text"
      formatText={(text, item) => (
        <span style={{ color: item.color }}>{i18next.t(text)}</span>
      )}
      isShowSearch={false}
    />
    <RestInputItem
      required
      maxLength={255}
      isValidatorEmpty
      header="common.subject"
      placeholder="common.subject"
      source="subject"
    />
    <RestInputItem
      required
      isValidatorEmpty
      header="common.description"
      placeholder="common.description"
      source="description"
      ContentComponent={Input.TextArea}
      rows={4}
    />
    <FormItem
      name="assigneeUserId"
      label={i18next.t('tasks.assignedTo')}
      rules={[
        {
          required: true,
          message: i18next.t('error.required'),
        },
      ]}
    >
      <AddAssignee />
    </FormItem>

    <FormItem
      name="dueDate"
      label={i18next.t('activitiesAndNotes.activityDate')}
    >
      <AddDueDate />
    </FormItem>
    <ActivitiesUploadForm
      record={record}
      setIsDisabled={setIsDisabled}
      source="attachments"
    />
  </ActivityFormStyles>
);

FormActivity.propTypes = {
  setIsDisabled: PropTypes.func,
  record: PropTypes.object,
};

export default FormActivity;
