import styled from 'styled-components';

const CRMPackagePriceStyles = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  .price-detail {
    &__sign {
      font-size: 12px;
      vertical-align: super;
    }
    &__unit {
      font-size: 12px;
      margin-left: 6px;
      color: ${({ theme }) => theme.text.mainL3};
    }
  }
`;

export default CRMPackagePriceStyles;
