import React from 'react';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import FilterCustom from 'components/common/FilterCustom';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import { ENQUIRIES_STATUS_CONST, ENQUIRY_TYPES_CONST } from 'configs/localData';
import RestRangePickerInput from 'components/RestInput/RestRangePickerInput';
import { formatTimeFilterSubmit } from 'utils/dataUtils';

const FilterEnquiries = ({ retrieveList }) => {
  const enquiryTypes = useSelector((state) => state.config.data?.EnquiryType);

  const onSubmitFilter = ({ lastUpdate, ...values }) => {
    const time = formatTimeFilterSubmit(lastUpdate);
    retrieveList({
      filter: {
        offset: 0,
        outsideFilter: {
          ...values,
          startDate: time?.$gt,
          endDate: time?.$lt,
        },
      },
      isRefresh: true,
    });
  };

  const onClearFilter = () => {
    retrieveList({
      filter: {
        offset: 0,
      },
      isRefresh: true,
    });
  };
  return (
    <FilterCustom
      onSubmitFilter={onSubmitFilter}
      onClearFilter={onClearFilter}
      responsiveFilter={{
        xxl: 20,
        xl: 18,
        lg: 24,
        md: 24,
        xs: 24,
      }}
      responsiveAction={{
        xxl: 4,
        xl: 6,
        lg: 24,
        md: 24,
        xs: 24,
      }}
    >
      <Row gutter={16}>
        <Col xl={5} md={12} xs={12}>
          <RestInputItem
            source="centre"
            placeholder="inboxes.searchCentre"
            isShowTooltip
          />
        </Col>
        <Col xl={5} md={12} xs={12}>
          <RestInputItem
            source="sender"
            placeholder="inboxes.nameEmailSender"
            isShowTooltip
          />
        </Col>
        <Col xl={4} md={12} xs={12}>
          <RestSelect
            source="enquiryTypeId"
            placeholder="inboxes.enquiryType"
            isShowTooltip
            resourceData={enquiryTypes}
            valueProp="id"
            titleProp="name"
            formatText={(value) =>
              ENQUIRY_TYPES_CONST[value]?.text
                ? i18next.t(ENQUIRY_TYPES_CONST[value]?.text)
                : value
            }
          />
        </Col>
        <Col xl={4} md={12} xs={12}>
          <RestSelect
            source="centreEnquiryStatus"
            placeholder="inboxes.enquiryStatus"
            isShowTooltip
            resourceData={ENQUIRIES_STATUS_CONST}
            formatText={(text) => i18next.t(text)}
            valueProp="value"
            titleProp="text"
          />
        </Col>
        <Col xl={6} md={12} xs={12}>
          <RestRangePickerInput
            source="lastUpdate"
            placeholder="common.time"
            isShowTooltip
            sourceGt="'startDate"
            sourceLt="endDate"
          />
        </Col>
      </Row>
    </FilterCustom>
  );
};

FilterEnquiries.propTypes = {
  retrieveList: PropTypes.func,
};
export default FilterEnquiries;
