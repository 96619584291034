import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Row, Col } from 'antd';
import i18next from 'i18next';
import { PictureOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';

import {
  ChildIcon,
  KindiCareIcon,
  ServiceIcon,
  WaitlistIcon,
} from 'components/common/SVGIcon';

import TagCustom from 'components/common/TagCustom';
import KindiRating from 'components/common/KindiRating';
import { getFeaturedImage } from 'utils/dataUtils';
import { CENTRE_STATUS_VALUE } from 'configs/localData';
import ProgressCustome from 'components/common/ProgressCustome';
import CentresCardStyles from './styles';

const CentresCard = ({ record }) => {
  const { push } = useHistory();
  const redirectDetail = () => {
    localStorage.removeItem('currentStep');
    record?.id && push(`/centres/${record?.id}/show`);
  };

  return (
    <CentresCardStyles className="cursor-pointer" onClick={redirectDetail}>
      <div className="centre-image">
        <Avatar
          icon={<PictureOutlined />}
          src={getFeaturedImage(record?.images)}
        />
        <KindiRating className="rating" value={record?.kindiCareRating} />
        <img alt="" src={record?.NQSRType?.image} className="nqs-rating-img" />
        <ProgressCustome
          type="circle"
          className="indicator-progress"
          percent={record?.totalListingStrength}
          title="centres.listingStrength"
          format={(data) => `${data}%`}
        />
      </div>
      <div className="content-card">
        <div className="name-centre">
          {record?.name || i18next.t('error.waitingUpdate')}
        </div>
        <Row gutter={[10, 10]} className="info-content">
          <Col span={24} className="address item-info">
            <EnvironmentOutlined />
            <span className="value-item">
              {record?.displayAddress || i18next.t('error.waitingUpdate')}
            </span>
          </Col>
          <Col span={12} className="item-info">
            <ChildIcon />
            <span className="value-item">
              {`${record?.placeAllowed || 0} ${i18next.t(
                'centres.childrenOpacity',
              )}`}
            </span>
          </Col>
          <Col span={12} className="item-info">
            <WaitlistIcon />
            <span className="value-item">{record?.totalWaitListed || 0}</span>
          </Col>
          {record?.CRMPackage &&
            record?.claimStatus === CENTRE_STATUS_VALUE.claimed && (
              <Col span={12} className="item-info">
                <KindiCareIcon />
                <TagCustom
                  name={record?.CRMPackage?.name}
                  color="#32A4FC"
                  backgroundColor="#E9F4FF"
                  className="value-item"
                />
              </Col>
            )}
          <Col span={12} className="item-info">
            <ServiceIcon />
            <span className="value-item">{record?.totalServices || 0}</span>
          </Col>
        </Row>
      </div>
    </CentresCardStyles>
  );
};

CentresCard.propTypes = {
  record: PropTypes.object,
};

CentresCard.defaultProps = {
  record: {},
};

export default CentresCard;
