import { createSlice } from '@reduxjs/toolkit';
import { getSearchStatistics } from './actions';

export const initialState = {
  loading: false,
  data: [],
  resourceData: {
    filter: {},
    offset: 0,
    limit: 10,
    total: 0,
    numberOfPages: 1,
  },
};

const getSearchStatisticsPending = (
  state,
  {
    meta: {
      arg: { data, options = {} },
    },
  },
) => {
  state.loading = true;

  if (options.isRefresh) {
    state.data = [];
    if (options.keepFilter) {
      state.resourceData = {
        ...state.resourceData,
        ...data,
        offset: 0,
      };
    } else {
      state.resourceData = {
        ...initialState.resourceData,
        ...data,
      };
    }
  } else {
    state.resourceData.offset =
      state.resourceData.offset + state.resourceData.limit || 0;
  }
};

const getSearchStatisticsSuccess = (state, { payload: { data } }) => {
  state.data = data.results || [];

  state.resourceData.total = data.total || 0;
  state.resourceData.numberOfPages = data.numberOfPages;

  state.loading = false;
  state.error = null;
};

const getSearchStatisticsFail = (state, { payload: { data } }) => {
  state.error = data;
  state.loading = false;
};

const { reducer } = createSlice({
  name: 'searchStatistics',
  initialState,
  extraReducers: {
    [getSearchStatistics.pending]: getSearchStatisticsPending,
    [getSearchStatistics.fulfilled]: getSearchStatisticsSuccess,
    [getSearchStatistics.rejected]: getSearchStatisticsFail,
  },
});

export default reducer;
