import Breadcrumb from 'components/common/Breadcrumb';
import PageTitle from 'components/common/PageTitle';
import i18next from 'i18next';
import React from 'react';
import { sendNotification } from 'redux/notifications/actions';

import Form from '../components/Form';

const NotificationsCreate = () => {
  const BREADCRUMB = [
    {
      path: '/notifications',
      title: i18next.t('notifications.header'),
    },
    {
      title: i18next.t('notifications.createPage'),
    },
  ];

  const handleNotify = (data) => sendNotification(data);

  return (
    <div>
      <Breadcrumb data={BREADCRUMB} />
      <PageTitle>{i18next.t('notifications.createPage')}</PageTitle>
      <Form handleNotify={handleNotify} />
    </div>
  );
};

export default NotificationsCreate;
