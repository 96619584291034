import React, { useState } from 'react';
import i18n from 'i18next';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import PageTitle from 'components/common/PageTitle';
import ProfileInfo from './components/ProfileInfo';
import ProfileStyles from './styles';
import Logout from './components/Logout';
import ModalChangePassword from './components/ModalChangePassword';

const Profile = () => {
  const currentUser = useSelector((state) => state.auth.data);
  const [visibleModalChangePass, setVisibleModalChangePass] = useState(false);
  const toggleVisibleModalChangePass = () => {
    setVisibleModalChangePass(!visibleModalChangePass);
  };
  return (
    <ProfileStyles>
      <PageTitle>{i18n.t('profile.header')}</PageTitle>
      <ProfileInfo currentUser={currentUser} />
      <div className="change-password-section">
        <Button onClick={toggleVisibleModalChangePass} type="primary">
          {i18n.t('button.changePassword')}
        </Button>
      </div>
      <div className="logout-section">
        <Logout currentUser={currentUser} />
      </div>
      <ModalChangePassword
        visible={visibleModalChangePass}
        onCancel={toggleVisibleModalChangePass}
      />
    </ProfileStyles>
  );
};

export default Profile;
