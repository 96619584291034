import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import { CardHourStyles } from './styles';
import HourItem from './HourItem';

const CardHourSchool = ({ title, openTime, data, isEdit }) => (
  <CardHourStyles>
    <div className="title-card-hour">{i18next.t(title)}</div>
    <div className="info-hours">
      {data.map((obj, index) => {
        const restItem = openTime?.[obj.valueMap || obj.value];
        return (
          <div className="hour-item" key={String(index)}>
            <div className="label-item">{i18next.t(obj.text)}</div>
            <Row gutter={14}>
              <Col span={12}>
                <HourItem
                  isEdit={isEdit}
                  source={['openTime', obj.value, 'start']}
                  time={restItem?.start}
                  placeholder="text.from"
                />
              </Col>
              <Col span={12}>
                <HourItem
                  isEdit={isEdit}
                  source={['openTime', obj.value, 'end']}
                  time={restItem?.end}
                  placeholder="text.to"
                />
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  </CardHourStyles>
);

CardHourSchool.propTypes = {
  openTime: PropTypes.object,
  title: PropTypes.string,
  data: PropTypes.array,
  isEdit: PropTypes.bool,
};

CardHourSchool.defaultProps = {
  openTime: {},
  data: [],
};

export default CardHourSchool;
