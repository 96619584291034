import { Skeleton } from 'antd';
import HeaderSection from 'components/common/HeaderSection';
import i18next from 'i18next';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  getDataTotalSearchStatistic,
  getLoadingTotalSearchStatistic,
} from 'redux/totalSearchStatistic/selectors';
import TableCustom from 'components/common/TableCustom';
import moment from 'moment';
import { FORMAT_DATE_STATISTIC } from 'configs/localData/constant';
import { TopSearchStatisticStyles } from '../../styles';
import PercentValue from './PercentValue';
import { TotalSearchTableStyles } from './styles';

const TotalSearchTable = () => {
  const data = useSelector(getDataTotalSearchStatistic);
  const isLoading = useSelector(getLoadingTotalSearchStatistic);
  const endDate = moment().format(FORMAT_DATE_STATISTIC);
  const startDate = moment().subtract(7, 'd').format(FORMAT_DATE_STATISTIC);

  const columns = [
    {
      title: i18next.t('searchStatistics.totalSearchTable.search'),
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: i18next.t('searchStatistics.totalSearchTable.total'),
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: i18next.t('searchStatistics.totalSearchTable.change'),
      dataIndex: 'percentChange',
      key: 'percentChange',
      render: (text, record) => (
        <PercentValue
          record={record}
          text={text}
          startDate={startDate}
          endDate={endDate}
        />
      ),
    },
  ];

  return (
    <TopSearchStatisticStyles>
      <HeaderSection title="searchStatistics.totalSearchTable.title" />
      <TotalSearchTableStyles>
        <Skeleton loading={isLoading}>
          <TableCustom
            columns={columns}
            dataSource={data}
            pagination={{ hideOnSinglePage: true }}
            scroll={{}}
          />
        </Skeleton>
      </TotalSearchTableStyles>
    </TopSearchStatisticStyles>
  );
};

export default TotalSearchTable;
