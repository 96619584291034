import React from 'react';
import { isEmpty } from 'lodash-es';
import { formatLabelTable } from 'utils/textUtils';
import Edit from 'containers/rest/Edit';
import { LABEL_TABLE_KEY, PREFIX_URL } from 'configs/localData/constant';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import Form from '../components/Form';

const ServiceTypesEdit = (props) => {
  const appSettings = useSelector((state) => state.config?.data?.AppSetting);

  const formatOnSubmit = ({ code, logo, ...values }) => ({
    ...values,
    ...(!isEmpty(logo)
      ? { logo: { key: logo.key, url: logo.url } }
      : { logo: {} }),
    code: code.toUpperCase(),
  });

  return (
    <Edit
      {...props}
      resource="childcareServiceTypes"
      formatOnSubmit={formatOnSubmit}
      defaultOptions={{
        isTrimStr: true,
        isGetConfig: true,
        prefixUrl: PREFIX_URL.superAdmin,
        customApiResource: 'primary-child-care-service',
      }}
      header={`${i18next.t('button.edit')} ${formatLabelTable(
        LABEL_TABLE_KEY.childCareService,
        appSettings,
      )}`}
    >
      <Form isEdit />
    </Edit>
  );
};

export default ServiceTypesEdit;
