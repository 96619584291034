import React, { useCallback } from 'react';
import TableCustom from 'components/common/TableCustom';
import { useLocation } from 'react-router';
import { getFilterFromUrl } from 'utils/tools';
import PropsType from 'prop-types';
import {
  getJobApplicationsTrackerData,
  getResourceDataJobApplicationsTracker,
} from 'redux/jobApplicationsTracker/selectors';
import { useSelector } from 'react-redux';
import {
  DEFAULT_CURRENT_PAGE,
  I18N_PREFIX_KEY,
  JOB_PRIMARY_PROGRESS_BAR_COLOR,
  SORT_OPTIONS,
} from 'containers/JobApplicationsTracker/constants';
import {
  LIMIT_ITEM,
  SORT_ASCEND_VALUE,
  SORT_DESCEND_VALUE,
} from 'configs/localData/constant';
import {
  renderApplicantComponent,
  renderBrand,
  renderJobListingStatus,
  renderJobReference,
  renderJobType,
  renderNormalData,
  renderWorkplace,
  transformApplicationStatus,
} from 'containers/JobApplicationsTracker/utils';
import ProgressCustome from 'components/common/ProgressCustome';
import { formatNightDayTable } from 'utils/formatFieldUtils';
import { formatDate } from 'utils/textUtils';
import i18next from 'i18next';

const Table = ({ isLoading, fetchDataList, pushQueryUrl }) => {
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);
  const resourceFilter =
    useSelector(getResourceDataJobApplicationsTracker) || {};
  const applicationsTrackerData = useSelector(getJobApplicationsTrackerData);

  const getSorterOrder = useCallback(
    (source) => {
      if (filter.sorts?.[0] === source) return SORT_ASCEND_VALUE;
      if (filter.sorts?.[0] === `-${source}`) return SORT_DESCEND_VALUE;
      return '';
    },
    [filter],
  );

  const tableColumns = [
    {
      title: i18next.t(`${I18N_PREFIX_KEY}.jobReference`),
      dataIndex: 'jobReference',
      sorter: true,
      render: (_, record) => renderJobReference(record),
      width: 150,
      defaultSortOrder: getSorterOrder('jobReference'),
    },
    {
      title: i18next.t(`${I18N_PREFIX_KEY}.workplace.placeholder`),
      dataIndex: 'workplace',
      sorter: true,
      render: (_, record) => renderWorkplace(record),
      width: 300,
      defaultSortOrder: getSorterOrder('workplace'),
    },
    {
      title: i18next.t(`${I18N_PREFIX_KEY}.workplace.type`),
      dataIndex: 'workplaceType',
      sorter: true,
      width: 200,
      render: (_, record) => (
        <span className="ellipsis-t-1">{record?.organization?.type}</span>
      ),
      defaultSortOrder: getSorterOrder('workplaceType'),
    },
    {
      title: i18next.t(`${I18N_PREFIX_KEY}.company`),
      dataIndex: 'company',
      sorter: true,
      width: 300,
      render: (_, record) => renderNormalData(record?.organization?.company),
      defaultSortOrder: getSorterOrder('company'),
    },
    {
      title: i18next.t(`${I18N_PREFIX_KEY}.brand`),
      dataIndex: 'brand',
      sorter: true,
      width: 230,
      render: (_, record) => renderBrand(record),
      defaultSortOrder: getSorterOrder('brand'),
    },
    {
      title: i18next.t(`${I18N_PREFIX_KEY}.state`),
      dataIndex: 'state',
      sorter: true,
      width: 120,
      render: (_, record) => (
        <span className="ellipsis-t-1">{record?.organization?.state}</span>
      ),
      defaultSortOrder: getSorterOrder('state'),
    },
    {
      title: i18next.t(`${I18N_PREFIX_KEY}.jobTitle`),
      dataIndex: 'jobTitle',
      sorter: true,
      width: 300,
      render: (_, record) => renderNormalData(record?.jobPost?.title),
      defaultSortOrder: getSorterOrder('jobTitle'),
    },
    {
      title: i18next.t(`${I18N_PREFIX_KEY}.jobRole`),
      dataIndex: 'jobRole',
      width: 200,
      sorter: true,
      render: (_, record) => renderNormalData(record?.jobPost?.role),
      defaultSortOrder: getSorterOrder('jobRole'),
    },
    {
      title: i18next.t(`${I18N_PREFIX_KEY}.jobType`),
      dataIndex: 'jobType',
      sorter: true,
      width: 200,
      render: (_, record) => renderJobType(record?.jobPost?.type),
      defaultSortOrder: getSorterOrder('jobType'),
    },
    {
      title: i18next.t(`${I18N_PREFIX_KEY}.jobListingStatus.title`),
      dataIndex: 'jobListingStatus',
      sorter: true,
      render: (_, record) =>
        renderJobListingStatus(record?.jobPost?.listingStatus),
      defaultSortOrder: getSorterOrder('jobListingStatus'),
      width: 180,
    },
    {
      title: i18next.t(`${I18N_PREFIX_KEY}.applicantOrEmail.title`),
      dataIndex: 'applicantName',
      sorter: true,
      width: 300,
      render: (_, record) => renderApplicantComponent(record),
      defaultSortOrder: getSorterOrder('applicantName'),
    },
    {
      title: i18next.t(`${I18N_PREFIX_KEY}.applicationStatus.placeholder`),
      dataIndex: 'applicationStatus',
      sorter: true,
      width: 200,
      render: (_, record) => transformApplicationStatus(record?.status),
      defaultSortOrder: getSorterOrder('applicationStatus'),
    },
    {
      title: i18next.t(`${I18N_PREFIX_KEY}.jobMatchScore`),
      dataIndex: 'jobMatchScore',
      sorter: true,
      defaultSortOrder: getSorterOrder('jobMatchScore'),
      render: (_, record) => (
        <ProgressCustome
          strokeColor={JOB_PRIMARY_PROGRESS_BAR_COLOR}
          percent={record?.jobPost?.matchingScore?.toString() ?? '0'}
          className="pr-32"
        />
      ),
    },
    {
      title: i18next.t(`${I18N_PREFIX_KEY}.appliedTime.title`),
      dataIndex: 'appliedTime',
      sorter: true,
      width: 180,
      render: (data) => (
        <span className="ellipsis-t-1">{formatDate(data)}</span>
      ),
      defaultSortOrder: getSorterOrder('appliedTime'),
    },
    {
      title: i18next.t(`${I18N_PREFIX_KEY}.lastUpdated.title`),
      dataIndex: 'lastUpdated',
      width: 200,
      sorter: true,
      render: (_, record) => formatNightDayTable(record?.updatedAt),
      defaultSortOrder: getSorterOrder('lastUpdated'),
    },
  ];

  const onChange = (e, filters, sorter) => {
    const formatSort =
      sorter && sorter.field && sorter.order
        ? `${sorter.order === SORT_DESCEND_VALUE ? '-' : ''}${sorter.field}`
        : null;

    const restFilter = {
      size: e.pageSize,
      page: e.current,
      sorts: [formatSort || SORT_OPTIONS.APPLIED_TIME_DESC],
      filter: { ...filter.filter },
    };

    fetchDataList({
      filter: restFilter,
      isRefresh: true,
      keepFilter: true,
    });
    pushQueryUrl({
      'sorts[]': restFilter.sorts,
      ...restFilter,
    });
  };

  return (
    <TableCustom
      xScroll={3300}
      isSorter
      columns={tableColumns}
      onChange={onChange}
      data={applicationsTrackerData}
      loading={isLoading}
      pagination={{
        pageSize: resourceFilter.size || LIMIT_ITEM,
        current: filter.page || DEFAULT_CURRENT_PAGE,
        total: resourceFilter.total,
      }}
    />
  );
};

Table.propTypes = {
  isLoading: PropsType.bool,
  pushQueryUrl: PropsType.func,
  fetchDataList: PropsType.func,
};

export default Table;
