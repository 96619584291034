import React from 'react';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { getServiceTypes } from 'redux/config/selectors';

import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import { APPLICATION_STATUS } from 'configs/localData';
import i18next from 'i18next';
import RestRangePickerInput from 'components/RestInput/RestRangePickerInput';
import { formatLabelTable } from 'utils/textUtils';
import { LABEL_TABLE_KEY } from 'configs/localData/constant';

const Filter = () => {
  const serviceTypes = useSelector(getServiceTypes);
  const appSettings = useSelector((state) => state.config?.data?.AppSetting);

  return (
    <Row gutter={16}>
      <Col lg={4} md={12} xs={24}>
        <RestInputItem
          source={['outsideFilter', 'centre']}
          placeholder="inboxes.searchCentre"
          isShowTooltip
        />
      </Col>
      <Col lg={5} md={12} xs={24}>
        <RestInputItem
          source={['outsideFilter', 'sender']}
          placeholder="applications.searchAppUser"
          isShowTooltip
        />
      </Col>
      <Col lg={5} md={12} xs={24}>
        <RestSelect
          source={['outsideFilter', 'serviceTypeId']}
          placeholder={formatLabelTable(
            LABEL_TABLE_KEY.serviceType,
            appSettings,
          )}
          isShowTooltip
          resourceData={serviceTypes}
          valueProp="id"
          titleProp="name"
        />
      </Col>
      <Col lg={5} md={12} xs={24}>
        <RestSelect
          source="status"
          placeholder="applications.applicationStatus"
          isShowTooltip
          resourceData={APPLICATION_STATUS}
          formatText={(text) => i18next.t(text)}
          valueProp="value"
          titleProp="text"
        />
      </Col>
      <Col lg={5} md={12} xs={24}>
        <RestRangePickerInput
          source="createdAt"
          placeholder="common.time"
          isShowTooltip
          sourceGt={['createdAt', '$gt']}
          sourceLt={['createdAt', '$lt']}
        />
      </Col>
    </Row>
  );
};

export default Filter;
