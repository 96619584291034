import List from 'containers/Users/List';
import Edit from 'containers/Users/Edit';
import Create from 'containers/Users/Create';
import Show from 'containers/Users/Show';
import AddMoreCentres from 'containers/Users/AddMoreCentres';
import AddMoreProviders from 'containers/Users/AddMoreProviders';
import AddMoreCompanies from 'containers/Users/AddMoreCompanies';

export default {
  List,
  Edit,
  Create,
  Show,
  AddMoreCentres,
  AddMoreProviders,
  AddMoreCompanies,
};
