import i18next from 'i18next';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { getProvidersOfCompany } from 'redux/providersOfCompany/actions';
import ProviderFilter from './ProviderFilter';
import ProviderListSelect from './ProviderListSelect';

const ProviderForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, keepFilter }) => {
      dispatch(
        getProvidersOfCompany({
          id,
          data: {
            ...filter,
            filter: {
              ...filter.filter,
            },
          },
          options: {
            keepFilter,
            isRefresh,
            isOrderBy: false,
          },
        }),
      );
    },
    [id, dispatch],
  );

  useEffect(() => {
    retrieveList({
      filter: {
        limit: 10,
        offset: 0,
      },
      isRefresh: true,
    });
  }, [retrieveList]);

  return (
    <div>
      <div className="title-session">{i18next.t('users.selectProviders')}</div>
      <ProviderFilter retrieveList={retrieveList} />
      <ProviderListSelect retrieveList={retrieveList} />
    </div>
  );
};

export default ProviderForm;
