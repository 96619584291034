import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import RestSelect from 'components/RestInput/RestSelect';

const FormStatus = () => {
  const statusItem = useContext(RestInputContext).item?.status;

  const taskStatus = useSelector((state) => state.config.data?.taskStatus);

  const statusData = statusItem?.next
    ? taskStatus?.filter(
        (item) =>
          item?.name === statusItem.name ||
          statusItem.next?.includes(item?.name),
      )
    : taskStatus;

  return (
    <RestSelect
      required
      header="common.status"
      source="statusId"
      resourceData={statusData}
      valueProp="id"
      titleProp="name"
      isShowSearch={false}
      defaultValue={statusData?.[0]?.id}
    />
  );
};

export default FormStatus;
