import FilterCustom from 'components/common/FilterCustom';
import PropTypes from 'prop-types';
import React from 'react';
import CentreFilterForm from './CentreFilterForm';

const CentreFilter = ({ retrieveList, greyInput = true }) => {
  const onSubmitFilter = (values) => {
    const filterValue = values;
    const outsideFilter = filterValue?.outsideFilter;
    delete filterValue.outsideFilter;
    retrieveList({
      filter: {
        filter: values,
        offset: 0,
        outsideFilter,
      },
      isRefresh: true,
      keepFilter: false,
    });
  };

  const onClearFilter = () => {
    retrieveList({
      filter: {
        offset: 0,
      },
      isRefresh: true,
      keepFilter: false,
    });
  };

  return (
    <FilterCustom
      greyInput={greyInput}
      onSubmitFilter={onSubmitFilter}
      onClearFilter={onClearFilter}
    >
      <CentreFilterForm />
    </FilterCustom>
  );
};

CentreFilter.propTypes = {
  retrieveList: PropTypes.func,
  greyInput: PropTypes.bool,
};

export default CentreFilter;
