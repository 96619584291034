import { makeActions } from 'redux/crudCreator/actions';

export const MODEL_NAME = 'claimCentreRequests';
export const claimCentreRequestsActions = makeActions(MODEL_NAME);

export const getAllClaimCentreRequests = claimCentreRequestsActions.getAll;
export const editClaimCentreRequests = claimCentreRequestsActions.edit;
export const createClaimCentreRequests = claimCentreRequestsActions.create;
export const getByIdClaimCentreRequests =
  claimCentreRequestsActions.getDataById;
