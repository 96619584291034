import { PREFIX_URL_API } from 'configs/localData/constant';
import React from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';

import { getProvidersOfCompany } from 'redux/providersOfCompany/actions';
import { getIdByUrl } from 'utils/tools';
import Edit from 'containers/rest/Edit';
import Form from '../components/Form';

const ProviderEdit = (props) => {
  const location = useLocation();

  const companyId = getIdByUrl({ resource: 'companies' }, location);

  const dispatch = useDispatch();

  const formatOnSubmit = ({ userId, companyId, ...values }) => ({
    ...values,
    companyId: companyId === undefined ? null : companyId,
    userId: userId === undefined ? null : userId,
  });

  const successAction = () => {
    if (companyId) {
      dispatch(
        getProvidersOfCompany({
          id: companyId,
          data: {},
          options: {
            keepFilter: true,
            isRefresh: true,
            isOrderBy: false,
          },
        }),
      );
    }
  };

  return (
    <Edit
      {...props}
      resource="providers"
      defaultOptions={{
        prefixUrl: PREFIX_URL_API,
        successAction,
      }}
      formatOnSubmit={formatOnSubmit}
    >
      <Form />
    </Edit>
  );
};

export default ProviderEdit;
