import React, { useRef, useState } from 'react';
import i18next from 'i18next';
import EmailEditor from 'react-email-editor';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import Form, { useForm } from 'antd/lib/form/Form';
import Button from 'antd/lib/button';

import { createEmailTemplate } from 'redux/EDM/actions';
import { handleApiError } from 'utils/validateUtils';
import { EDM_TAB_KEYS_CONST } from 'configs/localData/constant';

import Breadcrumb from 'components/common/Breadcrumb';
import PageTitle from 'components/common/PageTitle';
import EDMForm from './Form';
import sample from './sample.json';
import { DesignStyles } from './styles';
import { EMAIL_EDITOR_OPTIONS } from './constant';

const Create = () => {
  const [form] = useForm();

  const emailEditorRef = useRef();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { push } = useHistory();
  const { model } = useParams();

  const BREADCRUMB = [
    {
      path: `/EDM/${model}`,
      title: i18next.t('marketings.title'),
    },
    {
      title: i18next.t('marketings.designEDM'),
    },
  ];

  const onLoad = () => {
    if (emailEditorRef?.current) {
      emailEditorRef.current.editor.loadDesign(sample);
    } else {
      setTimeout(
        () =>
          emailEditorRef.current.editor &&
          emailEditorRef.current.editor.loadDesign(sample),
        3000,
      );
    }
  };

  const onFinish = (values) => {
    emailEditorRef.current.editor.exportHtml((data) => {
      setLoading(true);
      dispatch(
        createEmailTemplate({
          data: { ...values, value: data.html, editorDesign: data.design },
        }),
      )
        .then(({ payload }) => {
          if (payload?.id) {
            push(
              `/EDM/${
                payload?.isSystem
                  ? EDM_TAB_KEYS_CONST.system
                  : EDM_TAB_KEYS_CONST.favorites
              }`,
            );
          } else {
            handleApiError(payload, form);
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    });
  };

  return (
    <DesignStyles>
      <Breadcrumb data={BREADCRUMB} />
      <PageTitle>{i18next.t('marketings.designEDM')}</PageTitle>

      <div className="box-wrapper box-design">
        <Form form={form} onFinish={onFinish} scrollToFirstError>
          <div className="flex justify-between mb-20">
            <EDMForm />
            <Button
              className="btn-submit"
              loading={loading}
              htmlType="submit"
              type="primary"
            >
              {i18next.t('button.create')}
            </Button>
          </div>
          <EmailEditor
            ref={emailEditorRef}
            onLoad={onLoad}
            options={EMAIL_EDITOR_OPTIONS}
          />
        </Form>
      </div>
    </DesignStyles>
  );
};

export default Create;
