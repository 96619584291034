import React, { useState, forwardRef, useImperativeHandle } from 'react';
import i18next from 'i18next';
import Form from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';

import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  createActivities,
  getSummariesActivities,
} from 'redux/activities/actions';
import { PREFIX_URL_API } from 'configs/localData/constant';

import ModalCustom from 'components/common/ModalCustom';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { formatOnSubmit } from 'containers/Company/utils';
import moment from 'moment';
import FormActivity from '../../FormActivity';

const CompanyCreateModal = forwardRef((props, ref) => {
  const [form] = useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  const dispatch = useDispatch();

  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        setVisible(true);
      },
    }),
    [],
  );

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const onOk = () => {
    form
      .validateFields()
      .then((values) => {
        const data = {
          ...values,
          dueDate: moment(values?.dueDate)?.toISOString(),
        };

        setLoading(true);
        return dispatch(
          createActivities({
            data: {
              companyId: id,
              ...formatOnSubmit(data),
            },
            options: {
              prefixUrl: PREFIX_URL_API,
            },
          }),
        );
      })
      .then(({ payload }) => {
        setLoading(false);
        if (payload?.data?.id) {
          onCancel();
          dispatch(getSummariesActivities({ companyId: id }));
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <ModalCustom
      title={i18next.t('activitiesAndNotes.createPage')}
      onCancel={onCancel}
      visible={visible}
      onOk={onOk}
      resetStyleForm
      width={800}
      resetPaddingTopBody={false}
      okText="button.create"
      loading={loading}
    >
      <RestInputContext.Provider value={{ form }}>
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          autoComplete="off"
          labelAlign="left"
          initialValues={{ dueDate: moment() }}
        >
          <FormActivity />
        </Form>
      </RestInputContext.Provider>
    </ModalCustom>
  );
});

export default CompanyCreateModal;
