import { Col, Row } from 'antd';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import React from 'react';
import { useSelector } from 'react-redux';

const Filter = () => {
  const stateOfAustralia = useSelector(
    (state) => state.config.data?.stateOfAustralia,
  );
  return (
    <div>
      <Row gutter={16}>
        <Col xl={8} lg={12} md={12} xs={24}>
          <RestInputItem
            source={['city', '$like']}
            placeholder="suburbs.title"
            isShowTooltip
          />
        </Col>
        <Col xl={8} lg={12} md={12} xs={24}>
          <RestSelect
            source="state"
            placeholder="input.state.name"
            resourceData={stateOfAustralia || []}
            isShowTooltip
          />
        </Col>
        <Col xl={8} lg={12} md={12} xs={24}>
          <RestInputItem
            source="postCode"
            placeholder="suburbs.postCode"
            isShowTooltip
          />
        </Col>
      </Row>
    </div>
  );
};

export default Filter;
