import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  delCentresOfUserApi,
  getCentresOfUserApi,
  postCentresOfUserApi,
} from 'api/user';
import { getAllCustom } from 'redux/crudCreatorCustom/actions';
import { apiWrapper } from 'utils/reduxUtils';

export const MODEL_NAME = 'centresOfUser';

export const getCentresOfUser = getAllCustom(MODEL_NAME, getCentresOfUserApi);

export const postCentresOfUser = createAsyncThunk(
  'centresOfUser/postCentresOfUser',
  async (payload = {}, thunkApi) => {
    try {
      const { id, data = {}, options } = payload;

      const response = await apiWrapper({}, postCentresOfUserApi, id, data);

      return { data: response, options, success: true };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const delCentresOfUser = createAsyncThunk(
  'centresOfUser/delCentresOfUser',
  async (payload = {}, thunkApi) => {
    try {
      const { id, centreId, options = {} } = payload;

      const { total, offset, limit } =
        thunkApi.getState().centresOfUser?.resourceData || {};

      await apiWrapper({}, delCentresOfUserApi, id, centreId);

      thunkApi.dispatch(
        getCentresOfUser({
          id,
          data: {
            offset: offset > 0 && total % offset <= 1 ? offset - limit : offset,
            orderBy: 'name',
          },
          options: { isRefresh: true, keepFilter: true },
        }),
      );

      return { centreId, id, options };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
