import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Form, Input, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { editCentreInformation, getByIdCentres } from 'redux/centres/actions';
import { useParams } from 'react-router';
import { handleApiError } from 'utils/validateUtils';
import { PREFIX_URL } from 'configs/localData/constant';
import CentreModal from '.';

const CentreInfoModal = forwardRef(({ record }, ref) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [visible, setVisible] = useState(false);
  const onCancel = () => setVisible(false);

  useImperativeHandle(
    ref,
    () => ({
      openModal: ({ record }) => {
        setVisible(true);
        form.setFieldsValue(record);
      },
    }),
    [form],
  );

  const onUpdateCentreInformation = () => {
    form.validateFields().then((values) => {
      setLoading(true);
      dispatch(
        editCentreInformation({
          id,
          data: values,
        }),
      )
        .then(({ payload }) => {
          handleApiError(payload?.data, form);
          if (payload?.data) {
            dispatch(
              getByIdCentres({
                data: { id },
                options: {
                  isRequestApi: true,
                  prefixUrl: PREFIX_URL.superAdmin,
                },
              }),
            );
            onCancel();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <CentreModal
      title="centreDetail.titleCentreInformationModal"
      visible={visible}
      onCancel={onCancel}
      onOk={onUpdateCentreInformation}
      loading={loading}
      initialValue={{ ...record }}
    >
      <Form form={form}>
        <Form.Item
          rules={[{ required: true, message: i18next.t('error.required') }]}
          name="applicationFeeAmount"
          label={i18next.t('centreDetail.applicationWaitlistFee')}
        >
          <InputNumber
            placeholder={i18next.t('centreDetail.applicationWaitlistFee')}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              type: 'number',
              min: 1,
              message: i18next.t('input.approvedPlaces.validateMsg.invalid'),
            },
          ]}
          name="placeAllowed"
          label={i18next.t('centreDetail.approvedPlaces')}
        >
          <InputNumber placeholder={i18next.t('centreDetail.approvedPlaces')} />
        </Form.Item>
        <Form.Item
          name="shortDescription"
          label={i18next.t('centreDetail.centreDescription')}
        >
          <Input.TextArea
            placeholder={i18next.t('centreDetail.centreDescription')}
            rows={10}
          />
        </Form.Item>
      </Form>
    </CentreModal>
  );
});

CentreInfoModal.propTypes = {
  record: PropTypes.object,
};

export default CentreInfoModal;
