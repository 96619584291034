import styled from 'styled-components';

export const CentresModalStyles = styled.div`
  max-height: 500px;
  overflow: auto;
  .centre-item {
    padding: 15px 10px;
    border-radius: 8px;
    &:hover {
      background: ${({ theme }) => theme.background.clearBtn};
    }
  }

  .remove-icon {
    font-size: 20px;
  }
  .add-centres-user {
    display: flex;
    justify-content: space-between;
    .select-form-wrapper {
      flex: 1;
      margin-right: 20px;
    }
  }
`;
