import styled from 'styled-components';

export const KindiCareRatingStyles = styled.div`
  padding: 0 30px;
  .header-ratings {
    text-align: center;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .rating-value {
    font-size: 20px;
    box-shadow: 0px 8px 16px rgba(39, 40, 51, 0.16);
    border-radius: 8px;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
  }
  .good-text {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: ${({ theme }) => theme.text.mainL1};
  }

  .legend-summary {
    .legend-item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .dot-legend {
        height: 14px;
        width: 14px;
        margin-right: 5px;
        border-radius: 4px;
      }
      .dot-legend-avg {
        background: ${({ theme }) => theme.color.blueSecondary};
      }
      .dot-legend-service {
        background: ${({ theme }) => theme.palette.primary};
      }
      .name-legend {
        font-size: 14px;
      }
    }
  }

  .summary-message {
    text-align: center;
    font-size: 16px;
    line-height: 22px;
  }

  .footer-rating {
    text-align: center;
    .link-kindi-rating {
      color: ${({ theme }) => theme.color.blueSecondary};
      text-decoration: underline;
    }
  }
`;

export const SliderRatingStyles = styled.div`
  .ant-slider {
    margin: 50px 0 !important;
    .price-label {
      top: -40px;
      position: absolute;
      transform: translateX(-50%);
      left: 61.5385%;
    }
    .avg-label {
      transform: translateX(-50%);
      left: 55.3846%;
      top: 10px;
      position: absolute;
    }
    .ant-slider-handle {
      display: none;
    }
    .ant-slider-step {
      & > .ant-slider-dot:first-child,
      & > .ant-slider-dot:last-child {
        display: none;
      }
      .ant-slider-dot {
        height: 12px !important;
        width: 12px !important;
        top: -1px !important;
      }
    }
    .ant-slider-rail,
    .ant-slider-track {
      height: 10px !important;
      border-radius: 4px;
    }
    .ant-slider-track-1 {
      background-color: ${({ theme, isPriceLower }) =>
        isPriceLower
          ? theme.palette.primary
          : theme.color.blueSecondary} !important;
    }
    .ant-slider-track-2 {
      background-color: ${({ theme, isPriceLower }) =>
        isPriceLower
          ? theme.color.blueSecondary
          : theme.palette.primary} !important;
    }
  }
`;
