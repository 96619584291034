import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import I18n from 'i18next';
import { Tooltip } from 'antd';
import { getRecordData } from 'utils/tools';
import { disabledDate } from 'utils/formatFieldUtils';
import { RestInputContext } from '../RestInputContext';
import FormRangePicker from '../../form/FormRangePicker';

const RestRangePickerInput = ({
  sourceGt,
  sourceLt,
  isShowTooltip,
  placeholder,
  defaultValue,
  disabledDate,
  ...props
}) => {
  const { record, form } = useContext(RestInputContext);

  const getDefaultValue = () => {
    if (defaultValue) return defaultValue;

    const startTime = getRecordData(record, sourceGt);
    const endTime = getRecordData(record, sourceLt);
    return [
      startTime ? moment(startTime) : null,
      endTime ? moment(endTime) : null,
    ];
  };

  return (
    <Tooltip placement="top" title={isShowTooltip ? I18n.t(placeholder) : ''}>
      <div>
        <FormRangePicker
          form={form}
          disabledDate={disabledDate}
          defaultValue={getDefaultValue()}
          {...props}
        />
      </div>
    </Tooltip>
  );
};

RestRangePickerInput.propTypes = {
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sourceGt: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sourceLt: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  formatDate: PropTypes.string,
  placeholder: PropTypes.string,
  isShowTooltip: PropTypes.bool,
  defaultValue: PropTypes.array,
  disabledDate: PropTypes.func,
};

RestRangePickerInput.defaultProps = {
  source: 'rangeValue',
  sourceGt: 'startTime',
  sourceLt: 'endTime',
  disabledDate,
};

export default RestRangePickerInput;
