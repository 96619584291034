import React, { useContext, useEffect } from 'react';
import { Col, Row } from 'antd';
import RestInputItem from 'components/RestInput/RestInputItem';
import ReferenceInput from 'containers/rest/ReferenceInput';
import { PREFIX_URL } from 'configs/localData/constant';
import RestSelect from 'components/RestInput/RestSelect';
import { useSelector } from 'react-redux';
import { getRolesBelongToUser } from 'redux/config/selectors';
import { useLocation, useParams } from 'react-router';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { getFilterFromUrl } from 'utils/tools';
import { ACTIVE_TYPES, BLOCK_USER_TYPES } from 'configs/localData';
import i18next from 'i18next';
import RestRangePickerInput from 'components/RestInput/RestRangePickerInput';
import moment from 'moment';

const FilterForm = () => {
  const { id } = useParams();
  const { search } = useLocation();
  const { form } = useContext(RestInputContext);

  const roleBelongToUser = useSelector(getRolesBelongToUser);

  useEffect(() => {
    const { filter } = getFilterFromUrl(search) || {};
    const { createdAt, ...restFilter } = filter?.filter || {};
    form.setFieldsValue({
      createdAt: Object.values(createdAt || {}).map((item) => moment(item)),
      ...restFilter,
    });
  }, [search, form]);

  return (
    <Row gutter={16}>
      <Col xl={4} md={12} xs={12}>
        <RestInputItem
          source="userName"
          placeholder="users.userName"
          isShowTooltip
        />
      </Col>
      <Col xl={4} md={12} xs={12}>
        <RestInputItem
          source="email"
          placeholder="input.email.name"
          isShowTooltip
        />
      </Col>
      <Col xl={4} md={12} xs={12}>
        <RestSelect
          source="roleId"
          placeholder="users.role"
          resourceData={roleBelongToUser}
          valueProp="id"
          titleProp="description"
          isShowTooltip
        />
      </Col>
      <Col xl={4} md={12} xs={12}>
        <ReferenceInput
          prefixUrl={PREFIX_URL.superAdmin}
          source="centreId"
          resource={`companies/${id}/centresOfCompany`}
          searchKey="centreName"
          searchOutsideFilter
          initialFilter={{ orderBy: 'name' }}
        >
          <RestSelect
            source="centreId"
            placeholder="centres.centreName"
            isShowTooltip
            titleProp="name"
            valueProp="id"
            isFilterOption={false}
          />
        </ReferenceInput>
      </Col>
      <Col lg={4} md={12} xs={12}>
        <RestSelect
          source="isAllowedCRMApp"
          placeholder="users.CRMApp"
          resourceData={ACTIVE_TYPES}
          valueProp="value"
          titleProp="text"
          formatText={(text) => i18next.t(text)}
          isShowTooltip
          isShowSearch={false}
          ruleType="boolean"
        />
      </Col>
      <Col lg={4} md={12} xs={12}>
        <RestSelect
          source="isAccessInsightFeature"
          placeholder="users.InsightsModule"
          resourceData={ACTIVE_TYPES}
          valueProp="value"
          titleProp="text"
          formatText={(text) => i18next.t(text)}
          isShowTooltip
          isShowSearch={false}
          ruleType="boolean"
        />
      </Col>
      <Col lg={4} md={12} xs={12}>
        <RestSelect
          source="isActiveOnJobModule"
          placeholder="users.jobsModule"
          resourceData={ACTIVE_TYPES}
          valueProp="value"
          titleProp="text"
          formatText={(text) => i18next.t(text)}
          isShowTooltip
          isShowSearch={false}
          ruleType="boolean"
        />
      </Col>
      <Col lg={4} md={12} xs={12}>
        <RestSelect
          source="isBlocked"
          placeholder="users.blocked"
          resourceData={BLOCK_USER_TYPES}
          valueProp="value"
          titleProp="text"
          formatText={(text) => i18next.t(text)}
          isShowTooltip
          isShowSearch={false}
        />
      </Col>
      <Col lg={8} md={12} xs={12}>
        <RestRangePickerInput
          sourceGt="createdAt.from"
          sourceLt="createdAt.to"
          source="createdAt"
          placeholder="common.time"
          isShowTooltip
          isShowRangesFooter
        />
      </Col>
    </Row>
  );
};

export default FilterForm;
