import React from 'react';

import { Col, Form, Row } from 'antd';
import { useSelector } from 'react-redux';

import { getCentreTypesConst } from 'redux/config/selectors';

import ReferenceInput from 'containers/rest/ReferenceInput';
import { PREFIX_URL } from 'configs/localData/constant';
import RestSelect from 'components/RestInput/RestSelect';
import SEPRNumber from './SEPRNumber';

const CentreNumberCode = () => {
  const centreTypeConst = useSelector(getCentreTypesConst);

  const checkCentreTypeComingSoon = (type) =>
    type === centreTypeConst?.CENTRE_COMING_SOON;

  return (
    <Row gutter={16}>
      <Col md={12} xs={24}>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, curValues) =>
            prevValues.type !== curValues.type
          }
        >
          {({ getFieldValue }) => (
            <SEPRNumber
              isComingSoon={checkCentreTypeComingSoon(getFieldValue('type'))}
            />
          )}
        </Form.Item>
      </Col>
      <Col md={12} xs={24}>
        <ReferenceInput
          prefixUrl={PREFIX_URL.superAdmin}
          source="businessName"
          resource="providers"
        >
          <RestSelect
            titleProp="name"
            valueProp="name"
            header="providers.providerName"
            placeholder="providers.providerName"
            isFilterOption={false}
            disabled
          />
        </ReferenceInput>
      </Col>
    </Row>
  );
};

export default CentreNumberCode;
