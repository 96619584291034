import { unionBy } from 'lodash-es';
import { makeCRUDSlice } from 'redux/crudCreator';
import {
  MODEL_NAME,
  inboxesActions,
  getMessagesOfInbox,
  clearMessages,
  getInboxesOfCentre,
  clearInboxesListOfCentre,
  delInboxesOfCentre,
  getSummariesInboxes,
} from './actions';

const INITIAL_STATE_EXTRA = {
  resourceData: {
    filter: {},
    offset: 0,
    page: 1,
    limit: 10,
    total: 0,
    numberOfPages: 1,
    sort: '',
  },
  data: [],
};

const getMessagesOfInboxPending = (
  state,
  {
    meta: {
      arg: { data, options = {} },
    },
  },
) => {
  state.messagesLoading = true;

  if (options.isRefresh) {
    state.messages = {
      resourceData: {
        ...INITIAL_STATE_EXTRA.resourceData,
        ...data,
      },
      data: [],
    };
  } else {
    const resourceData = state.messages.resourceData || {};

    state.messages.resourceData = {
      ...resourceData,
      offset: resourceData.offset + resourceData.limit || 0,
      ...data,
    };
  }
};

const getMessagesOfInboxSuccess = (state, { payload: { data } }) => {
  const messagesData = state.messages.data;

  state.messages.data = unionBy(messagesData, data?.results, 'id');

  state.messages.resourceData = {
    ...state.messages.resourceData,
    total: data?.total || 0,
    numberOfPages: data.numberOfPages,
  };

  state.messagesLoading = false;
  state.error = null;
};

const getMessagesOfInboxFail = (state, { payload: { data } }) => {
  state.error = data;
  state.messagesLoading = false;
};

const getInboxesOfCentrePending = (
  state,
  {
    meta: {
      arg: { data, options = {} },
    },
  },
) => {
  state.inboxesOfCentreLoading = true;

  if (options.isRefresh) {
    state.inboxesOfCentre = {
      resourceData: {
        ...INITIAL_STATE_EXTRA.resourceData,
        ...data,
      },
      data: [],
    };
  } else {
    const resourceData = state.inboxesOfCentre.resourceData || {};

    state.inboxesOfCentre.resourceData = {
      ...resourceData,
      offset: resourceData.offset + resourceData.limit || 0,
      ...data,
    };
  }
};

const getInboxesOfCentreSuccess = (state, { payload: { data } }) => {
  const inboxesOfCentreData = state.inboxesOfCentre.data;

  state.inboxesOfCentre.data = unionBy(
    [...inboxesOfCentreData, ...data?.results],
    'id',
  );

  state.inboxesOfCentre.resourceData = {
    ...state.inboxesOfCentre.resourceData,
    total: data?.total || 0,
    numberOfPages: data.numberOfPages,
  };

  state.inboxesOfCentreLoading = false;
  state.error = null;
};

const getInboxesOfCentreFail = (state, { payload: { data } }) => {
  state.error = data;
  state.inboxesOfCentreLoading = false;
};

const clearInboxesListOfCentreSuccess = (state) => {
  state.inboxesOfCentre = INITIAL_STATE_EXTRA;
};

const delInboxesOfCentreSuccess = (state, { payload: { id } }) => {
  if (state.inboxesOfCentre?.data) {
    state.inboxesOfCentre.data = state.inboxesOfCentre.data?.filter(
      (item) => item.id !== id,
    );
  }
};

const getSummaryInboxesSuccess = (state, { payload }) => {
  state.summaries = payload;
};

const slice = makeCRUDSlice(MODEL_NAME, inboxesActions, {
  [getMessagesOfInbox.pending]: getMessagesOfInboxPending,
  [getMessagesOfInbox.fulfilled]: getMessagesOfInboxSuccess,
  [getMessagesOfInbox.rejected]: getMessagesOfInboxFail,

  [clearMessages]: (state) => {
    state.messages = INITIAL_STATE_EXTRA;
  },

  [getInboxesOfCentre.pending]: getInboxesOfCentrePending,
  [getInboxesOfCentre.fulfilled]: getInboxesOfCentreSuccess,
  [getInboxesOfCentre.rejected]: getInboxesOfCentreFail,
  [clearInboxesListOfCentre]: clearInboxesListOfCentreSuccess,

  [delInboxesOfCentre.fulfilled]: delInboxesOfCentreSuccess,

  [getSummariesInboxes.fulfilled]: getSummaryInboxesSuccess,
});

export default slice.reducer;
