import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Badge } from 'antd';
import { template } from 'lodash-es';
import { useSelector } from 'react-redux';
import AutomatedEdit from './AutomatedEdit';
import { useAutomatedMessage } from './context';

const AutomatedInfo = ({ centreName }) => {
  const currentData =
    useSelector((state) => state.automatedResponses.currentData) || {};

  const { setMessage } = useAutomatedMessage();
  const getCurrentMessage = currentData.data?.text;

  useEffect(() => {
    setMessage(getCurrentMessage);
  }, [currentData.id, getCurrentMessage, setMessage]);

  const description = useMemo(() => {
    try {
      const compiled = template(currentData.description);
      return compiled({ centre: { name: centreName } });
    } catch (error) {
      return null;
    }
  }, [currentData.description, centreName]);

  return (
    <div className="automated-info">
      <div className="automated__title">
        {currentData.name ||
          currentData.key ||
          i18next.t('error.waitingUpdate')}
      </div>

      {currentData?.isActive ? (
        <Badge status="success" text={i18next.t('common.on')} />
      ) : (
        <Badge status="error" text={i18next.t('common.off')} />
      )}

      <div className="automated-info__des">{description}</div>

      <AutomatedEdit item={currentData} />
    </div>
  );
};

AutomatedInfo.propTypes = {
  centreName: PropTypes.string,
};

export default AutomatedInfo;
