import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import ActivitiesNotesTable from './ActivitiesNotesTable';
import CreateModal from './CreateModal';
import HeaderList from './HeaderList';
import { ActivitiesNotesListStyles } from './styles';

const ActivitiesNotesList = ({ retrieveList, resourceFilter }) => {
  const createModalRef = useRef();

  const openCreateModal = () => {
    createModalRef.current && createModalRef.current.open();
  };

  return (
    <ActivitiesNotesListStyles>
      <HeaderList
        retrieveList={retrieveList}
        openCreateModal={openCreateModal}
      />

      <ActivitiesNotesTable
        retrieveList={retrieveList}
        resourceFilter={resourceFilter}
      />

      <CreateModal ref={createModalRef} />
    </ActivitiesNotesListStyles>
  );
};

ActivitiesNotesList.propTypes = {
  retrieveList: PropTypes.func,
  resourceFilter: PropTypes.object,
};

export default ActivitiesNotesList;
