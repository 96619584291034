import React from 'react';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestAvatarInput from '../../../../components/RestInput/RestAvatarInput';

const CompaniesForm = (props) => (
  <div {...props}>
    <div className="flex justify-center">
      <RestAvatarInput
        style={{
          width: 140,
          height: 140,
          borderRadius: '50%',
        }}
        className="avatar-section"
        defaultText="Logo"
        defaultIcon={null}
        cropDimension={{ width: 300, height: 300 }}
        hasCrop={false}
        source="logo"
      />
    </div>
    <RestInputItem
      source="name"
      header="companies.name"
      required
      placeholder="companies.name"
    />
  </div>
);

export default CompaniesForm;
