import React from 'react';
import { isEmpty } from 'lodash-es';
import Create from 'containers/rest/Create';
import i18next from 'i18next';
import Form from '../components/Form';

const ServiceTypesCreate = (props) => (
  <Create
    {...props}
    resource="serviceTypes"
    formatOnSubmit={({ externalId, icon, ...data }) => ({
      ...data,
      ...(!isEmpty(icon) && { icon: { key: icon.key, url: icon.url } }),
      externalId,
    })}
    defaultOptions={{ isTrimStr: true, isGetConfig: true }}
    header={i18next.t('button.addNewItem')}
    okText="button.save"
  >
    <Form />
  </Create>
);

export default ServiceTypesCreate;
