import { createSlice } from '@reduxjs/toolkit';
import {
  createCategories,
  delCategories,
  editCategories,
  getAllCategories,
} from './actions';
import {
  formatTreeDataCategories,
  formatTreeDataWhenAddNewCategory,
  formatTreeDataWhenDelCategory,
  formatTreeDataWhenEditCategory,
} from './providerData';

export const initialState = {
  treeData: [],
};

const getAllCategoriesPending = (state) => {
  state.loading = true;
};

const getAllCategoriesSuccess = (state, { payload }) => {
  state.loading = false;
  state.total = payload.total;
  state.treeData = formatTreeDataCategories(payload?.results);
};

const getAllCategoriesFail = (state, { payload }) => {
  state.loading = false;
  state.error = payload;
};

const createCategoriesSuccess = (state, { payload, meta }) => {
  const restData = [...state.treeData];
  state.treeData = formatTreeDataWhenAddNewCategory(
    payload,
    restData,
    meta?.arg?.parent,
  );
};

const editCategoriesSuccess = (state, { payload }) => {
  const restData = [...state.treeData];
  state.treeData = formatTreeDataWhenEditCategory(payload, restData);
};

const delCategoriesSuccess = (state, { payload }) => {
  const restData = [...state.treeData];
  state.treeData = formatTreeDataWhenDelCategory(payload, restData);
};

const { reducer } = createSlice({
  name: 'Categories',
  initialState,
  extraReducers: {
    [getAllCategories.pending]: getAllCategoriesPending,
    [getAllCategories.fulfilled]: getAllCategoriesSuccess,
    [getAllCategories.rejected]: getAllCategoriesFail,

    [createCategories.fulfilled]: createCategoriesSuccess,

    [editCategories.fulfilled]: editCategoriesSuccess,

    [delCategories.fulfilled]: delCategoriesSuccess,
  },
});

export default reducer;
