import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { isEmpty } from 'lodash-es';
import {
  clearCurrentNotification,
  getByIdNotification,
} from 'redux/notifications/actions';
import { PREFIX_URL_API } from 'configs/localData/constant';
import { Col, Row, Skeleton } from 'antd';
import PageTitle from 'components/common/PageTitle';
import Breadcrumb from 'components/common/Breadcrumb';
import { formatDateTime } from 'utils/textUtils';
import { formatNotificationStatus } from 'utils/formatFieldUtils';
import { getATagHref } from 'utils/tools';
import PreviewNoti from '../components/PreviewNoti';
import ShowStyles from './styles';
import { PreviewNotifyProvider } from '../components/PreviewNoti/context';

const NotificationsShow = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const currentData = useSelector((state) => state.notifications.currentData);

  const BREADCRUMB = [
    {
      path: '/notifications',
      title: i18next.t('notifications.header'),
    },
    {
      title: i18next.t('notifications.detail'),
    },
  ];

  useEffect(() => {
    dispatch(
      getByIdNotification({
        data: { id },
        options: { prefixUrl: PREFIX_URL_API, isRequestApi: true },
      }),
    );
  }, [id]); // eslint-disable-line

  useEffect(
    () => () => {
      dispatch(clearCurrentNotification());
    },
    [dispatch],
  );

  return (
    <PreviewNotifyProvider>
      <ShowStyles>
        <Breadcrumb data={BREADCRUMB} />
        <PageTitle>{i18next.t('notifications.detail')}</PageTitle>
        <Skeleton active loading={isEmpty(currentData)}>
          <div className="notification-detail-content">
            <Row>
              <Col xl={12} lg={24} md={24} xs={24}>
                <div className="notification-detail-content__title">
                  {i18next.t('notifications.notificationInfo')}
                </div>
                <div className="notification-item">
                  <div className="notification-item__label">
                    {i18next.t('notifications.title')}
                  </div>
                  <div className="notification-item__value notification-item__content-box">
                    {currentData?.title || i18next.t('error.waitingUpdate')}
                  </div>
                </div>

                <div className="notification-item">
                  <div className="notification-item__label">
                    {i18next.t('notifications.text')}
                  </div>
                  <div className="notification-item__value notification-item__content-box">
                    {currentData?.text || i18next.t('error.waitingUpdate')}
                  </div>
                </div>

                <div className="notification-item">
                  <div className="notification-item__label">
                    {i18next.t('notifications.externalUrl')}
                  </div>
                  <div className="notification-item__value notification-item__content-box">
                    {currentData?.externalUrl ? (
                      <a
                        href={getATagHref(currentData?.externalUrl)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {currentData?.externalUrl}
                      </a>
                    ) : (
                      i18next.t('error.waitingUpdate')
                    )}
                  </div>
                </div>

                <div className="notification-item">
                  <div className="notification-item__label">
                    {i18next.t('notifications.detail')}
                  </div>
                  <div
                    className="notification-item__value notification-item__content-box"
                    dangerouslySetInnerHTML={{
                      __html:
                        currentData?.htmlContent ||
                        i18next.t('error.waitingUpdate'),
                    }}
                  />
                </div>

                <div className="notification-item notification-item-line">
                  <div className="notification-item__label">
                    {i18next.t('notifications.status')}
                  </div>
                  <div className="notification-item__value">
                    {formatNotificationStatus(currentData?.status)}
                  </div>
                </div>

                <div className="notification-item notification-item-line">
                  <div className="notification-item__label">
                    {i18next.t('notifications.type')}
                  </div>
                  <div className="notification-item__value">
                    {currentData?.type?.name ||
                      i18next.t('error.waitingUpdate')}
                  </div>
                </div>

                <div className="notification-item notification-item-line">
                  <div className="notification-item__label">
                    {i18next.t('notifications.startSend')}
                  </div>
                  <div className="notification-item__value">
                    {formatDateTime(currentData?.startedAt) ||
                      i18next.t('error.waitingUpdate')}
                  </div>
                </div>

                <div className="notification-item notification-item-line">
                  <div className="notification-item__label">
                    {i18next.t('notifications.endAt')}
                  </div>
                  <div className="notification-item__value">
                    {formatDateTime(currentData?.endedAt) ||
                      i18next.t('error.waitingUpdate')}
                  </div>
                </div>

                <div className="notification-item notification-item-line">
                  <div className="notification-item__label">
                    {i18next.t('notifications.sends')}
                  </div>
                  <div className="notification-item__value">
                    {currentData?.sentCount || 0}
                  </div>
                </div>

                <div className="notification-item notification-item-line">
                  <div className="notification-item__label">
                    {i18next.t('notifications.opens')}
                  </div>
                  <div className="notification-item__value">
                    {currentData?.openedCount || 0}
                  </div>
                </div>
              </Col>

              <Col xl={12} lg={24} md={24} xs={24}>
                <PreviewNoti />
              </Col>
            </Row>
          </div>
        </Skeleton>
      </ShowStyles>
    </PreviewNotifyProvider>
  );
};

export default NotificationsShow;
