import React from 'react';
import { Tabs } from 'antd';
import i18n from 'i18next';
import BrandList from 'containers/Brands/List';

const { TabPane } = Tabs;

const BrandCompany = () => (
  <Tabs destroyInactiveTabPane defaultActiveKey="brands">
    <TabPane tab={i18n.t('brands.title')} key="brands">
      <BrandList />
    </TabPane>
  </Tabs>
);

export default BrandCompany;
