import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { PlusCircleFilled, UserOutlined } from '@ant-design/icons';
import Avatar from 'antd/lib/avatar';
import Popover from 'antd/lib/popover';

import { RestInputContext } from 'components/RestInput/RestInputContext';
import AssigneePopover from '../AssigneePopover';

const AddAssignee = ({ onChange }) => {
  const assigneeUser = useContext(RestInputContext).item?.assigneeUser;

  const [visible, setVisible] = useState(false);
  const [assigneeSelected, setAssigneeSelected] = useState(assigneeUser);

  const handleSelectedAssignee = (item) => {
    onChange(item.id);
    setAssigneeSelected(item);
    setVisible(false);
  };

  return (
    <div className="add-assignee-wrapper flex">
      <Popover
        placement="bottomLeft"
        content={
          <AssigneePopover handleSelectedAssignee={handleSelectedAssignee} />
        }
        trigger="click"
        overlayStyle={{ zIndex: 1000 }}
        visible={visible}
        onVisibleChange={(e) => setVisible(e)}
      >
        <div className="add-assignee cursor-pointer">
          <div className="add-assignee__avatar-wrapper">
            <Avatar
              src={assigneeSelected?.avatar}
              size={40}
              icon={<UserOutlined />}
            />
            <PlusCircleFilled className="add-assignee__add-icon" />
          </div>
          {assigneeSelected?.id ? (
            <div className="add-assignee__name">
              <div className="fw-600">{`${assigneeSelected?.firstName} ${assigneeSelected?.lastName}`}</div>
              <div className="text-secondary ellipsis-t text-12">
                {assigneeSelected?.email}
              </div>
            </div>
          ) : (
            <div className="text-uppercase fw-600 add-assignee__no-assignee">
              {i18next.t('error.noAssignee')}
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
};

AddAssignee.propTypes = {
  onChange: PropTypes.func,
};

export default AddAssignee;
