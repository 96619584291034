import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';
import Space from 'antd/lib/space';
import Modal from 'antd/lib/modal';

import { EditIcon } from 'components/common/SVGIcon';
import i18next from 'i18next';
import { delEmailTemplate } from 'redux/EDM/actions';

const ActionGroup = ({
  templateKey,
  isSystem,
  isDesign,
  clearCurrentTemplate,
  openEditModal,
}) => {
  const { push } = useHistory();
  const { model } = useParams();

  const dispatch = useDispatch();

  const handleClickEdit = (e) => {
    e.stopPropagation();
    if (isDesign) {
      push(`/EDM/${model}/design/${templateKey}/edit`);
    } else {
      openEditModal();
    }
  };

  const handleClickDel = (e) => {
    e.stopPropagation();
    Modal.confirm({
      title: i18next.t('EDM.confirmTitleDelete'),
      content: i18next.t('EDM.confirmContentDelete'),
      onOk() {
        return dispatch(delEmailTemplate(templateKey)).then(() => {
          clearCurrentTemplate();
        });
      },
    });
  };

  return (
    <div className="flex justify-end template-item__actions">
      <Space size={8}>
        <Button
          onClick={handleClickDel}
          icon={<DeleteOutlined />}
          type="primary"
          shape="circle"
          disabled={isSystem}
        />
        <Button
          onClick={handleClickEdit}
          icon={<EditIcon />}
          type="primary"
          shape="circle"
        />
      </Space>
    </div>
  );
};

ActionGroup.propTypes = {
  isDesign: PropTypes.bool,
  templateKey: PropTypes.string,
  clearCurrentTemplate: PropTypes.func,
  openEditModal: PropTypes.func,
  isSystem: PropTypes.bool,
};

export default ActionGroup;
