import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import TemplateList from './TemplateList';
import { FavoritesStyles } from './styles';
import EmailPreview from './EmailPreview';

const FavoritesTab = ({ initialFilter, tab }) => (
  <FavoritesStyles>
    <Row gutter={[20, 20]}>
      <Col xxl={10} xl={8} lg={24} md={24} xs={24}>
        <TemplateList initialFilter={initialFilter} tab={tab} />
      </Col>
      <Col xxl={14} xl={16} lg={24} md={24} xs={24}>
        <EmailPreview />
      </Col>
    </Row>
  </FavoritesStyles>
);

FavoritesTab.propTypes = {
  initialFilter: PropTypes.object,
  tab: PropTypes.string,
};

export default FavoritesTab;
