import styled from 'styled-components';

export const ServiceAppliedTableStyled = styled.div`
  width: 180px;
  .ant-space {
    max-width: 100%;
  }
  .service-item-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
    color: #316dde;
    background: rgba(50, 164, 252, 0.2);
    border-radius: 8px;
    padding: 5px 10px;
    max-width: 100%;
    display: inline-block;
  }
`;
