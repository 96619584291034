import { PREFIX_API_VER_1, PREFIX_URL_API } from 'configs/localData/constant';
import { get, post, put, del } from './utils';

export async function getEmailTemplateApi(data) {
  return get('/email-templates', data, {}, PREFIX_URL_API);
}

export async function getByKeyEmailTemplateApi(key) {
  return get(`/email-templates/keys/${key}`, null, {}, PREFIX_URL_API);
}

export async function postEmailTemplateApi(data) {
  return post('/email-templates', data, {}, PREFIX_URL_API);
}

export async function putEmailTemplateApi(key, data) {
  return put(`/email-templates/keys/${key}`, data, {}, PREFIX_URL_API);
}

export async function delEmailTemplateApi(key) {
  return del(`/email-templates/keys/${key}`, null, {}, PREFIX_URL_API);
}

export async function sendIndividualEmailApi(data) {
  return post('/manual-emails/send-email', data, {}, PREFIX_URL_API);
}

export async function getClaimStatusApi(data) {
  return get('/centres/claim-status-summary', data, {}, PREFIX_URL_API);
}

export async function sendEmailGroupCentresApi(data) {
  return post(
    '/manual-emails/send-multi-email',
    data,
    {},
    PREFIX_URL_API,
    PREFIX_API_VER_1,
    {
      isDataQuery: true,
    },
  );
}

export async function sendEmailIndividualContactApi(data) {
  return post('/manual-emails/business-user-email', data, {}, PREFIX_URL_API);
}

export const getOptionEmailTemplateApi = (centreId, key) =>
  get(`/centres/${centreId}/email-options/${key}`, null, {}, PREFIX_URL_API);
