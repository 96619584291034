import React from 'react';
import Create from 'containers/rest/Create';
import { PREFIX_URL_API } from 'configs/localData/constant';
import Form from '../components/Form';

const SuburbsCreate = (props) => (
  <Create
    {...props}
    defaultOptions={{ prefixUrl: PREFIX_URL_API }}
    resource="suburbs"
  >
    <Form />
  </Create>
);

export default SuburbsCreate;
