import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSearchStatisticsApi } from 'api/statistic';
import { convertRequestParams } from 'redux/crudCreator/dataProvider';
import { apiWrapper } from 'utils/reduxUtils';

export const getSearchStatistics = createAsyncThunk(
  'searchStatistics/getSearchStatistics',
  async (payload = {}, thunkApi) => {
    try {
      const { limit, offset, filter, orderBy } =
        thunkApi.getState().searchStatistics?.resourceData || {};

      const { data } = payload;
      const convertRequest = convertRequestParams(
        'GET_ALL',
        {
          limit,
          offset,
          filter,
          orderBy,
          ...data,
        },
        'searchStatistics',
        payload.options,
      );

      const response = await apiWrapper(
        {},
        getSearchStatisticsApi,
        convertRequest,
      );

      return {
        data: {
          numberOfPages: Math.ceil(response.total / limit),
          ...response,
        },
        options: payload.options,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ id: payload.data.id, data: error });
    }
  },
);
