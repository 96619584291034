import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { ROLES_CONST } from 'configs/localData/permissions';
import RestInputItem from 'components/RestInput/RestInputItem';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import GeneralUserForm from './GeneralUserForm';
import FormStyles from './styles';
import RoleForm from './RoleForm';

const UsersForm = ({ isEdit }) => {
  const { record } = useContext(RestInputContext);
  return (
    <FormStyles>
      <GeneralUserForm isEdit={isEdit} />
      {record?.role?.name === ROLES_CONST.customer ? (
        <div className="d-none">
          <RestInputItem source="roleId" />
        </div>
      ) : (
        <RoleForm />
      )}
    </FormStyles>
  );
};

UsersForm.propTypes = {
  isEdit: PropTypes.bool,
};

export default UsersForm;
