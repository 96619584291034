import React from 'react';
import { useSelector } from 'react-redux';
import TitleTabCount from './TitleTabCount';

const TasksTitleTab = () => {
  const count = useSelector((state) => state.tasks.summaries?.count);

  return <TitleTabCount title="tasks.title" count={count} />;
};

export default TasksTitleTab;
