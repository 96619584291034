import React from 'react';
import { Switch } from 'antd';
import RestInputItem from 'components/RestInput/RestInputItem';
import PropTypes from 'prop-types';
import { formatLabelTable } from 'utils/textUtils';
import { LABEL_TABLE_KEY } from 'configs/localData/constant';
import { useSelector } from 'react-redux';

const SessionTypesForm = ({ isEdit, ...props }) => {
  const appSettings = useSelector((state) => state.config?.data?.AppSetting);

  return (
    <div {...props}>
      <RestInputItem
        required
        source="name"
        header={formatLabelTable(LABEL_TABLE_KEY.sessionType, appSettings)}
        placeholder={formatLabelTable(LABEL_TABLE_KEY.sessionType, appSettings)}
        isValidatorEmpty
      />

      {isEdit && (
        <RestInputItem
          source="isActive"
          header="sessionTypes.isActive"
          placeholder="sessionTypes.isActive"
          ContentComponent={Switch}
          ruleType="boolean"
          valuePropName="checked"
        />
      )}
    </div>
  );
};

SessionTypesForm.propTypes = {
  isEdit: PropTypes.bool,
};

export default SessionTypesForm;
