import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { getAllTasks } from 'redux/tasks/actions';
import { PREFIX_URL_API } from 'configs/localData/constant';
import { tasksSelectors } from 'redux/tasks/selectors';
import TaskList from './TaskList';
import TaskDetail from './TaskDetail';

const TasksTab = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const resourceFilter = useSelector(tasksSelectors.getFilters);

  const retrieveList = (filter = {}) => {
    dispatch(
      getAllTasks({
        data: {
          ...resourceFilter,
          ...filter,
          filter: {
            ...resourceFilter.filter,
            providerId: id,
            centreId: {
              $exists: false,
            },
            ...filter.filter,
          },
        },
        options: { prefixUrl: PREFIX_URL_API, isRefresh: true },
      }),
    );
  };

  useEffect(() => {
    retrieveList();
  }, [id]); // eslint-disable-line

  return (
    <div>
      <Row gutter={[24, 24]}>
        <Col xl={17} lg={24} md={24} xs={24}>
          <TaskList
            retrieveList={retrieveList}
            resourceFilter={resourceFilter}
          />
        </Col>
        <Col xl={7} lg={24} md={24} xs={24}>
          <TaskDetail />
        </Col>
      </Row>
    </div>
  );
};

export default TasksTab;
