import { createSlice } from '@reduxjs/toolkit';
import {
  getAllJobApplicationsTrackerData,
  getJobApplicationsTrackerConfig,
  MODEL_NAME,
} from 'redux/jobApplicationsTracker/actions';

export const initialState = {
  data: null,
  isLoading: false,
  error: false,
  config: null,
  resourceData: {
    filter: {},
    page: 1,
    size: 10,
    total: 0,
  },
};

const getAllJobApplicationsTrackerDataPending = (
  state,
  {
    meta: {
      arg: { data, options = {} },
    },
  },
) => {
  state.isLoading = true;
  if (options.isRefresh) {
    state.data = [];
    state.resourceData = {
      ...initialState.resourceData,
      ...data,
    };
  }
};

const getAllJobApplicationsTrackerDataFulfilled = (state, { payload }) => {
  const { paging, ...restPayload } = payload;
  const { page, size, total } = paging;
  state.isLoading = false;
  state.data = restPayload;
  state.resourceData = {
    ...initialState.resourceData,
    page,
    size,
    total,
  };
};

const getAllJobApplicationsTrackerDataRejected = (
  state,
  { payload: { data } },
) => {
  state.isLoading = false;
  state.error = data;
};

const getConfigFulfilled = (state, { payload: { result } }) => {
  state.config = result;
};

const { actions, reducer } = createSlice({
  name: MODEL_NAME,
  initialState,
  reducers: {
    resetResourceFilter: (state) => {
      state.resourceData = initialState.resourceData;
    },
  },
  extraReducers: {
    [getAllJobApplicationsTrackerData.pending]:
      getAllJobApplicationsTrackerDataPending,
    [getAllJobApplicationsTrackerData.fulfilled]:
      getAllJobApplicationsTrackerDataFulfilled,
    [getAllJobApplicationsTrackerData.rejected]:
      getAllJobApplicationsTrackerDataRejected,

    [getJobApplicationsTrackerConfig.fulfilled]: getConfigFulfilled,
  },
});

export const { resetResourceFilter } = actions;

export default reducer;
