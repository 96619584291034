import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import { formatMoney } from 'utils/textUtils';
import TooltipStyles from './styles';

const TooltipChart = ({
  active,
  payload,
  dataChart,
  isShowTitle,
  formatValue,
  legendTypeTooltip,
}) =>
  active ? (
    <TooltipStyles>
      {isShowTitle && (
        <>
          <div className="title-tooltip">
            {payload?.[0]?.payload?.nameNote || payload?.[0]?.payload?.name}
          </div>
          <Divider />
        </>
      )}
      <div className="content-tooltip">
        {payload?.map((item, i) => (
          <div className="tooltip-item flex-center-between" key={String(i)}>
            <div className="flex items-center">
              <div
                className={`${legendTypeTooltip}-tooltip`}
                style={{
                  backgroundColor:
                    item?.payload?.fill ||
                    item?.color ||
                    dataChart?.find((chart) => chart.value === item.dataKey)
                      ?.fillColor,
                }}
              />
              <div className="tooltip-item__name">{item.name}</div>
            </div>

            <div className="tooltip-item__value">
              <strong className="strong-value">
                {formatValue(item.value, item)}
              </strong>
            </div>
          </div>
        ))}
      </div>
    </TooltipStyles>
  ) : null;

TooltipChart.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  dataChart: PropTypes.array,
  isShowTitle: PropTypes.bool,
  formatValue: PropTypes.func,
  legendTypeTooltip: PropTypes.oneOf(['circle', 'rectangle', 'line']),
};

TooltipChart.defaultProps = {
  isShowTitle: true,
  formatValue: (value) => formatMoney(value),
  legendTypeTooltip: 'circle',
};

export default TooltipChart;
