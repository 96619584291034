import React from 'react';
import { Checkbox, Modal } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { editInboxes } from 'redux/inboxes/actions';
import { useDispatch } from 'react-redux';
import { PREFIX_URL_API } from 'configs/localData/constant';

const { confirm } = Modal;

const MarkContactAction = ({ isContacted, id }) => {
  const dispatch = useDispatch();

  const showConfirm = (e) => {
    confirm({
      title: i18next.t('inboxes.titleMarkedContacted'),
      content: i18next.t(
        e.target.checked
          ? 'inboxes.contentMarkedContacted'
          : 'inboxes.contentUnMarkedContacted',
      ),
      onOk() {
        return new Promise((resolve) => {
          if (id) {
            resolve(
              dispatch(
                editInboxes({
                  data: { id, isContacted: e.target.checked },
                  options: { prefixUrl: PREFIX_URL_API },
                }),
              ),
            );
          }
        });
      },
    });
  };
  return <Checkbox checked={isContacted} onChange={showConfirm} />;
};

MarkContactAction.propTypes = {
  isContacted: PropTypes.bool,
  id: PropTypes.string,
};

export default MarkContactAction;
