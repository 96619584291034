import SeeMore from 'components/common/SeeMore';
import StatisticItem from 'components/common/StatisticItem';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  getDataSearchStatistics,
  getLoadingSearchStatistics,
} from 'redux/searchStatistics/selector';
import { useHistory } from 'react-router';
import { Skeleton } from 'antd';

const SearchBySuburb = () => {
  const { push } = useHistory();

  const data = useSelector(getDataSearchStatistics);
  const loading = useSelector(getLoadingSearchStatistics);

  const directToSearchSuburb = () => {
    push('/searchStatistics/searchSuburb');
  };
  return (
    <Skeleton loading={loading}>
      {data?.map((item, key) => (
        <StatisticItem
          key={String(key)}
          searchName={`${item?.suburb}, ${item?.state}, ${item?.postcode}`}
          percent={item?.percent}
          count={item?.count}
        />
      ))}
      <SeeMore onClick={directToSearchSuburb} />
    </Skeleton>
  );
};

export default SearchBySuburb;
