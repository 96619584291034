import React from 'react';
import Edit from 'containers/rest/Edit';
import i18next from 'i18next';
import { PREFIX_URL } from 'configs/localData/constant';
import Form from '../components/Form';
import { CreateEditStyles } from '../styles';

const UsersEdit = (props) => {
  const breadcrumbCustom = [
    {
      title: i18next.t('users.header'),
      path: '/users',
    },
    {
      title: i18next.t('users.editPage'),
    },
  ];

  return (
    <CreateEditStyles>
      <Edit
        {...props}
        resource="users"
        breadcrumbCustom={breadcrumbCustom}
        defaultOptions={{ prefixUrl: PREFIX_URL.superAdmin }}
        isResetStyle
      >
        <div>
          <div className="create-user-title">
            {i18next.t('users.editUserInformation')}
          </div>
          <Form isEdit />
        </div>
      </Edit>
    </CreateEditStyles>
  );
};

export default UsersEdit;
