import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  SCHOOL_TERM_DAYS_OF_WEEKEND,
  SCHOOL_TERM_EXCEPT_WEEKEND,
  SCHOOL_TERM_MON_TO_FRI,
} from 'configs/localData';
import { Row, Col, Checkbox } from 'antd';
import i18next from 'i18next';

import CardHourSchool from '../CardHourSchool';
import { responsiveColHour } from '../utils';

const SchoolHourForm = ({ openTime, isDifferent, isEdit }) => {
  const [different, setDifferent] = useState(isDifferent);

  useEffect(() => {
    setDifferent(isDifferent);
  }, [isDifferent]);

  const onChange = (e) => {
    setDifferent(e.target.checked);
  };

  return (
    <div className="hour-section">
      {isEdit && (
        <Checkbox
          onChange={onChange}
          checked={different}
          className="hour-section__checkbox-different"
        >
          <span className="hour-section__checkbox-different__bold">
            {i18next.t('daysOfWeek.different')}
          </span>{' '}
          <span className="hour-section__checkbox-different__light">
            {i18next.t('daysOfWeek.descriptionDifferent')}
          </span>
        </Checkbox>
      )}
      <Row gutter={[24, 24]} style={{ marginBottom: 20 }}>
        {different ? (
          SCHOOL_TERM_EXCEPT_WEEKEND.map((item) => (
            <Col {...responsiveColHour} key={item.value}>
              <CardHourSchool
                isEdit={isEdit}
                title={item.text}
                data={item.data}
                openTime={openTime}
              />
            </Col>
          ))
        ) : (
          <Col {...responsiveColHour}>
            <CardHourSchool
              isEdit={isEdit}
              title={SCHOOL_TERM_MON_TO_FRI.text}
              data={SCHOOL_TERM_MON_TO_FRI.data}
              openTime={openTime}
            />
          </Col>
        )}
        {SCHOOL_TERM_DAYS_OF_WEEKEND.map((item) => (
          <Col {...responsiveColHour} key={item.value}>
            <CardHourSchool
              isEdit={isEdit}
              title={item.text}
              data={item.data}
              openTime={openTime}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

SchoolHourForm.propTypes = {
  openTime: PropTypes.object,
  isDifferent: PropTypes.bool,
  isEdit: PropTypes.bool,
};

SchoolHourForm.defaultProps = {
  openTime: {},
};

export default SchoolHourForm;
