import {
  ROLES_BELONG_TO_BUSINESS,
  ROLE_FOR_PROVIDER_USER,
} from 'configs/localData/permissions';
import { createSelector } from 'reselect';

const getRawConfigName = (state, name) => state.config[name];
const getRawServiceAgeGroups = (state) => state.config?.data?.serviceAgeGroup;
const getRawServiceTypes = (state) => state.config?.data?.ServiceType;
const getRawSessionTypes = (state) => state.config.data?.sessionTypes;
const getRawCentreTypes = (state) => state.config?.data?.CentreType;
const getRawChildcareServicesTypes = (state) =>
  state.config?.data?.primaryChildCareService;
const getRawNotificationStatus = (state) =>
  state.config?.data?.notificationStatus;
const getRawRolesArr = (state) => state.config.data?.role;
const getRawSaleStages = (state) => state.config?.data?.salesStage;

export const getSaleStages = createSelector(
  [getRawSaleStages],
  (config) => config || [],
);

export const getConfigByName = createSelector(
  [getRawConfigName],
  (config) => config || [],
);

export const getServiceAgeGroups = createSelector(
  [getRawServiceAgeGroups],
  (data) => data || [],
);

export const getServiceTypes = createSelector(
  [getRawServiceTypes],
  (data) => data || [],
);

export const getSessionTypes = createSelector(
  [getRawSessionTypes],
  (data) => data || [],
);

export const getCentreTypes = createSelector([getRawCentreTypes], (data) =>
  Object.values(data || {}),
);

export const getPrimaryChildCareService = createSelector(
  [getRawChildcareServicesTypes],
  (data) => Object.values(data || {}),
);

export const getCentreTypesConst = createSelector(
  [getRawCentreTypes],
  (data) => data,
);

export const getNotificationStatus = createSelector(
  [getRawNotificationStatus],
  (data) => Object.values(data || {}),
);

export const getRolesBelongToUser = createSelector([getRawRolesArr], (data) =>
  data?.filter((item) => ROLES_BELONG_TO_BUSINESS?.includes(item.name)),
);

export const getRoleForProviderUser = createSelector([getRawRolesArr], (data) =>
  data?.filter((item) => ROLE_FOR_PROVIDER_USER?.includes(item.name)),
);
