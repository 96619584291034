import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import EditButton from 'components/RestActions/EditButton';

const GroupActions = ({ id }) => {
  const { push } = useHistory();

  const openProviderEdit = () => {
    push({ hash: `#providers/${id}/edit` });
  };

  return (
    <div className="flex">
      <EditButton
        isView
        source="show"
        onClickCustom={() => push(`/providers/${id}/show/summary`)}
      />
      <EditButton onClickCustom={openProviderEdit} />
    </div>
  );
};

GroupActions.propTypes = {
  id: PropTypes.string,
};

export default GroupActions;
