import React from 'react';
import PropTypes from 'prop-types';
import RestAvatarInput from 'components/RestInput/RestAvatarInput';
import CompanyInfoForm from './CompanyInfoForm';
import PrimaryContact from './PrimaryContact';

const CompaniesForm = ({ ...props }) => (
  <div {...props}>
    <div className="flex-center mb-32">
      <RestAvatarInput
        defaultText="Logo"
        defaultIcon={null}
        cropDimension={{ width: 300, height: 300 }}
        hasCrop={false}
        source="logo"
        style={{
          marginBottom: 20,
          width: 150,
          height: 150,
          borderRadius: '50%',
        }}
      />
    </div>

    <CompanyInfoForm />
    <PrimaryContact />
  </div>
);

CompaniesForm.propTypes = {
  isEdit: PropTypes.bool,
};

export default CompaniesForm;
