import React from 'react';
import Edit from 'containers/rest/Edit';
import { LABEL_TABLE_KEY, PREFIX_URL_API } from 'configs/localData/constant';
import i18next from 'i18next';
import { formatLabelTable } from 'utils/textUtils';
import { useSelector } from 'react-redux';
import Form from '../components/Form';

const SessionTypesEdit = (props) => {
  const appSettings = useSelector((state) => state.config?.data?.AppSetting);

  return (
    <Edit
      {...props}
      resource="sessionTypes"
      defaultOptions={{
        isTrimStr: true,
        isGetConfig: true,
        prefixUrl: PREFIX_URL_API,
        customApiResource: 'session-types',
      }}
      header={`${i18next.t('button.edit')} ${formatLabelTable(
        LABEL_TABLE_KEY.sessionType,
        appSettings,
      )}`}
    >
      <Form isEdit />
    </Edit>
  );
};

export default SessionTypesEdit;
