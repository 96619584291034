import React, { useMemo } from 'react';
import RestAvatarInput from 'components/RestInput/RestAvatarInput';
import { USER_TYPES_CONSTANT } from 'configs/localData';
import RestInputItem from 'components/RestInput/RestInputItem';
import ReferenceInput from 'containers/rest/ReferenceInput';
import { PREFIX_URL } from 'configs/localData/constant';
import RestSelect from 'components/RestInput/RestSelect';
import { validateRegex } from 'utils/validateUtils';
import i18next from 'i18next';
import { useSelector } from 'react-redux';

const CreateProviderForm = () => {
  const filterUser = useMemo(
    () => ({
      orderBy: 'firstName,lastName',
      filter: { userType: USER_TYPES_CONSTANT.business.value },
    }),
    [],
  );

  const currentData = useSelector((state) => state.companies.currentData) || {};

  return (
    <div>
      <div className="flex-center mb-32">
        <RestAvatarInput
          defaultText="Logo"
          defaultIcon={null}
          cropDimension={{ width: 300, height: 300 }}
          hasCrop={false}
          source="logo"
          style={{
            marginBottom: 20,
            width: 150,
            height: 150,
            borderRadius: '50%',
          }}
        />
      </div>

      <RestInputItem
        required
        source="name"
        header="providers.providerName"
        placeholder="providers.providerName"
        isValidatorEmpty
        maxLength={255}
      />
      <ReferenceInput
        prefixUrl={PREFIX_URL.superAdmin}
        source="companyId"
        resource="companies"
        searchKey="company.name"
      >
        <RestSelect
          titleProp="name"
          valueProp="id"
          header="centres.company"
          placeholder="input.company.placeholder"
          isFilterOption={false}
          curData={currentData}
        />
      </ReferenceInput>
      <RestInputItem
        required
        source="governmentReferenceId"
        header="providers.PRNumber"
        placeholder="providers.PRNumber"
        isValidatorEmpty
        maxLength={255}
        rules={[
          {
            pattern: validateRegex.PRNumber,
            message: i18next.t('input.PRNumber.validateMsg.invalid'),
          },
        ]}
      />
      <ReferenceInput
        prefixUrl={PREFIX_URL.superAdmin}
        source="userId"
        resource="users"
        initialFilter={filterUser}
        searchKey="q"
      >
        <RestSelect
          header="providers.businessUser"
          titleProp="firstName"
          valueProp="id"
          placeholder="input.businessUser.placeholder"
          formatText={(name, record) =>
            `${name || ''} ${record.lastName || ''}
          `
          }
          isFilterOption={false}
        />
      </ReferenceInput>
      <ReferenceInput
        prefixUrl={PREFIX_URL.superAdmin}
        source="userId"
        resource="users"
        initialFilter={filterUser}
      >
        <RestSelect
          disabled
          titleProp="email"
          valueProp="id"
          header="input.email.name"
          placeholder="input.email.name"
        />
      </ReferenceInput>
      <ReferenceInput
        prefixUrl={PREFIX_URL.superAdmin}
        source="userId"
        resource="users"
        initialFilter={filterUser}
      >
        <RestSelect
          disabled
          titleProp="phoneNumber"
          valueProp="id"
          header="input.phoneNumber.name"
          placeholder="input.phoneNumber.name"
          formatText={(phoneNumber) =>
            `${phoneNumber || ''} 
          `
          }
        />
      </ReferenceInput>
    </div>
  );
};

export default CreateProviderForm;
