import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { makeBreadCrumbFromPath } from 'utils/tools';
import i18next from 'i18next';
import { isEmpty } from 'lodash-es';
import { Spin } from 'antd';

import CustomBreadcrumb from '../../common/Breadcrumb';
import ButtonEdit from '../../RestActions/EditButton';
import DeleteButton from '../../RestActions/DeleteButton';
import Layout from '../../common/Layout';
import Box from '../../common/Box';
import ActionView from '../ActionLayout';
import PageTitle from '../../common/PageTitle';
import ShowStyles, { ContentShowStyles } from './styles';

const RestShowComponent = ({
  noCardWrapper,
  noActions,
  deleteItem,
  gotoEditPage,
  record,
  resource,
  children,
  hasEdit,
  hasDel,
  header,
  customBreadcrumb,
  extraAction,
  isResetStyle,
}) => {
  const location = useLocation();
  const BREADCRUMB_LIST = makeBreadCrumbFromPath(location);

  if (isEmpty(record))
    return (
      <div className="flex-center w-full">
        <Spin />
      </div>
    );

  const actions = (
    <div>
      {hasEdit && (
        <ButtonEdit
          resource={resource}
          record={record}
          gotoEditPage={gotoEditPage}
        />
      )}
      {hasDel && (
        <DeleteButton
          resource={resource}
          record={record}
          deleteItem={deleteItem}
        />
      )}
    </div>
  );

  const components = React.Children.map(children, (element) =>
    React.cloneElement(element, { key: element.props.source, record }),
  );

  const content = (
    <ContentShowStyles className="content-detail-show">
      {!noActions && <ActionView>{actions}</ActionView>}
      {components}
    </ContentShowStyles>
  );

  return noCardWrapper ? (
    content
  ) : (
    <ShowStyles>
      <Layout bordered={false} extra={actions}>
        <PageTitle {...(extraAction && { extraAction })}>
          <CustomBreadcrumb data={customBreadcrumb || BREADCRUMB_LIST} />
          {header && <div className="header-title">{i18next.t(header)}</div>}
        </PageTitle>
        <Box isResetStyle={isResetStyle} className="box-detail-show">
          {content}
        </Box>
      </Layout>
    </ShowStyles>
  );
};

RestShowComponent.propTypes = {
  children: PropTypes.node,
  record: PropTypes.object,
  noCardWrapper: PropTypes.bool,
  deleteItem: PropTypes.func,
  gotoEditPage: PropTypes.func,
  resource: PropTypes.string,
  hasEdit: PropTypes.bool,
  hasDel: PropTypes.bool,
  header: PropTypes.any,
  noActions: PropTypes.bool,
  customBreadcrumb: PropTypes.array,
  extraAction: PropTypes.node,
  isResetStyle: PropTypes.bool,
};

RestShowComponent.defaultProps = {
  noCardWrapper: false,
};
export default RestShowComponent;
