import React, { useEffect } from 'react';
import i18next from 'i18next';
import TableCustom from 'components/common/TableCustom';
import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import {
  getDataSearchStatistics,
  getLoadingSearchStatistics,
  getResourceDataSearchStatistics,
} from 'redux/searchStatistics/selector';
import { useLocation } from 'react-router';
import { getFilterFromUrl } from 'utils/tools';
import { eventTime } from '../../../Events/index';

const PostCodeList = ({ retrieveList, pushQuery }) => {
  const data = useSelector(getDataSearchStatistics);

  const loading = useSelector(getLoadingSearchStatistics);
  const resourceFilter = useSelector(getResourceDataSearchStatistics) || {};
  const { search } = useLocation();
  const { offset, limit } = getFilterFromUrl(search);
  useEffect(() => {
    retrieveList({
      filter: {
        limit,
        offset,
        filter: {
          eventName: 'postcode',
          eventTime,
        },
      },
      isRefresh: true,
    });
  }, [limit, offset, retrieveList]);

  const columns = [
    {
      title: i18next.t('#'),
      dataIndex: 'index',
      width: 100,
    },
    {
      title: i18next.t('searchStatistics.postcode'),
      dataIndex: 'postcode',
    },
    {
      title: i18next.t('searchStatistics.search'),
      dataIndex: 'count',
    },
  ];

  const onChange = (e, filters, sorter) => {
    const formatSort =
      sorter && sorter.field && sorter.order
        ? `${sorter.order === 'descend' ? '-' : ''}${sorter.field}`
        : null;

    const restFilter = {
      offset: (e.current - 1) * e.pageSize,
      limit: e.pageSize,
      orderBy: formatSort,
      filter: resourceFilter.filter,
      outsideFilter: resourceFilter.outsideFilter,
    };

    retrieveList({
      filter: restFilter,
      isRefresh: true,
    });
    pushQuery(restFilter);
  };
  return (
    <TableCustom
      isSorter
      onChange={onChange}
      data={data}
      columns={columns}
      loading={loading}
      pagination={{
        pageSize: resourceFilter.limit,
        current: resourceFilter.offset / resourceFilter.limit + 1 || 1,
        total: resourceFilter.total,
      }}
    />
  );
};
PostCodeList.propTypes = {
  retrieveList: PropTypes.func,
  pushQuery: PropTypes.func,
};

export default PostCodeList;
