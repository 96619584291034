import React, { useMemo } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import UserInfo from 'components/RestField/UserInfo';
import { isEmpty } from 'lodash-es';
import { Button } from 'antd';
import { ROLES_CONST } from 'configs/localData/permissions';

const CentresField = ({ data, handleClickViewAll, roleName }) => {
  const isAllCentre = useMemo(
    () =>
      Number(data?.totalCentres) > 0 &&
      [
        ROLES_CONST.companyAdmin,
        ROLES_CONST.companyStaff,
        ROLES_CONST.providerAdmin,
        ROLES_CONST.providerStaff,
      ].includes(roleName),
    [roleName, data],
  );

  if (isAllCentre) {
    return (
      <Button
        size="small"
        type="link"
        onClick={handleClickViewAll}
        className="mt-10 text-black"
      >
        {i18next.t('centres.allCentres')}
      </Button>
    );
  }

  return isEmpty(data?.firstCentre) ? null : (
    <div>
      <UserInfo
        record={data?.firstCentre}
        prefixLink="centres"
        nameProp="name"
        avatarProp="image.url"
      />
      {Number(data?.totalCentres) > 1 && (
        <Button
          size="small"
          type="dashed"
          onClick={handleClickViewAll}
          role="presentation"
          className="mt-10 ml-40"
        >
          {i18next.t('button.viewAll')}
        </Button>
      )}
    </div>
  );
};

CentresField.propTypes = {
  data: PropTypes.object,
  handleClickViewAll: PropTypes.func,
  roleName: PropTypes.string,
};

export default CentresField;
