import styled from 'styled-components';

export const SearchStatisticDetailStyles = styled.div`
  .btn-back {
    border-radius: 50%;
    background-color: #ffffff;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: #ebecf0;
  }
`;
