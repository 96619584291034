import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import RestInputItem from 'components/RestInput/RestInputItem';
import { validateRegex } from 'utils/validateUtils';
import { Input } from 'antd';

const FormChangePassword = ({ form }) => {
  const { getFieldValue } = form;
  const handleValidatePassword = (rule, value) => {
    if (!value || getFieldValue('newPassword') === value) {
      return Promise.resolve();
    }

    return Promise.reject(i18next.t('input.confirmPassword.validateMsg.match'));
  };
  return (
    <div>
      <RestInputItem
        required
        source="oldPassword"
        header="profile.oldPassword"
        placeholder="profile.oldPassword"
        ContentComponent={Input.Password}
        autoComplete="new-password"
      />
      <RestInputItem
        required
        source="newPassword"
        header="profile.newPassword"
        placeholder="profile.newPassword"
        ContentComponent={Input.Password}
        autoComplete="new-password"
        rules={[
          {
            pattern: validateRegex.password,
            message: i18next.t('input.password.validateMsg.pattern'),
          },
        ]}
      />
      <RestInputItem
        required
        source="confirmPassword"
        header="profile.confirmPassword"
        placeholder="profile.confirmPassword"
        ContentComponent={Input.Password}
        dependencies={['newPassword']}
        autoComplete="new-password"
        rules={[
          {
            validator: handleValidatePassword,
          },
        ]}
      />
    </div>
  );
};

FormChangePassword.propTypes = {
  form: PropTypes.object,
};

export default FormChangePassword;
