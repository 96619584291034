import React from 'react';
import PropTypes from 'prop-types';
import { InputNumber } from 'antd';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestEditor from 'components/RestInput/RestEditor';

const CRMPackagesForm = ({ isEdit }) => (
  <div>
    <RestInputItem
      required
      source="name"
      header="CRMPackages.name"
      placeholder="CRMPackages.name"
    />
    <RestInputItem
      required
      source="signupButtonText"
      header="CRMPackages.signUpButtonText"
      placeholder="CRMPackages.signUpButtonText"
    />
    {isEdit && (
      <RestInputItem
        source={['businessFeaturesCentreLevel', 'limitEnquiries', 'value']}
        header="CRMPackages.limitEnquiries"
        placeholder="CRMPackages.limitEnquiries"
        ContentComponent={InputNumber}
      />
    )}

    <RestEditor
      source="description"
      header="CRMPackages.description"
      height={500}
    />
  </div>
);

CRMPackagesForm.propTypes = {
  isEdit: PropTypes.bool,
};

export default CRMPackagesForm;
