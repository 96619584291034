import { Col, Row } from 'antd';
import React from 'react';

import CategoriesTree from './CategoriesTree';
import ArticleTable from './ArticlesList';
import ArticlesStyles from './styles';

const Articles = () => (
  <ArticlesStyles>
    <Row gutter={[20, 20]}>
      <Col lg={8} md={24} xs={24}>
        <CategoriesTree />
      </Col>

      <Col lg={16} md={24} xs={24}>
        <ArticleTable />
      </Col>
    </Row>
  </ArticlesStyles>
);

export default Articles;
