import React from 'react';
import Edit from 'containers/rest/Edit';
import { PREFIX_URL_API } from 'configs/localData/constant';
import Form from '../components/Form';

const SuburbsEdit = (props) => (
  <Edit
    {...props}
    defaultOptions={{ prefixUrl: PREFIX_URL_API }}
    resource="suburbs"
  >
    <Form />
  </Edit>
);

export default SuburbsEdit;
