import React from 'react';
import { Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import RestInputItem from 'components/RestInput/RestInputItem';

const CompanyFilterForm = () => (
  <Row gutter={16} type="flex">
    <Col md={10} xs={12}>
      <RestInputItem
        source={['filter', 'name', '$like']}
        placeholder="input.searchCompany"
        isShowTooltip
        inputProps={{ prefix: <SearchOutlined /> }}
      />
    </Col>
    <Col md={14} xs={12}>
      <RestInputItem
        source="q"
        placeholder="input.companyFilterQuery"
        isShowTooltip
        inputProps={{ prefix: <SearchOutlined /> }}
      />
    </Col>
  </Row>
);

export default CompanyFilterForm;
