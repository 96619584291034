import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import { Tooltip } from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { ButtonWrapper } from './styles';

const EditButton = ({
  isView,
  iconProps,
  onClickCustom,
  gotoShowPage,
  gotoEditPage,
  record,
  ...props
}) => {
  const handleOnClick = () => {
    if (onClickCustom) {
      onClickCustom(record?.id);
    } else if (isView) {
      gotoShowPage(record?.id);
    } else {
      gotoEditPage(record?.id);
    }
  };

  return (
    <div className="edit-div-button">
      <Tooltip title={I18n.t(`button.${isView ? 'view' : 'edit'}`)}>
        <ButtonWrapper
          source={props.source}
          icon={
            isView ? (
              <EyeOutlined {...iconProps} />
            ) : (
              <EditOutlined {...iconProps} />
            )
          }
          onClick={handleOnClick}
        />
      </Tooltip>
    </div>
  );
};

EditButton.propTypes = {
  gotoEditPage: PropTypes.func,
  record: PropTypes.object,
  source: PropTypes.string,
  isView: PropTypes.bool,
  gotoShowPage: PropTypes.func,
  disabled: PropTypes.bool,
  onClickCustom: PropTypes.func,
  iconProps: PropTypes.object,
};

EditButton.defaultProps = {
  source: 'edit',
  iconProps: {},
};

export default EditButton;
