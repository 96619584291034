import React from 'react';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import { useDispatch } from 'react-redux';
import { setEventFilterStatistic } from 'redux/statistic/slice';

import {
  DEFAULT_DATE_FILTER_STATISTIC,
  PREFIX_URL_API,
} from 'configs/localData/constant';
import { formatTimeFilterSubmit } from 'utils/dataUtils';

import RestSelect from 'components/RestInput/RestSelect';
import ReferenceInput from 'containers/rest/ReferenceInput';
import RestRangePickerInput from 'components/RestInput/RestRangePickerInput';
import FilterCustom from 'components/common/FilterCustom';
import { CHANNEL_EVENT } from 'configs/localData';
import i18next from 'i18next';

const Filter = () => {
  const dispatch = useDispatch();

  const onSubmitFilter = ({ platform, eventTime, ...values }) => {
    dispatch(
      setEventFilterStatistic({
        ...values,
        filter: {
          ...values?.filter,
          platform: platform || '',
          eventTime: formatTimeFilterSubmit(eventTime),
        },
      }),
    );
  };

  const onClearFilter = () => {
    dispatch(
      setEventFilterStatistic({
        filter: {
          eventTime: formatTimeFilterSubmit(DEFAULT_DATE_FILTER_STATISTIC),
        },
      }),
    );
  };

  return (
    <FilterCustom onSubmitFilter={onSubmitFilter} onClearFilter={onClearFilter}>
      <Row gutter={16} type="flex">
        <Col lg={4} md={12} xs={12}>
          <RestSelect
            source="platform"
            titleProp="text"
            valueProp="value"
            placeholder="events.channel"
            isFilterOption={false}
            isShowTooltip
            resourceData={CHANNEL_EVENT}
            defaultValue="All"
            formatText={(data) => i18next.t(data)}
          />
        </Col>
        <Col lg={5} md={12} xs={12}>
          <ReferenceInput
            prefixUrl={PREFIX_URL_API}
            resource="companies"
            source={['filter', 'companyId']}
            searchKey="name"
          >
            <RestSelect
              titleProp="name"
              valueProp="id"
              placeholder="centres.companyTitle"
              isFilterOption={false}
              isShowTooltip
            />
          </ReferenceInput>
        </Col>
        <Col lg={5} md={12} xs={12}>
          <ReferenceInput
            prefixUrl={PREFIX_URL_API}
            source={['filter', 'brandId']}
            resource="brands"
            searchKey="name"
          >
            <RestSelect
              titleProp="name"
              valueProp="id"
              placeholder="centres.brandTitle"
              isFilterOption={false}
              isShowTooltip
            />
          </ReferenceInput>
        </Col>
        <Col lg={5} md={12} xs={12}>
          <ReferenceInput
            prefixUrl={PREFIX_URL_API}
            source={['filter', 'centreId']}
            resource="centres"
            searchKey="name"
          >
            <RestSelect
              titleProp="name"
              valueProp="id"
              placeholder="users.centre"
              isFilterOption={false}
              isShowTooltip
            />
          </ReferenceInput>
        </Col>
        <Col lg={5} md={12} xs={12}>
          <RestRangePickerInput
            sourceGt={['filter', 'eventTime', '$gt']}
            sourceLt={['filter', 'eventTime', '$lt']}
            source="eventTime"
            placeholder="common.time"
            isShowTooltip
            defaultValue={DEFAULT_DATE_FILTER_STATISTIC}
            formatDate="DD/MM/YYYY"
            allowClear={false}
          />
        </Col>
      </Row>
    </FilterCustom>
  );
};

export default Filter;
