import styled from 'styled-components';

const FeaturesTabStyles = styled.div`
  .title-feature-tab {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 20px;
  }

  .wrapper-feature-col {
    padding: 15px;
    background: ${({ theme }) => theme.background.content};
    border-radius: 8px;
    .header-title-table {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      background: ${({ theme }) => theme.table.headerBackground};
      padding: 12px 15px;
      border-radius: 8px;
    }
    .row-feature-item {
      padding: 12px 12px 0 12px;
    }
  }
  .ant-empty {
    width: 100%;
    padding: 10px 0px;
  }
`;

export default FeaturesTabStyles;

export const FeatureItemStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .icon-name-feature {
    display: flex;
    align-items: center;
    .ant-image {
      margin-right: 15px;
    }
  }
`;
