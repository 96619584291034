import React from 'react';
import i18next from 'i18next';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import {
  SERVICE_AGE_GROUPS,
  VACANCY_COD,
  ACTIVE_TYPES,
} from 'configs/localData';
import RestSelect from 'components/RestInput/RestSelect';

const Filter = (props) => {
  const serviceTypes = useSelector((state) => state.config.data?.ServiceType);

  return (
    <Row {...props} gutter={16}>
      <Col lg={6} md={12} sm={12} xs={24}>
        <RestSelect
          source="serviceTypeId"
          placeholder="centreServices.name"
          resourceData={serviceTypes || []}
          valueProp="id"
          titleProp="name"
          isShowTooltip
        />
      </Col>
      <Col lg={6} md={12} sm={12} xs={24}>
        <RestSelect
          placeholder="centreServices.ageGroup"
          source="serviceAgeGroup"
          resourceData={SERVICE_AGE_GROUPS || []}
          valueProp="value"
          titleProp="text"
          formatText={(data) => i18next.t(data)}
          isShowTooltip
        />
      </Col>
      <Col lg={6} md={12} sm={12} xs={24}>
        <RestSelect
          placeholder="centreServices.vacancy"
          source="vacancy_cod"
          resourceData={VACANCY_COD || []}
          valueProp="value"
          titleProp="text"
          formatText={(data) => i18next.t(data)}
          isShowTooltip
          isShowSearch={false}
          ruleType="boolean"
        />
      </Col>
      <Col lg={6} md={12} sm={12} xs={24}>
        <RestSelect
          placeholder="centreServices.isActive"
          source="isActive"
          resourceData={ACTIVE_TYPES || []}
          valueProp="value"
          titleProp="text"
          formatText={(data) => i18next.t(data)}
          isShowTooltip
          isShowSearch={false}
          ruleType="boolean"
        />
      </Col>
    </Row>
  );
};

export default Filter;
