import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import {
  getDataProvidersOfCompany,
  getLoadingProvidersOfCompany,
  getResourceDataProvidersOfCompany,
} from 'redux/providersOfCompany/selector';
import ListCheckboxSelect from './ListCheckboxSelect';

const ProviderListSelect = ({ retrieveList }) => {
  const { record } = useContext(RestInputContext);
  const data = useSelector(getDataProvidersOfCompany);
  const paging = useSelector(getResourceDataProvidersOfCompany);
  const loading = useSelector(getLoadingProvidersOfCompany);

  const defaultValue = useMemo(
    () => record?.providers?.map((item) => item?.id),
    [record],
  );

  return (
    <ListCheckboxSelect
      retrieveList={retrieveList}
      name="providerIds"
      defaultValue={defaultValue}
      data={data}
      paging={paging}
      messageRequired="users.selectProviderRequired"
      selectedResourceTitles="users.selectedProvidersTotal"
      selectedResourceTitle="users.selectedProviderTotal"
      loading={loading}
    />
  );
};

ProviderListSelect.propTypes = {
  retrieveList: PropTypes.func,
};

export default ProviderListSelect;
