import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'antd/lib/checkbox';
import Space from 'antd/lib/space';
import i18next from 'i18next';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { orderBy } from 'lodash-es';

const LegendActions = ({
  eventTypes,
  setEventTypes,
  eventTypesLines,
  eventTypesList,
  setEventTypesList,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const onChange = (event) => {
    if (event?.target?.checked) {
      setEventTypes([...eventTypes, event?.target?.value]);
    } else {
      setEventTypes(
        eventTypes?.filter((item) => item !== event?.target?.value),
      );
    }
  };

  const onSearch = (e, searchValue) => {
    let searchValueValid = '';
    if (e?.target?.value) {
      searchValueValid = e?.target?.value?.trim();
      if (eventTypesList?.length > 0) {
        setEventTypesList(
          eventTypesList?.filter((item) =>
            item?.value?.includes(searchValueValid),
          ),
        );
      } else {
        setEventTypesList(
          eventTypesLines?.filter((item) =>
            item?.value?.includes(searchValueValid),
          ),
        );
      }
    } else if (searchValue) {
      searchValueValid = searchValue?.trim();
      if (eventTypesList?.length > 0) {
        setEventTypesList(
          eventTypesList?.filter((item) => item?.value?.includes(searchValue)),
        );
      } else {
        setEventTypesList(
          eventTypesLines?.filter((item) => item?.value?.includes(searchValue)),
        );
      }
    } else {
      setEventTypesList(eventTypesLines);
    }
  };

  return (
    <div className="legend-actions">
      <div>
        <Input
          placeholder={i18next.t('button.search')}
          size="large"
          suffix={
            <SearchOutlined onClick={() => onSearch(null, searchValue)} />
          }
          onPressEnter={onSearch}
          onBlur={(e) => setSearchValue(e?.target?.value)}
        />
        <div className="d-flex mt-19">
          <p
            className="mr-10 btn-select cursor-pointer"
            role="presentation"
            onClick={() =>
              setEventTypes(eventTypesList?.map((item) => item?.value))
            }
          >
            {i18next.t('button.selectAll')}
          </p>
          <div className="circle" />
          <p
            className="ml-12 btn-clear cursor-pointer"
            role="presentation"
            onClick={() => setEventTypes([])}
          >
            {i18next.t('button.clearAll')}
          </p>
        </div>
      </div>
      <div className="list-legend">
        <Checkbox.Group value={eventTypes}>
          <Space direction="vertical" size={10}>
            {orderBy(eventTypesList, 'index', 'asc')?.map((item, idx) => (
              <div className="legend-item" key={String(idx)}>
                <div
                  className="legend-item__line"
                  style={{ backgroundColor: item.fillColor }}
                />
                <Checkbox onChange={onChange} value={item.value}>
                  {item.value}
                </Checkbox>
              </div>
            ))}
          </Space>
        </Checkbox.Group>
      </div>
    </div>
  );
};

LegendActions.propTypes = {
  eventTypes: PropTypes.array,
  setEventTypes: PropTypes.func,
  eventTypesLines: PropTypes.array,
  eventTypesList: PropTypes.array,
  setEventTypesList: PropTypes.func,
};

export default LegendActions;
