import React, { useEffect, useCallback } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';
import { useHistory, useParams } from 'react-router';
import { getCompaniesOfUser } from 'redux/companiesOfUser/actions';

import CompaniesOfUserTable from './CompaniesOfUserTable';
import CompanyFilter from '../Form/CompanyFilter';

const CompaniesOfUserList = () => {
  const { push, location } = useHistory();

  const { id } = useParams();

  const dispatch = useDispatch();

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, keepFilter }) => {
      dispatch(
        getCompaniesOfUser({
          id,
          data: {
            ...filter,
            filter: {
              ...filter.filter,
            },
          },
          options: {
            isRefresh,
            isOrderBy: false,
            keepFilter,
          },
        }),
      );
    },
    [id, dispatch],
  );

  useEffect(() => {
    retrieveList({
      filter: {
        limit: 10,
        offset: 0,
        orderBy: 'name',
      },
      isRefresh: true,
    });
  }, [retrieveList]);

  const redirectAddMoreCompanies = () => {
    push(`${location.pathname}/addMoreCompanies`);
  };

  return (
    <div>
      <CompanyFilter greyInput={false} retrieveList={retrieveList} />
      <div className="box-wrapper">
        <div className="flex-center-between mb-20">
          <div className="title-table">{i18next.t('companies.company')}</div>
          <Button
            icon={<PlusOutlined />}
            className="btn-second-primary"
            onClick={redirectAddMoreCompanies}
          >
            {i18next.t('users.addMoreCompanies')}
          </Button>
        </div>
        <CompaniesOfUserTable retrieveList={retrieveList} />
      </div>
    </div>
  );
};

export default CompaniesOfUserList;
