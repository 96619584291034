import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';

import ModalCustom from 'components/common/ModalCustom';
import UserInfo from 'components/RestField/UserInfo';
import { CRUDSelectorsCustom } from 'redux/crudCreatorCustom/selector';
import InfiniteScrollData from 'components/common/InfiniteScrollData';
import { CentresModalStyles } from './styles';

const CentresModal = forwardRef(
  (
    {
      title = 'centres.header',
      resource = 'centresOfBrand',
      retrieveListAction,
    },
    ref,
  ) => {
    const [visible, setVisible] = useState(false);
    const [record, setRecord] = useState();
    const dispatch = useDispatch();

    const centresSelectorsCustom = new CRUDSelectorsCustom(resource);

    const centresData = useSelector(centresSelectorsCustom.getDataArr);
    const loading = useSelector(centresSelectorsCustom.getLoading);
    const enableLoadMore = useSelector(centresSelectorsCustom.enabledLoadMore);

    const getCentres = ({ id, isRefresh }) => {
      id &&
        dispatch(
          retrieveListAction({
            id,
            options: { isRefresh },
          }),
        );
    };

    useImperativeHandle(
      ref,
      () => ({
        open: (data) => {
          setVisible(true);
          setRecord(data);
          getCentres({ id: data?.id, isRefresh: true });
        },
      }),
      [], // eslint-disable-line
    );

    const onCancel = () => setVisible(false);

    const handleViewMore = useCallback(() => {
      enableLoadMore && getCentres({ id: record?.id });
    }, [enableLoadMore]); // eslint-disable-line

    return (
      <ModalCustom
        visible={visible}
        title={`${i18next.t(title)} ${record?.firstName || ''} ${
          record?.lastName || ''
        }`}
        footer={null}
        onCancel={onCancel}
        resetPaddingBottomBody={false}
        resetPaddingTopBody={false}
      >
        <CentresModalStyles>
          <InfiniteScrollData
            handleViewMore={handleViewMore}
            loading={loading}
            enableLoadMore={enableLoadMore}
          >
            {centresData?.map((centre, index) => (
              <div
                key={String(index)}
                className="centre-item flex-center-between"
              >
                <UserInfo
                  record={centre}
                  nameProp="name"
                  avatarProp="image.url"
                  prefixLink="centres"
                  size={50}
                />
              </div>
            ))}
          </InfiniteScrollData>
        </CentresModalStyles>
      </ModalCustom>
    );
  },
);

CentresModal.propTypes = {
  title: PropTypes.string,
  resource: PropTypes.string,
  retrieveListAction: PropTypes.func,
};

export default CentresModal;
