import { Col, Divider, Progress, Row, Typography } from 'antd';
import ModalCustom from 'components/common/ModalCustom';
import {
  CheckCircleIcon,
  CheckCircleUncheckIcon,
  EditIcon,
} from 'components/common/SVGIcon';
import { LISTING_STRENGTH_CONTENT } from 'configs/localData';
import { LISTING_STEP } from 'configs/localData/constant';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { getAllAutomatedResponses } from 'redux/automatedResponses/actions';
import { ListingStrengthStyles } from './styles';

const ViewListModal = ({ visible, onCancel, record, centreStep }) => {
  const stepDone = centreStep?.filter((item) => item?.percent === 100).length;

  const [currentIndex, setCurrentIndex] = useState(0);
  const dispatch = useDispatch();

  const { push } = useHistory();

  const { id: centreId } = useParams();

  const modalRef = useRef();

  const { key, Component } = LISTING_STRENGTH_CONTENT[currentIndex];

  const onClickUpdateButton = useCallback(
    (index) => {
      const { key: currentKey } = LISTING_STRENGTH_CONTENT[index];
      if (currentKey !== null) {
        if (currentKey === 'automatedResponses') {
          push({
            pathname: `/centres/${centreId}/${currentKey}`,
            state: {
              isUpdateStep11: true,
            },
          });
          dispatch(getAllAutomatedResponses({ id: centreId }));
        } else {
          push(`/centres/${centreId}/show/${currentKey}`);
          onCancel();
        }
      } else {
        modalRef.current && modalRef.current.toggleModal();
      }
    },
    [push, centreId, currentIndex, modalRef.current], // eslint-disable-line
  );

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (record?.brand?.logo) {
      setDisabled(true);
    }
  }, [record?.brand?.logo]); // eslint-disable-line

  return (
    <>
      <ModalCustom
        title={
          <div>
            <span>{i18next.t('listingStrength.yourListingStrength')}</span>
            <span className="ml-16 title-header-item">{`${stepDone}/${LISTING_STEP}`}</span>
          </div>
        }
        visible={visible}
        onCancel={onCancel}
        width={700}
        footer={null}
      >
        <ListingStrengthStyles>
          <Progress
            steps={LISTING_STEP}
            percent={(stepDone * 100) / LISTING_STEP}
            showInfo={false}
            className="mt-20 w-full mb-40"
          />
          {centreStep?.map((item, index) => (
            <div key={Number(index)}>
              <Row gutter={[30, 0]} className="flex-center-between">
                <Col span={22} className="flex items-center">
                  {item?.percent === 100 ? (
                    <CheckCircleIcon />
                  ) : (
                    <CheckCircleUncheckIcon />
                  )}
                  <span className="ml-12">
                    <strong>{`${i18next.t(item?.title)}: `}</strong>
                    <span>{item?.description}</span>
                  </span>
                </Col>
                <Col span={2}>
                  <Typography.Link
                    disabled={disabled && item?.step === 4}
                    onClick={async () => {
                      await setCurrentIndex(index);
                      onClickUpdateButton(index);
                    }}
                    className="edit-icon"
                  >
                    <EditIcon />
                  </Typography.Link>
                </Col>
              </Row>
              <Divider />
            </div>
          ))}
        </ListingStrengthStyles>
      </ModalCustom>
      {!key && (
        <Component ref={modalRef} record={record} centreStep={centreStep} />
      )}
    </>
  );
};

ViewListModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  record: PropTypes.object,
  centreStep: PropTypes.array,
};

export default ViewListModal;
