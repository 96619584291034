import { Tabs } from 'antd';
import i18next from 'i18next';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import BusinessUsersList from '../components/BusinessUsersList';
import ClientUsersList from '../components/ClientUsersLiest';
import { ListUsersStyles } from './styles';

const { TabPane } = Tabs;

const UsersList = () => {
  const { modelDetail } = useParams();
  const history = useHistory();
  const TABS = [
    {
      key: 'businessUser',
      text: 'users.businessUser',
      url: '/businessUser',
      component: BusinessUsersList,
    },
    {
      key: 'clientUser',
      text: 'users.clientUser',
      url: '/clientUser',
      component: ClientUsersList,
    },
  ];

  const onChange = (key) => {
    history.push(`/users/${key}`);
  };

  useEffect(() => {
    !modelDetail && history.push(`/users/businessUser`);
  }, [history, modelDetail]);

  return (
    <ListUsersStyles>
      <h1 className="header-title">{i18next.t('users.title')}</h1>
      <Tabs activeKey={modelDetail || 'businessUser'} onChange={onChange}>
        {TABS.map((tab) => (
          <TabPane tab={i18next.t(tab.text)} key={tab.key}>
            <tab.component noCardWrapper layoutButtonCreate="non-inline" />
          </TabPane>
        ))}
      </Tabs>
    </ListUsersStyles>
  );
};

export default UsersList;
