import React, { useEffect, useCallback } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { getCentresOfUser } from 'redux/centresOfUser/actions';
import CentreFilter from '../Form/CentreFilter';
import CentresOfUserTable from './CentresOfUserTable';

const CentresOfUserList = () => {
  const { push, location } = useHistory();

  const { id } = useParams();
  const dispatch = useDispatch();

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, keepFilter }) => {
      dispatch(
        getCentresOfUser({
          id,
          data: filter,
          options: {
            isRefresh,
            isOrderBy: false,
            keepFilter,
          },
        }),
      );
    },
    [id, dispatch],
  );

  useEffect(() => {
    retrieveList({
      filter: {
        limit: 10,
        offset: 0,
        orderBy: 'name',
      },
      isRefresh: true,
    });
  }, [retrieveList]);

  const redirectAddMoreCentres = () => {
    push(`${location.pathname}/addMoreCentres`);
  };

  return (
    <div>
      <CentreFilter greyInput={false} retrieveList={retrieveList} />
      <div className="box-wrapper">
        <div className="flex-center-between mb-20">
          <div className="title-table">{i18next.t('centres.title')}</div>
          <Button
            icon={<PlusOutlined />}
            className="btn-second-primary"
            onClick={redirectAddMoreCentres}
          >
            {i18next.t('users.addMoreCentres')}
          </Button>
        </div>
        <CentresOfUserTable retrieveList={retrieveList} />
      </div>
    </div>
  );
};

export default CentresOfUserList;
