import React from 'react';
import RestShow from 'containers/rest/Show';
import RestFieldItem from 'components/RestField/RestFieldItem';

const InboxesShow = (props) => (
  <RestShow {...props} hasEdit resource="inboxes">
    <RestFieldItem source="enquiryType" header="inboxes.enquiryType" />
    <RestFieldItem source="status" header="inboxes.status" />
    <RestFieldItem source="responseTime" header="inboxes.responseTime" />
  </RestShow>
);

export default InboxesShow;
