import React from 'react';
import { Button } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { ModalWrapper } from './styles';
import { CloseSquareIcon } from '../SVGIcon';

const ModalCustom = ({
  className,
  resetStyleForm,
  isScrollY,
  resetPaddingTopBody,
  resetPaddingBottomBody,
  okText,
  ...props
}) => (
  <ModalWrapper
    width={600}
    footer={[
      <Button key="back" onClick={props.onCancel} className="cancel-button">
        {i18next.t('button.cancel')}
      </Button>,
      <Button
        key="submit"
        type="primary"
        loading={props.loading}
        onClick={props.onOk}
        disabled={props.isDisabled}
        className="submit-button"
      >
        {i18next.t(okText)}
      </Button>,
    ]}
    {...props}
    className={`${className || ''} ${isScrollY ? 'modal-scroll-y' : ''} ${
      resetPaddingBottomBody ? 'reset-padding-bottom-body' : ''
    } ${resetPaddingTopBody ? 'reset-padding-top-body' : ''} ${
      resetStyleForm ? '' : 'form-vertical-style'
    }`}
    destroyOnClose
    maskClosable={false}
    closeIcon={<CloseSquareIcon />}
  />
);

ModalCustom.propTypes = {
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  onOk: PropTypes.func,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  resetStyleForm: PropTypes.bool,
  isScrollY: PropTypes.bool,
  resetPaddingTopBody: PropTypes.bool,
  resetPaddingBottomBody: PropTypes.bool,
  okText: PropTypes.string,
};

ModalCustom.defaultProps = {
  onCancel: () => {},
  loading: false,
  onOk: () => {},
  isDisabled: false,
  resetPaddingBottomBody: true,
  resetPaddingTopBody: true,
  okText: 'button.save',
};

export default ModalCustom;
