import React from 'react';
import PropTypes from 'prop-types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Col } from 'antd';
import UploadImageItem from './UploadImageItem';

const SortableItem = ({
  id,
  imageItemProps,
  index,
  item,
  responsiveSortable,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? '100' : 'auto',
    position: 'relative',
  };

  return (
    <Col {...responsiveSortable} ref={setNodeRef} style={style} {...attributes}>
      <div>
        <span
          data-cypress="draggable-handle"
          className="dragable-item"
          {...listeners}
        >
          <svg viewBox="0 0 20 20" width="1em" height="1em">
            <path
              d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"
              fill="currentColor"
            />
          </svg>
        </span>

        <UploadImageItem item={item} index={index} {...imageItemProps} />
      </div>
    </Col>
  );
};

SortableItem.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  item: PropTypes.object,
  imageItemProps: PropTypes.object,
  responsiveSortable: PropTypes.object,
};

SortableItem.defaultProps = {
  responsiveSortable: {
    lg: 8,
    md: 8,
    sm: 8,
    xs: 12,
  },
};

export default SortableItem;
