import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { formatDateTimeTable } from 'utils/textUtils';
import { PREFIX_URL } from 'configs/localData/constant';
import { formatTimeFilterSubmit } from 'utils/dataUtils';

import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import CreateButton from 'components/RestActions/CreateButton';
import UserInfo from 'components/RestField/UserInfo';
import Filter from '../components/Filter';

const CompaniesList = (props) => {
  const { push } = useHistory();

  const redirectDetail = (id) => {
    id && push(`/companies/${id}/show/summary`);
  };
  const openCreate = () => {
    push(`#companies/create`);
  };

  const formatFilter = useCallback(
    ({ lastContact, ...data }) => ({
      ...data,
      lastContact: formatTimeFilterSubmit(lastContact),
    }),
    [],
  );

  return (
    <div>
      <List
        {...props}
        resource="companies"
        hasSearch={false}
        filter={<Filter format={formatFilter} />}
        defaultOptions={{ prefixUrl: PREFIX_URL.superAdmin }}
        widthTable="1800px"
        hasCreate={false}
        extraAction={<CreateButton gotoCreatePage={openCreate} />}
        redirects={{
          edit: 'modal',
          create: 'modal',
        }}
      >
        <RestFieldItem
          sorter
          width="20%"
          source="name"
          header="companies.company"
          format={(data, record) => (
            <UserInfo
              record={record}
              prefixLink="companies"
              suffixLink="show/summary"
              roleProp="user.email"
              nameProp="name"
              avatarProp="logo"
            />
          )}
        />
        <RestFieldItem
          sorter
          source="totalProviders"
          header="summaries.totalProviders"
        />

        <RestFieldItem
          sorter
          source="totalCentres"
          header="summaries.totalCentres"
        />
        <RestFieldItem
          sorter
          source="totalPlaces"
          header="summaries.totalPlaces"
        />

        <RestFieldItem
          sorter
          source="totalUsers"
          header="summaries.totalUsers"
        />

        <RestFieldItem
          sorter
          source="primaryContact"
          header="companies.primaryContact"
          format={(data, record) => (
            <div>
              <strong>
                {`${record?.user?.firstName || ''} ${
                  record?.user?.lastName || ''
                }`}
              </strong>
              <p>{record?.user?.phoneNumber || ''}</p>
            </div>
          )}
        />

        <RestFieldItem
          sorter
          source="educatorDiscount"
          header="summaries.educatorDiscount"
          format={(data, record) => (
            <div>
              {record?.educatorDiscount && (
                <p>{`${record?.educatorDiscount}%`}</p>
              )}
            </div>
          )}
        />

        <RestFieldItem
          sorter
          source="staffDiscount"
          header="summaries.staffDiscount"
          format={(data, record) => (
            <div>
              {record?.staffDiscount && <p>{`${record?.staffDiscount}%`}</p>}
            </div>
          )}
        />

        <RestFieldItem
          sorter
          source="lastContactTime"
          header="companies.lastActivity"
          format={(data, record) =>
            formatDateTimeTable(record?.lastContactTime)
          }
        />

        <ActionGroup widthAction={120}>
          <EditButton source="show" isView onClickCustom={redirectDetail} />
          <EditButton />
        </ActionGroup>
      </List>
    </div>
  );
};

export default CompaniesList;
