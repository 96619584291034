import { createSlice } from '@reduxjs/toolkit';
import { getUsersOfCompany, MODEL_NAME } from './actions';

export const initialState = {
  loading: false,
  data: [],
  resourceData: {
    filter: {},
    page: 1,
    size: 10,
    total: 0,
  },
};

const getUsersOfCompanyPending = (
  state,
  {
    meta: {
      arg: { data, options = {} },
    },
  },
) => {
  state.loading = true;

  if (options.isRefresh) {
    state.data = [];
    state.resourceData = {
      ...initialState.resourceData,
      ...data,
    };
  }
};

const getUsersOfCompanySuccess = (state, { payload: { data } }) => {
  state.data = data?.results;
  state.resourceData.total = data.paging.total || 0;
  state.resourceData.page = data.paging.page;

  state.loading = false;
  state.error = null;
};

const getUsersOfCompanyFail = (state, { payload: { data } }) => {
  state.error = data;
  state.loading = false;
};

const { reducer } = createSlice({
  name: MODEL_NAME,
  initialState,
  extraReducers: {
    [getUsersOfCompany.pending]: getUsersOfCompanyPending,
    [getUsersOfCompany.fulfilled]: getUsersOfCompanySuccess,
    [getUsersOfCompany.rejected]: getUsersOfCompanyFail,
  },
});

export default reducer;
