import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash-es';
import { Upload, Avatar, Form, Input, notification, Spin } from 'antd';
import I18n from 'i18next';
import { UserOutlined, CameraOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { logoutSuccess } from 'redux/auth/slice';
import { resizeImageFile } from 'utils/fileUtils';
import AvatarCropperModal from './AvatarCropperModal';
import UploadImageWrapper from './style';
import { getUrl, uploadMedia } from '../../../api/uploadMedia';

const uploadUrl = `${process.env.REACT_APP_BASE_API_URL}/api/v1/uploadFile`;

class UploadImage extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const restSource = nextProps.customSource || nextProps.source;
    if (get(nextProps.record, restSource) !== prevState.prevRecordImgSource) {
      return {
        prevRecordImgSource: get(nextProps.record, restSource),
        imgDisplay: get(nextProps.record, restSource) || nextProps.defaultValue,
        defaultValueAvatar:
          get(nextProps.record, nextProps.source) || nextProps.defaultValue,
      };
    }
    return {};
  }

  constructor(props) {
    super(props);
    const restSource = props.customSource || props.source;
    this.state = {
      file: null,
      // eslint-disable-next-line
      prevRecordImgSource: get(this.props.record, restSource) || undefined,
      imgDisplay: get(this.props.record, restSource) || undefined,
      defaultValueAvatar: get(this.props.record, props.source),
      loading: false,
      isShowCropperModal: false,
      hasErr: false,
    };
  }

  onHideCropperModal = () => {
    this.setState({
      isShowCropperModal: false,
    });
  };

  showNotiError = (description) => {
    notification.error({
      message: I18n.t('error.title'),
      description: I18n.t(description),
      position: 'tr',
    });
  };

  onChangePreview = async ({ croppedFile, prefixKey }) => {
    const { setIsDisabled } = this.props;
    try {
      const { onChange, source, form, isValueObject } = this.props;
      if (this.state.imgDisplay) {
        this.onRemove(this.state.imgDisplay);
      }
      this.setState({
        isShowCropperModal: false,
        loading: true,
      });
      setIsDisabled && setIsDisabled(true);
      const compressedFile = await resizeImageFile(croppedFile);
      if (compressedFile) {
        const responseS3 = await getUrl(
          `${prefixKey || ''}${compressedFile.name}`,
          compressedFile.type,
        );
        if (responseS3) {
          try {
            const rest = await uploadMedia(
              responseS3.uploadUrl,
              compressedFile,
            );
            if (rest?.status === 200) {
              this.setState({
                imgDisplay: responseS3.url,
                loading: false,
                hasErr: false,
              });
              setIsDisabled && setIsDisabled(false);

              onChange && onChange(source, responseS3.url);

              form &&
                form.setFieldsValue({
                  [source]: !isValueObject
                    ? responseS3.url
                    : { url: responseS3.url },
                });
            } else {
              this.setState({
                loading: false,
                hasErr: true,
              });
              setIsDisabled && setIsDisabled(true);
              this.showNotiError('error.errorUploadFile');
            }
            return responseS3;
          } catch (error) {
            this.showNotiError('error.errorUploadFile');
            return error;
          }
        }
        return responseS3;
      }
      setIsDisabled && setIsDisabled(false);
      return this.setState({
        loading: false,
      });
    } catch (error) {
      if (error.code === 401) {
        this.props.logoutSuccess();
        this.showNotiError('error.error401');
      } else {
        this.showNotiError('error.description');
      }
      this.setState({
        file: null,
        imgDisplay: null,
        loading: false,
        hasErr: true,
      });
      setIsDisabled && setIsDisabled(false);
      return error;
    }
  };

  onRemove = () => {
    this.setState({
      file: null,
      imgDisplay: null,
      loading: false,
    });
    localStorage.removeItem('url');
  };

  renderImage() {
    const { style, defaultText, defaultIcon, defaultValue } = this.props;
    const { loading, imgDisplay, hasErr } = this.state;
    if (loading) {
      return (
        <Avatar style={style}>
          <Spin />
          <div className="ant-upload-text">Uploading....</div>
        </Avatar>
      );
    }
    if (!imgDisplay) {
      return (
        <Avatar
          size={this.props.size}
          icon={defaultIcon}
          src={defaultValue}
          style={style}
        >
          <span className="default-image">{defaultText}</span>
        </Avatar>
      );
    }

    if (!hasErr) {
      return <Avatar size={this.props.size} src={imgDisplay} style={style} />;
    }

    return (
      <Avatar size={this.props.size} src={imgDisplay} style={style}>
        <div className="ant-upload-text">Upload Failed</div>
      </Avatar>
    );
  }

  render() {
    const {
      hasCrop,
      source,
      style,
      className,
      defaultValue,
      cropDimension,
      header,
      prefixKey,
    } = this.props;
    const { defaultValueAvatar } = this.state;

    const props = {
      showUploadList: false,
      action: uploadUrl,
      beforeUpload: async (file) => {
        this.setState(() => ({
          file,
          isShowCropperModal: hasCrop,
        }));
        if (!hasCrop) {
          this.onChangePreview({ croppedFile: file, prefixKey });
        }
        return false;
      },
    };

    return (
      <UploadImageWrapper className={className}>
        <Upload {...props} accept="image/*">
          <div className="image-uploader">
            {this.renderImage()}
            <div className="image-hover" style={style}>
              <CameraOutlined className="image-hover-icon" />
            </div>
          </div>
        </Upload>
        <Form.Item
          label={I18n.t(header)}
          name={source}
          initialValue={defaultValueAvatar || defaultValue}
          rules={[
            {
              required: this.props.required,
              message: I18n.t('error.required'),
            },
          ]}
        >
          <Input style={{ display: 'none' }} />
        </Form.Item>
        <AvatarCropperModal
          cropDimension={cropDimension}
          isShowModal={this.state.isShowCropperModal}
          onHideModal={this.onHideCropperModal}
          onChangePreview={this.onChangePreview}
          image={this.state.file}
          prefixKey={prefixKey}
        />
      </UploadImageWrapper>
    );
  }
}

UploadImage.propTypes = {
  showErrorMsg: PropTypes.func,
  form: PropTypes.object,
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  record: PropTypes.object,
  style: PropTypes.object,
  defaultText: PropTypes.string,
  defaultIcon: PropTypes.node,
  onUploadImage: PropTypes.func,
  className: PropTypes.string,
  cropDimension: PropTypes.object,
  hasCrop: PropTypes.bool,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  header: PropTypes.string,
  logoutSuccess: PropTypes.func,
  isValueObject: PropTypes.bool,
  customSource: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.number,
  prefixKey: PropTypes.string,
  setIsDisabled: PropTypes.func,
};

UploadImage.defaultProps = {
  hasCrop: true,
  defaultIcon: <UserOutlined />,
  size: 100,
};

export default connect(null, (dispatch) => ({
  logoutSuccess: (error) => {
    dispatch(logoutSuccess(error));
  },
}))(UploadImage);
