import React from 'react';

import { PropTypes } from 'prop-types';
import i18next from 'i18next';
import { Form, Input } from 'antd';

import CompaniesModal from '.';

const CompanyInfoModal = ({ onCancel, visible, description }) => (
  <CompaniesModal
    onCancel={onCancel}
    visible={visible}
    title={i18next.t('companies.editCompanyInfo')}
  >
    <Form.Item
      style={{ fontWeight: 700 }}
      label={i18next.t('companies.companyDescription')}
      initialValue={description}
      name="description"
    >
      <Input.TextArea
        placeholder={i18next.t('companies.companyDescription')}
        rows={10}
      />
    </Form.Item>
  </CompaniesModal>
);
CompanyInfoModal.propTypes = {
  onCancel: PropTypes.func,
  visible: PropTypes.bool,
  description: PropTypes.string,
};
export default CompanyInfoModal;
