import { PREFIX_URL } from 'configs/localData/constant';
import { get } from './utils';

export const getEventStatisticChartApi = (data) =>
  get(`/event-statistic/chart/counts-by-name`, data, {}, PREFIX_URL.superAdmin);

export const getEventStatisticInfoApi = (data) =>
  get(`/event-statistic/info`, data, {}, PREFIX_URL.superAdmin);

export const getSearchStatisticsApi = (data) =>
  get(`/dashboards/search-statistic`, data, {}, PREFIX_URL.superAdmin);

export const getTotalSearchStatisticApi = (data) =>
  get(
    `/dashboards/search-statistics/total-search`,
    data,
    {},
    PREFIX_URL.superAdmin,
  );
