import React, { useState, useMemo, useCallback, useContext } from 'react';
import { Form, Upload, notification } from 'antd';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { isEmpty, isPlainObject, remove } from 'lodash-es';
import { getUrl, uploadMedia } from 'api/uploadMedia';
import { logoutSuccess } from 'redux/auth/slice';
import { getRecordData } from 'utils/tools';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import UploadImageItem from './UploadImageItem';
import { FormCentrePhotosStyles } from './styles';

const makeObjFile = (value) => ({
  uid: value,
  name: value,
  status: 'done',
  url: value,
  id: value,
});

const makeFileList = (values) => {
  if (isEmpty(values)) return [];

  if (Array.isArray(values))
    return values.map((value) =>
      value && value.url ? value : makeObjFile(value),
    );

  if (isPlainObject(values))
    return [
      {
        ...makeObjFile(values.url),
        ...values,
      },
    ];

  return [makeObjFile(values)];
};

const ActivitiesUploadForm = ({ record, source, setIsDisabled }) => {
  const dispatch = useDispatch();

  const { form } = useContext(RestInputContext);

  const initialValue = useMemo(
    () => getRecordData(record, source),
    [record, source],
  );

  const [fileList, setFileList] = useState(makeFileList(initialValue));

  const customRequest = async ({ onSuccess, onError, file }) => {
    try {
      const responseS3 = await getUrl(file.name, file.type);

      const response = await uploadMedia(responseS3.uploadUrl, file);
      if (response?.status === 200) {
        onSuccess(responseS3.url, file);
      } else {
        onError(null, { status: 'done' });
      }
    } catch (error) {
      onError(null, { status: 'done' });
      if (error.code === 401) {
        dispatch(logoutSuccess());
        notification.error({
          message: i18next.t('error.title'),
          description: i18next.t('error.error401'),
          duration: 2,
          position: 'tr',
        });
      } else
        notification.error({
          message: i18next.t('error.title'),
          description: i18next.t('error.description'),
          position: 'tr',
          duration: 2,
        });
    }
  };

  const onChangeUpload = (e) => {
    isDisableButtonSubmit(e.fileList);
    setFileList(e.fileList);
  };

  const formatValueFile = useCallback(
    (results) =>
      results?.map((data) => ({
        key: data.name || data.key,
        url: data.response || data.url,
      })),
    [],
  );

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return formatValueFile(e);
    }
    return e && formatValueFile(e.fileList);
  };

  const isDisableButtonSubmit = (newFileList) => {
    const isDisabled =
      newFileList &&
      newFileList.length >= 0 &&
      newFileList?.filter((i) => {
        if (i.url !== undefined) return false;
        return i.response === undefined;
      }).length > 0;

    setIsDisabled(isDisabled);
  };

  const deleteImage = (item, index) => {
    const newList = remove(fileList, (obj, idx) => idx !== index);
    setFileList(newList);
    normFile(newList);
    form.setFieldsValue({ [source]: formatValueFile(newList) });
  };

  return (
    <FormCentrePhotosStyles>
      <Form.Item
        className="form-item-upload-image"
        label={i18next.t('activitiesAndNotes.attachments')}
      >
        <Form.Item
          name={source}
          initialValue={initialValue}
          noStyle
          getValueFromEvent={normFile}
        >
          <Upload.Dragger
            accept="image/*,.xlsx,.pdf,.pptx,.txt"
            showUploadList={false}
            name="files"
            fileList={fileList}
            customRequest={customRequest}
            onChange={onChangeUpload}
            multiple
          >
            <p className="ant-upload-text">
              {i18next.t('activitiesAndNotes.dragAndDrop')}
            </p>
            <p className="ant-upload-drag-icon">
              {i18next.t('activitiesAndNotes.or')}
              <b>{i18next.t('activitiesAndNotes.uploadFile')}</b>
              {i18next.t('activitiesAndNotes.fromYourComputer')}
            </p>
          </Upload.Dragger>
        </Form.Item>
      </Form.Item>

      <div className="">
        {fileList?.map((file, index) => (
          <UploadImageItem
            key={String(index)}
            item={file}
            deleteImage={deleteImage}
            index={index}
          />
        ))}
      </div>
    </FormCentrePhotosStyles>
  );
};

ActivitiesUploadForm.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
  setIsDisabled: PropTypes.func,
};

ActivitiesUploadForm.defaultProps = {
  setIsDisabled: () => null,
};

export default ActivitiesUploadForm;
