import React, { forwardRef, useState, useImperativeHandle } from 'react';
import EmptyData from 'components/common/EmptyData';
import { TemplateModalStyles } from './styles';

const EmailPreviewModal = forwardRef((props, ref) => {
  const [visible, setVisible] = useState();
  const [content, setContent] = useState();

  useImperativeHandle(
    ref,
    () => ({
      open: (data) => {
        setVisible(true);
        setContent(data);
      },
    }),
    [],
  );

  return (
    <TemplateModalStyles
      width={800}
      footer={null}
      visible={visible}
      onCancel={() => setVisible(false)}
      className="nhi"
    >
      {content ? (
        <div
          className="email-preview__content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      ) : (
        <EmptyData />
      )}
    </TemplateModalStyles>
  );
});

export default EmailPreviewModal;
