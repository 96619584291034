import React from 'react';
import { PropTypes } from 'prop-types';
import { Col, Row } from 'antd';

import SummaryCompanies from './SummaryCompanies';
import SummaryEnquiries from './SummaryEnquiries';
import SummaryApplication from './SummaryApplications';
import CompanyInfo from './CompanyInfo';
import CompanyInfoGeneral from '../CompanyInfoGeneral';
import EducatorAndStaffDiscounts from './Educator&StaffDiscounts';

const SummaryTab = ({ record }) => (
  <div>
    <Row gutter={[24, 24]}>
      <Col xl={6} lg={10} md={24} xs={24}>
        <CompanyInfoGeneral record={record} />
      </Col>
      <Col xl={18} lg={14} md={24} xs={24}>
        <Row gutter={[24, 24]}>
          <Col xl={8} lg={24} md={24} xs={24}>
            <SummaryCompanies />
          </Col>
          <Col xl={8} lg={24} md={24} xs={24}>
            <SummaryEnquiries />
          </Col>
          <Col xl={8} lg={24} md={24} xs={24}>
            <SummaryApplication />
          </Col>
        </Row>
        <Row className="mt-24">
          <Col span={24}>
            <CompanyInfo record={record} />
          </Col>
        </Row>
        <Row className="mt-24">
          <Col span={24}>
            <EducatorAndStaffDiscounts record={record} />
          </Col>
        </Row>
      </Col>
    </Row>
  </div>
);

SummaryTab.propTypes = {
  record: PropTypes.object,
};

export default SummaryTab;
