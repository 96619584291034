import React, { useMemo } from 'react';
import RestInputItem from 'components/RestInput/RestInputItem';
import { Col, Form, Input, Row, Typography } from 'antd';
import i18next from 'i18next';
import ReferenceInput from 'containers/rest/ReferenceInput';
import RestSelect from 'components/RestInput/RestSelect';
import { PREFIX_URL } from 'configs/localData/constant';
import { USER_TYPES_CONSTANT } from 'configs/localData';
import { validateRegex } from 'utils/validateUtils';
import { useSelector } from 'react-redux';
import { providersSelectors } from 'redux/providers/selector';
import { ProviderFormStyles } from './styles';

const ProviderInfoForm = () => {
  const filterUser = useMemo(
    () => ({
      orderBy: 'firstName,lastName',
      filter: { userType: USER_TYPES_CONSTANT.business.value },
    }),
    [],
  );

  const currentData = useSelector(providersSelectors.getCurrentData) || {};

  return (
    <ProviderFormStyles>
      <Typography.Title level={4}>
        {i18next.t('providers.providerInfo')}
      </Typography.Title>
      <RestInputItem
        required
        source="name"
        header="providers.providerName"
        placeholder="providers.providerName"
        isValidatorEmpty
        maxLength={255}
      />
      <ReferenceInput
        prefixUrl={PREFIX_URL.superAdmin}
        source="companyId"
        resource="companies"
        searchKey="company.name"
      >
        <RestSelect
          titleProp="name"
          valueProp="id"
          header="centres.company"
          placeholder="input.company.placeholder"
          isFilterOption={false}
        />
      </ReferenceInput>
      <RestInputItem
        required
        source="governmentReferenceId"
        header="providers.PRNumber"
        placeholder="providers.PRNumber"
        isValidatorEmpty
        maxLength={255}
        rules={[
          {
            pattern: validateRegex.PRNumber,
            message: i18next.t('input.PRNumber.validateMsg.invalid'),
          },
        ]}
      />
      <Row gutter={[40, 40]}>
        <Col span={12}>
          <Form.Item shouldUpdate className="input-field-style">
            {({ setFieldsValue, validateFields }) => (
              <Form.Item
                className="input-field-style"
                label={i18next.t('companies.acnNumber')}
                name="australianCompanyNumber"
                shouldUpdate
                initialValue={currentData?.australianCompanyNumber}
                rules={[
                  () => ({
                    validator(_, value) {
                      if (
                        validateRegex?.ACNNumber?.test(
                          value?.replaceAll(' ', ''),
                        ) ||
                        !value
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          i18next.t(
                            'input.ABN&ACNNumber.validateMsg.acnDigits',
                          ),
                        ),
                      );
                    },
                  }),
                ]}
              >
                <Input
                  maxLength="11"
                  onChange={(e) => {
                    setFieldsValue({
                      australianCompanyNumber: e.target.value
                        .replaceAll(' ', '')
                        .split(/(?=(?:\d{3})+(?:\.|$))/g)
                        .join(' '),
                    });
                    validateFields(['australianCompanyNumber']);
                  }}
                />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item shouldUpdate className="input-field-style">
            {({ setFieldsValue, validateFields }) => (
              <Form.Item
                className="input-field-style"
                label={i18next.t('companies.abnNumber')}
                name="australianBusinessNumber"
                shouldUpdate
                initialValue={currentData?.australianBusinessNumber}
                rules={[
                  () => ({
                    validator(_, value) {
                      if (
                        validateRegex?.ABNNumber?.test(
                          value?.replaceAll(' ', ''),
                        ) ||
                        !value
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          i18next.t(
                            'input.ABN&ACNNumber.validateMsg.abnDigits',
                          ),
                        ),
                      );
                    },
                  }),
                ]}
              >
                <Input
                  maxLength="14"
                  onChange={(e) => {
                    setFieldsValue({
                      australianBusinessNumber: e.target.value
                        .replaceAll(' ', '')
                        .split(/(?=(?:\d{3})+(?:\.|$))/g)
                        .join(' '),
                    });
                    validateFields(['australianBusinessNumber']);
                  }}
                />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
      </Row>
      <ReferenceInput
        prefixUrl={PREFIX_URL.superAdmin}
        source="userId"
        resource="users"
        initialFilter={filterUser}
        searchKey="q"
      >
        <RestSelect
          header="providers.businessUser"
          titleProp="firstName"
          valueProp="id"
          placeholder="input.businessUser.placeholder"
          formatText={(name, record) =>
            `${name || ''} ${record.lastName || ''}
          `
          }
          isFilterOption={false}
        />
      </ReferenceInput>
      <ReferenceInput
        prefixUrl={PREFIX_URL.superAdmin}
        source="userId"
        resource="users"
        initialFilter={filterUser}
      >
        <RestSelect
          disabled
          titleProp="email"
          valueProp="id"
          header="input.email.name"
          placeholder="input.email.name"
        />
      </ReferenceInput>
      <ReferenceInput
        prefixUrl={PREFIX_URL.superAdmin}
        source="userId"
        resource="users"
        initialFilter={filterUser}
      >
        <RestSelect
          disabled
          titleProp="phoneNumber"
          valueProp="id"
          header="input.phoneNumber.name"
          placeholder="input.phoneNumber.name"
          formatText={(phoneNumber) =>
            `${phoneNumber || ''} 
          `
          }
        />
      </ReferenceInput>
    </ProviderFormStyles>
  );
};

export default ProviderInfoForm;
