import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { Form, Button } from 'antd';
import i18n from 'i18next';
import { login as loginAction } from 'redux/auth/actions';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import MaterialInput from '../../components/common/MaterialInput';
import logo from '../../assets/images/kindiCare_logo.svg';
import LoginStyles from './styles';

const FormItem = Form.Item;

const Login = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const login = (params) => dispatch(loginAction(params));
  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        login(values);
      })
      .catch();
  };
  if (isAuthenticated) {
    return <Redirect to="/" />;
  }
  return (
    <LoginStyles>
      <img alt="" src={logo} className="logo" />
      <div className="title">
        <div className="maintitle">{i18n.t('login.title')}</div>
      </div>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <FormItem
          name="email"
          rules={[
            {
              required: true,
              message: i18n.t('input.email.validateMsg.required'),
            },
          ]}
        >
          <MaterialInput
            placeholder={i18n.t('input.email.placeholder')}
            prefix={<MailOutlined />}
          />
        </FormItem>
        <FormItem
          name="password"
          rules={[
            {
              required: true,
              message: i18n.t('input.password.validateMsg.required'),
            },
          ]}
        >
          <MaterialInput
            placeholder={i18n.t('input.password.placeholder')}
            prefix={<LockOutlined />}
            type="password"
          />
        </FormItem>
        <div className="action-div">
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            loading={loading}
          >
            {i18n.t('login.loginBtn')}
          </Button>
          <div className="div-forgot-password">
            <Link to="/forgot-password" href="/forgot-password">
              {i18n.t('forgotPassword.title')}
            </Link>
          </div>
        </div>
      </Form>
    </LoginStyles>
  );
};

export default withRouter(Login);
