import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useSelector } from 'react-redux';

import TableCustom from 'components/common/TableCustom';
import { CRUDSelectorsCustom } from 'redux/crudCreatorCustom/selectors';
import {
  formatApplicationStatus,
  formatApplicationValue,
  formatCentreName,
  formatSubscription,
  formatUser,
} from 'utils/formatFieldUtils';
import { formatDateTimeTable, formatLabelTable } from 'utils/textUtils';
import { LABEL_TABLE_KEY } from 'configs/localData/constant';
import ServiceApplied from 'containers/Applications/List/ServiceApplied';

const ApplicationsTable = ({ retrieveList, resource }) => {
  const applicationsSelectorsCustom = new CRUDSelectorsCustom(resource);
  const loading = useSelector(applicationsSelectorsCustom.getLoading);
  const resourceFilter =
    useSelector(applicationsSelectorsCustom.getResourceData) || {};
  const data = useSelector(applicationsSelectorsCustom.getDataArr);

  const appSettings = useSelector((state) => state.config?.data?.AppSetting);

  const columns = [
    {
      title: i18next.t('centres.centreName'),
      dataIndex: 'centreName',
      render: (data, record) => formatCentreName(data, record?.centre),
      width: 250,
      sorter: true,
    },
    {
      title: i18next.t('centres.subscription'),
      dataIndex: ['centre', 'CRMPackage', 'name'],
      render: (data, record) => formatSubscription(data, record?.centre),
      width: 300,
    },
    {
      title: i18next.t('centres.centrePhone'),
      dataIndex: ['centre', 'phone'],
      render: (record) => <p className="text-nowrap">{record}</p>,
      width: 250,
    },
    {
      title: formatLabelTable(LABEL_TABLE_KEY.serviceType, appSettings),
      dataIndex: ['careOption', 'centreServices'],
      render: (data) => <ServiceApplied centreServices={data} />,
      width: 150,
    },
    {
      title: i18next.t('applications.opportunityValue'),
      dataIndex: ['careOption', 'value'],
      render: formatApplicationValue,
      width: 150,
    },
    {
      title: i18next.t('applications.applicationStatus'),
      dataIndex: 'status',
      render: formatApplicationStatus,
      width: 150,
    },
    {
      title: i18next.t('users.appUser'),
      dataIndex: 'user',
      render: formatUser,
      width: 200,
    },
    {
      title: i18next.t('users.userPhone'),
      dataIndex: ['user', 'phoneNumber'],
      width: 200,
    },
    {
      title: i18next.t('inboxes.applicationDate'),
      dataIndex: 'createdAt',
      render: (record) => (
        <p className="text-nowrap">{formatDateTimeTable(record)}</p>
      ),
      width: 200,
      sorter: true,
    },
    {
      title: i18next.t('inboxes.lastUpdate'),
      dataIndex: 'applicationUpdatedAt',
      render: (record) => (
        <p className="text-nowrap">{formatDateTimeTable(record)}</p>
      ),
      sorter: true,
      width: 200,
    },
  ];

  const onChange = (e, filters, sorter) => {
    const formatSort =
      sorter && sorter.field && sorter.order
        ? `${sorter.order === 'descend' ? '-' : ''}${sorter.field}`
        : '-applicationUpdatedAt';

    const restFilter = {
      offset: (e.current - 1) * e.pageSize,
      limit: e.pageSize,
      orderBy: formatSort,
      filter: resourceFilter.filter,
      outsideFilter: resourceFilter.outsideFilter,
    };

    retrieveList({
      filter: restFilter,
      isRefresh: true,
    });
  };

  return (
    <div>
      <TableCustom
        xScroll={1000}
        onChange={onChange}
        data={data}
        columns={columns}
        loading={loading}
        pagination={{
          pageSize: resourceFilter.limit,
          current: resourceFilter.offset / resourceFilter.limit + 1 || 1,
          total: resourceFilter.total,
        }}
      />
    </div>
  );
};

ApplicationsTable.propTypes = {
  retrieveList: PropTypes.func,
  resource: PropTypes.string,
};

ApplicationsTable.defaultProps = {
  retrieveList: () => null,
};

export default ApplicationsTable;
