import moment from 'moment';

export const COLOR_KINDI_RATING = {
  price: '#DB147F',
  avg: '#32A4FC',
};

export const EMBED_YOUTUBE_LINK = 'https://www.youtube.com/embed/';

export const PREFIX_URL_API = 'super-admin';

export const PREFIX_URL = {
  public: 'public',
  admin: 'admin',
  superAdmin: 'super-admin',
};

export const PREFIX_API_VER_1 = 'api/v1';

export const PREFIX_API_VER_2 = 'thor/v2';

export const SOCIAL_MEDIA_CHANNEL_TYPE = 'social_channel';

export const CURRENCY_UNIT_SYMBOL = '$';

export const CURRENCY_UNIT = 'AUD';

export const ENQUIRY_MODE_KEY = 'enquiry-mode';

export const RATING_LINK = `${process.env.REACT_APP_LANDING_WEB_URL}/the-kindicare-rating`;

export const CONTACT_US_URL = `${process.env.REACT_APP_LANDING_WEB_URL}/contact-us`;

export const CRM_URL =
  process.env.REACT_APP_CRM_WEB_URL || 'https://crm.kindicare.com';

export const DEVICE_TIME_ZONE =
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const LISTING_STEP = 11;

export const CUSTOMISE_BUTTONS_CONST = {
  applyBtn: {
    key: 'apply-button-url',
    text: 'button.apply',
  },
  bookATourOrVisit: {
    key: 'book-visit-and-tour-url',
    text: 'button.bookATourOrVisit',
  },
};

export const CUSTOMISE_BUTTONS = Object.values(CUSTOMISE_BUTTONS_CONST);

export const EMPTY_COST_PER_DAY = 'common.enquire';

export const EMPTY_CENTRE_VALUE_MONEY = 'common.notAssessed';

export const KEYS_GET_GEO_MAP_REQUIRED = [
  'city',
  'state',
  'addressL1',
  'postCode',
];

export const PLACE_DEFAULT_OPTIONS = {
  componentRestrictions: { country: 'au' },
  strictBounds: true,
};

export const EDM_TAB_KEYS_CONST = {
  favorites: 'favorites',
  system: 'system',
};

export const DEFAULT_DATE_FILTER_STATISTIC = [
  moment().subtract(6, 'days').startOf('date'),
  moment().endOf('date'),
];

export const FORMAT_DATE_INPUT = 'DD/MM/YYYY';

export const FORMAT_DATE_SUBMIT = 'YYYY-MM-DD';

export const FORMAT_DATE_TIME_SUBMIT = 'YYYY-MM-DD hh:mm';

export const FORMAT_DATE_STATISTIC = 'MMM DD, yyyy';

export const LABEL_TABLE_KEY = {
  serviceType: 'title-of-centre-service',
  ageGroup: 'title-of-age-group',
  sessionType: 'title-of-session-type',
  childCareService: 'title-of-primary-childcare-service',
};

export const PREFIX_KEY_UPLOAD_ARTICLE = 'uploads/articles/assets/';

export const LIMIT_LATEST_EMAILS_SENT = 10;
export const LIMIT_ITEM = 10;
export const SORT_ASCEND_VALUE = 'ascend';
export const SORT_DESCEND_VALUE = 'descend';
export const DEFAULT_CURRENT_PAGE = 1;
export const DEFAULT_SORT_CREATED_AT = '-createdAt';
