import React, { useState } from 'react';
import PropTypes from 'prop-types';

import GridPhotosCustom from 'components/common/GridPhotosCustom';
import CardSection from 'components/common/CardSection';
import CentrePhotoModal from '../Modals/CentrePhotoModal';
import { CentrePhotoStyles } from './styles';

const CentrePhoto = ({ images, picsShowing }) => {
  const [visibleModal, setVisibleModal] = useState(false);

  const onClickButton = () => {
    setVisibleModal(true);
  };

  const onCancel = () => {
    setVisibleModal(false);
  };
  return (
    <>
      <CardSection
        isEdit
        onClickButton={onClickButton}
        title="centreDetail.centrePhotos"
        permissionKey="myCentres"
        action="isEdit"
      >
        <CentrePhotoStyles>
          <div className="centre-photo">
            <GridPhotosCustom images={images} picsShowing={picsShowing} />
          </div>
        </CentrePhotoStyles>
      </CardSection>
      <CentrePhotoModal
        record={{ images }}
        onCancel={onCancel}
        visible={visibleModal}
      />
    </>
  );
};

CentrePhoto.propTypes = {
  images: PropTypes.array,
  picsShowing: PropTypes.number,
};

export default CentrePhoto;
