import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiWrapper } from 'utils/reduxUtils';
import {
  getEventStatisticChartApi,
  getEventStatisticInfoApi,
} from 'api/statistic';
import { convertRequestParams } from 'redux/crudCreator/dataProvider';

export const getEventStatisticChart = createAsyncThunk(
  'auth/getEventStatisticChart',
  async (payload, thunkAPI) => {
    try {
      const convertRequest = convertRequestParams(
        'GET_ALL',
        payload,
        'statistic',
        { isOrderBy: false },
      );

      const response = await apiWrapper(
        null,
        getEventStatisticChartApi,
        convertRequest,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getEventStatisticInfo = createAsyncThunk(
  'auth/getEventStatisticInfo',
  async (payload, thunkAPI) => {
    try {
      const convertRequest = convertRequestParams(
        'GET_ALL',
        payload,
        'statistic',
        { isOrderBy: false },
      );

      const response = await apiWrapper(
        null,
        getEventStatisticInfoApi,
        convertRequest,
      );

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);
