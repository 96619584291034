import React, { useCallback } from 'react';
import { Space, Tag } from 'antd';
import i18next from 'i18next';
import { formatDateTimeTable } from 'utils/textUtils';
import {
  CENTRE_STATUS,
  CENTRE_STATUS_VALUE,
  ENQUIRY_CENTRE_STATUS,
} from 'configs/localData';

import UserInfo from 'components/RestField/UserInfo';
import ContentTextEllipsis from 'components/common/ContentTextEllipsis';

const useFormat = () => {
  const formatCentre = useCallback(
    (data, record) => (
      <UserInfo
        record={record.centre}
        nameProp="name"
        prefixLink="centres"
        suffixLink="show"
        roleProp="adminEmail"
        avatarProp="logo.url"
      />
    ),
    [],
  );

  const formatUser = useCallback((data, record) => {
    const fullName = `${record?.sender?.firstName || ''} ${
      record?.sender?.lastName || ''
    }`;

    return (
      <UserInfo
        record={{ ...record?.sender, fullName }}
        roleProp="email"
        isLink={false}
      />
    );
  }, []);

  const formatCentreStatus = useCallback((data) => {
    const restItem = ENQUIRY_CENTRE_STATUS?.find((item) => item.value === data);
    return (
      <Tag color={restItem?.color}>
        {restItem?.text ? i18next.t(restItem.text) : data}
      </Tag>
    );
  }, []);

  const formatStatus = useCallback((data, record) => {
    const item = CENTRE_STATUS.find((status) => status.value === data);
    return item ? (
      <Space wrap>
        <Tag color={item.color}>{i18next.t(item.text)}</Tag>
        {!record.isAdminEmailUsed &&
          (data === CENTRE_STATUS_VALUE.pending ||
            data === CENTRE_STATUS_VALUE.inProcessing) && (
            <Tag color="pink">Admin</Tag>
          )}
      </Space>
    ) : null;
  }, []);

  const formatTime = useCallback((data) => formatDateTimeTable(data), []);

  const formatMessage = useCallback(
    (data) => <ContentTextEllipsis content={data} />,
    [],
  );

  return {
    formatCentre,
    formatUser,
    formatStatus,
    formatTime,
    formatMessage,
    formatCentreStatus,
  };
};

export default useFormat;
