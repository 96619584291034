import React from 'react';
import { isEmpty } from 'lodash-es';
import Create from 'containers/rest/Create';
import Form from '../components/Form';

const FeaturesCreate = (props) => (
  <Create
    {...props}
    resource="features"
    formatOnSubmit={({ icon, ...data }) => ({
      ...data,
      ...(!isEmpty(icon) && { icon: { key: icon.key, url: icon.url } }),
    })}
    defaultOptions={{ isTrimStr: true, isGetConfig: true }}
  >
    <Form />
  </Create>
);

export default FeaturesCreate;
