import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAutomatedResponses } from 'redux/automatedResponses/actions';
import List from 'antd/lib/list';

import { useParams, useHistory } from 'react-router';
import { changeFinishedStep } from 'redux/centres/actions';
import SuccessListingStrengthModal from 'containers/Centres/components/CentreDetail/SuccessListingStrengthModal';
import AutomatedItem from './AutomatedItem';
import AutomatedListStyles from './styles';

const AutomatedList = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const data = useSelector((state) => state.automatedResponses.data);
  const loading = useSelector((state) => state.automatedResponses.loading);

  const centreCurrent = useSelector((state) => state.centres?.currentData);
  const isFinishedStep = centreCurrent?.isFinishedStep;

  const [visible, setVisible] = useState(false);

  const { push } = useHistory();

  useEffect(() => {
    if (isFinishedStep) {
      setVisible(true);
    }
  }, [isFinishedStep]);

  const onCancel = () => {
    setVisible(false);
  };

  const onCompleted = () => {
    dispatch(
      changeFinishedStep({
        centreId: centreCurrent?.id,
        data: { isFinishedStep: false },
      }),
    )
      .then(({ payload }) => {
        if (payload) {
          push(`/centres/${centreCurrent?.id}/show/summary`);
          onCancel();
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    id && dispatch(getAllAutomatedResponses({ id }));
  }, [id, dispatch]);

  return (
    <AutomatedListStyles className="automated-list">
      <div className="automated__title automated-list__title">
        {i18next.t('automatedResponses.header')}
      </div>

      <List
        className="automated-list__list"
        loading={loading}
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <AutomatedItem item={item} centreId={id} />
          </List.Item>
        )}
      />
      {centreCurrent?.isFinishedStep && (
        <SuccessListingStrengthModal
          visible={visible}
          onCancel={onCancel}
          onCompleted={onCompleted}
          loading={loading}
        />
      )}
    </AutomatedListStyles>
  );
};

export default AutomatedList;
