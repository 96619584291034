import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Col, Row, Switch } from 'antd';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestAvatarInput from 'components/RestInput/RestAvatarInput';
import RestEditor from 'components/RestInput/RestEditor';
import { PictureFilled } from '@ant-design/icons';
import RestTreeSelect from 'components/RestInput/RestTreeSelect';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getQueryParams } from 'utils/tools';
import { PREFIX_KEY_UPLOAD_ARTICLE } from 'configs/localData/constant';

const ArticleForm = ({ isEdit }) => {
  const location = useLocation();
  const currentData = useSelector((state) => state.articles.currentData);
  const [isWarningSelectCategory, setIsWarningSelectCategory] = useState(
    !isEdit,
  );
  const [externalUrL, setExternalUrl] = useState(
    isEdit ? currentData?.externalUrl : '',
  );

  const [content, setContent] = useState(isEdit && currentData?.content);

  const treeData = useSelector((state) => state.categories.treeData);

  const filter = getQueryParams(location.search);

  const formatValueCategory = (record) => {
    if (isEdit) return record?.categories?.[0]?.id;

    return filter?.categoryId || treeData?.[0]?.id;
  };

  useEffect(() => {
    if (!isEdit) {
      const parentCategory = treeData?.find(
        (category) => category?.id === filter?.categoryId,
      );
      parentCategory === undefined
        ? setIsWarningSelectCategory(false)
        : setIsWarningSelectCategory(true);
    }
  }, [isEdit, treeData]); // eslint-disable-line

  return (
    <div>
      <div className="flex-center">
        <RestAvatarInput
          required
          style={{
            width: 140,
            height: 140,
            borderRadius: 5,
          }}
          className="avatar-section"
          cropDimension={{ width: 300, height: 300 }}
          hasCrop={false}
          source="thumbnail"
          defaultIcon={<PictureFilled />}
          prefixKey={PREFIX_KEY_UPLOAD_ARTICLE}
        />
      </div>
      <RestTreeSelect
        required
        source="categoryId"
        header="articles.category"
        placeholder="articles.category"
        treeData={treeData}
        treeDefaultExpandAll
        treeCheckable={false}
        onChange={(value, record) => {
          const selectedCategory = treeData?.find(
            (item) => item.title === record?.[0],
          );
          selectedCategory
            ? setIsWarningSelectCategory(true)
            : setIsWarningSelectCategory(false);
        }}
        formatValue={formatValueCategory}
      />
      {isWarningSelectCategory && (
        <p className="warning-color warning-text">
          {i18next.t('articles.warningSelectCategory')}
        </p>
      )}
      <RestInputItem
        required
        source="heading"
        header="common.heading"
        placeholder="common.heading"
        isValidatorEmpty
      />
      <RestInputItem
        source="subheading"
        header="common.subHeading"
        placeholder="common.subHeading"
      />
      <RestInputItem
        source="externalUrl"
        header="articles.externalUrl"
        placeholder="articles.externalUrl"
        rules={[
          {
            type: 'url',
            message: i18next.t('error.invalidUrl'),
          },
        ]}
        onChange={(value) => setExternalUrl(value.target.value)}
      />

      <Row gutter={16}>
        <Col span={12}>
          <RestInputItem
            source="isSponsored"
            header="articles.sponsored"
            ContentComponent={Switch}
            valuePropName="checked"
          />
        </Col>

        <Col span={12}>
          <RestInputItem
            source="isRecommended"
            header="articles.recommended"
            ContentComponent={Switch}
            valuePropName="checked"
          />
        </Col>
      </Row>

      <RestEditor
        required={
          isEdit
            ? !content?.length > 0 && !externalUrL?.length > 0
            : !externalUrL?.length > 0
        }
        source="content"
        header="common.content"
        onChange={(content) => setContent(content)}
        prefixKey={PREFIX_KEY_UPLOAD_ARTICLE}
      />
    </div>
  );
};

ArticleForm.propTypes = {
  isEdit: PropTypes.bool,
};

export default ArticleForm;
