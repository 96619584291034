import { makeActions } from 'redux/crudCreator/actions';

export const MODEL_NAME = 'childcareServiceTypes';
export const childcareServiceTypesActions = makeActions(MODEL_NAME);

export const getAllChildcareServiceTypes = childcareServiceTypesActions.getAll;
export const editChildcareServiceTypes = childcareServiceTypesActions.edit;
export const createChildCareServiceTypes = childcareServiceTypesActions.create;
export const getByIdChildcareServiceTypes =
  childcareServiceTypesActions.getDataById;
export const delChildcareServiceTypes = childcareServiceTypesActions.del;
