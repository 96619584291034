import styled from 'styled-components';

export const UserTagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .ant-avatar {
    margin-right: 10px;
    min-width: 32px;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .link-item {
    word-break: break-word;
    color: ${({ theme }) => theme.text.primary};
  }

  .link-class {
    &:hover,
    &:active {
      color: ${({ theme }) => theme.palette.primary} !important;
    }
  }

  .none-link-class {
    cursor: default;
  }

  .avatar-item {
    display: inline-flex;
  }
  .text-style {
    color: #8d8a8a;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
`;
