import React from 'react';
import PropTypes from 'prop-types';
import FilterColumnCustom from 'components/common/FilterColumnCustom';
import styled from 'styled-components';
import RestInputItem from 'components/RestInput/RestInputItem';

const ProviderColumnFilter = ({ retrieveList, placeholder, source }) => {
  const onSubmitFilter = (values) => {
    retrieveList({
      filter: {
        ...values,
        offset: 0,
      },
      keepFilter: false,
    });
  };

  const onClearFilter = () => {
    retrieveList({
      filter: {
        offset: 0,
      },
      keepFilter: false,
    });
  };

  return (
    <FilterColumnCustomWrapper>
      <FilterColumnCustom
        onSubmitFilter={onSubmitFilter}
        onClearFilter={onClearFilter}
      >
        <RestInputItem
          source={source}
          placeholder={placeholder}
          isShowTooltip
        />
      </FilterColumnCustom>
    </FilterColumnCustomWrapper>
  );
};

ProviderColumnFilter.propTypes = {
  retrieveList: PropTypes.func,
  placeholder: PropTypes.string,
  source: PropTypes.string,
};

export const FilterColumnCustomWrapper = styled.div`
  padding: 10px;
`;

export default ProviderColumnFilter;
