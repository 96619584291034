import React from 'react';
import { isEmpty } from 'lodash-es';
import Create from 'containers/rest/Create';
import i18next from 'i18next';
import { PREFIX_URL } from 'configs/localData/constant';
import Form from '../components/Form';

const ServiceTypesCreate = (props) => (
  <Create
    {...props}
    resource="childcareServiceTypes"
    formatOnSubmit={({ code, logo, ...data }) => ({
      ...data,
      ...(!isEmpty(logo)
        ? { logo: { key: logo.key, url: logo.url } }
        : { logo: {} }),
      code: code.toUpperCase(),
    })}
    header={i18next.t('button.addNewItem')}
    okText="button.save"
    defaultOptions={{
      isTrimStr: true,
      isGetConfig: true,
      prefixUrl: PREFIX_URL.superAdmin,
      customApiResource: 'primary-child-care-service',
    }}
  >
    <Form />
  </Create>
);

export default ServiceTypesCreate;
