import React from 'react';
import Edit from 'containers/rest/Edit';
import { useSelector } from 'react-redux';
import Form from '../components/Form';

const CRMPackagesEdit = (props) => {
  const currentData = useSelector((state) => state.CRMPackages.currentData);

  const formatOnSubmit = ({ businessFeaturesCentreLevel, ...data }) => ({
    ...data,
    businessFeaturesCentreLevel: currentData.businessFeaturesCentreLevel
      ? {
          ...currentData.businessFeaturesCentreLevel,
          limitEnquiries: {
            ...currentData.businessFeaturesCentreLevel?.limitEnquiries,
            value: businessFeaturesCentreLevel?.limitEnquiries?.value,
          },
        }
      : undefined,
  });

  return (
    <Edit
      {...props}
      resource="CRMPackages"
      defaultOptions={{ prefixUrl: 'super-admin' }}
      formatOnSubmit={formatOnSubmit}
    >
      <Form isEdit />
    </Edit>
  );
};

export default CRMPackagesEdit;
