import React from 'react';
import PropTypes from 'prop-types';

import RestInputItem from 'components/RestInput/RestInputItem';

const StripeTaxRateForm = ({ field }) => {
  const { name, fieldKey, ...restField } = field || {};

  return (
    <RestInputItem
      {...restField}
      source={name}
      fieldKey={fieldKey}
      placeholder="marketings.stripeTaxRateId"
    />
  );
};

StripeTaxRateForm.propTypes = {
  field: PropTypes.object,
};

export default StripeTaxRateForm;
