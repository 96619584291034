import React, { useMemo, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { useHistory, useLocation } from 'react-router';

import CRUDActions from 'redux/crudActions';
import { PRIMARY_KEY } from 'redux/crudCreator/dataProvider';
import crudSelectors from 'redux/crudSelectors';

import { getIdByUrl } from 'utils/tools';
import { handleApiError } from 'utils/validateUtils';

import Text from 'components/common/Text';
import RestEditComponent from 'components/RestLayout/Edit';
import { CloseSquareIcon } from 'components/common/SVGIcon';

const RestEdit = (props) => {
  const {
    showModal,
    visibleModal,
    header,
    resource,
    customOnSubmit,
    defaultOptions,
    isClearCurrent,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const id = getIdByUrl(props, location);

  const errorRequest = useSelector(crudSelectors[props.resource].getError);
  const record = useSelector(crudSelectors[props.resource].getCurrentData);
  const closeModal = () => {
    history.replace(`${location.pathname}${location.search}`);
  };
  const gotoShowPage = (id) =>
    history.push(`${location.pathname.replace('/:id/edit', '')}/${id}/show`);

  const onBack = () => {
    if (!visibleModal) {
      if (!props.isRedirect) return;
      history.goBack();
    } else {
      closeModal();
    }
  };

  const onSubmit = (data, form) => {
    if (customOnSubmit) {
      dispatch(
        customOnSubmit({
          id,
          data,
          options: {
            isBack: true,
            ...defaultOptions,
          },
        }),
      );
    } else
      dispatch(
        CRUDActions[resource].edit({
          data: {
            ...data,
            [PRIMARY_KEY]: id,
          },
          options: { isBack: true, ...defaultOptions },
        }),
      ).then(({ payload: { data } }) => {
        handleApiError(data, form);
        if (data.id && !(!defaultOptions || defaultOptions.isBack === false)) {
          onBack();
        }
      });
  };

  useLayoutEffect(() => {
    dispatch(
      CRUDActions[resource].getDataById({
        data: {
          [PRIMARY_KEY]: id,
        },
        options: {
          isRequestApi: true,
          isRefresh: true,
          ...defaultOptions,
          ...defaultOptions.getDataOptions,
        },
      }),
    );
    return () => {
      if (isClearCurrent) dispatch(CRUDActions[resource].clearCurrent());
    };
    // eslint-disable-next-line
  }, []);
  const content = useMemo(
    () => (
      <RestEditComponent
        {...props}
        resource={resource}
        header={header}
        customOnSubmit={customOnSubmit}
        showModal={showModal}
        onBack={onBack}
        onSubmit={onSubmit}
        record={record}
        error={errorRequest}
        gotoShowPage={gotoShowPage}
      />
    ),
    // eslint-disable-next-line
    [record],
  );
  return !showModal ? (
    content
  ) : (
    <>
      {header !== null && (
        <Text type="h3" className="modalTitleContent">
          <div className="modalTitle">
            {!header || typeof header === 'string'
              ? i18n.t(header || `${resource}.editPage`)
              : header}
          </div>
          <CloseSquareIcon onClick={onBack} className="modalBtnBack" />
        </Text>
      )}
      {content}
    </>
  );
};

RestEdit.propTypes = {
  resource: PropTypes.string,
  showModal: PropTypes.bool,
  header: PropTypes.string,
  visibleModal: PropTypes.bool,
  defaultOptions: PropTypes.shape({
    isShowSuccessNoti: PropTypes.bool,
    isBack: PropTypes.bool,
    getDataOptions: PropTypes.object,
  }),
  customOnSubmit: PropTypes.func,
  isRedirect: PropTypes.bool,
  isClearCurrent: PropTypes.bool,
};

RestEdit.defaultProps = {
  isRedirect: true,
  defaultOptions: {},
  isClearCurrent: true,
};

export default RestEdit;
