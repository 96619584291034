import { FilterFillIcon } from 'components/common/SVGIcon';
import TableCustom from 'components/common/TableCustom';
import { onChangeTable } from 'containers/Providers/utils';
import useDataFilterActivityTask from 'hooks/useDataFilterActivityTask';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { activitiesSelectors } from 'redux/activities/selectors';
import { formatActivityProviderType } from 'utils/formatFieldUtils';
import { formatDateTime } from 'utils/textUtils';
import GroupActions from './GroupActions';

const ActivitiesNotesTable = ({ retrieveList, resourceFilter }) => {
  const resourceData = useSelector(activitiesSelectors.getDataArr);
  const loading = useSelector(activitiesSelectors.getLoading);

  const { activityProviderTypeFilter } = useDataFilterActivityTask();

  const columns = [
    {
      title: i18next.t('activitiesAndNotes.activitiesNoteSubject'),
      dataIndex: 'subject',
    },
    {
      title: i18next.t('activitiesAndNotes.createdBy'),
      dataIndex: ['createdByUser', 'userName'],
    },
    {
      title: i18next.t('tasks.assignee'),
      dataIndex: 'assigneeUser',
      render: (data) => `${data?.firstName || ''} ${data?.lastName || ''}`,
    },
    {
      title: i18next.t('activitiesAndNotes.activityType'),
      dataIndex: 'type',
      render: formatActivityProviderType,
      filters: activityProviderTypeFilter,
      filterIcon: <FilterFillIcon />,
      width: 160,
    },
    {
      title: i18next.t('activitiesAndNotes.activityDate'),
      dataIndex: 'dueDate',
      render: formatDateTime,
      sorter: true,
      width: 160,
    },
    {
      title: '',
      dataIndex: 'id',
      width: 120,
      fixed: 'right',
      render: (id, record) => <GroupActions id={id} record={record} />,
    },
  ];

  const onChange = (e, filters, sorter) => {
    onChangeTable(e, filters, sorter, retrieveList);
  };

  return (
    <TableCustom
      isSorter
      isResetStyle
      xScroll={1000}
      onChange={onChange}
      data={resourceData}
      columns={columns}
      loading={loading}
      pagination={{
        pageSize: resourceFilter.limit,
        current: resourceFilter.offset / resourceFilter.limit + 1 || 1,
        total: resourceFilter.count,
      }}
    />
  );
};

ActivitiesNotesTable.propTypes = {
  retrieveList: PropTypes.func,
  resourceFilter: PropTypes.object,
};

export default ActivitiesNotesTable;
