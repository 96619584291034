import React from 'react';
import PropTypes from 'prop-types';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestAvatarInput from 'components/RestInput/RestAvatarInput';
import ReferenceInput from 'containers/rest/ReferenceInput';
import RestSelect from 'components/RestInput/RestSelect';
import { PREFIX_URL_API } from 'configs/localData/constant';

const BrandsForm = ({ isDetailCompany }) => (
  <div>
    <div className="flex justify-center">
      <RestAvatarInput
        style={{
          width: 140,
          height: 140,
          borderRadius: '50%',
        }}
        className="avatar-section"
        defaultText="Logo"
        defaultIcon={null}
        cropDimension={{ width: 300, height: 300 }}
        hasCrop={false}
        source="logo"
      />
    </div>
    <RestInputItem
      source="name"
      header="brands.name"
      required
      placeholder="brands.name"
    />
    {!isDetailCompany && (
      <ReferenceInput
        prefixUrl={PREFIX_URL_API}
        source="companyId"
        resource="companies"
        searchKey="q"
      >
        <RestSelect
          required
          titleProp="name"
          valueProp="id"
          header="brands.company"
          isFilterOption={false}
        />
      </ReferenceInput>
    )}
  </div>
);

BrandsForm.propTypes = {
  isDetailCompany: PropTypes.bool,
};

export default BrandsForm;
