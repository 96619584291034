import React, { createContext, useContext, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';

import { editCentres } from 'redux/centres/actions';
import { PREFIX_URL_API } from 'configs/localData/constant';

const initialValue = {
  id: null,
  updateCentre: () => null,
  collapsed: 'true',
  setCollapsed: (data) => data,
};

const CentreContext = createContext(initialValue);

export const CentreProvider = ({ children }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [collapsed, setCollapsed] = useState(true);

  const updateCentre = useCallback(
    (values) =>
      new Promise((resolve) => {
        if (id)
          resolve(
            dispatch(
              editCentres({
                data: { id, ...values },
                options: { prefixUrl: PREFIX_URL_API },
              }),
            ),
          );
      }),
    [id, dispatch],
  );

  return (
    <CentreContext.Provider
      value={{ id, updateCentre, collapsed, setCollapsed }}
    >
      {children}
    </CentreContext.Provider>
  );
};

CentreProvider.propTypes = {
  children: PropTypes.node,
};

export const useCentre = () => {
  const { id, updateCentre, collapsed, setCollapsed } =
    useContext(CentreContext);

  return {
    id,
    updateCentre,
    collapsed,
    setCollapsed,
  };
};
