import { makeCRUDSlice } from 'redux/crudCreator';
import {
  MODEL_NAME,
  companiesActions,
  getSummariesCompany,
  getSummariesProviderOfCompany,
} from './actions';

const getSummariesCompanySuccess = (state, { payload }) => {
  state.summaries = payload;
};
const getSummariesProvidersOfCompanySuccess = (state, { payload }) => {
  state.providersSummary = payload;
};

const slice = makeCRUDSlice(MODEL_NAME, companiesActions, {
  [getSummariesCompany.fulfilled]: getSummariesCompanySuccess,
  [getSummariesProviderOfCompany.fulfilled]:
    getSummariesProvidersOfCompanySuccess,
});

export default slice.reducer;
