import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { checkRole } from 'utils/tools';
import { GroupWrapper } from './styles';

const ActionGroup = ({
  children,
  elementProps,
  gotoEditPage,
  gotoShowPage,
  deleteItem,
  record,
  modelResource,
  hasCustomize,
}) => {
  const currentRole = useSelector((state) => state.auth.role);

  const checkRoleAction = (element) => {
    const acceptRole = checkRole(element.props.roles, currentRole);
    if (element.props.checkVisibleButton && acceptRole)
      return element.props.checkVisibleButton(record);
    return true;
  };

  const content = React.Children.map(children, (element) =>
    checkRoleAction(element)
      ? React.cloneElement(element, {
          resource: modelResource,
          gotoEditPage: element.props.gotoEditPage || gotoEditPage,
          gotoShowPage: element.props.gotoShowPage || gotoShowPage,
          deleteItem: element.props.deleteItem || deleteItem,
          record,
          key: element.props.source,
        })
      : null,
  );

  if (hasCustomize) {
    if (!record.isDefault) {
      return <GroupWrapper {...elementProps}>{content}</GroupWrapper>;
    }
    return null;
  }
  return <GroupWrapper {...elementProps}>{content}</GroupWrapper>;
};

ActionGroup.propTypes = {
  children: PropTypes.node,
  elementProps: PropTypes.object,
  record: PropTypes.object,
  gotoEditPage: PropTypes.func,
  gotoShowPage: PropTypes.func,
  deleteItem: PropTypes.func,
  source: PropTypes.string,
  fixed: PropTypes.string,
  widthAction: PropTypes.number,
  resource: PropTypes.string,
  icon: PropTypes.string,
  placement: PropTypes.string,
  modelResource: PropTypes.string,
  hasCustomize: PropTypes.bool,
};

ActionGroup.defaultProps = {
  source: 'actionGroup',
  fixed: 'right',
  placement: 'bottomRight',
};

export default ActionGroup;
