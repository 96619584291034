import React, { useCallback } from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Tabs } from 'antd';

import SummaryTab from '../SummaryTab';
import ActivitiesNotesTab from '../ActivitiesNotesTab';
import TasksTab from '../TasksTab';
import ActivitiesTitleTab from './ActivitiesTitleTab';
import TasksTitleTab from './TasksTitleTab';
import CentresTab from '../CentresTab';
import UsersTab from '../UsersTab';

const { TabPane } = Tabs;

const ProviderTabs = () => {
  const { push } = useHistory();

  const { id, modelDetail } = useParams();
  const currentData = useSelector((state) => state.providers.currentData) || {};

  const TABS = [
    {
      key: 'summary',
      title: i18next.t('providerDetail.tabs.summary'),
      TabComponent: SummaryTab,
    },
    {
      key: 'centres',
      title: i18next.t('centres.header'),
      TabComponent: CentresTab,
    },
    {
      key: 'users',
      title: i18next.t('users.header'),
      TabComponent: UsersTab,
    },
    {
      key: 'activitiesNotes',
      title: <ActivitiesTitleTab />,
      TabComponent: ActivitiesNotesTab,
    },
    {
      key: 'tasks',
      title: <TasksTitleTab />,
      TabComponent: TasksTab,
    },
  ];

  const onChange = useCallback(
    (key) => {
      push(`/providers/${id}/show/${key}`);
    },
    [push, id],
  );

  return (
    <div>
      <Tabs
        destroyInactiveTabPane
        activeKey={modelDetail || 'summary'}
        onChange={onChange}
      >
        {TABS.map((tab) => (
          <TabPane tab={tab.title} key={tab.key}>
            <tab.TabComponent record={currentData} />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default ProviderTabs;
