import { Modal, Switch } from 'antd';
import CentresField from 'components/CentresViewAll/CentresField';
import CentresModal from 'components/CentresViewAll/CentresModal';
import { IncognitoIcon } from 'components/common/SVGIcon';
import CustomButtonIcon from 'components/form/CustomButtonIcon';
import ActionGroup from 'components/RestActions/ActionGroup';
import DeleteButton from 'components/RestActions/DeleteButton';
import EditButton from 'components/RestActions/EditButton';
import RestFieldItem from 'components/RestField/RestFieldItem';
import UserInfo from 'components/RestField/UserInfo';
import { USER_TYPES_CONSTANT } from 'configs/localData';
import { PREFIX_URL_API } from 'configs/localData/constant';
import List from 'containers/rest/List';
import i18next from 'i18next';
import moment from 'moment';
import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { editBusinessUsers } from 'redux/businessUsers/actions';
import { getCentresOfUser } from 'redux/centresOfUser/actions';
import {
  checkLoginGodMode,
  clearCurrentUser,
  getAuthorizeWebPortal,
} from 'redux/users/actions';
import { formatDateTimeTable } from 'utils/textUtils';
import Filter from '../BusinessFilter';

const { confirm, warning } = Modal;

const BusinessUsersList = (props) => {
  const { push } = useHistory();
  const dispatch = useDispatch();

  const centresUserModalRef = useRef();

  const openCentresUserModal = (record) => {
    centresUserModalRef.current.open({
      ...record,
      name: `${record.firstName || ''} ${record.lastName || ''}`,
    });
  };

  const formatCentreUsers = (data, record) => (
    <CentresField
      data={data}
      handleClickViewAll={() => openCentresUserModal(record)}
      roleName={record?.role?.name}
    />
  );

  const checkVisibleImpersonate = (record) =>
    record?.userType === USER_TYPES_CONSTANT.business.value;

  const formatFilter = useCallback(({ email, domain, createdAt, ...value }) => {
    let emailFilter;
    if (email.$like && !domain) {
      emailFilter = email;
    } else if (!email.$like && domain) {
      if (domain === 'internal') {
        emailFilter = {
          $or: [{ $like: '@kindicare.com' }, { $like: '@enouvo.com' }],
        };
      } else
        emailFilter = {
          $and: [{ $notlike: '@kindicare.com' }, { $notlike: '@enouvo.com' }],
        };
    } else if (email.$like && domain) {
      if (domain === 'internal') {
        emailFilter = {
          $or: [
            { $and: [email, { $like: '@kindicare.com' }] },
            { $and: [email, { $like: '@enouvo.com' }] },
          ],
        };
      } else {
        emailFilter = {
          $and: [
            { $and: [email, { $notlike: '@kindicare.com' }] },
            { $and: [email, { $notlike: '@enouvo.com' }] },
          ],
        };
      }
    }

    return {
      ...value,
      email: emailFilter,
      createdAt: createdAt
        ? {
            $gt: createdAt[0]
              ? moment(createdAt[0]).startOf('day').toISOString()
              : undefined,
            $lt: createdAt[1]
              ? moment(createdAt[1]).endOf('day').toISOString()
              : undefined,
          }
        : undefined,
    };
  }, []);

  const handleBlockUser = (value, record) => {
    Modal.confirm({
      title: i18next.t(
        record?.isBlocked
          ? 'users.unBlockUserConfirmTitle'
          : 'users.blockUserConfirmTitle',
      ),
      content: i18next.t(
        record?.isBlocked
          ? 'users.unlockUserConfirmContent'
          : 'users.blockUserConfirmContent',
      ),
      onOk: () =>
        dispatch(
          editBusinessUsers({
            data: { id: record?.id, isBlocked: value },
            options: { prefixUrl: PREFIX_URL_API },
          }),
        ),
    });
  };

  const handleLoginCRMApp = (value, record) => {
    Modal.confirm({
      title: i18next.t(
        record?.isAllowedCRMApp
          ? 'users.disallowLoginAppTitle'
          : 'users.allowLoginAppTitle',
      ),
      content: i18next.t(
        record?.isAllowedCRMApp
          ? 'users.disallowLoginAppConfirm'
          : 'users.allowLoginAppConfirm',
      ),
      onOk: () =>
        dispatch(
          editBusinessUsers({
            data: { id: record?.id, isAllowedCRMApp: value },
            options: { prefixUrl: PREFIX_URL_API },
          }),
        ),
    });
  };

  const handleActiveInsights = (value, record) => {
    Modal.confirm({
      title: i18next.t(
        record?.isAccessInsightFeature
          ? 'users.disallowActiveInsights'
          : 'users.allowActiveInsights',
      ),
      content: i18next.t(
        record?.isAccessInsightFeature
          ? 'users.disallowActiveInsigtsConfirm'
          : 'users.allowActiveInsightsConfirm',
      ),
      onOk: () =>
        dispatch(
          editBusinessUsers({
            data: { id: record?.id, isAccessInsightFeature: value },
            options: { prefixUrl: PREFIX_URL_API },
          }),
        ),
    });
  };

  const handleActiveOnJobs = (value, record) => {
    Modal.confirm({
      title: i18next.t(
        record?.isActiveOnJobModule
          ? 'users.disallowActiveOnJob'
          : 'users.allowActiveOnJob',
      ),
      content: i18next.t(
        record?.isActiveOnJobModule
          ? 'users.disallowActiveOnJobConfirm'
          : 'users.allowActiveOnJobConfirm',
      ),
      onOk: () =>
        dispatch(
          editBusinessUsers({
            data: { id: record?.id, isActiveOnJobModule: value },
            options: { prefixUrl: PREFIX_URL_API },
          }),
        ),
    });
  };

  const handleRedirect = useCallback(
    async (record) => {
      await dispatch(getAuthorizeWebPortal({ userId: record.id })).then(
        ({ payload }) => {
          if (payload?.accessToken) {
            window.open(
              `${process.env.REACT_APP_CRM_WEB_URL}?authToken=${payload?.accessToken}`,
              '_blank',
            );
          }
        },
      );
    },
    [dispatch],
  );

  const handleImpersonating = (record) => {
    dispatch(checkLoginGodMode({ userId: record?.id })).then(({ payload }) => {
      if (payload?.canLoginGodMode) {
        confirm({
          title: i18next.t('button.impersonate'),
          content: i18next.t('users.impersonateConfirmDesc'),
          onOk: () => {
            handleRedirect(record);
          },
        });
      } else {
        warning({
          title: i18next.t('users.titleImpersonateNoCenter'),
          content: i18next.t('users.descImpersonateNoCenter'),
        });
      }
    });
  };

  const handleClickView = (id) => {
    dispatch(clearCurrentUser());
    push(`/users/${id}/show`);
  };

  const gotoCreatePageCustom = () => {
    push(`/users/create`);
  };

  return (
    <div>
      <List
        {...props}
        header={false}
        resource="businessUsers"
        hasSearch={false}
        filter={<Filter format={formatFilter} />}
        defaultOptions={{ prefixUrl: PREFIX_URL_API }}
        widthTable="2000px"
        gotoCreatePageCustom={gotoCreatePageCustom}
      >
        <RestFieldItem
          source="firstName"
          header="users.name"
          width="15%"
          format={(data, record) => (
            <UserInfo
              isLink={false}
              record={{
                ...record,
                fullName: `${data || ''} ${record?.lastName || ''}`,
              }}
              nameProp="fullName"
              roleProp="email"
            />
          )}
        />
        <RestFieldItem source="jobTitle" header="users.jobTitle" />
        <RestFieldItem source={['role', 'description']} header="users.role" />
        <RestFieldItem
          source="centres"
          header="centres.header"
          format={formatCentreUsers}
        />
        <RestFieldItem
          sorter
          source="lastActiveAt"
          header="common.lastActiveAt"
          format={formatDateTimeTable}
        />
        <RestFieldItem
          sorter
          source="createdAt"
          header="common.createdAt"
          format={formatDateTimeTable}
        />
        <RestFieldItem
          source="isAllowedCRMApp"
          header="users.activeCRMApp"
          valueProp="checked"
          component={<Switch />}
          customOnChange={handleLoginCRMApp}
        />
        <RestFieldItem
          source="isAccessInsightFeature"
          header="users.activeInsights"
          valueProp="checked"
          component={<Switch />}
          customOnChange={handleActiveInsights}
          width="11%"
        />
        <RestFieldItem
          source="isActiveOnJobModule"
          header="users.activeOnJobsModule"
          valueProp="checked"
          component={<Switch />}
          customOnChange={handleActiveOnJobs}
          width="10%"
        />
        <RestFieldItem
          source="isBlocked"
          header="users.blocked"
          valueProp="checked"
          component={<Switch />}
          customOnChange={handleBlockUser}
        />
        <ActionGroup widthAction={160}>
          <CustomButtonIcon
            checkVisibleButton={checkVisibleImpersonate}
            title="button.impersonate"
            IconComponent={IncognitoIcon}
            handleClick={handleImpersonating}
          />
          <EditButton isView onClickCustom={handleClickView} />
          <DeleteButton customMessage="users.customDescDelete" />
        </ActionGroup>
      </List>

      <CentresModal
        ref={centresUserModalRef}
        title="users.centresUserModalTitle"
        resource="centresOfUser"
        retrieveListAction={getCentresOfUser}
      />
    </div>
  );
};

export default BusinessUsersList;
