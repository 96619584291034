import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import ModalCustom from 'components/common/ModalCustom';
import {
  CUSTOMISE_BUTTONS_CONST,
  PREFIX_URL,
} from 'configs/localData/constant';
import { editAppSettingsCentre, getByIdCentres } from 'redux/centres/actions';
import { useDispatch } from 'react-redux';

const CustomiseButtonsModal = forwardRef(({ id }, ref) => {
  const [loading, setLoading] = useState();

  const [visible, setVisible] = useState();

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  useImperativeHandle(
    ref,
    () => ({
      open: (initialValue) => {
        setVisible(true);
        form.setFieldsValue(initialValue);
      },
    }),
    [], // eslint-disable-line
  );

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const onOk = () => {
    form.validateFields().then((values) => {
      setLoading(true);

      dispatch(
        editAppSettingsCentre({
          id,
          data: values,
        }),
      )
        .then(({ payload }) => {
          if (payload?.isSuccess) {
            handleCancel();
            dispatch(
              getByIdCentres({
                data: { id },
                options: {
                  isRequestApi: true,
                  prefixUrl: PREFIX_URL.superAdmin,
                },
              }),
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <ModalCustom
      title={i18next.t('centreDetail.customiseButtons')}
      onCancel={handleCancel}
      onOk={onOk}
      loading={loading}
      visible={visible}
    >
      <div>
        <Form form={form} layout="vertical">
          <Form.Item
            name={CUSTOMISE_BUTTONS_CONST.applyBtn.key}
            label={i18next.t('button.apply')}
            className="form-item-empty-label"
            rules={[
              {
                type: 'url',
                message: i18next.t('error.url'),
              },
            ]}
          >
            <Input placeholder={i18next.t('input.apply.placeholder')} />
          </Form.Item>
          <Form.Item
            name={CUSTOMISE_BUTTONS_CONST.bookATourOrVisit.key}
            label={i18next.t('button.bookATourOrVisit')}
            className="form-item-empty-label"
            rules={[
              {
                type: 'url',
                message: i18next.t('error.url'),
              },
            ]}
          >
            <Input
              placeholder={i18next.t('input.bookATourOrVisit.placeholder')}
            />
          </Form.Item>
        </Form>
      </div>
    </ModalCustom>
  );
});

CustomiseButtonsModal.propTypes = {
  id: PropTypes.string,
};

export default CustomiseButtonsModal;
