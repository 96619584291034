import { createSlice } from '@reduxjs/toolkit';
import { getEventStatisticChart, getEventStatisticInfo } from './actions';

export const initialState = {
  eventFilter: {},
  eventLineChart: {
    loading: false,
    data: {},
  },
  eventInfo: {
    loading: false,
    data: [],
  },
};

const { reducer, actions } = createSlice({
  name: 'Statistic',
  initialState,
  reducers: {
    setEventFilterStatistic: (state, { payload }) => {
      state.eventFilter = payload;
    },
  },
  extraReducers: {
    [getEventStatisticChart.pending]: (state) => {
      state.eventLineChart.loading = true;
      state.eventLineChart.data = {};
    },
    [getEventStatisticChart.fulfilled]: (state, { payload }) => {
      state.eventLineChart.loading = false;
      state.eventLineChart.data = payload;
    },
    [getEventStatisticChart.rejected]: (state) => {
      state.eventLineChart.loading = false;
    },

    [getEventStatisticInfo.pending]: (state) => {
      state.eventInfo.loading = true;
      state.eventInfo.data = [];
    },
    [getEventStatisticInfo.fulfilled]: (state, { payload }) => {
      state.eventInfo.loading = false;
      state.eventInfo.data = payload;
    },
    [getEventStatisticInfo.rejected]: (state) => {
      state.eventInfo.loading = false;
    },
  },
});

export const { setEventFilterStatistic } = actions;

export default reducer;
