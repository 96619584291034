import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import i18next from 'i18next';

import ModalCustom from 'components/common/ModalCustom';
import CentreOfProviderList from './CentreOfProviderList';

const CentresProviderModal = forwardRef((_props, ref) => {
  const [visible, setVisible] = useState(false);
  const [providerId, setProviderId] = useState();

  useImperativeHandle(
    ref,
    () => ({
      open: (data) => {
        setVisible(true);
        setProviderId(data);
      },
    }),
    [],
  );

  const providers = useSelector((state) => state.providersOfCompany?.data);

  const currentProvider = useMemo(() => {
    const resTex = providers?.find((item) => item.id === providerId)?.name;
    return resTex ? ` ${i18next.t(resTex)}` : '';
  }, [providerId]); // eslint-disable-line

  const onCancel = () => setVisible(false);

  return (
    <ModalCustom
      visible={visible}
      title={`${i18next.t(
        'providers.centresProviderModalTitle',
      )} ${currentProvider}`}
      footer={null}
      onCancel={onCancel}
      resetPaddingBottomBody={false}
      resetPaddingTopBody={false}
    >
      <CentreOfProviderList providerId={providerId} />
    </ModalCustom>
  );
});

export default CentresProviderModal;
