import React, { useState, forwardRef, useImperativeHandle } from 'react';
import i18next from 'i18next';
import Form from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';

import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { PREFIX_URL_API } from 'configs/localData/constant';

import { createTasks, getSummariesTasks } from 'redux/tasks/actions';
import {
  getAllActivities,
  getSummariesActivities,
} from 'redux/activities/actions';

import ModalCustom from 'components/common/ModalCustom';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { formatOnSubmit } from 'containers/Providers/utils';
import FormTask from './FormTask';

const CreateModal = forwardRef((props, ref) => {
  const [form] = useForm();
  const [visible, setVisible] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { filter, orderBy } = useSelector((state) => state.activities);

  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        setVisible(true);
      },
    }),
    [],
  );

  const refreshGetAllActivities = () => {
    if (!filter?.centreId?.$exists) {
      dispatch(
        getAllActivities({
          data: { offset: 0, orderBy, filter },
          options: { isRefresh: true, prefixUrl: PREFIX_URL_API },
        }),
      );
    }
  };

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const onOk = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        return dispatch(
          createTasks({
            data: {
              providerId: id,
              ...formatOnSubmit(values),
            },
            options: {
              prefixUrl: PREFIX_URL_API,
            },
          }),
        );
      })
      .then(({ payload }) => {
        setLoading(false);
        if (payload?.data?.id) {
          onCancel();
          refreshGetAllActivities();
          dispatch(getSummariesActivities({ providerId: id }));
          dispatch(getSummariesTasks({ providerId: id }));
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <ModalCustom
      title={i18next.t('tasks.createPage')}
      onCancel={onCancel}
      visible={visible}
      onOk={onOk}
      resetStyleForm
      width={800}
      resetPaddingTopBody={false}
      okText="button.create"
      loading={loading}
    >
      <RestInputContext.Provider value={{ form }}>
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          autoComplete="off"
          labelAlign="left"
        >
          <FormTask />
        </Form>
      </RestInputContext.Provider>
    </ModalCustom>
  );
});

export default CreateModal;
