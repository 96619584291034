import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Tooltip } from 'antd';
import { getRecordData } from 'utils/tools';
import FormSelect from '../../form/FormSelect';
import { RestInputContext } from '../RestInputContext';

const RestSelect = ({
  isShowTooltip,
  customDefaultValue,
  defaultValue,
  curData,
  ...props
}) => {
  const getDefaultValue = (record) => {
    if (customDefaultValue) {
      return customDefaultValue(record);
    }

    const value = getRecordData(record, props.source);
    return value !== undefined && value !== null ? value : defaultValue;
  };

  return (
    <RestInputContext.Consumer>
      {({ record, form }) => (
        <Tooltip
          placement="top"
          title={isShowTooltip ? i18next.t(props?.placeholder) : ''}
        >
          <div className="select-form-wrapper">
            <FormSelect
              {...props}
              form={form}
              curData={curData}
              defaultValue={getDefaultValue(record)}
              getPopupContainer={(trigger) => trigger.parentNode}
            />
          </div>
        </Tooltip>
      )}
    </RestInputContext.Consumer>
  );
};

RestSelect.propTypes = {
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  record: PropTypes.object,
  defaultValue: PropTypes.any,
  isShowTooltip: PropTypes.bool,
  customDefaultValue: PropTypes.func,
  placeholder: PropTypes.string,
  curData: PropTypes.object,
};

export default RestSelect;
