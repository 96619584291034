import React from 'react';

import { PropTypes } from 'prop-types';
import i18next from 'i18next';

import { Form, Input } from 'antd';
import ProvidersModal from '.';

const ProviderInfoModal = ({ onCancel, visible, description }) => (
  <ProvidersModal
    onCancel={onCancel}
    visible={visible}
    title="Edit Provider Information"
  >
    <Form.Item
      style={{ fontWeight: 700 }}
      label={i18next.t('providers.providerDescription')}
      initialValue={description}
      name="description"
    >
      <Input.TextArea
        placeholder={i18next.t('providers.providerDescription')}
        rows={10}
      />
    </Form.Item>
  </ProvidersModal>
);
ProviderInfoModal.propTypes = {
  onCancel: PropTypes.func,
  visible: PropTypes.bool,
  description: PropTypes.string,
};
export default ProviderInfoModal;
