import React from 'react';
import { Col, Row, Avatar } from 'antd';
import i18next from 'i18next';
import { UserOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import useFormatFnc from 'containers/DisputeCentres/List/useFormat';
import DetailStyles from './styles';

const DetailRequest = ({ record }) => {
  const useFormat = useFormatFnc();

  return (
    <DetailStyles>
      <Row gutter={10} className="row-container">
        <Col xl={6} lg={8} md={24} xs={24}>
          <div className="info-centre">
            <Avatar src={record.logo?.url} icon={<UserOutlined />} size={100} />
            <div className="name-centre">{record.centre?.name}</div>
            <div className="email-centre">
              {record.centre?.adminEmail || i18next.t('error.waitingUpdate')}
            </div>
          </div>
        </Col>
        <Col xl={18} lg={16} md={24} xs={24}>
          <div>
            <Row gutter={16} className="info-sender">
              <Col xl={8} lg={12} md={24} xs={24}>
                <span className="title-item">
                  {`${i18next.t('centreRequests.sender')}:`}
                </span>
                <span className="value-item">
                  {`${record.sender?.firstName || ''} ${
                    record.sender?.lastName || ''
                  }`}
                </span>
              </Col>
              <Col xl={8} lg={12} md={24} xs={24}>
                <span className="title-item">
                  {`${i18next.t('centreRequests.email')}:`}
                </span>
                <span className="value-item">
                  {record.sender?.email || i18next.t('error.waitingUpdate')}
                </span>
              </Col>
              <Col xl={8} lg={12} md={24} xs={24}>
                <span className="title-item">
                  {`${i18next.t('centreRequests.phone')}:`}
                </span>
                <span className="value-item">
                  {record.sender?.phoneNumber ||
                    i18next.t('error.waitingUpdate')}
                </span>
              </Col>
              <Col xl={8} lg={12} md={24} xs={24}>
                <span className="title-item">
                  {`${i18next.t('centreRequests.IPAddress')}:`}
                </span>
                <span className="value-item">
                  {record.IPAddress || i18next.t('error.waitingUpdate')}
                </span>
              </Col>
              <Col xl={8} lg={12} md={24} xs={24}>
                <span className="title-item">
                  {`${i18next.t('centreRequests.status')}:`}
                </span>
                <span className="value-item">
                  {useFormat.formatStatus(record.status, record)}
                </span>
              </Col>
            </Row>
            <div className="item-section">
              <span className="title-item">
                {`${i18next.t('centreRequests.reason')}:`}
              </span>
              <span className="value-item">
                {record.reason || i18next.t('centreRequests.emptyReason')}
              </span>
            </div>
            <div className="item-section">
              <span className="title-item">
                {`${i18next.t('centreRequests.message')}:`}
              </span>
              <span className="value-item">
                {record.message || i18next.t('centreRequests.emptyMessage')}
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </DetailStyles>
  );
};

DetailRequest.propTypes = {
  record: PropTypes.object,
};

DetailRequest.defaultProps = {
  record: {},
};

export default DetailRequest;
