import { createSlice } from '@reduxjs/toolkit';
import { unionBy } from 'lodash-es';
import {
  editAllClickThroughTracker,
  getAllClickThroughTracker,
  MODEL_NAME,
} from './actions';

export const initialState = {
  loading: false,
  data: [],
  resourceData: {
    filter: {},
    page: 1,
    size: 10,
    total: 0,
  },
};

const getAllClickThroughTrackerPending = (
  state,
  {
    meta: {
      arg: { data, options = {} },
    },
  },
) => {
  state.loading = true;

  if (options.isRefresh) {
    state.data = [];
    state.resourceData = {
      ...initialState.resourceData,
      ...data,
    };
  }
};

const getAllClickThroughTrackerSuccess = (state, { payload: { data } }) => {
  state.data = unionBy([...state.data, ...(data.results || [])], 'id');

  state.resourceData.total = data.total || 0;
  state.resourceData.page = data.page;

  state.loading = false;
  state.error = null;
};

const getAllClickThroughTrackerFail = (state, { payload: { data } }) => {
  state.error = data;
  state.loading = false;
};

const editClickThroughTrackerSuccess = (state, { payload }) => {
  state.data = state.data?.map((item) =>
    item.id === payload?.data?.result?.id ? { ...item, ...payload.data } : item,
  );
};

const { actions, reducer } = createSlice({
  name: MODEL_NAME,
  initialState,
  reducers: {
    resetResourceFilterAllClickThroughTracker: (state) => {
      state.resourceData = initialState.resourceData;
    },
  },
  extraReducers: {
    [getAllClickThroughTracker.pending]: getAllClickThroughTrackerPending,
    [getAllClickThroughTracker.fulfilled]: getAllClickThroughTrackerSuccess,
    [getAllClickThroughTracker.rejected]: getAllClickThroughTrackerFail,

    [editAllClickThroughTracker.fulfilled]: editClickThroughTrackerSuccess,
  },
});

export const { resetResourceFilterAllClickThroughTracker } = actions;

export default reducer;
