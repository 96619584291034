import { Row } from 'antd';
import styled from 'styled-components';

export const SummariesProviderStyle = styled(Row)`
  margin-bottom: 22px;
  .list-card {
    float: left;
    width: 20%;
    padding: 10px;
  }
  @media (max-width: 1200px) {
    .list-card {
      width: 50%;
    }
  }
  @media (max-width: 992px) {
    .list-card {
      width: 100%;
    }
  }
`;
