import { PREFIX_API_VER_2, PREFIX_URL } from 'configs/localData/constant';
import { del, get } from './utils';

export const getSummariesCompanyApi = (data) =>
  get(`/companies/summaries`, data, {}, PREFIX_URL.superAdmin);

export const getCentresOfCompanyApi = (id, data) =>
  get(`/companies/${id}/centres`, data, {}, PREFIX_URL.superAdmin);

export const getSummariesCentresOfCompanyApi = (id, data) =>
  get(`/companies/${id}/centres/summary`, data, {}, PREFIX_URL.superAdmin);

export const getSummariesProvidersOfCompanyApi = (id, data) =>
  get(`/companies/${id}/providersSummary`, data, {}, PREFIX_URL.superAdmin);

export const getProvidersOfCompanyApi = (id, data) =>
  get(`/companies/${id}/providers`, data, {}, PREFIX_URL.superAdmin);

export const getUsersOfCompanyApi = (companyId, data) =>
  get(
    `/companies/users`,
    { companyId, ...data },
    {},
    PREFIX_URL.superAdmin,
    PREFIX_API_VER_2,
  );

export const delUserOfCompanyApi = (id, data) =>
  del(`/companies/${id}/users`, data, {}, PREFIX_URL.superAdmin);

export const getBrandsOfCompanyApi = (id, data) =>
  get(`/companies/${id}/brands`, data, {}, PREFIX_URL.superAdmin);

export const getEnquiriesOfCompanyApi = (id, data) =>
  get(`/companies/${id}/enquiries`, data, {}, PREFIX_URL.superAdmin);

export const getApplicationsOfCompanyApi = (id, data) =>
  get(`/companies/${id}/applications`, data, {}, PREFIX_URL.superAdmin);

export const getCentreIdsOfCompanyApi = (id, data) =>
  get(`/companies/${id}/centreId`, data, {}, PREFIX_URL.superAdmin);

export const getProvidersLevelOfCompanyApi = (data) =>
  get(`/companies/activities`, data, {}, PREFIX_URL.superAdmin);
