import React from 'react';
import Edit from 'containers/rest/Edit';
import Form from '../components/Form';
import { PREFIX_URL_API } from '../../../configs/localData/constant';

const CompaniesEdit = (props) => (
  <Edit
    {...props}
    resource="companies"
    defaultOptions={{
      prefixUrl: PREFIX_URL_API,
    }}
  >
    <Form />
  </Edit>
);

export default CompaniesEdit;
