import { Form } from 'antd';
import ModalCustom from 'components/common/ModalCustom';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { handleApiError } from 'utils/validateUtils';
import { useCentre } from '../../Show/context';
import FormNormalStyles from './styles';

const CentreModal = ({
  onCancel,
  title,
  children,
  record,
  formatOnSubmit,
  ...props
}) => {
  const [form] = Form.useForm();

  const { validateFields, resetFields } = form;

  const { updateCentre } = useCentre();

  const [loading, setLoading] = useState(false);

  const onOk = () => {
    validateFields()
      .then(({ urlVideo, displayAddress, businessName, ...values }) => {
        setLoading(true);
        updateCentre(formatOnSubmit(values))
          .then(({ payload }) => {
            handleApiError(payload?.data, form);
            if (payload?.data?.id) {
              handleCancel();
            }
          })
          .finally(() => setLoading(false));
      })
      .catch((error) => {
        form.scrollToField(error.errorFields?.[0]?.name?.[0]);
      });
  };

  const handleCancel = () => {
    onCancel();
    resetFields();
  };

  return (
    <ModalCustom
      title={i18next.t(title)}
      onCancel={handleCancel}
      onOk={onOk}
      loading={loading}
      {...props}
    >
      <FormNormalStyles>
        <Form form={form} layout="vertical" scrollToFirstError>
          <RestInputContext.Provider value={{ form, record }}>
            {children}
          </RestInputContext.Provider>
        </Form>
      </FormNormalStyles>
    </ModalCustom>
  );
};

CentreModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
  isDisabled: PropTypes.bool,
  record: PropTypes.object,
  formatOnSubmit: PropTypes.func,
};

CentreModal.defaultProps = {
  formatOnSubmit: (value) => value,
};

export default CentreModal;
