import React, { useCallback } from 'react';
import {
  formatApplicationStatus,
  formatApplicationValue,
  formatCentreName,
  formatSubscription,
  formatUser,
} from 'utils/formatFieldUtils';
import { formatDateTimeTable, formatLabelTable } from 'utils/textUtils';
import { LABEL_TABLE_KEY, PREFIX_URL_API } from 'configs/localData/constant';

import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import { formatTimeFilterSubmit } from 'utils/dataUtils';
import { useSelector } from 'react-redux';
import Filter from '../components/Filter';

import ServiceApplied from './ServiceApplied';

const ApplicationsList = (props) => {
  const formatFilter = useCallback(
    ({ createdAt, ...data }) => ({
      ...data,
      createdAt: formatTimeFilterSubmit(createdAt),
    }),
    [],
  );

  const appSettings = useSelector((state) => state.config?.data?.AppSetting);

  return (
    <List
      {...props}
      resource="applications"
      defaultOptions={{ prefixUrl: PREFIX_URL_API }}
      widthTable={1800}
      hasSearch={false}
      hasCreate={false}
      filter={<Filter format={formatFilter} />}
    >
      <RestFieldItem
        sorter
        source="name"
        header="centres.centreName"
        format={(data, record) => formatCentreName(data, record?.centre)}
        width={250}
      />

      <RestFieldItem
        source="centre.CRMPackage.name"
        header="centres.subscription"
        format={formatSubscription}
      />

      <RestFieldItem source="centre.phone" header="centres.centrePhone" />

      <RestFieldItem
        source="careOption.centreServices"
        header={formatLabelTable(LABEL_TABLE_KEY.serviceType, appSettings)}
        format={(data) => <ServiceApplied centreServices={data} />}
      />

      <RestFieldItem
        source="careOption.value"
        header="applications.opportunityValue"
        format={formatApplicationValue}
      />
      <RestFieldItem
        source="status"
        header="applications.applicationStatus"
        format={formatApplicationStatus}
      />
      <RestFieldItem
        source="user"
        header="users.appUser"
        format={formatUser}
        width={250}
      />
      <RestFieldItem source="user.phoneNumber" header="users.userPhone" />
      <RestFieldItem
        sorter
        source="createdAt"
        header="common.time"
        format={formatDateTimeTable}
      />
      <RestFieldItem
        sorter
        source="updatedAt"
        header="common.lastUpdated"
        format={formatDateTimeTable}
      />
    </List>
  );
};

export default ApplicationsList;
