import React, { createContext, useContext, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';

import { PREFIX_URL_API } from 'configs/localData/constant';
import { editProviders } from 'redux/providers/actions';

const initialValue = {
  id: null,
  updateProvider: () => null,
};

const ProviderContext = createContext(initialValue);

export const Provider = ({ children }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const updateProvider = useCallback(
    (values) =>
      new Promise((resolve) => {
        if (id)
          resolve(
            dispatch(
              editProviders({
                data: { id, ...values },
                options: { prefixUrl: PREFIX_URL_API },
              }),
            ),
          );
      }),
    [id, dispatch],
  );

  return (
    <ProviderContext.Provider value={{ id, updateProvider }}>
      {children}
    </ProviderContext.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node,
};

export const useProvider = () => {
  const { id, updateProvider } = useContext(ProviderContext);

  return {
    id,
    updateProvider,
  };
};
