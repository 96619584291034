import React from 'react';
import RestInputItem from 'components/RestInput/RestInputItem';
import { Col, Row } from 'antd';
import RestSelect from 'components/RestInput/RestSelect';
import i18next from 'i18next';
import { DISPUTE_STATUS_TYPES, REASON_TYPES } from 'configs/localData';
import RestRangePickerInput from 'components/RestInput/RestRangePickerInput';

const Filter = () => (
  <Row gutter={16}>
    <Col lg={6} md={12} xs={12}>
      <RestInputItem
        source="centreName"
        placeholder="disputeReviewRequests.centreName"
        isShowTooltip
      />
    </Col>
    <Col lg={5} md={12} xs={12}>
      <RestSelect
        source="reason"
        placeholder="disputeReviewRequests.reason.title"
        isShowTooltip
        resourceData={REASON_TYPES}
        valueProp="value"
        titleProp="text"
        formatText={(text) => i18next.t(text)}
      />
    </Col>
    <Col lg={5} md={12} xs={12}>
      <RestSelect
        source="status"
        placeholder="disputeReviewRequests.status"
        isShowTooltip
        resourceData={DISPUTE_STATUS_TYPES}
        valueProp="value"
        titleProp="text"
        formatText={(text) => i18next.t(text)}
      />
    </Col>
    <Col lg={8} md={12} xs={12}>
      <RestRangePickerInput
        source="disputeAt"
        placeholder="common.time"
        isShowTooltip
        sourceGt="disputeAt.$gt"
        sourceLt="disputeAt.$lt"
      />
    </Col>
  </Row>
);

export default Filter;
