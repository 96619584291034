import React, { useState } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import ModalCustom from 'components/common/ModalCustom';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { handleApiError } from 'utils/validateUtils';
import { useCompany } from 'containers/Company/Show/context';
import { isEmpty } from 'lodash-es';

const CompaniesModal = ({ onCancel, title, children, record, ...props }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { validateFields, resetFields } = form;
  const { updateCompany } = useCompany();
  const onOk = () => {
    validateFields()
      .then(({ userId, ...values }) => {
        setLoading(true);
        updateCompany({
          ...values,
          userId: userId === undefined ? null : userId,
        })
          .then(({ payload }) => {
            handleApiError(payload?.data, form);
            if (isEmpty(payload?.data?.errors)) {
              onCancel();
            }
          })
          .finally(() => setLoading(false));
      })
      .catch((error) => {
        form.scrollToField(error.errorFields?.[0]?.name?.[0]);
      });
  };

  const handleCancel = () => {
    onCancel();
    resetFields();
  };
  return (
    <ModalCustom
      title={i18next.t(title)}
      onCancel={handleCancel}
      onOk={onOk}
      loading={loading}
      {...props}
      width={1000}
    >
      <Form form={form} layout="vertical" scrollToFirstError>
        <RestInputContext.Provider value={{ form, record }}>
          {children}
        </RestInputContext.Provider>
      </Form>
    </ModalCustom>
  );
};

CompaniesModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
  isDisabled: PropTypes.bool,
  record: PropTypes.object,
};

export default CompaniesModal;
