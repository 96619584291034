import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Space } from 'antd';
import { ServiceAppliedTableStyled } from './styles';

const ServiceApplied = ({ centreServices }) => (
  <ServiceAppliedTableStyled>
    <Space direction="vertical">
      {centreServices.map((item, index) => (
        <div key={String(index)} className="service-item-wrapper">
          {item?.serviceType?.name || i18next.t('error.waitingUpdate')}
        </div>
      ))}
    </Space>
  </ServiceAppliedTableStyled>
);

ServiceApplied.propTypes = {
  centreServices: PropTypes.array,
};

ServiceApplied.defaultProps = {
  centreServices: [],
};

export default ServiceApplied;
