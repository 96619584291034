import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import i18next from 'i18next';
import moment from 'moment';
import FormItemUI from '../FormItem';
import { RangePickerWrapper } from './styles';

const { RangePicker } = DatePicker;

const FormRangePicker = ({
  disabledDate,
  formatDate,
  allowClear,
  ...props
}) => {
  const { source } = props;

  const rangesFooter = useMemo(
    () => ({
      [i18next.t('dateTimePickerText.today')]: [
        moment().startOf('day'),
        moment().endOf('day'),
      ],
      [i18next.t('dateTimePickerText.yesterday')]: [
        moment().subtract(1, 'days').startOf('day'),
        moment().subtract(1, 'days').endOf('day'),
      ],
      [i18next.t('dateTimePickerText.last7Days')]: [
        moment().subtract(7, 'days').startOf('day'),
        moment().subtract(1, 'days').endOf('day'),
      ],
      [i18next.t('dateTimePickerText.lastMonth')]: [
        moment().subtract(1, 'months').startOf('month'),
        moment().subtract(1, 'months').endOf('month'),
      ],
    }),
    [],
  );

  return (
    <RangePickerWrapper>
      <FormItemUI {...props} ruleType="array" name={source}>
        <RangePicker
          disabledDate={disabledDate}
          format={formatDate}
          ranges={rangesFooter}
          allowClear={allowClear}
        />
      </FormItemUI>
    </RangePickerWrapper>
  );
};

FormRangePicker.propTypes = {
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  header: PropTypes.any,
  required: PropTypes.bool,
  requiredMessage: PropTypes.node,
  icon: PropTypes.string,
  form: PropTypes.object,
  defaultValue: PropTypes.any,
  formOptions: PropTypes.object,
  formatDate: PropTypes.string,
  allowClear: PropTypes.bool,
  disabledDate: PropTypes.func,
};

FormRangePicker.defaultProps = {
  formOptions: {},
  formatDate: 'MMM DD YYYY',
  allowClear: true,
};

export default FormRangePicker;
