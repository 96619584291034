import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const initialValue = {
  title: null,
  setTitle: (value) => value,

  description: null,
  setDescription: (value) => value,

  image: null,
  setImage: (value) => value,
};

const PreviewNotifyContext = createContext(initialValue);

export const PreviewNotifyProvider = ({ children }) => {
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [image, setImage] = useState();

  const currentData =
    useSelector((state) => state.notifications.currentData) || {};

  useEffect(() => {
    if (currentData.id) {
      setTitle(currentData.title);
      setDescription(currentData.text);
      setImage(currentData.image);
    }
  }, [currentData.id]); // eslint-disable-line

  const value = {
    title,
    setTitle,
    description,
    setDescription,
    image,
    setImage,
  };

  return (
    <PreviewNotifyContext.Provider value={value}>
      {children}
    </PreviewNotifyContext.Provider>
  );
};

PreviewNotifyProvider.propTypes = {
  children: PropTypes.node,
};

export const usePreviewNotify = () => {
  const { title, setTitle, description, setDescription, image, setImage } =
    useContext(PreviewNotifyContext);

  return {
    title,
    setTitle,
    description,
    setDescription,
    image,
    setImage,
  };
};
