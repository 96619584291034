import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { apiWrapper } from 'utils/reduxUtils';
import { delApi, getAllApi, getDataByIdApi, postApi, putApi } from 'api/crud';
import { getConfig } from '../config/actions';
import {
  convertRequestParams,
  convertResponseData,
  PRIMARY_KEY,
} from './dataProvider';

export const getAll = (resource) =>
  createAsyncThunk(`${resource}/getAll`, async (payload, thunkAPI) => {
    try {
      const { data = {}, options = {} } = payload;
      const { limit, offset, includes, filter, outsideFilter } =
        thunkAPI.getState()[resource];

      const { outsideFilter: outsideFilterPayload, ...dataPayload } =
        data || {};

      const convertRequest = convertRequestParams(
        'GET_ALL',
        {
          limit,
          offset,
          filter,
          includes,
          ...outsideFilter,
          ...outsideFilterPayload,
          ...dataPayload,
          ...(options.keepFilter && {
            filter: { ...filter, ...data.filter },
          }),
        },
        resource,
      );
      const response = await apiWrapper(
        { isShowProgress: options.isShowProgress },
        getAllApi,
        options.customApiResource || `${resource}`,
        convertRequest,
        options.prefixUrl,
      );
      const result = convertResponseData('GET_ALL', response);
      if (result.data) {
        return {
          data: {
            numberOfPages: Math.ceil(result.total / limit),
            ...result,
          },
          options,
        };
      }
      return thunkAPI.rejectWithValue({ data: response, options });
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  });

export const getDataById = (resource) =>
  createAsyncThunk(`${resource}/getDataById`, async (payload, thunkAPI) => {
    const { data, options = { isRequestApi: true, extraParams: {} } } = payload;
    try {
      if (!options.isRequestApi) {
        return { data, options: { isRequestApi: true } };
      }
      const response = await apiWrapper(
        { isShowProgress: options.isShowProgress },
        getDataByIdApi,
        options.customApiResource || `${resource}`,
        data[PRIMARY_KEY],
        options.extraParams,
        options.prefixUrl,
      );
      const result = convertResponseData('GET_BY_ID', response);
      if (result) {
        return { data: result };
      }
      return thunkAPI.rejectWithValue({ data: result, options });
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error, options });
    }
  });

export const edit = (resource) =>
  createAsyncThunk(`${resource}/edit`, async (payload, thunkAPI) => {
    const { data, options = {} } = payload;
    try {
      const convertRequest = convertRequestParams('EDIT', data, resource, {
        isTrimStr: options.isTrimStr,
      });
      const response = await apiWrapper(
        {
          isShowProgress: true,
          isShowSuccessNoti: true,
          isCheckError: true,
          ...options,
        },
        putApi,
        options.customApiResource || `${resource}`,
        data[PRIMARY_KEY],
        convertRequest,
        options.prefixUrl,
      );
      const result = convertResponseData('EDIT', response);
      if (result) {
        if (options.isGetConfig) thunkAPI.dispatch(getConfig());
        if (options.isGetAll) {
          const { limit, includes, filter } = thunkAPI.getState()[resource];
          thunkAPI.dispatch(
            getAll(resource)({
              data: { offset: 0, limit, includes, filter },
              options: { isRefresh: true, prefixUrl: options.prefixUrl },
            }),
          );
        }

        if (options.successAction) options.successAction({ data: result });

        return { data: { ...data, ...result } };
      }
      return thunkAPI.rejectWithValue({
        id: data[PRIMARY_KEY],
        data: response,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue({ id: data[PRIMARY_KEY], data: error });
    }
  });

export const create = (resource) =>
  createAsyncThunk(`${resource}/create`, async (payload, thunkAPI) => {
    const { data, options = {} } = payload;
    try {
      const convertRequest = convertRequestParams('CREATE', data, resource, {
        isTrimStr: options.isTrimStr,
      });
      const response = await apiWrapper(
        {
          isShowProgress: true,
          isShowSuccessNoti: true,
          isCheckError: true,
          ...options,
        },
        postApi,
        options.customApiResource || `${resource}`,
        convertRequest,
        options.prefixUrl,
      );
      const result = convertResponseData('CREATE', response);
      if (result) {
        if (options.isGetConfig) thunkAPI.dispatch(getConfig());
        if (options.isGetAll) {
          const { limit, includes, filter } = thunkAPI.getState()[resource];
          thunkAPI.dispatch(
            getAll(resource)({
              data: { offset: 0, limit, includes, filter },
              options: { isRefresh: true, prefixUrl: options.prefixUrl },
            }),
          );
        }

        if (options.successAction) options.successAction({ data: result });

        return {
          data: options.formatSave ? options.formatSave(result) : result,
        };
      }
      return thunkAPI.rejectWithValue({ data: response });
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  });

export const del = (resource) =>
  createAsyncThunk(`${resource}/del`, async (payload, thunkAPI) => {
    const { data, options = {} } = payload;
    try {
      const response = await apiWrapper(
        { isShowProgress: true, isShowSuccessNoti: true, ...options },
        delApi,
        options.customApiResource || `${resource}`,
        data[PRIMARY_KEY],
        options.prefixUrl,
      );
      const result = convertResponseData('DELETE', response);
      if (result) {
        if (options.isGetConfig) thunkAPI.dispatch(getConfig());

        if (options.successAction)
          options.successAction({ data, success: result.success });

        return { data, success: result.success };
      }
      return thunkAPI.rejectWithValue({
        id: data[PRIMARY_KEY],
        data: response,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue({ id: data[PRIMARY_KEY], data: error });
    }
  });

export const clearCurrent = (resource) =>
  createAction(`${resource}/clearCurrent`);

export const setIsDisabledButtonSubmit = (resource) =>
  createAction(`${resource}/setIsDisabledButtonSubmit`);

export const setCurrentData = (resource) =>
  createAction(`${resource}/setCurrentData`);

export const makeActions = (resource) => ({
  getAll: getAll(resource),
  getDataById: getDataById(resource),
  edit: edit(resource),
  create: create(resource),
  del: del(resource),
  clearCurrent: clearCurrent(resource),
  setIsDisabledButtonSubmit: setIsDisabledButtonSubmit(resource),
  setCurrentData: setCurrentData(resource),
});
