import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import {
  PhoneIcon,
  MailIcon,
  UserContactIcon,
  UserRoleIcon,
} from 'components/common/SVGIcon';
import { Link } from 'react-router-dom';

const BusinessUser = ({ record }) => (
  <div className="content-info-other ">
    <div className="item-info">
      <UserContactIcon />
      <span>
        {record ? (
          <Link to={`/users/${record?.id}/show`} className="text-black">
            {record?.firstName || ''} {record?.lastName || ''}
          </Link>
        ) : (
          i18next.t('error.waitingUpdate')
        )}
      </span>
    </div>
    <div className="item-info">
      <UserRoleIcon />
      <span>{record?.jobTitle || i18next.t('error.waitingUpdate')}</span>
    </div>
    <div className="item-info">
      <PhoneIcon />
      <span>{record?.phoneNumber || i18next.t('error.waitingUpdate')}</span>
    </div>
    <div className="item-info">
      <MailIcon />
      <span className="value-info-item">
        {record?.email || i18next.t('error.waitingUpdate')}
      </span>
    </div>
  </div>
);

BusinessUser.propTypes = {
  record: PropTypes.object,
};

export default BusinessUser;
