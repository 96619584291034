import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import { isEmpty } from 'lodash-es';
import { Form, Button, Row, Col } from 'antd';
import { FilterFormWrapper } from './styles';
import { RestInputContext } from '../../RestInput/RestInputContext';

const FormComponent = ({
  format,
  children,
  retrieveList,
  resourceFilter,
  keepInitialFilterAfterClear,
}) => {
  const [form] = Form.useForm();

  const onFilter = useCallback(() => {
    form.validateFields().then((values) => {
      retrieveList({
        ...resourceFilter,
        offset: 0,
        filter: {
          ...resourceFilter.filter,
          ...format(values),
        },
      });
    });
  }, [form, format, resourceFilter, retrieveList]);

  const onClear = (e) => {
    e.preventDefault();
    isEmpty(keepInitialFilterAfterClear)
      ? retrieveList({ filter: {} })
      : retrieveList(keepInitialFilterAfterClear);

    setTimeout(() => {
      form.resetFields();
    }, 200);
  };

  const inputContextValue = useMemo(
    () => ({
      form,
      record: resourceFilter.filter || {},
      handleSubmit: onFilter,
      allowPressEnter: true,
    }),
    [form, onFilter, resourceFilter.filter],
  );

  return (
    <FilterFormWrapper form={form}>
      <div className="box-filter">
        <Row gutter={24} className="row-filter-container">
          <Col lg={19} md={24} xs={24} className="col-filter-content">
            <RestInputContext.Provider value={inputContextValue}>
              {children}
            </RestInputContext.Provider>
          </Col>
          <Col lg={5} md={24} xs={24} className="col-actions">
            <div className="filterActions">
              <Button
                type="primary"
                onClick={onFilter}
                className="filterButton action-button"
              >
                {I18n.t('button.filter')}
              </Button>
              <Button onClick={onClear} className="clearButton action-button">
                {I18n.t('button.clearFilter')}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </FilterFormWrapper>
  );
};

FormComponent.propTypes = {
  children: PropTypes.node,
  retrieveList: PropTypes.func,
  format: PropTypes.func,
  keepInitialFilterAfterClear: PropTypes.object,
  resourceFilter: PropTypes.object,
};

FormComponent.defaultProps = {
  format: (values) => values,
};

export default FormComponent;
