import React from 'react';
import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import DeleteButton from 'components/RestActions/DeleteButton';

const ClientUsersList = (props) => (
  <List {...props} resource="clientUsers">
    <RestFieldItem source="firstName" header="clientUsers.firstName" />
    <RestFieldItem source="phoneNumber" header="clientUsers.phoneNumber" />
    <RestFieldItem source="lastActiveAt" header="clientUsers.lastActiveAt" />
    <RestFieldItem source="createdAt" header="clientUsers.createdAt" />
    <RestFieldItem source="isBlocked" header="clientUsers.isBlocked" />
    <ActionGroup>
      <EditButton />
      <DeleteButton />
    </ActionGroup>
  </List>
);

export default ClientUsersList;
