import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import i18next from 'i18next';

const ProviderImageInfo = ({ logo, size }) => (
  <div className="avatar-provider">
    <Avatar src={logo} size={size}>
      {i18next.t('providers.logo')}
    </Avatar>
  </div>
);

ProviderImageInfo.propTypes = {
  logo: PropTypes.string,
  size: PropTypes.number,
};

ProviderImageInfo.defaultProps = {
  size: 120,
};

export default ProviderImageInfo;
