import React from 'react';
import { Button, Tooltip } from 'antd';
import { ContactOutlineIcon } from 'components/common/SVGIcon';
import i18next from 'i18next';
import { useEnquiryContext } from 'containers/Enquiries/List/context';

const SeeContactBtn = () => {
  const { setCollapsed, collapsed } = useEnquiryContext();

  return collapsed ? (
    <Tooltip title={i18next.t('button.viewContact')}>
      <Button
        shape="circle"
        size="small"
        className="btn-info-fill"
        icon={<ContactOutlineIcon />}
        onClick={() => setCollapsed(false)}
      />
    </Tooltip>
  ) : null;
};

export default SeeContactBtn;
