import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { formatDate } from 'utils/textUtils';
import { EnvironmentOutlined, CalendarOutlined } from '@ant-design/icons';

import { CENTRE_STATUS_VALUE } from 'configs/localData';
import {
  CentreTypeIcon,
  ChildcareServiceTypeIcon,
  CompanyIcon,
  KindiCareIcon,
  PrNumberIcon,
  ProviderIcon,
  SENumberIcon,
} from 'components/common/SVGIcon';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import SubscriptionDropdown from '../SubscriptionDropdown';

const GeneralInfo = ({ record }) => (
  <div className="item-info-other">
    <div className="title-info-other">{i18next.t('centres.generalInfo')}</div>
    <div className="content-info-other">
      <div className="address-section item-info">
        <CompanyIcon />
        {record?.company?.name ? (
          <Tooltip title={i18next.t('centres.companyName')}>
            <Link
              to={`/companies/${record?.company?.id}/show/summary`}
              className="link-item link-class"
            >
              <span className="value-info-item">{record?.company?.name}</span>
            </Link>
          </Tooltip>
        ) : (
          <span className="value-info-item">
            {i18next.t('error.waitingUpdate')}
          </span>
        )}
      </div>
      <div className="address-section item-info">
        <ProviderIcon />
        {record?.provider?.name ? (
          <Tooltip title={i18next.t('centres.providerName')}>
            <Link
              to={`/providers/${record?.provider?.id}/show/summary`}
              className="link-item link-class"
            >
              <span className="value-info-item">{record?.provider?.name}</span>
            </Link>
          </Tooltip>
        ) : (
          <span className="value-info-item">
            {i18next.t('error.waitingUpdate')}
          </span>
        )}
      </div>
      <div className="item-info">
        <ChildcareServiceTypeIcon />
        {record?.primaryChildCareServiceId ? (
          <Tooltip title={i18next.t('centres.childcareServiceType')}>
            <span className="value-info-item">
              {record?.primaryChildCareService?.name}
            </span>
          </Tooltip>
        ) : (
          <span className="value-info-item">
            {i18next.t('error.waitingUpdate')}
          </span>
        )}
      </div>
      <div className="item-info">
        <CentreTypeIcon />
        {record?.type ? (
          <Tooltip title={i18next.t('centres.centreType')}>
            <span className="value-info-item">{record?.type}</span>
          </Tooltip>
        ) : (
          <span className="value-info-item">
            {i18next.t('error.waitingUpdate')}
          </span>
        )}
      </div>
      <div className="address-section item-info">
        <PrNumberIcon />
        {record?.governmentReferenceId ? (
          <Tooltip title={i18next.t('centres.prNumber')}>
            <span className="value-info-item">
              {record?.governmentReferenceId}
            </span>
          </Tooltip>
        ) : (
          <span className="value-info-item">
            {i18next.t('error.waitingUpdate')}
          </span>
        )}
      </div>
      <div className="address-section item-info">
        <SENumberIcon />
        {record?.governmentCentreServiceId ? (
          <Tooltip title={i18next.t('centres.seNumber')}>
            <span className="value-info-item">
              {record?.governmentCentreServiceId}
            </span>
          </Tooltip>
        ) : (
          <span className="value-info-item">
            {i18next.t('error.waitingUpdate')}
          </span>
        )}
      </div>

      <div className="address-section item-info">
        <EnvironmentOutlined />
        {record?.displayAddress ? (
          <Tooltip title={i18next.t('centres.address')}>
            <span className="value-info-item">
              {record?.displayAddress?.trim()}
            </span>
          </Tooltip>
        ) : (
          <span className="value-info-item">
            {i18next.t('error.waitingUpdate')}
          </span>
        )}
      </div>

      <div className="item-info">
        <CalendarOutlined />
        {record?.dateApplied ? (
          <Tooltip title={i18next.t('centres.dateApplied')}>
            <span className="value-info-item">
              {formatDate(record?.dateApplied)}
            </span>
          </Tooltip>
        ) : (
          <span className="value-info-item">
            {i18next.t('error.waitingUpdate')}
          </span>
        )}
      </div>
      {record?.claimStatus === CENTRE_STATUS_VALUE.claimed && (
        <div className="item-info">
          <KindiCareIcon />
          <SubscriptionDropdown
            className="item-info__subscription"
            CRMPackageId={record?.CRMPackage?.id}
            id={record?.id}
          />
        </div>
      )}
    </div>
  </div>
);
GeneralInfo.propTypes = {
  record: PropTypes.object,
};

GeneralInfo.defaultProps = {
  record: {},
};

export default GeneralInfo;
