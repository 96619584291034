import styled from 'styled-components';

export const FilterMessageStyles = styled.div`
  padding: 15px;
  .ant-radio-button-wrapper {
    border: none;
  }
  .ant-radio-button-wrapper:first-child {
    border-radius: 0;
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-radius: 8px;
    background: #db147f20;
    color: ${({ theme }) => theme.palette.primary};
    font-weight: 700;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    height: 0;
    width: 0;
  }
`;
