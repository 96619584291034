import i18next from 'i18next';
import React from 'react';
import PreviewNotiStyles from './styles';
import PhonePreview from './PhonePreview';

const PreviewNoti = () => (
  <PreviewNotiStyles className="notify-preview">
    <div className="notify-preview__title">
      {i18next.t('notifications.preview')}
    </div>

    <PhonePreview platform="IOS" />

    <PhonePreview platform="Android" />
  </PreviewNotiStyles>
);

export default PreviewNoti;
