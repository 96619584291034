import React from 'react';
import i18next from 'i18next';
import { Row, Col } from 'antd';
import { ACTIVE_TYPES } from 'configs/localData';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';

const Filter = (props) => (
  <Row {...props} gutter={16}>
    <Col lg={12} md={12} xs={24}>
      <RestInputItem
        source={['name', '$like']}
        placeholder="features.name"
        isShowTooltip
      />
    </Col>
    <Col lg={12} md={12} xs={24}>
      <RestSelect
        placeholder="features.isActive"
        source="isActive"
        isShowTooltip
        resourceData={ACTIVE_TYPES}
        valueProp="value"
        titleProp="text"
        formatText={(data) => i18next.t(data)}
        ruleType="boolean"
        isShowSearch={false}
      />
    </Col>
  </Row>
);

export default Filter;
