import React, { useRef } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';

import CardSection from 'components/common/CardSection';
import { MoreInformationStyles } from './styles';
import MoreCentreInfoModal from '../Modals/MoreCentreInfoModal';
import MobilePreviewModal from '../Modals/MobilePreviewModal';

const MoreInformation = ({ moreInformation, centreId }) => {
  const previewModalRef = useRef();
  const moreInfoModalRef = useRef();

  const openMobilePreview = (data) => {
    previewModalRef?.current && previewModalRef.current.open(data);
  };

  const openMoreInfoModal = () => {
    moreInfoModalRef?.current && moreInfoModalRef.current.toggleModal();
  };

  return (
    <div>
      <CardSection
        isEdit
        onClickButton={openMoreInfoModal}
        title="centreDetail.moreCentreInformation"
        permissionKey="myCentres"
        action="isEdit"
      >
        <MoreInformationStyles
          dangerouslySetInnerHTML={{
            __html: moreInformation || i18next.t('error.waitingUpdate'),
          }}
        />
      </CardSection>

      <MoreCentreInfoModal
        ref={moreInfoModalRef}
        openMobilePreview={openMobilePreview}
        moreInformation={moreInformation}
        centreId={centreId}
      />

      <MobilePreviewModal ref={previewModalRef} />
    </div>
  );
};

MoreInformation.propTypes = {
  moreInformation: PropTypes.string,
  centreId: PropTypes.string,
};

export default MoreInformation;
