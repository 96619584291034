import { PREFIX_URL } from 'configs/localData/constant';
import { del, get, post, put } from './utils';

export async function loginApi(params) {
  return post('/auth/login', params);
}

export async function logoutApi(data) {
  return post('/auth/logout', data);
}

export async function getPermissionsApi() {
  return get('/auth/permission');
}

export async function getPermissionByIdApi(id) {
  return get(`/auth/permission/${id}`);
}

export async function getCurrentUserApi(filter) {
  return get('/users/me', filter);
}

export async function updateCurrentUserApi(data) {
  return put('/users/me', data);
}

export async function createInstallationApi(params) {
  return post('/installations', params);
}

export async function updateInstallationApi(id, params) {
  return put(`/installations/${id}`, params);
}

export async function deleteInstallationApi(id) {
  return del(`/installations/${id}`);
}

export async function forgotPasswordApi(data) {
  return post('/auth/forgotPassword', data);
}

export async function resetPasswordApi(data) {
  return post('/auth/resetPassword', data);
}

export async function changePasswordApi(data) {
  return post('/auth/changePassword', data);
}

export async function registerApi(data) {
  return post('/auth/register', data);
}

export async function registerWithTokenApi(data) {
  return post('/auth/registerByToken', data);
}

export async function disableUserApi(id, isDisabled) {
  return put(`/users/${id}/disable`, { isDisabled });
}

export async function subscribeUserApi(data) {
  return post('/auth/subscribe', data);
}

export async function getCentresOfUserApi(id, data) {
  return get(`/users/${id}/centres`, data, {}, PREFIX_URL.superAdmin);
}

export async function getUnownedCentresOfUserApi(id, data) {
  return get(`/users/${id}/unowned-centres`, data, {}, PREFIX_URL.superAdmin);
}

export async function postCentresOfUserApi(id, data) {
  return post(`/users/${id}/centres`, data, {}, PREFIX_URL.superAdmin);
}

export async function delCentresOfUserApi(id, centreId) {
  return del(
    `/users/${id}/centres/${centreId}`,
    null,
    {},
    PREFIX_URL.superAdmin,
  );
}

export async function createUserApi(data) {
  return post('/users/provider-admin', data, {}, PREFIX_URL.superAdmin);
}

export async function getAuthorizeWebPortalApi(data) {
  return post('/auth/loginInGodMode', data, {}, PREFIX_URL.superAdmin);
}

export async function getProvidersOfUserApi(id, data) {
  return get(`/users/${id}/providers`, data, {}, PREFIX_URL.superAdmin);
}

export async function getUnownedProvidersOfUserApi(id, data) {
  return get(`/users/${id}/unowned-providers`, data, {}, PREFIX_URL.superAdmin);
}

export async function postProvidersOfUserApi(id, data) {
  return post(`/users/${id}/providers`, data, {}, PREFIX_URL.superAdmin);
}

export async function delProvidersOfUserApi(id, providerId) {
  return del(
    `/users/${id}/providers/${providerId}`,
    null,
    {},
    PREFIX_URL.superAdmin,
  );
}

export async function editRoleUserApi(id, data) {
  return put(`/users/${id}/roles`, data, {}, PREFIX_URL.superAdmin);
}

export async function checkLastUserApi(data) {
  return get(`/users/check-last-user?${data}`, null, {}, PREFIX_URL.superAdmin);
}

export async function unSubcriptionCentreApi(data) {
  return del('/centresUnsubscription', data, {}, PREFIX_URL.superAdmin);
}

export async function getCompaniesOfUserApi(id, data) {
  return get(`/users/${id}/companies`, data, {}, PREFIX_URL.superAdmin);
}

export async function getUnownedCompaniesOfUserApi(id, data) {
  return get(`/users/${id}/unowned-companies`, data, {}, PREFIX_URL.superAdmin);
}

export async function postCompaniesOfUserApi(id, data) {
  return post(`/users/${id}/companies`, data, {}, PREFIX_URL.superAdmin);
}

export async function delCompaniesOfUserApi(id, data) {
  return del(`/users/${id}/companies`, data, {}, PREFIX_URL.superAdmin);
}

export async function checkLoginGodModeApi(data) {
  return post(`/auth/god-mode`, data, {}, PREFIX_URL.superAdmin);
}
