import React, { useEffect, useCallback, useState } from 'react';
import { Button, Space } from 'antd';
import i18next from 'i18next';
import { useHistory, useParams } from 'react-router';
import Form, { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { getUnownedCompaniesOfUser } from 'redux/unownedCompaniesOfUser/actions';
import { postCompaniesOfUser } from 'redux/companiesOfUser/actions';

import { RestInputContext } from 'components/RestInput/RestInputContext';
import { FormAddMoreStyles } from 'containers/Users/styles';
import CompaniesAddMoreSelect from './CompaniesAddMoreSelect';
import CompanyFilter from '../Form/CompanyFilter';

const FormAddMoreCompanies = () => {
  const [form] = useForm();
  const dispatch = useDispatch();

  const { push } = useHistory();

  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, keepFilter }) => {
      dispatch(
        getUnownedCompaniesOfUser({
          id,
          data: {
            orderBy: 'name',
            ...filter,
            filter: filter.filter,
          },
          options: {
            keepFilter,
            isRefresh,
          },
        }),
      );
    },
    [id, dispatch],
  );

  useEffect(() => {
    retrieveList({
      filter: {
        limit: 10,
        offset: 0,
      },
      isRefresh: true,
    });
  }, [retrieveList]);

  const onBack = () => {
    push(`/users/${id}/show`);
  };

  const onFinish = (values) => {
    setLoading(true);
    dispatch(postCompaniesOfUser({ id, data: values }))
      .then(({ payload }) => {
        setLoading(false);
        if (payload?.success) {
          onBack();
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <FormAddMoreStyles>
      <CompanyFilter retrieveList={retrieveList} />
      <Form form={form} onFinish={onFinish}>
        <RestInputContext.Provider value={{ form, record: {} }}>
          <CompaniesAddMoreSelect retrieveList={retrieveList} />
        </RestInputContext.Provider>
        <div className="flex-center-end group-action-footer">
          <Space size={15}>
            <Button onClick={onBack}>{i18next.t('button.cancel')}</Button>
            <Button htmlType="submit" type="primary" loading={loading}>
              {i18next.t('button.save')}
            </Button>
          </Space>
        </div>
      </Form>
    </FormAddMoreStyles>
  );
};

export default FormAddMoreCompanies;
