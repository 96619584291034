import React from 'react';
import { Spin, Row, Col } from 'antd';
import { isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Waypoint } from 'react-waypoint';

import EmptyData from 'components/common/EmptyData';

import { CRUDSelectorsCustom } from 'redux/crudCreatorCustom/selectors';
import CentresListCustomStyles from './styles';
import CentresCard from '../CentresCard';

const CentresListBoard = ({
  retrieveList,
  resource = 'centresOfProvider',
  ...props
}) => {
  const centresSelectorsCustom = new CRUDSelectorsCustom(resource);
  const loading = useSelector(centresSelectorsCustom.getLoading);
  const enabledLoadMore = useSelector(centresSelectorsCustom.enabledLoadMore);
  const resourceData = useSelector(centresSelectorsCustom.getDataArr);

  const handleEnterWaypoint = () => {
    retrieveList({
      filter: {},
      isRefresh: false,
    });
  };

  return (
    <CentresListCustomStyles>
      {isEmpty(resourceData) ? (
        <EmptyData />
      ) : (
        <Row gutter={[24, 24]} className="row-locations-list">
          {resourceData.map((item, index) => (
            <Col key={String(index)} xxl={6} xl={6} lg={8} md={12} xs={24}>
              <CentresCard record={item} {...props} />
            </Col>
          ))}
        </Row>
      )}
      {loading && (
        <div className="loading-centres">
          <Spin />
        </div>
      )}
      {enabledLoadMore && <Waypoint onEnter={handleEnterWaypoint} />}
    </CentresListCustomStyles>
  );
};

CentresListBoard.propTypes = {
  resourceData: PropTypes.array,
  retrieveList: PropTypes.func,
  resourceFilter: PropTypes.object,
  resource: PropTypes.string,
};

export default CentresListBoard;
