import ActionGroup from 'components/RestActions/ActionGroup';
import DeleteButton from 'components/RestActions/DeleteButton';
import EditButton from 'components/RestActions/EditButton';
import RestFieldItem from 'components/RestField/RestFieldItem';
import { PREFIX_URL_API } from 'configs/localData/constant';
import List from 'containers/rest/List';
import React from 'react';
import Filter from '../components/Filter';

const SuburbList = (props) => (
  <List
    {...props}
    resource="suburbs"
    filter={<Filter />}
    hasSearch={false}
    defaultOptions={{ prefixUrl: PREFIX_URL_API }}
    widthTable="1000px"
  >
    <RestFieldItem source="city" header="suburbs.title" />
    <RestFieldItem source="state" header="input.state.name" />
    <RestFieldItem source="postCode" header="suburbs.postCode" />
    <RestFieldItem source="country" header="suburbs.country" />
    <RestFieldItem source="geolocation.latitude" header="suburbs.latitude" />
    <RestFieldItem source="geolocation.longitude" header="suburbs.longitude" />
    <ActionGroup widthAction={120}>
      <EditButton />
      <DeleteButton customMessage="suburbs.customMessageDelete" />
    </ActionGroup>
  </List>
);

export default SuburbList;
