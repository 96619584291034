import React, { useState } from 'react';
import i18next from 'i18next';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import CardSection from 'components/common/CardSection';
import ReadMore from 'components/common/ReadMore';
import { CentreInformationStyles } from './styles';
import GoogleDataModal from '../Modals/GoogleDataModal';

const GoogleData = ({ placeId, placeDetail }) => {
  const [visibleModal, setVisibleModal] = useState(false);

  const onClickButton = () => {
    setVisibleModal(true);
  };

  const onCancel = () => {
    setVisibleModal(false);
  };

  return (
    <>
      <CardSection
        isEdit
        onClickButton={onClickButton}
        title="centreDetail.googleData"
        permissionKey="myCentres"
        action="isEdit"
      >
        <CentreInformationStyles>
          <Row gutter={[20, 20]} className="row-centre-information">
            <Col className="col-title" xl={5} md={8} xs={24}>
              {i18next.t('centreDetail.placeId')}
            </Col>
            <Col className="col-value" xl={19} md={16} xs={24}>
              {placeId || i18next.t('error.waitingUpdate')}
            </Col>

            <Col className="col-title" xl={5} md={8} xs={24}>
              {i18next.t('centreDetail.placeDetails')}
            </Col>
            <Col className="col-value" xl={19} md={16} xs={24}>
              <ReadMore
                text={
                  placeDetail
                    ? JSON.stringify(placeDetail)
                    : i18next.t('error.waitingUpdate')
                }
                readMoreCharacterLimit={1000}
                className="text-black"
              />
            </Col>
          </Row>
        </CentreInformationStyles>
      </CardSection>
      <GoogleDataModal
        visible={visibleModal}
        onCancel={onCancel}
        record={{ placeId, placeDetail }}
      />
    </>
  );
};

GoogleData.propTypes = {
  placeId: PropTypes.string,
  placeDetail: PropTypes.any,
};

export default GoogleData;
