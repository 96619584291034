import React, { useRef } from 'react';
import i18next from 'i18next';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { formatMoney } from 'utils/tools';

import ReadMore from 'components/common/ReadMore';
import CardSection from 'components/common/CardSection';
import CentreInfoModal from '../Modals/CentreInfoModal';
import { CentreInformationStyles } from './styles';

const CentreInformation = ({
  applicationFeeAmount,
  placeAllowed,
  description,
}) => {
  const centreInfoModalRef = useRef();

  const onClickButton = () => {
    centreInfoModalRef.current.openModal({
      record: {
        applicationFeeAmount,
        placeAllowed,
        shortDescription: description,
      },
    });
  };

  return (
    <>
      <CardSection
        isEdit
        onClickButton={onClickButton}
        title="centreDetail.tabs.centreInformation"
        permissionKey="myCentres"
        action="isEdit"
      >
        <CentreInformationStyles>
          <Row gutter={[20, 20]} className="row-centre-information">
            <Col className="col-title" xxl={8} xl={8} lg={10} md={10} xs={24}>
              {i18next.t('centreDetail.applicationWaitlistFee')}
            </Col>
            <Col className="col-value" xxl={16} xl={16} lg={14} md={14} xs={24}>
              {`${i18next.t('currencyUnit.sign')}${formatMoney(
                applicationFeeAmount,
              )}`}
            </Col>

            <Col className="col-title" xxl={8} xl={8} lg={10} md={10} xs={24}>
              {i18next.t('centreDetail.approvedPlaces')}
            </Col>
            <Col className="col-value" xxl={16} xl={16} lg={14} md={14} xs={24}>
              {placeAllowed > 0
                ? `${placeAllowed} ${i18next.t('centres.childrenOpacity')}`
                : i18next.t('common.N/A')}
            </Col>

            <Col className="col-title" xxl={8} xl={8} lg={10} md={10} xs={24}>
              {i18next.t('centreDetail.centreDescription')}
            </Col>
            <Col className="col-value" xxl={16} xl={16} lg={14} md={14} xs={24}>
              <ReadMore
                text={description || i18next.t('error.waitingUpdate')}
                readMoreCharacterLimit={500}
              />
            </Col>
          </Row>
        </CentreInformationStyles>
      </CardSection>
      <CentreInfoModal
        ref={centreInfoModalRef}
        record={{
          applicationFeeAmount,
          placeAllowed,
          shortDescription: description,
        }}
      />
    </>
  );
};

CentreInformation.propTypes = {
  applicationFeeAmount: PropTypes.number,
  placeAllowed: PropTypes.any,
  description: PropTypes.string,
};

export default CentreInformation;
