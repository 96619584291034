import { Button, Space } from 'antd';
import i18next from 'i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { CENTRE_STATUS_VALUE } from 'configs/localData';

import { FooterActionStyles } from './styles';

const FooterAction = ({ onCancel, record, handleConfirm }) => {
  const handleApprove = () => {
    handleConfirm({ record, status: CENTRE_STATUS_VALUE.approved });
    onCancel();
  };

  const handleDecline = () => {
    handleConfirm({ record, status: CENTRE_STATUS_VALUE.declined });
    onCancel();
  };

  return (
    <FooterActionStyles>
      <Space size={20}>
        <Button onClick={handleApprove} className="approve-button">
          {i18next.t('button.approve')}
        </Button>

        <Button
          type="primary"
          className="decline-button"
          onClick={handleDecline}
        >
          {i18next.t('button.decline')}
        </Button>

        <Button onClick={onCancel}>{i18next.t('button.close')}</Button>
      </Space>
    </FooterActionStyles>
  );
};

FooterAction.propTypes = {
  onCancel: PropTypes.func,
  handleConfirm: PropTypes.func,
  record: PropTypes.object,
};

export default FooterAction;
