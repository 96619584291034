/* eslint-disable */
import moment from 'moment';
import slug from 'slug';
import unidecode from 'unidecode';
import { isEmpty, round } from 'lodash-es';
import i18next from 'i18next';

export const formatResponseTime = (obj) => {
  if (isEmpty(obj)) return '';

  let i = 0;
  let result = '';
  const typeTime = ['years', 'months', 'days', 'hours', 'minutes', 'seconds'];
  typeTime.some((key) => {
    if (i > 1) return true;
    if (obj[key] > 0) {
      i = i + 1;
      result = result + ' ' + formatTimes(obj[key], key);
    }
  });
  return result;
};

const formatTimes = (time, type) => {
  switch (type) {
    case 'years':
      return `${time} ${time > 1 ? 'years' : 'year'}`;
    case 'months':
      return `${time} ${time > 1 ? 'months' : 'month'}`;
    case 'days':
      return `${time} ${time > 1 ? 'days' : 'day'}`;
    case 'hours':
      return `${time} ${time > 1 ? 'hrs' : 'hr'}`;
    case 'minutes':
      return `${time} ${time > 1 ? 'mins' : 'min'}`;
    default:
      return `${time} s`;
  }
};

export const formatTimeDisplay = (num) => {
  if (!num) return '0';
  const number = parseFloat(num).toFixed(2);
  if (number > 0) {
    const hours = Math.floor(number);

    const days = Math.floor(hours / 24);

    const restHour = hours % 24;

    const restMinutes = Math.round((number - hours) * 60);

    return `${days ? `${days} days` : ''}${restHour ? ` ${restHour} hrs` : ''}${
      restMinutes ? ` ${restMinutes} mins` : ''
    }`;
  }
  return '0';
};

export const upperFirstChar = (text) => {
  return text.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1);
  });
};
export const lowerFirstChar = (text) => {
  return text.charAt(0).toLowerCase() + text.substr(1);
};
export const replaceAll = (text, search, replacement) => {
  return text.replace(new RegExp(search, 'g'), replacement);
};

export const formatDateTimeTable = (text) => {
  return text ? moment(text).format('DD MMM YYYY, HH:mm') : null;
};

export const formatDateTime = (text) => {
  return text ? moment(text).format('DD/MM/YYYY, hh:mma') : null;
};

export const formatDate = (text, type = 'DD MMM YYYY') => {
  return text ? moment(text).format(type) : null;
};

export const formatDueDate = (text) => {
  return text ? moment(text).format('DD/MM/YYYY') : null;
};

export const formatTime = (text) => {
  return text ? moment(text).format('hh:mma') : null;
};

export const formatDateFromNow = (date) => {
  if (moment().isAfter(date)) {
    return moment().diff(moment(date), 'days') < 1
      ? moment(date).fromNow()
      : moment(date).format('DD MMM, YYYY [at] HH:mm');
  }
  return i18next.t('text.justNow');
};

export const formatDateLastActive = (date) => {
  if (!date) return null;
  if (moment().isAfter(date)) {
    return moment().diff(moment(date), 'days') < 1
      ? moment(date).fromNow()
      : moment(date).format('MMM DD YYYY, HH:mm');
  }
  return i18next.t('common.active');
};

export const formatBirthday = (text) => {
  return text ? moment(text).format('DD/MM/YYYY') : null;
};

export const formatDateApplication = (text) => {
  return text ? moment(text).format('D MMM YYYY') : null;
};

export const encodeJsonToURI = (params) => {
  return Object.keys(params)
    .map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
    })
    .join('&');
};

export const stringToSlug = (e) => {
  let str = String(e);
  return slug(unidecode(str), '_');
};
export const makeActionName = (text) => {
  return lowerFirstChar(
    replaceAll(
      upperFirstChar(replaceAll(text, '_', ' ').toLowerCase()),
      ' ',
      '',
    ),
  );
};

export const formatMoney = (num, digits = 3) => {
  if (!num) return '0';
  else if (Number.isNaN(num)) return '0';
  const roundNum = round(Number(num), 2);
  const re = `\\B(?=(\\d{${digits}})+(?!\\d))`;
  return String(roundNum).replace(new RegExp(re, 'g'), ',');
};

export const formatMoneyWithCurrency = (
  num,
  currency = '$',
  n = 2,
  digits = 3,
) => {
  const roundNum = String(round(Number(num), n) || 0);

  const re = `\\B(?=(\\d{${digits}})+(?!\\d))`;

  if (currency !== 'AUD' && currency !== '$' && currency !== null) {
    return `${roundNum.replace(new RegExp(re, 'g'), `$&,`)} ${currency}`;
  }

  return `$${roundNum.replace(new RegExp(re, 'g'), `$&,`)}`;
};

export const inputNumberFormatter = () => {
  return {
    formatter: (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    parser: (value) => value.replace(/\$\s?|(,*)/g, ''),
    ruleType: 'number',
  };
};

export const formatLabelTable = (key, appSettings) => {
  const data = appSettings?.find((item) => item?.key === key);
  return data ? data?.value : '';
};

export const convertNameToType = (e) => {
  let str = String(e);
  return slug(unidecode(str), '');
};

export const convertStatisticDate = (date) => {
  return [date?.[0]?.format('MMM DD, yyyy'), date?.[1]?.format('MMM DD, yyyy')];
};
