import { createSlice } from '@reduxjs/toolkit';
import { unionBy } from 'lodash-es';
import { getProvidersOfCompany } from './actions';

export const initialState = {
  loading: false,
  data: [],
  resourceData: {
    filter: {},
    offset: 0,
    limit: 10,
    total: 0,
    numberOfPages: 1,
  },
};

const getProvidersOfCompanyPending = (
  state,
  {
    meta: {
      arg: { data, options = {} },
    },
  },
) => {
  state.loading = true;

  if (options.isRefresh) {
    state.data = [];
    if (options.keepFilter) {
      state.resourceData = {
        ...state.resourceData,
        ...data,
        offset: 0,
      };
    } else {
      state.resourceData = {
        ...initialState.resourceData,
        ...data,
      };
    }
  } else {
    state.resourceData.offset =
      state.resourceData.offset + state.resourceData.limit || 0;
  }
};

const getProvidersOfCompanySuccess = (state, { payload: { data } }) => {
  state.data = unionBy([...state.data, ...(data.results || [])], 'id');

  state.resourceData.total = data.total || 0;
  state.resourceData.numberOfPages = data.numberOfPages;

  state.loading = false;
  state.error = null;
};

const getProvidersOfCompanyFail = (state, { payload: { data } }) => {
  state.error = data;
  state.loading = false;
};

const { actions, reducer } = createSlice({
  name: 'providersOfCompany',
  initialState,
  reducers: {
    resetResourceFilterProvidersOfCompany: (state) => {
      state.resourceData = initialState.resourceData;
    },
    editProviderOfCompanySuccess: (state, payload) => {
      const matchIdx = state.data?.findIndex(
        (item) => item.id === payload?.data?.id,
      );
      if (matchIdx !== -1) {
        state.data[matchIdx] = payload.data;
      }
    },
  },
  extraReducers: {
    [getProvidersOfCompany.pending]: getProvidersOfCompanyPending,
    [getProvidersOfCompany.fulfilled]: getProvidersOfCompanySuccess,
    [getProvidersOfCompany.rejected]: getProvidersOfCompanyFail,
  },
});

export const {
  resetResourceFilterProvidersOfCompany,
  editProviderOfCompanySuccess,
} = actions;

export default reducer;
