import React from 'react';
import RestShow from 'containers/rest/Show';
import RestFieldItem from 'components/RestField/RestFieldItem';

const FeaturesShow = (props) => (
  <RestShow {...props} hasEdit resource="features">
    <RestFieldItem source="name" header="features.name" />
  </RestShow>
);

export default FeaturesShow;
