import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import CardSection from 'components/common/CardSection';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getAllSocialMediaChannels } from 'redux/centres/actions';
import { Avatar, Space } from 'antd';
import { SOCIAL_MEDIA_CHANNEL_TYPE } from 'configs/localData/constant';
import i18next from 'i18next';
import { forEach } from 'lodash-es';
import {
  getSocialMediaChannelsData,
  getTotalSocialMediaChannelsValue,
} from 'redux/centres/selectors';
import { getATagHref } from 'utils/tools';
import SocialMediaChannelsModal from '../Modals/SocialMediaChannelsModal';
import { SocialMediaChannelStyles } from './styles';

const SocialMediaChannels = () => {
  const { id: centreId } = useParams();
  const modalRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getAllSocialMediaChannels({ centreId, type: SOCIAL_MEDIA_CHANNEL_TYPE }),
    );
  }, [dispatch, centreId]);

  const socialMedialChannelsList = useSelector(getSocialMediaChannelsData);

  const totalSocialMedia = useSelector(getTotalSocialMediaChannelsValue);

  const initialValue = useMemo(() => {
    const res = {};

    forEach(socialMedialChannelsList, (item) => {
      if (item.id) {
        res[item.id] = item.externalLink;
      }
    });

    return res;
  }, [socialMedialChannelsList]);

  const onClickButton = useCallback(() => {
    modalRef.current.open(initialValue);
  }, [initialValue]);

  return (
    <SocialMediaChannelStyles>
      <CardSection
        isEdit
        title="centreDetail.socialMediaChannels"
        onClickButton={onClickButton}
      >
        {socialMedialChannelsList?.length === totalSocialMedia &&
          socialMedialChannelsList?.map((item, index) => (
            <div key={Number(index)}>
              <Space className="mb-20">
                <Avatar src={item?.icon} size={20} />
                <div className="text-16 break-all ml-12">
                  <a
                    href={getATagHref(item?.externalLink)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${
                      item?.externalLink ? 'text-gray' : 'social-text'
                    }`}
                  >
                    {item?.externalLink ||
                      `${item?.name} ${i18next.t(
                        'centreDetail.notUpdatedYet',
                      )}`}
                  </a>
                </div>
              </Space>
            </div>
          ))}
      </CardSection>
      <SocialMediaChannelsModal
        ref={modalRef}
        socialMedialChannelsList={socialMedialChannelsList}
      />
    </SocialMediaChannelStyles>
  );
};

export default SocialMediaChannels;
