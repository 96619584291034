import React from 'react';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { ManOutlined, WomanOutlined, UserOutlined } from '@ant-design/icons';
import { getRecordData } from 'utils/tools';
import { Link } from 'react-router-dom';
import { UserTagWrapper } from './styles';

const UserInfo = (props) => {
  const {
    record,
    size,
    isLink,
    prefixLink,
    nameProp,
    roleProp,
    avatarProp,
    suffixLink,
    customName,
  } = props;

  const onLinkClick = () => {
    localStorage.removeItem('currentStep');
  };

  const content =
    record &&
    (isLink ? (
      <Link
        onClick={onLinkClick}
        className="link-item link-class"
        to={`/${prefixLink}/${record?.id}/${suffixLink}`}
      >
        <div className="ellipsis-t-2">
          {customName || getRecordData(record, nameProp)}
        </div>
        {record.gender === 'Male' && (
          <ManOutlined
            style={{
              marginLeft: 5,
              fontSize: 17,
              color: '#00a7f7',
            }}
          />
        )}
        {record.gender === 'Female' && (
          <WomanOutlined
            style={{
              marginLeft: 5,
              fontSize: 17,
              color: '#9b28b0',
            }}
          />
        )}
        <div className="text-style">{getRecordData(record, roleProp)}</div>
      </Link>
    ) : (
      // eslint-disable-next-line
      <a role="main" className="link-item none-link-class">
        {customName || getRecordData(record, nameProp)}
        <div>
          <small className="text-style">
            {getRecordData(record, roleProp)}
          </small>
        </div>
      </a>
    ));
  return (
    <UserTagWrapper>
      <div className="avatar-item">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {(getRecordData(record, avatarProp) && (
            <Avatar src={getRecordData(record, avatarProp)} size={size} />
          )) || (
            <Avatar
              icon={<UserOutlined />}
              size={size}
              style={{ minWidth: '32px' }}
            />
          )}
          {content || (
            <span style={{ marginLeft: 10 }}>
              {i18next.t('error.waitingUpdate')}
            </span>
          )}
        </div>
      </div>
    </UserTagWrapper>
  );
};

UserInfo.propTypes = {
  record: PropTypes.object,
  size: PropTypes.number,
  isLink: PropTypes.bool,
  nameProp: PropTypes.string,
  roleProp: PropTypes.string,
  avatarProp: PropTypes.string,
  prefixLink: PropTypes.string,
  suffixLink: PropTypes.string,
  customName: PropTypes.any,
};

UserInfo.defaultProps = {
  nameProp: 'fullName',
  roleProp: 'businessRole',
  avatarProp: 'avatar',
  isLink: true,
  prefixLink: 'users',
  suffixLink: 'show',
};

export default UserInfo;
