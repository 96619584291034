import React, { useContext, useEffect, useMemo, useState, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import { get, isEmpty, isEqual } from 'lodash-es';
import axios from 'axios';
import i18next from 'i18next';
import { Form, Skeleton } from 'antd';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import {
  splitAddressFromGoogleMap,
  getAddressCentreToGetGeo,
} from 'utils/dataUtils';
import MarkerPoint from './MarkerPoint';
import { GoogleMapStyles } from './styles';

const defaultGeoAU = {
  lat: -25.274398,
  lng: 133.775136,
};

const GoogleMap = ({
  source,
  nameProp,
  addressMarkerProp,
  geoLocationCustom,
  addressMarkerCustom,
  geoAddressObj,
}) => {
  const { record, form } = useContext(RestInputContext);
  const defaultGeo = useMemo(() => get(record, source), []); // eslint-disable-line
  const [isShowMap, setIsShowMap] = useState();
  const [isWrongAddress, setIsWrongAddress] = useState();
  const geoAddressObjRef = useRef();

  const [geoLocation, setGeoLocation] = useState({
    lat: defaultGeo?.latitude || defaultGeoAU.lat,
    lng: defaultGeo?.longitude || defaultGeoAU.lng,
  });

  const [addressMarker, setAddressMarker] = useState(
    get(record, addressMarkerProp),
  );

  useEffect(() => {
    if (!addressMarker && !isEmpty(defaultGeo)) {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${defaultGeo.latitude},${defaultGeo.longitude}&sensor=true&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
        )
        .then((res) => {
          setAddressMarker(res?.data?.results?.[0]?.formatted_address);
        });
    }

    setTimeout(() => {
      setIsShowMap(true);
    }, 2000);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!isEqual(geoAddressObj, geoAddressObjRef.current)) {
      geoAddressObjRef.current = geoAddressObj;
      axios(
        `https://maps.googleapis.com/maps/api/geocode/json?components=country:AU&address=${getAddressCentreToGetGeo(
          geoAddressObj,
        )}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
      ).then((res) => {
        const addressObj = splitAddressFromGoogleMap(
          res?.data?.results?.[0]?.address_components,
        );
        if (
          addressObj.city?.toLowerCase() ===
            geoAddressObj.city?.toLowerCase() &&
          addressObj.postCode === geoAddressObj.postCode &&
          addressObj.state?.toLowerCase() === geoAddressObj.state?.toLowerCase()
        ) {
          const location = res?.data?.results?.[0]?.geometry?.location;
          setIsWrongAddress(false);
          setGeoLocation({
            lat: location.lat,
            lng: location.lng,
          });

          setAddressMarker(res?.data?.results?.[0]?.formatted_address);
          form.setFieldsValue({
            [source]: {
              latitude: location.lat,
              longitude: location.lng,
            },
          });
        } else {
          setIsWrongAddress(true);
          setGeoLocation({});

          setAddressMarker('');
          form.setFieldsValue({
            [source]: null,
          });
        }
      });
    }
  }, [geoAddressObj]); // eslint-disable-line

  useEffect(() => {
    geoLocationCustom && setGeoLocation(geoLocationCustom);
  }, [geoLocationCustom]);

  useEffect(() => {
    addressMarkerCustom && setAddressMarker(addressMarkerCustom);
  }, [addressMarkerCustom]);

  const contentMap = (
    <GoogleMapReact
      bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY }}
      center={geoLocation}
      zoom={geoLocation.lat === defaultGeoAU.lat ? 4 : 15}
      style={{ width: '100%', height: '100%' }}
    >
      <MarkerPoint
        lat={geoLocation.lat}
        lng={geoLocation.lng}
        name={get(record, nameProp)}
        address={addressMarker}
      />
    </GoogleMapReact>
  );

  return (
    <GoogleMapStyles>
      <Form.Item
        className="geolocation-form-item"
        label={i18next.t('address.location')}
        name="geolocation"
        initialValue={defaultGeo}
        rules={[
          {
            required: true,
            message: i18next.t('input.coordinates.validateMsg.notExistAddress'),
          },
        ]}
      />
      <div className="google-map-content">
        {isShowMap ? contentMap : <Skeleton paragraph={{ rows: 6 }} />}

        <div className={`error-overlay ${isWrongAddress ? 'flex' : 'd-none'}`}>
          <span>{i18next.t('input.address.validateMsg.notExist')}</span>
        </div>
      </div>
    </GoogleMapStyles>
  );
};

GoogleMap.propTypes = {
  source: PropTypes.string,
  nameProp: PropTypes.string,
  addressMarkerProp: PropTypes.string,
  geoLocationCustom: PropTypes.object,
  addressMarkerCustom: PropTypes.string,
  geoAddressObj: PropTypes.object,
};

GoogleMap.defaultProps = {
  source: 'geolocation',
  nameProp: 'name',
  addressMarkerProp: 'displayAddress',
};

export default GoogleMap;
