import React from 'react';
import { Switch, Tag } from 'antd';
import i18next from 'i18next';
import { VACANCY_COD, SERVICE_AGE_GROUPS } from 'configs/localData';
import { useSelector } from 'react-redux';

import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import DeleteButton from 'components/RestActions/DeleteButton';
import Filter from '../components/Filter';

const CentreServicesList = (props) => {
  const serviceTypes = useSelector((state) => state.config.data?.ServiceType);

  return (
    <List
      {...props}
      resource="centreServices"
      hasSearch={false}
      filter={<Filter />}
      widthTable="1000px"
    >
      <RestFieldItem
        source="serviceTypeId"
        header="centreServices.name"
        format={(data) => {
          const rest = serviceTypes.find((item) => item.id === data);
          return rest ? rest.name : i18next.t('error.waitingUpdate');
        }}
      />
      <RestFieldItem
        source="serviceAgeGroup"
        header="centreServices.ageGroup"
        format={(data) => {
          const rest = SERVICE_AGE_GROUPS.find((item) => item.value === data);
          return rest ? i18next.t(rest.text) : i18next.t('error.waitingUpdate');
        }}
      />
      <RestFieldItem
        source="costPerDay"
        header="centreServices.costPerDay"
        format={(data) => `${data || 0} ${i18next.t('currencyUnit.sign')}`}
      />
      <RestFieldItem
        source="vacancy_cod"
        header="centreServices.vacancy"
        format={(data) => {
          const rest = VACANCY_COD.find((item) => item.value === data);
          return rest ? (
            <Tag color={rest.color}>{i18next.t(rest.text)}</Tag>
          ) : (
            i18next.t('error.waitingUpdate')
          );
        }}
      />
      <RestFieldItem
        source="isActive"
        header="centreServices.isActive"
        valueProp="checked"
        component={<Switch />}
      />
      <ActionGroup widthAction={100}>
        <EditButton />
        <DeleteButton customMessage="centreServices.customMessageDelete" />
      </ActionGroup>
    </List>
  );
};

export default CentreServicesList;
