import Form from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import ModalCustom from 'components/common/ModalCustom';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import RestInputItem from 'components/RestInput/RestInputItem';
import { PREFIX_URL_API } from 'configs/localData/constant';
import { ROLES_CONST } from 'configs/localData/permissions';
import i18next from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createUsers } from 'redux/users/actions';
import { handleApiError } from 'utils/validateUtils';
import GeneralUserForm from './Form/GeneralUserForm';
import RoleForm from './Form/RoleForm';

const CreateModal = ({ retrieveList, visible, onCancel }) => {
  const [form] = useForm();
  const { record } = useContext(RestInputContext);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onOk = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        return dispatch(
          createUsers({
            data: {
              ...values,
            },
            options: {
              prefixUrl: PREFIX_URL_API,
            },
          }),
        );
      })
      .then(({ payload }) => {
        setLoading(false);
        if (payload?.data?.id) {
          onCancel();
          retrieveList({
            filter: {
              offset: 0,
            },
            isRefresh: true,
          });
          form.resetFields();
        } else {
          handleApiError(payload?.data, form);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <ModalCustom
      title={i18next.t('users.createPage')}
      onCancel={onCancel}
      visible={visible}
      onOk={onOk}
      resetStyleForm
      width={800}
      resetPaddingTopBody={false}
      okText="button.create"
      loading={loading}
    >
      <RestInputContext.Provider value={{ form }}>
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          autoComplete="off"
          labelAlign="left"
          initialValues={{ dueDate: moment() }}
          layout="vertical"
        >
          <GeneralUserForm />
          {record?.role?.name === ROLES_CONST.customer ? (
            <div className="d-none">
              <RestInputItem source="roleId" />
            </div>
          ) : (
            <RoleForm />
          )}
        </Form>
      </RestInputContext.Provider>
    </ModalCustom>
  );
};

CreateModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  retrieveList: PropTypes.func,
};

export default CreateModal;
