import React from 'react';
import { Switch } from 'antd';

import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import RestImageField from 'components/RestField/ImageField';
import { formatLabelTable } from 'utils/textUtils';
import { LABEL_TABLE_KEY } from 'configs/localData/constant';
import { useDispatch, useSelector } from 'react-redux';
import { editServiceTypes } from 'redux/serviceTypes/actions';

const ServiceTypesList = (props) => {
  const dispatch = useDispatch();
  const handleActive = (isActive, record) =>
    dispatch(
      editServiceTypes({
        data: {
          id: record?.id,
          name: record?.name,
          logo: record?.logo,
          externalId: record?.externalId,
          isActive,
        },
      }),
    );
  const appSettings = useSelector((state) => state.config?.data?.AppSetting);

  return (
    <List
      {...props}
      resource="serviceTypes"
      widthTable="1000px"
      hasSearch={false}
      header=""
      hasCreate={false}
      defaultOptions={{ isGetConfig: true }}
    >
      <RestFieldItem
        source="name"
        header={formatLabelTable(LABEL_TABLE_KEY.serviceType, appSettings)}
        format={(data, record) => (
          <div className="flex items-center ">
            <RestImageField
              style={{ width: 32, height: 32 }}
              record={record}
              source="icon.url"
            />
            <span className="ml-16">{data}</span>
          </div>
        )}
      />
      <RestFieldItem
        source="externalId"
        header="serviceTypes.code"
        format={(data) => data.toUpperCase()}
      />
      <RestFieldItem
        source="weekPerYear"
        header="serviceTypes.weeksOfTheYear"
      />
      <RestFieldItem
        source="isActive"
        header="serviceTypes.isActive"
        valueProp="checked"
        component={<Switch />}
        customOnChange={handleActive}
      />
      <ActionGroup widthAction={100}>
        <EditButton />
      </ActionGroup>
    </List>
  );
};

export default ServiceTypesList;
