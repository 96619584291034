import { PlusOutlined } from '@ant-design/icons';
import { Button, Tabs } from 'antd';
import EditButton from 'components/RestActions/EditButton';
import SessionTypesList from 'containers/SessionTypes/List';
import { LABEL_TABLE_KEY } from 'configs/localData/constant';
import ChildcareServiceTypesList from 'containers/ChildcareServiceType/List';
import ServiceTypesList from 'containers/ServiceTypes/List';
import i18next from 'i18next';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { formatLabelTable } from 'utils/textUtils';
import ServiceAgeGroupsList from 'containers/ServiceAgeGroups/List';
import LabelFormModal from './components/Form/LabelFormModal';
import { ServiceStyle } from './styles';

const { TabPane } = Tabs;

const TABS = [
  {
    key: LABEL_TABLE_KEY.serviceType,
    component: ServiceTypesList,
    source: 'serviceTypes',
  },
  {
    key: LABEL_TABLE_KEY.ageGroup,
    component: ServiceAgeGroupsList,
    source: 'serviceAgeGroups',
  },
  {
    key: LABEL_TABLE_KEY.sessionType,
    component: SessionTypesList,
    source: 'sessionTypes',
  },
  {
    key: LABEL_TABLE_KEY.childCareService,
    component: ChildcareServiceTypesList,
    source: 'childcareServiceTypes',
  },
];

const Services = () => {
  const { push } = useHistory();

  const openCreateModal = (source) => {
    push(`#${source}/create`);
  };

  const [visible, setVisible] = useState(false);
  const [activeTab, setActiveTab] = useState(LABEL_TABLE_KEY.serviceType);

  const onCancel = () => setVisible(false);

  const onOpen = () => setVisible(true);

  const onTabChange = (activeKey) => setActiveTab(activeKey);
  const appSettings = useSelector((state) => state.config?.data?.AppSetting);

  return (
    <ServiceStyle>
      <Tabs type="card" activeKey={activeTab} onChange={onTabChange}>
        {TABS.map((tab) => (
          <TabPane tab={formatLabelTable(tab?.key, appSettings)} key={tab.key}>
            <div className="bg-service">
              <div className="flex justify-between p-20 header-style">
                <div className="flex items-center">
                  <strong>{formatLabelTable(tab?.key, appSettings)}</strong>
                  <EditButton className="ml-16" gotoEditPage={onOpen} />
                </div>
                <Button
                  className="btn-second-primary"
                  icon={<PlusOutlined />}
                  onClick={() => openCreateModal(tab?.source)}
                >
                  {i18next.t('button.addNewItem')}
                </Button>
              </div>
              <tab.component />
            </div>
          </TabPane>
        ))}
      </Tabs>
      <LabelFormModal
        onCancel={onCancel}
        visible={visible}
        keys={activeTab}
        title={formatLabelTable(activeTab, appSettings)}
      />
    </ServiceStyle>
  );
};

export default Services;
