import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import KindiRatingStyles from './styles';

const KindiRating = ({ value, className }) => {
  const formatValue = useMemo(
    () =>
      Number(value) ? Number(value).toFixed(1) : i18next.t('classification.NR'),
    [value],
  );

  return (
    <KindiRatingStyles className={className}>{formatValue}</KindiRatingStyles>
  );
};

KindiRating.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default KindiRating;
