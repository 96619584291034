import styled from 'styled-components';

export const TotalSearchTableStyles = styled.div`
  th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  th:last-child {
    border-bottom-right-radius: 8px;
  }

  .ant-table-thead > tr > th {
    background: ${({ theme }) => theme.value.tableBackground};
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.8px;
    color: #2d1f21;
  }

  .ant-table-tbody > tr > td {
    border-bottom: none;
  }

  .anticon {
    margin-top: 4px;
    margin-right: 6px;
  }

  .border-red {
    color: ${({ theme }) => theme.value.red};
    border-bottom: 1px dotted ${({ theme }) => theme.value.red};
  }

  .border-green {
    color: ${({ theme }) => theme.value.green};
    border-bottom: 1px dotted ${({ theme }) => theme.value.green};
  }

  .common-value-green {
    color: ${({ theme }) => theme.value.green};
  }

  .common-value-red {
    color: ${({ theme }) => theme.value.red};
  }

  .common-value-green,
  .common-value-red {
    cursor: pointer;
  }
`;
