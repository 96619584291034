import { Col, Row } from 'antd';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestRangePickerInput from 'components/RestInput/RestRangePickerInput';
import RestSelect from 'components/RestInput/RestSelect';
import { BLOCK_USER_TYPES, FEATURED_STATUS } from 'configs/localData';
import i18next from 'i18next';
import React from 'react';

const Filter = () => (
  <Row gutter={16}>
    <Col lg={5} md={12} xs={12}>
      <RestInputItem
        source={['outsideFilter', 'q']}
        placeholder="users.fullName"
        isShowTooltip
      />
    </Col>
    <Col lg={5} md={12} xs={12}>
      <RestInputItem source="phoneNumber" placeholder="profile.phoneNumber" />
    </Col>
    <Col lg={5} md={12} xs={12}>
      <RestInputItem
        source={['email', '$like']}
        placeholder="users.email"
        isShowTooltip
      />
    </Col>
    <Col lg={4} md={12} xs={12}>
      <RestSelect
        source="isUpdated"
        placeholder="users.createdJobProfile"
        resourceData={FEATURED_STATUS}
        valueProp="value"
        titleProp="text"
        formatText={(text) => i18next.t(text)}
        isShowTooltip
      />
    </Col>
    <Col lg={4} md={12} xs={12}>
      <RestSelect
        source="isBlocked"
        placeholder="users.blocked"
        resourceData={BLOCK_USER_TYPES}
        valueProp="value"
        titleProp="text"
        formatText={(text) => i18next.t(text)}
        isShowTooltip
      />
    </Col>
    <Col lg={5} md={12} xs={12}>
      <RestRangePickerInput
        source="createdAt"
        placeholder="common.time"
        isShowTooltip
        sourceGt="createdAt.$gt"
        sourceLt="createdAt.$lt"
      />
    </Col>
  </Row>
);

export default Filter;
