import React from 'react';
import { useSelector } from 'react-redux';

import SummaryView from 'components/common/SummaryCardDetail/SummaryView';
import { TotalCompanyIcon } from 'components/common/SVGIcon';

const SummaryCompanies = () => {
  const centreSummaries =
    useSelector((state) => state.companies.summaries?.centreSummary) || {};

  const providersSummaries =
    useSelector((state) => state.companies.summaries?.providersSummary) || {};

  const data = [
    {
      title: 'summaries.totalProviders',
      value: providersSummaries.totalProviders,
    },
    {
      title: 'summaries.totalCentres',
      value: centreSummaries.totalCentres,
      isShowIconInfo: true,
    },
    {
      title: 'summaries.totalPlaces',
      value: centreSummaries.totalPlaces,
      isShowIconInfo: true,
    },
    {
      title: 'summaries.enrolmentCoverage',
      value: providersSummaries.enrolmentCoverage,
      isPercent: true,
    },
    {
      title: 'summaries.averageEnquiryResponseTime',
      value: providersSummaries.averageEnquiryResponseTime,
      isTime: true,
    },
  ];

  return (
    <SummaryView
      IconComponent={TotalCompanyIcon}
      title="centres.companyTitle"
      data={data}
      color="#DB147F"
    />
  );
};

export default SummaryCompanies;
