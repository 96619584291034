import React, { useState, useImperativeHandle, forwardRef } from 'react';
import i18next from 'i18next';
import { Radio, Space, Button } from 'antd';
import { map } from 'lodash-es';
import { useSelector, useDispatch } from 'react-redux';
import { getEnquiryMode } from 'redux/appSettings/selectors';
import { updateAppSettingsOfCentre } from 'redux/appSettings/actions';
import ModalCustom from 'components/common/ModalCustom';
import EmptyData from 'components/common/EmptyData';
import { ENQUIRY_MODE_KEY } from 'configs/localData/constant';
import { useParams } from 'react-router';
import EnquirySettingModalStyles from './styles';

const EnquirySettingModal = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const enquiryMode = useSelector(getEnquiryMode) || {};

  const { id: centreId } = useParams();

  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        setVisible(true);
        setValue(enquiryMode.value);
      },
    }),
    [enquiryMode.value],
  );

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onCancel = () => {
    setVisible(false);
  };

  const onOk = () => {
    if (value !== enquiryMode.value) {
      setLoading(true);
      dispatch(
        updateAppSettingsOfCentre({
          centreId,
          key: ENQUIRY_MODE_KEY,
          data: {
            value,
          },
        }),
      )
        .then(({ payload }) => {
          setLoading(false);
          if (payload?.success) {
            onCancel();
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      onCancel();
    }
  };

  return (
    <ModalCustom
      visible={visible}
      title={i18next.t('enquiriesSettings.title')}
      footer={null}
      resetPaddingBottomBody={false}
      resetPaddingTopBody={false}
      onCancel={onCancel}
    >
      {enquiryMode.data ? (
        <EnquirySettingModalStyles>
          <Radio.Group onChange={onChange} value={value}>
            <Space direction="vertical" size={20}>
              {map(enquiryMode.data, (item) => (
                <Radio key={item.value} value={item.value}>
                  <div className="enquiry-setting-item">
                    <div className="enquiry-setting-item__title">
                      {item?.name}
                    </div>
                    <div className="enquiry-setting-item__description">
                      {item?.description}
                    </div>
                  </div>
                </Radio>
              ))}
            </Space>
          </Radio.Group>

          <Button
            type="primary"
            className="mt-32 w-full fs-16"
            loading={loading}
            onClick={onOk}
          >
            {i18next.t('button.save')}
          </Button>
        </EnquirySettingModalStyles>
      ) : (
        <EmptyData />
      )}
    </ModalCustom>
  );
});

export default EnquirySettingModal;
