import React, { useCallback } from 'react';
import moment from 'moment';
import { formatDateTime } from 'utils/textUtils';
import { PREFIX_URL_API } from 'configs/localData/constant';
import { formatNotificationStatus } from 'utils/formatFieldUtils';
import { NOTIFICATION_STATUS_CONSTANT } from 'configs/localData';

import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import List from '../../rest/List';
import Filter from '../components/Filter';

const NotificationsList = (props) => {
  const formmatFilter = useCallback(
    ({ createdAt, ...value }) => ({
      ...value,
      createdAt: createdAt
        ? {
            $gt: createdAt[0]
              ? moment(createdAt[0]).startOf('day').toISOString()
              : undefined,
            $lt: createdAt[1]
              ? moment(createdAt[1]).endOf('day').toISOString()
              : undefined,
          }
        : undefined,
    }),
    [],
  );

  const checkVisibleDetail = (record) =>
    record?.status === NOTIFICATION_STATUS_CONSTANT.sending.value ||
    record?.status === NOTIFICATION_STATUS_CONSTANT.complete.value;

  const checkVisibleEdit = (record) =>
    record?.status === NOTIFICATION_STATUS_CONSTANT.draft.value ||
    record?.status === NOTIFICATION_STATUS_CONSTANT.scheduled.value;

  return (
    <List
      {...props}
      isUpdateRoute={false}
      resource="notifications"
      filter={<Filter format={formmatFilter} />}
      defaultOptions={{ prefixUrl: PREFIX_URL_API }}
      redirects={{ create: '/create' }}
      hasSearch={false}
    >
      <RestFieldItem source="title" header="notifications.title" width={300} />
      <RestFieldItem
        source="status"
        header="notifications.status"
        format={formatNotificationStatus}
      />
      <RestFieldItem
        source="startedAt"
        header="notifications.startSend"
        format={formatDateTime}
      />
      <RestFieldItem
        source="endedAt"
        header="notifications.endAt"
        format={formatDateTime}
      />
      <RestFieldItem
        source="sentCount"
        header="notifications.sends"
        format={(data) => data || 0}
      />
      <RestFieldItem source="openedCount" header="notifications.opens" />
      <ActionGroup widthAction={80}>
        <EditButton checkVisibleButton={checkVisibleEdit} />
        <EditButton isView checkVisibleButton={checkVisibleDetail} />
      </ActionGroup>
    </List>
  );
};

export default NotificationsList;
