import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router';
import Modal from 'components/common/Modal';
import ClientUsers from 'pages/ClientUsers';
import BusinessUsers from 'pages/BusinessUsers';
import Companies from 'pages/Companies';
import Brands from 'pages/Brands';
import Marketings from 'pages/Marketings';
import CRMPackages from 'pages/CRMPackages';
import CentreServices from 'pages/CentreServices';
import ServiceTypes from 'pages/ServiceTypes';
import SessionTypes from 'pages/SessionTypes';
import Features from 'pages/Features';
import NQSRTypes from 'pages/NQSRTypes';
import AppSettings from 'pages/AppSettings';
import Centres from 'pages/Centres';
import Users from 'pages/Users';
import Articles from 'pages/Articles';
import Suburbs from 'pages/Suburbs';
import Providers from 'pages/Providers';
import Company from 'pages/Company';
import childcareServiceTypes from 'pages/ChildcareServiceType';
import ServiceAgeGroups from 'pages/ServiceAgeGroups';

const modalRoutes = [
  {
    path: '/clientUsers',
    routes: [
      {
        path: '/create',
        component: ClientUsers.Create,
      },
      {
        path: '/edit',
        component: ClientUsers.Edit,
      },
    ],
  },

  {
    path: '/businessUsers',
    routes: [
      {
        path: '/create',
        component: BusinessUsers.Create,
      },
      {
        path: '/edit',
        component: BusinessUsers.Edit,
      },
    ],
  },
  {
    path: '/companies',
    routes: [
      {
        path: '/create',
        component: Company.Create,
        modalOptions: { width: 1000 },
      },
      {
        path: '/edit',
        component: Company.Edit,
        modalOptions: { width: 1000 },
      },
    ],
  },
  {
    path: '/providers',
    routes: [
      {
        path: '/create',
        component: Providers.Create,
        modalOptions: { width: 1000 },
      },
      {
        path: '/edit',
        component: Providers.Edit,
        modalOptions: { width: 1000 },
      },
    ],
  },
  {
    path: '/articles',
    routes: [
      {
        path: '/create',
        component: Articles.Create,
        modalOptions: { width: 800 },
      },
      {
        path: '/edit',
        component: Articles.Edit,
        modalOptions: { width: 800 },
      },
    ],
  },

  {
    path: '/companies',
    routes: [
      {
        path: '/create',
        component: Companies.Create,
      },
      {
        path: '/edit',
        component: Companies.Edit,
      },
    ],
  },

  {
    path: '/brands',
    routes: [
      {
        path: '/createOfCompany',
        component: Brands.CreateBrandOfCompany,
      },
      {
        path: '/editOfCompany',
        component: Brands.EditBrandOfCompany,
      },
      {
        path: '/create',
        component: Brands.Create,
      },
      {
        path: '/edit',
        component: Brands.Edit,
      },
    ],
  },

  {
    path: '/marketings',
    routes: [
      {
        path: '/create',
        component: Marketings.Create,
      },
      {
        path: '/edit',
        component: Marketings.Edit,
      },
    ],
  },

  {
    path: '/suburbs',
    routes: [
      {
        path: '/create',
        component: Suburbs.Create,
      },
      {
        path: '/edit',
        component: Suburbs.Edit,
      },
    ],
  },

  {
    path: '/CRMPackages',
    routes: [
      {
        path: '/edit',
        component: CRMPackages.Edit,
        modalOptions: { width: 800 },
      },
    ],
  },
  {
    path: '/centreServices',
    routes: [
      {
        path: '/create',
        component: CentreServices.Create,
      },
      {
        path: '/edit',
        component: CentreServices.Edit,
      },
    ],
  },
  {
    path: '/serviceAgeGroups',
    routes: [
      {
        path: '/create',
        component: ServiceAgeGroups.Create,
      },
      {
        path: '/edit',
        component: ServiceAgeGroups.Edit,
      },
    ],
  },

  {
    path: '/serviceTypes',
    routes: [
      {
        path: '/create',
        component: ServiceTypes.Create,
      },
      {
        path: '/edit',
        component: ServiceTypes.Edit,
      },
    ],
  },
  {
    path: '/childcareServiceTypes',
    routes: [
      {
        path: '/create',
        component: childcareServiceTypes.Create,
      },
      {
        path: '/edit',
        component: childcareServiceTypes.Edit,
      },
    ],
  },
  {
    path: '/sessionTypes',
    routes: [
      {
        path: '/edit',
        component: SessionTypes.Edit,
      },
      {
        path: '/create',
        component: SessionTypes.Create,
      },
    ],
  },
  {
    path: '/features',
    routes: [
      {
        path: '/create',
        component: Features.Create,
      },
      {
        path: '/edit',
        component: Features.Edit,
      },
    ],
  },

  {
    path: '/NQSRTypes',
    routes: [
      {
        path: '/create',
        component: NQSRTypes.Create,
      },
      {
        path: '/edit',
        component: NQSRTypes.Edit,
      },
    ],
  },

  {
    path: '/appSettings',
    routes: [
      {
        path: '/create',
        component: AppSettings.Create,
        modalOptions: { width: 800 },
      },
      {
        path: '/edit',
        component: AppSettings.Edit,
        modalOptions: { width: 800 },
      },
    ],
  },

  {
    path: '/centres',
    routes: [
      {
        path: '/create',
        component: Centres.Create,
        modalOptions: { width: 1100 },
      },
      {
        path: '/edit',
        component: Centres.Edit,
        modalOptions: { width: 1100 },
      },
    ],
  },

  {
    path: '/users',
    routes: [
      {
        path: '/create',
        component: Users.Create,
      },
      {
        path: '/edit',
        component: Users.Edit,
      },
    ],
  },
];

let modal = null;

const getModalRoute = (currentModal) => {
  const modalRoute =
    currentModal &&
    modalRoutes.find((route) => currentModal.search(route.path) > -1);
  if (modalRoute) {
    return modalRoute.routes.find(
      (route) => currentModal.indexOf(route.path) > -1,
    );
  }
  return modalRoute;
};

const ModalRoute = () => {
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (location.hash && location.hash !== '#') {
      const modelRoute = location.hash.replace('#', '/');
      modal = getModalRoute(modelRoute);
    }
    // eslint-disable-next-line
  }, [location.hash]);
  const closeModal = () => {
    history.replace(`${location.pathname}${location.search}`);
  };

  const modelRoute = location.hash.replace('#', '/');
  modal = getModalRoute(modelRoute) || modal;
  const modalOptions = modal && modal?.modalOptions ? modal?.modalOptions : {};
  return (
    <Modal
      {...modalOptions}
      visible={!!(location.hash && location.hash !== '#')}
      footer={null}
      onCancel={closeModal}
      onClose={closeModal}
    >
      {modal?.component && (
        <modal.component
          showModal
          visibleModal={!!(location.hash && location.hash !== '#')}
          location={location}
        />
      )}
    </Modal>
  );
};

ModalRoute.propTypes = {
  location: PropTypes.object,
  currentModal: PropTypes.string,
  closeModal: PropTypes.func,
  showModal: PropTypes.func,
  replaceRoute: PropTypes.func,
};

export default ModalRoute;
