import React, { useCallback } from 'react';
import { Radio } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ENQUIRIES_STATUS_CONST } from 'configs/localData';
import { map } from 'lodash-es';
import i18next from 'i18next';
import { FilterMessageStyles } from './styles';

const FilterMessage = ({ retrieveList }) => {
  const summaries = useSelector((state) => state.inboxes.summaries) || {};

  const handleGetAll = ({
    centreHasNewMessage,
    centreResponded,
    archived = false,
  }) => {
    retrieveList({
      filter: {
        filter: {
          centreHasNewMessage,
          centreResponded,
          archivedAt: { $exists: archived },
        },
      },
      isRefresh: true,
    });
  };
  const formatTitle = useCallback(
    (text, count) => `${i18next.t(text)} (${count || 0})`,
    [],
  );

  const onChangeStatus = (e) => {
    switch (e.target.value) {
      case ENQUIRIES_STATUS_CONST.unread.value:
        handleGetAll({ centreHasNewMessage: true });
        break;

      case ENQUIRIES_STATUS_CONST.read.value:
        handleGetAll({ centreHasNewMessage: false });
        break;

      case ENQUIRIES_STATUS_CONST.responded.value:
        handleGetAll({ centreResponded: true });
        break;

      case ENQUIRIES_STATUS_CONST.notResponded.value:
        handleGetAll({ centreResponded: false });
        break;

      case ENQUIRIES_STATUS_CONST.archived.value:
        handleGetAll({ archived: true });
        break;

      default:
        handleGetAll({});
        break;
    }
  };

  return (
    <FilterMessageStyles className="enquiries__status-groups">
      <Radio.Group
        defaultValue="all"
        onChange={onChangeStatus}
        buttonStyle="solid"
      >
        <Radio.Button key="all" value="all">
          {formatTitle('common.all', summaries.total)}
        </Radio.Button>
        {map(ENQUIRIES_STATUS_CONST, (item, index) => (
          <Radio.Button key={String(index)} value={item.value}>
            {formatTitle(item.text, summaries?.[item.keySummary])}
          </Radio.Button>
        ))}
      </Radio.Group>
    </FilterMessageStyles>
  );
};

FilterMessage.propTypes = {
  retrieveList: PropTypes.func,
};

FilterMessage.defaultProps = {
  retrieveList: () => null,
};

export default FilterMessage;
