import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import EditButton from 'components/RestActions/EditButton';
import { useHistory, useParams } from 'react-router';
import CustomButtonIcon from 'components/form/CustomButtonIcon';
import { IncognitoIcon } from 'components/common/SVGIcon';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Tooltip } from 'antd';
import {
  checkLastUser,
  getAuthorizeWebPortal,
  unSubcriptionCentre,
} from 'redux/users/actions';
import { PREFIX_URL } from 'configs/localData/constant';
import { getCentreIdsOfCompany } from 'redux/companies/actions';
import { ButtonWrapper } from 'components/RestActions/DeleteButton/styles';
import { DeleteOutlined } from '@ant-design/icons';
import { delUserOfCompany } from 'redux/usersOfCompany/actions';

export const confirmDeleteLastUser = (
  deleteItem,
  record,
  resource,
  customMessage,
  nameProp,
  roleProp,
  lastItem,
  onCancel,
) => {
  Modal.confirm({
    title: `${i18next.t('popup.alertDelete')} ${i18next.t(
      `${resource}.titleDelete`,
    )}`,
    content: lastItem
      ? i18next.t(customMessage, {
          role: roleProp,
          name: `${record[nameProp]}`,
        })
      : i18next.t('popup.alertDeleteDes', {
          customMessage: customMessage
            ? `${i18next.t(customMessage)} ${record[nameProp]}`
            : `${record[nameProp]}`,
        }),
    okText: i18next.t('button.yes'),
    cancelText: i18next.t('button.no'),
    onOk: () => deleteItem(),
    onCancel: onCancel || null,
  });
};

const GroupActionsUser = ({ userId, record }) => {
  const { push } = useHistory();

  const dispatch = useDispatch();

  const currentCompany = useSelector((state) => state.companies?.currentData);

  const { id: companyId } = useParams();

  const handleClickView = () => {
    push(`/users/${userId}/show`);
  };

  const handleRedirect = useCallback(async () => {
    await dispatch(getAuthorizeWebPortal({ userId })).then(({ payload }) => {
      if (payload?.accessToken) {
        window.open(
          `${process.env.REACT_APP_CRM_WEB_URL}?authToken=${payload?.accessToken}`,
          '_blank',
        );
      }
    });
  }, []); // eslint-disable-line

  function handleImpersonating() {
    Modal.confirm({
      title: i18next.t('button.impersonate'),
      content: i18next.t('users.impersonateConfirmDesc'),
      onOk: () => {
        handleRedirect();
      },
    });
  }

  const onCancel = () => {};

  const unSubcription = () => () => {
    dispatch(getCentreIdsOfCompany(companyId)).then(({ payload }) => {
      payload && dispatch(unSubcriptionCentre({ centreIds: payload }));
    });
  };

  const deleteUserItem = () =>
    dispatch(
      delUserOfCompany({
        id: companyId,
        userId,
        options: {
          prefixUrl: PREFIX_URL.superAdmin,
          isRefresh: true,
        },
      }),
    );

  const deleteUserUnsubscription = () => {
    dispatch(
      delUserOfCompany({
        id: companyId,
        userId,
        options: {
          prefixUrl: PREFIX_URL.superAdmin,
          isRefresh: true,
        },
      }),
    ).then(({ payload }) => {
      payload?.success &&
        confirmDeleteLastUser(
          onCancel,
          currentCompany,
          'usersOfCompany',
          'users.confirmUnsubcription',
          'name',
          null,
          true,
          unSubcription(),
        );
    });
  };

  const deleteUserFromCompany = () => {
    dispatch(checkLastUser(`companyId=${companyId}&userId=${userId}`)).then(
      ({ payload }) => {
        if (!payload?.lastUser) {
          confirmDeleteLastUser(
            deleteUserItem,
            currentCompany,
            'usersOfCompany',
            'users.deleteUser',
            'name',
            null,
            payload?.lastUser,
          );
        } else {
          confirmDeleteLastUser(
            deleteUserUnsubscription,
            currentCompany,
            'usersOfCompany',
            'users.confirmDeleteLastUser',
            'name',
            record?.role?.description,
            payload?.lastUser,
          );
        }
      },
    );
  };

  return (
    <div className="flex items-center">
      <CustomButtonIcon
        title="button.impersonate"
        IconComponent={IncognitoIcon}
        handleClick={() => handleImpersonating()}
      />
      <EditButton isView source="show" onClickCustom={handleClickView} />
      {record?.role?.type === 'company' && (
        <ButtonWrapper>
          <Tooltip title={i18next.t('button.delete')}>
            <Button
              icon={<DeleteOutlined />}
              onClick={() => deleteUserFromCompany()}
            />
          </Tooltip>
        </ButtonWrapper>
      )}
    </div>
  );
};

GroupActionsUser.propTypes = {
  userId: PropTypes.string,
  record: PropTypes.object,
};

export default GroupActionsUser;
