import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Skeleton, Tree } from 'antd';
import PageTitle from 'components/common/PageTitle';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { customUrlParams, getQueryParams } from 'utils/tools';
import TitleTree from './components/TitleTree';
import { CategoriesTreeStyles } from './styles';
import CreateCategoryModal from './components/CategoriesModal/CreateCategoryModal';
import EditCategoryModal from './components/CategoriesModal/EditCategoryModal';

const { DirectoryTree } = Tree;

const CategoriesTree = () => {
  const history = useHistory();

  const filter = getQueryParams(history.location.search);

  const createModalRef = useRef();

  const editModalRef = useRef();

  const treeData = useSelector((state) => state.categories.treeData);

  const loading = useSelector((state) => state.categories.loading);

  const [isRedirect, setIsRedirect] = useState();

  const handleAddCategory = (item) => {
    createModalRef.current.open(item);
  };

  const handleEditCategory = (record) => {
    editModalRef.current.open(record);
  };

  const onSelect = (selectedKeys) => {
    const urlSearchParams = customUrlParams(
      { categoryId: selectedKeys[0] },
      history.location.search,
    );

    history.push(`?${urlSearchParams.toString()}`);
  };

  const selectedKeys = useMemo(() => {
    if (filter.categoryId) return [String(filter.categoryId)];

    if (treeData?.[0]?.id) return [String(treeData?.[0]?.id)];

    return [];
  }, [filter.categoryId, treeData]); // eslint-disable-line

  useEffect(() => {
    if (!isRedirect && !filter?.categoryId && treeData?.[0]?.id) {
      history.push(`?categoryId=${JSON.stringify(treeData?.[0]?.id)}`);
      setIsRedirect(true);
    }
  }, [treeData]); // eslint-disable-line

  return (
    <CategoriesTreeStyles>
      <PageTitle>{i18next.t('categories.title')}</PageTitle>
      <Skeleton loading={loading}>
        <div className="category-tree-content">
          <DirectoryTree
            multiple
            defaultExpandAll
            treeData={treeData}
            onSelect={onSelect}
            selectedKeys={selectedKeys}
            expandAction={false}
            titleRender={(item) => (
              <TitleTree
                handleEditCategory={handleEditCategory}
                handleAddCategory={handleAddCategory}
                item={item}
              />
            )}
          />
          <Button className="mt-10" type="primary" onClick={handleAddCategory}>
            {i18next.t('button.addNewCategory')}
          </Button>
        </div>
      </Skeleton>

      <CreateCategoryModal ref={createModalRef} />
      <EditCategoryModal ref={editModalRef} />
    </CategoriesTreeStyles>
  );
};

export default CategoriesTree;
