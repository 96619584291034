import React, { useState } from 'react';
import i18next from 'i18next';
import Form, { useForm } from 'antd/lib/form/Form';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import CardSection from 'components/common/CardSection';
import RestSelect from 'components/RestInput/RestSelect';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionsArr } from 'redux/centres/selectors';
import { CENTRE_STATUS_MAIN } from 'configs/localData';
import { sendEmailGroupCentres } from 'redux/EDM/actions';
import { compact, debounce } from 'lodash-es';
import { getTotalCentresWithQueryApi } from 'api/centres';
import { formatMoney } from 'utils/tools';
import { useEmailTemplate } from '../../context';

const GroupCentres = () => {
  const [form] = useForm();
  const { template } = useEmailTemplate();
  const dispatch = useDispatch();
  const [totalCentre, setTotalCentre] = useState(null);

  const stateOfAustralia = useSelector(
    (state) => state.config.data?.stateOfAustralia,
  );

  const subscriptionsArr = useSelector(getSubscriptionsArr);

  const [loading, setLoading] = useState();

  const handleSendMail = (value) => {
    setLoading(true);

    dispatch(
      sendEmailGroupCentres({
        filter: JSON.stringify(value),
        emailTemplateKey: template.key,
      }),
    ).finally(() => {
      setLoading(false);
    });
  };

  const onFinish = (value) => {
    const compactValue = compact(Object.values(value));

    if (compactValue?.length) {
      if (template.key) {
        Modal.confirm({
          title: i18next.t('EDM.confirmTitleGroupCentres'),
          content: i18next.t('EDM.confirmContentGroupCentres'),
          onOk() {
            handleSendMail(value);
          },
        });
      } else {
        Modal.warning({
          title: i18next.t('EDM.confirmTitleGroupCentres'),
          content: i18next.t('EDM.confirmContentSelectTemplate'),
        });
      }
    } else {
      Modal.warning({
        title: i18next.t('EDM.confirmTitleGroupCentres'),
        content: i18next.t('EDM.confirmContentInputInfo'),
      });
    }
  };

  const onValuesChange = async (changedValues, data) => {
    if (compact(Object.values(data))?.length) {
      try {
        const { CRMPackageId, claimStatus, ...values } = data;
        const response = await getTotalCentresWithQueryApi({
          limit: 1,
          offset: 0,
          CRMPackageId,
          filter: JSON.stringify({
            ...values,
            isActive: true,
            claimStatus:
              claimStatus === 'NULL' ? { $exists: false } : claimStatus,
          }),
        });
        setTotalCentre(response.total || 0);
      } catch (error) {
        throw new Error(error);
      }
    } else {
      setTotalCentre(null);
    }
  };

  return (
    <CardSection title="marketings.groupCentres">
      <Form
        form={form}
        onFinish={onFinish}
        onValuesChange={debounce(onValuesChange, 1000)}
      >
        <RestInputContext.Provider value={{ form }}>
          <RestSelect
            source="state"
            placeholder="input.state.placeholder"
            resourceData={stateOfAustralia}
          />

          <RestSelect
            source="CRMPackageId"
            placeholder="input.subscription.placeholder"
            resourceData={subscriptionsArr}
            valueProp="id"
            titleProp="name"
          />

          <RestSelect
            source="claimStatus"
            placeholder="input.claimStatus.placeholder"
            resourceData={CENTRE_STATUS_MAIN}
            formatText={(text) => i18next.t(text)}
            valueProp="valueTemplate"
            titleProp="text"
          />
        </RestInputContext.Provider>

        <div className="flex-center-between">
          {totalCentre !== null ? (
            <b className="centre-total">
              {i18next.t('EDM.centresFound')}
              {': '}
              {formatMoney(totalCentre)}
            </b>
          ) : (
            <span />
          )}
          <Button htmlType="submit" type="primary" loading={loading}>
            {i18next.t('button.send')}
          </Button>
        </div>
      </Form>
    </CardSection>
  );
};

export default GroupCentres;
