import React from 'react';
import { isPlainObject } from 'lodash-es';
import Create from 'containers/rest/Create';
import Form from '../components/Form';

const NQSRTypesCreate = (props) => (
  <Create
    {...props}
    resource="NQSRTypes"
    formatOnSubmit={({ image, ...data }) => ({
      ...data,
      image: isPlainObject(image) ? image.url : image,
      isActive: true,
    })}
    defaultOptions={{ isTrimStr: true, isGetConfig: true }}
  >
    <Form />
  </Create>
);

export default NQSRTypesCreate;
