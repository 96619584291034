import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import SwitcherAction from './SwitcherAction';

const HeaderList = ({
  retrieveList,
  title = 'activitiesAndNotes.title',
  openCreateModal,
}) => {
  const [isCentreLevel, setIsCentreLevel] = useState(false);

  return (
    <div className="section-header flex-center-between">
      <div className="flex items-center">
        <div className="section-header__title">{i18next.t(title)}</div>
        <SwitcherAction
          retrieveList={retrieveList}
          setIsCentreLevel={setIsCentreLevel}
        />
      </div>

      {!isCentreLevel && (
        <Button
          className="btn-second-primary"
          icon={<PlusOutlined />}
          onClick={openCreateModal}
        >
          {i18next.t('button.create')}
        </Button>
      )}
    </div>
  );
};

HeaderList.propTypes = {
  retrieveList: PropTypes.func,
  openCreateModal: PropTypes.func,
  title: PropTypes.string,
};

export default HeaderList;
