import React, { useState } from 'react';
import i18next from 'i18next';
import { PropTypes } from 'prop-types';
import { Row, Col } from 'antd';

import CardSection from 'components/common/CardSection';
import { ImageInfoStyles } from 'containers/Providers/components/SummaryTab/styles';
import EducatorAndStaffDiscountsModal from '../Modals/EducatorAndStaffDiscountsModal';

const EducatorAndStaffDiscounts = ({ record }) => {
  const [visible, setVisible] = useState(false);

  const onOpen = () => {
    setVisible(true);
  };

  const onCancel = () => {
    setVisible(false);
  };

  return (
    <ImageInfoStyles className="text-16">
      <CardSection
        onClickButton={onOpen}
        isEdit
        title="companies.educatorAndStaffDiscounts"
      >
        <Row className="mb-32">
          <Col span={8}>{i18next.t('companies.educatorDiscount')}</Col>
          <Col span={16}>
            <strong>
              {record?.educatorDiscount
                ? `${record?.educatorDiscount}%`
                : i18next.t('error.waitingUpdate')}
            </strong>
          </Col>
        </Row>
        <Row className="mb-32">
          <Col span={8}>{i18next.t('companies.staffDiscount')}</Col>
          <Col span={16}>
            <strong>
              {record?.staffDiscount
                ? `${record?.staffDiscount}%`
                : i18next.t('error.waitingUpdate')}
            </strong>
          </Col>
        </Row>
      </CardSection>
      <EducatorAndStaffDiscountsModal
        visible={visible}
        onCancel={onCancel}
        educatorDiscount={record?.educatorDiscount}
        staffDiscount={record?.staffDiscount}
      />
    </ImageInfoStyles>
  );
};

EducatorAndStaffDiscounts.propTypes = {
  record: PropTypes.object,
};

export default EducatorAndStaffDiscounts;
