import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import I18n from 'i18next';
import { ButtonWrapper } from './styles';

const { confirm, warn } = Modal;

const DeleteButton = ({
  deleteItem,
  record,
  resource,
  customMessage,
  nameProp,
  customTitle,
  content,
  ...props
}) => {
  const handleDelete = () => {
    if (record.isActive && props.isDeleteInactiveCentre) {
      warn({
        title: customTitle,
        content: props.contentCannotDelete,
        okText: I18n.t('button.ok'),
      });
    } else {
      confirm({
        title: `${I18n.t('popup.alertDelete')} ${I18n.t(
          `${resource}.titleDelete`,
        )}`,
        content: I18n.t('popup.alertDeleteDes', {
          customMessage: customMessage
            ? I18n.t(customMessage)
            : `${props.customResource} ${record[nameProp]}`,
        }),
        okText: I18n.t('button.ok'),
        cancelText: I18n.t('button.cancel'),
        onOk: () => deleteItem(record.id, record),
        onCancel: () => {},
      });
    }
  };

  return (
    <ButtonWrapper>
      <Tooltip title={I18n.t('button.delete')}>
        <Button icon={<DeleteOutlined />} onClick={handleDelete} />
      </Tooltip>
    </ButtonWrapper>
  );
};

DeleteButton.propTypes = {
  deleteItem: PropTypes.func,
  record: PropTypes.object,
  resource: PropTypes.string,
  customMessage: PropTypes.string,
  nameProp: PropTypes.string,
  customTitle: PropTypes.string,
  content: PropTypes.string,
  contentCannotDelete: PropTypes.string,
  customResource: PropTypes.string,
  isDeleteInactiveCentre: PropTypes.bool,
};

DeleteButton.defaultProps = {
  record: {},
  nameProp: 'name',
  contentCannotDelete: '',
  customResource: '',
};

export default DeleteButton;
