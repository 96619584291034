import { createSlice } from '@reduxjs/toolkit';
import {
  getAllCustomFail,
  getAllCustomPending,
  getAllCustomSuccess,
  INITIAL_STATE_CUSTOM,
  resetResourceDataCustom,
} from 'redux/crudCreatorCustom/utils';
import { getProvidersOfUser } from './actions';

export const initialState = {
  ...INITIAL_STATE_CUSTOM,
};

const { actions, reducer } = createSlice({
  name: 'ProvidersOfUser',
  initialState,
  reducers: {
    resetResourceFilterProvidersOfUser: resetResourceDataCustom,
  },
  extraReducers: {
    [getProvidersOfUser.pending]: getAllCustomPending,
    [getProvidersOfUser.fulfilled]: getAllCustomSuccess,
    [getProvidersOfUser.rejected]: getAllCustomFail,
  },
});

export const { resetResourceFilterProvidersOfUser } = actions;

export default reducer;
