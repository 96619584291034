export const formatSortTable = (sorter) =>
  sorter && sorter.field && sorter.order
    ? `${sorter.order === 'descend' ? '-' : ''}${sorter.field}`
    : 'name';

export const getQueryParamsWhenChangeTable = (e, filters, sorter) => {
  const queryParams = {
    offset: (e.current - 1) * e.pageSize,
    limit: e.pageSize,
    orderBy: formatSortTable(sorter),
  };

  return queryParams;
};
