import React from 'react';
import PropTypes from 'prop-types';

import { formatMoney } from 'utils/tools';
import { CURRENCY_UNIT } from 'configs/localData/constant';
import { ServiceTypesStyles } from './styles';

const ServiceTypes = ({ centreServices }) => (
  <ServiceTypesStyles>
    {centreServices?.map((item, index) => (
      <div key={String(index)} className="service-item-wrapper">
        <div className="service-item">
          {`${item?.serviceName || item?.serviceType?.name} - ${formatMoney(
            item.costPerDay,
          )} ${CURRENCY_UNIT}`}
        </div>

        {item?.serviceAgeGroup?.name && (
          <div className="age-group-service">
            {`(${item?.serviceAgeGroup?.name})`}
          </div>
        )}
      </div>
    ))}
  </ServiceTypesStyles>
);

ServiceTypes.propTypes = {
  centreServices: PropTypes.array,
};

export default ServiceTypes;
