import React from 'react';
import { Switch } from 'antd';
import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import { formatContent } from 'utils/formatFieldUtils';
import Filter from '../components/Filter';

const AppSettings = (props) => (
  <List
    widthTable="1000px"
    {...props}
    resource="appSettings"
    hasSearch={false}
    filter={<Filter />}
  >
    <RestFieldItem source="key" header="appSettings.key" />
    <RestFieldItem
      source="value"
      header="appSettings.value"
      format={formatContent}
    />
    <RestFieldItem
      source="isActive"
      header="appSettings.isActive"
      valueProp="checked"
      component={<Switch />}
    />
    <ActionGroup widthAction={80}>
      <EditButton />
    </ActionGroup>
  </List>
);

export default AppSettings;
