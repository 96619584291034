import React from 'react';
import RestShow from 'containers/rest/Show';
import { usersSelectors } from 'redux/users/selectors';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import { PREFIX_URL } from 'configs/localData/constant';

import UserDetail from '../components/UserDetail';

const UsersShow = (props) => {
  const currentData = useSelector(usersSelectors.getCurrentData);

  const customBreadcrumb = [
    {
      title: i18next.t('users.header'),
      path: '/users',
    },
    {
      title: `${currentData?.firstName || ''} ${currentData?.lastName || ''}`,
      path: '#',
    },
  ];

  return (
    <RestShow
      {...props}
      resource="users"
      isResetStyle
      header="users.titleDetail"
      customBreadcrumb={customBreadcrumb}
      defaultOptions={{ prefixUrl: PREFIX_URL.superAdmin }}
    >
      <UserDetail />
    </RestShow>
  );
};

export default UsersShow;
