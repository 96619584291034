import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import { Button } from 'antd';

const CreateButton = ({ className, header, gotoCreatePage, source }) => (
  <div className={className || ''}>
    <Button
      source={source}
      type="primary"
      onClick={gotoCreatePage}
      className="t-500-14px-1.57"
    >
      {I18n.t(header)}
    </Button>
  </div>
);
CreateButton.propTypes = {
  gotoCreatePage: PropTypes.func,
  header: PropTypes.string,
  source: PropTypes.string,
  className: PropTypes.string,
};

CreateButton.defaultProps = {
  source: 'create',
  header: 'button.create',
};

export default CreateButton;
