import { uniqBy } from 'lodash-es';
import {
  FallOutlined,
  RiseOutlined,
  WomanOutlined,
  ManOutlined,
  KeyOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileWordOutlined,
  FileExcelOutlined,
  FileZipOutlined,
  FileTextOutlined,
  VideoCameraOutlined,
  AudioOutlined,
  FileImageOutlined,
  SendOutlined,
} from '@ant-design/icons';
import {
  PharmacyIcon,
  PreparingIcon,
  SendingIcon,
  DeliveryIcon,
  DraftIcon,
  CalendarIcon,
  CheckIcon,
  IncomingCallCircleShadowIcon,
  OutgoingCallCircleShadowIcon,
  EmailCircleShadowIcon,
  NoteCircleShadowIcon,
  OtherCircleShadowIcon,
  CentreIcon,
  ProviderIcon,
  CompaniesIcon,
} from 'components/common/SVGIcon';
import CentreInformationModal from 'containers/Centres/components/Modals/CentreDetailInfoModal';
import UploadBusinessLogoModal from 'containers/Centres/components/Modals/UploadBusinessLogoModal';
import CentrePhotoModal from 'containers/Centres/components/Modals/CentrePhotoModal';
import HoursTab from 'containers/Centres/components/HoursTab';
import FeaturesTab from 'containers/Centres/components/FeaturesTab';
import ServicesTab from 'containers/Centres/components/ServicesTab';
import RatingsReviewsTab from 'containers/Centres/components/RatingsReviewsTab';
import CentreDescriptionModal from 'containers/Centres/components/Modals/DescriptionModal';
import MoreCentreInfoModal from 'containers/Centres/components/Modals/MoreCentreInfoModal';
import nationalities from './nationalities';
import commonJobs from './commonJobs';
import theme from '../theme/index';

export const FORMAT_DATE = 'MMM DD, YYYY';

export const ENQUIRIES_STATUS_CONST = {
  read: {
    key: 'read',
    value: 'Read',
    text: 'enquiriesStatus.read',
    fillColor: '#32A4FC',
    backgroundColor: '#EEF2FA',
    color: 'blue',
    keySummary: 'read',
  },
  unread: {
    key: 'unread',
    value: 'Unread',
    text: 'enquiriesStatus.unread',
    fillColor: '#FB8429',
    backgroundColor: '#FFF4EC',
    color: 'orange',
    keySummary: 'unRead',
  },
  responded: {
    key: 'responded',
    value: 'Responded',
    text: 'enquiriesStatus.responded',
    fillColor: '#36BF57',
    backgroundColor: '#EDF9F0',
    keySummary: 'responded',
    color: 'green',
  },
  notResponded: {
    key: 'notResponded',
    value: 'NotResponded',
    text: 'enquiriesStatus.notResponded',
    fillColor: '#857E7F',
    backgroundColor: '#F2F2F2',
    keySummary: 'unResponded',
  },
  archived: {
    key: 'archived',
    value: 'Archived',
    text: 'enquiriesStatus.archived',
    fillColor: '#DB147F',
    backgroundColor: '#FFF3FC',
    keySummary: 'archived',
    color: 'pink',
  },
};

export const PERMISSION = [
  {
    value: 'PUT',
    text: 'permission.put',
  },
  {
    value: 'CREATE',
    text: 'permission.create',
  },
  {
    value: 'READ',
    text: 'permission.read',
  },
  {
    value: 'DELETE',
    text: 'permission.delete',
  },
  {
    value: 'ADDCLASS',
    text: 'permission.addClass',
  },
  {
    value: 'REMOVECLASS',
    text: 'permission.removeClass',
  },
  {
    value: 'GETLIST',
    text: 'permission.getList',
  },
  {
    value: 'LIST_PERMISSION',
    text: 'permission.listPermission',
  },
];

export const SEMESTERS = [
  {
    value: true,
    text: 'semesters.isDone',
    color: 'gray',
  },
  {
    value: false,
    text: 'semesters.notDone',
    color: 'green',
  },
];

export const CHART_LINES = [
  {
    value: 'sales',
    dataKey: 'sales',
    text: 'home.chart.sales',
    stroke: '#f83995',
    fillId: 'salesFillColor',
    fillColor: '#f83995',
  },
  {
    value: 'profit',
    dataKey: 'profit',
    text: 'home.chart.profit',
    stroke: '#4d79f6',
    fillId: 'profitFillColor',
    fillColor: '#4d79f6',
  },
];

export const COUNTRY = [
  {
    value: 'buenosAires',
    name: 'home.country.buenosAires',
    color: '#F93B7A',
  },
  {
    value: 'brasilia',
    name: 'home.country.brasilia',
    color: '#0AAFFF',
  },
  {
    value: 'santiago',
    name: 'home.country.santiago',
    color: '#FFC212',
  },
  {
    value: 'bogota',
    name: 'home.country.bogota',
    color: '#7551E9',
  },
];

export const FINANCES = [
  {
    value: 'tuition',
    text: 'finances.tuition',
  },
  {
    value: 'mealFee',
    text: 'finances.mealFee',
  },
  {
    value: 'subClass',
    text: 'finances.subClass',
  },
];

export const GENDER = [
  {
    value: 'male',
    text: 'gender.male',
  },
  {
    value: 'female',
    text: 'gender.female',
  },
];

export const SUBJECTS = [
  {
    value: 'main',
    text: 'subjects.typeMain',
    color: '#56ac00',
  },
  {
    value: 'sub',
    text: 'subjects.typeSub',
    color: '#f8961d',
  },
];

export const STATUS = [
  {
    value: 1,
    text: 'status.active',
    color: theme.color.green,
  },
  {
    value: 0,
    text: 'status.inactive',
    color: theme.color.red,
  },
];

export const PAYMENT_STATUS = [
  {
    value: false,
    text: 'Not paid',
    textColor: theme.color.red,
    icon: 'close-circle',
  },
  {
    value: true,
    text: 'Paid',
    textColor: theme.color.green,
    icon: 'check-circle',
  },
];

export const QUOTE_STATUS = [
  {
    value: 'REJECTED',
    text: 'Rejected',
    color: theme.color.red,
    textColor: '#fff',
  },
  {
    value: 'ACCEPTED',
    text: 'Accepted',
    color: theme.color.green,
    textColor: '#fff',
  },
  {
    value: 'CANCELLED',
    text: 'Cancelled',
    color: theme.color.red,
    textColor: '#fff',
  },
];

export const ORDER_STATUS = [
  {
    value: 'OPEN',
    text: 'Open',
    color: theme.color.yellow,
    icon: 'folder-open',
    textColor: 'white',
  },
  {
    value: 'PROCESSING',
    text: 'Processing',
    color: theme.color.green,
    icon: 'interaction',
    textColor: 'white',
  },
  {
    value: 'ONDELIVERY',
    text: 'On Delivery',
    color: theme.color.green,
    icon: 'car',
    textColor: 'white',
  },
  {
    value: 'DELIVERED',
    text: 'Delivered',
    color: theme.color.blue,
    icon: 'home',
    textColor: 'white',
  },
  {
    value: 'COMPLETE',
    text: 'Complete',
    color: theme.color.blue,
    icon: 'check-circle',
    textColor: 'white',
  },
  {
    value: 'CANCELLED',
    text: 'Cancelled',
    color: theme.color.red,
    icon: 'close-circle',
    textColor: 'white',
  },
  {
    value: 'WAITING_FOR_QUOTE',
    text: 'Waiting for Quote',
    color: theme.color.orange,
    icon: 'check-circle',
    textColor: 'white',
  },
];

export const PRODUCT_STATUS = [
  {
    value: 'inprogress',
    text: 'status.inprogress',
    color: theme.color.green,
  },
  {
    value: 'pending',
    text: 'status.pending',
    color: theme.color.orange,
  },
  {
    value: 'completed',
    text: 'status.completed',
    color: theme.color.blue,
  },
  {
    value: 'developing',
    text: 'status.developing',
    color: theme.color.green,
  },
];

export const ACTIVITIES_LOG = [
  {
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis vulputate justo ornare feugiat.',
  },
  {
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis vulputate justo ornare feugiat.',
  },
  {
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis vulputate justo ornare feugiat.',
  },
  {
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis vulputate justo ornare feugiat.',
  },
];

export const USER_STATUS = [
  {
    id: 1,
    data: 'true',
    text: {
      en: 'Locked',
      vi: 'Khoá',
    },
  },
  {
    id: 1,
    data: 'false',
    text: {
      en: 'Unlock',
      vi: 'Không Khoá',
    },
  },
];

export const MEMBER_STATUS = [
  {
    id: 1,
    data: 'false',
    text: {
      en: 'Locked',
      vi: 'Khoá',
    },
  },
  {
    id: 1,
    data: 'true',
    text: {
      en: 'Unlock',
      vi: 'Không Khoá',
    },
  },
];

export const ACTIVE_TYPES = [
  {
    id: 1,
    value: true,
    text: 'isActive.active',
    color: '#52c41a',
  },
  {
    id: 2,
    value: false,
    text: 'isActive.inactive',
    color: '#e64c38',
  },
];

export const DISABLE_TYPES = [
  {
    value: false,
    text: 'isDisabled.notDisabled',
  },
  {
    value: true,
    text: 'isDisabled.disabled',
  },
];

export const MEMBER_ROLES = [
  {
    id: 1,
    text: {
      en: 'Admin',
      vi: 'Admin',
    },
  },
  {
    id: 2,
    text: {
      en: 'Staff',
      vi: 'Nhân viên',
    },
  },
  {
    id: 3,
    text: {
      en: 'Member',
      vi: 'Khách hàng',
    },
  },
];

export const LANGUAGES = [
  {
    id: 'en',
    text: {
      en: 'English',
      vi: 'Tiếng Anh',
    },
  },
  {
    id: 'vi',
    text: {
      en: 'Vietnamese',
      vi: 'Tiếng Việt',
    },
  },
];

export const GENDERS = [
  {
    value: 'male',
    text: 'gender.male',
    color: '#1890ff',
    icon: ManOutlined,
  },
  {
    value: 'female',
    text: 'gender.female',
    color: '#eb2f96',
    icon: WomanOutlined,
  },
  {
    value: 'other',
    text: 'gender.other',
    color: '#959595',
    icon: KeyOutlined,
  },
];

export const BOOKING_STATUS = [
  {
    id: 'INPROGRESS',
    value: 'INPROGRESS',
    text: 'status.inprogress',
    requestSuffixUrl: 'inprogress',
  },
  {
    id: 'COMPLETED',
    value: 'COMPLETED',
    text: 'status.completed',
    requestSuffixUrl: 'complete',
  },
  {
    id: 'CANCELLED',
    value: 'CANCELLED',
    text: 'status.cancelled',
    requestSuffixUrl: 'cancel',
  },
  { id: 'PENDING', value: 'PENDING', text: 'status.pending' },
];

export const HOME_TAB = [
  { id: 'todayBooking', title: 'Today' },
  { id: 'pendingBooking', title: 'Upcoming' },
];

export const MOMENT_CODE = {
  daily: 'd',
  hourly: 'h',
  weekly: 'w',
  monthly: 'M',
};

export const TRANSACTION_TYPE = [
  { value: 'INCOME', text: 'button.income' },
  { value: 'EXPENSE', text: 'button.expense' },
];

export const PACKAGE_TYPES_TIME_UNIT = {
  hourly: 'hour',
  daily: 'day',
  weekly: 'week',
  monthly: 'month',
};
export const BOOKINGS_TYPES = ['todayBooking', 'pendingBooking'];
export const CHECKIN_STATUS = [
  { text: 'checkinStatus.waitingCheckin', value: 'waitingCheckin' },
  { text: 'checkinStatus.waitingCheckout', value: 'waitingCheckout' },
  { text: 'checkinStatus.completed', value: 'completed' },
  { text: 'checkinStatus.all', value: 'all' },
  { text: 'checkinStatus.upcoming', value: 'upcoming' },
];
export const DISCOUNT_UNIT = [
  { text: '%', value: 'percent' },
  { text: 'VND', value: 'number' },
];
export const PAYMENT_METHOD = [
  { text: 'payType.paymentByCash', value: 'cash' },
  { text: 'payType.paymentByBank', value: 'bank' },
];

export const NOTIFICATIONS = [
  {
    value: 'pharmacy',
    text: 'notifications.pharmacy',
    icon: PharmacyIcon,
  },
  {
    value: 'delivery',
    text: 'notifications.delivery',
    icon: DeliveryIcon,
  },
  {
    value: 'prepare',
    text: 'notifications.prepare',
    icon: PreparingIcon,
  },
  {
    value: 'send',
    text: 'notifications.send',
    icon: SendingIcon,
  },
];

export const BILLING_UNIT = [
  { text: 'packages.hour', value: 'Hour' },
  { text: 'packages.month', value: 'Month' },
  { text: 'packages.week', value: 'Week' },
  { text: 'packages.day', value: 'Day' },
];

export const ROLE = [
  { text: 'role.superadmin', value: 'superadmin' },
  { text: 'role.admin', value: 'admin' },
  { text: 'role.user', value: 'user' },
];

export const JOBS = commonJobs;
export const NATIONALITIES = uniqBy(nationalities, 'nationality');

export const QUALIFICATIONS = [
  { text: 'qualifications.university', value: 'university' },
  { text: 'qualifications.colleges', value: 'colleges' },
];

export const OPEN_TIME = [
  {
    value: true,
    text: 'time.open',
    color: '#52c41a',
  },
  {
    value: false,
    text: 'time.closed',
    color: '#e64c38',
  },
];

export const SUMMARY_CARD_TYPES = [
  {
    value: 'up',
    text: 'summaryCard.up',
    icon: RiseOutlined,
    color: theme.color.green,
  },
  {
    value: 'down',
    text: 'summaryCard.down',
    icon: FallOutlined,
    color: theme.color.red,
  },
];

export const DETAIL_SUMMARY_CARD_TYPES = [
  {
    value: 'up',
    text: 'detailSummaryCard.up',
    icon: 'arrow-up',
    color: theme.color.green,
  },
  {
    value: 'down',
    text: 'detailSummaryCard.down',
    icon: 'arrow-down',
    color: theme.color.red,
  },
];

export const SUMMARIES_CUSTOMER = [
  {
    dataKey: 'newCustomer',
    color: '#0088FE',
    text: 'customers.newCustomer',
  },
  {
    dataKey: 'repeatedCustomer',
    color: '#00C49F',
    text: 'customers.repeatedCustomer',
  },
];

export const CONTRACT_TYPES = [
  { text: 'contractTypes.official', value: 'official' },
  { text: 'contractTypes.partTime', value: 'partTime' },
];

export const RATINGS = [
  {
    value: '1',
    text: '1 Star',
  },
  {
    value: '2',
    text: '2 Star',
  },
  {
    value: '3',
    text: '3 Star',
  },
  {
    value: '4',
    text: '4 Star',
  },
  {
    value: '5',
    text: '5 Star',
  },
];

export const SEASON_TYPE = [
  { value: 'schoolTerm', text: 'seasonType.schoolTerm' },
  { value: 'normal', text: 'seasonType.normal' },
  { value: 'holiday', text: 'seasonType.holiday' },
];

export const SCHOOL_TERM_MON_TO_FRI = {
  text: 'daysOfWeek.monToFri',
  value: 'monToFri_schoolTerm',
  data: [
    {
      value: 'monToFri_morning_schoolTerm',
      text: 'daysOfWeek.morning',
      valueMap: 'mon_morning_schoolTerm',
    },
    {
      value: 'monToFri_afternoon_schoolTerm',
      text: 'daysOfWeek.afternoon',
      valueMap: 'mon_afternoon_schoolTerm',
    },
  ],
};

export const SCHOOL_TERM_DAYS_OF_WEEKEND = [
  {
    value: 'sat_schoolTerm',
    text: 'daysOfWeek.sat',
    data: [
      {
        value: 'sat_morning_schoolTerm',
        text: 'daysOfWeek.morning',
      },
      {
        value: 'sat_afternoon_schoolTerm',
        text: 'daysOfWeek.afternoon',
      },
    ],
  },
  {
    value: 'sun_schoolTerm',
    text: 'daysOfWeek.sun',
    data: [
      {
        value: 'sun_morning_schoolTerm',
        text: 'daysOfWeek.morning',
      },
      {
        value: 'sun_afternoon_schoolTerm',
        text: 'daysOfWeek.afternoon',
      },
    ],
  },
];

export const SCHOOL_TERM_EXCEPT_WEEKEND = [
  {
    value: 'mon_schoolTerm',
    text: 'daysOfWeek.mon',
    data: [
      {
        value: 'mon_morning_schoolTerm',
        text: 'daysOfWeek.morning',
      },
      {
        value: 'mon_afternoon_schoolTerm',
        text: 'daysOfWeek.afternoon',
      },
    ],
  },
  {
    value: 'tue_schoolTerm',
    text: 'daysOfWeek.tue',
    data: [
      {
        value: 'tue_morning_schoolTerm',
        text: 'daysOfWeek.morning',
      },
      {
        value: 'tue_afternoon_schoolTerm',
        text: 'daysOfWeek.afternoon',
      },
    ],
  },
  {
    value: 'wed_schoolTerm',
    text: 'daysOfWeek.wed',
    data: [
      {
        value: 'wed_morning_schoolTerm',
        text: 'daysOfWeek.morning',
      },
      {
        value: 'wed_afternoon_schoolTerm',
        text: 'daysOfWeek.afternoon',
      },
    ],
  },
  {
    value: 'thu_schoolTerm',
    text: 'daysOfWeek.thu',
    data: [
      {
        value: 'thu_morning_schoolTerm',
        text: 'daysOfWeek.morning',
      },
      {
        value: 'thu_afternoon_schoolTerm',
        text: 'daysOfWeek.afternoon',
      },
    ],
  },
  {
    value: 'fri_schoolTerm',
    text: 'daysOfWeek.fri',
    data: [
      {
        value: 'fri_morning_schoolTerm',
        text: 'daysOfWeek.morning',
      },
      {
        value: 'fri_afternoon_schoolTerm',
        text: 'daysOfWeek.afternoon',
      },
    ],
  },
];

export const NORMAL_MON_TO_FRI = {
  value: 'monToFri_normal',
  text: 'daysOfWeek.monToFri',
  valueMap: 'mon_normal',
};

export const NORMAL_DAYS_OF_WEEKEND = [
  {
    value: 'sat_normal',
    text: 'daysOfWeek.sat',
  },
  {
    value: 'sun_normal',
    text: 'daysOfWeek.sun',
  },
];

export const NORMAL_EXCEPT_WEEKEND = [
  {
    value: 'mon_normal',
    text: 'daysOfWeek.mon',
  },
  {
    value: 'tue_normal',
    text: 'daysOfWeek.tue',
  },
  {
    value: 'wed_normal',
    text: 'daysOfWeek.wed',
  },
  {
    value: 'thu_normal',
    text: 'daysOfWeek.thu',
  },
  {
    value: 'fri_normal',
    text: 'daysOfWeek.fri',
  },
];

export const HOLIDAY_MON_TO_FRI = {
  value: 'monToFri_holiday',
  text: 'daysOfWeek.monToFri',
  valueMap: 'mon_holiday',
};

export const HOLIDAY_DAYS_OF_WEEKEND = [
  {
    value: 'sat_holiday',
    text: 'daysOfWeek.sat',
  },
  {
    value: 'sun_holiday',
    text: 'daysOfWeek.sun',
  },
];

export const HOLIDAY_EXCEPT_WEEKEND = [
  {
    value: 'mon_holiday',
    text: 'daysOfWeek.mon',
  },
  {
    value: 'tue_holiday',
    text: 'daysOfWeek.tue',
  },
  {
    value: 'wed_holiday',
    text: 'daysOfWeek.wed',
  },
  {
    value: 'thu_holiday',
    text: 'daysOfWeek.thu',
  },
  {
    value: 'fri_holiday',
    text: 'daysOfWeek.fri',
  },
];

export const KEYS_MON_TO_FRI = [
  'monToFri_morning_schoolTerm',
  'monToFri_afternoon_schoolTerm',
  'monToFri_normal',
  'monToFri_holiday',
];

export const SERVICE_AGE_GROUPS = [
  {
    value: '24to36months',
    text: 'ageGroups.24to36months',
  },
  {
    value: '13to24months',
    text: 'ageGroups.13to24months',
  },
  {
    value: '0to12months',
    text: 'ageGroups.0to12months',
  },
  {
    value: '36monthsplus',
    text: 'ageGroups.36monthsplus',
  },
];

export const VALUE_ROOT_OPEN_TIME = {
  arrRootMorning1: ['mon_morning_schoolTerm'],
  arrRootMorning2: [
    'tue_morning_schoolTerm',
    'wed_morning_schoolTerm',
    'thu_morning_schoolTerm',
    'fri_morning_schoolTerm',
  ],
  arrRootAfternoon1: ['mon_afternoon_schoolTerm'],
  arrRootAfternoon2: [
    'tue_afternoon_schoolTerm',
    'wed_afternoon_schoolTerm',
    'thu_afternoon_schoolTerm',
    'fri_afternoon_schoolTerm',
  ],
  arrRootNormal1: ['mon_normal'],
  arrRootNormal2: ['tue_normal', 'wed_normal', 'thu_normal', 'fri_normal'],
  arrRootHoliday1: ['mon_holiday'],
  arrRootHoliday2: ['tue_holiday', 'wed_holiday', 'thu_holiday', 'fri_holiday'],
};

export const VACANCY_COD = [
  {
    value: true,
    text: 'vacancyCod.available',
    color: 'green',
    colorText: theme.color.green,
  },
  {
    value: false,
    text: 'vacancyCod.unavailable',
    color: 'red',
    colorText: theme.color.red,
  },
];

export const CENTRE_NQS_RATING = [
  { value: 'currentEPM', text: 'centreNQSRating.currentEPM' },
  { value: 'currentCHS', text: 'centreNQSRating.currentCHS' },
  { value: 'currentPE', text: 'centreNQSRating.currentPE' },
  { value: 'currentSA', text: 'centreNQSRating.currentSA' },
  { value: 'currentRWC', text: 'centreNQSRating.currentRWC' },
  { value: 'currentCPWFAC', text: 'centreNQSRating.currentCPWFAC' },
  { value: 'currentGAL', text: 'centreNQSRating.currentGAL' },
];

export const CENTRE_STATUS = [
  { value: 'PENDING', text: 'centreStatus.pending', color: 'blue' },
  {
    value: 'IN_PROCESSING',
    text: 'centreStatus.inProcessing',
    color: 'orange',
  },
  { value: 'APPROVED', text: 'centreStatus.approved', color: 'green' },
  { value: 'DECLINED', text: 'centreStatus.declined', color: 'red' },
];

export const ENQUIRY_CENTRE_STATUS = [
  { value: null, text: 'centreStatus.unclaimed', color: 'purple' },
  { value: 'PENDING', text: 'centreStatus.pending', color: 'blue' },
  { value: 'IN_PROCESSING', text: 'centreStatus.inprogress', color: 'orange' },
  { value: 'APPROVED', text: 'centreStatus.approved', color: 'purple' },
  { value: 'CLAIMED', text: 'centreStatus.claimed', color: 'green' },
  { value: 'DECLINED', text: 'centreStatus.declined', color: 'red' },
  { value: 'UNCLAIMED', text: 'centreStatus.unclaimed', color: 'purple' },
];

export const CENTRE_STATUS_MAIN = [
  {
    value: null,
    valueTemplate: 'NULL',
    text: 'centreStatus.unclaimed',
    color: 'purple',
  },
  {
    value: 'PENDING',
    valueTemplate: 'PENDING',
    text: 'centreStatus.pending',
    color: 'blue',
  },
  {
    value: 'CLAIMED',
    valueTemplate: 'CLAIMED',
    text: 'centreStatus.claimed',
    color: 'green',
  },
];

export const CENTRE_STATUS_VALUE = {
  pending: 'PENDING',
  inProcessing: 'IN_PROCESSING',
  approved: 'APPROVED',
  declined: 'DECLINED',
  claimed: 'CLAIMED',
};

export const ENQUIRY_TYPES_CONST = {
  APPLICATION: {
    value: 'APPLICATION',
    text: 'enquiryTypes.application',
    color: 'magenta',
    dotColor: '#DB147F',
  },
  CHECK_FOR_VACANCIES: {
    value: 'CHECK_FOR_VACANCIES',
    text: 'enquiryTypes.checkForVacancies',
    color: 'green',
    dotColor: '#36BF57',
  },
  BOOK_A_TOUR_OR_VISIT: {
    value: 'BOOK_A_TOUR_OR_VISIT',
    text: 'enquiryTypes.bookATourOrVisit',
    color: 'purple',
    dotColor: '#BF2CF3',
  },
  MORE_INFORMATION: {
    value: 'MORE_INFORMATION',
    text: 'enquiryTypes.moreInformation',
    color: 'blue',
    dotColor: '#32A4FC',
  },
  FEE_INFORMATION: {
    value: 'FEE_INFORMATION',
    text: 'enquiryTypes.feeInformation',
    color: 'orange',
    dotColor: '#FB8429',
  },
};

export const ENQUIRY_TYPES = Object.values(ENQUIRY_TYPES_CONST);

export const APP_SETTING_TYPES = [
  {
    value: 'text',
    text: 'appSettingTypes.text',
  },
  {
    value: 'json',
    text: 'appSettingTypes.json',
  },
  {
    value: 'number',
    text: 'appSettingTypes.number',
  },
  {
    value: 'array',
    text: 'appSettingTypes.array',
  },
  {
    value: 'html',
    text: 'appSettingTypes.html',
  },
];

export const APP_SETTING_TYPES_CONSTANT = {
  text: 'text',
  json: 'json',
  number: 'number',
  array: 'array',
  html: 'html',
};
export const USER_TYPES = [
  {
    value: 'client',
    text: 'userTypes.client',
    color: 'pink',
  },
  {
    value: 'business',
    text: 'userTypes.business',
    color: 'blue',
  },
];

export const DOMAIN_TYPE = [
  {
    value: 'internal',
    text: 'domainType.internal',
  },
  {
    value: 'external',
    text: 'domainType.external',
  },
];

export const USER_TYPES_CONSTANT = {
  client: {
    value: 'client',
    color: 'pink',
    text: 'userTypes.client',
  },
  business: {
    value: 'business',
    color: 'blue',
    text: 'userTypes.business',
  },
};

export const FILE_TYPES = [
  { value: 'pdf', icon: FilePdfOutlined, color: theme.color.red },
  { value: 'ppt', icon: FilePptOutlined, color: theme.color.pink },
  { value: 'pptx', icon: FilePptOutlined, color: theme.color.pink },
  { value: 'doc', icon: FileWordOutlined, color: theme.color.blue },
  { value: 'docx', icon: FileWordOutlined, color: theme.color.blue },
  { value: 'xlsx', icon: FileExcelOutlined, color: theme.color.green },
  { value: 'xls', icon: FileExcelOutlined, color: theme.color.green },
  { value: 'csv', icon: FileExcelOutlined, color: theme.color.green },
  { value: 'zip', icon: FileZipOutlined, color: theme.color.violet },
  { value: 'zar', icon: FileZipOutlined, color: theme.color.violet },
  { value: 'txt', icon: FileTextOutlined, color: 'currentColor' },
  { value: 'mov', icon: VideoCameraOutlined, color: 'currentColor' },
  { value: 'mp4', icon: VideoCameraOutlined, color: 'currentColor' },
  { value: 'avi', icon: VideoCameraOutlined, color: 'currentColor' },
  { value: 'flv', icon: VideoCameraOutlined, color: 'currentColor' },
  { value: 'wmv', icon: VideoCameraOutlined, color: 'currentColor' },
  { value: 'mp3', icon: AudioOutlined, color: theme.color.lightGreen },
  { value: 'image', icon: FileImageOutlined, color: theme.color.blueSecondary },
];

export const IMAGE_TYPE = {
  icon: FileImageOutlined,
  color: theme.color.blueSecondary,
};

export const IMAGE_TYPES = ['png', 'jpg', 'jpeg', 'gif', 'tif', 'tiff'];

export const VIDEO_TYPES = ['mov', 'mp4', 'avi', 'flv', 'wmv'];

export const AUDIO_TYPES = ['mp3', 'wav'];

export const MAX_FILE_SIZE_IN_MB = 2;

export const MAX_FILE_DIMENSIONS = 1280;

export const APPLICATION_STATUS = [
  {
    value: 'SUBMITTED',
    text: 'applicationStatus.submitted',
    color: '#ABA7A7',
    backgroundColor: '#EEF2FA',
    accepts: ['IN_PROGRESS'],
  },
  {
    value: 'IN_PROGRESS',
    text: 'applicationStatus.inProgress',
    color: '#36BF57',
    backgroundColor: '#EDF9F0',
    accepts: ['WAIT_LISTED', 'DECLINED'],
  },
  {
    value: 'VISIT_OR_TOUR',
    text: 'applicationStatus.visitOrTour',
    color: '#BF2CF3',
    backgroundColor: '#EDE0FC',
  },
  {
    value: 'WAIT_LISTED',
    text: 'applicationStatus.waitListed',
    color: '#37C5E5',
    backgroundColor: '#E8FBFF',
    accepts: ['ACCEPTED', 'DECLINED'],
  },
  {
    value: 'ACCEPTED',
    text: 'applicationStatus.accepted',
    color: '#DB147F',
    backgroundColor: '#FFF3FC',
    accepts: ['ENROLLED', 'DECLINED'],
  },
  {
    value: 'ENROLLED',
    text: 'applicationStatus.enrolled',
    color: '#316DDE',
    backgroundColor: '#F0F7FF',
  },
  {
    value: 'DECLINED',
    text: 'applicationStatus.declined',
    color: '#BF2CF3',
    backgroundColor: '#EDE1FC',
  },
  {
    value: 'CANCELLED',
    text: 'applicationStatus.cancelled',
    color: '#FF0E0E',
    backgroundColor: '#FFE9E9',
  },
];

export const CALLED_OR_CONTACTED_TYPES = [
  {
    value: true,
    text: 'calledOrContactedTypes.active',
    color: '#52c41a',
  },
  {
    value: false,
    text: 'calledOrContactedTypes.inactive',
    color: '#e64c38',
  },
];

export const MARKETING_TYPES = [
  {
    value: 'FEATURED',
    text: 'marketingTypes.featured',
    color: 'pink',
  },
  {
    value: 'SPECIAL_OFFER',
    text: 'marketingTypes.specialOffer',
    color: 'blue',
  },
];

export const BILLING_PERIODS = [
  {
    value: 'daily',
    text: 'billingPeriods.daily',
    title: 'billingPeriods.day',
    color: 'blue',
  },
  {
    value: 'weekly',
    text: 'billingPeriods.weekly',
    title: 'billingPeriods.week',
    color: 'orange',
  },
  {
    value: 'monthly',
    text: 'billingPeriods.monthly',
    title: 'billingPeriods.month',
    color: 'red',
  },
];

export const NOTIFICATION_STATUS_CONSTANT = {
  draft: {
    value: 'Draft',
    icon: DraftIcon,
    text: 'notificationStatus.draft',
    color: '#ABA7A7',
    backgroundColor: '#EEF2FA',
  },
  sending: {
    value: 'Sending',
    icon: SendOutlined,
    text: 'notificationStatus.sending',
    color: '#316DDE',
    backgroundColor: '#F0F7FF',
  },
  scheduled: {
    value: 'Scheduled',
    icon: CalendarIcon,
    text: 'notificationStatus.scheduled',
    color: '#DB147F',
    backgroundColor: '#FFF3FC',
  },
  complete: {
    value: 'Complete',
    icon: CheckIcon,
    text: 'notificationStatus.complete',
    color: '#36BF57',
    backgroundColor: '#EDF9F0',
  },
};

export const NOTIFICATION_STATUS = Object.values(NOTIFICATION_STATUS_CONSTANT);

export const REASON_ARCHIVE_ENQUIRY = [
  {
    value: 'VISIT_OR_TOUR_BOOKED',
    text: 'reasonArchiveEnquiry.visitOrTourBooked',
  },
  {
    value: 'WAITLISTED',
    text: 'reasonArchiveEnquiry.waitlisted',
  },
  {
    value: 'ENROLLED',
    text: 'reasonArchiveEnquiry.enrolled',
  },
  {
    value: 'NO_AVAILABILITY',
    text: 'reasonArchiveEnquiry.noAvailability',
  },
  {
    value: 'NOT_A_GENUINE_ENQUIRY',
    text: 'reasonArchiveEnquiry.notAGenuineEnquiry',
  },
  {
    value: 'DUPLICATE_ENQUIRY',
    text: 'reasonArchiveEnquiry.duplicateEnquiry',
  },
  {
    value: 'OTHER',
    text: 'reasonArchiveEnquiry.other',
  },
];

export const REASON_TYPES = [
  {
    value: 'Not genuine',
    text: 'disputeReviewRequests.reason.notGenuine',
  },
  {
    value: 'Not related to my Centre',
    text: 'disputeReviewRequests.reason.notRelated',
  },
  {
    value: 'Violates Privacy Policy',
    text: 'disputeReviewRequests.reason.violatesPrivacyPolicy',
  },
  {
    value: 'Other',
    text: 'disputeReviewRequests.reason.other',
  },
];

export const DISPUTE_STATUS_TYPES = [
  {
    value: 'pending',
    text: 'status.pending',
    color: 'orange',
  },
  {
    value: 'approved',
    text: 'status.approved',
    color: 'green',
  },
  {
    value: 'denied',
    text: 'status.denied',
    color: 'red',
  },
];

export const KEYS_DAYS_IN_WEEK = [
  {
    value: 'mon',
    text: 'requiredDays.mon',
  },
  {
    value: 'tue',
    text: 'requiredDays.tue',
  },
  {
    value: 'wed',
    text: 'requiredDays.wed',
  },
  {
    value: 'thu',
    text: 'requiredDays.thu',
  },
  {
    value: 'fri',
    text: 'requiredDays.fri',
  },
  {
    value: 'sat',
    text: 'requiredDays.sat',
  },
  {
    value: 'sun',
    text: 'requiredDays.sun',
  },
];

export const SALES_STAGE_TAG = [
  {
    value: 'PRE-QUALIFICATION',
    text: 'saleStageStatus.preQualification',
    color: '#ABA7A7',
    backgroundColor: '#F7F8F9',
  },
  {
    value: 'QUALIFIED',
    text: 'saleStageStatus.qualified',
    color: '#36BF57',
    backgroundColor: '#EDF9F0',
  },
  {
    value: 'PRESENTATION',
    text: 'saleStageStatus.presentation',
    color: '#37C5E5',
    backgroundColor: '#E8FBFF',
  },
  {
    value: 'ONBOARDED',
    text: 'saleStageStatus.onboarded',
    color: '#316DDE',
    backgroundColor: '#EEF2FA',
  },
  {
    value: 'PILOT',
    text: 'saleStageStatus.pilot',
    color: '#FF5F5F',
    backgroundColor: '#FFF5F5',
  },
  {
    value: 'PROPOSAL',
    text: 'saleStageStatus.proposal',
    color: '#BF2CF3',
    backgroundColor: '#EDE1FC',
  },
  {
    value: 'IMPLEMENTATION',
    text: 'saleStageStatus.implementation',
    color: '#FB8E29',
    backgroundColor: '#FFFEED',
  },
  {
    value: 'SUBSCRIBED',
    text: 'saleStageStatus.subscribed',
    color: '#DB147F',
    backgroundColor: '#FFF3FC',
  },
];

export const ACTIVITY_PROVIDER_TYPES = [
  {
    value: 'incomingCall',
    text: 'types.incomingCall',
    color: '#36BF57',
    IconCPN: IncomingCallCircleShadowIcon,
  },
  {
    value: 'outgoingCall',
    text: 'types.outgoingCall',
    color: '#FB8429',
    IconCPN: OutgoingCallCircleShadowIcon,
  },
  {
    value: 'email',
    text: 'types.email',
    color: '#328FFC',
    IconCPN: EmailCircleShadowIcon,
  },
  {
    value: 'note',
    text: 'types.note',
    color: '#DB147F',
    IconCPN: NoteCircleShadowIcon,
  },
  {
    value: 'other',
    text: 'types.other',
    color: '#FF0303',
    IconCPN: OtherCircleShadowIcon,
  },
];

export const ActivitiesNotesLevel = {
  companyLevel: 'companyLevel',
  providerLevel: 'providerLevel',
  centreLevel: 'centreLevel',
};

export const ACTIVITY_NOTE_LEVEL = [
  {
    value: 'companyLevel',
    text: 'common.companyLevel',
    IconCPN: CompaniesIcon,
  },
  {
    value: 'providerLevel',
    text: 'common.providerLevel',
    IconCPN: ProviderIcon,
  },
  {
    value: 'centreLevel',
    text: 'common.centreLevel',
    IconCPN: CentreIcon,
  },
];
export const STEPS_MODAL_CONSTANT = {
  centreDescription: {
    current: 0,
    title: 'centreDetail.titleCentreDescriptionModal',
  },
  additionalDetail: {
    current: 1,
    title: 'centreDetail.titleCentreAddDetailModal',
  },
  moreCentreInfo: {
    current: 2,
    title: 'centreDetail.titleMoreCentreInfoModal',
  },
  customiseButton: {
    current: 3,
    title: 'centreDetail.titleCustomiseButtonModal',
  },
};
export const STEPS_MODAL = Object.values(STEPS_MODAL_CONSTANT);

export const BLOCK_USER_TYPES = [
  {
    value: true,
    text: 'blockUserTypes.blocked',
    color: theme.color.red,
  },
  {
    value: false,
    text: 'blockUserTypes.unblock',
    color: theme.color.blueSecondary,
  },
];

export const FEATURED_STATUS = [
  {
    value: true,
    text: 'common.yes',
  },
  {
    value: false,
    text: 'common.no',
  },
];

export const STATUS_SPECIAL_OFFER = [
  {
    value: 'ACTIVE',
    text: 'status.active',
    color: theme.color.green,
  },
  {
    value: 'INACTIVE',
    text: 'status.inactive',
    color: theme.color.red,
  },
];

export const LISTING_STRENGTH_CONTENT = [
  {
    step: 1,
    key: null,
    title: 'listingStrength.listStep.step1',
    Component: CentreInformationModal,
  },
  {
    step: 2,
    key: null,
    title: 'listingStrength.listStep.step2',
    Component: CentreDescriptionModal,
  },
  {
    step: 3,
    key: null,
    title: 'listingStrength.listStep.step3',
    Component: MoreCentreInfoModal,
  },
  {
    step: 4,
    key: null,
    title: 'listingStrength.listStep.step4',
    Component: UploadBusinessLogoModal,
  },
  {
    step: 5,
    key: null,
    title: 'listingStrength.listStep.step5',
    Component: CentrePhotoModal,
  },
  {
    step: 6,
    key: null,
    title: 'listingStrength.listStep.step6',
    Component: CentrePhotoModal,
  },
  {
    step: 7,
    key: 'hours',
    title: 'listingStrength.listStep.step7',
    Component: HoursTab,
  },
  {
    step: 8,
    key: 'services',
    title: 'listingStrength.listStep.step8',
    Component: ServicesTab,
  },
  {
    step: 9,
    key: 'features',
    title: 'listingStrength.listStep.step9',
    Component: FeaturesTab,
  },
  {
    step: 10,
    key: 'nqsRating',
    title: 'listingStrength.listStep.step10',
    Component: RatingsReviewsTab,
  },
  {
    step: 11,
    key: 'automatedResponses',
    title: 'listingStrength.listStep.step11',
  },
];

export const CHANNEL_EVENT = [
  {
    value: '',
    text: 'common.all',
  },
  {
    value: 'mobile',
    text: 'events.mobile',
  },
  {
    value: 'rich-web',
    text: 'events.richWeb',
  },
];

export const CLICK_THROUGH_TRACKER_CONST = {
  make_enquiry: {
    value: 'Make Enquiry',
    text: 'clickThroughType.makeEnquiry',
  },
  enquiry_submitted: {
    value: 'Enquiry Submitted',
    text: 'clickThroughType.enquirySubmitted',
  },
  custom_button_apply: {
    value: 'Custom Button - Apply',
    text: 'clickThroughType.customButtonApply',
  },
  apply: {
    value: 'Apply',
    text: 'clickThroughType.apply',
  },
  application_submitted: {
    value: 'Application Submitted',
    text: 'clickThroughType.applicationSubmitted',
  },
  custom_button_book_a_tour: {
    value: 'Custom Button - Book a Tour or Visit',
    text: 'clickThroughType.customButtonBookATourOrVisit',
  },
  phone_call: {
    value: 'Phone call',
    text: 'clickThroughType.phoneCall',
  },
  website: {
    value: 'Website',
    text: 'clickThroughType.website',
  },
  social_facebook: {
    value: 'Social - Facebook',
    text: 'clickThroughType.socialFacebook',
  },
  social_instagram: {
    value: 'Social - Instagram',
    text: 'clickThroughType.socialInstagram',
  },
  social_linked_in: {
    value: 'Social - Linkedin',
    text: 'clickThroughType.socialLinkedIn',
  },
  social_tiktok: {
    value: 'Social - TikTok',
    text: 'clickThroughType.socialTikTok',
  },
  social_twitter: {
    value: 'Social - Twitter',
    text: 'clickThroughType.socialTwitter',
  },
};

export const CLICK_THROUGH_TRACKER_TYPES = Object.values(
  CLICK_THROUGH_TRACKER_CONST,
);
