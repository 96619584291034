import React, { useState } from 'react';
import i18next from 'i18next';
import { PropTypes } from 'prop-types';
import { Row, Col } from 'antd';

import CardSection from 'components/common/CardSection';
import ReadMore from 'components/common/ReadMore';
import ProviderInfoModal from '../Modals/ProviderInfoModal';
import { ImageInfoStyles } from './styles';
import ProviderImageInfo from '../ProviderGeneral/ProviderImageInfo';

const ProviderInfo = ({ record }) => {
  const [visible, setVisible] = useState(false);

  const onOpen = () => {
    setVisible(true);
  };

  const onCancel = () => {
    setVisible(false);
  };

  return (
    <ImageInfoStyles className="text-16">
      <CardSection onClickButton={onOpen} isEdit title="providers.providerInfo">
        <Row className="mb-32">
          <Col xxl={4} xl={6} sm={8}>
            {i18next.t('providers.providerDescription')}
          </Col>
          <Col xxl={20} xl={18} sm={16}>
            <ReadMore
              text={record?.description || i18next.t('error.waitingUpdate')}
              readMoreCharacterLimit={500}
            />
          </Col>
        </Row>
        <Row className="mb-32">
          <Col xxl={4} xl={6} sm={8}>
            {i18next.t('providers.numberOfBrand')}
          </Col>
          <Col xxl={20} xl={18} sm={16}>
            {record?.brands?.length || 0}
          </Col>
        </Row>
        <Row gutter={[16, 32]}>
          {record?.brands?.map((item, index) => (
            <Col key={String(index)}>
              <ProviderImageInfo logo={item?.logo} size={70} />
            </Col>
          ))}
        </Row>
      </CardSection>
      <ProviderInfoModal
        visible={visible}
        onCancel={onCancel}
        description={record?.description}
      />
    </ImageInfoStyles>
  );
};

ProviderInfo.propTypes = {
  record: PropTypes.object,
};

export default ProviderInfo;
