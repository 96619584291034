import { Button } from 'antd';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_CREATED_AT,
  LIMIT_ITEM,
} from 'configs/localData/constant';
import i18next from 'i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { resetResourceFilterCentresOfCompany } from 'redux/centresOfCompany/slice';
import { resetResourceFilterProvidersOfCompany } from 'redux/providersOfCompany/slice';
import { getUsersOfCompany } from 'redux/usersOfCompany/actions';
import { getFilterFromUrl, getSearchUrl } from 'utils/tools';
import CreateModal from './CreateModal';
import FilterUsers from './Filter';
import UsersListTable from './UsersListTable';

const UserTab = () => {
  const dispatch = useDispatch();
  const { location, push } = useHistory();

  const { id: companyId } = useParams();
  const [visible, setVisible] = useState(false);

  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);
  const filterRef = useRef(filter);

  const openCreateModal = () => {
    setVisible(true);
  };

  const onCancel = () => {
    setVisible(false);
    dispatch(resetResourceFilterCentresOfCompany());
    dispatch(resetResourceFilterProvidersOfCompany());
  };

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, keepFilter }) => {
      dispatch(
        getUsersOfCompany({
          data: {
            companyId,
            ...filter,
          },
          options: {
            keepFilter,
            isRefresh,
          },
        }),
      );
    },
    [companyId, dispatch],
  );

  useEffect(() => {
    retrieveList({
      filter: {
        sorts: filterRef.current.sorts || [DEFAULT_SORT_CREATED_AT],
        page: filterRef.current.page || DEFAULT_CURRENT_PAGE,
        size: filterRef.current.size || LIMIT_ITEM,
        filter: filterRef.current.filter,
      },
      isRefresh: true,
      keepFilter: true,
    });
  }, [retrieveList]);

  const pushQuery = useCallback(
    (searchStr) => {
      push(`${location.pathname}?${getSearchUrl(searchStr)}`);
    },
    [location.pathname, push],
  );

  return (
    <div>
      <FilterUsers retrieveList={retrieveList} pushQuery={pushQuery} />
      <div className="d-flex justify-end mb-3">
        <Button type="primary" onClick={openCreateModal}>
          {i18next.t('button.create')}
        </Button>
      </div>
      <UsersListTable retrieveList={retrieveList} pushQuery={pushQuery} />
      <CreateModal
        retrieveList={retrieveList}
        onCancel={onCancel}
        visible={visible}
      />
    </div>
  );
};

export default UserTab;
