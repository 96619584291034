import styled, { createGlobalStyle } from 'styled-components';
import { CLASS_UTILITY } from 'utils/css';

const AppWrapper = styled.div`
  .gradientBackground {
    background-image: ${({ theme }) =>
      `linear-gradient(90deg, ${theme.palette.lightPrimary}, ${theme.palette.primary})`};
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${CLASS_UTILITY}
  .note {
    font-size: 12px;
    color: ${({ theme }) => theme.text.note};
  }
  .black {
    color: ${({ theme }) => theme.text.primary};
  }
  & > * {
    font-family: 'Open Sans', sans-serif;
  }
  .anticon:before {
    display: block;
    font-family: 'anticon', 'kindicare' !important;
  }
  .anticon:after {
    display: block;
    font-family: 'anticon', 'kindicare' !important;
  }
  .text-primary {
    color: ${({ theme }) => theme.palette.primary};
  }
  .text-gray {
    color: ${({ theme }) => theme.text.primary};
  }
  .text-light-gray {
    color: ${({ theme }) => theme.text.mainL3};
  }
  .text-blue-secondary {
    color: ${({ theme }) => theme.color.blueSecondary};
  }
  .text-headerTable {
    color: ${({ theme }) => theme.palette.headerTable};
  }
  .bg-primary {
    background: ${({ theme }) => theme.background.primary};
  }
  .bg-warning {
    background: ${({ theme }) => theme.background.warning};
  }
  .bg-error {
    background: ${({ theme }) => theme.background.error};
  }
  .t-14px-1\\.57 {
    font: normal normal 14px/1.57 ${({ theme }) => theme.fonts.primary};
  }
  .t-500-14px-1\\.57 {
    font: normal 500 14px/1.57 ${({ theme }) => theme.fonts.primary};
  }
  .t-500-16px-1\\.5 {
    font: normal 500 16px/1.5 ${({ theme }) => theme.fonts.header};
  }
  .t-500-24px-1\\.17 {
    font: normal 500 16px/1.17 ${({ theme }) => theme.fonts.header};
  }
  .ml-8px {
    margin-left: 8px;
  }

  /* ------------------ Override antd---------------- */
  .ant-tag-blue {
    color: #32A4FC;
    background: #E9F4FF;
    border-color: #91d5ff;
  }
  .ant-tag-purple {
    color: #BF2CF3;
    background: #F3EAFF;
    border-color: #d3adf7;
  }
  .ant-tag-green {
    color: #36BF57;
    background: #EDF9F0;
    border-color: #b7eb8f;
  } 
  .ant-tag-orange {
    color: #FB8429;
    background: #FFF4EC;
    border-color: #ffd591;
  }
  .ant-progress-inner {
    background-color: #E9ECF7 ;
  }
  .ant-pagination .ant-pagination-item-active a {
    color: #ffffff;
  }
  .ant-form-item-required::before {
    content: none !important;
  } 

  .ant-form-item-required::after {
    display: inline-block !important;
    ${'' /* display: table-cell; */}
    margin-left: 4px !important;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*' !important;
  }

  ${
    '' /* .ant-select-item-option-disabled[aria-selected=false] {
    display: none;
  } */
  }
  .ant-pagination-disabled:hover a, .ant-pagination-disabled a {
    color: rgba(0,0,0,.4);
    border-color: rgba(212,210,244,.31);
    cursor: not-allowed;
  }
  .ant-pagination-prev .ant-pagination-item-link > .anticon, .ant-pagination-next .ant-pagination-item-link > .anticon {
    display: block;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
    content: none !important;
  }

  /* css selection */
  .loading-select {
    text-align: center;
    margin: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .loading-select-option {
    min-height: 0!important;
    padding: 0!important;
  }
  .ant-carousel .slick-dots li button {
    background: ${({ theme }) => theme.palette.primary};
  }
  .ant-carousel .slick-dots li.slick-active button {
    background: ${({ theme }) => theme.palette.primary};
  }

  .ant-empty-image > svg {
    max-width: 100%
  }

  .empty-after-label {
    .ant-form-item-label label:after {
      content: '';
        display: inline-block;
    }
  }

  .btn-line-primary {
    border-color: ${({ theme }) => theme.palette.primary};
    color: ${({ theme }) => theme.palette.primary};
  }
  
  .btn-second-primary {
    background: ${({ theme }) => theme.color.blueSecondary};
    border: none;
    color: #fff;
    &:hover, &:focus {
      background: ${({ theme }) => `${theme.color.blueSecondary}90`};
      color: #fff;
    }
  }

  .btn-second-primary-fill {
    background: ${({ theme }) => `${theme.color.blueSecondary}20`};
    border-color: transparent;
    color: ${({ theme }) => theme.color.blueSecondary};
    &:hover, &:focus {
      border-color: ${({ theme }) => theme.palette.primary};
    }
  }

  .btn-primary-fill {
    background: ${({ theme }) => `${theme.palette.primary}20`};
    border-color: transparent;
    color: ${({ theme }) => theme.palette.primary};
    &:hover, &:focus {
      border-color: ${({ theme }) => theme.palette.primary};
    }
  }

  /*Modal */
  .ant-modal-content {
    border-radius: ${({ theme }) => theme.borderRadius.modal};
  }
  .ant-modal-header {
    border-radius: ${({ theme }) =>
      `${theme.borderRadius.modal} ${theme.borderRadius.modal} 0 0`};
  }
  /* Form */
  .ant-form-item-explain, .ant-form-item-extra {
    margin: 0 !important;
  }
  .ant-checkbox-inner {
    border-radius: ${({ theme }) => theme.borderRadius.checkbox};
  }
  .ant-notification-notice-description {
    word-break: break-word;
  }

  .box-wrapper {
    padding: 20px;
    border-radius: 8px;
    background: ${({ theme }) => theme.background.content};
  }

  .box-wrapper-large {
    padding: 20px;
    border-radius: 16px;
    background: ${({ theme }) => theme.background.content};
  };
  
  .text-secondary {
    color: ${({ theme }) => theme.text.mainL2};
  }

  .form-custom-label .ant-form-item-label {
    > label{
      width: 100px !important;
      margin-right: 28px;
    }
    font-weight: bold;
    label:after {
      content: '';
      display: inline-block;
    }
  }

  .error-color {
    color: red;
  }

  .wrapper-relative {
    flex-grow: 1;
    position: relative;
  }

  .wrapper-absolute {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
  }

  .btn-info-fill {
    background: ${({ theme }) => `${theme.color.info}20`};
    border-color: transparent;
    color: ${({ theme }) => theme.color.info};
  }
  
  .link-custom-primary {
    color: ${({ theme }) => theme.text.primary} !important;
    &:hover,
    &:focus {
      color: ${({ theme }) => theme.palette.primary} !important;
    }
  }

  .title-table {
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
  }

  .flex {
    display: flex;
  }
  .flex-end {
    justify-content: end;
  }
  .mb-3	{
    margin-bottom: 0.75rem;
  }
  .space-between {
    justify-content: space-between;
  }
  .warning-color{
    color: #ff4d4f;
  }
  .warning-text {
    margin-top: -18px;
    margin-bottom: 0px;
  }

  .success-modal {
    .ant-modal-body {
      padding: 0px;
    }
    .ant-card {
      border: none;
      width: 100% !important;
    }
    .ant-card-body {
      padding: 32px !important;
    }
  
    .ant-card-cover {
      background-color: #F9EAF7;
    }
    .ant-card-hoverable:hover {
      box-shadow: none;
    }
    .start-icon {
      margin-left: 8px;
      margin-right: 8px;
    }
    .ant-card-meta-title {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: #2D1F21;
    }
    h3 {
      margin-top: 12px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
      color: #2D1F21;
    }
    .btnOk {
      font-weight: bold;
      font-size: 16px;
    }
    .ant-card-bordered .ant-card-cover {
      margin-top: 0px;
      margin-right: 0px;
      margin-left: 0px;
      border-radius: 10px 10px 0 0  ;
    }
  }
  .title-session {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 15px;
    margin-top: 5px;
  }
  .modal-f-width-btns {
    .ant-modal-confirm-btns {
      float: none;
    }
  }
`;

export default AppWrapper;
