import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentDataAutomatedResponse } from 'redux/automatedResponses/slice';
import { updateAutomatedResponses } from 'redux/automatedResponses/actions';
import { getByIdCentres } from 'redux/centres/actions';

const AutomatedItem = ({ item, centreId }) => {
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();
  const automatedKey = useSelector(
    (state) => state.automatedResponses.currentData.key,
  );

  const onChange = async (checked, e) => {
    e.stopPropagation();

    if (centreId && item.id && item.key) {
      setLoading(true);
      await dispatch(
        updateAutomatedResponses({
          centreId,
          id: item.id,
          key: item.key,
          data: { isActive: checked },
        }),
      );
      setLoading(false);
      dispatch(
        getByIdCentres({
          data: { id: centreId },
          options: { isRequestApi: true },
        }),
      );
    }
  };

  const onSelectedItem = () => {
    if (item.key && automatedKey !== item.id) {
      dispatch(setCurrentDataAutomatedResponse(item));
    }
  };

  return (
    <div
      className={`automated-item flex-center-between w-full ${
        automatedKey === item.key ? 'automated-item-selected' : ''
      }`}
      onClick={onSelectedItem}
      role="presentation"
    >
      <span className="automated-item__name">{item.name || item.key}</span>
      <Switch checked={item.isActive} onChange={onChange} loading={loading} />
    </div>
  );
};

AutomatedItem.propTypes = {
  item: PropTypes.object,
  centreId: PropTypes.string,
};

export default AutomatedItem;
