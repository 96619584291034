import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createUserApi,
  delCentresOfUserApi,
  editRoleUserApi,
  getAuthorizeWebPortalApi,
  getCentresOfUserApi,
  postCentresOfUserApi,
  checkLastUserApi,
  unSubcriptionCentreApi,
  checkLoginGodModeApi,
} from 'api/user';
import { PREFIX_URL_API } from 'configs/localData/constant';
import { makeActions } from 'redux/crudCreator/actions';
import { convertRequestParams } from 'redux/crudCreator/dataProvider';
import { apiWrapper } from 'utils/reduxUtils';

export const MODEL_NAME = 'users';
export const usersActions = makeActions(MODEL_NAME);

export const getAllUsers = usersActions.getAll;
export const editUsers = usersActions.edit;
export const createUsers = usersActions.create;
export const getByIdUsers = usersActions.getDataById;
export const clearCurrentUser = usersActions.clearCurrent;
export const delUsers = usersActions.del;

export const getCentresOfUser = createAsyncThunk(
  'users/getCentresOfUser',
  async (payload = {}, thunkApi) => {
    try {
      const { id, data = {}, options = {} } = payload;

      const { limit, offset, filter } =
        thunkApi.getState().users.centres?.resourceData || {};

      const convertRequest = convertRequestParams('GET_ALL', {
        limit,
        offset,
        filter,
        ...data,
      });

      const response = await apiWrapper(
        {},
        getCentresOfUserApi,
        id,
        convertRequest,
      );

      return {
        data: {
          numberOfPages: Math.ceil(response.total / limit),
          ...response,
        },
        options,
      };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const postCentresOfUser = createAsyncThunk(
  'users/postCentresOfUser',
  async (payload = {}, thunkApi) => {
    try {
      const { id, data = {}, options } = payload;

      const response = await apiWrapper({}, postCentresOfUserApi, id, data);

      thunkApi.dispatch(getCentresOfUser({ id, options: { isRefresh: true } }));

      if (options.isGetAllUsersList) {
        thunkApi.dispatch(
          getAllUsers({
            options: { isRefresh: true, prefixUrl: PREFIX_URL_API },
          }),
        );
      }
      return { data: response, options };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const delCentresOfUser = createAsyncThunk(
  'users/delCentresOfUser',
  async (payload = {}, thunkApi) => {
    try {
      const { id, centreId, options = {} } = payload;

      await apiWrapper({}, delCentresOfUserApi, id, centreId);
      if (options.isGetAllUsersList) {
        thunkApi.dispatch(
          getAllUsers({
            options: { isRefresh: true, prefixUrl: PREFIX_URL_API },
          }),
        );
      }
      return { centreId, id, options };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const createUser = createAsyncThunk(
  'users/createUser',
  async (payload = {}, thunkApi) => {
    try {
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        createUserApi,
        payload,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getAuthorizeWebPortal = createAsyncThunk(
  'auth/getPermissions',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        null,
        getAuthorizeWebPortalApi,
        payload,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const editRoleUser = createAsyncThunk(
  'users/editRoleUser',
  async (payload = {}, thunkApi) => {
    try {
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        editRoleUserApi,
        payload.id,
        payload.data,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const checkLastUser = createAsyncThunk(
  'auth/checkLastUser',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(null, checkLastUserApi, payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const unSubcriptionCentre = createAsyncThunk(
  'auth/unSubcriptionCentreApi',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(null, unSubcriptionCentreApi, payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const checkLoginGodMode = createAsyncThunk(
  'auth/checkLoginGodModeApi',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(null, checkLoginGodModeApi, payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
