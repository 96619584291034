import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import { getFilterFromUrl } from 'utils/tools';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import i18next from 'i18next';

import { ENQUIRY_TYPES_CONST } from 'configs/localData';

import { RestInputContext } from 'components/RestInput/RestInputContext';
import RestSelect from 'components/RestInput/RestSelect';
import RestRangePickerInput from 'components/RestInput/RestRangePickerInput';

const FormFilter = () => {
  const { form } = useContext(RestInputContext);
  const { search } = useLocation();
  const enquiryTypes = useSelector((state) => state.config.data?.EnquiryType);

  useEffect(() => {
    const filter = getFilterFromUrl(search) || {};

    const { updatedAt, ...restFilter } = filter?.filter || {};

    form.setFieldsValue({
      ...restFilter,
      updatedAt: [
        updatedAt?.$gt ? moment(updatedAt?.$gt) : null,
        updatedAt?.$lt ? moment(updatedAt?.$lt) : null,
      ],
    });
  }, []); // eslint-disable-line

  return (
    <div>
      <Row gutter={16}>
        <Col lg={12} md={12} xs={12}>
          <RestSelect
            source="enquiryTypeId"
            placeholder="inboxes.enquiryType"
            resourceData={enquiryTypes}
            valueProp="id"
            titleProp="name"
            isShowTooltip
            formatText={(value) =>
              ENQUIRY_TYPES_CONST[value]?.text
                ? i18next.t(ENQUIRY_TYPES_CONST[value]?.text)
                : value
            }
          />
        </Col>
        <Col lg={12} md={12} xs={12}>
          <RestRangePickerInput
            sourceGt={['updatedAt', '$gt']}
            sourceLt={['updatedAt', '$lt']}
            source="updatedAt"
            placeholder="common.updatedAt"
            isShowTooltip
          />
        </Col>
      </Row>
    </div>
  );
};

export default FormFilter;
