import React from 'react';
import PropTypes from 'prop-types';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { isEmpty } from 'lodash-es';
import EmptyData from 'components/common/EmptyData';
import LegendChart from 'components/charts/LegendChart';
import TooltipChart from 'components/charts/TooltipChart';
import { formatMoney } from 'utils/textUtils';

const LineChartCustom = ({
  data,
  width,
  height,
  heightXAxis,
  formatValueTooltip,
  dataLines,
  YAxisOptions,
  lineOptions,
  isShowLegend,
  legendTypeTooltip,
}) =>
  isEmpty(data) ? (
    <EmptyData />
  ) : (
    <ResponsiveContainer width={width} height={height}>
      <LineChart
        data={data}
        margin={{
          top: 30,
          right: 20,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid
          vertical={false}
          strokeOpacity={0.5}
          strokeDasharray="3 3"
        />
        <XAxis dataKey="name" height={heightXAxis} tickMargin={16} />
        <YAxis tickFormatter={(data) => formatMoney(data)} {...YAxisOptions} />
        <Tooltip
          wrapperStyle={{ pointerEvents: 'auto' }}
          formatValue={formatValueTooltip}
          content={<TooltipChart legendTypeTooltip={legendTypeTooltip} />}
        />
        {isShowLegend && (
          <Legend
            verticalAlign="top"
            wrapperStyle={{ top: 0, marginBottom: 20 }}
            content={<LegendChart />}
          />
        )}

        {dataLines?.map((item) => (
          <Line
            key={item.value}
            strokeWidth={2}
            type="monotone"
            dataKey={item.value}
            stroke={item.fillColor}
            dot={false}
            activeDot={{ r: 6 }}
            {...lineOptions}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );

LineChartCustom.propTypes = {
  data: PropTypes.array,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  colors: PropTypes.array,
  dataLines: PropTypes.array,
  heightXAxis: PropTypes.number,
  formatValueTooltip: PropTypes.func,
  lineOptions: PropTypes.object,
  YAxisOptions: PropTypes.object,
  isShowLegend: PropTypes.bool,
  legendTypeTooltip: PropTypes.oneOf(['circle', 'rectangle', 'line']),
};

LineChartCustom.defaultProps = {
  height: 400,
  width: '100%',
  colors: [],
  heightXAxis: 60,
  YAxisOptions: {},
  isShowLegend: true,
};

export default LineChartCustom;
