import styled from 'styled-components';

const KindiRatingStyles = styled.div`
  font-size: 16px;
  color: #ffffff;
  padding: 2px 5px;
  background: ${({ theme }) => theme.palette.primary};
  border-radius: 8px;
  display: inline-flex;
`;

export default KindiRatingStyles;
