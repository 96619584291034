import React, { useEffect, useMemo, useRef, useState } from 'react';
import i18next from 'i18next';
import { Button, Divider } from 'antd';
import { FullscreenOutlined } from '@ant-design/icons';
import { round, template as temp } from 'lodash-es';
import EmptyData from 'components/common/EmptyData';
import { useSelector, useDispatch } from 'react-redux';
import { getOptionEmailTemplate } from 'redux/EDM/actions';
import { useEmailTemplate } from '../../context';
import EmailPreviewModal from './EmailPreviewModal';

const EmailPreview = () => {
  const dispatch = useDispatch();

  const templateModalRef = useRef();
  const contentRef = useRef();

  const { template, centreId } = useEmailTemplate();

  const [zoom, setZoom] = useState('unset');

  const optionTemplate = useSelector((state) => state.EDM.optionTemplate) || {};

  useEffect(() => {
    if (contentRef.current && contentRef.current.offsetWidth < 600) {
      setZoom(round(contentRef.current.offsetWidth / 600, 2));
    }
  }, [contentRef.current]); // eslint-disable-line

  useEffect(() => {
    centreId &&
      template.key &&
      dispatch(getOptionEmailTemplate({ centreId, key: template.key }));
  }, [centreId, template.key]); // eslint-disable-line

  const contentTemplate = useMemo(() => {
    try {
      if (template?.value && optionTemplate?.centre?.id) {
        return temp(template?.value)(optionTemplate);
      }

      return template?.previewContent;
    } catch (error) {
      return template?.previewContent;
    }
  }, [template.id, optionTemplate]); // eslint-disable-line

  const handleZoom = () => {
    templateModalRef.current.open(contentTemplate);
  };

  return (
    <div className="email-preview">
      <div ref={contentRef}>
        <div className="email-preview__header flex-center-between">
          <div className="email-preview__title">
            {i18next.t('EDM.emailPreview')}
          </div>

          <Button
            className="email-preview__zoom-btn"
            icon={<FullscreenOutlined />}
            onClick={handleZoom}
          />
        </div>

        <Divider />

        {contentTemplate ? (
          <div
            className="email-preview__content"
            style={{ zoom }}
            dangerouslySetInnerHTML={{ __html: contentTemplate }}
          />
        ) : (
          <EmptyData />
        )}
      </div>

      <EmailPreviewModal ref={templateModalRef} />
    </div>
  );
};

export default EmailPreview;
