import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Row, Col } from 'antd';
import { isEmpty, chunk, times, remove } from 'lodash-es';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { editCentres } from 'redux/centres/actions';
import EmptyData from 'components/common/EmptyData';
import i18next from 'i18next';
import { PREFIX_URL_API } from 'configs/localData/constant';
import FeatureItem from './FeatureItem';
import FeaturesTabStyles from './styles';

const FeaturesTab = () => {
  const { id } = useParams();

  const record = useSelector((state) => state.centres.currentData);

  const [featureIds, setFeatureIds] = useState(record?.featureIds || []);

  useEffect(() => {
    if (record?.featureIds?.length > 0) {
      setFeatureIds(record?.featureIds);
    }
  }, [record.id]); // eslint-disable-line

  const dispatch = useDispatch();

  useEffect(() => {
    if (record?.featureIds?.length > 0) {
      setFeatureIds(record?.featureIds);
    }
  }, [record.id]); // eslint-disable-line

  const updateFeatures = useCallback((featureIds) => {
    dispatch(
      editCentres({
        data: { id, featureIds },
        options: {
          prefixUrl: PREFIX_URL_API,
          isShowSuccessNoti: false,
          isCheckError: false,
        },
      }),
    );
  }, []); // eslint-disable-line

  const debounceUpdateFeatures = useCallback(
    (value) =>
      setTimeout(() => {
        updateFeatures(value);
      }, 500),
    [updateFeatures],
  );

  const featuresConfig = useSelector(
    (state) => state.config?.data?.FeatureCentre,
  );

  const formatFeatures = useMemo(
    () => chunk(featuresConfig, Math.ceil(featuresConfig?.length / 2)),
    [featuresConfig],
  );

  const handleAddFeature = (id) => {
    const newFeature = [...featureIds, id];
    setFeatureIds(newFeature);
    debounceUpdateFeatures(newFeature);
  };

  const handleRemoveFeature = (id) => {
    const newFeature = remove([...featureIds], (i) => i !== id);
    setFeatureIds(newFeature);
    debounceUpdateFeatures(newFeature);
  };

  return (
    <FeaturesTabStyles>
      <div className="title-feature-tab">
        {i18next.t('centreDetail.tabs.features')}
      </div>
      <Row gutter={[24, 24]} className="row-features">
        {times(2).map((idx) => (
          <Col key={String(idx)} md={12} xs={24}>
            <div className="wrapper-feature-col">
              <div className="header-title-table">
                <span className="label-table">
                  {i18next.t('centreDetail.featureName')}
                </span>
                <span className="label-table">
                  {i18next.t('centreDetail.status')}
                </span>
              </div>
              <Row gutter={[24, 24]} className="row-feature-item">
                {isEmpty(formatFeatures?.[idx]) ? (
                  <EmptyData />
                ) : (
                  formatFeatures[idx].map((feature, index) => (
                    <Col span={24} key={String(index)} className="col-feature">
                      <FeatureItem
                        featureIds={featureIds}
                        idCentre={id}
                        checked={featureIds.includes(feature.id)}
                        item={feature}
                        onAdd={handleAddFeature}
                        onRemove={handleRemoveFeature}
                      />
                    </Col>
                  ))
                )}
              </Row>
            </div>
          </Col>
        ))}
      </Row>
    </FeaturesTabStyles>
  );
};

export default FeaturesTab;
