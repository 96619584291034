import styled from 'styled-components';

export const ImageInfoStyles = styled.div`
  .image-brand {
    object-fit: scale-down;
    width: 100px;
    height: 50px;
    border-radius: 8px;
  }
`;
