import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalWrapper = styled(Modal)`
  min-height: 30%;
  max-width: calc(100vw - 16px);

  .ant-modal-header {
    border: none;
    padding-right: 50px !important;

    height: 70px;
    display: flex;
    align-items: center;
  }

  &.reset-padding-top-body .ant-modal-body {
    padding-top: 0;
  }

  &.reset-padding-bottom-body .ant-modal-body {
    padding-bottom: 0;
  }

  &.modal-scroll-y .ant-modal-body {
    max-height: 70vh;
    overflow-y: auto;
  }

  &.form-vertical-style .ant-form-item {
    flex-direction: column;
    .ant-form-item-label {
      text-align: left;
    }
  }

  .ant-modal-title,
  .ant-modal-close,
  .ant-modal-close-icon,
  .ant-modal-close-x {
    color: ${({ theme }) => theme.palette.primary};
    font-weight: bold;
    font-size: 22px;
  }

  .multi-upload-wrapper .ant-form-item:not(:first-child) {
    display: none;
  }
  .multi-upload-wrapper .ant-form-item:first-child {
    margin-bottom: 10px;
  }

  .ant-select-selection__clear {
    background-color: transparent;
    color: white;
    border-radius: 5px;
  }
  .ant-select-arrow-icon {
    background-color: transparent;
  }
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .ant-modal-footer {
    border: none;
    padding: 24px;
    button:not(:first-child) {
      margin-left: 15px !important;
    }
    button[disabled] {
      background: ${({ theme }) => theme.background.disabledBtn};
      color: #fff;
    }
    button {
      min-width: 150px;
    }
  }
  .ant-calendar-picker,
  .ant-select {
    width: 100%;
  }

  .ant-tabs-bar {
    font-weight: 500;
  }
  .ant-tabs-nav {
    .ant-tabs-tab {
      padding-bottom: 5px;
    }
  }
  .ant-tabs-tab {
    color: ${({ theme }) => theme.text.tabTitle};
  }
  .ant-list {
    margin-top: 20px;
    overflow: auto;
    max-height: 460px;
  }
  div::-webkit-scrollbar-thumb {
    border-radius: 3px !important;
    background: ${({ theme }) => theme.scrollbar.thumb} !important;
  }
  div::-webkit-scrollbar-track {
    position: absolute;
    border-radius: 3px !important;
    background: ${({ theme }) => theme.scrollbar.track} !important;
  }
  div::-webkit-scrollbar-thumb:hover {
    border-radius: 3px !important;
    background: ${({ theme }) => theme.scrollbar.thumb} !important;
  }
  div::-webkit-scrollbar {
    width: 6px;
    border-radius: 3px !important;
    background: ${({ theme }) => theme.scrollbar.thumb} !important;
  }
  .ant-list-split .ant-list-item {
    border-bottom: none;
    padding: 1px 0px;
  }
  .ant-list-empty-text {
    color: ${({ theme }) => theme.text.empty};
  }
  .modalTitleContent {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: flex;
    .modalBtnBack {
      font-size: 18px;
      margin: 0px 24px;
      color: ${({ theme }) => theme.drawer.closeIcon};
    }
    .modalTitle {
      flex: 1;
      padding-left: 24px;
      font-size: 18px;
      color: ${({ theme }) => theme.drawer.headerTitle};
    }
  }

  .ant-form-item-control {
    line-height: 2;
  }
  .txtTitle {
    font-size: 12px;
  }
  .ant-form-item-label {
    line-height: 1.5em;
    padding-bottom: 5px;
  }
  .ant-input-number {
    width: 100%;
  }
  .txtTitleForm {
    color: ${({ theme }) => theme.text.formLabel};
    font-size: 12px;
  }
  .ant-form-item .ant-form-explain {
    margin-top: 2px;
  }

  .ant-form-item-control-input {
    min-height: unset !important;
  }

  .form-item-empty-label .ant-form-item-label > label:after {
    content: none;
  }
  .title-header-item {
    font-size: 16px;
    color: #32a4fc;
  }
`;
