import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import CardSection from 'components/common/CardSection';
import NoteDetailBody from './NoteDetailBody';
import CompanyEditModal from './components/CompanyLevel/EditModal';
import ProviderEditModal from './components/ProviderLevel/EditModal';

const NoteDetail = ({ isProviderName }) => {
  const editModalRef = useRef();

  const activity = useSelector((state) => state.activities.currentData);

  const openEditModal = () => {
    editModalRef.current && editModalRef.current.open(activity);
  };

  return (
    <CardSection
      title="activitiesAndNotes.noteDetails"
      isEdit={!!activity?.id}
      onClickButton={openEditModal}
    >
      <NoteDetailBody record={activity} isProviderName={isProviderName} />
      {isProviderName ? (
        <ProviderEditModal ref={editModalRef} />
      ) : (
        <CompanyEditModal ref={editModalRef} />
      )}
    </CardSection>
  );
};

NoteDetail.propTypes = {
  isProviderName: PropTypes.bool,
};

export default NoteDetail;
