import React from 'react';
import i18next from 'i18next';
import Input from 'antd/lib/input';
import FormItem from 'antd/lib/form/FormItem';

import { ACTIVITY_PROVIDER_TYPES } from 'configs/localData';

import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import AddAssignee from 'containers/Providers/components/ActivitiesNotesTab/AddAssignee';
import { PropTypes } from 'prop-types';
import { DatePicker } from 'antd';
import ReferenceInput from 'containers/rest/ReferenceInput';
import { PREFIX_URL } from 'configs/localData/constant';
import { useParams } from 'react-router';
import ActivitiesUploadForm from './ActivitiesUploadForm';
import { ActivityFormStyles } from './styles';

const FormActivity = ({ record, isProvider }) => {
  const { id } = useParams();

  return (
    <ActivityFormStyles className="form-custom-label">
      {isProvider && (
        <ReferenceInput
          prefixUrl={PREFIX_URL.superAdmin}
          source="providerId"
          resource={`companies/${id}/providers`}
          searchKey="name"
          initialFilter={{ orderBy: 'name' }}
        >
          <RestSelect
            required
            header="centres.providerName"
            placeholder="activitiesAndNotes.selectProvider"
            titleProp="name"
            valueProp="id"
            isFilterOption={false}
            isShowSearch={false}
          />
        </ReferenceInput>
      )}
      <RestSelect
        required
        header="activitiesAndNotes.activityType"
        placeholder="activitiesAndNotes.activityType"
        source="type"
        resourceData={ACTIVITY_PROVIDER_TYPES}
        valueProp="value"
        titleProp="text"
        formatText={(text, item) => (
          <span style={{ color: item.color }}>{i18next.t(text)}</span>
        )}
        isShowSearch={false}
      />
      <RestInputItem
        required
        maxLength={255}
        isValidatorEmpty
        header="common.subject"
        placeholder="common.subject"
        source="subject"
      />
      <RestInputItem
        required
        isValidatorEmpty
        header="common.description"
        placeholder="common.description"
        source="description"
        ContentComponent={Input.TextArea}
        rows={4}
      />
      <FormItem
        name="assigneeUserId"
        label={i18next.t('tasks.assignedTo')}
        rules={[
          {
            required: true,
            message: i18next.t('error.required'),
          },
        ]}
      >
        <AddAssignee />
      </FormItem>

      <FormItem name="dueDate" label={i18next.t('tasks.activitiesDay')}>
        <DatePicker
          className="viewDatePicker w-full"
          showTime={{ format: 'HH:mm' }}
          format="YYYY-MM-DD HH:mm"
          allowClear={false}
        />
      </FormItem>
      <ActivitiesUploadForm record={record} source="attachments" />
    </ActivityFormStyles>
  );
};

FormActivity.propTypes = {
  record: PropTypes.object,
  isProvider: PropTypes.bool,
};

export default FormActivity;
