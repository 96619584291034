import React from 'react';
import RestInputItem from 'components/RestInput/RestInputItem';

const ClientUsersForm = (props) => (
  <div {...props}>
    <RestInputItem source="firstName" header="clientUsers.firstName" />
    <RestInputItem source="phoneNumber" header="clientUsers.phoneNumber" />
    <RestInputItem source="lastActiveAt" header="clientUsers.lastActiveAt" />
    <RestInputItem source="createdAt" header="clientUsers.createdAt" />
    <RestInputItem source="isBlocked" header="clientUsers.isBlocked" />
  </div>
);

export default ClientUsersForm;
