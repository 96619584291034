import React, { useEffect } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEventStatisticChart,
  getEventStatisticInfo,
} from 'redux/statistic/actions';
import { setEventFilterStatistic } from 'redux/statistic/slice';

import { DEFAULT_DATE_FILTER_STATISTIC } from 'configs/localData/constant';

import PageTitle from 'components/common/PageTitle';
import Filter from './Filter';
import ChartByTime from './ChartByTime';
import TableEvents from './TableEvents';
import EventsStyles from './styles';

export const eventTime = {
  $gt: DEFAULT_DATE_FILTER_STATISTIC[0].toISOString(),
  $lt: DEFAULT_DATE_FILTER_STATISTIC[1].toISOString(),
};

const DashboardEvents = () => {
  const dispatch = useDispatch();

  const filter = useSelector((state) => state.statistic.eventFilter);

  const getStatistic = (data) => {
    dispatch(getEventStatisticChart(data));
    dispatch(getEventStatisticInfo(data));
  };

  useEffect(() => {
    getStatistic({ ...filter, filter: { eventTime, ...filter.filter } });
  }, [filter]); // eslint-disable-line

  useEffect(
    () => () => {
      dispatch(setEventFilterStatistic({}));
    },
    [dispatch],
  );

  return (
    <EventsStyles>
      <PageTitle>{i18next.t('events.header')}</PageTitle>
      <Filter />
      <div className="section-groups">
        <ChartByTime />
        <TableEvents />
      </div>
    </EventsStyles>
  );
};

export default DashboardEvents;
