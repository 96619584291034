import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash-es';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

const ProvidersField = ({ data, handleClickViewAll }) =>
  isEmpty(data?.firstProviderName) ? null : (
    <div>
      <div>
        <Link
          to={`/providers/${data?.firstProviderId}/show/summary`}
          className="text-black"
        >
          {data?.firstProviderName}
        </Link>
      </div>
      {Number(data?.totalProviders) > 1 && (
        <Button
          size="small"
          type="dashed"
          onClick={handleClickViewAll}
          role="presentation"
          className="mt-10"
        >
          {i18next.t('button.viewAll')}
        </Button>
      )}
    </div>
  );

ProvidersField.propTypes = {
  data: PropTypes.object,
  handleClickViewAll: PropTypes.func,
};

export default ProvidersField;
