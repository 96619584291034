import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';

const CheckboxItem = ({ checked, item, onAdd, onRemove }) => {
  const onChange = (e) => {
    if (e.target.checked) {
      onAdd(item?.id);
    } else {
      onRemove(item?.id);
    }
  };
  return (
    <div className="user-centre-item">
      <Checkbox checked={checked} onChange={onChange}>
        {item?.name}
      </Checkbox>
    </div>
  );
};

CheckboxItem.propTypes = {
  checked: PropTypes.bool,
  item: PropTypes.object,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
};

export default CheckboxItem;
