import { CloseCircleOutlined } from '@ant-design/icons';
import { Image, Tooltip } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { formatMoney } from 'utils/tools';
import { InfoIcon } from '../SVGIcon';
import { SummaryCardStyles } from './styles';

const SummaryCard = ({
  IconComponent,
  title,
  count,
  isMoney,
  isPerAnnum,
  isShowIconInfo,
}) => {
  const countValue = useMemo(() => {
    if (isPerAnnum)
      return `${i18next.t('currencyUnit.sign')} ${formatMoney(
        count,
      )} ${i18next.t('text.perAnnum')}`;

    if (isMoney)
      return `${i18next.t('currencyUnit.sign')} ${formatMoney(count)}`;

    return formatMoney(count);
  }, [count, isMoney, isPerAnnum]);

  return (
    <SummaryCardStyles>
      <div className="wrapper-icon">
        {IconComponent.url ? (
          <Image
            preview={false}
            src={IconComponent?.url}
            width={50}
            className="flex-center"
          />
        ) : (
          <IconComponent />
        )}
      </div>
      <div className="wrapper-content">
        <div className="d-flex">
          <div className="title mr-7">{i18next.t(title)}</div>
          {count > 0 && isShowIconInfo && (
            <Tooltip title={i18next.t('text.includeCentreComingSoon')}>
              <InfoIcon className="mt-2" />
            </Tooltip>
          )}
        </div>

        <div className="count-value">{countValue}</div>
      </div>
    </SummaryCardStyles>
  );
};

SummaryCard.propTypes = {
  IconComponent: PropTypes.any,
  title: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isMoney: PropTypes.bool,
  isPerAnnum: PropTypes.bool,
  isShowIconInfo: PropTypes.bool,
};

SummaryCard.defaultProps = {
  IconComponent: CloseCircleOutlined,
};

export default SummaryCard;
