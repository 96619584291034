import { createSelector } from 'reselect';

const getRawResourceDataUsersOfProvider = (state) =>
  state.usersOfProvider.resourceData;

const getRawUsersOfProviderLoading = (state) => state.usersOfProvider.loading;

const getRawDataUsersOfProvider = (state) => state.usersOfProvider.data;

export const enabledLoadMoreUsersOfProviderSelector = createSelector(
  [getRawUsersOfProviderLoading, getRawResourceDataUsersOfProvider],
  (loading, resources) => {
    const { offset, limit, numberOfPages } = resources || {};
    return !loading && offset / limit + 1 < numberOfPages;
  },
);

export const getResourceDataUsersOfProvider = createSelector(
  [getRawResourceDataUsersOfProvider],
  (data) => data,
);

export const getLoadingUsersOfProvider = createSelector(
  [getRawUsersOfProviderLoading],
  (data) => data,
);

export const getDataUsersOfProvider = createSelector(
  [getRawDataUsersOfProvider],
  (data) => data,
);
