import React, { useEffect, useCallback } from 'react';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { getAllCentres } from 'redux/centres/actions';
import { PREFIX_URL } from 'configs/localData/constant';
import CentreFilter from './CentreFilter';
import CentreListMultiSelect from './CentreListMultiSelect';

const CentreForm = () => {
  const dispatch = useDispatch();

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, keepFilter }) => {
      dispatch(
        getAllCentres({
          data: {
            orderBy: 'name',
            ...filter,
            filter: filter.filter,
          },
          options: {
            keepFilter,
            isRefresh,
            prefixUrl: PREFIX_URL.superAdmin,
          },
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    retrieveList({
      filter: {
        limit: 10,
        offset: 0,
      },
      isRefresh: true,
    });
  }, [retrieveList]);

  return (
    <div>
      <div className="title-session">{i18next.t('users.selectCentres')}</div>
      <CentreFilter retrieveList={retrieveList} />
      <CentreListMultiSelect retrieveList={retrieveList} />
    </div>
  );
};

export default CentreForm;
