import styled from 'styled-components';

const CentreDetailStyles = styled.div`
  height: 100%;

  .listing-strength__contact-collapsed {
    transform: scale(0);
    width: 100%;
    height: 0;
  }
  .listing-strength__contact-col {
    transition: height 0.6s;
  }

  .row-customer-detail {
    display: flex;
    flex-wrap: wrap;
  }
  @media only screen and (max-width: 776px) {
    .row-customer-detail {
      display: flex;
      flex-wrap: wrap;
    }
  }

  @media only screen and (max-width: 1024px) {
    .container-wrapper {
      height: 500px;
    }
  }
`;

export default CentreDetailStyles;

export const ListingStrengthStyles = styled.div`
  .ant-progress-status-success
    .ant-progress-inner:not(.ant-progress-circle-gradient)
    .ant-progress-circle-path {
    stroke: #1890ff;
  }
  .start-content {
    font-weight: 400;
    font-size: 16px;
    text-align: justify;
    margin-bottom: 18px;
  }
  .btn-view-list {
    background: #fff6fd;
    color: ${({ theme }) => theme.color.blueSecondary};
    font-weight: bold;
    margin-top: -8px;
  }
  .header-info {
    background: #fff6fd;
    padding: 20px 32px 0 32px;
    &__text {
      color: ${({ theme }) => theme.palette.primary};
      margin-right: 10px;
      font-weight: bold;
    }
  }
  .content-step {
    background: #fff;
    padding: 16px 45px 0 32px;

    &__title-info {
      font-weight: bold;
      font-size: 18px;
      word-wrap: break-word;
      max-height: 120px;
    }
    &__btn-update {
      color: #fff;
      font-weight: bold;
      font-size: 16px;
    }
  }

  .show-hide-btn {
    font-size: 14px;
    font-weight: bold;
    margin-right: 8px;
    &__next {
      color: ${({ theme }) => theme.text.text};
    }
    &__previous {
      color: ${({ theme }) => theme.text.mainL2};
    }
  }
  .show-hide-btn:hover {
    color: ${({ theme }) => theme.background.primary};
  }
  .icon-star {
    margin-left: -20px;
    box-sizing: border-box;
    margin-bottom: 4px;
  }
  .ant-progress-steps-item {
    min-width: 9%;
  }
  .ant-progress-steps-item:first-child {
    border-radius: 7px 0 0 7px;
  }
  .ant-progress-steps-item:not(.ant-progress-steps-item-active) {
    background: #dee1e4;
  }

  .ant-progress-steps-item:last-child {
    border-radius: 0px 7px 7px 0;
  }
  .ant-progress-inner:not(.ant-progress-circle-gradient)
    .ant-progress-circle-path {
    stroke-width: 15px;
  }
  .ant-progress-steps-outer {
    padding-right: 20px;
  }
  .ant-btn {
    box-shadow: 0px 0px 0px #fff;
    padding: 6px 15px;
  }
  .edit-icon {
    color: #857e7f;
  }
  a.ant-typography[disabled],
  .ant-typography a[disabled],
  a.ant-typography.ant-typography-disabled,
  .ant-typography a.ant-typography-disabled {
    color: #857e7f;
  }
`;
