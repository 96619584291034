import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import LegendStyles from './styles';

const LegendChart = ({ payload, opacity, setOpacity, isSetOpacity }) => {
  const handleMouseEnter = (o) => {
    const { dataKey } = o;
    const opacityNew = { ...opacity };
    Object.keys(opacity).forEach((item) => {
      if (item !== dataKey) opacityNew[item] = 0.3;
    });
    setOpacity({ ...opacityNew });
  };

  const handleMouseLeave = () => {
    const opacityNew = { ...opacity };
    Object.keys(opacity).forEach((item) => {
      if (opacity[item] !== 1) opacityNew[item] = 1;
    });
    setOpacity({ ...opacityNew });
  };

  return (
    <LegendStyles>
      {payload?.map((entry, index) => (
        <div
          key={String(index)}
          className="legend-item"
          {...(isSetOpacity && {
            onMouseEnter: () => handleMouseEnter({ dataKey: entry.value }),
            onMouseLeave: () => handleMouseLeave({ dataKey: entry.value }),
          })}
        >
          <div className="dot" style={{ background: entry.payload.stroke }} />
          <span className="text-legend">
            {i18next.t(entry.payload.text) || entry.value}
          </span>
        </div>
      ))}
    </LegendStyles>
  );
};

LegendChart.propTypes = {
  payload: PropTypes.array,
  opacity: PropTypes.object,
  setOpacity: PropTypes.func,
  isSetOpacity: PropTypes.bool,
};

LegendChart.defaultProps = {
  isSetOpacity: false,
};

export default LegendChart;
