import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import i18next from 'i18next';
import PageTitle from 'components/common/PageTitle';
import AppSettingsList from 'containers/AppSettings/List';
import NQSRTypesList from 'containers/NQSRTypes/List';
import FeaturesList from 'containers/Features/List';
import MarketingsList from 'containers/Marketings/List';
import SuburbList from 'containers/Suburbs/List';
import CRMPackagesList from 'containers/CRMPackages/List';
import { useHistory } from 'react-router';
import Services from 'containers/Services';
import SettingStyle from './styles';

const { TabPane } = Tabs;

const TABS = [
  {
    key: 'appSettings',
    text: 'settings.tabs.appSettings',
    url: '/appSettings',
    component: AppSettingsList,
  },
  {
    key: 'services',
    text: 'settings.tabs.services',
    url: '/services',
    component: Services,
  },
  {
    key: 'NQSRTypes',
    text: 'settings.tabs.NQSRTypes',
    url: '/NQSRTypes',
    component: NQSRTypesList,
  },
  {
    key: 'features',
    text: 'settings.tabs.features',
    url: '/features',
    component: FeaturesList,
  },
  {
    key: 'marketings',
    text: 'settings.tabs.marketing',
    url: '/marketings',
    component: MarketingsList,
  },
  {
    key: 'suburbs',
    text: 'settings.tabs.suburbs',
    url: '/suburbs',
    component: SuburbList,
  },
  {
    key: 'CRMPackages',
    text: 'sideBar.CRMPackages',
    url: '/CRMPackages',
    component: CRMPackagesList,
  },
];

const Settings = ({ match, ...props }) => {
  const history = useHistory();
  const onChange = (key) => {
    history.push(`/settings/${key}`);
  };

  return (
    <SettingStyle>
      <div className="page-header">
        <PageTitle>{i18next.t('settings.header')}</PageTitle>
      </div>
      <Tabs activeKey={match.params.model || 'appSettings'} onChange={onChange}>
        {TABS.map((tab) => (
          <TabPane tab={i18next.t(tab.text)} key={tab.key}>
            <tab.component
              rootPath="/settings"
              noCardWrapper
              layoutButtonCreate="non-inline"
              {...props}
            />
          </TabPane>
        ))}
      </Tabs>
    </SettingStyle>
  );
};

Settings.propTypes = {
  match: PropTypes.object,
  redirects: PropTypes.object,
  rootPath: PropTypes.string,
  initCreateData: PropTypes.object,
};

Settings.defaultProps = {
  rootPath: '/settings',
  redirects: {
    edit: 'modal',
    create: 'modal',
  },
  initCreateData: {},
};

export default Settings;
