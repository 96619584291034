import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import i18next from 'i18next';

import EditCustomBtn from 'components/RestActions/EditCustomBtn';
import CompanyImageInfo from './CompanyImageInfo';
import GeneralInfo from './GeneralInfo';
import PrimaryContact from './PrimaryContact';
import CompanyGeneralInfoModal from '../Modals/CompanyGeneralInfoModal';
import CompanyInfoStyles from './styles';

const CompanyInfoGeneral = ({ record }) => {
  const modalRef = useRef();

  const onClickButton = () => {
    modalRef.current && modalRef.current.toggleModal();
  };

  return (
    <CompanyInfoStyles>
      <EditCustomBtn onClickButton={onClickButton} />
      <CompanyImageInfo logo={record?.logo} />
      {record?.type && (
        <div className="w-full flex-center mb-20">
          <span className="company-type">{record?.type?.name}</span>
        </div>
      )}
      <div className="company-name-style">{record?.name}</div>
      <div className="content-info-container">
        <GeneralInfo record={record} />
        <Divider />
        <div className="title-info-other">
          {i18next.t('companies.primaryContact')}
        </div>
        <PrimaryContact record={record?.user} />
      </div>

      <CompanyGeneralInfoModal ref={modalRef} record={record} />
    </CompanyInfoStyles>
  );
};

CompanyInfoGeneral.propTypes = {
  record: PropTypes.object,
};

CompanyInfoGeneral.defaultProps = {
  record: {},
};

export default CompanyInfoGeneral;
