import styled from 'styled-components';
import { Form } from 'antd';

export const FilterFormWrapper = styled(Form)`
  .box-filter {
    padding: 0;
    padding-bottom: 20px;
    background: none;
    border: none;
  }
  .row-filter-container {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
  }
  .filterActions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    & > .filterButton {
      margin-right: 24px;
    }
    & > .action-button {
      width: 50%;
    }

    @media (max-width: 1050px) and (min-width: 992px) {
      & > .filterButton {
        margin-right: 12px;
      }
    }
  }

  .clearButton {
    background: ${({ theme }) => theme.background.clearBtn};
    color: ${({ theme }) => theme.text.primary};
    border: 1px solid transparent;
    &:hover {
      color: ${({ theme }) => theme.palette.primary};
      border: 1px solid ${({ theme }) => theme.palette.primary};
    }
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
  .ant-form-item-label label {
    color: ${({ theme }) => theme.text.itemLabel};
  }
  .ant-input-number {
    width: 100%;
  }
  .date-picker-wrapper {
    height: 50px;
    .ant-calendar-picker {
      height: 40px;
      line-height: 40px;
      margin-bottom: 10px;
      input {
        padding-right: 25px;
      }
    }
  }
  textarea,
  .ant-input,
  .ant-input-number,
  .ant-select-dropdown-menu-item,
  .ant-select-dropdown-menu,
  .ant-select-dropdown,
  .ant-select-clear-icon,
  .ant-select-dropdown-menu-vertical {
    &:focus,
    &:active {
      border: 1px solid ${({ theme }) => theme.border.primary}!important;
    }
  }

  .ant-select-focused.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    border: 1px solid ${({ theme }) => theme.border.primary}!important;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-selector,
  .ant-input {
    border: 1px solid transparent !important;
  }
  .ant-picker-range {
    width: 100%;
  }
`;
