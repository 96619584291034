import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import TableCustom from 'components/common/TableCustom';
import UserInfo from 'components/RestField/UserInfo';
import { formatDateTimeTable } from 'utils/textUtils';
import CentresUserField from 'containers/Users/components/CentresUserProvider/CentresUserField';
import CentresUserModal from 'containers/Users/components/CentresUserProvider/CentresUserModal';
import {
  getDataUsersOfProvider,
  getLoadingUsersOfProvider,
  getResourceDataUsersOfProvider,
} from 'redux/usersOfProvider/selector';
import { useSelector } from 'react-redux';
import GroupActionsUser from './GroupActionUser';

export const formatUserName = (data, record) => {
  const recordInfo = {
    id: record.id,
    avatar: record.avatar,
    email: record.email,
    fullName: `${record?.firstName || ''} ${record?.lastName || ''}`,
  };

  return (
    <UserInfo
      record={recordInfo}
      roleProp="email"
      prefixLink="users"
      suffixLink="show"
    />
  );
};

const UsersListTable = ({ retrieveList }) => {
  const centresUserModalRef = useRef();

  const resourceFilter = useSelector(getResourceDataUsersOfProvider) || {};

  const loading = useSelector(getLoadingUsersOfProvider);

  const resourceData = useSelector(getDataUsersOfProvider);

  const openCentresUserModal = useCallback((record) => {
    centresUserModalRef.current.open(record);
  }, []);

  const formatCentreName = useCallback(
    (data, record) => (
      <CentresUserField
        roleName={record?.role?.name}
        data={data}
        handleClickViewAll={() => openCentresUserModal(record)}
      />
    ),
    [], // eslint-disable-line
  );

  const columns = [
    {
      title: i18next.t('common.name'),
      dataIndex: 'userName',
      sorter: true,
      render: formatUserName,
    },
    {
      title: i18next.t('users.jobTitle'),
      dataIndex: 'jobTitle',
      sorter: true,
    },
    {
      title: i18next.t('users.role'),
      dataIndex: ['role', 'description'],
    },
    {
      title: i18next.t('users.centre'),
      dataIndex: 'centres',
      render: formatCentreName,
      width: 200,
    },
    {
      title: i18next.t('common.lastActiveAt'),
      dataIndex: 'lastActiveAt',
      sorter: true,
      render: formatDateTimeTable,
    },
    {
      title: i18next.t('common.createdAt'),
      dataIndex: 'createdAt',
      sorter: true,
      render: formatDateTimeTable,
    },
    {
      dataIndex: 'id',
      fixed: 'right',
      width: 150,
      render: (id, record) => <GroupActionsUser userId={id} record={record} />,
    },
  ];

  const onChange = (e, filters, sorter) => {
    const formatSort =
      sorter && sorter.field && sorter.order
        ? `${sorter.order === 'descend' ? '-' : ''}${sorter.field}`
        : '-createdAt';

    const restFilter = {
      offset: (e.current - 1) * e.pageSize,
      limit: e.pageSize,
      orderBy: formatSort,
      filter: resourceFilter.filter,
      outsideFilter: resourceFilter.outsideFilter,
    };

    retrieveList({
      filter: restFilter,
      isRefresh: true,
    });
  };

  return (
    <div>
      <TableCustom
        xScroll={1200}
        isSorter
        onChange={onChange}
        data={resourceData}
        columns={columns}
        loading={loading}
        pagination={{
          pageSize: resourceFilter.limit,
          current: resourceFilter.offset / resourceFilter.limit + 1 || 1,
          total: resourceFilter.total,
        }}
      />
      <CentresUserModal ref={centresUserModalRef} />
    </div>
  );
};

UsersListTable.propTypes = {
  retrieveList: PropTypes.func,
};

UsersListTable.defaultProps = {
  retrieveList: () => null,
};

export default UsersListTable;
