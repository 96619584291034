import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import LogoStyle from './style';

const Logo = ({ logo, name }) => (
  <LogoStyle>
    <Avatar size={32} src={logo}>
      Logo
    </Avatar>
    <span type="text" className="text-style">
      {name}
    </span>
  </LogoStyle>
);

Logo.propTypes = {
  logo: PropTypes.string,
  name: PropTypes.string,
};

export default Logo;
