import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import Form from 'antd/lib/form';
import Button from 'antd/lib/button';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';

import { RestInputContext } from 'components/RestInput/RestInputContext';
import FilterCustomStyles from './styles';

const FilterCustom = ({
  children,
  greyInput,
  onSubmitFilter = () => null,
  onClearFilter = () => null,
  responsiveFilter = {
    xxl: 20,
    xl: 18,
    lg: 18,
    md: 24,
    xs: 24,
  },
  responsiveAction = {
    xxl: 4,
    xl: 6,
    lg: 6,
    md: 24,
    xs: 24,
  },
}) => {
  const [form] = Form.useForm();

  const onFilter = () => {
    form
      .validateFields()
      .then((values) => {
        onSubmitFilter(values);
      })
      .catch(() => {});
  };

  const onClear = () => {
    form.resetFields();
    onClearFilter();
  };

  return (
    <FilterCustomStyles
      className={greyInput ? 'grey-input-filter' : 'default-input-filter'}
    >
      <Form form={form} autoComplete="off">
        <RestInputContext.Provider
          value={{
            form,
            allowPressEnter: true,
            handleSubmit: onFilter,
          }}
        >
          <Row gutter={16} className="row-filter">
            <Col {...responsiveFilter}>{children}</Col>

            <Col {...responsiveAction} className="row-action-bottom">
              <Button
                type="primary"
                className="filterButton"
                onClick={onFilter}
              >
                {i18next.t('button.filter')}
              </Button>
              <Button className="clearButton" onClick={onClear}>
                {i18next.t('button.clearFilter')}
              </Button>
            </Col>
          </Row>
        </RestInputContext.Provider>
      </Form>
    </FilterCustomStyles>
  );
};

FilterCustom.propTypes = {
  children: PropTypes.node,
  greyInput: PropTypes.bool,
  onSubmitFilter: PropTypes.func,
  onClearFilter: PropTypes.func,
  responsiveFilter: PropTypes.object,
  responsiveAction: PropTypes.object,
};

export default FilterCustom;
