import React, { useState } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import i18next from 'i18next';

import CalendarPicker from 'components/common/CalendarPicker';
import { DueDateOverlayWrapper } from './styles';

const DueDateOverlay = ({ closeOverlay, value, onChange }) => {
  const [dueDate, setDueDate] = useState(value ? moment(value) : moment());

  const onChangeCalender = (value) => {
    setDueDate(value);
  };

  const onOk = () => {
    onChange(dueDate);
    closeOverlay();
  };

  return (
    <DueDateOverlayWrapper>
      <CalendarPicker value={dueDate} onChange={onChangeCalender} />
      <div className="footer-due-date">
        <Button type="primary" onClick={onOk} size="small">
          {i18next.t('button.ok')}
        </Button>
      </div>
    </DueDateOverlayWrapper>
  );
};

DueDateOverlay.propTypes = {
  closeOverlay: PropTypes.func,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

DueDateOverlay.defaultProps = {
  closeOverlay: () => null,
};

export default DueDateOverlay;
