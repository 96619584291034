import { makeActions } from 'redux/crudCreator/actions';

export const MODEL_NAME = 'articles';
export const articlesActions = makeActions(MODEL_NAME);

export const getAllArticles = articlesActions.getAll;
export const editArticles = articlesActions.edit;
export const createArticles = articlesActions.create;
export const getByIdArticles = articlesActions.getDataById;
export const delArticles = articlesActions.del;
