import React from 'react';
import PropTypes from 'prop-types';
import RestSelect from 'components/RestInput/RestSelect';
import ReferenceInput from 'containers/rest/ReferenceInput';
import { PREFIX_URL_API } from 'configs/localData/constant';

const SuburbInput = ({ handleGetGeoWhenChangeAddress }) => (
  <div>
    <ReferenceInput
      source="city"
      resource="cities"
      searchKey="city"
      filterKey="city"
      mappedBy="city"
      initialFilter={{ orderBy: 'city' }}
      prefixUrl={PREFIX_URL_API}
    >
      <RestSelect
        required
        header="input.suburb.name"
        titleProp="city"
        valueProp="city"
        placeholder="input.suburb.placeholder"
        isFilterOption={false}
        onChange={handleGetGeoWhenChangeAddress}
      />
    </ReferenceInput>
  </div>
);

SuburbInput.propTypes = {
  handleGetGeoWhenChangeAddress: PropTypes.func,
};

export default SuburbInput;
