import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, Input, Spin } from 'antd';
import { isEmpty } from 'lodash-es';
import { useSelector } from 'react-redux';
import crudSelectors from '../../../redux/crudSelectors';
import ButtonRow from '../FooterButtonRow';
import RestInputItem from '../../RestInput/RestInputItem';
import { RestInputContext } from '../../RestInput/RestInputContext';

const EDIT_BLACKLIST = ['createdAt', 'updatedAt', '_id'];

const EditFormComponent = ({
  record,
  onBack,
  children,
  showModal,
  customSubmitButton,
  positionOfSubmitButton,
  onSubmit,
  formatOnSubmit,
  resource,
}) => {
  const loading = useSelector(crudSelectors[resource].getLoadingCurrentRecord);

  const isDisabled = useSelector(
    (state) => state[resource]?.isDisabledButtonSubmit,
  );

  const [form] = Form.useForm();
  const getData = () =>
    new Promise((resolve) => {
      form.validateFields().then((values) => {
        const submitData = formatOnSubmit ? formatOnSubmit(values) : values;
        resolve(submitData);
        resolve({});
      });
    });

  const handleSubmit = (values) => {
    const submitData = formatOnSubmit ? formatOnSubmit(values) : values;
    onSubmit(submitData, form);
  };
  if (isEmpty(record))
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItem: 'center',
        }}
      >
        <Spin />
      </div>
    );

  if (!children || children.length === 0) {
    const components = Object.keys(record).map((key) =>
      EDIT_BLACKLIST.indexOf(key) > -1 ? null : (
        <RestInputItem
          type={typeof record[key]}
          disabled={key === 'id'}
          form={form}
          defaultValue={record[key]}
          name={key}
          header={key}
        >
          <Input />
        </RestInputItem>
      ),
    );
    return (
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        form={form}
        scrollToFirstError
      >
        {components}
        {customSubmitButton !== undefined ? (
          customSubmitButton &&
          React.cloneElement(customSubmitButton, {
            loading,
            disabled: isDisabled,
            onBack,
            getData,
          })
        ) : (
          <ButtonRow
            showModal={showModal}
            loading={loading}
            disabled={isDisabled}
            handleSubmit={handleSubmit}
            onBack={onBack}
          />
        )}
      </Form>
    );
  }
  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      style={{ width: '100%' }}
    >
      <Row className="drawerContainer">
        <Col
          className="content"
          md={positionOfSubmitButton === 'left' ? 20 : 24}
          xs={24}
        >
          <div className="content-form">
            <RestInputContext.Provider
              value={{
                form,
                record,
                getData,
                handleSubmit,
              }}
            >
              {React.cloneElement(children, { form })}
            </RestInputContext.Provider>
          </div>
        </Col>
        <Col
          className="footer"
          md={positionOfSubmitButton === 'left' ? 4 : 24}
          xs={24}
        >
          {customSubmitButton !== undefined ? (
            customSubmitButton &&
            React.cloneElement(customSubmitButton, {
              loading,
              disabled: isDisabled,
              handleSubmit,
              onBack,
              getData,
            })
          ) : (
            <ButtonRow
              showModal={showModal}
              loading={loading}
              disabled={isDisabled}
              handleSubmit={handleSubmit}
              onBack={onBack}
            />
          )}
        </Col>
      </Row>
    </Form>
  );
};
EditFormComponent.propTypes = {
  resource: PropTypes.string,
  showModal: PropTypes.bool,
  onBack: PropTypes.func,
  onSubmit: PropTypes.func,
  children: PropTypes.node,
  positionOfSubmitButton: PropTypes.string,
  customSubmitButton: PropTypes.node,
  record: PropTypes.object,
  formatOnSubmit: PropTypes.func,
};

EditFormComponent.defaultProps = {
  positionOfSubmitButton: 'bottom',
  record: {},
};

export default EditFormComponent;
