import styled from 'styled-components';

const TooltipStyles = styled.div`
  background: white;
  background-clip: padding-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  .circle-tooltip {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .rectangle-tooltip {
    width: 15px;
    height: 10px;
    margin-right: 10px;
  }
  .line-tooltip {
    width: 36px;
    height: 6px;
    border-radius: 8px;
    margin-right: 10px;
  }
  .content-tooltip {
    list-style-type: none;
    height: 400px;
    padding: 15px;
    overflow-y: scroll;
  }
  .title-tooltip {
    text-align: center;
    padding: 10px;
  }

  .tooltip-item {
    &:not(:first-child) {
      margin-top: 10px;
    }
    &__value {
      margin-left: 20px;
    }
  }
  .ant-divider {
    margin: 0;
  }
  .content-tooltip {
  }
`;

export default TooltipStyles;
