import React from 'react';
import RestShow from 'containers/rest/Show';
import RestFieldItem from 'components/RestField/RestFieldItem';

const CRMPackagesShow = (props) => (
  <RestShow {...props} hasEdit resource="CRMPackages">
    <RestFieldItem source="name" header="CRMPackages.name" />
    <RestFieldItem
      source="signupButtonText"
      header="CRMPackages.signupButtonText"
    />
    <RestFieldItem source="description" header="CRMPackages.description" />
  </RestShow>
);

export default CRMPackagesShow;
