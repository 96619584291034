import React, { useState } from 'react';

import CreateButton from 'components/RestActions/CreateButton';
import CreateServiceModal from '../Modals/CreateServiceModal';

const CreateServiceButton = () => {
  const [visible, setVisible] = useState(false);
  const toggleModal = () => {
    setVisible(!visible);
  };

  return (
    <>
      <CreateButton
        permissionKey="myCentres"
        action="isEdit"
        gotoCreatePage={toggleModal}
      />
      <CreateServiceModal visible={visible} onCancel={toggleModal} />
    </>
  );
};

export default CreateServiceButton;
