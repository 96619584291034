import styled from 'styled-components';

export const RequiredDaysStyles = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 600;
  .ant-space {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  &.required-day-primary .avatar-active {
    background: ${({ theme }) => theme.palette.primary} !important;
    color: #fff !important;
  }
  &.required-day-secondary .avatar-active {
    background: #ffff !important;
    color: #32a4fc !important;
    border: 1px solid #cae8ff !important;
  }

  .avatar-disabled {
    color: #aba7a7;
    background: #f2f2f2;
  }
  .ant-avatar-string {
    font-size: 12px;
  }
`;

export const ServiceTypesStyles = styled.div`
  .service-item {
    margin-top: 15px;
    text-align: center;
    color: #316dde;
    background: rgba(50, 164, 252, 0.2);
    border-radius: 8px;
    padding: 4px 10px;
    font-weight: 600;
  }
  .age-group-service {
    font-size: 12px;
    color: ${({ theme }) => theme.text.secondary};
    text-align: center;
    margin-top: 5px;
  }
`;
