import React, { useState, useCallback, useMemo } from 'react';
import { isEmpty, uniqBy } from 'lodash-es';
import PropTypes from 'prop-types';
import { Skeleton, Button } from 'antd';
import i18next from 'i18next';
import { getRepliesOfRating } from 'api/ratings';
import { useSelector } from 'react-redux';
import ReplyItem from './ReplyItem';
import { ReplySectionStyles } from './styles';

const limitDefault = 10;

const RepliesSection = ({ id, latestReplyReview, totalReplies }) => {
  const { images, name } =
    useSelector((state) => state.centres?.currentData) || {};

  const featureImage = useMemo(() => {
    const restUrl = images?.find((item) => item.featured)?.url;
    return restUrl || images?.[0]?.url;
  }, [images]);

  const [replies, setReplies] = useState({
    offset: 0,
    total: Number(totalReplies) || 0,
    data: latestReplyReview ? [latestReplyReview] : [],
  });

  const [loading, setLoading] = useState(false);

  const enabledLoadMore = useMemo(() => {
    if (Number(totalReplies) < 2) return false;
    return !loading && replies?.data?.length < replies.total;
  }, [loading, replies, totalReplies]);

  const handleViewMore = useCallback(() => {
    setLoading(true);

    id &&
      getRepliesOfRating(id, {
        limit: limitDefault,
        orderBy: '-createdAt',
        offset: replies.offset,
      })
        .then((response) => {
          setReplies({
            offset: replies.offset + limitDefault,
            total: response.total || 0,
            data: uniqBy([...replies.data, ...(response.results || [])], 'id'),
          });
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
  }, [id, replies]);

  return (
    <ReplySectionStyles>
      <div>
        {!loading && isEmpty(replies?.data) ? null : (
          <div>
            {replies?.data.map((item, index) => (
              <ReplyItem
                item={item}
                key={String(index)}
                logoUrl={featureImage}
                centreName={name}
              />
            ))}
          </div>
        )}
        {loading && <Skeleton active avatar />}
        {enabledLoadMore && (
          <Button type="link" onClick={handleViewMore}>
            {i18next.t('button.viewMore')}
          </Button>
        )}
      </div>
    </ReplySectionStyles>
  );
};

RepliesSection.propTypes = {
  latestReplyReview: PropTypes.object,
  id: PropTypes.string,
  totalReplies: PropTypes.any,
};

export default RepliesSection;
