/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/** ------------------------ Utils---------- */

export function getNodeFromTree(node, id) {
  if (node.id === id) {
    return node;
  }
  if (node.children != null) {
    let result = null;
    for (let i = 0; result == null && i < node.children.length; i++) {
      result = getNodeFromTree(node.children[i], id);
    }
    return result;
  }
  return null;
}

export function insertNodeIntoTree(node, newNode) {
  if ((!newNode?.parentId || node.id === newNode?.parentId) && newNode) {
    const restNew = {
      ...newNode,
      key: newNode.id,
      title: newNode.name,
      isLeaf: newNode.parentId,
      parent: newNode.parent,
      value: newNode.id,
    };
    if (node.children) {
      node.children.push(restNew);
    } else {
      node.children = [restNew];
    }
  } else if (node.children?.length) {
    for (let i = 0; i < node.children.length; i++) {
      insertNodeIntoTree(node.children[i], newNode);
    }
  }
}

export function updateNodeInTree(node, newNode) {
  if (node.id === newNode?.id) {
    node.title = newNode.name;
    node.name = newNode.name;
  } else if (node.children?.length) {
    for (let i = 0; i < node.children.length; i++) {
      updateNodeInTree(node.children[i], newNode);
    }
  }
}

export function deleteNodeFromTree(node, id) {
  if (node.children?.length) {
    for (let i = 0; i < node.children.length; i++) {
      if (node.children.find((item) => item.id === id)) {
        node.children = node.children.filter((f) => f.id !== id);
        return;
      }
      deleteNodeFromTree(node.children[i], id);
    }
  }
}
