import React from 'react';
import { PREFIX_URL_API } from 'configs/localData/constant';

import Create from 'containers/rest/Create';
import Form from '../components/Form';

const ProviderCreate = (props) => (
  <Create
    {...props}
    resource="providers"
    defaultOptions={{
      isTrimStr: true,
      prefixUrl: PREFIX_URL_API,
    }}
  >
    <Form />
  </Create>
);

export default ProviderCreate;
