import moment from 'moment';
import i18next from 'i18next';
import DefaultImage from 'assets/images/default-centre-cover.png';
import { forEach } from 'lodash-es';
import { validateRegex } from './validateUtils';

export const getFeaturedImage = (images) => {
  const featuredItem = images?.find((item) => item?.featured);

  if (featuredItem?.type === 'video' || !featuredItem?.url) {
    if (images?.[0]?.type === 'video') return null;
    return images?.[0]?.url || DefaultImage;
  }

  return featuredItem?.url || DefaultImage;
};

export const formatTimeFilterSubmit = (timeArr) =>
  timeArr
    ? {
        $gt: timeArr?.[0]
          ? moment(timeArr[0]).startOf('date').toISOString()
          : undefined,
        $lt: timeArr?.[1]
          ? moment(timeArr[1]).endOf('date').toISOString()
          : undefined,
      }
    : undefined;

export const getClassificationCentre = (kindiCareRating) => {
  if (!kindiCareRating) {
    return i18next.t('classification.notRated');
  }
  if (kindiCareRating >= 9) {
    return i18next.t('classification.excellent');
  }
  if (kindiCareRating >= 8.5) {
    return i18next.t('classification.veryGood');
  }
  if (kindiCareRating >= 7.5) {
    return i18next.t('classification.good');
  }
  if (kindiCareRating >= 5) {
    return i18next.t('classification.fair');
  }
  return i18next.t('classification.poor');
};

export const getAddressCentreToGetGeo = (values) =>
  `${values.addressL2 ? `${values.addressL2}, ` : ''}${values.addressL1}, ${
    values.city
  } ${values.state} ${values.postCode}`;

export const splitAddressFromGoogleMap = (addressArr) => {
  const addressObj = {};

  forEach(addressArr, (itemAddress) => {
    switch (itemAddress?.types?.[0]) {
      case 'street_number': {
        addressObj.addressL1 = `${itemAddress.long_name}${
          addressObj.addressL1 ? ` ${addressObj.addressL1}` : ''
        }`;
        break;
      }

      case 'route': {
        addressObj.addressL1 = `${
          addressObj.addressL1 ? `${addressObj.addressL1} ` : ''
        }${itemAddress.short_name}`;
        break;
      }

      case 'postal_code': {
        addressObj.postCode = `${itemAddress.long_name}`;
        break;
      }

      case 'locality':
        addressObj.city = itemAddress.long_name;
        break;

      case 'administrative_area_level_1':
        addressObj.state = itemAddress.short_name;
        break;

      case 'country':
        addressObj.country = itemAddress.long_name;
        break;

      default:
        break;
    }
  });
  return addressObj;
};

export const getUrlIfyMessage = (string) =>
  string?.replace(
    validateRegex.url,
    (url) => `<a class="detect-link" href="${url}" target="_blank">${url}</a>`,
  );

export const isInvalidEmail = (email) => !validateRegex.email.test(email);

export const formatFromToTimeFilterSubmit = (timeArr) =>
  timeArr
    ? {
        from: timeArr?.[0]
          ? moment(timeArr[0]).startOf('date').toISOString()
          : undefined,
        to: timeArr?.[1]
          ? moment(timeArr[1]).endOf('date').toISOString()
          : undefined,
      }
    : undefined;

export const toUpperCaseFirstChar = (str) =>
  str?.[0]?.toUpperCase() + str?.substring(1);
