import React, { useEffect, useContext } from 'react';
import { getFilterFromUrl } from 'utils/tools';
import { useLocation } from 'react-router';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import RestSelect from 'components/RestInput/RestSelect';
import RestInputItem from 'components/RestInput/RestInputItem';
import {
  CALLED_OR_CONTACTED_TYPES,
  CLICK_THROUGH_TRACKER_TYPES,
} from 'configs/localData';
import i18next from 'i18next';
import RestRangePickerInput from 'components/RestInput/RestRangePickerInput';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { getSubscriptionsArr } from 'redux/centres/selectors';
import moment from 'moment';

const FilterForm = () => {
  const { search } = useLocation();
  const { form } = useContext(RestInputContext);
  const subscriptionsArr = useSelector(getSubscriptionsArr);
  const { filter } = getFilterFromUrl(search);

  useEffect(() => {
    const { clickThroughDate, ...restFilter } = filter?.filter || {};

    form.setFieldsValue({
      clickThroughDate: Object.values(clickThroughDate || {}).map((item) =>
        moment(item),
      ),
      ...restFilter,
    });
  }, [filter, form]);

  return (
    <Row gutter={16}>
      <Col lg={4} md={12} xs={24}>
        <RestSelect
          source="isContacted"
          placeholder="inboxes.calledOrContacted"
          isShowTooltip
          resourceData={CALLED_OR_CONTACTED_TYPES}
          valueProp="value"
          titleProp="text"
          formatText={(text) => i18next.t(text)}
        />
      </Col>
      <Col lg={4} md={12} xs={24}>
        <RestInputItem
          source="centre"
          placeholder="inboxes.searchCentre"
          isShowTooltip
        />
      </Col>
      <Col lg={4} md={12} xs={24}>
        <RestSelect
          source="subscription"
          placeholder="clickThroughTracker.centreSubscription"
          resourceData={subscriptionsArr}
          valueProp="name"
          titleProp="name"
          isShowTooltip
        />
      </Col>
      <Col lg={4} md={12} xs={24}>
        <RestSelect
          source="clickThroughType"
          placeholder="clickThroughTracker.clickThroughType"
          isShowTooltip
          resourceData={CLICK_THROUGH_TRACKER_TYPES}
          valueProp="value"
          titleProp="text"
          formatText={(data) => i18next.t(data)}
        />
      </Col>
      <Col lg={4} md={12} xs={24}>
        <RestInputItem
          source="user"
          placeholder="clickThroughTracker.searchUser"
          isShowTooltip
        />
      </Col>
      <Col lg={4} md={12} xs={24}>
        <RestRangePickerInput
          source="clickThroughDate"
          placeholder="common.time"
          isShowTooltip
          sourceGt="clickThroughDate.from"
          sourceLt="clickThroughDate.to"
        />
      </Col>
    </Row>
  );
};

export default FilterForm;
