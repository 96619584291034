import SeeMore from 'components/common/SeeMore';
import StatisticItem from 'components/common/StatisticItem';
import React from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import {
  getDataSearchStatistics,
  getLoadingSearchStatistics,
} from 'redux/searchStatistics/selector';
import { Skeleton } from 'antd';

const SearchByPostcode = () => {
  const data = useSelector(getDataSearchStatistics);
  const loading = useSelector(getLoadingSearchStatistics);

  const { push } = useHistory();
  const directToSearchPostcode = () => {
    push('/searchStatistics/searchPostcode');
  };

  return (
    <Skeleton loading={loading}>
      {data?.map((item, key) => (
        <StatisticItem
          key={String(key)}
          searchName={item?.postcode || ''}
          percent={item?.percent}
          count={item?.count}
        />
      ))}
      <SeeMore onClick={directToSearchPostcode} />
    </Skeleton>
  );
};

export default SearchByPostcode;
