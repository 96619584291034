import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Button, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { companiesOfUserSelectors } from 'redux/companiesOfUser/selectors';
import { getCentreIdsOfCompany } from 'redux/companies/actions';
import { checkLastUser, unSubcriptionCentre } from 'redux/users/actions';
import { delCompaniesOfUser } from 'redux/companiesOfUser/actions';
import { getProvidersOfCompany } from 'redux/providersOfCompany/actions';
import TableCustom from 'components/common/TableCustom';
import { getQueryParamsWhenChangeTable } from 'utils/tableUtils';
import ProvidersField from 'components/ProvidersViewAll/ProvidersField';
import ProvidersModal from 'components/ProvidersViewAll/ProvidersModal';
import { ButtonWrapper } from 'components/RestActions/DeleteButton/styles';
import { confirmDeleteForLastUser } from '../ProvidersOfUserList/ProvidersOfUserTable';

const CompaniesOfUserTable = ({ retrieveList }) => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const resourceFilter =
    useSelector(companiesOfUserSelectors.getResourceData) || {};

  const loading = useSelector(companiesOfUserSelectors.getLoading);

  const data = useSelector(companiesOfUserSelectors.getDataArr);

  const providersCompanyModalRef = useRef();

  const openProvidersCompanyModal = useCallback((record) => {
    providersCompanyModalRef.current.open(record);
  }, []);

  const formatProviderCompanies = useCallback(
    (data, record) => (
      <ProvidersField
        data={record}
        handleClickViewAll={() => openProvidersCompanyModal(record)}
      />
    ),
    [openProvidersCompanyModal],
  );

  const deleteCompanyUser = (companyId) =>
    dispatch(
      delCompaniesOfUser({
        id,
        companyId,
      }),
    );

  const unSubcription = (record) => () => {
    dispatch(getCentreIdsOfCompany(record?.id)).then(({ payload }) => {
      payload && dispatch(unSubcriptionCentre({ centreIds: payload }));
    });
  };

  const onCancel = () => {};

  const deleteItem = (companyId, record) => {
    dispatch(
      delCompaniesOfUser({
        id,
        companyId,
      }),
    ).then(({ payload }) => {
      payload?.companyId &&
        confirmDeleteForLastUser(
          onCancel,
          record,
          'companyUser',
          'companyUser.confirmUnsubcription',
          'name',
          true,
          unSubcription(record),
        );
    });
  };

  const deleteCompanyOfUser = (companyId, record) => {
    dispatch(checkLastUser(`companyId=${companyId}&userId=${id}`)).then(
      ({ payload }) => {
        if (!payload?.lastUser) {
          confirmDeleteForLastUser(
            deleteCompanyUser,
            record,
            'companyUser',
            'companyUser.confirmDelete',
            'name',
            payload?.lastUser,
          );
        } else {
          confirmDeleteForLastUser(
            deleteItem,
            record,
            'companyUser',
            'companyUser.confirmDeleteLastUser',
            'name',
            payload?.lastUser,
          );
        }
      },
    );
  };

  const columns = [
    {
      title: i18next.t('companies.company'),
      dataIndex: 'name',
      sorter: true,
      render: (data, record) => (
        <Link
          to={`/companies/${record.id}/show/summary`}
          className="link-custom-primary"
        >
          <span className="fw-bold">{data}</span>
        </Link>
      ),
    },
    {
      title: i18next.t('providers.title'),
      dataIndex: 'firstProviderName',
      render: formatProviderCompanies,
    },
    {
      title: i18next.t('providers.numberOfCentres'),
      dataIndex: 'totalCentres',
      sorter: true,
    },
    {
      title: i18next.t('companies.action'),
      dataIndex: 'id',
      width: 100,
      fixed: 'right',
      render: (id, record) => (
        <ButtonWrapper>
          <Tooltip title={i18next.t('button.delete')}>
            <Button
              icon={<DeleteOutlined />}
              onClick={() => deleteCompanyOfUser(id, record)}
            />
          </Tooltip>
        </ButtonWrapper>
      ),
    },
  ];

  const onChange = (e, filters, sorter) => {
    const queryParams = getQueryParamsWhenChangeTable(e, filters, sorter);

    retrieveList({
      filter: queryParams,
      isRefresh: true,
      keepFilter: true,
    });
  };

  return (
    <div>
      <TableCustom
        xScroll={600}
        onChange={onChange}
        data={data}
        columns={columns}
        loading={loading}
        isResetStyle
        pagination={{
          pageSize: resourceFilter.limit,
          current: resourceFilter.offset / resourceFilter.limit + 1 || 1,
          total: resourceFilter.total,
        }}
      />
      <ProvidersModal
        ref={providersCompanyModalRef}
        retrieveListAction={getProvidersOfCompany}
      />
    </div>
  );
};

CompaniesOfUserTable.propTypes = {
  retrieveList: PropTypes.func,
};

CompaniesOfUserTable.defaultProps = {
  retrieveList: () => null,
};

export default CompaniesOfUserTable;
