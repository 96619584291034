import React from 'react';
import Edit from 'containers/rest/Edit';
import { LABEL_TABLE_KEY, PREFIX_URL_API } from 'configs/localData/constant';
import { convertNameToType, formatLabelTable } from 'utils/textUtils';
import i18next from 'i18next';
import Form from '../components/Form';

const ServiceAgeGroupsEdit = (props) => (
  <Edit
    {...props}
    resource="serviceAgeGroups"
    formatOnSubmit={(data) => ({
      ...data,
      type: convertNameToType(data?.name),
    })}
    defaultOptions={{
      isTrimStr: true,
      isGetConfig: true,
      prefixUrl: PREFIX_URL_API,
    }}
    header={`${i18next.t('button.edit')} ${formatLabelTable(
      LABEL_TABLE_KEY.ageGroup,
    )}`}
  >
    <Form isEdit />
  </Edit>
);

export default ServiceAgeGroupsEdit;
