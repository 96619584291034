import { createSelector } from 'reselect';
import { CRUDSelectors } from '../crudCreator/selectors';
import { MODEL_NAME } from './actions';

const getResourceDataCentresOfUser = (state) =>
  state.users.centres?.resourceData;

const getCentresOfUserLoading = (state) => state.users.centres?.loading;

export const enabledLoadMoreCentresOfUserSelector = createSelector(
  [getCentresOfUserLoading, getResourceDataCentresOfUser],
  (loading, resources) => {
    const { offset, limit, numberOfPages } = resources || {};

    return !loading && offset / limit + 1 < numberOfPages;
  },
);

export const usersSelectors = new CRUDSelectors(MODEL_NAME);
