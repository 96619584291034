import i18next from 'i18next';
import { isEmpty } from 'lodash-es';

export const validateRegex = {
  phone: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/gs,
  password: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*\d).{6,}$/g,
  username: /^(?=.{3,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+([_.])$/g,
  editBookingId: '#bookings/(.*)/edit',
  fullName: /^[a-z0-9 ]{3,100}$/iu,
  environmentVariable: /^([a-z0-9A-Z_-]{1,100})$/g,
  keyID: /^([a-z0-9A-Z_-]{1,255})$/g,
  postCode: /^[0-9]{4}$/,
  SENumber: /^\SE-[0-9]{8}$/,
  PRNumber: /^\PR-[0-9]{8}$/,
  url: /(https?:\/\/[^\s]+)/g,
  website:
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
  youtube:
    /(?:http(?:s)?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:(?:watch)?\?(?:.*&)?v(?:i)?=|(?:embed|v|vi|user)\/))([^\\?&\\"'<> #]+)/,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  link: /^(?:http(?:s)?:\/\/)?(?:www\.)?(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/,
  ACNNumber: /^(([0-9])\s?){9}$/,
  ABNNumber: /^(([0-9])\s?){11}$/,
  onlyNumber: /^[0-9]+$|^$/,
};

export const handleApiError = (error, form) => {
  if (form && !isEmpty(error?.errors)) {
    const fieldData = Object.entries(error?.errors).map(([key, value]) => ({
      name: key,
      errors: [value],
    }));
    form.setFields(fieldData);
    form.scrollToField(fieldData[0]?.name);
    form.getFieldInstance(fieldData[0]?.name).focus();
  }
};

export const getConfirmVideoURLRules = () => [
  () => ({
    validator(_, value) {
      if (!value || validateRegex.youtube.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(i18next.t('urlVideo.validateMsg')));
    },
  }),
];

export const getDiscountNumberRules = (
  integerValidateMsg,
  inputValidateMsg,
  title,
) => [
  () => ({
    validator(_, value) {
      if (value === null) {
        return Promise.resolve();
      }
      if (!Number?.isInteger(value)) {
        return Promise.reject(
          new Error(i18next.t(integerValidateMsg, { title: i18next.t(title) })),
        );
      }
      if (value < 0 || value > 100) {
        return Promise.reject(
          new Error(i18next.t(inputValidateMsg, { title: i18next.t(title) })),
        );
      }
      return Promise.resolve();
    },
  }),
];
