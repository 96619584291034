import React from 'react';
import PropTypes from 'prop-types';
import { getRecordData } from 'utils/tools';

const RestFieldItem = ({
  record,
  source,
  format,
  formatSubmitData,
  valueProp,
  component,
  onChangeRecord,
  customOnChange,
}) => {
  const element = React.cloneElement(component, {
    record,
    [valueProp]: format(getRecordData(record, source), record),
    onChange: (value) =>
      customOnChange
        ? customOnChange(formatSubmitData(value), record)
        : onChangeRecord(formatSubmitData(value)),
  });
  return element;
};
RestFieldItem.propTypes = {
  record: PropTypes.any,
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  format: PropTypes.func,
  formatSubmitData: PropTypes.func,
  onChangeRecord: PropTypes.func,
  filterKey: PropTypes.string,
};

RestFieldItem.defaultProps = {
  format: (data) => data,
  formatSubmitData: (data) => data,
  onChangeRecord: () => {},
  component: <span />,
  valueProp: 'children',
};

export default RestFieldItem;
