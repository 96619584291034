import { makeActions } from 'redux/crudCreator/actions';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiWrapper } from 'utils/reduxUtils';
import {
  getSummariesCentresOfProviderApi,
  getSummariesProviderApi,
  getCentreIdsOfProviderApi,
} from 'api/providers';
import { convertRequestParams } from 'redux/crudCreator/dataProvider';
import { pick } from 'lodash-es';

export const MODEL_NAME = 'providers';
export const providersActions = makeActions(MODEL_NAME);

export const getAllProviders = providersActions.getAll;
export const editProviders = providersActions.edit;
export const createProviders = providersActions.create;
export const getByIdProviders = providersActions.getDataById;

export const getSummariesProvider = createAsyncThunk(
  'providers/getSummariesProvider',
  async (payload = {}, thunkAPI) => {
    try {
      const response = await apiWrapper({}, getSummariesProviderApi, payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getSummariesCentresOfProvider = createAsyncThunk(
  'centres/getSummariesCentresOfProvider',
  async (payload = {}, thunkApi) => {
    try {
      const { id } = payload;

      const convertRequest = convertRequestParams(
        'GET_ALL',
        pick(payload.data, ['filter']),
        'centres',
        {
          isOrderBy: false,
        },
      );

      const response = await apiWrapper(
        {},
        getSummariesCentresOfProviderApi,
        id,
        convertRequest,
      );

      return response;
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  },
);

export const getCentreIdsOfProvider = createAsyncThunk(
  'providers/getCentreIdsOfProvider',
  async (payload = {}, thunkAPI) => {
    try {
      const response = await apiWrapper({}, getCentreIdsOfProviderApi, payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
