import styled from 'styled-components';

export const DueDateWrapper = styled.div`
  display: flex;
  align-items: center;
  .due-date-label {
    line-height: normal;
  }

  cursor: pointer;
  .date-expired {
    color: ${({ theme }) => theme.color.red};
  }
  .clear-due-date {
    margin-right: 5px;
    font-size: 14px;
    color: #1890ff;
  }
  .calender-due-date-icon {
    font-size: 20px;
    color: #979797;
  }
`;

export const DueDateOverlayWrapper = styled.div`
  width: 300px;
  .footer-due-date {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .ant-btn {
    min-width: 40px;
  }
`;
