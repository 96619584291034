import React from 'react';
import PublicLayout from '../../layout/PublicLayout';
import InviteForm from '../../containers/Invite';
import InviteWrapper from './styles';

const Register = () => (
  <PublicLayout>
    <InviteWrapper>
      <InviteForm />
    </InviteWrapper>
  </PublicLayout>
);

export default Register;
