import React from 'react';
import Create from 'containers/rest/Create';
import { PREFIX_URL } from 'configs/localData/constant';
import Form from '../components/Form';

const BrandsCreate = (props) => (
  <Create
    {...props}
    defaultOptions={{ prefixUrl: PREFIX_URL.superAdmin }}
    resource="brands"
  >
    <Form />
  </Create>
);

export default BrandsCreate;
