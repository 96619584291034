import React from 'react';
import PropTypes from 'prop-types';
import Radio from 'antd/lib/radio';
import i18next from 'i18next';
import { CentreIcon, ProviderIcon } from 'components/common/SVGIcon';

const SwitcherAction = ({ retrieveList, setIsCentreLevel }) => {
  const onChange = (e) => {
    setIsCentreLevel(e.target.value);
    retrieveList({
      filter: {
        centreId: {
          $exists: e.target.value,
        },
      },
    });
  };

  return (
    <div className="switcher-actions">
      <Radio.Group defaultValue={false} size="small" onChange={onChange}>
        <Radio.Button value={false}>
          <ProviderIcon />
          {i18next.t('common.providerLevel')}
        </Radio.Button>
        <Radio.Button value>
          <CentreIcon />
          {i18next.t('common.centreLevel')}
        </Radio.Button>
      </Radio.Group>
    </div>
  );
};

SwitcherAction.propTypes = {
  retrieveList: PropTypes.func,
  setIsCentreLevel: PropTypes.func,
};

export default SwitcherAction;
