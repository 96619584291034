import React from 'react';
import { Row, Col } from 'antd';
import i18next from 'i18next';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import { ACTIVE_TYPES } from 'configs/localData';

const Filter = () => (
  <div>
    <Row gutter={16}>
      <Col xl={12} lg={12} md={12} xs={24}>
        <RestInputItem
          source={['name', '$like']}
          placeholder="common.name"
          isShowTooltip
        />
      </Col>
      <Col xl={12} lg={12} md={12} xs={24}>
        <RestSelect
          isShowTooltip
          isShowSearch={false}
          source="isActive"
          placeholder="common.isActive"
          resourceData={ACTIVE_TYPES}
          valueProp="value"
          titleProp="text"
          formatText={(data) => i18next.t(data)}
          ruleType="boolean"
        />
      </Col>
    </Row>
  </div>
);

export default Filter;
