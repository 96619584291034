import { EVENT_STATISTIC_COLORS } from 'configs/localData/color';
import moment from 'moment';
import { createSelector } from 'reselect';

const getRawEventLineChartData = (state) => state.statistic.eventLineChart.data;

export const getEventLineChartSelector = createSelector(
  [getRawEventLineChartData],
  (data) => {
    if (!data?.eventName?.length) return { data: [], eventTypesLines: [] };

    return {
      data: data?.data?.map((item) => ({
        ...item,
        name: moment(item.time).format('DD MMM'),
      })),

      eventTypesLines: data?.eventName?.map((name, idx) => ({
        value: name,
        fillColor: EVENT_STATISTIC_COLORS[idx] || '#f2f2f2',
      })),
    };
  },
);
