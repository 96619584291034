import React from 'react';
import i18next from 'i18next';
import kindiLogo from 'assets/images/kindi-logo.png';
import { DownOutlined } from '@ant-design/icons';

const HeaderNotifyAndroid = () => (
  <div className="notify-preview__noti-header notify-preview__noti-header-android">
    <img className="app-logo" alt="kindi" src={kindiLogo} />
    <span className="app-name">{i18next.t('appInfo.name')}</span>
    <span className="dot" />
    <span className="noti-time">{i18next.t('common.now')}</span>
    <DownOutlined />
  </div>
);

export default HeaderNotifyAndroid;
