import styled from 'styled-components';

const LoginStyles = styled.div`
  .ant-form-item {
    margin-bottom: 15px !important;
  }
  .action-div {
    margin-top: 30px;
  }
  .div-forgot-password {
    margin-top: 20px;
    fontsize: 20px;
    width: 180px;
    height: 23px;
    textalign: left;
    a {
      font-size: 20px;
    }
  }
`;

export default LoginStyles;
