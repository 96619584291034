import React from 'react';
import PropTypes from 'prop-types';
import MobilePreviewIframeStyles from './styles';

const MobilePreviewIframe = ({ srcDoc }) => {
  const overrideStyle = `
  <link
    href="https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"
    rel="stylesheet">
  <style type="text/css">
    body {
      font-family: 'Mulish';
    }
    iframe {
      border: none;
    }
    iframe, img {
      max-width: 100%;
    }
    img {
      object-fit: contain;
      height: auto;
    }
  </style>
  `;

  return (
    <MobilePreviewIframeStyles className="preview-mobile">
      <div className="preview-mobile-content">
        <iframe
          id="preview-mobile-iframe"
          data-amp="false"
          data-device="mobile"
          title="Preview (mobile)"
          srcDoc={`${overrideStyle} ${srcDoc || ''}`}
        />
      </div>
    </MobilePreviewIframeStyles>
  );
};

MobilePreviewIframe.propTypes = {
  srcDoc: PropTypes.string,
};

export default MobilePreviewIframe;
