import { PREFIX_API_VER_1 } from 'configs/localData/constant';
import i18next from 'i18next';
import qs from 'qs';
import { handleRequestError } from './sentry';

const checkIfErrorOccurs = (res) => ({
  code: res.status,
  res,
});

const TIME_OUT = 30000;

async function customFetch(path, headerOptions) {
  const normalFetch = fetch(path, headerOptions);
  const res = await timeoutPromise(
    TIME_OUT,
    normalFetch.then(checkIfErrorOccurs).catch(checkIfErrorOccurs),
  );

  if (!res.code) {
    const error = {
      code: 503,
      message: i18next.t('error.error503'),
    };
    throw error;
  }

  if (res.code < 300) {
    const response = await res.res.json();
    return response;
  }
  try {
    const response = await res.res.json();
    const error = {
      code: res.code,
      ...response,
    };
    throw error;
  } catch (e) {
    if (res.code === 426) {
      const error = {
        code: res.code,
        message:
          'We have had some significant upgrades for the app. Please click below to upgrade your app!',
        ...e,
      };
      throw error;
    } else {
      const error = {
        code: res.code,
        message: 'Something wrong. Please try again.',
        ...e,
      };

      handleRequestError({ error, apiUrl: path, payload: headerOptions.body });

      throw error;
    }
  }
}

export const timeoutPromise = (ms, promise) =>
  new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new Error('Request time out! Please try again.'));
    }, ms);
    promise.then(
      (res) => {
        clearTimeout(timeoutId);
        resolve(res);
      },
      (err) => {
        clearTimeout(timeoutId);
        reject(err);
      },
    );
  });

export default customFetch;

function requestWrapper(method) {
  const request = async (
    url,
    data = null,
    params = {},
    prefixUrl = 'admin',
    apiVersion = PREFIX_API_VER_1,
    options = { isDataQuery: false },
  ) => {
    let convertUrl = `${process.env.REACT_APP_BASE_API_URL}/${apiVersion}/${prefixUrl}${url}`;

    let convertData = data;

    if (method === 'GET' || options?.isDataQuery) {
      if (data !== null) {
        convertUrl = `${convertUrl}?${
          apiVersion === PREFIX_API_VER_1
            ? getQueryString(data)
            : qs.stringify(data)
        }`;
      }
      convertData = null;
    } else if (convertData === Object(convertData)) {
      convertData = JSON.stringify(convertData);
    }

    const defaults = {
      method,
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    };

    const token = localStorage.getItem('sessionToken');
    if (token) {
      defaults.headers.Authorization = `Bearer ${token}`;
    }

    if (!options?.isDataQuery && (method === 'POST' || method === 'PUT')) {
      defaults.headers.Accept = 'application/json';
      defaults.headers['Content-Type'] = 'application/json';
    }

    if (convertData) {
      defaults.body = convertData;
    }

    const paramsObj = {
      ...defaults,
      headers: { ...params, ...defaults.headers },
    };
    return customFetch(convertUrl, paramsObj);
  };
  return request;
}

export function getQueryString(params) {
  const esc = encodeURIComponent;
  return Object.keys(params)
    .filter((k) => params[k] || params[k] === 0)
    .map((k) => `${esc(k)}=${esc(params[k])}`)
    .join('&');
}

export const get = requestWrapper('GET');
export const post = requestWrapper('POST');
export const put = requestWrapper('PUT');
export const patch = requestWrapper('PATCH');
export const del = requestWrapper('DELETE');
