import { createSlice } from '@reduxjs/toolkit';
import { unionBy } from 'lodash-es';
import { getProvidersLevelOfCompany } from './actions';

export const initialState = {
  loading: false,
  data: [],
  resourceData: {
    filter: {},
    offset: 0,
    limit: 10,
    total: 0,
    numberOfPages: 1,
  },
};

const getProvidersLevelOfCompanyPending = (
  state,
  {
    meta: {
      arg: { data, options = {} },
    },
  },
) => {
  state.loading = true;

  if (options.isRefresh) {
    state.data = [];
    if (options.keepFilter) {
      state.resourceData = {
        ...state.resourceData,
        ...data,
        offset: 0,
      };
    } else {
      state.resourceData = {
        ...initialState.resourceData,
        ...data,
      };
    }
  } else {
    state.resourceData.offset =
      state.resourceData.offset + state.resourceData.limit || 0;
  }
};

const getProvidersLevelOfCompanySuccess = (state, { payload: { data } }) => {
  state.data = unionBy([...state.data, ...(data.results || [])], 'id');

  state.resourceData.total = data.total || 0;
  state.resourceData.numberOfPages = data.numberOfPages;

  state.loading = false;
  state.error = null;
};

const getProvidersLevelOfCompanyFail = (state, { payload: { data } }) => {
  state.error = data;
  state.loading = false;
};

const { actions, reducer } = createSlice({
  name: 'providersLevelOfCompany',
  initialState,
  extraReducers: {
    [getProvidersLevelOfCompany.pending]: getProvidersLevelOfCompanyPending,
    [getProvidersLevelOfCompany.fulfilled]: getProvidersLevelOfCompanySuccess,
    [getProvidersLevelOfCompany.rejected]: getProvidersLevelOfCompanyFail,
  },
});

export const {
  resetResourceFilterProvidersOfCompany,
  editProviderOfCompanySuccess,
} = actions;

export default reducer;
