import React from 'react';
import PropTypes from 'prop-types';
import SearchInput from './SearchInput';
import { FilterHeaderStyles } from './styles';

const Header = ({ getListAndSummary }) => (
  <FilterHeaderStyles>
    <SearchInput getListAndSummary={getListAndSummary} />
  </FilterHeaderStyles>
);

Header.propTypes = {
  getListAndSummary: PropTypes.func,
};

export default Header;
