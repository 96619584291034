import styled from 'styled-components';

export const FormItemWrapper = styled.div`
  .ant-form-item-label {
    font-weight: bold;
    label:after {
      content: '';
      display: inline-block;
    }
  }
`;
