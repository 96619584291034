import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import i18next from 'i18next';
import ViewModeSwitcherStyles from './styles';
import { LayoutListIcon, LayoutBoardIcon } from '../SVGIcon';

const ViewModeSwitcher = ({ onChange, activeTab }) => (
  <ViewModeSwitcherStyles>
    <Radio.Group
      onChange={onChange}
      style={{ marginBottom: 16 }}
      value={activeTab}
      className="group-tab"
    >
      <Radio.Button value="board">
        <div className="title-tab">
          <LayoutBoardIcon className="icon-tab" />
          {i18next.t('text.board')}
        </div>
      </Radio.Button>
      <Radio.Button value="list">
        <div className="title-tab">
          <LayoutListIcon className="icon-tab" />
          {i18next.t('text.list')}
        </div>
      </Radio.Button>
    </Radio.Group>
  </ViewModeSwitcherStyles>
);

ViewModeSwitcher.propTypes = {
  onChange: PropTypes.func,
  activeTab: PropTypes.string,
};

export default ViewModeSwitcher;
