import React from 'react';
import PublicLayout from '../../layout/PublicLayout';
import ForgotPasswordContainer from '../../containers/ForgotPassword';

const ForgotPassword = () => (
  <PublicLayout>
    <ForgotPasswordContainer />
  </PublicLayout>
);

export default ForgotPassword;
