import React from 'react';
import { ModalWrapper } from './styles';

const Modal = (props) => (
  <ModalWrapper
    width={500}
    closable={false}
    maskClosable={false}
    {...props}
    destroyOnClose
  />
);

export default Modal;
