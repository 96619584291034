import React from 'react';
import RestShow from 'containers/rest/Show';
import RestFieldItem from 'components/RestField/RestFieldItem';

const NQSRTypesShow = (props) => (
  <RestShow {...props} hasEdit resource="NQSRTypes">
    <RestFieldItem source="name" header="NQSRTypes.name" />
  </RestShow>
);

export default NQSRTypesShow;
