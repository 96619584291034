import React from 'react';
import { Comment, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import ReadMore from 'components/common/ReadMore';
import HeaderComment from '../ReviewsSection/HeaderComment';

import { CommentItemStyles } from '../ReviewsSection/styles';

const ReplyItem = ({ item, logoUrl, centreName }) => (
  <CommentItemStyles>
    <Comment
      author={
        <HeaderComment
          authorName={centreName}
          createdAt={item.createdAt}
          isShowRate={false}
          isShowLogo={false}
        />
      }
      avatar={<Avatar size={40} src={logoUrl} alt="" icon={<UserOutlined />} />}
      content={
        <p className="comment-message">
          <ReadMore text={item.comment} />
        </p>
      }
    />
  </CommentItemStyles>
);

ReplyItem.propTypes = {
  item: PropTypes.object,
  centreName: PropTypes.string,
  logoUrl: PropTypes.string,
};

export default ReplyItem;
