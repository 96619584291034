import { SearchOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { FilterFillIcon } from 'components/common/SVGIcon';
import TableCustom from 'components/common/TableCustom';
import { PREFIX_URL } from 'configs/localData/constant';
import { onChangeTable } from 'containers/Providers/utils';
import ProviderColumnFilter from 'containers/Users/components/Form/ProviderColumnFilter';
import useDataFilterActivityTask from 'hooks/useDataFilterActivityTask';
import i18next from 'i18next';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { getProvidersLevelOfCompany } from 'redux/providersLevelOfCompany/actions';
import {
  getDataProvidersLevelOfCompany,
  getLoadingProvidersLevelOfCompany,
  getResourceDataProvidersLevelOfCompany,
} from 'redux/providersLevelOfCompany/selector';
import { formatActivityProviderType } from 'utils/formatFieldUtils';
import { formatDateTime } from 'utils/textUtils';
import GroupActions from '../../GroupActions';

const ActivitiesNotesTable = () => {
  const data = useSelector(getDataProvidersLevelOfCompany);
  const loading = useSelector(getLoadingProvidersLevelOfCompany);
  const resourceFilter = useSelector(getResourceDataProvidersLevelOfCompany);

  const dispatch = useDispatch();

  const { push } = useHistory();

  const { id } = useParams();

  const retrieveList = (filter = {}) => {
    dispatch(
      getProvidersLevelOfCompany({
        data: {
          ...filter,
          filter: {
            companyId: id,
            providerIds: 'all',
            ...filter.filter,
          },
        },
        options: { prefixUrl: PREFIX_URL.superAdmin, isRefresh: true },
      }),
    );
  };

  useEffect(() => {
    retrieveList();
  }, [id]); // eslint-disable-line

  const { activityProviderTypeFilter } = useDataFilterActivityTask();

  const directionProviderDetail = (providerId) => {
    push(`/providers/${providerId}/show/summary`);
  };

  const columns = [
    {
      title: i18next.t('centres.providerName'),
      dataIndex: 'providerName',
      width: 200,
      sorter: true,
      filterIcon: <SearchOutlined />,
      render: (data, record) => (
        <Typography.Link
          onClick={() => directionProviderDetail(record?.provider?.id)}
          className="link-custom-primary"
        >
          <span>{record?.provider?.name}</span>
        </Typography.Link>
      ),
      filterDropdown: () => (
        <ProviderColumnFilter
          retrieveList={retrieveList}
          placeholder={i18next.t('providers.searchProviderName')}
          source="providerName"
        />
      ),
    },
    {
      title: i18next.t('activitiesAndNotes.activitiesNoteSubject'),
      dataIndex: 'subject',
      width: 200,
    },
    {
      title: i18next.t('activitiesAndNotes.createdBy'),
      dataIndex: 'createdByUser',
      render: (data) => `${data?.userName}`,
      filterIcon: <SearchOutlined />,
      filterDropdown: () => (
        <ProviderColumnFilter
          retrieveList={retrieveList}
          placeholder={i18next.t('providers.searchCreatedBy')}
          source="createdBy"
        />
      ),
    },
    {
      title: i18next.t('tasks.assignee'),
      dataIndex: 'assigneeUser',
      render: (data) => `${data?.firstName || ''} ${data?.lastName || ''}`,
      filterIcon: <SearchOutlined />,
      filterDropdown: () => (
        <ProviderColumnFilter
          retrieveList={retrieveList}
          placeholder={i18next.t('providers.searchAssignee')}
          source="assigneeUser"
        />
      ),
    },
    {
      title: i18next.t('activitiesAndNotes.activityType'),
      dataIndex: 'type',
      render: formatActivityProviderType,
      filters: activityProviderTypeFilter,
      filterIcon: <FilterFillIcon />,
    },
    {
      title: i18next.t('activitiesAndNotes.activityDate'),
      dataIndex: 'dueDate',
      render: formatDateTime,
      sorter: true,
    },
    {
      title: '',
      dataIndex: 'id',
      fixed: 'right',
      render: (id, record) => <GroupActions id={id} record={record} />,
    },
  ];

  const onChange = (e, filters, sorter) => {
    onChangeTable(e, filters, sorter, retrieveList);
  };

  return (
    <TableCustom
      isSorter
      isResetStyle
      xScroll={1000}
      onChange={onChange}
      columns={columns}
      loading={loading}
      data={data}
      pagination={{
        pageSize: resourceFilter.limit,
        current: resourceFilter.offset / resourceFilter.limit + 1 || 1,
        total: resourceFilter.count,
      }}
    />
  );
};

export default ActivitiesNotesTable;
