import React from 'react';
import Create from 'containers/rest/Create';
import { PREFIX_URL } from 'configs/localData/constant';
import i18next from 'i18next';
import Form from '../components/Form';
import { CreateEditStyles } from '../styles';

const UsersCreate = (props) => {
  const breadcrumbCustom = [
    {
      title: i18next.t('users.header'),
      path: '/users',
    },
    {
      title: i18next.t('users.createPage'),
    },
  ];

  return (
    <CreateEditStyles>
      <Create
        {...props}
        resource="users"
        breadcrumbCustom={breadcrumbCustom}
        defaultOptions={{
          prefixUrl: PREFIX_URL.superAdmin,
        }}
        isResetStyle
      >
        <div>
          <div className="create-user-title">
            {i18next.t('users.addUserInformation')}
          </div>
          <Form />
        </div>
      </Create>
    </CreateEditStyles>
  );
};

export default UsersCreate;
