import React from 'react';
import RestInputItem from 'components/RestInput/RestInputItem';
import { Col, Row } from 'antd';
import RestSelect from 'components/RestInput/RestSelect';
import i18next from 'i18next';
import { PREFIX_URL_API } from 'configs/localData/constant';
import ReferenceInput from 'containers/rest/ReferenceInput';
import RestRangePickerInput from 'components/RestInput/RestRangePickerInput';
import { useSelector } from 'react-redux';
import { getSaleStages } from 'redux/config/selectors';
import { ACTIVE_TYPES } from 'configs/localData';

const Filter = () => {
  const saleStagesConfig = useSelector(getSaleStages);
  return (
    <Row gutter={16}>
      <Col lg={6} md={12} xs={12}>
        <RestInputItem
          source={['name', '$like']}
          placeholder="providers.providerName"
          isShowTooltip
        />
      </Col>
      <Col lg={6} md={12} xs={12}>
        <RestInputItem
          source={['governmentReferenceId', '$like']}
          placeholder="centres.PRCode"
          isShowTooltip
        />
      </Col>
      <Col lg={6} md={12} xs={12}>
        <RestInputItem
          source={['email', '$like']}
          placeholder="users.email"
          isShowTooltip
        />
      </Col>
      <Col lg={6} md={12} xs={12}>
        <RestInputItem
          source={['phone', '$like']}
          placeholder="providers.phone"
          isShowTooltip
        />
      </Col>
      <Col lg={6} md={12} xs={12}>
        <ReferenceInput
          prefixUrl={PREFIX_URL_API}
          resource="companies"
          source="companyId"
          searchKey="company.name"
        >
          <RestSelect
            source="companyName"
            placeholder="companies.company"
            isShowTooltip
            titleProp="name"
            valueProp="id"
            isFilterOption={false}
            formatText={(text) => i18next.t(text)}
          />
        </ReferenceInput>
      </Col>
      <Col lg={6} md={12} xs={12}>
        <RestSelect
          source="salesStages"
          resourceData={saleStagesConfig}
          placeholder="companies.saleStage"
          isShowTooltip
          titleProp="name"
          valueProp="id"
          optionFilterProp="name"
          isFilterOption
        />
      </Col>
      <Col lg={6} md={12} xs={12}>
        <RestSelect
          source="isActiveOnACECQA"
          resourceData={ACTIVE_TYPES}
          placeholder="centres.acecqa"
          valueProp="value"
          titleProp="text"
          formatText={(data) => i18next.t(data)}
          ruleType="boolean"
          isShowTooltip
          isShowSearch={false}
        />
      </Col>
      <Col lg={6} md={12} xs={12}>
        <RestRangePickerInput
          source="lastContact"
          placeholder="common.time"
          isShowTooltip
          sourceGt="lastContact.$gt"
          sourceLt="lastContact.$lt"
        />
      </Col>
    </Row>
  );
};

export default Filter;
