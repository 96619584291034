import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import i18n from 'i18next';
import RestInputItem from '../../../../components/RestInput/RestInputItem/index';
import { PREFIX_URL_API } from '../../../../configs/localData/constant';
import RestSelect from '../../../../components/RestInput/RestSelect';
import ReferenceInput from '../../../rest/ReferenceInput';
import { CustomUpload } from '../Upload';
import RestEditor from '../../../../components/RestInput/RestEditor';
import { usePreviewNotify } from '../PreviewNoti/context';

const Step1Form = ({ form, record }) => {
  const [checkRequireContent, setCheckRequireContent] = useState(true);

  const { setTitle, setDescription, setImage } = usePreviewNotify();

  const onChangeExternalUrl = (e) => {
    if (e.target.value) {
      return setCheckRequireContent(false);
    }
    return setCheckRequireContent(true);
  };

  const onChangeTitle = (e) => {
    setTitle(e.target.value);
  };

  const onChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  const onChangeUpload = (url) => {
    form.setFieldsValue({ image: url });
    setImage(url);
  };

  const onChangeInputImage = (e) => {
    setImage(e.target.value);
  };

  return (
    <div>
      <RestInputItem
        required
        isValidatorEmpty
        maxLength={40}
        source="title"
        header="notifications.title"
        onChange={onChangeTitle}
      />
      <RestInputItem
        required
        isValidatorEmpty
        maxLength={200}
        source="text"
        header="notifications.text"
        ContentComponent={Input.TextArea}
        onChange={onChangeDescription}
      />
      <RestInputItem
        source="externalUrl"
        header="notifications.externalUrl"
        placeholder="notifications.externalUrl"
        onChange={onChangeExternalUrl}
        rules={[
          {
            type: 'url',
            message: i18n.t('error.invalidUrl'),
          },
        ]}
      />
      <RestEditor
        required={checkRequireContent}
        source="htmlContent"
        header="notifications.detail"
      />
      <ReferenceInput
        prefixUrl={PREFIX_URL_API}
        resource="notification-types"
        source="typeId"
      >
        <RestSelect
          required
          titleProp="name"
          valueProp="id"
          header="notifications.type"
          isFilterOption={false}
        />
      </ReferenceInput>
      <Form.Item
        name="image"
        label={i18n.t('notifications.image')}
        rules={[
          {
            type: 'url',
            message: i18n.t('error.invalidUrl'),
          },
        ]}
        initialValue={record?.image}
      >
        <Input
          placeholder={i18n.t('notifications.imagePlaceholder')}
          onChange={onChangeInputImage}
          suffix={<CustomUpload onChange={onChangeUpload} />}
        />
      </Form.Item>
    </div>
  );
};

Step1Form.propTypes = {
  form: PropTypes.object,
  record: PropTypes.object,
};

export default Step1Form;
