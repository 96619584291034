import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import { CENTRE_STATUS_VALUE } from 'configs/localData';
import ConfirmButton from 'components/RestActions/ConfirmButton';

import GroupActionsStyles from './styles';
import ConfirmClaimCentreModal from '../ConfirmClaimCentreModal';

const GroupActions = ({ handleConfirm, status, isAdminEmailUsed }) => {
  const confirmModalRef = useRef();

  const isShowDeclineApproveBtn = useMemo(
    () => !isAdminEmailUsed && status === CENTRE_STATUS_VALUE.inProcessing,
    [status, isAdminEmailUsed],
  );

  return (
    <GroupActionsStyles>
      {isShowDeclineApproveBtn && (
        <>
          <ConfirmButton
            title="button.decline"
            alertText="claimCentreRequests.alertDecline"
            confirm={() =>
              handleConfirm({ status: CENTRE_STATUS_VALUE.declined })
            }
            isShowText
            isShowTooltip={false}
            className="decline-btn"
          />
          <ConfirmButton
            title="button.approve"
            alertText="claimCentreRequests.alertApprove"
            confirm={() =>
              handleConfirm({ status: CENTRE_STATUS_VALUE.approved })
            }
            isShowText
            isShowTooltip={false}
            className="approve-btn"
          />
        </>
      )}
      <ConfirmClaimCentreModal
        ref={confirmModalRef}
        handleConfirm={handleConfirm}
      />
    </GroupActionsStyles>
  );
};

GroupActions.propTypes = {
  handleConfirm: PropTypes.func,
  status: PropTypes.string,
  isAdminEmailUsed: PropTypes.bool,
};

export default GroupActions;
