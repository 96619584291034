import React from 'react';
import { Col, Row } from 'antd';
import { useCentre } from 'containers/Centres/Show/context';
import PropTypes from 'prop-types';
import CentreInfo from '../CentreInfo';
import CentreTabs from '../CentreTabs';
import ListingStrengthCard from './ListingStrengthCard';
import CentreDetailStyles from './styles';

const CentreDetail = ({ record }) => {
  const { collapsed } = useCentre();

  return (
    <CentreDetailStyles>
      <Row gutter={24} className="row-customer-detail">
        <Col xl={6} lg={6} md={24} xs={24}>
          <CentreInfo record={record} />
        </Col>

        <Col xl={18} lg={18} md={24} xs={24}>
          <div
            className={`listing-strength__contact-col ${
              !collapsed ? 'listing-strength__contact-collapsed' : ''
            }`}
          >
            <ListingStrengthCard className="steps-content" record={record} />
          </div>
          <CentreTabs record={record} />
        </Col>
      </Row>
    </CentreDetailStyles>
  );
};

CentreDetail.propTypes = {
  record: PropTypes.object,
};

export default CentreDetail;
