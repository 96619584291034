import styled from 'styled-components';

export const FormStyles = styled.div`
  flex: 1;
  padding-right: 30px;
`;

export const DesignStyles = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  .box-design {
    flex: 1;
    > .ant-form {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .btn-submit {
      min-width: 150px;
    }
  }
`;
