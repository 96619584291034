import React from 'react';
import RestInputItem from 'components/RestInput/RestInputItem';
import { Col, Row } from 'antd';
import RestSelect from 'components/RestInput/RestSelect';
import i18next from 'i18next';
import { DOMAIN_TYPE, BLOCK_USER_TYPES, ACTIVE_TYPES } from 'configs/localData';
import RestRangePickerInput from 'components/RestInput/RestRangePickerInput';

const Filter = () => (
  <Row gutter={16}>
    <Col lg={4} md={12} xs={12}>
      <RestInputItem
        source={['outsideFilter', 'q']}
        placeholder="users.fullName"
        isShowTooltip
      />
    </Col>
    <Col lg={4} md={12} xs={12}>
      <RestInputItem
        source={['email', '$like']}
        placeholder="users.email"
        isShowTooltip
      />
    </Col>
    <Col lg={4} md={12} xs={12}>
      <RestSelect
        source="domain"
        placeholder="domainType.title"
        isShowTooltip
        resourceData={DOMAIN_TYPE}
        valueProp="value"
        titleProp="text"
        formatText={(text) => i18next.t(text)}
        isShowSearch={false}
      />
    </Col>
    <Col lg={4} md={12} xs={12}>
      <RestSelect
        source="isAllowedCRMApp"
        placeholder="users.CRMApp"
        resourceData={ACTIVE_TYPES}
        valueProp="value"
        titleProp="text"
        formatText={(text) => i18next.t(text)}
        isShowTooltip
        isShowSearch={false}
        ruleType="boolean"
      />
    </Col>
    <Col lg={4} md={12} xs={12}>
      <RestSelect
        source="isAccessInsightFeature"
        placeholder="users.InsightsModule"
        resourceData={ACTIVE_TYPES}
        valueProp="value"
        titleProp="text"
        formatText={(text) => i18next.t(text)}
        isShowTooltip
        isShowSearch={false}
        ruleType="boolean"
      />
    </Col>
    <Col lg={4} md={12} xs={12}>
      <RestSelect
        source="isActiveOnJobModule"
        placeholder="users.jobsModule"
        resourceData={ACTIVE_TYPES}
        valueProp="value"
        titleProp="text"
        formatText={(text) => i18next.t(text)}
        isShowTooltip
        isShowSearch={false}
        ruleType="boolean"
      />
    </Col>
    <Col lg={4} md={12} xs={12}>
      <RestSelect
        source="isBlocked"
        placeholder="users.blocked"
        resourceData={BLOCK_USER_TYPES}
        valueProp="value"
        titleProp="text"
        formatText={(text) => i18next.t(text)}
        isShowTooltip
        isShowSearch={false}
      />
    </Col>
    <Col lg={4} md={12} xs={12}>
      <RestRangePickerInput
        source="createdAt"
        placeholder="common.time"
        isShowTooltip
        sourceGt="createdAt.$gt"
        sourceLt="createdAt.$lt"
      />
    </Col>
  </Row>
);

export default Filter;
