import { Skeleton } from 'antd';
import Breadcrumb from 'components/common/Breadcrumb';
import PageTitle from 'components/common/PageTitle';
import { PREFIX_URL_API } from 'configs/localData/constant';
import i18next from 'i18next';
import { isEmpty } from 'lodash-es';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  clearCurrentNotification,
  editNotification,
  getByIdNotification,
} from 'redux/notifications/actions';
import Form from '../components/Form';

const NotificationsEdit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const currentData = useSelector((state) => state.notifications.currentData);

  const BREADCRUMB = [
    {
      path: '/notifications',
      title: i18next.t('notifications.header'),
    },
    {
      title: i18next.t('notifications.editPage'),
    },
  ];

  useEffect(() => {
    dispatch(
      getByIdNotification({
        data: { id },
        options: { prefixUrl: PREFIX_URL_API, isRequestApi: true },
      }),
    );
  }, [id]); // eslint-disable-line

  useEffect(
    () => () => {
      dispatch(clearCurrentNotification());
    },
    [dispatch],
  );

  const handleNotify = (data) => editNotification({ id, data });

  return (
    <div>
      <Breadcrumb data={BREADCRUMB} />
      <PageTitle>{i18next.t('notifications.editPage')}</PageTitle>
      <Skeleton active loading={isEmpty(currentData)}>
        <Form handleNotify={handleNotify} record={currentData} />
      </Skeleton>
    </div>
  );
};

export default NotificationsEdit;
