import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Progress, Button, Typography } from 'antd';
import i18next from 'i18next';
import { useHistory, useParams } from 'react-router';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import {
  CheckDoneIcon,
  EditListingStrengthIcon,
} from 'components/common/SVGIcon';
import { useDispatch } from 'react-redux';
import { getAllAutomatedResponses } from 'redux/automatedResponses/actions';
import { changeFinishedStep } from 'redux/centres/actions';
import { PREFIX_URL } from 'configs/localData/constant';
import SuccessListingStrengthModal from './SuccessListingStrengthModal';

const StepContent = ({ record, centreStep }) => {
  const dispatch = useDispatch();

  const [currentIndex, setCurrent] = useState(
    JSON.parse(localStorage.getItem('currentStep')) || 0,
  );

  localStorage.setItem('currentStep', currentIndex);

  const [loading, setLoading] = useState(false);

  const modalRef = useRef();

  const { push } = useHistory();

  const { id: centreId } = useParams();

  const [visible, setVisible] = useState(false);
  const lengthStepNotCompleteYet = centreStep?.filter(
    (item) => item?.percent !== 100,
  ).length;

  useEffect(() => {
    if (lengthStepNotCompleteYet === 0) {
      setVisible(true);
    }
  }, [lengthStepNotCompleteYet]);

  const onCancel = () => {
    setVisible(false);
  };

  const onClickNext = () => {
    setCurrent(currentIndex + 1);
  };

  const onClickPrev = () => {
    setCurrent(currentIndex - 1);
  };

  const { key, Component } = centreStep[currentIndex];

  const handleAction = useCallback(() => {
    if (key !== null) {
      if (key === 'automatedResponses') {
        push({
          pathname: `/centres/${centreId}/${key}`,
          state: {
            isUpdateStep11: true,
          },
        });
        dispatch(getAllAutomatedResponses({ id: centreId }));
      } else push(`/centres/${centreId}/show/${key}`);
    } else {
      modalRef.current && modalRef.current.toggleModal();
    }
  }, [push, centreId, currentIndex, modalRef.current, key]); // eslint-disable-line

  const onCompleted = () => {
    setLoading(true);
    dispatch(
      changeFinishedStep({
        centreId,
        data: { isFinishedStep: false },
        options: { prefixUrl: PREFIX_URL.superAdmin },
      }),
    )
      .then(({ payload }) => {
        if (payload?.data?.id) {
          onCancel();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (record?.brand?.logo) {
      setDisabled(true);
    }
  }, [record?.brand?.logo]); // eslint-disable-line

  return (
    <div className="content-step">
      <div className="flex-center-between">
        <div className="flex items-center">
          <div>
            <Progress
              type="circle"
              percent={centreStep[currentIndex].percent}
              width={50}
              format={(data) =>
                data === 100 ? <CheckDoneIcon /> : <EditListingStrengthIcon />
              }
            />
            <p className="text-center mt-4">
              {`${centreStep[currentIndex].percent}%`}
            </p>
          </div>

          <div className="ml-24">
            <div className="ml-12 content-step__title-info flex items-stretch mb-16 h-full">
              {`${i18next.t(centreStep[currentIndex]?.title)}: ${i18next.t(
                centreStep[currentIndex]?.description,
              )}`}
            </div>
            <div className="show-hide-btn mb-16">
              <Typography.Link
                onClick={onClickPrev}
                disabled={currentIndex === 0}
                className="show-hide-btn"
              >
                <LeftOutlined
                  className={`ml-12 ${
                    currentIndex === 0
                      ? 'show-hide-btn__previous'
                      : 'show-hide-btn__next'
                  }`}
                />
                <span
                  className={`ml-6 ${
                    currentIndex === 0
                      ? 'show-hide-btn__previous'
                      : 'show-hide-btn__next'
                  }`}
                >
                  {i18next.t('button.previous')}
                </span>
              </Typography.Link>
              <Typography.Link
                onClick={onClickNext}
                disabled={currentIndex === centreStep.length - 1}
                className="show-hide-btn ml-16"
              >
                <span
                  className={`ml-16 ${
                    currentIndex === centreStep.length - 1
                      ? 'show-hide-btn__previous'
                      : 'show-hide-btn__next'
                  }`}
                >
                  {i18next.t('button.next')}
                </span>
                <RightOutlined
                  className={`ml-6 ${
                    currentIndex === centreStep.length - 1
                      ? 'show-hide-btn__previous'
                      : 'show-hide-btn__next'
                  }`}
                />
              </Typography.Link>
            </div>
          </div>
        </div>
        <div>
          <Button
            disabled={disabled && centreStep[currentIndex].step === 4}
            className="btn-second-primary content-step__btn-update"
            onClick={handleAction}
          >
            {centreStep[currentIndex].step === 10
              ? i18next.t('button.checkItNow')
              : i18next.t('button.updateNow')}
          </Button>
        </div>
      </div>

      {!key && <Component ref={modalRef} record={record} />}
      {record?.isFinishedStep && (
        <SuccessListingStrengthModal
          visible={visible}
          onCancel={onCancel}
          onCompleted={onCompleted}
          loading={loading}
        />
      )}
    </div>
  );
};

StepContent.propTypes = {
  record: PropTypes.object,
  centreStep: PropTypes.array,
};

export default StepContent;
