import { makeActions } from 'redux/crudCreator/actions';

export const MODEL_NAME = 'serviceAgeGroups';
export const serviceAgeGroupsActions = makeActions(MODEL_NAME);

export const getAllServiceAgeGroups = serviceAgeGroupsActions.getAll;
export const editServiceAgeGroups = serviceAgeGroupsActions.edit;
export const createServiceAgeGroups = serviceAgeGroupsActions.create;
export const getByIdServiceAgeGroups = serviceAgeGroupsActions.getDataById;
export const delServiceAgeGroups = serviceAgeGroupsActions.del;
