import React, { createContext, useContext, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';

import { PREFIX_URL } from 'configs/localData/constant';
import { editCompanies } from 'redux/companies/actions';

const initialValue = {
  id: null,
  updateCompany: () => null,
};

const CompanyContext = createContext(initialValue);

export const Company = ({ children }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const updateCompany = useCallback(
    (values) =>
      new Promise((resolve) => {
        if (id)
          resolve(
            dispatch(
              editCompanies({
                data: { id, ...values },
                options: { prefixUrl: PREFIX_URL.superAdmin },
              }),
            ),
          );
      }),
    [id], // eslint-disable-line
  );

  return (
    <CompanyContext.Provider value={{ id, updateCompany }}>
      {children}
    </CompanyContext.Provider>
  );
};

Company.propTypes = {
  children: PropTypes.node,
};

export const useCompany = () => {
  const { id, updateCompany } = useContext(CompanyContext);

  return {
    id,
    updateCompany,
  };
};
