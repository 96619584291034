import React from 'react';
import Edit from 'containers/rest/Edit';
import { PREFIX_URL_API } from 'configs/localData/constant';
import Form from '../components/Form';
import { formatOnSubmit } from '../utils';

const MarketingsEdit = (props) => (
  <Edit
    {...props}
    defaultOptions={{ prefixUrl: PREFIX_URL_API }}
    resource="marketings"
    formatOnSubmit={formatOnSubmit}
  >
    <Form />
  </Edit>
);

export default MarketingsEdit;
