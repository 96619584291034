import styled from 'styled-components';

const ShowStyles = styled.div`
  .notification-detail-content {
    background: ${({ theme }) => theme.background.content};
    padding: 40px;
    border-radius: 8px;
    word-wrap: break-word;

    &__title {
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
      color: ${({ theme }) => theme.palette.primary};
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .notification-item + .notification-item {
    margin-top: 15px;
  }

  .notification-item {
    font-size: 16px;
    &__label {
      font-weight: bold;
      margin-bottom: 10px;
    }
    &__content-box {
      background: #f7f8f9;
      border-radius: 8px;
      padding: 20px 12px;
      margin-bottom: 20px;
    }
    &__value {
      white-space: pre-line;
    }
  }
  .notification-item-line {
    display: flex;
    .notification-item__label {
      &:after {
        content: ':';
      }
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
`;

export default ShowStyles;
