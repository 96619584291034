import React, { useCallback } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Row, Col, Divider, Tooltip } from 'antd';
import { formatMoney } from 'utils/tools';
import { formatTimeDisplay } from 'utils/textUtils';

import { SummaryDetailCardStyles, SummaryViewStyles } from './styles';
import { InfoIcon } from '../SVGIcon';

const SummaryView = ({ title, data, IconComponent, total, color }) => {
  const formatValue = useCallback(
    ({ value, isPerAnnum, isPercent, isTime }) => {
      if (isPercent) return `${formatMoney(value)}%`;

      if (isTime) return formatTimeDisplay(value);

      if (isPerAnnum)
        return `${i18next.t('currencyUnit.sign')}${formatMoney(
          value,
        )} ${i18next.t('common.perAnnum')}`;

      return formatMoney(value);
    },
    [],
  );

  return (
    <SummaryViewStyles>
      <Row className="title-card" gutter={10}>
        <Col span={18} className="title-card__left">
          {IconComponent && <IconComponent />}
          <span className="title-card__name">{i18next.t(title)}</span>
        </Col>
        <Col span={6} className="title-card__total" style={{ color }}>
          {total}
        </Col>
      </Row>
      <Divider />
      <SummaryDetailCardStyles>
        <Row className="content-info-card" gutter={[10, 20]}>
          {data?.map((item, index) => (
            <React.Fragment key={String(index)}>
              <Col span={18} className="col-title-item">
                <span className="text-name">{i18next.t(item.title)}</span>
                {item?.value > 0 && item?.isShowIconInfo && (
                  <Tooltip title={i18next.t('text.includeCentreComingSoon')}>
                    <InfoIcon className="mt-6 ml-6" />
                  </Tooltip>
                )}
              </Col>
              <Col span={6} className="col-value-item">
                {formatValue({
                  value: item.value,
                  isPerAnnum: item.isPerAnnum,
                  isPercent: item.isPercent,
                  isTime: item.isTime,
                })}
              </Col>
            </React.Fragment>
          ))}
        </Row>
      </SummaryDetailCardStyles>
    </SummaryViewStyles>
  );
};

SummaryView.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  IconComponent: PropTypes.any,
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
};

export default SummaryView;
