import { RestInputContext } from 'components/RestInput/RestInputContext';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  centresOfProviderSelectors,
  getLoadingCentresOfProvider,
  getResourceDataCentresOfProvider,
} from 'redux/centresOfProvider/selectors';
import ListCheckboxSelect from './ListCheckboxSelect';

const CentreListMultiSelect = ({ retrieveList }) => {
  const { record } = useContext(RestInputContext);
  const data = useSelector(centresOfProviderSelectors.getDataArr);
  const paging = useSelector(getResourceDataCentresOfProvider);
  const loading = useSelector(getLoadingCentresOfProvider);

  const defaultValue = useMemo(
    () => record?.centreIds || record?.centres?.map((item) => item?.id),
    [record],
  );

  return (
    <ListCheckboxSelect
      retrieveList={retrieveList}
      name="centreIds"
      defaultValue={defaultValue}
      data={data}
      paging={paging}
      loading={loading}
      selectedResourceTitles="users.selectedCentresTotal"
      selectedResourceTitle="users.selectedCentreTotal"
    />
  );
};

CentreListMultiSelect.propTypes = {
  retrieveList: PropTypes.func,
};

export default CentreListMultiSelect;
