import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import SwitcherAction from './SwitcherAction';

const HeaderList = ({
  title = 'activitiesAndNotes.title',
  openCreateModal,
  setValue,
  value,
}) => (
  <div className="section-header flex-center-between">
    <div className="flex items-center">
      <div className="section-header__title">{i18next.t(title)}</div>
      <SwitcherAction setValue={setValue} value={value} />
    </div>
    <Button
      className="btn-second-primary"
      icon={<PlusOutlined />}
      onClick={openCreateModal}
    >
      {i18next.t('button.create')}
    </Button>
  </div>
);

HeaderList.propTypes = {
  openCreateModal: PropTypes.func,
  title: PropTypes.string,
  setValue: PropTypes.func,
  value: PropTypes.string,
};

export default HeaderList;
