import React, { useImperativeHandle, forwardRef, useState } from 'react';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import Form, { useForm } from 'antd/lib/form/Form';

import { createEmailTemplate } from 'redux/EDM/actions';
import { handleApiError } from 'utils/validateUtils';

import ModalCustom from 'components/common/ModalCustom';
import CustomHtmlForm from './Form';

const CreateModal = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        setVisible(true);
      },
    }),
    [],
  );

  const [form] = useForm();

  const dispatch = useDispatch();

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  const onOk = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        return dispatch(
          createEmailTemplate({ data: values, options: { isGetAll: true } }),
        );
      })
      .then(({ payload }) => {
        setLoading(false);
        if (payload?.id) {
          onCancel();
        } else {
          handleApiError(payload, form);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <ModalCustom
      visible={visible}
      title={i18next.t('EDM.createModalCustomHtmlTitle')}
      onOk={onOk}
      onCancel={onCancel}
      width={1000}
      loading={loading}
    >
      <Form form={form} layout="vertical" scrollToFirstError>
        <CustomHtmlForm />
      </Form>
    </ModalCustom>
  );
});

export default CreateModal;
