import React from 'react';
import { isEmpty } from 'lodash-es';
import Edit from 'containers/rest/Edit';
import Form from '../components/Form';

const FeaturesEdit = (props) => (
  <Edit
    {...props}
    resource="features"
    formatOnSubmit={({ icon, ...data }) => ({
      ...data,
      ...(!isEmpty(icon) ? { icon: { key: icon.key, url: icon.url } } : null),
    })}
    defaultOptions={{ isTrimStr: true, isGetConfig: true }}
  >
    <Form isEdit />
  </Edit>
);

export default FeaturesEdit;
