import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Skeleton from 'antd/lib/skeleton';

import { useSelector } from 'react-redux';
import { getEventLineChartSelector } from 'redux/statistic/selectors';

import LineChartCustom from 'components/charts/LineChartCustom';
import LegendActions from './LegendActions';

/**
 *
 * eventTypes: selected type list
 * eventTypesLines: filter/search based on this list
 * eventTypesList: chart will be shown by this list
 *
 */
const ChartByTime = () => {
  const { data, eventTypesLines } = useSelector(getEventLineChartSelector);
  const loading = useSelector(
    (state) => state.statistic.eventLineChart.loading,
  );

  const [eventTypes, setEventTypes] = useState();
  const [eventTypesList, setEventTypesList] = useState();
  const [dataLines, setDataLines] = useState([]);

  useEffect(() => {
    setEventTypes(eventTypesLines?.map((item) => item?.value));
    setEventTypesList(
      eventTypesLines?.map((item, index) => ({
        ...item,
        index,
      })),
    );
  }, [eventTypesLines]);

  useEffect(() => {
    setDataLines([]);
    setTimeout(() => {
      setDataLines(
        eventTypesLines?.filter((item) => eventTypes?.includes(item.value)),
      );
    }, 50);
  }, [eventTypes, eventTypesLines]);

  return (
    <div className="chart-by-time-box">
      <Row gutter={24}>
        <Col xl={15} lg={24} md={24} xs={24} className="chart-col">
          <div className="chart-by-time-wrapper">
            <div className="chart-title">
              {i18next.t('events.chartByTimeTitle')}
            </div>
            <Skeleton active loading={loading}>
              <LineChartCustom
                data={data}
                dataLines={dataLines}
                lineOptions={{ type: 'linear' }}
                isShowLegend={false}
                legendTypeTooltip="line"
              />
            </Skeleton>
          </div>
        </Col>

        <Col xl={9} lg={24} md={24} xs={24} className="legend-col">
          <LegendActions
            eventTypes={eventTypes}
            setEventTypes={setEventTypes}
            eventTypesLines={eventTypesLines}
            eventTypesList={eventTypesList}
            setEventTypesList={setEventTypesList}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ChartByTime;
