import React, { useEffect } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { activitiesSelectors } from 'redux/activities/selectors';
import { formatActivityProviderType } from 'utils/formatFieldUtils';
import useDataFilterActivityTask from 'hooks/useDataFilterActivityTask';
import TableCustom from 'components/common/TableCustom';
import { FilterFillIcon } from 'components/common/SVGIcon';
import { onChangeTable } from 'containers/Providers/utils';
import { formatDateTime } from 'utils/textUtils';
import { PREFIX_URL } from 'configs/localData/constant';
import { useParams } from 'react-router';
import { getAllActivities } from 'redux/activities/actions';
import GroupActions from '../../GroupActions';

const ActivitiesNotesTable = () => {
  const resourceData = useSelector(activitiesSelectors.getDataArr);

  const loading = useSelector(activitiesSelectors.getLoading);

  const dispatch = useDispatch();

  const { id } = useParams();

  const resourceFilter = useSelector(activitiesSelectors.getFilters);

  const retrieveList = (filter = {}) => {
    dispatch(
      getAllActivities({
        data: {
          ...resourceFilter,
          ...filter,
          filter: {
            ...resourceFilter.filter,
            companyId: id,
            providerId: {
              $exists: false,
            },
            centreId: {
              $exists: false,
            },
            ...filter.filter,
          },
        },
        options: { prefixUrl: PREFIX_URL.superAdmin, isRefresh: true },
      }),
    );
  };

  useEffect(() => {
    retrieveList();
  }, [id]); // eslint-disable-line

  const { activityProviderTypeFilter } = useDataFilterActivityTask();

  const columns = [
    {
      title: i18next.t('activitiesAndNotes.activitiesNoteSubject'),
      dataIndex: 'subject',
    },
    {
      title: i18next.t('activitiesAndNotes.createdBy'),
      dataIndex: ['createdByUser', 'userName'],
    },
    {
      title: i18next.t('tasks.assignee'),
      dataIndex: 'assigneeUser',
      render: (data) => `${data?.firstName || ''} ${data?.lastName || ''}`,
    },
    {
      title: i18next.t('activitiesAndNotes.activityType'),
      dataIndex: 'type',
      render: formatActivityProviderType,
      filters: activityProviderTypeFilter,
      filterIcon: <FilterFillIcon />,
      width: 160,
    },
    {
      title: i18next.t('activitiesAndNotes.activityDate'),
      dataIndex: 'dueDate',
      render: formatDateTime,
      sorter: true,
      width: 160,
    },

    {
      title: '',
      dataIndex: 'id',
      width: 120,
      fixed: 'right',
      render: (id, record) => <GroupActions id={id} record={record} />,
    },
  ];

  const onChange = (e, filters, sorter) => {
    onChangeTable(e, filters, sorter, retrieveList);
  };

  return (
    <TableCustom
      isSorter
      isResetStyle
      xScroll={1000}
      onChange={onChange}
      data={resourceData}
      columns={columns}
      loading={loading}
      pagination={{
        pageSize: resourceFilter.limit,
        current: resourceFilter.offset / resourceFilter.limit + 1 || 1,
        total: resourceFilter.count,
      }}
    />
  );
};

export default ActivitiesNotesTable;
