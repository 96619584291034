import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  LoadingOutlined,
  ExclamationCircleOutlined,
  FileOutlined,
  CloseCircleFilled,
} from '@ant-design/icons';
import i18next from 'i18next';
import { isImageFile, getExtensionFile } from 'utils/fileUtils';
import { FILE_TYPES } from 'configs/localData';

import { getYoutubeEmbedURL } from 'utils/tools';
import { UploadImageItemStyles } from './styles';

const UploadImageItem = ({
  item,
  deleteImage,
  index,
  onlyShowImg,
  isSetFeature,
  onSetFeature,
  onPreviewImage,
  isAttachURLVideo,
}) => {
  const handleClick = useCallback(() => {
    onPreviewImage(item);
  }, [item, onPreviewImage]);

  const restFileItem = useMemo(
    () =>
      FILE_TYPES.find(
        (file) =>
          file.value.toLocaleLowerCase() ===
          getExtensionFile(item.url || item.response)?.toLocaleLowerCase(),
      ),
    [item.url, item.response],
  );

  const fileResult = useMemo(() => {
    if (isAttachURLVideo && item?.type?.includes('video')) {
      const urlVideo = getYoutubeEmbedURL(item?.url);
      return (
        <div
          className="iframe-video-wrapper"
          role="presentation"
          onClick={handleClick}
        >
          <iframe
            title="iframe-video-item"
            frameBorder="0"
            className="iframe-video"
            allowFullScreen="allowfullscreen"
            src={urlVideo}
          />
        </div>
      );
    }
    if (onlyShowImg) {
      return (
        <img
          className="image-upload-view image-only-show"
          src={item?.url || item?.response}
          alt=""
          onClick={handleClick}
          role="presentation"
        />
      );
    }

    return (typeof item?.type === 'string' && item.type?.includes('image')) ||
      isImageFile(item.url) ? (
      <img
        className="image-upload-view"
        src={item.url || item?.response}
        alt=""
      />
    ) : (
      <div className="icon-div-item">
        {restFileItem ? (
          <restFileItem.icon
            style={{
              color: restFileItem.color,
            }}
            className="icon-file"
          />
        ) : (
          <FileOutlined className="icon-file" />
        )}
      </div>
    );
    // eslint-disable-next-line
  }, [
    onlyShowImg,
    restFileItem,
    item.url,
    item.response,
    item.type,
    handleClick,
  ]);

  return (
    <UploadImageItemStyles className="uploadImage">
      {item.status === 'error' && (
        <ExclamationCircleOutlined className="icon-error" />
      )}
      {item.status !== 'error' && item.status !== 'done' && !item.url && (
        <div className="wrapper-loading">
          <LoadingOutlined className="icon-loading" />
        </div>
      )}
      {fileResult}
      <CloseCircleFilled
        className="delete-icon-image"
        onClick={() => deleteImage(index)}
      />
      {isSetFeature && !item.type?.includes('video') ? (
        <div
          role="presentation"
          onClick={() => onSetFeature(index)}
          className={`lbSetFeature  ${item.featured ? 'feature-active' : ''}`}
        >
          {i18next.t('button.setFeature')}
        </div>
      ) : null}
    </UploadImageItemStyles>
  );
};
UploadImageItem.propTypes = {
  item: PropTypes.object,
  deleteImage: PropTypes.func,
  index: PropTypes.number,
  onlyShowImg: PropTypes.bool,
  onSetFeature: PropTypes.func,
  isSetFeature: PropTypes.bool,
  onPreviewImage: PropTypes.func,
  isAttachURLVideo: PropTypes.bool,
};

UploadImageItem.defaultProps = {
  item: {},
};

export default UploadImageItem;
