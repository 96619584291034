import { Modal, Switch } from 'antd';
import CentresModal from 'components/CentresViewAll/CentresModal';
import { IncognitoIcon } from 'components/common/SVGIcon';
import CustomButtonIcon from 'components/form/CustomButtonIcon';
import ActionGroup from 'components/RestActions/ActionGroup';
import DeleteButton from 'components/RestActions/DeleteButton';
import EditButton from 'components/RestActions/EditButton';
import RestFieldItem from 'components/RestField/RestFieldItem';
import UserInfo from 'components/RestField/UserInfo';
import { USER_TYPES_CONSTANT } from 'configs/localData';
import { PREFIX_URL_API } from 'configs/localData/constant';
import List from 'containers/rest/List';
import { CreatedJobProfileWrapper } from 'containers/Users/styles';
import i18next from 'i18next';
import moment from 'moment';
import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getCentresOfUser } from 'redux/centresOfUser/actions';
import { editClientUsers } from 'redux/clientUsers/actions';
import { clearCurrentUser, getAuthorizeWebPortal } from 'redux/users/actions';
import { formatDateTimeTable } from 'utils/textUtils';
import Filter from '../ClientFilter';

const { confirm } = Modal;

const ClientUsersList = (props) => {
  const { push } = useHistory();
  const dispatch = useDispatch();

  const centresUserModalRef = useRef();

  const checkVisibleImpersonate = (record) =>
    record?.userType === USER_TYPES_CONSTANT.business.value;

  const formatFilter = useCallback(({ email, domain, createdAt, ...value }) => {
    let emailFilter;
    if (email.$like && !domain) {
      emailFilter = email;
    } else if (!email.$like && domain) {
      if (domain === 'internal') {
        emailFilter = {
          $or: [{ $like: '@kindicare.com' }, { $like: '@enouvo.com' }],
        };
      } else
        emailFilter = {
          $and: [{ $notlike: '@kindicare.com' }, { $notlike: '@enouvo.com' }],
        };
    } else if (email.$like && domain) {
      if (domain === 'internal') {
        emailFilter = {
          $or: [
            { $and: [email, { $like: '@kindicare.com' }] },
            { $and: [email, { $like: '@enouvo.com' }] },
          ],
        };
      } else {
        emailFilter = {
          $and: [
            { $and: [email, { $notlike: '@kindicare.com' }] },
            { $and: [email, { $notlike: '@enouvo.com' }] },
          ],
        };
      }
    }

    return {
      ...value,
      email: emailFilter,
      createdAt: createdAt
        ? {
            $gt: createdAt[0]
              ? moment(createdAt[0]).startOf('day').toISOString()
              : undefined,
            $lt: createdAt[1]
              ? moment(createdAt[1]).endOf('day').toISOString()
              : undefined,
          }
        : undefined,
    };
  }, []);

  const handleBlockUser = (value, record) => {
    Modal.confirm({
      title: i18next.t(
        record?.isBlocked
          ? 'users.unBlockUserConfirmTitle'
          : 'users.blockUserConfirmTitle',
      ),
      content: i18next.t(
        record?.isBlocked
          ? 'users.unlockUserConfirmContent'
          : 'users.blockUserConfirmContent',
      ),
      onOk: () =>
        dispatch(
          editClientUsers({
            data: { id: record?.id, isBlocked: value },
            options: { prefixUrl: PREFIX_URL_API },
          }),
        ),
    });
  };

  const handleRedirect = useCallback(
    async (record) => {
      await dispatch(getAuthorizeWebPortal({ userId: record.id })).then(
        ({ payload }) => {
          if (payload?.accessToken) {
            window.open(
              `${process.env.REACT_APP_CRM_WEB_URL}?authToken=${payload?.accessToken}`,
              '_blank',
            );
          }
        },
      );
    },
    [dispatch],
  );

  const handleImpersonating = (record) => {
    confirm({
      title: i18next.t('button.impersonate'),
      content: i18next.t('users.impersonateConfirmDesc'),
      onOk: () => {
        handleRedirect(record);
      },
    });
  };

  const handleClickView = (id) => {
    dispatch(clearCurrentUser());
    push(`/users/${id}/show`);
  };

  return (
    <div>
      <List
        {...props}
        resource="clientUsers"
        hasSearch={false}
        hasCreate={false}
        filter={<Filter format={formatFilter} />}
        defaultOptions={{ prefixUrl: PREFIX_URL_API }}
        widthTable="1400px"
        redirects={{
          edit: 'screen',
          create: 'screen',
        }}
      >
        <RestFieldItem
          source="firstName"
          header="users.name"
          format={(data, record) => (
            <UserInfo
              isLink={false}
              record={{
                ...record,
                fullName: `${data || ''} ${record?.lastName || ''}`,
              }}
              nameProp="fullName"
              roleProp="email"
            />
          )}
        />

        <RestFieldItem source="phoneNumber" header="centres.phoneNumber" />
        <RestFieldItem
          sorter
          source="lastActiveAt"
          header="common.lastActiveAt"
          format={formatDateTimeTable}
        />
        <RestFieldItem
          sorter
          source="createdAt"
          header="common.createdAt"
          format={formatDateTimeTable}
        />
        <RestFieldItem
          sorter
          source="isUpdated"
          header="users.createdJobProfile"
          format={(_, record) => (
            <CreatedJobProfileWrapper
              className="fw-bold border-none"
              textColor={record?.createdSeekerProfile?.color}
              backgroundColor={record?.createdSeekerProfile?.backgroundColor}
            >
              {record?.createdSeekerProfile?.text}
            </CreatedJobProfileWrapper>
          )}
        />
        <RestFieldItem
          source="isBlocked"
          header="users.blocked"
          valueProp="checked"
          component={<Switch />}
          customOnChange={handleBlockUser}
        />
        <ActionGroup widthAction={160}>
          <CustomButtonIcon
            checkVisibleButton={checkVisibleImpersonate}
            title="button.impersonate"
            IconComponent={IncognitoIcon}
            handleClick={handleImpersonating}
          />
          <EditButton isView onClickCustom={handleClickView} />
          <DeleteButton customMessage="users.customDescDelete" />
        </ActionGroup>
      </List>

      <CentresModal
        ref={centresUserModalRef}
        title="users.centresUserModalTitle"
        resource="centresOfUser"
        retrieveListAction={getCentresOfUser}
      />
    </div>
  );
};

export default ClientUsersList;
