import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import EditButton from 'components/RestActions/EditButton';
import { useHistory, useParams } from 'react-router';
import CustomButtonIcon from 'components/form/CustomButtonIcon';
import { IncognitoIcon } from 'components/common/SVGIcon';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Tooltip } from 'antd';
import {
  checkLastUser,
  getAuthorizeWebPortal,
  unSubcriptionCentre,
} from 'redux/users/actions';
import { PREFIX_URL } from 'configs/localData/constant';
import { getCentreIdsOfProvider } from 'redux/providers/actions';
import { ButtonWrapper } from 'components/RestActions/DeleteButton/styles';
import { DeleteOutlined } from '@ant-design/icons';
import { confirmDeleteLastUser } from 'containers/Company/components/UserTab/GroupActionsUser';
import { delUserOfProvider } from 'redux/usersOfProvider/actions';

const GroupActionsUser = ({ userId, record }) => {
  const { push } = useHistory();

  const dispatch = useDispatch();

  const currentProvider = useSelector((state) => state.providers?.currentData);

  const { id: providerId } = useParams();

  const handleClickView = () => {
    push(`/users/${userId}/show`);
  };

  const handleRedirect = useCallback(async () => {
    await dispatch(getAuthorizeWebPortal({ userId })).then(({ payload }) => {
      if (payload?.accessToken) {
        window.open(
          `${process.env.REACT_APP_CRM_WEB_URL}?authToken=${payload?.accessToken}`,
          '_blank',
        );
      }
    });
  }, []); // eslint-disable-line

  function handleImpersonating() {
    Modal.confirm({
      title: i18next.t('button.impersonate'),
      content: i18next.t('users.impersonateConfirmDesc'),
      onOk: () => {
        handleRedirect();
      },
    });
  }

  const onCancel = () => {};

  const unSubcription = () => () => {
    dispatch(getCentreIdsOfProvider(providerId)).then(({ payload }) => {
      payload && dispatch(unSubcriptionCentre({ centreIds: payload }));
    });
  };

  const deleteUserItem = () =>
    dispatch(
      delUserOfProvider({
        id: providerId,
        userId,
        options: {
          prefixUrl: PREFIX_URL.superAdmin,
          isRefresh: true,
        },
      }),
    );

  const deleteUserUnsubscription = () => {
    dispatch(
      delUserOfProvider({
        id: providerId,
        userId,
        options: {
          prefixUrl: PREFIX_URL.superAdmin,
          isRefresh: true,
        },
      }),
    ).then(({ payload }) => {
      payload?.success &&
        confirmDeleteLastUser(
          onCancel,
          currentProvider,
          'usersOfProvider',
          'users.confirmUnsubcription',
          'name',
          null,
          true,
          unSubcription(),
        );
    });
  };

  const deleteUserFromProvider = () => {
    dispatch(checkLastUser(`providerId=${providerId}&userId=${userId}`)).then(
      ({ payload }) => {
        if (!payload?.lastUser) {
          confirmDeleteLastUser(
            deleteUserItem,
            currentProvider,
            'usersOfProvider',
            'users.deleteUser',
            'name',
            null,
            payload?.lastUser,
          );
        } else {
          confirmDeleteLastUser(
            deleteUserUnsubscription,
            currentProvider,
            'usersOfProvider',
            'users.confirmDeleteLastUser',
            'name',
            record?.role?.description,
            payload?.lastUser,
          );
        }
      },
    );
  };

  return (
    <div className="flex items-center">
      <CustomButtonIcon
        title="button.impersonate"
        IconComponent={IncognitoIcon}
        handleClick={() => handleImpersonating()}
      />
      <EditButton isView source="show" onClickCustom={handleClickView} />
      {record?.role?.type === 'provider' && (
        <ButtonWrapper>
          <Tooltip title={i18next.t('button.delete')}>
            <Button
              icon={<DeleteOutlined />}
              onClick={() => deleteUserFromProvider()}
            />
          </Tooltip>
        </ButtonWrapper>
      )}
    </div>
  );
};

GroupActionsUser.propTypes = {
  userId: PropTypes.string,
  record: PropTypes.object,
};

export default GroupActionsUser;
