import React from 'react';
import { Row, Col } from 'antd';
import i18next from 'i18next';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import { ACTIVE_TYPES } from 'configs/localData';

const Filter = (props) => (
  <div {...props}>
    <Row {...props} gutter={16}>
      <Col xl={8} lg={8} md={8} xs={24}>
        <RestInputItem
          source={['name', '$like']}
          placeholder="NQSRTypes.name"
          isShowTooltip
        />
      </Col>
      <Col xl={8} lg={8} md={8} xs={24}>
        <RestInputItem
          source={['title', '$like']}
          placeholder="NQSRTypes.titleField"
          isShowTooltip
        />
      </Col>
      <Col xl={8} lg={8} md={8} xs={24}>
        <RestSelect
          isShowTooltip
          isShowSearch={false}
          source="isActive"
          placeholder="NQSRTypes.isActive"
          resourceData={ACTIVE_TYPES}
          valueProp="value"
          titleProp="text"
          formatText={(data) => i18next.t(data)}
          ruleType="boolean"
        />
      </Col>
    </Row>
  </div>
);

export default Filter;
