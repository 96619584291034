import React from 'react';
import i18next from 'i18next';
import kindiLogo from 'assets/images/kindi-logo.png';

const HeaderNotifyIOS = () => (
  <div className="notify-preview__noti-header flex-center-between">
    <div className="notify-preview__noti-logo-ios flex">
      <img className="app-logo" alt="kindi" src={kindiLogo} />
      <span className="app-name">{i18next.t('appInfo.name')}</span>
    </div>

    <div className="notify-preview__noti-time-ios">
      {i18next.t('common.now')}
    </div>
  </div>
);

export default HeaderNotifyIOS;
