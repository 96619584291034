import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import { debounce } from 'lodash-es';
import { validateRegex } from 'utils/validateUtils';
import { KEYS_GET_GEO_MAP_REQUIRED } from 'configs/localData/constant';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import SuburbInput from './SuburbInput';

const AddressFullForm = ({ form, setGeoAddressObj }) => {
  const stateOfAustralia = useSelector(
    (state) => state.config.data?.stateOfAustralia,
  );

  const handleGetGeoWhenChangeAddress = debounce(() => {
    const values = form.getFieldsValue();
    if (KEYS_GET_GEO_MAP_REQUIRED.every((key) => values?.[key])) {
      setGeoAddressObj(values);
    }
  }, 1000);

  return (
    <div>
      <Row gutter={16}>
        <Col md={12} xs={24}>
          <RestInputItem
            required
            isValidatorEmpty
            source="addressL1"
            header="input.streetAddress.name"
            placeholder="input.streetAddress.name"
            onChange={handleGetGeoWhenChangeAddress}
          />
        </Col>

        <Col md={12} xs={24}>
          <SuburbInput
            handleGetGeoWhenChangeAddress={handleGetGeoWhenChangeAddress}
          />
        </Col>

        <Col md={12} xs={24}>
          <RestInputItem
            required
            source="postCode"
            header="centres.postCode"
            placeholder="centres.postCode"
            rules={[
              {
                pattern: validateRegex.postCode,
                message: i18next.t('input.postCode.validateMsg.invalid'),
              },
            ]}
            onChange={handleGetGeoWhenChangeAddress}
          />
        </Col>

        <Col md={12} xs={24}>
          <RestSelect
            required
            source="state"
            header="input.state.name"
            placeholder="input.state.placeholder"
            resourceData={stateOfAustralia || []}
            onChange={handleGetGeoWhenChangeAddress}
          />
        </Col>
      </Row>
    </div>
  );
};

AddressFullForm.propTypes = {
  form: PropTypes.object,
  setGeoAddressObj: PropTypes.func,
};

export default AddressFullForm;
