import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Button, Col, Form, Row, Steps } from 'antd';
import i18n from 'i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import { NOTIFICATION_STATUS_CONSTANT } from 'configs/localData';
import { isEmpty } from 'lodash-es';
import NotificationsForm from './NotificationsForm';
import Wrapper from './styles';
import ScheduleForm from './ScheduleForm';
import TestNotificationForm from './TestNotificationForm';
import PreviewNoti from '../PreviewNoti';
import { PreviewNotifyProvider } from '../PreviewNoti/context';

const { Step } = Steps;

const STEPS_NOTIFY_CONSTANT = {
  notification: {
    current: 0,
    value: 'notification',
    title: 'notifications.notification',
  },
  testNotification: {
    current: 1,
    value: 'testNotification',
    title: 'notifications.testNotification',
  },
  schedule: {
    current: 2,
    value: 'schedule',
    title: 'notifications.scheduling',
  },
};

const STEPS_NOTIFY = Object.values(STEPS_NOTIFY_CONSTANT);

const NotificationForm = ({ record, handleNotify }) => {
  const [currentStep, setCurrentStep] = React.useState(
    STEPS_NOTIFY_CONSTANT.notification.current,
  );

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { push } = useHistory();

  const [loadingDraft, setLoadingDraft] = useState();
  const [loadingPublish, setLoadingPublish] = useState();

  const next = () => {
    form
      .validateFields(['title', 'text', 'htmlContent', 'typeId', 'image'])
      .then(() => {
        if (currentStep < STEPS_NOTIFY_CONSTANT.schedule.current) {
          setCurrentStep(currentStep + 1);
        }
      });
  };

  const prev = () => {
    if (currentStep > STEPS_NOTIFY_CONSTANT.notification.current) {
      setCurrentStep(currentStep - 1);
    }
  };

  const formatUTCFromDateWithTimezone = (date, time, timezone) => {
    if (date && time && timezone) {
      const dateTime = date
        .set({
          hour: moment(time).hours(),
          minute: moment(time).minutes(),
          second: 0,
          millisecond: 0,
        })
        .format('YYYY-MM-DD HH:mm');

      return momentTZ.tz(dateTime, timezone).toISOString();
    }

    return null;
  };

  const handleSendNoti = (status, setLoading) => {
    form
      .validateFields()
      .then(({ scheduleType, date, time, recipientEmail, ...values }) => {
        setLoading(true);
        const startedAt = formatUTCFromDateWithTimezone(
          date,
          time,
          values.timezone,
        );

        const statusNotify =
          status ||
          (startedAt
            ? NOTIFICATION_STATUS_CONSTANT.scheduled.value
            : NOTIFICATION_STATUS_CONSTANT.sending.value);

        return dispatch(
          handleNotify({
            ...values,
            startedAt,
            status: statusNotify,
            recipientFilter: '{}',
          }),
        );
      })
      .then((response) => {
        if (!isEmpty(response?.payload?.errors)) {
          const fieldData = Object.entries(response.payload.errors).map(
            ([key, value]) => ({
              name: key === 'startedAt' ? 'time' : key,
              errors: [value],
            }),
          );
          form.setFields(fieldData);
        } else if (response?.payload?.id) {
          push('/notifications');
        }
      })
      .finally(() => {
        setLoading(false);
      })
      .catch(() => {});
  };

  const handleSendDraft = () => {
    handleSendNoti(NOTIFICATION_STATUS_CONSTANT.draft.value, setLoadingDraft);
  };

  const handleSendPublish = () => {
    handleSendNoti(null, setLoadingPublish);
  };

  const customDot = (dot, { status, index }) => (
    <Avatar size={35} className={`custom-dot custom-dot-${status}`}>
      {index + 1}
    </Avatar>
  );

  const getClassHidden = (step) => (step === currentStep ? '' : 'd-none');

  return (
    <PreviewNotifyProvider>
      <Wrapper>
        <Row gutter={16}>
          <Col lg={12} className="form col">
            <Steps current={currentStep} progressDot={customDot}>
              {STEPS_NOTIFY.map((item) => (
                <Step key={item.title} title={i18n.t(item.title)} />
              ))}
            </Steps>

            <div className="steps-content">
              <Form layout="vertical" form={form} style={{ width: '100%' }}>
                <RestInputContext.Provider value={{ form, record }}>
                  <div
                    className={getClassHidden(
                      STEPS_NOTIFY_CONSTANT.notification.current,
                    )}
                  >
                    <NotificationsForm record={record} form={form} />
                  </div>

                  <div
                    className={getClassHidden(
                      STEPS_NOTIFY_CONSTANT.testNotification.current,
                    )}
                  >
                    <TestNotificationForm record={record} form={form} />
                  </div>

                  <div
                    className={getClassHidden(
                      STEPS_NOTIFY_CONSTANT.schedule.current,
                    )}
                  >
                    <ScheduleForm record={record} form={form} />
                  </div>
                </RestInputContext.Provider>
              </Form>
            </div>
            <div className="steps-action">
              {currentStep < STEPS_NOTIFY.length - 1 && (
                <div className="float-right">
                  <Button type="primary" onClick={() => next()}>
                    {i18n.t('button.next')}
                  </Button>
                </div>
              )}
              {currentStep > 0 && (
                <div className="float-left">
                  <Button
                    className="btn-line-primary"
                    style={{ margin: '0 8px' }}
                    onClick={() => prev()}
                  >
                    {i18n.t('button.back')}
                  </Button>
                </div>
              )}
              {currentStep === STEPS_NOTIFY.length - 1 && (
                <div className="float-right">
                  <Button
                    loading={loadingPublish}
                    disabled={loadingDraft}
                    type="primary"
                    onClick={handleSendPublish}
                  >
                    {i18n.t('button.publish')}
                  </Button>
                </div>
              )}
              <div
                className={
                  currentStep !== STEPS_NOTIFY_CONSTANT.notification.current
                    ? 'float-right'
                    : ''
                }
              >
                <Button
                  className="btn-line-primary"
                  loading={loadingDraft}
                  disabled={loadingPublish}
                  onClick={handleSendDraft}
                >
                  {i18n.t('button.draft')}
                </Button>
              </div>
            </div>
          </Col>
          <Col className="preview col" lg={12}>
            <PreviewNoti />
          </Col>
        </Row>
      </Wrapper>
    </PreviewNotifyProvider>
  );
};

NotificationForm.propTypes = {
  record: PropTypes.object,
  handleNotify: PropTypes.func,
};

export default NotificationForm;
