import { Col, Form, Input, Row, Typography } from 'antd';
import RestInputItem from 'components/RestInput/RestInputItem';
import React from 'react';
import i18next from 'i18next';
import RestSelect from 'components/RestInput/RestSelect';
import { useSelector } from 'react-redux';
import { validateRegex } from 'utils/validateUtils';
import { companiesSelectors } from 'redux/companies/selectors';
import { CompanyFormStyles } from './styles';

const CompanyInfoForm = () => {
  const companyType = useSelector((state) => state?.config?.data?.companyType);
  const currentData = useSelector(companiesSelectors.getCurrentData) || {};

  return (
    <CompanyFormStyles>
      <Typography.Title level={4}>
        {i18next.t('companies.companyInfo')}
      </Typography.Title>

      <Row gutter={[40]}>
        <Col span={12}>
          <RestInputItem
            required
            source="name"
            header="companies.companyName"
            placeholder="companies.companyName"
            isValidatorEmpty
          />
        </Col>
        <Col span={12}>
          <RestSelect
            header="companies.companyType"
            source="typeId"
            placeholder="companies.companyType"
            resourceData={companyType || []}
            valueProp="id"
            titleProp="name"
            required
          />
        </Col>
        <Col span={12}>
          <Form.Item shouldUpdate className="input-filed-style">
            {({ setFieldsValue, validateFields }) => (
              <Form.Item
                className="input-filed-style"
                label={i18next.t('companies.acnNumber')}
                name="australianCompanyNumber"
                shouldUpdate
                initialValue={currentData?.australianCompanyNumber}
                rules={[
                  () => ({
                    validator(_, value) {
                      if (
                        validateRegex?.ACNNumber?.test(
                          value?.replaceAll(' ', ''),
                        ) ||
                        !value
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          i18next.t(
                            'input.ABN&ACNNumber.validateMsg.acnDigits',
                          ),
                        ),
                      );
                    },
                  }),
                ]}
              >
                <Input
                  maxLength="11"
                  onChange={(e) => {
                    setFieldsValue({
                      australianCompanyNumber: e.target.value
                        .replaceAll(' ', '')
                        .split(/(?=(?:\d{3})+(?:\.|$))/g)
                        .join(' '),
                    });
                    validateFields(['australianCompanyNumber']);
                  }}
                />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item shouldUpdate className="input-filed-style">
            {({ setFieldsValue, validateFields }) => (
              <Form.Item
                className="input-filed-style"
                label={i18next.t('companies.abnNumber')}
                name="australianBusinessNumber"
                shouldUpdate
                initialValue={currentData?.australianBusinessNumber}
                rules={[
                  () => ({
                    validator(_, value) {
                      if (
                        validateRegex?.ABNNumber?.test(
                          value?.replaceAll(' ', ''),
                        ) ||
                        !value
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          i18next.t(
                            'input.ABN&ACNNumber.validateMsg.abnDigits',
                          ),
                        ),
                      );
                    },
                  }),
                ]}
              >
                <Input
                  maxLength="14"
                  onChange={(e) => {
                    setFieldsValue({
                      australianBusinessNumber: e.target.value
                        .replaceAll(' ', '')
                        .split(/(?=(?:\d{3})+(?:\.|$))/g)
                        .join(' '),
                    });
                    validateFields(['australianBusinessNumber']);
                  }}
                />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
        <Col span={24}>
          <RestInputItem
            source="website"
            header="companies.website"
            placeholder="companies.website"
            isValidatorEmpty
          />
        </Col>
      </Row>
    </CompanyFormStyles>
  );
};

export default CompanyInfoForm;
