import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import i18next from 'i18next';

import EditCustomBtn from 'components/RestActions/EditCustomBtn';
import ProviderImageInfo from './ProviderImageInfo';
import GeneralInfo from './GeneralInfo';
import ProviderInfoStyles from './styles';
import ProviderGeneralInfoModal from '../Modals/ProviderGeneralInfoModal';
import BusinessUser from './BusinessUser';

const ProviderGeneral = ({ record }) => {
  const modalRef = useRef();

  const onClickButton = () => {
    modalRef.current && modalRef.current.toggleModal();
  };
  return (
    <ProviderInfoStyles>
      <EditCustomBtn onClickButton={onClickButton} />
      <ProviderImageInfo logo={record?.logo} />
      <div className="provider-name-style">{record?.name}</div>
      <div className="content-info-container">
        <GeneralInfo record={record} />
        <Divider />
        <div className="title-info-other">
          {i18next.t('providers.businessUser')}
        </div>
        <BusinessUser record={record?.user} />
      </div>

      <ProviderGeneralInfoModal ref={modalRef} record={record} />
    </ProviderInfoStyles>
  );
};

ProviderGeneral.propTypes = {
  record: PropTypes.object,
};

ProviderGeneral.defaultProps = {
  record: {},
};

export default ProviderGeneral;
