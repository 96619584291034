import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const initialValue = {
  editModal: {
    visible: false,
    record: {},
  },
  setEditModal: () => {},
};

const ServiceModalContext = createContext(initialValue);

export const ServiceModalProvider = ({ children }) => {
  const [editModal, setEditModal] = useState({ visible: false, record: {} });

  const value = {
    editModal,
    setEditModal,
  };
  return (
    <ServiceModalContext.Provider value={value}>
      {children}
    </ServiceModalContext.Provider>
  );
};

ServiceModalProvider.propTypes = {
  children: PropTypes.node,
};

export const useServiceModal = () => {
  const { editModal, setEditModal } = useContext(ServiceModalContext);

  const toggleAndSetRecord = (record) => {
    setEditModal({ visible: !editModal.visible, record });
  };

  return {
    toggleAndSetRecord,
    visibleEditModal: editModal.visible,
    record: editModal.record,
  };
};
