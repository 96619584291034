import { makeCRUDSlice } from 'redux/crudCreator';
import {
  MODEL_NAME,
  disputeReviewActions,
  responseDisputeReview,
} from './actions';

const responseDisputeReviewSuccess = (state, { meta }) => {
  if (state.data[meta.arg?.id]?.id) {
    state.data[meta.arg.id].disputation = {
      ...state.data[meta.arg.id].disputation,
      ...meta.arg.data,
    };
  }
};

const slice = makeCRUDSlice(MODEL_NAME, disputeReviewActions, {
  [responseDisputeReview.fulfilled]: responseDisputeReviewSuccess,
});
export default slice.reducer;
