import React from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'antd';

import SummaryCard from 'components/common/SummaryCardCustom';
import {
  TotalPlaceIcon,
  TotalCentreIcon,
  TotalEarningIcon,
  TotalWaitlistValueIcon,
  TotalProviderIcon,
} from 'components/common/SVGIcon';
import { SummariesProviderStyle } from './styles';

const SummariesProvider = () => {
  const summaries = useSelector(
    (state) => state.companies?.providersSummary || {},
  );
  const data = [
    {
      title: 'summaries.totalProviders',
      count: summaries.totalProviders,
      IconComponent: TotalProviderIcon,
    },
    {
      title: 'summaries.totalCentres',
      count: summaries.totalCentres,
      IconComponent: TotalCentreIcon,
      isShowIconInfo: true,
    },
    {
      title: 'summaries.totalPlaces',
      count: summaries.totalPlaces,
      IconComponent: TotalPlaceIcon,
      isShowIconInfo: true,
    },
    {
      title: 'summaries.estEarningPotential',
      count: summaries.estimatedEarningPotentialValue,
      IconComponent: TotalEarningIcon,
      isMoney: true,
    },
    {
      title: 'summaries.waitlistValuePa',
      count: summaries.waitlistedValue,
      IconComponent: TotalWaitlistValueIcon,
      isMoney: true,
    },
  ];
  return (
    <SummariesProviderStyle>
      {data.map((item, index) => (
        <Col key={String(index)} className="list-card">
          <SummaryCard {...item} />
        </Col>
      ))}
    </SummariesProviderStyle>
  );
};

export default SummariesProvider;
