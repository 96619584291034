import React, { useCallback } from 'react';
import { Switch } from 'antd';
import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import RestImageField from 'components/RestField/ImageField';
import { PREFIX_URL_API } from 'configs/localData/constant';
import {
  formatCentreName,
  formatKindiRating,
  formatSubscription,
  formatOnActiveACECQA,
} from 'utils/formatFieldUtils';
import { useDispatch } from 'react-redux';
import { changeSpecialOffer } from 'redux/centres/actions';
import { formatDateTimeTable } from 'utils/textUtils';
import { formatTimeFilterSubmit } from 'utils/dataUtils';
import { useHistory } from 'react-router';
import ProgressCustome from 'components/common/ProgressCustome';
import DeleteButton from 'components/RestActions/DeleteButton';
import i18next from 'i18next';
import Filter from '../components/Filter';

const CentresList = (props) => {
  const dispatch = useDispatch();

  const { push } = useHistory();

  const handleChangeSpecialOffer = (value, record) => {
    dispatch(
      changeSpecialOffer({
        data: { id: record.id, isSpecialOffer: value },
      }),
    );
  };
  const redirectDetail = (id) => {
    localStorage.removeItem('currentStep');
    id && push(`/centres/${id}/show/summary`);
  };

  const formatFilter = useCallback(
    ({ createdAt, ...data }) => ({
      ...data,
      createdAt: formatTimeFilterSubmit(createdAt),
    }),
    [],
  );

  return (
    <List
      {...props}
      resource="centres"
      hasSearch={false}
      redirects={{
        edit: 'screen',
        create: 'modal',
      }}
      filter={<Filter format={formatFilter} />}
      defaultOptions={{ prefixUrl: PREFIX_URL_API }}
    >
      <RestFieldItem
        sorter
        source="name"
        header="centres.name"
        format={formatCentreName}
        width={280}
      />
      <RestFieldItem
        width={180}
        source="companyName"
        header="centres.companyTitle"
        sorter
        format={(value, record) => record?.company?.name}
      />
      <RestFieldItem
        width={150}
        source="brandName"
        header="centres.brandTitle"
        sorter
        format={(value, record) => record?.brand?.name}
      />
      <RestFieldItem sorter source="city" header="centres.suburb" width={120} />
      <RestFieldItem sorter source="state" header="centres.state" width={100} />
      <RestFieldItem
        source="CRMPackage.name"
        header="centres.subscription"
        format={formatSubscription}
        width={170}
      />
      <RestImageField
        style={{ width: 'auto', objectFit: 'contain', height: 40 }}
        source="NQSRType.image"
        header="centres.NQSRating"
        width={100}
      />
      <RestFieldItem
        sorter
        source="kindiCareRating"
        header="centres.rating"
        format={formatKindiRating}
        width={100}
      />
      <RestFieldItem
        sorter
        source="placeAllowed"
        header="centres.capacity"
        width={100}
      />
      <RestFieldItem
        source="totalServices"
        header="centres.services"
        width={100}
      />
      <RestFieldItem
        sorter
        source="viewsCount"
        header="centres.viewsCount"
        width={120}
      />
      <RestFieldItem
        sorter
        source="latestViewCount"
        header="centres.latestViewCount"
        width={180}
      />
      <RestFieldItem
        sorter
        source="totalListingStrength"
        header="centres.listingStrength"
        format={(data) => <ProgressCustome percent={data} className="pr-32" />}
        width={220}
      />
      <RestFieldItem
        source="isACECQA"
        header="centres.acecqa"
        format={formatOnActiveACECQA}
        width={120}
      />
      <RestFieldItem
        source="isFeatured"
        header="centres.featured"
        valueProp="checked"
        component={<Switch />}
        width={150}
      />
      <RestFieldItem
        source="isSpecialOffer"
        header="centres.isSpecialOffer"
        valueProp="checked"
        component={<Switch />}
        customOnChange={handleChangeSpecialOffer}
        width={150}
      />
      <RestFieldItem
        source="isActive"
        header="centres.isActive"
        valueProp="checked"
        component={<Switch />}
        width={150}
      />
      <RestFieldItem
        sorter
        source="createdAt"
        header="common.createdAt"
        format={formatDateTimeTable}
        width={150}
      />
      <ActionGroup widthAction={80}>
        <EditButton source="show" isView onClickCustom={redirectDetail} />
        <DeleteButton
          customTitle={i18next.t('popup.alertNoticeDeleteCentre')}
          contentCannotDelete={i18next.t(
            'popup.alertNoticeDeleteCentreContentCannotDelete',
          )}
          customResource={i18next.t('popup.thisCentre')}
          isDeleteInactiveCentre
        />
      </ActionGroup>
    </List>
  );
};

export default CentresList;
