import React from 'react';
import { useSelector } from 'react-redux';

import {
  TotalEnquiriesIcon,
  TotalMoreInfoIcon,
  TotalCheckVacanciesIcon,
  BookTourIcon,
  FeeInformationIcon,
} from 'components/common/SVGIcon';
import SummaryCardDetail from 'components/common/SummaryCardDetail';

const SummaryEnquiries = () => {
  const summaries =
    useSelector(
      (state) => state.centres.summaryDetail?.centreEnquiriesSummary,
    ) || {};

  const data = [
    {
      title: 'centreDetail.totalEnquiries',
      value: summaries.totalEnquiries,
      IconComponent: TotalEnquiriesIcon,
    },
    {
      title: 'enquiryTypes.moreInformation',
      value: summaries.moreInformationEnquiries,
      IconComponent: TotalMoreInfoIcon,
    },
    {
      title: 'enquiryTypes.checkForVacancies',
      value: summaries.checkForVacanciesEnquiries,
      IconComponent: TotalCheckVacanciesIcon,
    },
    {
      title: 'enquiryTypes.bookATourOrVisit',
      value: summaries.bookATourOrVisitEnquiries,
      IconComponent: BookTourIcon,
    },
    {
      title: 'enquiryTypes.feeInformation',
      value: summaries.feeInformationEnquiries,
      IconComponent: FeeInformationIcon,
    },
  ];
  return (
    <SummaryCardDetail title="centreDetail.enquiriesSummary" data={data} />
  );
};

export default SummaryEnquiries;
