import React, { forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';

import RestUploadFile from 'components/RestInput/RestUploadFile';
import { getPrefixKeyUploadCentre } from 'utils/fileUtils';
import CentreModal from '.';

const CentrePhotoModal = forwardRef(({ visible, onCancel, record }, ref) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const { id } = useParams();
  const [visibleModal, setVisibleModal] = useState(false);

  const onCancelModal = () => setVisibleModal(false);

  useImperativeHandle(
    ref,
    () => ({
      toggleModal: () => {
        setVisibleModal(true);
      },
    }),
    [],
  );
  return (
    <CentreModal
      title="centreDetail.titleCentrePhotoModal"
      visible={visible || visibleModal}
      onCancel={onCancel || onCancelModal}
      isDisabled={isDisabled}
      record={record}
    >
      <RestUploadFile
        setIsDisabled={setIsDisabled}
        source="images"
        onlyShowImg
        isSetFeature
        isAttachURLVideo
        isDrapDrop
        prefixKey={getPrefixKeyUploadCentre(id)}
        accept="images/*, audio/video"
      />
    </CentreModal>
  );
});

CentrePhotoModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  record: PropTypes.object,
};

export default CentrePhotoModal;
