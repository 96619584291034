import React from 'react';
import { Col, InputNumber, Row, Typography } from 'antd';
import i18next from 'i18next';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestFormDateTimePicker from 'components/RestInput/RestDateTimePicker';
import RestSelect from 'components/RestInput/RestSelect';
import { useSelector } from 'react-redux';
import {
  getCentreTypes,
  getPrimaryChildCareService,
} from 'redux/config/selectors';
import CompanyBrandForm from './CompanyBrandForm';
import CentreNumberCode from './CentreNumberCode';

const CentreInfoForm = () => {
  const centreTypes = useSelector(getCentreTypes);

  const childcareServiceType = useSelector(getPrimaryChildCareService);

  return (
    <div>
      <Typography.Title level={5} className="centre-form__title-section">
        {i18next.t('centres.centreInformation')}
      </Typography.Title>
      <Row gutter={16}>
        <Col md={12} xs={24}>
          <RestInputItem
            required
            source="name"
            header="centres.name"
            placeholder="centres.name"
            isValidatorEmpty
          />
        </Col>
        <Col md={6} xs={24}>
          <RestSelect
            required
            resourceData={childcareServiceType || []}
            header="centres.childcareServiceType"
            source="primaryChildCareServiceId"
            placeholder="centres.childcareServiceType"
            valueProp="id"
            titleProp="name"
          />
        </Col>
        <Col md={6} xs={24}>
          <RestSelect
            required
            resourceData={centreTypes || []}
            header="centres.centreServiceType"
            source="type"
            placeholder="centres.centreServiceType"
          />
        </Col>
      </Row>

      <CentreNumberCode />

      <CompanyBrandForm />

      <Row gutter={16}>
        <Col md={12} xs={24}>
          <RestFormDateTimePicker
            source="dateApplied"
            header="centres.dateApplied"
            placeholder="centres.dateApplied"
          />
        </Col>
        <Col md={12} xs={24}>
          <RestInputItem
            source="placeAllowed"
            header="centreDetail.approvedPlaces"
            placeholder="centreDetail.approvedPlaces"
            ContentComponent={InputNumber}
            rules={[
              {
                type: 'number',
                min: 1,
                message: i18next.t('input.approvedPlaces.validateMsg.invalid'),
              },
            ]}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CentreInfoForm;
