import styled from 'styled-components';

const LogoutStyles = styled.div`
  .ant-btn {
    width: 100%;

    .anticon {
      color: #fff;
    }
  }
`;

export default LogoutStyles;
