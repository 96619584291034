import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb as AntBreadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import BreadcrumbStyles from './styles';

const Breadcrumb = ({ data }) => (
  <BreadcrumbStyles>
    <AntBreadcrumb>
      {data.map((data, index) => (
        <AntBreadcrumb.Item key={String(index)}>
          {data.path ? (
            <Link to={data.path}>
              <span className="text-breadcrumb">{data.title}</span>
            </Link>
          ) : (
            <span className="text-breadcrumb">{data.title}</span>
          )}
        </AntBreadcrumb.Item>
      ))}
    </AntBreadcrumb>
  </BreadcrumbStyles>
);

export const makeBreacrumbFromPath = (path, title) => {
  const BREADCRUMB_LIST = [];
  const paths = path.split('/');
  paths.forEach((data, index) => {
    if (data === '') return;
    BREADCRUMB_LIST.push({
      title: data,
      path: `${
        BREADCRUMB_LIST[index - 1] ? BREADCRUMB_LIST[index - 1].path : ''
      }/${data}`,
    });
  });
  if (title) {
    BREADCRUMB_LIST[paths.length].title =
      title || BREADCRUMB_LIST[paths.length].title;
  }
  return BREADCRUMB_LIST;
};

Breadcrumb.propTypes = {
  data: PropTypes.array,
};
export default Breadcrumb;
