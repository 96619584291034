import React from 'react';
import List from 'containers/rest/List';
import { Switch } from 'antd';
import RestFieldItem from 'components/RestField/RestFieldItem';
import RestImageField from 'components/RestField/ImageField';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import DeleteButton from 'components/RestActions/DeleteButton';
import Filter from '../components/Filter';

const NQSRTypesList = (props) => (
  <List
    {...props}
    resource="NQSRTypes"
    hasSearch={false}
    widthTable="1000px"
    filter={<Filter />}
    defaultOptions={{ isGetConfig: true }}
  >
    <RestImageField
      style={{ width: 'auto', objectFit: 'contain' }}
      source="image"
      header="NQSRTypes.image"
    />
    <RestFieldItem source="name" header="NQSRTypes.name" />
    <RestFieldItem source="title" header="NQSRTypes.titleField" />
    <RestFieldItem
      source="isActive"
      header="NQSRTypes.isActive"
      valueProp="checked"
      component={<Switch />}
    />

    <ActionGroup widthAction={100}>
      <EditButton />
      <DeleteButton customMessage="NQSRTypes.customMessageDelete" />
    </ActionGroup>
  </List>
);

export default NQSRTypesList;
