import React from 'react';
import PropTypes from 'prop-types';
import { Progress, Tooltip } from 'antd';
import i18next from 'i18next';
import ProgressStyle from './styles';

const ProgressCustome = ({ percent, type, className, title, strokeColor }) => (
  <ProgressStyle>
    <Tooltip title={i18next.t(title) || ''}>
      <Progress
        className={className}
        strokeLinecap="square"
        type={type}
        percent={percent}
        width={55}
        format={(data) => `${data}%`}
        strokeColor={strokeColor}
      />
    </Tooltip>
  </ProgressStyle>
);

ProgressCustome.propTypes = {
  percent: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  strokeColor: PropTypes.string,
};

export default ProgressCustome;
