import styled from 'styled-components';

const ListStyles = styled.div`
  display: flex;
  flex-direction: column;

  .row-list,
  .row-list > .ant-col {
    height: 100%;
  }
  .row-list > .ant-col > div {
    height: 100%;
  }

  .ant-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .enquiries {
    &__row-wrapper {
      height: 100%;
    }
    &__chat-box {
      display: flex;
      flex-direction: column;
      background: #fff;
      border-radius: 8px;
      height: 100%;
    }
    &__chat-wrapper {
      flex-grow: 1;
      position: relative;
    }
    &__status-groups {
      padding: 10px 16px;
      border-bottom: 1px solid ${({ theme }) => theme.border.light};
    }
  }

  .enquiries__contact-collapsed {
    transform: scale(0);
    width: 0;
    height: 0;
  }
  .enquiries__contact-col {
    transition: all 0.6s;
    transform-origin: top right;
  }
  .enquiries__chat-box-col {
    transition: all 0.6s;
  }

  .enquiries__row-list,
  .enquiries__row-list > .ant-col,
  .enquiries__row-list > .ant-col > div,
  .enquiries__row-wrapper,
  .enquiries__row-wrapper > .ant-col {
    height: 100%;
  }
`;

export default ListStyles;
export const InboxesStyles = styled.div`
  border-radius: 8px;
  background: #fff;
  .ant-divider-horizontal {
    margin: 0;
  }
  .ant-divider {
    border-top: 1px solid #ddd;
  }
`;
