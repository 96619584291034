import { createAsyncThunk } from '@reduxjs/toolkit';
import { delUserOfCompanyApi, getUsersOfCompanyApi } from 'api/companies';
import { apiWrapper } from 'utils/reduxUtils';

export const MODEL_NAME = 'usersOfCompany';

export const getUsersOfCompany = createAsyncThunk(
  `${MODEL_NAME}/getUsersOfCompany`,
  async (payload = {}, thunkApi) => {
    try {
      const { size, page, filter, sorts } =
        thunkApi.getState().usersOfCompany?.resourceData || {};

      const response = await apiWrapper(
        {},
        getUsersOfCompanyApi,
        payload.companyId,
        {
          size,
          page,
          filter,
          sorts,
          ...payload.data,
        },
      );
      return {
        data: {
          page: Math.ceil(response.paging.total / size),
          ...response,
        },
        options: payload.options,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ data: error });
    }
  },
);

export const delUserOfCompany = createAsyncThunk(
  'centres/delUserOfCompany',
  async (payload = {}, thunkAPI) => {
    try {
      const { id, userId } = payload;
      const { size, page, sorts } =
        thunkAPI.getState().usersOfCompany?.resourceData || {};

      await apiWrapper({ isShowSuccessNoti: true }, delUserOfCompanyApi, id, {
        userId,
      });

      await thunkAPI.dispatch(
        getUsersOfCompany({
          companyId: id,
          data: {
            size,
            page,
            sorts,
          },
          options: { isRefresh: true, keepFilter: true },
        }),
      );

      return { id, userId, payload, success: true };
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error, id: payload });
    }
  },
);
