import styled from 'styled-components';

export const SeeMoreStyles = styled.div`
  padding-top: 12px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.02em;
  font-size: 16px;
  .ant-typography {
    color: #32a4fc !important;
  }
`;
