import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import TagCustomWrapper from './styles';

const TagCustom = ({
  text,
  color,
  backgroundColor,
  className,
  name,
  IconCPN,
  borderColor,
  isCheckBorderTag,
}) => (
  <TagCustomWrapper
    className={className}
    style={{ backgroundColor, color, borderColor }}
    isCheckBorderTag={isCheckBorderTag}
  >
    {IconCPN && <IconCPN />}
    <span className="ellipsis-t-1">{name || i18next.t(text)}</span>
  </TagCustomWrapper>
);

TagCustom.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  name: PropTypes.string,
  IconCPN: PropTypes.any,
  borderColor: PropTypes.string,
  isCheckBorderTag: PropTypes.bool,
};

export default TagCustom;
