import React, { useState } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import ModalCustom from 'components/common/ModalCustom';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { handleApiError } from 'utils/validateUtils';
import { useProvider } from 'containers/Providers/Show/context';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { getSummariesProvider } from 'redux/providers/actions';

const ProvidersModal = ({ onCancel, title, children, record, ...props }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { validateFields, resetFields } = form;
  const { updateProvider } = useProvider();
  const dispatch = useDispatch();
  const { id: providerId } = useParams();

  const onOk = () => {
    validateFields()
      .then(({ urlVideo, companyId, userId, ...values }) => {
        setLoading(true);
        updateProvider({
          ...values,
          companyId: companyId === undefined ? null : companyId,
          userId: userId === undefined ? null : userId,
        })
          .then(({ payload }) => {
            handleApiError(payload?.data, form);
            if (
              payload?.data?.id ||
              payload?.data?.australianBusinessNumber ||
              payload?.data?.australianCompanyNumber
            ) {
              handleCancel();
            }
            if (
              payload?.data.governmentReferenceId !==
              record?.governmentReferenceId
            ) {
              dispatch(getSummariesProvider({ providerId }));
            }
          })
          .finally(() => setLoading(false));
      })
      .catch((error) => {
        form.scrollToField(error.errorFields?.[0]?.name?.[0]);
      });
  };

  const handleCancel = () => {
    onCancel();
    resetFields();
  };
  return (
    <ModalCustom
      title={i18next.t(title)}
      onCancel={handleCancel}
      onOk={onOk}
      loading={loading}
      {...props}
      width={1000}
    >
      <Form form={form} layout="vertical" scrollToFirstError>
        <RestInputContext.Provider value={{ form, record }}>
          {children}
        </RestInputContext.Provider>
      </Form>
    </ModalCustom>
  );
};

ProvidersModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
  isDisabled: PropTypes.bool,
  record: PropTypes.object,
};

ProvidersModal.defaultProps = {};

export default ProvidersModal;
