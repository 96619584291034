import React from 'react';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import CentreModal from '.';

const GoogleDataModal = ({ visible, record, onCancel }) => (
  <CentreModal
    title="centreDetail.titleGoogleDataModal"
    visible={visible}
    onCancel={onCancel}
  >
    <Form.Item
      rules={[{ required: true, message: i18next.t('error.required') }]}
      name="placeId"
      label={i18next.t('centreDetail.placeId')}
      initialValue={record.placeId}
    >
      <Input placeholder={i18next.t('centreDetail.placeId')} />
    </Form.Item>
    <Form.Item
      rules={[
        { required: true, message: i18next.t('error.required') },

        () => ({
          validator(_, value) {
            if (value) {
              try {
                JSON.parse(value);
              } catch (e) {
                return Promise.reject(
                  i18next.t('input.placeDetails.validateMsg.invalid'),
                );
              }
              return Promise.resolve();
            }
            return Promise.resolve();
          },
        }),
      ]}
      name="placeDetail"
      label={i18next.t('centreDetail.placeDetails')}
      initialValue={
        record.placeDetail ? JSON.stringify(record.placeDetail) : ''
      }
    >
      <Input.TextArea
        placeholder={i18next.t('centreDetail.placeDetails')}
        rows={10}
      />
    </Form.Item>
  </CentreModal>
);

GoogleDataModal.propTypes = {
  record: PropTypes.object,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default GoogleDataModal;
