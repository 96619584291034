import { PREFIX_URL_API } from 'configs/localData/constant';
import { get, del } from './utils';

export const getMessagesOfInboxApi = (id, data) =>
  get(`/inboxes/${id}/messages`, data, {}, PREFIX_URL_API);

export const getInboxesOfCentreApi = (id, data) =>
  get(`/centres/${id}/inboxes`, data, {}, PREFIX_URL_API);

export const delInboxesOfCentreApi = (id) =>
  del(`/inboxes/${id}`, null, {}, PREFIX_URL_API);

export const getSummariesInboxApi = (data) =>
  get('/inboxes/summaries', data, {}, PREFIX_URL_API);
