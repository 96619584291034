import styled from 'styled-components';

const ItemInfoStyles = styled.div`
  font-size: 16px;
  display: flex;

  .info-item-common__value {
    word-break: break-word;
  }
  .anticon {
    margin-right: 10px;
    line-height: 26px;
  }
`;

export default ItemInfoStyles;
