import React, { useEffect } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import PageTitle from 'components/common/PageTitle';
import CustomBreadcrumb from 'components/common/Breadcrumb';
import { getByIdUsers } from 'redux/users/actions';
import { PREFIX_URL } from 'configs/localData/constant';

import { CreateEditStyles } from './styles';
import FormAddMoreCompanies from './components/FormAddMoreCompanies';

const AddMoreCompanies = () => {
  const currentData = useSelector((state) => state.users.currentData) || {};
  const { id } = useParams();
  const dispatch = useDispatch();

  const breadcrumb = [
    {
      title: i18next.t('users.header'),
      path: '/users',
    },
    {
      title: `${currentData?.firstName || ''} ${currentData?.lastName || ''}`,
      path: `/users/${id}/show`,
    },
    {
      title: i18next.t('users.addMoreCompanies'),
      path: '#',
    },
  ];

  useEffect(() => {
    dispatch(
      getByIdUsers({
        data: { id },
        options: { prefixUrl: PREFIX_URL.superAdmin, isRequestApi: true },
      }),
    );
  }, [id, dispatch]);

  return (
    <CreateEditStyles>
      <CustomBreadcrumb data={breadcrumb} />
      <PageTitle>{i18next.t('users.titleDetail')}</PageTitle>
      <div className="add-more-content">
        <div className="add-more-title">
          {i18next.t('users.addMoreCompanies')}
        </div>
        <FormAddMoreCompanies />
      </div>
    </CreateEditStyles>
  );
};

export default AddMoreCompanies;
