import React, { useContext, useMemo } from 'react';
import { Typography, Button, Form } from 'antd';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash-es';
import i18next from 'i18next';
import { CloseCircleFilled, PlusOutlined } from '@ant-design/icons';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import FormStyles from './styles';

const FormListSingle = ({
  children,
  source,
  title,
  formatInitialValue,
  defaultValue,
}) => {
  const { record } = useContext(RestInputContext);

  const initialValue = useMemo(
    () =>
      isEmpty(record?.[source]) ? [null] : formatInitialValue(record?.[source]),
    [record, source, formatInitialValue],
  );

  return (
    <FormStyles className="form-list__list-wrapper">
      <Typography.Title level={5}>{i18next.t(title)}</Typography.Title>

      <div className="form-list__list">
        <Form.List name={source} initialValue={initialValue} defaultValue>
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <div key={String(index)} className="form-list__list-item">
                  {React.cloneElement(children, {
                    field,
                  })}

                  <CloseCircleFilled
                    className="form-list__remove-button"
                    onClick={() => remove(field.name)}
                  />
                </div>
              ))}
              <Form.Item className="add-more-form-item">
                <Button
                  type="dashed"
                  onClick={() => add(defaultValue)}
                  icon={<PlusOutlined />}
                >
                  {i18next.t('button.addMore')}
                </Button>

                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
    </FormStyles>
  );
};

FormListSingle.propTypes = {
  source: PropTypes.string,
  formatInitialValue: PropTypes.func,
  defaultValue: PropTypes.object,
  children: PropTypes.node,
  title: PropTypes.string,
};

FormListSingle.defaultProps = {
  formatInitialValue: (data) => data,
};

export default FormListSingle;
