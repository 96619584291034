import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { makeBreadCrumbFromPath } from 'utils/tools';
import i18next from 'i18next';
import CustomBreadcrumb from '../../common/Breadcrumb';
import RestEditForm from '../RestEditForm';
import Layout from '../../common/Layout';
import Box from '../../common/Box';
import PageTitle from '../../common/PageTitle';

const RestEditComponent = (props) => {
  const { showModal, header, noCardWrapper, isResetStyle, breadcrumbCustom } =
    props;
  const location = useLocation();
  const BREADCRUMB_LIST = makeBreadCrumbFromPath(location);
  if (header && !showModal) {
    BREADCRUMB_LIST[BREADCRUMB_LIST.length - 1].title =
      header || BREADCRUMB_LIST[BREADCRUMB_LIST.length - 1].title;
  }

  const content = (
    <div className="content-edit-wrapper" style={{ width: '100%' }}>
      <RestEditForm {...props} />
    </div>
  );

  return showModal || noCardWrapper ? (
    content
  ) : (
    <Layout bordered={false}>
      <PageTitle>
        <CustomBreadcrumb data={breadcrumbCustom || BREADCRUMB_LIST} />
        {header && <div className="header-title">{i18next.t(header)}</div>}
      </PageTitle>
      <Box isResetStyle={isResetStyle}>{content}</Box>
    </Layout>
  );
};

RestEditComponent.propTypes = {
  location: PropTypes.object,
  showModal: PropTypes.bool,
  header: PropTypes.any,
  noCardWrapper: PropTypes.bool,
  breadcrumbCustom: PropTypes.array,
  isResetStyle: PropTypes.bool,
};

RestEditComponent.defaultProps = {
  noCardWrapper: false,
};

export default RestEditComponent;
