/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { ACTIVITY_PROVIDER_TYPES } from 'configs/localData';

import EmptyData from 'components/common/EmptyData';
import { ActivitiesNotesDetailsStyles } from 'containers/Providers/components/ActivitiesNotesTab/styles';
import { Col, Divider, Row } from 'antd';
import { formatDateTime } from 'utils/textUtils';
import { LinkIcon } from 'components/common/SVGIcon';
import ReadMore from 'components/common/ReadMore';

const NoteDetailBody = ({ record, isProviderName }) => {
  const typeItem = ACTIVITY_PROVIDER_TYPES.find(
    (item) => item.value === record?.type,
  );

  return record?.id ? (
    <ActivitiesNotesDetailsStyles>
      <div className="activity-detail__header">
        {typeItem && (
          <typeItem.IconCPN className="activity-detail__type-icon" />
        )}

        <div
          style={{ color: typeItem?.color }}
          className="activity-detail__type-name"
        >
          {i18next.t(typeItem?.text)}
        </div>
      </div>

      <div className="activity-detail__info">
        {isProviderName && (
          <>
            <div className="info-item">
              <div className="info-item__label">
                {i18next.t('providers.providerName')}
              </div>
              <div className="info-item__value">
                {record?.provider?.name || i18next.t('error.waitingUpdate')}
              </div>
            </div>
            <Divider />
          </>
        )}
        <div className="info-item">
          <div className="info-item__label">{i18next.t('common.subject')}</div>
          <div className="info-item__value">
            {record?.subject || i18next.t('error.waitingUpdate')}
          </div>
        </div>

        <div className="info-item">
          <div className="info-item__label">
            {i18next.t('activitiesAndNotes.createdBy')}
          </div>
          <div className="info-item__value">
            {record?.createdByUser?.userName ||
              i18next.t('error.waitingUpdate')}
          </div>
        </div>

        <div className="info-item">
          <div className="info-item__label">{i18next.t('tasks.assignee')}</div>
          <div className="info-item__value">
            {`${record?.assigneeUser?.firstName || ''} ${
              record?.assigneeUser?.lastName || ''
            }`}
          </div>
        </div>

        <Divider />

        <div className="info-item">
          <div className="info-item__label">{i18next.t('common.date')}</div>
          <div className="info-item__value">
            {formatDateTime(record?.dueDate) ||
              i18next.t('error.waitingUpdate')}
          </div>
        </div>
        <Divider />

        <div className="info-item-note">
          <div className="info-item__label-note">
            {i18next.t('common.note')}
          </div>
          <div className="info-item__value-note">
            <ReadMore
              text={record?.description || i18next.t('error.waitingUpdate')}
              readMoreCharacterLimit={500}
            />
          </div>
        </div>

        <Divider />
        {record?.attachments?.length > 0 ? (
          <div className="info-item-note">
            <div className="info-item__label-note">
              {i18next.t('common.attachments')}
            </div>
            {record?.attachments?.map((item, index) => (
              <Row gutter={30} key={Number(index)}>
                <Col xxl={2} xl={3} sm={1}>
                  <LinkIcon />
                </Col>
                <Col xxl={22} xl={21} sm={23}>
                  <a
                    className="text-black text-style"
                    href={item?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>{item?.key}</span>
                  </a>
                </Col>
              </Row>
            ))}
          </div>
        ) : (
          <div className="info-item">
            <div className="info-item__label">
              {i18next.t('common.attachments')}
            </div>
            <div className="info-item__value">
              {i18next.t('error.waitingUpdate')}
            </div>
          </div>
        )}
      </div>
    </ActivitiesNotesDetailsStyles>
  ) : (
    <EmptyData />
  );
};

NoteDetailBody.propTypes = {
  record: PropTypes.object,
  isProviderName: PropTypes.bool,
};

export default NoteDetailBody;
