import { PREFIX_URL_API } from 'configs/localData/constant';
import { post, get, del, put } from './utils';

export const getAllCategoriesApi = () =>
  get(`/categories`, null, {}, PREFIX_URL_API);

export const postCategoriesApi = (data) =>
  post(`/categories`, data, {}, PREFIX_URL_API);

export const editCategoriesApi = (id, data) =>
  put(`/categories/${id}`, data, {}, PREFIX_URL_API);

export const delCategoriesApi = (id) =>
  del(`/categories/${id}`, null, {}, PREFIX_URL_API);
