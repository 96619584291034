import React from 'react';
import Create from 'containers/rest/Create';
import { PREFIX_URL_API } from 'configs/localData/constant';
import Form from '../components/Form';
import { formatOnSubmit } from '../utils';

const MarketingsCreate = (props) => (
  <Create
    {...props}
    defaultOptions={{ prefixUrl: PREFIX_URL_API }}
    resource="marketings"
    formatOnSubmit={formatOnSubmit}
  >
    <Form />
  </Create>
);

export default MarketingsCreate;
