import styled from 'styled-components';

export default styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  transition: padding-left 0.3s ease 0.1s, padding-right 0.3s ease 0.1s,
    position 0 ease 0.3s;
  .extraAction {
    margin-bottom: 0.5em;
    margin-left: 15px;
  }
  h1 {
    font-size: 28px;
    font-weight: bold;
    color: ${({ theme }) => theme.text.primary};
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
  }
`;
