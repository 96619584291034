import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import { Link, useHistory } from 'react-router-dom';
import { Menu, Dropdown, Avatar, Badge, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'redux/auth/actions';
import { ROLE } from 'configs/localData';
import { checkRole } from 'utils/tools';
import {
  MenuFoldOutlined,
  BellOutlined,
  UserOutlined,
} from '@ant-design/icons';
import HeaderWrapper from './styles';

const Header = ({ onToggle, collapsed }) => {
  const dispatch = useDispatch();
  const currentRole = useSelector((state) => state.auth.role);
  const currentUser = useSelector((state) => state.auth.data);
  const notSeen = useSelector((state) => state.notifications.notSeen);
  const { push } = useHistory();
  const toggleDrawer = () => {
    push('/notifications');
  };
  const profileMenu = [
    {
      key: 'profile',
      text: 'header.profile',
      url: '/profile',
    },
  ];

  useEffect(() => {
    I18n.changeLanguage('en');
  }, []);

  return (
    <HeaderWrapper className="header">
      <div className="leftHeader">
        <MenuFoldOutlined
          className={`trigger ${collapsed ? '' : 'reverse-trigger'}`}
          onClick={onToggle}
        />
        <div className="title">{I18n.t('appInfo.name')}</div>
      </div>
      <div className="rightHeader">
        <div className="notification-section">
          <Button onClick={toggleDrawer}>
            <Badge count={notSeen}>
              <BellOutlined />
            </Badge>
          </Button>
        </div>
        <Dropdown
          overlay={() => (
            <Menu style={{ minWidth: '120px' }}>
              {profileMenu.map((menu) =>
                !checkRole(menu.roles, currentRole) ? null : (
                  <Menu.Item key={menu.key}>
                    <Link to={menu.url}>{I18n.t(menu.text)}</Link>
                  </Menu.Item>
                ),
              )}
              <Menu.Divider />
              <Menu.Item onClick={() => dispatch(logout())} key="logout">
                {I18n.t('header.logout')}
              </Menu.Item>
            </Menu>
          )}
          trigger={['click']}
        >
          <div className="div-user-info">
            <span className="userInfo">
              <strong>{`${currentUser.firstName || ''} ${
                currentUser.lastName || ''
              }`}</strong>

              <span className="role">
                {I18n.t(ROLE.find((role) => currentRole === role.value)?.text)}
              </span>
            </span>
            <Avatar
              src={currentUser?.avatar}
              size="large"
              icon={<UserOutlined />}
            />
          </div>
        </Dropdown>
      </div>
    </HeaderWrapper>
  );
};
Header.propTypes = {
  collapsed: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default Header;
