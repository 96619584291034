import { createSelector } from 'reselect';

const getRawResourceDataProvidersOfCompany = (state) =>
  state.providersLevelOfCompany?.resourceData;

const getRawProvidersOfCompanyLoading = (state) =>
  state.providersLevelOfCompany?.loading;

const getRawDataProvidersOfCompany = (state) =>
  state.providersLevelOfCompany?.data;

export const getResourceDataProvidersLevelOfCompany = createSelector(
  [getRawResourceDataProvidersOfCompany],
  (data) => data,
);

export const getLoadingProvidersLevelOfCompany = createSelector(
  [getRawProvidersOfCompanyLoading],
  (data) => data,
);

export const getDataProvidersLevelOfCompany = createSelector(
  [getRawDataProvidersOfCompany],
  (data) => data,
);
