import React from 'react';
import PropTypes from 'prop-types';
import Spin from 'antd/lib/spin';

import EmptyData from 'components/common/EmptyData';
import UserInfo from 'components/RestField/UserInfo';
import { useSelector } from 'react-redux';
import { superAdminUsersSelectors } from 'redux/superAdminUsers/selectors';

const AssigneeList = ({ handleSelectedAssignee }) => {
  const data = useSelector(superAdminUsersSelectors.getDataArr);

  const loading = useSelector(superAdminUsersSelectors.getLoading);

  return (
    <div className="mt-10 assignee-list-wrapper">
      {data?.length ? (
        <div className="assignee-list">
          {data.map((item, idx) => (
            <div
              key={String(idx)}
              className="assignee-item cursor-pointer"
              onClick={() => handleSelectedAssignee(item)}
              role="presentation"
            >
              <UserInfo
                isLink={false}
                record={item}
                customName={`${item.firstName} ${item.lastName}`}
                roleProp="email"
              />
            </div>
          ))}
        </div>
      ) : (
        <EmptyData />
      )}
      {loading && (
        <div className="w-full flex-center">
          <Spin />
        </div>
      )}
    </div>
  );
};

AssigneeList.propTypes = {
  handleSelectedAssignee: PropTypes.func,
};

export default AssigneeList;
