import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  delInboxesOfCentreApi,
  getInboxesOfCentreApi,
  getMessagesOfInboxApi,
  getSummariesInboxApi,
} from 'api/inboxes';
import { pick } from 'lodash-es';
import { makeActions } from 'redux/crudCreator/actions';
import { convertRequestParams } from 'redux/crudCreator/dataProvider';
import { apiWrapper } from 'utils/reduxUtils';

export const MODEL_NAME = 'inboxes';
export const inboxesActions = makeActions(MODEL_NAME);

export const getAllInboxes = inboxesActions.getAll;
export const editInboxes = inboxesActions.edit;
export const createInboxes = inboxesActions.create;
export const getByIdInboxes = inboxesActions.getDataById;
export const clearCurrentInboxes = inboxesActions.clearCurrent;

export const getInboxesOfCentre = createAsyncThunk(
  'centres/getInboxesOfCentre',
  async (payload = {}, thunkApi) => {
    try {
      const { id, data = {}, options = {} } = payload;

      const { limit, offset, filter } =
        thunkApi.getState().inboxes.inboxesOfCentre?.resourceData || {};

      const convertRequest = convertRequestParams(
        'GET_ALL',
        {
          limit,
          offset,
          filter,
          ...data,
        },
        'inboxes',
        options,
      );

      const response = await apiWrapper(
        {},
        getInboxesOfCentreApi,
        id,
        convertRequest,
      );

      return {
        data: {
          numberOfPages: Math.ceil(response.total / limit),
          ...response,
        },
        options,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ id: payload.data.id, data: error });
    }
  },
);

export const getMessagesOfInbox = createAsyncThunk(
  'centres/getMessagesOfInbox',
  async (payload = {}, thunkApi) => {
    try {
      const { id, data = {}, options = {} } = payload;

      const { limit, offset, filter } =
        thunkApi.getState().inboxes?.messages?.resourceData || {};

      const convertRequest = convertRequestParams('GET_ALL', {
        limit,
        offset,
        filter,
        ...data,
      });

      const response = await apiWrapper(
        {},
        getMessagesOfInboxApi,
        id,
        convertRequest,
      );

      return {
        data: {
          numberOfPages: Math.ceil(response.total / limit),
          ...response,
        },
        options,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ id: payload.data.id, data: error });
    }
  },
);

export const delInboxesOfCentre = createAsyncThunk(
  'centres/delInboxesOfCentre',
  async (payload, thunkApi) => {
    try {
      const data = thunkApi.getState().inboxes.inboxesOfCentre?.data;
      const { q, filter } =
        thunkApi.getState().inboxes.inboxesOfCentre.resourceData || {};

      await apiWrapper(
        { isShowSuccessNoti: true },
        delInboxesOfCentreApi,
        payload.id,
      );
      thunkApi.dispatch(getSummariesInboxes({ data: { q, filter } }));

      return {
        id: payload.id,
        success: true,
        firstInboxId:
          data?.[0]?.id === payload.id ? data?.[1]?.id : data?.[0]?.id,
      };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const clearMessages = createAction('inboxes/clearMessages');

export const clearInboxesListOfCentre = createAction(
  'inboxes/clearInboxesListOfCentre',
);

export const getSummariesInboxes = createAsyncThunk(
  'inboxes/getSummariesInboxes',
  async (payload = {}, thunkApi) => {
    try {
      const convertRequest = convertRequestParams(
        'GET_ALL',
        pick(payload.data, ['q', 'filter.centreId', 'filter.enquiryTypeId']),
        'enquiries',
        {
          isOrderBy: false,
        },
      );

      const response = await apiWrapper(
        {},
        getSummariesInboxApi,
        convertRequest,
      );

      return response;
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  },
);
