import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import TableCustom from 'components/common/TableCustom';
import { CRUDSelectorsCustom } from 'redux/crudCreatorCustom/selectors';
import { formatDateTimeTable, formatResponseTime } from 'utils/textUtils';
import {
  formatCentreName,
  formatEnquiryStatus,
  formatEnquiryType,
  formatNightDayTable,
  formatUser,
} from 'utils/formatFieldUtils';
import GroupActionEnquiries from './GroupActionEnquiries';

const EnquiriesTable = ({ retrieveList, resource }) => {
  const enquiriesSelectorsCustom = new CRUDSelectorsCustom(resource);
  const loading = useSelector(enquiriesSelectorsCustom.getLoading);
  const resourceFilter =
    useSelector(enquiriesSelectorsCustom.getResourceData) || {};
  const data = useSelector(enquiriesSelectorsCustom.getDataArr);

  const columns = [
    {
      title: i18next.t('centres.centreName'),
      dataIndex: 'centreName',
      sorter: true,
      render: (data, record) => formatCentreName(data, record?.centre),
      width: 300,
    },
    {
      title: i18next.t('centres.centrePhone'),
      dataIndex: ['centre', 'phone'],
      width: 150,
    },
    {
      title: i18next.t('sender.sender'),
      dataIndex: 'user',
      render: formatUser,
      width: 200,
    },
    {
      title: i18next.t('inboxes.enquiryStatus'),
      dataIndex: 'centreEnquiryStatus',
      render: formatEnquiryStatus,
      width: 150,
    },
    {
      title: i18next.t('inboxes.enquiryType'),
      dataIndex: ['enquiryType', 'name'],
      render: formatEnquiryType,
      width: 150,
    },
    {
      title: i18next.t('inboxes.enquiryDate'),
      dataIndex: 'createdAt',
      render: (record) => (
        <p className="text-nowrap">{formatDateTimeTable(record)}</p>
      ),
      width: 200,
      sorter: true,
    },
    {
      title: i18next.t('inboxes.lastUpdate'),
      dataIndex: 'lastUpdate',
      render: (data, record) =>
        formatNightDayTable(record?.latestMessage?.updatedAt),
      sorter: true,
      width: 200,
    },
    {
      title: i18next.t('common.responseTime'),
      dataIndex: 'responseTime',
      render: formatResponseTime,
      sorter: true,
      width: 150,
    },
    {
      dataIndex: 'id',
      fixed: 'right',
      width: 100,
      render: (id, record) => <GroupActionEnquiries id={id} record={record} />,
    },
  ];

  const onChange = (e, filters, sorter) => {
    const formatSort =
      sorter && sorter.field && sorter.order
        ? `${sorter.order === 'descend' ? '-' : ''}${sorter.field}`
        : '-lastUpdate';
    const restFilter = {
      offset: (e.current - 1) * e.pageSize,
      limit: e.pageSize,
      orderBy: formatSort,
      filter: resourceFilter.filter,
      outsideFilter: resourceFilter.outsideFilter,
    };

    retrieveList({
      filter: restFilter,
      isRefresh: true,
    });
  };

  return (
    <div>
      <TableCustom
        xScroll={1000}
        onChange={onChange}
        data={data}
        columns={columns}
        loading={loading}
        pagination={{
          pageSize: resourceFilter.limit,
          current: resourceFilter.offset / resourceFilter.limit + 1 || 1,
          total: resourceFilter.total,
        }}
      />
    </div>
  );
};

EnquiriesTable.propTypes = {
  retrieveList: PropTypes.func,
  resource: PropTypes.string,
};

EnquiriesTable.defaultProps = {
  retrieveList: () => null,
};

export default EnquiriesTable;
