import { createSlice } from '@reduxjs/toolkit';
import {
  getAllCustomFail,
  getAllCustomPending,
  getAllCustomSuccess,
  INITIAL_STATE_CUSTOM,
  resetResourceDataCustom,
} from 'redux/crudCreatorCustom/utils';
import { getUnownedCentresOfUser } from './actions';

export const initialState = {
  ...INITIAL_STATE_CUSTOM,
};

const { actions, reducer } = createSlice({
  name: 'UnownedCentresOfUser',
  initialState,
  reducers: {
    resetResourceFilterUnownedCentresOfUser: resetResourceDataCustom,
  },
  extraReducers: {
    [getUnownedCentresOfUser.pending]: getAllCustomPending,
    [getUnownedCentresOfUser.fulfilled]: getAllCustomSuccess,
    [getUnownedCentresOfUser.rejected]: getAllCustomFail,
  },
});

export const { resetResourceFilterUnownedCentresOfUser } = actions;

export default reducer;
