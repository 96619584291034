import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import {
  ABNIcon,
  ACNIcon,
  CompanyIcon,
  PrNumberIcon,
  SaleStageIcon,
} from 'components/common/SVGIcon';
import SalesStageDropdown from '../SalesStageDropdown';

const GeneralInfo = ({ record }) => (
  <div className="provider-info">
    <div className="title-info">{i18next.t('providerDetail.generalInfo')}</div>
    <div className="content-info-other">
      <div className="item-info">
        <SaleStageIcon />
        <Tooltip title={i18next.t('input.salesStage.name')}>
          <span>
            <SalesStageDropdown
              saleStage={record?.saleStage?.name}
              id={record?.id}
            />
          </span>
        </Tooltip>
      </div>
      <div className="item-info">
        <CompanyIcon />
        <Tooltip title={i18next.t('centres.company')}>
          <Link
            to={`/companies/${record?.company?.id}/show/summary`}
            className="link-item link-class"
          >
            {record?.company?.name}
          </Link>
        </Tooltip>
      </div>
      <div className="item-info">
        <PrNumberIcon />
        <Tooltip title={i18next.t('providers.PRNumber')}>
          <span>{record?.governmentReferenceId}</span>
        </Tooltip>
      </div>
      <div className="item-info">
        <ACNIcon className="flex-center" />
        <Tooltip title={i18next.t('providers.acnNumber')}>
          <span>
            {record?.australianCompanyNumber ||
              i18next.t('error.waitingUpdate')}
          </span>
        </Tooltip>
      </div>
      <div className="item-info">
        <ABNIcon className="flex-center" />
        <Tooltip title={i18next.t('providers.abnNumber')}>
          <span>
            {record?.australianBusinessNumber ||
              i18next.t('error.waitingUpdate')}
          </span>
        </Tooltip>
      </div>
    </div>
  </div>
);

GeneralInfo.propTypes = {
  record: PropTypes.object,
};

export default GeneralInfo;
