import React from 'react';
import { PropTypes } from 'prop-types';
import { Col, Row } from 'antd';

import ProviderGeneral from '../ProviderGeneral';
import ProviderInfo from './ProviderInfo';
import TotalApplication from './SummariesApplication';
import TotalCentres from './SummariesCentres';
import TotalEnquiries from './SummariesEnquiries';

const SummaryTab = ({ record }) => (
  <div>
    <Row gutter={[24, 24]}>
      <Col xl={6} lg={10} md={24} xs={24}>
        <ProviderGeneral record={record} />
      </Col>
      <Col xl={18} lg={14} md={24} xs={24}>
        <Row gutter={[24, 24]}>
          <Col xl={8} lg={24} md={24} xs={24}>
            <TotalCentres />
          </Col>
          <Col xl={8} lg={24} md={24} xs={24}>
            <TotalEnquiries />
          </Col>
          <Col xl={8} lg={24} md={24} xs={24}>
            <TotalApplication />
          </Col>
        </Row>
        <Row className="mt-24">
          <Col span={24}>
            <ProviderInfo record={record} />
          </Col>
        </Row>
      </Col>
    </Row>
  </div>
);

SummaryTab.propTypes = {
  record: PropTypes.object,
};

export default SummaryTab;
