import React from 'react';
import i18next from 'i18next';
import { useHistory } from 'react-router';

import PageTitle from 'components/common/PageTitle';
import CreateButton from 'components/RestActions/CreateButton';
import ArticleTable from './components/ArticleTable';

const ArticlesList = () => {
  const history = useHistory();

  const openCreate = () => {
    history.push(`${history.location.search}#articles/create`);
  };

  return (
    <div>
      <PageTitle extraAction={<CreateButton gotoCreatePage={openCreate} />}>
        {i18next.t('sideBar.articles')}
      </PageTitle>
      <ArticleTable />
    </div>
  );
};

export default ArticlesList;
