import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProvidersLevelOfCompanyApi } from 'api/companies';
import { convertRequestParams } from 'redux/crudCreator/dataProvider';
import { apiWrapper } from 'utils/reduxUtils';

export const getProvidersLevelOfCompany = createAsyncThunk(
  'providersLevelOfCompany/getProvidersLevelOfCompany',
  async (payload = {}, thunkApi) => {
    try {
      const { limit, offset, filter, includes, orderBy } =
        thunkApi.getState().providersLevelOfCompany?.resourceData || {};

      const { outsideFilter: outsideFilterPayload, ...data } = payload.data;

      const convertRequest = convertRequestParams(
        'GET_ALL',
        {
          limit,
          offset,
          filter,
          includes,
          orderBy,
          ...data,
          ...outsideFilterPayload,
        },
        'providersLevelOfCompany',
        payload.options,
      );

      const response = await apiWrapper(
        {},
        getProvidersLevelOfCompanyApi,
        convertRequest,
      );

      return {
        data: {
          numberOfPages: Math.ceil(response.total / limit),
          ...response,
        },
        options: payload.options,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ data: error });
    }
  },
);
