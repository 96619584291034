import FilterCustom from 'components/common/FilterCustom';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_CREATED_AT,
} from 'configs/localData/constant';
import React from 'react';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { getFilterFromUrl } from 'utils/tools';
import { formatFromToTimeFilterSubmit } from 'utils/dataUtils';
import FilterForm from './FilterForm';

const FilterUsers = ({ retrieveList, pushQuery }) => {
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);

  const onSubmitFilter = ({ createdAt, ...values }) => {
    const restFilter = {
      size: filter.size,
      page: DEFAULT_CURRENT_PAGE,
      sorts: filter.sorts,
      filter: {
        createdAt: formatFromToTimeFilterSubmit(createdAt),
        ...values,
      },
    };
    retrieveList({
      filter: { ...restFilter },
      isRefresh: true,
    });
    pushQuery({
      'sorts[]': filter.sorts,
      ...restFilter,
    });
  };

  const onClearFilter = () => {
    retrieveList({
      filter: {
        size: filter.size,
        page: filter.page,
        sorts: filter.sorts || [DEFAULT_SORT_CREATED_AT],
      },
      isRefresh: true,
    });

    pushQuery({
      'sorts[]': filter.sorts,
      size: filter.size,
      page: filter.page,
    });
  };

  return (
    <FilterCustom
      onSubmitFilter={onSubmitFilter}
      onClearFilter={onClearFilter}
      responsiveFilter={{
        xxl: 20,
        xl: 18,
        lg: 24,
        md: 24,
        xs: 24,
      }}
      responsiveAction={{
        xxl: 4,
        xl: 6,
        lg: 24,
        md: 24,
        xs: 24,
      }}
    >
      <FilterForm />
    </FilterCustom>
  );
};

FilterUsers.propTypes = {
  retrieveList: PropTypes.func,
  pushQuery: PropTypes.func,
};
export default FilterUsers;
