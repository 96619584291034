import React from 'react';
import PropTypes from 'prop-types';
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { AttactmentIcon, TrashIcon } from 'components/common/SVGIcon';
import { Col, Row } from 'antd';
import { UploadImageItemStyles } from './styles';

const UploadImageItem = ({ item, deleteImage, index }) => (
  <UploadImageItemStyles className="uploadImage">
    {item.status === 'error' && (
      <ExclamationCircleOutlined className="icon-error" />
    )}
    {item.status !== 'error' && item.status !== 'done' && !item.url && (
      <div className="wrapper-loading">
        <LoadingOutlined className="icon-loading" />
      </div>
    )}
    <Row type="flex">
      <Col lg={3} />
      <Col lg={21} className="image-item">
        <div className="flex space-between">
          <div className="flex">
            <AttactmentIcon className="attactment-icon" />
            <p className="image-name">{item?.name || item?.key}</p>
          </div>
          <TrashIcon
            className="hide delete-icon-image"
            onClick={() => deleteImage(item, index)}
          />
        </div>
      </Col>
    </Row>
  </UploadImageItemStyles>
);
UploadImageItem.propTypes = {
  item: PropTypes.object,
  deleteImage: PropTypes.func,
  index: PropTypes.number,
};

export default UploadImageItem;
