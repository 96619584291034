import styled from 'styled-components';

const GroupActionsStyles = styled.div`
  display: flex;
  &.group-action__right {
    justify-content: right;
  }

  &.group-action__center {
    justify-content: center;
  }
`;

export default GroupActionsStyles;
