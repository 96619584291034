import React from 'react';

import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import CRMPackagePrice from 'components/common/CRMPackagePrice';

const CRMPackagesList = (props) => {
  const formatPrice = (data, record) => (
    <CRMPackagePrice price={data} unit={record?.unit} />
  );

  return (
    <List
      {...props}
      resource="CRMPackages"
      hasSearch={false}
      hasCreate={false}
      widthTable={800}
      defaultOptions={{ prefixUrl: 'super-admin' }}
      initialFilter={{ orderBy: 'rank' }}
    >
      <RestFieldItem source="name" header="CRMPackages.name" />
      <RestFieldItem
        source="price"
        header="CRMPackages.price"
        format={formatPrice}
      />
      <RestFieldItem
        source="signupButtonText"
        header="CRMPackages.signUpButtonText"
      />
      <ActionGroup widthAction={80}>
        <EditButton />
      </ActionGroup>
    </List>
  );
};

export default CRMPackagesList;
