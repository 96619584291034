import { makeCRUDSlice } from 'redux/crudCreator';
import {
  getByKeyAppSettingsOfCentre,
  updateAppSettingsOfCentre,
  MODEL_NAME,
  appSettingsActions,
  updateLabelTable,
} from './actions';

const slice = makeCRUDSlice(MODEL_NAME, appSettingsActions, {
  [getByKeyAppSettingsOfCentre.fulfilled]: (state, { payload }) => {
    if (payload?.key) {
      state[payload.key] = payload;
    }
  },

  [updateAppSettingsOfCentre.fulfilled]: (state, { payload }) => {
    const res = payload?.data;
    if (res?.key) {
      state[res.key] = res;
    }
  },

  [updateLabelTable.fulfilled]: (state, { payload }) => {
    const res = payload?.data;
    if (res?.key) {
      state[res.key] = res;
    }
  },
});

export default slice.reducer;
