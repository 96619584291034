import React from 'react';
import PropTypes, { bool } from 'prop-types';
import { TreeSelect } from 'antd';
import i18n from 'i18next';
import FormItem from '../FormItem';
import { onSearch as onChangeSearch } from '../../../utils/tools';
import { TreeSelectWrapper } from './style';

const { SHOW_CHILD, SHOW_PARENT } = TreeSelect;

const FormTreeSelect = ({ treeDefaultExpandAll, ...props }) => {
  const onSelectOption = (inputValue, option) => {
    if (onChangeSearch(option.props.title, inputValue)) {
      return option.props.value;
    }
    return null;
  };

  const {
    source,
    header,
    required,
    placeholder,
    defaultValue,
    onChange,
    loadData,
    treeData,
    showSearch,
    treeCheckable,
    treeIcon,
    isShowChild,
    allowClear,
  } = props;

  return (
    <FormItem
      {...props}
      name={source}
      placeholder={i18n.t(placeholder)}
      header={i18n.t(header)}
      required={required}
      defaultValue={defaultValue}
    >
      <TreeSelectWrapper
        onChange={onChange}
        treeData={treeData}
        showSearch={showSearch}
        placeholder={placeholder}
        loadData={loadData}
        style={{ width: '100%' }}
        treeCheckable={treeCheckable}
        treeIcon={treeIcon}
        showCheckedStrategy={isShowChild ? SHOW_CHILD : SHOW_PARENT}
        filterTreeNode={onSelectOption}
        allowClear={allowClear}
        treeDefaultExpandAll={treeDefaultExpandAll}
      />
    </FormItem>
  );
};

FormTreeSelect.propTypes = {
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  header: PropTypes.any,
  required: PropTypes.bool,
  requiredMessage: PropTypes.node,
  icon: PropTypes.string,
  placeholder: PropTypes.string,
  form: PropTypes.object,
  defaultValue: PropTypes.any,
  rules: PropTypes.any,
  onChange: PropTypes.func,
  loadData: PropTypes.func,
  options: PropTypes.array,
  showSearch: PropTypes.func,
  treeData: PropTypes.array,
  treeCheckable: PropTypes.bool,
  treeIcon: PropTypes.bool,
  searchKey: PropTypes.string,
  isShowChild: PropTypes.bool,
  allowClear: PropTypes.bool,
  treeDefaultExpandAll: bool,
};
FormTreeSelect.defaultProps = {
  required: false,
  requiredMessage: 'error.required',
  rules: [],
  placeholder: 'placeholder.undefined',
  treeCheckable: true,
  treeIcon: false,
  isShowChild: false,
  allowClear: true,
  treeDefaultExpandAll: true,
};
export default FormTreeSelect;
