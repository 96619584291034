import React from 'react';
import PropTypes from 'prop-types';
import Space from 'antd/lib/space';
import { useParams } from 'react-router';

import { useDispatch } from 'react-redux';
import {
  clearCurrentTasks,
  delTasks,
  getSummariesTasks,
  setCurrentDataTasks,
} from 'redux/tasks/actions';

import EditButton from 'components/RestActions/EditButton';
import DeleteButton from 'components/RestActions/DeleteButton';
import { PREFIX_URL_API } from 'configs/localData/constant';

const GroupActions = ({ id, record }) => {
  const dispatch = useDispatch();
  const { id: providerId } = useParams();

  const deleteItem = () =>
    dispatch(
      delTasks({ data: { id }, options: { prefixUrl: PREFIX_URL_API } }),
    ).then(({ payload }) => {
      if (payload?.success) {
        dispatch(clearCurrentTasks());
        dispatch(getSummariesTasks({ providerId }));
      }
    });

  const onClickEdit = () => {
    dispatch(setCurrentDataTasks(record));
  };

  return (
    <Space>
      <EditButton isView onClickCustom={onClickEdit} />
      <DeleteButton
        resource="tasks"
        nameProp="subject"
        record={record}
        deleteItem={deleteItem}
      />
    </Space>
  );
};

GroupActions.propTypes = {
  id: PropTypes.string,
  record: PropTypes.object,
};

export default GroupActions;
