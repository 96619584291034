import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';

import RestInputItem from 'components/RestInput/RestInputItem';
import RestMultiPhotos from 'components/RestInput/RestMultiPhotos';
import { formatLabelTable } from 'utils/textUtils';
import { LABEL_TABLE_KEY } from 'configs/localData/constant';
import { useSelector } from 'react-redux';

const ChildcareServiceTypesForm = ({ isEdit, ...props }) => {
  const appSettings = useSelector((state) => state.config?.data?.AppSetting);

  return (
    <div {...props}>
      <RestInputItem
        required
        source="name"
        header={formatLabelTable(LABEL_TABLE_KEY.childCareService, appSettings)}
        placeholder={formatLabelTable(
          LABEL_TABLE_KEY.childCareService,
          appSettings,
        )}
        isValidatorEmpty
      />
      <RestInputItem
        required
        source="code"
        header="serviceTypes.code"
        placeholder="serviceTypes.code"
        isValidatorEmpty
      />
      <RestMultiPhotos
        source="logo"
        header="serviceTypes.image"
        placeholder="serviceTypes.image"
        required
        multiple={false}
        objectFit="contain"
        width="auto"
        resource="features"
      />
      {isEdit && (
        <RestInputItem
          source="isActive"
          header="serviceTypes.isActive"
          placeholder="serviceTypes.isActive"
          ContentComponent={Switch}
          ruleType="boolean"
          valuePropName="checked"
        />
      )}
    </div>
  );
};

ChildcareServiceTypesForm.propTypes = {
  isEdit: PropTypes.bool,
};

export default ChildcareServiceTypesForm;
