import styled from 'styled-components';

const LayoutContentWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  flex-flow: row wrap;
  overflow: hidden;
  flex: 1;
`;

export { LayoutContentWrapper };
