import styled from 'styled-components';

const UserDetailStyles = styled.div``;

export default UserDetailStyles;

export const UserInfoStyles = styled.div`
  background: #fff;
  position: relative;
  border-radius: 8px;
  padding: 14px;
  .edit-wrapper {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
  }
  .edit-div-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .user-info {
    &__name {
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
    }
    &__content {
      .anticon {
        color: ${({ theme }) => theme.palette.primary};
      }
      > div {
        margin-bottom: 20px;
      }
    }
    &__user-role {
      align-items: center;
      .info-item-common__value {
        flex: 1;
      }
    }
  }
`;
