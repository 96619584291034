import { keyBy } from 'lodash-es';
import { convertRequestParamsAddEdit, getValidData } from '../../utils/tools';

export const PRIMARY_KEY = 'id';

const formatOrderBy = (isOrderBy = true, orderBy) => {
  if (orderBy) return orderBy;

  if (isOrderBy) return '-createdAt';

  return undefined;
};

export const convertRequestParams = (
  type,
  params,
  resource,
  options = { isTrimStr: false },
) => {
  const formattedParams = {
    ...params,
    count: undefined,
  };

  const paramsAddEdit = { ...params };

  const filter = getValidData(formattedParams.filter, true);

  switch (type) {
    case 'GET_ALL':
      return {
        ...formattedParams,
        orderBy: formatOrderBy(options?.isOrderBy, formattedParams.orderBy),
        filter:
          Object.keys(filter).length > 0 ? JSON.stringify(filter) : undefined,
      };
    case 'GET_BY_ID':
      return {
        ...params,
        [PRIMARY_KEY]: Number(params[PRIMARY_KEY]),
      };
    case 'EDIT':
      delete paramsAddEdit.id;
      return convertRequestParamsAddEdit(paramsAddEdit, options.isTrimStr);
    case 'CREATE':
      return convertRequestParamsAddEdit(paramsAddEdit, options.isTrimStr);
    case 'DELETE':
    default:
      return {};
  }
};

export const convertResponseData = (
  type,
  response,
  options = { primaryKey: PRIMARY_KEY },
) => {
  switch (type) {
    case 'GET_ALL':
      return {
        data: keyBy(
          response?.results.map((data) => ({
            ...data,
            [options.primaryKey]: data[options.primaryKey || PRIMARY_KEY],
            backupId: data[PRIMARY_KEY],
          })),
          options.primaryKey || PRIMARY_KEY,
        ),
        ids: response?.results.map(
          (data) => data[options.primaryKey || PRIMARY_KEY],
        ),
        total: response?.total,
      };
    case 'GET_BY_ID':
    case 'CREATE':
      return response
        ? {
            ...response,
            [options.primaryKey]: response[options.primaryKey || PRIMARY_KEY],
          }
        : null;
    case 'EDIT':
      return response && response ? { ...response } : null;
    case 'DELETE':
    default:
      return response;
  }
};
