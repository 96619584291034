import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import { Input, Form } from 'antd';
import { FormItemWrapper } from './styles';

const FormItemUI = ({
  source,
  header,
  required,
  requiredMessage,
  form,
  defaultValue,
  ruleType,
  rules,
  children,
  valuePropName,
  className,
  formOptions,
  disabled,
  label,
  whitespace,
  emptyMessage,
  isListField,
  fieldKey,
  autoSize,
  ...props
}) => {
  const getRules = () => {
    let restRule = [...rules];
    if (whitespace)
      restRule = [...restRule, { whitespace, message: I18n.t(emptyMessage) }];

    if (ruleType !== undefined) {
      return [
        { required, message: I18n.t(requiredMessage) },
        {
          type: ruleType,
          message: `${I18n.t('error.validateType')} ${I18n.t(
            ruleType || 'ruleType.string',
          )}`,
        },
        ...restRule,
      ];
    }
    return [{ required, message: I18n.t(requiredMessage) }, ...restRule];
  };

  return (
    <FormItemWrapper>
      <Form.Item
        className={className}
        label={I18n.t(label || header)}
        {...props}
        isListField={isListField}
        fieldKey={fieldKey}
        valuePropName={valuePropName}
        rules={getRules()}
        normalize={(value) =>
          ruleType === 'number' && value === null ? 0 : value
        }
        initialValue={
          defaultValue !== undefined && defaultValue !== null
            ? defaultValue
            : undefined
        }
        {...formOptions}
      >
        {React.cloneElement(children, {
          ...props,
          disabled,
        })}
      </Form.Item>
    </FormItemWrapper>
  );
};

FormItemUI.propTypes = {
  source: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  header: PropTypes.any,
  required: PropTypes.bool,
  requiredMessage: PropTypes.node,
  form: PropTypes.object,
  defaultValue: PropTypes.any,
  rules: PropTypes.array,
  valuePropName: PropTypes.string,
  ruleType: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  formOptions: PropTypes.object,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  whitespace: PropTypes.bool,
  emptyMessage: PropTypes.string,
  isListField: PropTypes.bool,
  fieldKey: PropTypes.any,
  autoSize: PropTypes.any,
};
FormItemUI.defaultProps = {
  required: false,
  requiredMessage: 'error.required',
  emptyMessage: 'error.empty',
  rules: [],
  valuePropName: 'value',
  disabled: false,
  children: <Input />,
};

export default FormItemUI;
