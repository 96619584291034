import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { unownedProvidersOfUserSelectors } from 'redux/unownedProvidersOfUser/selectors';
import ListCheckboxSelect from '../Form/ListCheckboxSelect';

const ProvidersAddMoreSelect = ({ retrieveList }) => {
  const data = useSelector(unownedProvidersOfUserSelectors.getDataArr);
  const paging = useSelector(unownedProvidersOfUserSelectors.getResourceData);
  const loading = useSelector(unownedProvidersOfUserSelectors.getLoading);

  return (
    <ListCheckboxSelect
      retrieveList={retrieveList}
      name="providerIds"
      data={data}
      paging={paging}
      loading={loading}
      selectedResourceTitles="users.selectedProvidersTotal"
      selectedResourceTitle="users.selectedProviderTotal"
      messageRequired="users.selectProviderRequired"
    />
  );
};

ProvidersAddMoreSelect.propTypes = {
  retrieveList: PropTypes.func,
};

export default ProvidersAddMoreSelect;
