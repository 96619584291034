import styled from 'styled-components';

export const GoogleMapStyles = styled.div`
  margin-bottom: 24px;

  .google-map-content {
    position: relative;
    height: 320px;
    width: 100%;
    .ant-select-selector {
      background: #fff !important;
      box-shadow: 0px 1px 16px rgb(0 0 0 / 0.1);
    }
  }
  .error-overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    justify-content: center;
    align-items: center;
    font-size: 26px;
  }
  .geolocation-form-item {
    margin-bottom: 0 !important;
    .ant-form-item-control-input {
      display: none;
    }
    .ant-form-item-explain {
      margin-bottom: 10px !important;
    }
    .ant-form-item-required {
      font-weight: bold;
    }
  }
`;

export const MarkerStyles = styled.div`
  .pointer-marker {
    width: 27px;
    height: 27px;

    border: 4px solid #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    background: ${({ theme }) => theme.palette.primary};
    border-radius: 50%;
  }
`;

export const ContentMarkerStyles = styled.div`
  width: 260px;
  .marker-name {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }
`;

export const GoogleAutocompleteInputStyles = styled.div`
  position: absolute;
  z-index: 10;
  top: 10px;
  left: 10px;
  width: 400px;
  max-width: calc(100% - 70px);
  .ant-select {
    width: 100%;
  }
`;
