import React from 'react';
import { Button } from 'antd';
import i18next from 'i18next';
import { useCentre } from '../../Show/context';

const ShowHideBtn = () => {
  const { setCollapsed, collapsed } = useCentre();

  return collapsed ? (
    <Button
      className="border-none show-hide-btn"
      onClick={() => setCollapsed(false)}
    >
      {i18next.t('button.hide')}
    </Button>
  ) : (
    <Button
      className="border-none show-hide-btn"
      onClick={() => setCollapsed(true)}
    >
      {i18next.t('button.show')}
    </Button>
  );
};

export default ShowHideBtn;
