import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { round, isEmpty } from 'lodash-es';
import CardStyles from './styles';
import { useEmailTemplate } from '../../context';
import ActionGroup from './ActionGroup';

const TemplateCard = ({ item, openEditModal }) => {
  const { template, setTemplate } = useEmailTemplate();
  const [zoom, setZoom] = useState(0.5);

  const handleSelectedItem = (e) => {
    e.stopPropagation();
    if (template?.id !== item.id) {
      setTemplate(item);
    }
  };

  const contentRef = useRef();

  useEffect(() => {
    if (contentRef.current && contentRef.current.offsetWidth < 300) {
      setZoom(round(contentRef.current.offsetWidth / 600, 2));
    }
  }, [contentRef.current]); // eslint-disable-line

  const clearCurrentTemplate = () => {
    if (template?.id === item.id) {
      setTemplate({});
    }
  };

  return (
    <CardStyles
      className={`template-item ${
        template?.id === item.id ? 'template-selected' : ''
      }`}
      bordered={false}
      hoverable
      onClick={handleSelectedItem}
    >
      <div className="template-item__content-wrapper" ref={contentRef}>
        <div
          className="template-item__content"
          dangerouslySetInnerHTML={{ __html: item.previewContent }}
          style={{
            zoom,
          }}
        />
      </div>

      <div className="template-item__footer">
        {item.previewSubject || i18next.t('error.waitingUpdate')}
        <ActionGroup
          templateKey={item.key}
          isDesign={!isEmpty(item.editorDesign)}
          clearCurrentTemplate={clearCurrentTemplate}
          openEditModal={openEditModal}
          isSystem={item.isSystem}
        />
      </div>
    </CardStyles>
  );
};

TemplateCard.propTypes = {
  item: PropTypes.object,
  openEditModal: PropTypes.func,
};

export default TemplateCard;
