import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

const initialValue = {
  collapsed: false,
  setCollapsed: (data) => data,
};

const EnquiryContext = createContext(initialValue);

export const EnquiryProvider = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <EnquiryContext.Provider value={{ collapsed, setCollapsed }}>
      {children}
    </EnquiryContext.Provider>
  );
};

EnquiryProvider.propTypes = {
  children: PropTypes.node,
};

export const useEnquiryContext = () => {
  const { collapsed, setCollapsed } = useContext(EnquiryContext);

  return {
    collapsed,
    setCollapsed,
  };
};
