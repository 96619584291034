import React from 'react';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import {
  CALLED_OR_CONTACTED_TYPES,
  ENQUIRIES_STATUS_CONST,
  ENQUIRY_TYPES_CONST,
} from 'configs/localData';
import i18next from 'i18next';

import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import RestRangePickerInput from 'components/RestInput/RestRangePickerInput';

const Filter = () => {
  const enquiryTypes = useSelector((state) => state.config.data?.EnquiryType);
  return (
    <Row gutter={16}>
      <Col lg={4} md={12} xs={24}>
        <RestSelect
          source="isContacted"
          placeholder="inboxes.calledOrContacted"
          isShowTooltip
          resourceData={CALLED_OR_CONTACTED_TYPES}
          valueProp="value"
          titleProp="text"
          formatText={(text) => i18next.t(text)}
        />
      </Col>
      <Col lg={4} md={12} xs={24}>
        <RestInputItem
          source={['outsideFilter', 'centre']}
          placeholder="inboxes.searchCentre"
          isShowTooltip
        />
      </Col>
      <Col lg={4} md={12} xs={24}>
        <RestInputItem
          source={['outsideFilter', 'sender']}
          placeholder="inboxes.searchSender"
          isShowTooltip
        />
      </Col>
      <Col lg={4} md={12} xs={24}>
        <RestSelect
          source="enquiryTypeId"
          placeholder="inboxes.enquiryType"
          isShowTooltip
          resourceData={enquiryTypes}
          valueProp="id"
          titleProp="name"
          formatText={(value) =>
            ENQUIRY_TYPES_CONST[value]?.text
              ? i18next.t(ENQUIRY_TYPES_CONST[value]?.text)
              : value
          }
        />
      </Col>
      <Col lg={4} md={12} xs={24}>
        <RestSelect
          source="centreEnquiryStatus"
          placeholder="inboxes.enquiryStatus"
          isShowTooltip
          resourceData={ENQUIRIES_STATUS_CONST}
          formatText={(text) => i18next.t(text)}
          valueProp="value"
          titleProp="text"
        />
      </Col>
      <Col lg={4} md={12} xs={24}>
        <RestRangePickerInput
          source="firstMessageDate"
          placeholder="common.time"
          isShowTooltip
          sourceGt={['outsideFilter', 'firstMessageStartDate']}
          sourceLt={['outsideFilter', 'firstMessageEndDate']}
        />
      </Col>
    </Row>
  );
};

export default Filter;
