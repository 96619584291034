import styled from 'styled-components';

export const ListUsersStyles = styled.div`
  .header-title {
    font-size: 28px;
    font-weight: 700;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #db147f;
  }

  .ant-tabs-tab-btn {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #857e7f;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
    bottom: 5px;
  }
`;
