import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Space } from 'antd';
import I18n from 'i18next';
import { ButtonWrapper, FooterButtonRowWrapper } from './styles';

const ButtonRow = (props) => {
  const { loading, disabled, onBack, type, showModal, okText } = props;
  if (showModal) {
    return (
      <FooterButtonRowWrapper className="showTotal">
        <Space size={15}>
          <Button
            className="btn-back"
            onClick={() => {
              onBack();
            }}
          >
            {I18n.t('button.cancel')}
          </Button>
          <Button
            type="primary"
            loading={loading}
            disabled={disabled}
            htmlType="submit"
          >
            {okText
              ? I18n.t(okText)
              : I18n.t(type === 'create' ? 'button.create' : 'button.save')}
          </Button>
        </Space>
      </FooterButtonRowWrapper>
    );
  }
  return (
    <Row gutter={8} type="flex" justify="end" style={{ marginTop: 20 }}>
      <Col lg={showModal ? 6 : 4} md={6} sm={12} xs={24}>
        <ButtonWrapper
          htmlType="submit"
          type="primary"
          loading={loading}
          disabled={disabled}
        >
          {okText
            ? I18n.t(okText)
            : I18n.t(type === 'create' ? 'button.create' : 'button.save')}
        </ButtonWrapper>
      </Col>
      <Col lg={showModal ? 6 : 4} md={6} sm={12} xs={24}>
        <ButtonWrapper
          onClick={() => {
            onBack();
          }}
        >
          {I18n.t('button.cancel')}
        </ButtonWrapper>
      </Col>
    </Row>
  );
};

ButtonRow.propTypes = {
  onBack: PropTypes.func,
  showModal: PropTypes.bool,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(['create', 'edit']),
  disabled: PropTypes.bool,
  okText: PropTypes.string,
};

ButtonRow.defaultProps = {
  type: 'edit',
};
export default ButtonRow;
