import { Typography } from 'antd';
import TableCustom from 'components/common/TableCustom';
import { formatPrimaryContact } from 'containers/Company/utils';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  getDataProvidersOfCompany,
  getLoadingProvidersOfCompany,
  getResourceDataProvidersOfCompany,
} from 'redux/providersOfCompany/selector';
import { formatDateTimeTable } from 'utils/textUtils';
import CentresProviderField from './CentresProviderField';
import CentresProviderModal from './CentresProviderModal';
import GroupActions from './GroupActions';

const ProvidersListTable = ({ retrieveList }) => {
  const centresProviderModalRef = useRef();

  const { push } = useHistory();

  const resourceFilter = useSelector(getResourceDataProvidersOfCompany) || {};

  const loading = useSelector(getLoadingProvidersOfCompany);

  const resourceData = useSelector(getDataProvidersOfCompany);

  const openCentresProviderModal = (record) => {
    centresProviderModalRef.current.open(record?.id);
  };

  const formatCentreName = useCallback(
    (data, record) => (
      <CentresProviderField
        data={record}
        handleClickViewAll={openCentresProviderModal}
      />
    ),
    [], // eslint-disable-line
  );

  const directionProviderDetail = (providerId) => {
    push(`/providers/${providerId}/show/summary`);
  };

  const columns = [
    {
      title: i18next.t('providers.provider'),
      dataIndex: 'name',
      sorter: true,
      render: (data, record) => (
        <div>
          <Typography.Link
            onClick={() => directionProviderDetail(record?.id)}
            className="link-custom-primary"
          >
            <strong>{data || ''}</strong>
          </Typography.Link>

          <p>{record?.user?.email || ''}</p>
        </div>
      ),
    },
    {
      title: i18next.t('centres.PRCode'),
      dataIndex: 'governmentReferenceId',
      sorter: true,
    },
    {
      title: i18next.t('summaries.totalCentres'),
      dataIndex: 'totalCentres',
      sorter: true,
    },
    {
      title: i18next.t('summaries.totalPlaces'),
      dataIndex: 'totalPlaces',
      sorter: true,
    },
    {
      title: i18next.t('users.centres'),
      dataIndex: 'firstCentre',
      render: formatCentreName,
      width: 200,
    },
    {
      title: i18next.t('companies.primaryContact'),
      dataIndex: 'user',
      render: formatPrimaryContact,
    },
    {
      title: i18next.t('centres.lastActivity'),
      dataIndex: 'lastActivityTime',
      render: formatDateTimeTable,
      sorter: true,
    },
    {
      title: '',
      dataIndex: 'id',
      width: 120,
      fixed: 'right',
      render: (id, record) => <GroupActions id={id} record={record} />,
    },
  ];

  const onChange = (e, filters, sorter) => {
    const formatSort =
      sorter && sorter.field && sorter.order
        ? `${sorter.order === 'descend' ? '-' : ''}${sorter.field}`
        : null;

    const restFilter = {
      offset: (e.current - 1) * e.pageSize,
      limit: e.pageSize,
      orderBy: formatSort,
      filter: resourceFilter.filter,
    };

    retrieveList({
      filter: restFilter,
      isRefresh: true,
    });
  };

  return (
    <div>
      <TableCustom
        xScroll={1200}
        isSorter
        columns={columns}
        onChange={onChange}
        data={resourceData}
        loading={loading}
        pagination={{
          pageSize: resourceFilter.limit,
          current: resourceFilter.offset / resourceFilter.limit + 1 || 1,
          total: resourceFilter.total,
        }}
      />
      <CentresProviderModal ref={centresProviderModalRef} />
    </div>
  );
};

ProvidersListTable.propTypes = {
  retrieveList: PropTypes.func,
};

ProvidersListTable.defaultProps = {
  retrieveList: () => null,
};

export default ProvidersListTable;
