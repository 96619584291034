import { SearchOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import React from 'react';
import { useSelector } from 'react-redux';

const CentreFilterForm = () => {
  const centreTypes = useSelector((state) => state.config.data?.CentreType);

  const stateOfAustralia = useSelector(
    (state) => state.config.data?.stateOfAustralia,
  );

  return (
    <Row gutter={16} type="flex">
      <Col xl={6} lg={6} md={12} xs={12}>
        <RestInputItem
          source={['outsideFilter', 'governmentCentreServiceId']}
          header="centres.SENumber"
          placeholder="centres.SENumber"
          dependencies={['type']}
        />
      </Col>

      <Col xl={6} lg={6} md={12} xs={12}>
        <RestInputItem
          source={['outsideFilter', 'cityOrPostCodeOrName']}
          placeholder="users.centreFilterQuery"
          isShowTooltip
          inputProps={{ prefix: <SearchOutlined /> }}
        />
      </Col>

      <Col xl={6} lg={6} md={12} xs={12}>
        <RestSelect
          resourceData={centreTypes || []}
          header="centres.centreServiceType"
          source={['outsideFilter', 'type']}
          placeholder="centres.centreServiceType"
          isShowTooltip
        />
      </Col>
      <Col xl={6} lg={6} md={12} xs={12}>
        <RestSelect
          source={['outsideFilter', 'state']}
          header="centres.state"
          placeholder="centres.state"
          resourceData={stateOfAustralia || []}
          isShowTooltip
        />
      </Col>
    </Row>
  );
};

export default CentreFilterForm;
