import React, { useCallback } from 'react';
import { Tabs } from 'antd';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { getSearchStatistics } from 'redux/searchStatistics/actions';
import HeaderSection from 'components/common/HeaderSection';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router';
import { getSearch } from 'utils/tools';
import PostCodeList from './Postcode';
import { SearchStatisticDetailStyles } from './styles';
import SuburbList from './Suburb';

const TABS = [
  {
    key: 'searchSuburb',
    text: 'searchStatistics.suburb',
    url: '/searchSuburb',
    component: SuburbList,
  },
  {
    key: 'searchPostcode',
    text: 'searchStatistics.postcode',
    url: '/searchPostcode',
    component: PostCodeList,
  },
];
const SearchStatisticDetail = () => {
  const dispatch = useDispatch();
  const { push, location } = useHistory();

  const { model } = useParams();

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, keepFilter }) => {
      dispatch(
        getSearchStatistics({
          data: {
            ...filter,
            filter: {
              ...filter.filter,
            },
          },
          options: {
            keepFilter,
            isRefresh,
            isOrderBy: false,
          },
        }),
      );
    },
    [dispatch],
  );

  const onChangeTab = (key) => {
    push(`/searchStatistics/${key}`);
  };

  const onBack = () => {
    push(`/searchStatistics`);
  };

  const pushQuery = useCallback(
    (searchStr) => {
      push(`${location.pathname}?${getSearch(searchStr)}`);
    },
    [push, location.pathname],
  );

  return (
    <SearchStatisticDetailStyles>
      <div className="flex">
        <ArrowLeftOutlined
          className="btn-back"
          twoToneColor="#5C595A"
          onClick={onBack}
        />
        <HeaderSection
          title="searchStatistics.searchStatisticDetail"
          className="ml-24 mt-4"
        />
      </div>

      <Tabs onChange={onChangeTab} activeKey={model} destroyInactiveTabPane>
        {TABS.map((tab) => (
          <Tabs.TabPane tab={i18next.t(tab.text)} key={tab.key}>
            <tab.component retrieveList={retrieveList} pushQuery={pushQuery} />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </SearchStatisticDetailStyles>
  );
};

export default SearchStatisticDetail;
