import React from 'react';
import { Modal, Select } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { editRoleUser } from 'redux/users/actions';
import { getRolesBelongToUser } from 'redux/config/selectors';

import { ArrowDownIcon } from 'components/common/SVGIcon';
import { DropDownWrapper } from './styles';

const { Option } = Select;

const DropdownUserRole = ({ roleId, disabled }) => {
  const roleBelongToUser = useSelector(getRolesBelongToUser);
  const dispatch = useDispatch();
  const { id } = useParams();

  const handleChange = (value) => {
    Modal.confirm({
      title: i18next.t('users.userRoleConfirmTitle'),
      content: i18next.t('users.userRoleConfirmContent'),
      onOk: () =>
        dispatch(
          editRoleUser({
            id,
            data: { roleId: value },
            role: roleBelongToUser?.find((item) => item.id === value),
          }),
        ),
    });
  };

  return (
    <DropDownWrapper
      value={roleId}
      style={{ width: '100%' }}
      onChange={handleChange}
      suffixIcon={<ArrowDownIcon style={{ pointerEvents: 'none' }} />}
      dropdownClassName="dropdown-status"
      disabled={disabled}
    >
      {roleBelongToUser?.map((item) => (
        <Option key={item.id} value={item.id}>
          {item.description}
        </Option>
      ))}
    </DropDownWrapper>
  );
};

DropdownUserRole.propTypes = {
  roleId: PropTypes.string,
  disabled: PropTypes.bool,
};

export default DropdownUserRole;
