import moment from 'moment';
import { FORMAT_DATE_SUBMIT } from 'configs/localData/constant';
import { mapValues } from 'lodash-es';

export const formatDueDateSubmit = (value) =>
  value ? moment(value).format(FORMAT_DATE_SUBMIT) : null;

export const formatOnSubmit = ({
  subject,
  description,
  dueDate,
  ...values
}) => ({
  ...values,
  subject: subject?.trim(),
  description: description?.trim(),
  dueDate: formatDueDateSubmit(dueDate),
});

export const onChangeTable = (e, filters, sorter, retrieveList) => {
  const formatSort =
    sorter && sorter.field && sorter.order
      ? `${sorter.order === 'descend' ? '-' : ''}${sorter.field}`
      : null;

  retrieveList({
    offset: (e.current - 1) * e.pageSize,
    limit: e.pageSize,
    orderBy: formatSort,
    filter: mapValues(filters, (value) => ({ $in: value })),
  });
};
