import styled from 'styled-components';
import { Card } from 'antd';

const CardStyles = styled(Card)`
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 2px 16px rgba(39, 40, 51, 0.12);

  &.template-selected {
    border: 2px solid ${({ theme }) => theme.palette.primary};
  }
  .ant-card-body {
    padding: 0 !important;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  #template-item-iframe {
    border: none;
    width: 100%;
  }

  .template-item {
    &__content-wrapper {
      height: 160px;
      overflow: hidden;
      position: relative;
      &:before {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        content: '';
      }
    }
    &__content {
      height: 100%;
      overflow: hidden;
    }
    &__footer {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      padding: 12px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &__actions {
      margin-top: 10px;
      .ant-btn {
        height: 32px !important;
        width: 32px !important;
        min-width: 32px !important;
        padding: 0 !important;
      }
      .anticon {
        font-size: 14px !important;
      }
    }
  }
`;

export default CardStyles;
