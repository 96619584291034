import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Space, Form } from 'antd';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { updateAutomatedResponses } from 'redux/automatedResponses/actions';
import { useAutomatedMessage } from './context';

const AutomatedForm = ({ setIsEdit, item }) => {
  const [form] = Form.useForm();
  const { setMessage } = useAutomatedMessage();
  const dispatch = useDispatch();

  const { id } = useParams();

  const [loading, setLoading] = useState();

  const onFinish = async (values) => {
    if (id && item.id && item.key) {
      setLoading(true);

      await dispatch(
        updateAutomatedResponses({
          centreId: id,
          id: item.id,
          key: item.key,
          data: { ...values, type: 'json' },
        }),
      ).then(({ payload }) => {
        if (payload?.id) setIsEdit(false);
      });

      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsEdit(false);
    setMessage(item?.data?.text);
  };

  const onChangeMessage = ({ target: { value } }) => {
    setMessage(value);
  };

  useEffect(() => {
    form.setFieldsValue(item);
  }, [item, form]);

  return (
    <div>
      <Form form={form} onFinish={onFinish} autoComplete="off">
        <Form.Item
          name={['data', 'text']}
          rules={[{ required: true, message: i18next.t('error.required') }]}
        >
          <Input.TextArea
            onChange={onChangeMessage}
            placeholder={i18next.t('input.enterHere')}
            autoSize={{ minRows: 4, maxRows: 10 }}
          />
        </Form.Item>
        <div className="text-right mt-20">
          <Space>
            <Button className="btn-line-primary" onClick={handleCancel}>
              {i18next.t('button.cancel')}
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              {i18next.t('button.save')}
            </Button>
          </Space>
        </div>
      </Form>
    </div>
  );
};

AutomatedForm.propTypes = {
  setIsEdit: PropTypes.func,
  item: PropTypes.object,
};

export default AutomatedForm;
