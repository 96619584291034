import styled from 'styled-components';

export const ProviderFormStyles = styled.div`
  .ant-form-item-label {
    font-weight: 700;
    color: #9c9d9b;
    font-size: 14px;
  }

  .input-field-style {
    margin-bottom: 10px !important;
    margin-top: 0px !important;
  }
`;
