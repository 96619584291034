export const I18N_PREFIX_KEY = 'jobApplicationsTracker';

export const JOB_LISTING_STATUSES = [
  {
    value: 'Open',
    text: `${I18N_PREFIX_KEY}.jobListingStatus.open`,
    color: '#3EBD5C',
    backgroundColor: '#EDF9F0',
  },
  {
    value: 'Closed',
    text: `${I18N_PREFIX_KEY}.jobListingStatus.closed`,
    color: '#101828',
    backgroundColor: '#EAECF0',
  },
  {
    value: 'Deleted',
    text: `${I18N_PREFIX_KEY}.jobListingStatus.deleted`,
    color: '#E22B30',
    backgroundColor: '#FEEFEF',
  },
];

export const APPLICATION_STATUS = {
  SUBMITTED: {
    text: `${I18N_PREFIX_KEY}.applicationStatus.submitted`,
    value: 'Submitted',
  },
  IN_PROGRESS: {
    text: `${I18N_PREFIX_KEY}.applicationStatus.inProgress`,
    value: 'In progress',
  },
  INTERVIEW: {
    text: `${I18N_PREFIX_KEY}.applicationStatus.interview`,
    value: 'Interview',
  },
  VERIFIED: {
    text: `${I18N_PREFIX_KEY}.applicationStatus.verified`,
    value: 'Verified',
  },
  OFFERED: {
    text: `${I18N_PREFIX_KEY}.applicationStatus.offered`,
    value: 'Offered',
  },
  EMPLOYED: {
    text: `${I18N_PREFIX_KEY}.applicationStatus.employed`,
    value: 'Employed',
  },
  DECLINED: {
    text: `${I18N_PREFIX_KEY}.applicationStatus.declined`,
    value: 'Declined',
  },
  CANCELLED: {
    text: `${I18N_PREFIX_KEY}.applicationStatus.cancelled`,
    value: 'Cancelled',
  },
};

export const DEFAULT_CURRENT_PAGE = 1;

export const LIMIT_ITEM = 10;

export const SORT_OPTIONS = {
  APPLICANT_NAME_ASC: 'applicantName',
  APPLICANT_NAME_DESC: '-applicantName',
  APPLICATION_STATUS_ASC: 'applicationStatus',
  APPLICATION_STATUS_DESC: '-applicationStatus',
  APPLIED_TIME_ASC: 'appliedTime',
  APPLIED_TIME_DESC: '-appliedTime',
  JOB_MATCH_SCORE_ASC: 'jobMatchScore',
  JOB_MATCH_SCORE_DESC: '-jobMatchScore',
  JOB_REFERENCE_ASC: 'jobReference',
  JOB_REFERENCE_DESC: '-jobReference',
  CAMPAIGN_ASC: 'campaign',
  CAMPAIGN_DESC: '-campaign',
  JOB_ROLE_ASC: 'jobRole',
  JOB_ROLE_DESC: '-jobRole',
  JOB_TEMPLATE_ASC: 'jobTemplate',
  JOB_TEMPLATE_DESC: '-jobTemplate',
  JOB_TITLE_ASC: 'jobTitle',
  JOB_TITLE_DESC: '-jobTitle',
  JOB_TYPE_ASC: 'jobType',
  JOB_TYPE_DESC: '-jobType',
  SALARY_ASC: 'salary',
  SALARY_DESC: '-salary',
  WORKPLACE_ASC: 'workplace',
  WORKPLACE_DESC: '-workplace',
  BRAND_ASC: 'brand',
  BRAND_DESC: '-brand',
  SUBURB_ASC: 'suburb',
  SUBURB_DESC: '-suburb',
  STATE_ASC: 'state',
  STATE_DESC: '-state',
  QUALIFICATION_ASC: 'qualification',
  QUALIFICATION_DESC: '-qualification',
  DISTANCE_ASC: 'distance',
  DISTANCE_DESC: '-distance',
  AVAILABLE_ASC: 'available',
  AVAILABLE_DESC: '-available',
  WORKPLACE_TYPE_ASC: 'workplaceType',
  WORKPLACE_TYPE_DESC: '-workplaceType',
  COMPANY_ASC: 'company',
  COMPANY_DESC: '-company',
  JOB_LISTING_STATUS_ASC: 'jobListingStatus',
  JOB_LISTING_STATUS_DESC: '-jobListingStatus',
  LAST_UPDATED_ASC: 'lastUpdated',
  LAST_UPDATED_DESC: '-lastUpdated',
};

export const JOB_PRIMARY_PROGRESS_BAR_COLOR = '#2BADA7';

export const DEFAULT_TAG_BACKGROUND_COLOR = '#F7F8F9';

export const DEFAULT_TAG_BORDER_COLOR = '#D0D5DD';

export const DASH_PLACEHOLDER_CHAR = '-';

export const DEFAULT_WORKPLACE_TYPE = 'Centre';

export const FILTER_NAME = {
  jobReference: 'jobReference',
  workplace: 'workplace',
  workplaceType: 'workplaceType',
  company: 'company',
  brand: 'brand',
  state: 'state',
  jobTitle: 'jobTitle',
  jobRole: 'jobRole',
  jobType: 'jobType',
  jobListingStatus: 'jobListingStatus',
  applicant: 'applicant',
  status: 'status',
  appliedTime: 'appliedTime',
  lastUpdated: 'lastUpdated',
};

export const DEFAULT_ORDER_DROPDOWN = {
  orderBy: 'name',
};
