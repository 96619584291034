import React, { useCallback } from 'react';
import FilterCustom from 'components/common/FilterCustom';
import {
  DEFAULT_CURRENT_PAGE,
  SORT_OPTIONS,
} from 'containers/JobApplicationsTracker/constants';
import { useLocation } from 'react-router-dom';
import { getFilterFromUrl } from 'utils/tools';
import { formatFromToTimeFilterSubmit } from 'utils/dataUtils';
import PropTypes from 'prop-types';
import FilterForm from 'containers/JobApplicationsTracker/components/FilterForm';
import { FilterContainerStyles } from 'containers/JobApplicationsTracker/components/FilterContainer/styles';

const FilterContainer = ({ fetchDataList, pushQueryUrl }) => {
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);

  const onSubmitFilter = ({ appliedTime, lastUpdated, ...fieldValues }) => {
    const restFilter = {
      size: filter.size,
      page: DEFAULT_CURRENT_PAGE,
      sorts: filter.sorts || [SORT_OPTIONS.APPLIED_TIME_DESC],
      filter: {
        appliedTime: formatFromToTimeFilterSubmit(appliedTime),
        lastUpdated: formatFromToTimeFilterSubmit(lastUpdated),
        ...fieldValues,
      },
    };

    fetchDataList({
      filter: { ...restFilter },
      isRefresh: true,
    });

    pushQueryUrl({
      'sorts[]': filter.sorts,
      ...(restFilter || {}),
    });
  };

  const onClearFilter = useCallback(() => {
    fetchDataList({
      filter: {
        size: filter.size,
        page: filter.page,
        sorts: filter.sorts || [SORT_OPTIONS.APPLIED_TIME_DESC],
      },
      isRefresh: true,
    });
    pushQueryUrl({
      'sorts[]': filter.sorts,
      size: filter.size,
      page: filter.page,
    });
  }, [fetchDataList, filter, pushQueryUrl]);

  return (
    <FilterContainerStyles>
      <FilterCustom
        onSubmitFilter={onSubmitFilter}
        onClearFilter={onClearFilter}
        responsiveFilter={{
          xxl: 20,
          xl: 18,
          lg: 24,
          md: 24,
          xs: 24,
        }}
        responsiveAction={{
          xxl: 4,
          xl: 6,
          lg: 24,
          md: 24,
          xs: 24,
        }}
      >
        <FilterForm />
      </FilterCustom>
    </FilterContainerStyles>
  );
};

FilterContainer.propTypes = {
  fetchDataList: PropTypes.func,
  pushQueryUrl: PropTypes.func,
};

export default FilterContainer;
