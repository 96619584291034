import styled from 'styled-components';

const MobilePreviewIframeStyles = styled.div`
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
  border: 0px;
  background-image: url(/static/images/iphone-background.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: 730px;
  width: 364px;
  .preview-mobile-content {
    position: absolute;
    inset: 60px 24px 50px 21px;
    iframe#preview-mobile-iframe {
      border: 0px;
      height: 100%;
      width: 100%;
    }
  }
`;

export default MobilePreviewIframeStyles;
