/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Col, Form, Input, Radio, Row } from 'antd';
import i18next from 'i18next';
import { ApproveButton, ButtonSwapper, DenyButton } from './styles';
import { HeaderCommentStyles } from '../Reviews/styles';

const DisputeForm = ({ record }) => {
  const [value, setValue] = useState();

  const onChangeValue = (event) => {
    setValue(event.target.value);
  };
  return (
    <ButtonSwapper>
      <Form.Item name="status">
        <Row gutter={[0, 10]}>
          <Col md={4} sm={6} xs={24}>
            <strong>{i18next.t('disputeReviewRequests.myAction')}</strong>
          </Col>
          <Col md={20} sm={18} xs={24}>
            {record?.status === 'pending' ? (
              <Radio.Group
                value={value}
                onChange={onChangeValue}
                optionType="button"
                buttonStyle="solid"
              >
                <ApproveButton value="approved">
                  {i18next.t('button.approve')}
                </ApproveButton>
                <DenyButton value="denied">
                  {i18next.t('button.deny')}
                </DenyButton>
              </Radio.Group>
            ) : (
              <HeaderCommentStyles>{record?.status}</HeaderCommentStyles>
            )}
          </Col>
        </Row>
      </Form.Item>
      <Form.Item name="message">
        <Row gutter={[0, 10]}>
          <Col md={4} sm={6} xs={24}>
            <strong>{i18next.t('input.notes.name')}</strong>
          </Col>
          <Col md={20} sm={18} xs={24}>
            {record?.status === 'pending' ? (
              <Input.TextArea
                placeholder={i18next.t('input.notes.placeholder')}
              />
            ) : (
              <HeaderCommentStyles>{record?.message}</HeaderCommentStyles>
            )}
          </Col>
        </Row>
      </Form.Item>
    </ButtonSwapper>
  );
};
DisputeForm.propsType = {
  record: DisputeForm.object,
};
export default DisputeForm;
