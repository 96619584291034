import { Col, Row } from 'antd';
import { ActivitiesNotesLevel } from 'configs/localData';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { ActivitiesNotesListStyles } from 'containers/Providers/components/ActivitiesNotesTab/styles';
import useRouter from 'hooks/useRouter';
import ActivitiesNotesTable from './components/CompanyLevel/ActivitiesNotesTable';
import ProviderCreateModal from './components/ProviderLevel/CreateModal';
import CompanyCreateModal from './components/CompanyLevel/CreateModal';
import HeaderList from './HeaderList';
import NoteDetail from './NoteDetail';
import ProviderActivitiesNotesTable from './components/ProviderLevel/ActivitiesNotesTable';

const ActivitiesNotesTab = () => {
  const createModalRef = useRef();
  const { push, query, ...rest } = useRouter();
  const searchParams = useMemo(
    () => new URLSearchParams(rest?.location?.search),
    [rest.location],
  );

  const openCreateModal = () => {
    createModalRef.current && createModalRef.current.open();
  };

  const [value, setValue] = useState(searchParams.get('activityTab'));

  useEffect(() => {
    const activityTab = searchParams.get('activityTab');
    if (activityTab) {
      setValue(searchParams.get('activityTab'));
    } else {
      searchParams.set('activityTab', ActivitiesNotesLevel.companyLevel);
      setValue(searchParams.get('activityTab'));
    }
  }, [searchParams]); // eslint-disable-line

  const showNoteDetailLevels = (activitiesLevel) => {
    switch (activitiesLevel) {
      case ActivitiesNotesLevel.companyLevel:
        return <NoteDetail />;
      case ActivitiesNotesLevel.providerLevel:
        return <NoteDetail isProviderName />;
      default:
        return <NoteDetail />;
    }
  };

  const showNoteTableLevels = (activitiesLevel) => {
    switch (activitiesLevel) {
      case ActivitiesNotesLevel.companyLevel:
        return (
          <>
            <ActivitiesNotesTable />
            <CompanyCreateModal ref={createModalRef} />
          </>
        );
      case ActivitiesNotesLevel.providerLevel:
        return (
          <>
            <ProviderActivitiesNotesTable />
            <ProviderCreateModal ref={createModalRef} />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Row gutter={[24, 24]}>
      <Col xl={17} lg={24} md={24} xs={24}>
        <ActivitiesNotesListStyles>
          <HeaderList
            openCreateModal={openCreateModal}
            setValue={setValue}
            value={value}
          />
          {showNoteTableLevels(value)}
        </ActivitiesNotesListStyles>
      </Col>
      <Col xl={7} lg={24} md={24} xs={24}>
        {showNoteDetailLevels(value)}
      </Col>
    </Row>
  );
};

export default ActivitiesNotesTab;
