import React from 'react';
import List from 'containers/rest/List';
import { Switch } from 'antd';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import DeleteButton from 'components/RestActions/DeleteButton';
import ImageField from 'components/RestField/ImageField';
import Filter from '../components/Filter';

const FeaturesList = (props) => (
  <List
    {...props}
    resource="features"
    widthTable="1000px"
    hasSearch={false}
    filter={<Filter />}
    defaultOptions={{ isGetConfig: true }}
  >
    <ImageField
      source="icon.url"
      header="features.image"
      style={{ height: 50, width: 'auto', objectFit: 'contain' }}
    />
    <RestFieldItem source="name" header="features.name" />
    <RestFieldItem
      source="isActive"
      header="features.isActive"
      valueProp="checked"
      component={<Switch />}
    />
    <ActionGroup widthAction={100}>
      <EditButton />
      <DeleteButton customMessage="features.customMessageDelete" />
    </ActionGroup>
  </List>
);

export default FeaturesList;
