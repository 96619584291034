import React from 'react';
import { useDispatch } from 'react-redux';
import { Switch } from 'antd';
import { editArticles } from 'redux/articles/actions';
import PropTypes from 'prop-types';
import { PREFIX_URL_API } from 'configs/localData/constant';

const SwitchAction = ({ value, id, source }) => {
  const dispatch = useDispatch();

  const onChangeSponsored = (checked) => {
    dispatch(
      editArticles({
        data: { id, [source]: checked },
        options: { prefixUrl: PREFIX_URL_API },
      }),
    );
  };

  return <Switch checked={value} onChange={onChangeSponsored} />;
};

SwitchAction.propTypes = {
  value: PropTypes.bool,
  id: PropTypes.string,
  source: PropTypes.string,
};

export default SwitchAction;
