import styled from 'styled-components';

export default styled.div`
  .logout-section {
    margin-top: 15px;
    @media only screen and (min-width: 640px) {
      display: none;
    }
  }
  .change-password-section {
    margin-top: 15px;
    @media only screen and (max-width: 640px) {
      & > .ant-btn {
        width: 100%;
      }
    }
  }
`;
