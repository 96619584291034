import React, { useMemo, useEffect } from 'react';
import i18next from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import RestShow from 'containers/rest/Show';
import { PREFIX_URL } from 'configs/localData/constant';

import { getSummariesCompany } from 'redux/companies/actions';
import { getSummariesActivities } from 'redux/activities/actions';
import { Company } from './context';
import CompanyTabs from '../components/CompanyTabs';

const CompanyShow = (props) => {
  const dispatch = useDispatch();

  const currentData =
    useSelector((state) => state.companies?.currentData) || {};

  const { id: companyId } = useParams();

  const customBreadcrumb = useMemo(
    () => [
      {
        title: i18next.t('companies.header'),
        path: '/companies',
      },
      {
        title: i18next.t('companies.companyDetail'),
      },
      {
        title: currentData?.name || i18next.t('error.waitingUpdate'),
      },
    ],
    [currentData.name],
  );

  useEffect(() => {
    if (companyId) {
      dispatch(getSummariesCompany({ companyId }));
      dispatch(getSummariesActivities({ companyId }));
    }
  }, [companyId]); // eslint-disable-line

  return (
    <Company>
      <RestShow
        {...props}
        resource="companies"
        customBreadcrumb={customBreadcrumb}
        defaultOptions={{ prefixUrl: PREFIX_URL.superAdmin }}
        header="companies.companyDetail"
        isResetStyle
      >
        <CompanyTabs />
      </RestShow>
    </Company>
  );
};

export default CompanyShow;
