import { createAsyncThunk } from '@reduxjs/toolkit';
import { getBannersApi, uploadBannersApi } from 'api/banners';
import { apiWrapper } from 'utils/reduxUtils';

export const getBanners = createAsyncThunk(
  'banners/getBanners',
  async (payload, thunkApi) => {
    try {
      const response = await apiWrapper(
        { isShowProgress: true, isShowSuccessNoti: true },
        getBannersApi,
        payload,
      );

      return response;
    } catch (error) {
      return thunkApi.rejectWithValue({ data: error });
    }
  },
);

export const uploadBanners = createAsyncThunk(
  'banners/uploadBanners',
  async (payload, thunkApi) => {
    try {
      const response = await apiWrapper(
        { isShowProgress: true, isShowSuccessNoti: true },
        uploadBannersApi,
        payload,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
