import React, { useCallback, useEffect } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Tabs } from 'antd';

import EnquiriesList from 'containers/Enquiries/List';
import { useCentre } from 'containers/Centres/Show/context';
import FeaturesTab from '../FeaturesTab';
import SummaryTab from '../SummaryTab';
import CentreInformationTab from '../CentreInformationTab';
import HoursTab from '../HoursTab';
import ServicesTab from '../ServicesTab';
import RatingsReviewsTab from '../RatingsReviewsTab';
import CentreTabsStyles from './styles';

const { TabPane } = Tabs;

const CentreTabs = ({ record }) => {
  const { setCollapsed } = useCentre();

  useEffect(
    () => {
      if (record?.totalListingStrength === 100 && !record?.isFinishedStep) {
        setCollapsed(false);
      } else setCollapsed(true);
    },
    [record?.totalListingStrength, record?.isFinishedStep], // eslint-disable-line
  );

  const { push } = useHistory();

  const { id, modelDetail } = useParams();

  const currentCentre = useSelector((state) => state.centres.currentData);

  const TABS = [
    {
      key: 'summary',
      title: 'centreDetail.tabs.summary',
      TabComponent: SummaryTab,
    },
    {
      key: 'centreInformation',
      title: 'centreDetail.tabs.centreInformation',
      TabComponent: CentreInformationTab,
    },
    {
      key: 'hours',
      title: 'centreDetail.tabs.hours',
      TabComponent: HoursTab,
    },
    {
      key: 'services',
      title: 'centreDetail.tabs.services',
      TabComponent: ServicesTab,
    },
    {
      key: 'features',
      title: 'centreDetail.tabs.features',
      TabComponent: FeaturesTab,
    },
    {
      key: 'nqsRating',
      title: 'centreDetail.tabs.ratingsReviews',
      TabComponent: RatingsReviewsTab,
    },
    {
      key: 'enquiries',
      title: 'centreDetail.tabs.enquiries',
      TabComponent: EnquiriesList,
    },
  ];

  const onChange = useCallback(
    (key) => {
      push(`/centres/${id}/show/${key}`);
    },
    [push, id],
  );

  return (
    <CentreTabsStyles>
      <Tabs activeKey={modelDetail} onChange={onChange}>
        {TABS.map(({ title, key, TabComponent, ...tab }) => (
          <TabPane tab={i18next.t(title)} key={key}>
            <TabComponent {...tab} record={currentCentre} />
          </TabPane>
        ))}
      </Tabs>
    </CentreTabsStyles>
  );
};

CentreTabs.propTypes = {
  record: PropTypes.object,
};

export default CentreTabs;
