import React from 'react';
import PropTypes from 'prop-types';
import Radio from 'antd/lib/radio';
import i18next from 'i18next';
import { ACTIVITY_NOTE_LEVEL } from 'configs/localData';
import { useDispatch } from 'react-redux';
import { clearCurrentActivities } from 'redux/activities/actions';
import useRouter from 'hooks/useRouter';

const SwitcherAction = ({ setValue, value }) => {
  const dispatch = useDispatch();
  const { push, query, ...rest } = useRouter();

  const searchParams = new URLSearchParams(rest?.location?.search);

  const onChange = (e) => {
    push(
      `/companies/${query?.id}/show/activitiesNotes?activityTab=${e.target.value}`,
    );
    dispatch(clearCurrentActivities());
    setValue(e.target.value);
  };

  return (
    <div className="switcher-actions">
      <Radio.Group
        size="small"
        value={searchParams.get('activityTab') || value}
        onChange={onChange}
      >
        {ACTIVITY_NOTE_LEVEL.map((item, index) => (
          <Radio.Button value={item.value} key={String(index)}>
            <item.IconCPN />
            {i18next.t(item.text)}
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
};

SwitcherAction.propTypes = {
  setValue: PropTypes.func,
  value: PropTypes.string,
};

export default SwitcherAction;
