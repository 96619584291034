import React from 'react';
import Edit from 'containers/rest/Edit';
import Form from '../components/Form';

const BusinessUsersEdit = (props) => (
  <Edit {...props} resource="businessUsers">
    <Form />
  </Edit>
);

export default BusinessUsersEdit;
