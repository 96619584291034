import { createSelector } from 'reselect';

const getRawDataSearchStatistics = (state) => state.searchStatistics?.data;

const getRawResourceDataSearchStatistics = (state) =>
  state.searchStatistics?.resourceData;

const getRawSearchStatisticsLoading = (state) =>
  state.searchStatistics?.loading;

export const getDataSearchStatistics = createSelector(
  [getRawDataSearchStatistics],
  (data) => data,
);

export const getResourceDataSearchStatistics = createSelector(
  [getRawResourceDataSearchStatistics],
  (data) => data,
);

export const getLoadingSearchStatistics = createSelector(
  [getRawSearchStatisticsLoading],
  (data) => data,
);
