import React from 'react';
import PropTypes from 'prop-types';
import Space from 'antd/lib/space';

import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  setCurrentDataActivities,
  delActivities,
  clearCurrentActivities,
  getSummariesActivities,
} from 'redux/activities/actions';

import EditButton from 'components/RestActions/EditButton';
import DeleteButton from 'components/RestActions/DeleteButton';
import { PREFIX_URL } from 'configs/localData/constant';
import { getProvidersLevelOfCompany } from 'redux/providersLevelOfCompany/actions';

const GroupActions = ({ id, record }) => {
  const dispatch = useDispatch();
  const { id: companyId } = useParams();

  const deleteItem = () =>
    dispatch(
      delActivities({
        data: { id },
        options: { prefixUrl: PREFIX_URL.superAdmin },
      }),
    ).then(({ payload }) => {
      if (payload?.success) {
        dispatch(clearCurrentActivities());
        dispatch(getSummariesActivities({ companyId }));
        dispatch(
          getProvidersLevelOfCompany({
            data: {
              filter: {
                companyId,
                providerIds: 'all',
              },
            },
            options: { prefixUrl: PREFIX_URL.superAdmin, isRefresh: true },
          }),
        );
      }
    });

  const onClickViewDetail = () => {
    dispatch(setCurrentDataActivities(record));
  };

  return (
    <Space>
      <EditButton isView onClickCustom={onClickViewDetail} />
      <DeleteButton
        resource="activitiesAndNotes"
        record={record}
        deleteItem={deleteItem}
        nameProp="subject"
      />
    </Space>
  );
};

GroupActions.propTypes = {
  id: PropTypes.string,
  record: PropTypes.object,
};

export default GroupActions;
