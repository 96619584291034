import React from 'react';
import { Col, Row, Progress } from 'antd';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import ShowHideBtn from './ShowHideBtn';

const ListingStrength = ({ percent }) => (
  <div>
    <Row justify="space-between" align="middle">
      <Col className="title-info">
        {`${i18next.t('listingStrength.title')} (${percent || 0}%)`}
      </Col>
      <Col>
        <ShowHideBtn />
      </Col>
    </Row>
    <Row>
      <Progress
        className="title-info-other"
        percent={percent}
        showInfo={false}
      />
    </Row>
  </div>
);

ListingStrength.propTypes = {
  percent: PropTypes.number,
};

export default ListingStrength;
