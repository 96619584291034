import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { getIdByUrl } from 'utils/tools';
import { PREFIX_URL } from 'configs/localData/constant';

import Create from 'containers/rest/Create';
import { getBrandsOfCompany } from 'redux/brandsOfCompany/actions';
import Form from '../components/Form';

const CreateBrandOfCompany = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const companyId = getIdByUrl({ resource: 'companies' }, location);

  const formatOnSubmit = (values) => ({
    companyId,
    ...values,
  });

  const successActionAfterCreate = () => {
    companyId &&
      dispatch(
        getBrandsOfCompany({
          id: companyId,
          options: {
            isRefresh: true,
            keepFilter: true,
          },
        }),
      );
  };

  return (
    <Create
      {...props}
      defaultOptions={{
        prefixUrl: PREFIX_URL.superAdmin,
        successAction: successActionAfterCreate,
      }}
      resource="brands"
      formatOnSubmit={formatOnSubmit}
    >
      <Form isDetailCompany />
    </Create>
  );
};

export default CreateBrandOfCompany;
