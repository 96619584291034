import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import { closeModal as closeModalAction } from 'redux/modal/slice';

import { useLocation, useHistory } from 'react-router';
import { handleApiError } from 'utils/validateUtils';

import RestCreateComponent from 'components/RestLayout/Create';
import Text from 'components/common/Text';
import { CloseSquareIcon } from 'components/common/SVGIcon';
import CRUDActions from '../../../redux/crudActions';
import crudSelectors from '../../../redux/crudSelectors';

const RestCreate = (props) => {
  const { showModal, header, defaultOptions, customOnSubmit, resource } = props;
  const route = useSelector((state) => state.modal.current);
  const record = useSelector((state) =>
    crudSelectors[props.resource].getDefaultCreateData(state, props),
  );

  const error = useSelector(crudSelectors[props.resource].getError);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const onBack = () => {
    if (!route) {
      history.goBack();
    } else {
      dispatch(closeModalAction());
    }
  };

  const gotoShowPage = (id) => {
    history.push(`${location.pathname.replace('create', '')}/${id}/edit`);
  };

  const onSubmit = (data, form) => {
    if (customOnSubmit) {
      dispatch(customOnSubmit({ data, options: defaultOptions }));
    } else
      dispatch(
        CRUDActions[props.resource].create({ data, options: defaultOptions }),
      ).then(({ payload: { data } }) => {
        handleApiError(data, form);
        if (data.id && !(!defaultOptions || defaultOptions.isBack === false)) {
          onBack();
        }
      });
  };

  const content = useMemo(
    () => (
      <RestCreateComponent
        {...props}
        gotoShowPage={gotoShowPage}
        onSubmit={onSubmit}
        onBack={onBack}
        record={record}
        error={error}
        showModal={showModal}
      />
    ),
    // eslint-disable-next-line
    [],
  );

  return !showModal ? (
    content
  ) : (
    <>
      {header !== null && (
        <Text type="h4White" className="modalTitleContent">
          <div className="modalTitle">
            {!header || typeof header === 'string'
              ? I18n.t(header || `${resource}.createPage`)
              : header}
          </div>
          <CloseSquareIcon onClick={onBack} className="modalBtnBack" />
        </Text>
      )}
      {content}
    </>
  );
};

RestCreate.propTypes = {
  closeModal: PropTypes.func,
  resource: PropTypes.string,
  header: PropTypes.any,
  route: PropTypes.string,
  showModal: PropTypes.bool,
  goBack: PropTypes.func,
  goShowPageWhenSuccess: PropTypes.bool,
  defaultOptions: PropTypes.object,
  customOnSubmit: PropTypes.func,
};

RestCreate.defaultProps = {
  goShowPageWhenSuccess: true,
  defaultOptions: {
    isBack: true,
  },
};
export default RestCreate;
