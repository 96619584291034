import { makeActions } from 'redux/crudCreator';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiWrapper } from 'utils/reduxUtils';
import {
  getAllJobApplicationsTrackerApi,
  getJobApplicationsTrackerConfigApi,
} from 'api/jobApplicationsTracker';

export const MODEL_NAME = 'jobApplicationsTracker';

export const jobApplicationsTrackerActions = makeActions(MODEL_NAME);

export const getAllJobApplicationsTrackerData = createAsyncThunk(
  `${MODEL_NAME}/getAllJobApplicationsTrackerData`,
  async (payload, thunkAPI) => {
    try {
      const { size, page, filter, sorts } =
        thunkAPI.getState().jobApplicationsTracker?.resourceData || {};
      return await apiWrapper({}, getAllJobApplicationsTrackerApi, {
        size,
        page,
        filter,
        sorts,
        ...payload?.data,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue({ id: payload.data.id, data: error });
    }
  },
);

export const getJobApplicationsTrackerConfig = createAsyncThunk(
  `${MODEL_NAME}/getJobApplicationsTrackerConfig`,
  async (payload, thunkAPI) => {
    try {
      return await apiWrapper({}, getJobApplicationsTrackerConfigApi, {});
    } catch (error) {
      return thunkAPI.rejectWithValue({ id: payload.data.id, data: error });
    }
  },
);
