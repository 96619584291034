import React from 'react';
import PropTypes from 'prop-types';
import TimezoneSelect from 'react-timezone-select';

const CustomTZSelect = ({ onChange, ...props }) => {
  const onChangeTZ = (data) => {
    onChange && onChange(data.value);
  };

  return <TimezoneSelect onChange={onChangeTZ} {...props} />;
};

CustomTZSelect.propTypes = {
  onChange: PropTypes.func,
};

export default CustomTZSelect;
