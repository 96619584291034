import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Avatar, Tag } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import {
  ENQUIRIES_STATUS_CONST,
  ENQUIRY_TYPES,
  REASON_ARCHIVE_ENQUIRY,
} from 'configs/localData';

import TagCustom from 'components/common/TagCustom';
import { MessageHeaderStyles } from './styles';
import ActionButton from './ActionButton';

const MessageHeader = ({
  user,
  enquiryType,
  id,
  centreHasNewMessage,
  archivedAt,
  archivedReason,
}) => {
  const enquiryTypeItem = useMemo(
    () =>
      ENQUIRY_TYPES.find((item) => item.value === enquiryType?.name) ||
      ENQUIRY_TYPES[0],
    [enquiryType],
  );

  const archivedReasonName = useMemo(() => {
    const resTex = REASON_ARCHIVE_ENQUIRY.find(
      (item) => item.value === archivedReason,
    )?.text;

    return resTex ? ` - ${i18next.t(resTex)}` : '';
  }, [archivedReason]);

  return (
    <MessageHeaderStyles>
      <div className="left-section">
        <Avatar size={40} src={user?.avatar} icon={<UserOutlined />} />
        <div className="info-section">
          <div className="title">
            {`${user?.firstName || ''} ${user?.lastName || ''}`}
          </div>
          <div className="status">
            {enquiryTypeItem && (
              <Tag color={enquiryTypeItem.color}>
                {i18next.t(enquiryTypeItem.text)}
              </Tag>
            )}
          </div>
        </div>
      </div>

      <div className="right-section">
        {archivedAt && (
          <TagCustom
            name={`${i18next.t(
              ENQUIRIES_STATUS_CONST.archived.text,
            )} ${archivedReasonName}`}
            color={ENQUIRIES_STATUS_CONST.archived.fillColor}
            backgroundColor={ENQUIRIES_STATUS_CONST.archived.backgroundColor}
          />
        )}
        {id ? (
          <ActionButton id={id} centreHasNewMessage={centreHasNewMessage} />
        ) : null}
      </div>
    </MessageHeaderStyles>
  );
};

MessageHeader.propTypes = {
  user: PropTypes.object,
  enquiryType: PropTypes.object,
  id: PropTypes.string,
  centreHasNewMessage: PropTypes.bool,
  archivedAt: PropTypes.string,
  archivedReason: PropTypes.string,
};

export default MessageHeader;
