import React from 'react';
import { Checkbox, Modal } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { PREFIX_API_VER_2, PREFIX_URL } from 'configs/localData/constant';
import { editAllClickThroughTracker } from 'redux/clickThroughTracker/actions';
import { useLocation } from 'react-router';
import { getFilterFromUrl } from 'utils/tools';
import {
  DEFAULT_SORT_CLICK_THROUGH_TRACKER,
  SORT_ARRAY_VALUE,
} from '../components/LocalData/constant';

const { confirm } = Modal;

const MarkContactAction = ({ isContacted, id, retrieveList }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);
  const showConfirm = (e) => {
    confirm({
      title: i18next.t('inboxes.titleMarkedContacted'),
      content: i18next.t(
        e.target.checked
          ? 'clickThroughTracker.contentMarkedContacted'
          : 'clickThroughTracker.contentUnMarkedContacted',
      ),
      onOk() {
        return new Promise((resolve) => {
          if (id) {
            resolve(
              dispatch(
                editAllClickThroughTracker({
                  data: { id, isContacted: e.target.checked },
                  options: {
                    prefixUrl: PREFIX_URL.superAdmin,
                    customApiResource: 'click-throughs',
                    apiVersion: PREFIX_API_VER_2,
                  },
                }),
              ).then(({ payload }) => {
                if (payload?.isSuccess) {
                  retrieveList({
                    filter: {
                      sorts: filter[SORT_ARRAY_VALUE] || [
                        DEFAULT_SORT_CLICK_THROUGH_TRACKER,
                      ],
                      page: filter.page,
                      size: filter.size,
                    },
                    isRefresh: true,
                  });
                }
              }),
            );
          }
        });
      },
    });
  };
  return <Checkbox checked={isContacted} onChange={showConfirm} />;
};

MarkContactAction.propTypes = {
  isContacted: PropTypes.bool,
  id: PropTypes.number,
  retrieveList: PropTypes.func,
};

export default MarkContactAction;
