import React from 'react';
import TableCustom from 'components/common/TableCustom';
import i18next from 'i18next';
import { sortNumber, sortString } from 'utils/sortUtils';
import { formatMoney } from 'utils/textUtils';
import { useSelector } from 'react-redux';

const TableEvents = () => {
  const { data, loading } = useSelector((state) => state.statistic.eventInfo);

  const columns = [
    {
      title: i18next.t('events.inAppsEvents'),
      dataIndex: 'eventName',
      sorter: (a, b) => sortString(a, b, 'eventName'),
    },
    {
      title: i18next.t('events.appAccountUsers'),
      dataIndex: 'uniqueUser',
      sorter: (a, b) => sortNumber(a, b, 'uniqueUser'),
      render: (data) => formatMoney(data),
    },
    {
      title: i18next.t('events.numberOfActions'),
      dataIndex: 'numberOfActions',
      sorter: (a, b) => sortNumber(a, b, 'numberOfActions'),
      render: (data) => formatMoney(data),
    },
    {
      title: i18next.t('events.averageActionPerUser'),
      dataIndex: 'averageActionPerUser',
      sorter: (a, b) => sortNumber(a, b, 'averageActionPerUser'),
    },
  ];

  return (
    <div className="box-wrapper-large">
      <div className="chart-title">{i18next.t('events.eventsDrillDown')}</div>
      <TableCustom
        pagination={{
          hideOnSinglePage: true,
        }}
        xScroll={800}
        data={data}
        columns={columns}
        loading={loading}
      />
    </div>
  );
};

export default TableEvents;
