import React from 'react';
import Edit from 'containers/rest/Edit';
import { PREFIX_URL_API } from 'configs/localData/constant';
import Form from '../components/Form';

const CentresEdit = (props) => (
  <Edit
    {...props}
    resource="articles"
    defaultOptions={{
      isTrimStr: true,
      prefixUrl: PREFIX_URL_API,
      isGetAll: true,
    }}
  >
    <Form isEdit />
  </Edit>
);

export default CentresEdit;
