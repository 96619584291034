import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Switch } from 'antd';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { editBusinessUsers } from 'redux/businessUsers/actions';
import { PREFIX_URL_API } from 'configs/localData/constant';
import { getUsersOfCompany } from 'redux/usersOfCompany/actions';
import { useParams } from 'react-router';

const ActiveUser = ({
  userId,
  value,
  isAllowedAction,
  disallowTitle,
  allowTitle,
  disallowDesc,
  allowDesc,
}) => {
  const dispatch = useDispatch();
  const { id: companyId } = useParams();

  const handleActiveUser = (checked) => {
    Modal.confirm({
      title: i18next.t(checked ? allowTitle : disallowTitle),
      content: i18next.t(checked ? allowDesc : disallowDesc),
      onOk() {
        return dispatch(
          editBusinessUsers({
            data: { id: userId, [isAllowedAction]: checked },
            options: { prefixUrl: PREFIX_URL_API },
          }),
        ).then(({ payload }) => {
          if (payload?.data) {
            dispatch(getUsersOfCompany({ companyId }));
          }
        });
      },
    });
  };

  return <Switch onChange={handleActiveUser} checked={value} />;
};

ActiveUser.propTypes = {
  value: PropTypes.bool,
  userId: PropTypes.string,
  isAllowedAction: PropTypes.string,
  disallowTitle: PropTypes.string,
  allowTitle: PropTypes.string,
  disallowDesc: PropTypes.string,
  allowDesc: PropTypes.string,
};

export default ActiveUser;
