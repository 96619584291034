import { createSlice } from '@reduxjs/toolkit';
import {
  clearCurrentDataTemplate,
  clearCurrentOptionTemplate,
  delEmailTemplate,
  getByKeyEmailTemplate,
  getClaimStatus,
  getEmailTemplates,
  getOptionEmailTemplate,
} from './actions';

const INITIAL_STATE_EXTRA = {
  resourceData: {
    filter: {},
    offset: 0,
    page: 1,
    limit: 10,
    total: 0,
    numberOfPages: 1,
    sort: '',
  },
  data: [],
  loading: false,
};

const initialState = {
  emailTemplates: INITIAL_STATE_EXTRA,
  loading: false,
  error: {},
  claimStatus: {
    totalCentres: 0,
    totalClaimedCentres: 0,
    totalUnclaimedCentres: 0,
  },
  currentData: {},
  optionTemplate: {},
};

const getEmailTemplatesPending = (
  state,
  {
    meta: {
      arg: { data, options = {} },
    },
  },
) => {
  if (options.isRefresh) {
    state.emailTemplates = {
      resourceData: {
        ...INITIAL_STATE_EXTRA.resourceData,
        ...data,
      },
      data: [],
      loading: true,
    };
  } else {
    const resourceData = state.emailTemplates.resourceData || {};
    state.emailTemplates.loading = true;
    state.emailTemplates.resourceData = {
      ...resourceData,
      offset: resourceData.offset + resourceData.limit || 0,
      ...data,
    };
  }
};

const getEmailTemplatesSuccess = (state, { payload: { data } }) => {
  state.emailTemplates.data = [...state.emailTemplates.data, ...data?.results];

  state.emailTemplates.resourceData = {
    ...state.emailTemplates.resourceData,
    total: data?.total || 0,
    numberOfPages: data.numberOfPages,
  };

  state.emailTemplates.loading = false;
  state.error = null;
};

const getEmailTemplatesFailure = (state, { payload }) => {
  state.error = payload;
  state.emailTemplates.loading = false;
};

const getClaimStatusSuccess = (state, { payload }) => {
  state.claimStatus = {
    totalCentres: payload.totalCentres,
    totalClaimedCentres: payload.totalClaimedCentres,
    totalUnclaimedCentres: payload.totalUnclaimedCentres,
  };
};

const delEmailTemplatesSuccess = (state, { meta }) => {
  state.emailTemplates.data = state.emailTemplates.data?.filter(
    (item) => item.key !== meta?.arg,
  );

  state.emailTemplates.resourceData.total -= 1;
};

const { reducer } = createSlice({
  name: 'EDM',
  initialState,
  extraReducers: {
    [getEmailTemplates.pending]: getEmailTemplatesPending,
    [getEmailTemplates.fulfilled]: getEmailTemplatesSuccess,
    [getEmailTemplates.rejected]: getEmailTemplatesFailure,

    [getClaimStatus.fulfilled]: getClaimStatusSuccess,
    [delEmailTemplate.fulfilled]: delEmailTemplatesSuccess,

    [getByKeyEmailTemplate.fulfilled]: (state, { payload }) => {
      state.currentData = payload;
    },
    [clearCurrentDataTemplate]: (state) => {
      state.currentData = {};
    },
    [getOptionEmailTemplate.fulfilled]: (state, { payload }) => {
      state.optionTemplate = payload;
    },
    [clearCurrentOptionTemplate]: (state) => {
      state.optionTemplate = {};
    },
  },
});

export default reducer;
