import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Button, Tooltip } from 'antd';
import { SwapOutlined } from '@ant-design/icons';
import { CustomButtonStyles } from './styles';

const CustomButtonIcon = ({
  title,
  handleClick,
  IconComponent,
  record,
  iconProps,
}) => (
  <CustomButtonStyles>
    <Tooltip title={i18next.t(title)}>
      <Button
        onClick={() => handleClick(record)}
        icon={<IconComponent {...iconProps} />}
      />
    </Tooltip>
  </CustomButtonStyles>
);

CustomButtonIcon.propTypes = {
  handleClick: PropTypes.func,
  title: PropTypes.string,
  record: PropTypes.object,
  IconComponent: PropTypes.any,
  iconProps: PropTypes.object,
};

CustomButtonIcon.defaultProps = {
  title: 'button.normal',
  IconComponent: SwapOutlined,
};

export default CustomButtonIcon;
