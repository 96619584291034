import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Modal } from 'antd';
import { getYoutubeEmbedURL } from 'utils/tools';
import { ModalContentPreviewStyles } from './styles';

const ModalPreview = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false);

  const [imagePreview, setImagePreview] = useState({});

  useImperativeHandle(ref, () => ({
    toggleModal: (file) => {
      setVisible(true);
      setImagePreview(file);
    },
  }));

  const handleCancel = () => setVisible(false);

  const getImage = () => {
    if (imagePreview?.type?.includes('video')) {
      const urlVideo = getYoutubeEmbedURL(imagePreview?.url);
      return (
        <iframe
          className="iframe-video"
          frameBorder="0"
          title="Preview Youtube Video Embed"
          allowFullScreen="allowfullscreen"
          src={urlVideo}
          width="100%"
          height={500}
        />
      );
    }
    return (
      <img
        alt="kindi"
        className="w-full"
        src={imagePreview?.url || imagePreview?.response}
      />
    );
  };

  return (
    <Modal
      style={{ top: 80 }}
      visible={visible}
      footer={null}
      onCancel={handleCancel}
      width={800}
    >
      <ModalContentPreviewStyles>{getImage()}</ModalContentPreviewStyles>
    </Modal>
  );
});

ModalPreview.defaultProps = {
  images: [],
  handleCancel: () => null,
};

export default ModalPreview;
