import { remove, unionBy, uniqBy } from 'lodash-es';
import { makeCRUDSlice } from 'redux/crudCreator';
import {
  allowedEnquiries,
  centresActions,
  changeFinishedStep,
  changeSpecialOffer,
  changeSubscriptionCentre,
  createCentreService,
  delAllGoogleReviews,
  delCentreService,
  editAppSettingsCentre,
  editCentreService,
  editSocialMediaChannels,
  getAllSocialMediaChannels,
  getAppSettingsCentre,
  getKindiCareRatingSummary,
  getRatingsCentre,
  getSummaryDetailCentre,
  MODEL_NAME,
} from './actions';

const INITIAL_STATE_EXTRA = {
  resourceData: {
    filter: {},
    offset: 0,
    page: 1,
    limit: 10,
    total: 0,
    numberOfPages: 1,
    sort: '',
  },
  data: [],
  socialMedia: [],
  totalSocialMedia: 0,
};

const createCentreServicePending = (state) => {
  state.createCentreServiceLoading = true;
};

const createCentreServiceSuccess = (state, { payload: { data } }) => {
  state.createCentreServiceLoading = false;
  state.error = null;
  state.currentData.services = [data, ...state.currentData.services];
};

const createCentreServiceFail = (state, { data }) => {
  state.createCentreServiceLoading = false;
  state.error = data;
};

const editCentreServiceSuccess = (state, { payload: { data } }) => {
  state.error = null;

  state.currentData.services = state.currentData.services?.map((item) =>
    item.id === data?.id ? { ...item, ...data } : item,
  );
};

const editCentreServiceFail = (state, { payload: { data } }) => {
  state.error = data;
};

const delCentreServiceSuccess = (state, { payload: { id } }) => {
  state.error = null;
  state.currentData.services = remove(
    state.currentData.services,
    (obj) => obj.id !== id,
  );
};

const delCentreServiceFail = (state, { payload: { data } }) => {
  state.error = data;
};

const getRatingsCentrePending = (
  state,
  {
    meta: {
      arg: { data, options = {} },
    },
  },
) => {
  state.ratingsLoading = true;

  if (options.isRefresh) {
    state.ratings = {
      resourceData: {
        ...INITIAL_STATE_EXTRA.resourceData,
        ...data,
      },
      data: [],
    };
  } else {
    const resourceData = state.ratings.resourceData || {};

    state.ratings.resourceData = {
      ...resourceData,
      offset: resourceData.offset + resourceData.limit || 0,
      ...data,
    };
  }
};

const getRatingsCentreSuccess = (state, { payload: { data } }) => {
  state.ratings.data = unionBy(state.ratings.data, data?.results, 'id');

  state.ratings.resourceData = {
    ...state.ratings.resourceData,
    total: data?.total || 0,
    numberOfPages: data.numberOfPages,
  };

  state.ratingsLoading = false;
  state.error = null;
};

const getRatingsCentreFail = (state, { payload: { data } }) => {
  state.error = data;
  state.ratingsLoading = false;
};

const getSummaryDetailCentreSuccess = (state, { payload }) => {
  state.summaryDetail = payload;
};

const changeSubscriptionCentreSuccess = (state, { meta }) => {
  state.currentData.CRMPackage = meta?.arg?.CRMPackage;
};

const getAppSettingsCentreSuccess = (state, { payload }) => {
  state.appSettings = payload;
};

const editAppSettingsCentreSuccess = (state, { payload }) => {
  state.appSettings = uniqBy(
    [...payload.data, ...(state.appSettings || [])],
    'id',
  );
};

const changeSpecialOfferSuccess = (state, { payload }) => {
  state.data[payload.id].isSpecialOffer = payload.isSpecialOffer;
};

const allowedEnquiriesSuccess = (state, { payload }) => {
  state.currentData.isAllowedEnquiries = payload?.data.isAllowed;
};

const changeFinishedStepSuccess = (state, { payload }) => {
  state.currentData.isFinishedStep = payload.isFinishedStep;
};

const delAllGoogleReviewSuccess = (state, { payload: { id } }) => {
  state.error = null;
  state.ratings.data = remove(state.ratings.data, (obj) => obj.id !== id);
};

const delAllGoogleReviewFail = (state, { payload: { data } }) => {
  state.error = data;
};

const getSocialMediaChannelsSuccess = (state, { payload }) => {
  state.socialMedia = payload.buttons;
  state.totalSocialMedia = payload.total;
};

const editSocialMediaChannelsSuccess = (state, { payload }) => {
  state.socialMedia = uniqBy(
    [...(payload.results || []), ...(state.socialMedia || [])],
    'id',
  );
};

const getKindiCareRatingSummaryPending = (state) => {
  state.loadingKindiCareRatingSummary = true;
};

const getKindiCareRatingSummaryFulfilled = (
  state,
  { payload: { kindiRatingSummary } },
) => {
  state.currentData.kindiRatingSummary = { ...kindiRatingSummary };
};

const getKindiCareRatingSummaryFailed = (state, { payload: { data } }) => {
  state.error = data;
  state.loadingKindiCareRatingSummary = false;
};

const slice = makeCRUDSlice(MODEL_NAME, centresActions, {
  [createCentreService.pending]: createCentreServicePending,
  [createCentreService.fulfilled]: createCentreServiceSuccess,
  [createCentreService.rejected]: createCentreServiceFail,

  [editCentreService.fulfilled]: editCentreServiceSuccess,
  [editCentreService.rejected]: editCentreServiceFail,

  [delCentreService.fulfilled]: delCentreServiceSuccess,
  [delCentreService.rejected]: delCentreServiceFail,

  [getRatingsCentre.pending]: getRatingsCentrePending,
  [getRatingsCentre.fulfilled]: getRatingsCentreSuccess,
  [getRatingsCentre.rejected]: getRatingsCentreFail,

  [getSummaryDetailCentre.fulfilled]: getSummaryDetailCentreSuccess,

  [changeSubscriptionCentre.fulfilled]: changeSubscriptionCentreSuccess,

  [getAppSettingsCentre.fulfilled]: getAppSettingsCentreSuccess,

  [editAppSettingsCentre.fulfilled]: editAppSettingsCentreSuccess,

  [changeSpecialOffer.fulfilled]: changeSpecialOfferSuccess,

  [allowedEnquiries.fulfilled]: allowedEnquiriesSuccess,

  [changeFinishedStep.fulfilled]: changeFinishedStepSuccess,

  [delAllGoogleReviews.fulfilled]: delAllGoogleReviewSuccess,
  [delAllGoogleReviews.rejected]: delAllGoogleReviewFail,

  [getAllSocialMediaChannels.fulfilled]: getSocialMediaChannelsSuccess,
  [editSocialMediaChannels.fulfilled]: editSocialMediaChannelsSuccess,

  [getKindiCareRatingSummary.pending]: getKindiCareRatingSummaryPending,
  [getKindiCareRatingSummary.fulfilled]: getKindiCareRatingSummaryFulfilled,
  [getKindiCareRatingSummary.rejected]: getKindiCareRatingSummaryFailed,
});

export default slice.reducer;
