import React from 'react';
import i18next from 'i18next';
import { Result } from 'antd';

const AlertSelectCenter = () => (
  <Result
    status="warning"
    title={i18next.t('automatedResponses.warningRedirect')}
  />
);

export default AlertSelectCenter;
