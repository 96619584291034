import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';

import { CENTRE_NQS_RATING } from 'configs/localData';
import { formatDateTimeTable } from 'utils/textUtils';
import CardSection from 'components/common/CardSection';
import { Col, Row } from 'antd';
import { NQSRatingStyles } from './styles';

const NQSRating = ({
  NQSRType,
  centreNQSRating,
  currentOverallNQSRatingDate,
}) => (
  <CardSection title="centreDetail.NQSRating">
    <NQSRatingStyles>
      <div className="NQSRating-title">
        {NQSRType?.image && (
          <img className="image-nqs-rating" src={NQSRType?.image} alt="" />
        )}
        <div className="title-nqs-rating">
          {formatDateTimeTable(currentOverallNQSRatingDate)}
        </div>
      </div>
      <Row gutter={[16, 16]} className="row-ratting">
        {CENTRE_NQS_RATING.map((item, index) => (
          <React.Fragment key={String(index)}>
            <Col span={16} className="item-title">
              {i18next.t(item.text)}
            </Col>
            <Col span={8} className="item-value">
              {centreNQSRating?.[item.value]}
            </Col>
          </React.Fragment>
        ))}
      </Row>
    </NQSRatingStyles>
  </CardSection>
);

NQSRating.propTypes = {
  NQSRType: PropTypes.object,
  centreNQSRating: PropTypes.object,
  currentOverallNQSRatingDate: PropTypes.string,
};

NQSRating.defaultProps = {
  NQSRType: {},
  centreNQSRating: {},
};

export default NQSRating;
