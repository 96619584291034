import { notification } from 'antd';
import imageCompression from 'browser-image-compression';
import {
  AUDIO_TYPES,
  IMAGE_TYPES,
  MAX_FILE_DIMENSIONS,
  MAX_FILE_SIZE_IN_MB,
  VIDEO_TYPES,
} from 'configs/localData';
import i18next from 'i18next';

export const isImageFile = (fileName) => {
  const extension = getExtensionFile(fileName);
  if (!extension) return false;
  return IMAGE_TYPES.includes(extension.toLocaleLowerCase());
};

export const isVideoFile = (fileName) => {
  const extension = getExtensionFile(fileName);
  if (!extension) return false;
  return VIDEO_TYPES.includes(extension.toLocaleLowerCase());
};

export const isAudioFile = (fileName) => {
  const extension = getExtensionFile(fileName);
  if (!extension) return false;
  return AUDIO_TYPES.includes(extension.toLocaleLowerCase());
};

export const getExtensionFile = (fileName) => {
  if (typeof fileName !== 'string' || fileName.indexOf('.') === -1) return '';
  return fileName.split('.').pop();
};

export const handleViewFile = async (record, callback = () => null) => {
  if (record?.url) {
    if (
      record.name &&
      /\.(xlsx|xls|doc|docx|ppt|pptx|pdf)$/i.test(record.name)
    ) {
      const data = {
        name: record.name,
        url: `//view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
          record.url,
        )}`,
      };
      if (record.name && /\.(pdf)$/i.test(record.name)) {
        data.url = `${
          process.env.REACT_APP_PDF_VIEWER_HOST
        }?file=${encodeURIComponent(record.url)}`;
        data.isPDF = true;
      }

      callback({ data, visible: true });
    } else if (isImageFile(record.name)) {
      callback({
        data: { name: record.name, url: record.url, isImage: true },
        visible: true,
      });
    } else {
      window.location.assign(record.url);
    }
  } else {
    notification.error({
      message: i18next.t('error.title'),
      description: i18next.t('error.description'),
      position: 'tr',
      duration: 2,
    });
  }
};

const showErrorDownloadFile = () => {
  notification.error({
    message: i18next.t('error.title'),
    description: i18next.t('error.errorDownloadFile'),
    position: 'tr',
    duration: 2,
  });
};

export const downloadFileByURL = async (file) => {
  if (file?.url) {
    try {
      fetch(file.url, {
        headers: {
          // 'Access-Control-Allow-Origin':'*',
          // Origin: '*',
        },
      })
        .then((res) => {
          if (res.status === 200) return res.blob();
          showErrorDownloadFile();
          return false;
        })
        .then((blob) => {
          if (!blob) return;
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = file.name;
          a.click();
          window.URL.revokeObjectURL(url);
        });
    } catch (error) {
      showErrorDownloadFile();
    }
  } else {
    showErrorDownloadFile();
  }
};

export const getPrefixKeyUploadCentre = (id) => `uploads/centres/${id}/`;

export const resizeImageFile = async (file) => {
  const options = {
    maxSizeMB: MAX_FILE_SIZE_IN_MB,
    maxWidthOrHeight: MAX_FILE_DIMENSIONS,
    useWebWorker: true,
  };
  let compressedFile = file;

  if (
    file.type?.includes('image') &&
    file.size / 1024 / 1024 > MAX_FILE_SIZE_IN_MB
  ) {
    try {
      compressedFile = await imageCompression(file, options);
      compressedFile.lastModifiedDate = new Date();
      compressedFile.name = file.name;
      return compressedFile;
    } catch (err) {
      notification.error({
        message: i18next.t('error.title'),
        description: i18next.t('error.description'),
      });
      return null;
    }
  }
  return file;
};
