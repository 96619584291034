import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';
import RestInputItem from 'components/RestInput/RestInputItem';
import { formatLabelTable } from 'utils/textUtils';
import { LABEL_TABLE_KEY } from 'configs/localData/constant';
import { useSelector } from 'react-redux';

const ServiceAgeGroupsForm = ({ isEdit, ...props }) => {
  const appSettings = useSelector((state) => state.config?.data?.AppSetting);

  return (
    <div {...props}>
      <RestInputItem
        required
        source="name"
        header={formatLabelTable(LABEL_TABLE_KEY.ageGroup, appSettings)}
        placeholder={`Enter ${formatLabelTable(
          LABEL_TABLE_KEY.ageGroup,
          appSettings,
        )}`}
        isValidatorEmpty
      />
      {isEdit && (
        <RestInputItem
          source="isActive"
          header="serviceAgeGroups.isActive"
          placeholder="serviceAgeGroups.isActive"
          ContentComponent={Switch}
          ruleType="boolean"
          valuePropName="checked"
        />
      )}
    </div>
  );
};

ServiceAgeGroupsForm.propTypes = {
  isEdit: PropTypes.bool,
};

export default ServiceAgeGroupsForm;
