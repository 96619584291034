import {
  checkNotExistEmailsApi,
  editNotificationApi,
  getLatestEmailsSentNotificationApi,
  getNotificationCountApi,
  sendNotificationApi,
  sendTestNotificationApi,
} from 'api/notifications';
import { apiWrapper } from 'utils/reduxUtils';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { makeActions } from 'redux/crudCreator';
import { convertRequestParamsAddEdit } from 'utils/tools';
import i18next from 'i18next';

export const updateNotifications = createAction('updateNotifications');
export const notificationsActions = makeActions('notifications');
export const getByIdNotification = notificationsActions.getDataById;
export const clearCurrentNotification = notificationsActions.clearCurrent;

export const getNotificationCount = createAsyncThunk(
  'notifications/getNotificationCount',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(null, getNotificationCountApi);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const sendNotification = createAsyncThunk(
  'notifications/sendNotification',
  async (payload, thunkAPI) => {
    try {
      const convertRequest = convertRequestParamsAddEdit(payload, true);

      const response = await apiWrapper(
        {},
        sendNotificationApi,
        convertRequest,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const sendTestNotification = createAsyncThunk(
  'notifications/sendTestNotification',
  async (payload, thunkAPI) => {
    try {
      const convertRequest = convertRequestParamsAddEdit(payload, true);

      const response = await apiWrapper(
        {
          isShowSuccessNoti: true,
          successDescription: i18next.t('notifications.sendTestSuccess'),
        },
        sendTestNotificationApi,
        convertRequest,
      );

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getLatestEmailsSentNotification = createAsyncThunk(
  'notifications/getLatestEmailsSentNotification',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {},
        getLatestEmailsSentNotificationApi,
        payload,
      );

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const checkNotExistEmails = createAsyncThunk(
  'notifications/checkNotExistEmails',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper({}, checkNotExistEmailsApi, payload);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const editNotification = createAsyncThunk(
  'notifications/editNotification',
  async (payload, thunkAPI) => {
    try {
      const convertRequest = convertRequestParamsAddEdit(payload.data, true);

      const response = await apiWrapper(
        {},
        editNotificationApi,
        payload.id,
        convertRequest,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);
