import i18next from 'i18next';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { getCentresOfProvider } from 'redux/centresOfProvider/actions';
import CentreFilter from './CentreFilter';
import CentreListMultiSelect from './CentreListMultiSelect';

const CentreForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, keepFilter, keepOrderBy }) => {
      dispatch(
        getCentresOfProvider({
          id,
          data: filter,
          options: {
            keepFilter,
            keepOrderBy,
            isRefresh,
            isOrderBy: false,
          },
        }),
      );
    },
    [id, dispatch],
  );
  useEffect(() => {
    retrieveList({
      filter: {
        limit: 10,
        offset: 0,
      },
      isRefresh: true,
    });
  }, [retrieveList]);

  return (
    <div>
      <div className="title-session">{i18next.t('users.selectCentres')}</div>
      <CentreFilter retrieveList={retrieveList} />
      <CentreListMultiSelect retrieveList={retrieveList} />
    </div>
  );
};

export default CentreForm;
