import React, { useEffect } from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import { history } from 'redux/store';
import PrivateRoutes from './PrivateRoutes';
import publicRoutes from './PublicRoutes';
import RoutesWrapper from './styles';
import ModalRoute from './ModalRoute';
import NotFoundPage from '../containers/404Page';

const Routes = () => {
  useEffect(() => {
    const ele = document.getElementById('ipl-progress-indicator');
    if (ele) {
      setTimeout(() => {
        ele.classList.add('available');
      }, 500);
      setTimeout(() => {
        ele.outerHTML = '';
      }, 1500);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <RoutesWrapper>
      <Router history={history}>
        <Switch>
          <Route exact path="/404" component={NotFoundPage} />
          {publicRoutes()}
          <PrivateRoutes />
        </Switch>

        <ModalRoute />
      </Router>
    </RoutesWrapper>
  );
};

export default Routes;
