import React, { useMemo, useEffect } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { getSummariesProvider } from 'redux/providers/actions';
import { getSummariesActivities } from 'redux/activities/actions';
import { getSummariesTasks } from 'redux/tasks/actions';
import RestShow from 'containers/rest/Show';
import { PREFIX_URL_API } from 'configs/localData/constant';

import ProviderTabs from '../components/ProviderTabs';
import { Provider } from './context';

const ProviderShow = (props) => {
  const dispatch = useDispatch();

  const currentData = useSelector((state) => state.providers.currentData) || {};

  const { id: providerId } = useParams();

  const customBreadcrumb = useMemo(
    () => [
      {
        title: i18next.t('providers.header'),
        path: '/providers',
      },
      {
        title: i18next.t('providers.providerDetail'),
      },
      {
        title: currentData?.name || i18next.t('error.waitingUpdate'),
      },
    ],
    [currentData.name],
  );

  useEffect(() => {
    if (providerId) {
      dispatch(getSummariesProvider({ providerId }));
      dispatch(getSummariesTasks({ providerId }));
      dispatch(getSummariesActivities({ providerId }));
    }
  }, [providerId, dispatch]);

  return (
    <Provider>
      <RestShow
        {...props}
        resource="providers"
        customBreadcrumb={customBreadcrumb}
        defaultOptions={{ prefixUrl: PREFIX_URL_API }}
        header="providers.providerDetail"
        isResetStyle
      >
        <ProviderTabs />
      </RestShow>
    </Provider>
  );
};

ProviderShow.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
};

export default ProviderShow;
