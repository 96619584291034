import styled from 'styled-components';

export const ActivitiesNotesListStyles = styled.div`
  background-color: #fff;
  border-radius: 8px;
  height: 100%;
  .section-header {
    padding: 16px;
    &__title {
      margin-right: 20px;
      font-weight: bold;
      font-size: 20px;
    }
  }
  .switcher-actions {
    border-radius: 8px;
    padding: 4px;
    background-color: #f7f8f9;
    .anticon {
      margin-right: 5px;
      font-size: 16px;
    }
    .ant-radio-button-wrapper {
      background: transparent;
      border: none;
      box-shadow: none;
      border-radius: 8px;
      color: #acb2b8;

      &::before {
        width: 0 !important;
      }

      &:not(:first-child) {
        padding-left: 20px;
      }
    }

    .ant-radio-button-wrapper-checked {
      color: ${({ theme }) => theme.color.blueSecondary} !important;

      background: ${({ theme }) => `${theme.color.blueSecondary}20`} !important;
    }
  }

  .expired-date {
    color: red;
  }
`;

export const ActivitiesNotesDetailsStyles = styled.div`
  .activity-detail {
    &__header {
      text-align: center;
      margin-bottom: 20px;
    }
    &__type-icon {
      font-size: 65px;
      margin-bottom: 6px;
    }
    &__type-name {
      font-weight: bold;
      font-size: 18px;
    }
    &__info {
      font-size: 16px;
    }
  }
  .info-item {
    display: flex;
    margin-bottom: 20px;
    &__label {
      width: 100px;
      font-weight: bold;
      min-width: 100px;
    }
    &__label:after,
    &__label-note:after {
      content: ':';
    }
    &__label-note {
      font-weight: bold;
      margin-bottom: 5px;
    }
  }

  .ant-divider {
    margin: 20px 0 !important;
  }
`;

export const ActivityFormStyles = styled.div`
  .add-assignee {
    display: flex;
    align-items: center;
    &__name {
      line-height: 20px;
    }
    &__avatar-wrapper {
      position: relative;
      display: inline-block;
      margin-right: 10px;
      .ant-avatar {
        border: 1px dashed ${({ theme }) => theme.text.mainL3};
        color: ${({ theme }) => theme.text.mainL2};
        background: unset !important;
      }
    }
    &__add-icon {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 15px;
      color: ${({ theme }) => theme.text.mainL3};
    }
    &__no-assignee {
      color: ${({ theme }) => theme.text.mainL2};
    }
  }

  .add-due-date {
    &-wrapper {
      display: flex;
      align-items: center;
    }
    &__remove-icon {
      margin-left: 10px;
      font-size: 16px;
    }
  }
`;

export const FormCentrePhotosStyles = styled.div`
  .file-list-view {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .ant-form-item-label {
    label {
      color: ${({ theme }) => theme.text.formLabel} !important;
      font-size: 14px !important;
    }
  }
  .form-item-upload-image {
    margin-bottom: 30px;
    label {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: ${({ theme }) => theme.text.mainL1};
    }
    label:after {
      content: '';
    }
    .ant-form-item-control-input {
      min-height: 150px;
    }
    .ant-upload.ant-upload-drag {
      background: ${({ theme }) => theme.background.content} !important;
      height: 150px;
      border-width: 2px;
      p.ant-upload-drag-icon .anticon {
        font-size: 16px !important;
        color: ${({ theme }) => theme.text.mainL3} !important;
      }
      p.ant-upload-text {
        font-size: 16px !important;
        line-height: 26px;
        color: ${({ theme }) => theme.text.mainL5} !important;
      }
      p.ant-upload-drag-icon {
        margin-bottom: 5px !important;
        color: ${({ theme }) => theme.text.mainL5}!important;
        font-size: 14px;
      }
      b {
        color: ${({ theme }) => theme.palette.primary} !important;
      }
    }
  }
`;

export const UploadImageItemStyles = styled.div`
  position: relative;
  .image-upload-view {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  .image-item {
    padding: 10px 7px 7px 7px;
  }
  .hide {
    display: none;
  }

  .image-item:hover {
    background: #f7f8f9;
    .hide {
      display: block;
    }
  }
  .image-name {
    margin-top: -4px;
    margin-left: 5px;
    margin-bottom: 0px;
  }
  .attactment-icon {
    svg {
      width: 17px;
    }
  }
  .delete-icon-image {
    svg {
      width: 17px;
    }
  }
  .wrapper-loading,
  .icon-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .icon-loading,
  .icon-error {
    font-size: 24px;
  }
  .icon-error {
    color: red;
  }
  img {
    display: none;
  }
  img[src] {
    display: block;
  }
`;
