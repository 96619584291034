import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getSummariesCompanyApi,
  getSummariesProvidersOfCompanyApi,
  getCentreIdsOfCompanyApi,
} from 'api/companies';
import { pick } from 'lodash-es';
import { makeActions } from 'redux/crudCreator/actions';
import { convertRequestParams } from 'redux/crudCreator/dataProvider';
import { apiWrapper } from 'utils/reduxUtils';

export const MODEL_NAME = 'companies';
export const companiesActions = makeActions(MODEL_NAME);

export const getAllCompanies = companiesActions.getAll;
export const editCompanies = companiesActions.edit;
export const createCompanies = companiesActions.create;
export const getByIdCompanies = companiesActions.getDataById;

export const getSummariesCompany = createAsyncThunk(
  'companies/getSummariesCompany',
  async (payload = {}, thunkAPI) => {
    try {
      const response = await apiWrapper({}, getSummariesCompanyApi, payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getSummariesProviderOfCompany = createAsyncThunk(
  'providers/getSummariesProviderOfCompany',
  async (payload = {}, thunkApi) => {
    try {
      const { id } = payload;

      const convertRequest = convertRequestParams(
        'GET_ALL',
        pick(payload.data, ['filter']),
        'providers',
        {
          isOrderBy: false,
        },
      );

      const response = await apiWrapper(
        {},
        getSummariesProvidersOfCompanyApi,
        id,
        convertRequest,
      );

      return response;
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  },
);

export const getCentreIdsOfCompany = createAsyncThunk(
  'companies/getCentreIdsOfCompany',
  async (payload = {}, thunkAPI) => {
    try {
      const response = await apiWrapper({}, getCentreIdsOfCompanyApi, payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
