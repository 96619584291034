import { Typography } from 'antd';
import RestSelect from 'components/RestInput/RestSelect';
import { USER_TYPES_CONSTANT } from 'configs/localData';
import { PREFIX_URL } from 'configs/localData/constant';
import ReferenceInput from 'containers/rest/ReferenceInput';
import i18next from 'i18next';
import React, { useMemo } from 'react';

const PrimaryContact = () => {
  const filterUser = useMemo(
    () => ({
      orderBy: 'firstName,lastName',
      filter: { userType: USER_TYPES_CONSTANT.business.value },
    }),
    [],
  );
  return (
    <div>
      <Typography.Title level={4}>
        {i18next.t('companies.primaryContact')}
      </Typography.Title>
      <ReferenceInput
        prefixUrl={PREFIX_URL.superAdmin}
        source="userId"
        resource="users"
        initialFilter={filterUser}
        searchKey="q"
      >
        <RestSelect
          header="companies.contactName"
          titleProp="firstName"
          valueProp="id"
          placeholder="companies.contactName"
          formatText={(name, record) =>
            `${name || ''} ${record.lastName || ''}
          `
          }
          isFilterOption={false}
        />
      </ReferenceInput>
      <ReferenceInput
        prefixUrl={PREFIX_URL.superAdmin}
        source="userId"
        resource="users"
        initialFilter={filterUser}
      >
        <RestSelect
          disabled
          titleProp="email"
          valueProp="id"
          header="input.email.name"
          placeholder="input.email.name"
        />
      </ReferenceInput>
      <ReferenceInput
        prefixUrl={PREFIX_URL.superAdmin}
        source="userId"
        resource="users"
        initialFilter={filterUser}
      >
        <RestSelect
          disabled
          titleProp="phoneNumber"
          valueProp="id"
          header="input.phoneNumber.name"
          placeholder="input.phoneNumber.name"
          formatText={(phoneNumber) =>
            `${phoneNumber || ''} 
          `
          }
        />
      </ReferenceInput>
    </div>
  );
};

export default PrimaryContact;
