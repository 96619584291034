import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { editClaimCentreRequests } from 'redux/claimCentreRequests/actions';
import { useDispatch, useSelector } from 'react-redux';
import { CENTRE_STATUS_VALUE } from 'configs/localData';

import RestShow from 'containers/rest/Show';
import DetailRequest from '../components/DetailRequest';
import GroupActions from '../components/GroupActions';
import ShowStyles from './styles';

const DisputeCentresShow = (props) => {
  const { match } = props;

  const dispatch = useDispatch();

  const record =
    useSelector((state) => state.claimCentreRequests.currentData) || {};

  const customBreadcrumb = [
    {
      title: i18next.t('claimCentreRequests.header'),
      path: '/claimCentreRequests',
    },
    {
      title: `#${match.params?.id}`,
      path: `/claimCentreRequests/${match.params?.id}/show`,
    },
  ];

  const handleConfirm = ({ status, options }) => {
    dispatch(
      editClaimCentreRequests({
        data: { id: record.id, status },
        options: {
          isShowSuccessNoti: true,
          successDescription: i18next.t('success.description'),
          ...options,
        },
      }),
    );
  };

  useEffect(() => {
    if (record?.status === CENTRE_STATUS_VALUE.pending)
      handleConfirm({
        status: CENTRE_STATUS_VALUE.inProcessing,
        options: {
          isShowSuccessNoti: false,
        },
      });
  }, [record.id]); // eslint-disable-line

  return (
    <ShowStyles>
      <RestShow
        {...props}
        resource="claimCentreRequests"
        customBreadcrumb={customBreadcrumb}
        extraAction={
          <GroupActions
            handleConfirm={handleConfirm}
            status={record?.status}
            isAdminEmailUsed={record?.isAdminEmailUsed}
          />
        }
      >
        <DetailRequest />
      </RestShow>
    </ShowStyles>
  );
};

DisputeCentresShow.propTypes = {
  match: PropTypes.object,
};

DisputeCentresShow.defaultProps = {
  match: {},
};

export default DisputeCentresShow;
