import React from 'react';
import PublicLayout from '../../layout/PublicLayout';
import RegisterForm from '../../containers/Register';
import RegisterWrapper from './styles';

const Register = () => (
  <PublicLayout>
    <RegisterWrapper>
      <RegisterForm />
    </RegisterWrapper>
  </PublicLayout>
);

export default Register;
