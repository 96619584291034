import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { HeaderSectionWrapper } from './styles';

const HeaderSection = ({ title, className }) => (
  <HeaderSectionWrapper className={className}>
    {i18next.t(title)}
  </HeaderSectionWrapper>
);
HeaderSection.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};

export default HeaderSection;
