import styled from 'styled-components';

const FormStyles = styled.div`
  .address-full-form-button {
    padding-left: 5px !important;
    > span {
      text-decoration: underline;
    }
  }

  .centre-form__title-section {
    font-size: 18px;
    font-weight: bold;
  }
`;

export default FormStyles;
