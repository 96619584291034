import styled from 'styled-components';

const SummaryTabStyles = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
`;

export default SummaryTabStyles;

export const BusinessCardStyles = styled.div`
  .content-business-logo {
    text-align: center;
    .ant-avatar {
      max-width: 100%;
    }
  }
`;

export const CentreInformationStyles = styled.div`
  .row-centre-information {
    font-size: 16px;
  }
  .col-value {
    font-weight: 600;
    line-height: 26px;
    word-break: break-word;
  }
`;

export const CentrePhotoStyles = styled.div``;

export const SocialMediaChannelStyles = styled.div`
  .social-text {
    color: ${({ theme }) => theme.text.mainL3};
    pointer-events: none;
    cursor: not-allowed;
  }
`;
