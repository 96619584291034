import React from 'react';
import RestShow from 'containers/rest/Show';
import RestFieldItem from 'components/RestField/RestFieldItem';

const BusinessUsersShow = (props) => (
  <RestShow {...props} hasEdit resource="businessUsers">
    <RestFieldItem source="firstName" header="businessUsers.firstName" />
    <RestFieldItem source="jobTitle" header="businessUsers.jobTitle" />
    <RestFieldItem source="role" header="businessUsers.role" />
    <RestFieldItem source="centres" header="businessUsers.centres" />
    <RestFieldItem source="lastActiveAt" header="businessUsers.lastActiveAt" />
    <RestFieldItem source="createdAt" header="businessUsers.createdAt" />
    <RestFieldItem
      source="isBlockCRMApp"
      header="businessUsers.isBlockCRMApp"
    />
    <RestFieldItem source="isBlocked" header="businessUsers.isBlocked" />
  </RestShow>
);

export default BusinessUsersShow;
