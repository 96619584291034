import { createSelector } from 'reselect';

export class CRUDSelectorsCustom {
  constructor(resource) {
    this.resource = resource;
  }

  getRestData = (state) => state[this.resource];

  getDataArr = createSelector([this.getRestData], (resources) => {
    const { data } = resources;
    return data;
  });

  getResourceData = createSelector([this.getRestData], (resources) => {
    const { resourceData } = resources;
    return resourceData;
  });

  enabledLoadMore = createSelector([this.getResourceData], (resources) => {
    const { offset, limit, loading, numberOfPages } = resources;
    return !loading && offset / limit + 1 < numberOfPages;
  });

  getLoading = createSelector(
    [this.getRestData],
    (resources = { loading: false }) => {
      const { loading } = resources;
      return loading;
    },
  );
}

export const crudSelectorsCustom = new CRUDSelectorsCustom();
