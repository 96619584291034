import React from 'react';
import PropTypes from 'prop-types';
import { Input, Switch } from 'antd';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestMultiPhotos from 'components/RestInput/RestMultiPhotos';

const NQSRTypesForm = ({ isEdit, ...props }) => (
  <div {...props}>
    <RestInputItem
      required
      source="name"
      header="NQSRTypes.name"
      placeholder="NQSRTypes.name"
      isValidatorEmpty
    />
    <RestInputItem
      required
      source="title"
      header="NQSRTypes.titleField"
      placeholder="NQSRTypes.titleField"
      isValidatorEmpty
    />
    <RestInputItem
      source="description"
      header="NQSRTypes.description"
      placeholder="NQSRTypes.description"
      ContentComponent={Input.TextArea}
      rows={6}
    />
    {isEdit && (
      <RestInputItem
        source="isActive"
        header="NQSRTypes.isActive"
        placeholder="NQSRTypes.isActive"
        ContentComponent={Switch}
        ruleType="boolean"
        valuePropName="checked"
      />
    )}

    <RestMultiPhotos
      source="image"
      header="NQSRTypes.image"
      placeholder="NQSRTypes.image"
      required
      multiple={false}
      objectFit="contain"
      width="auto"
      resource="features"
    />
  </div>
);

NQSRTypesForm.propTypes = {
  isEdit: PropTypes.bool,
};

export default NQSRTypesForm;
