import React, { useState, useEffect } from 'react';
import RestSelect from 'components/RestInput/RestSelect';
import { Button, Space } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { last } from 'lodash-es';
import { NOTIFICATION_STATUS_CONSTANT } from 'configs/localData';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkNotExistEmails,
  getLatestEmailsSentNotification,
  sendTestNotification,
} from 'redux/notifications/actions';
import { getLatestEmailsSentSelector } from 'redux/notifications/selectors';
import { LIMIT_LATEST_EMAILS_SENT } from 'configs/localData/constant';
import { isInvalidEmail } from 'utils/dataUtils';
import TagEmailRender from 'components/common/TagEmailRender';
import { EmailTagButton, TestNotificationFormStyles } from './styles';
import { getSendTestPopup, renderCustomOptions } from './utils';

const TestNotificationForm = ({ form }) => {
  const dispatch = useDispatch();
  const [recipientEmail, setRecipientEmail] = useState([]);
  const [invalidRecipients, setInvalidRecipients] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);

  const latestEmailsSent = useSelector(getLatestEmailsSentSelector);

  const isShowDropdown =
    !!searchValue &&
    latestEmailsSent.some((item) => item.includes(searchValue));

  const handleAddEmail = (email) => {
    const restRecipients = form.getFieldValue('recipientEmail') || [];
    const newRecipients = [...restRecipients, email];
    form.setFieldsValue({ recipientEmail: newRecipients });
    onRecipientsChange(newRecipients);
  };

  const onRecipientsChange = (values) => {
    setSearchValue('');
    const newestEmail = last(values);
    if (newestEmail && isInvalidEmail(newestEmail)) {
      setInvalidRecipients((prev) => [...prev, newestEmail]);
    }
    setRecipientEmail(values);
  };
  const onDeselect = (deselectValue) => {
    if (deselectValue === searchValue) {
      handleAddEmail(deselectValue);
    }
    const isRemovedInvalidValue = invalidRecipients.includes(deselectValue);
    if (isRemovedInvalidValue) {
      setInvalidRecipients((prev) =>
        prev.filter((item) => item !== deselectValue),
      );
    }
  };

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const onClear = () => {
    setInvalidRecipients([]);
  };

  const submitTestNoti = (close, recipientEmail) => {
    close();

    const bodyParams = [
      'htmlContent',
      'text',
      'title',
      'typeId',
      'recipientEmail',
      'image',
      'externalUrl',
    ];

    form
      .validateFields(bodyParams)
      .then((values) => {
        setLoading(true);

        return dispatch(
          sendTestNotification({
            ...values,
            recipientEmail: recipientEmail || values?.recipientEmail,
            status: NOTIFICATION_STATUS_CONSTANT.draft.value,
            recipientFilter: '{}',
          }),
        );
      })
      .then(() => {
        dispatch(
          getLatestEmailsSentNotification({
            limit: LIMIT_LATEST_EMAILS_SENT,
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSendTestNoti = () => {
    setLoading(true);
    dispatch(
      checkNotExistEmails({
        emails: JSON.stringify(recipientEmail),
      }),
    )
      .then(({ payload }) => {
        getSendTestPopup(payload, submitTestNoti, recipientEmail);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    dispatch(
      getLatestEmailsSentNotification({
        limit: LIMIT_LATEST_EMAILS_SENT,
      }),
    );
  }, [dispatch]);

  return (
    <TestNotificationFormStyles>
      <div className="form-test-noti">
        <RestSelect
          isFilterOption={false}
          customOptions={renderCustomOptions(latestEmailsSent, searchValue)}
          open={isShowDropdown}
          isShowSearch
          onSearch={onSearch}
          source="recipientEmail"
          mode="tags"
          header="notifications.recipients"
          placeholder="notifications.recipientsPlaceholder"
          className="select-recipients"
          onChange={onRecipientsChange}
          onClear={onClear}
          selectProps={{
            tagRender: TagEmailRender,
            searchValue,
            tokenSeparators: [','],
            getPopupContainer: (trigger) => trigger.parentNode,
            onDeselect,
          }}
        />

        <Button
          type="primary"
          loading={loading}
          onClick={handleSendTestNoti}
          disabled={!!invalidRecipients?.length || recipientEmail?.length === 0}
        >
          {i18next.t('button.send')}
        </Button>
      </div>
      <div>
        <div className="latest-emails-title mb-8">
          {i18next.t('notifications.latestEmailsSentTo')}
        </div>
        <Space wrap size={10}>
          {latestEmailsSent.map((email) => (
            <EmailTagButton
              disabled={recipientEmail.includes(email)}
              onClick={() => handleAddEmail(email)}
              key={email}
            >
              {email}
            </EmailTagButton>
          ))}
        </Space>
      </div>
    </TestNotificationFormStyles>
  );
};

TestNotificationForm.propTypes = {
  form: PropTypes.object,
};

export default TestNotificationForm;
