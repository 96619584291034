import styled from 'styled-components';

const DetailStyles = styled.div`
  .row-container {
    display: flex;
    align-items: stretch;
    > .ant-col > div {
      padding: 15px;
      background: ${({ theme }) => theme.background.content};
      height: 100%;
    }
  }
  .info-centre {
    text-align: center;
    .ant-avatar {
      margin-bottom: 20px;
    }
    .name-centre {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
  .title-item {
    font-weight: 700;
    margin-right: 10px;
  }
  .email-centre,
  .value-item {
    word-break: break-word;
  }
  .info-sender > .ant-col,
  .item-section {
    margin-bottom: 15px;
  }
`;

export default DetailStyles;
