import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { formatDateTimeTable } from 'utils/textUtils';
import { PREFIX_URL_API } from 'configs/localData/constant';
import { formatTimeFilterSubmit } from 'utils/dataUtils';

import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import CreateButton from 'components/RestActions/CreateButton';
import UserInfo from 'components/RestField/UserInfo';
import { formatOnActiveACECQA } from 'utils/formatFieldUtils';
import Filter from '../components/Filter';

const ProviderList = (props) => {
  const { push } = useHistory();

  const redirectDetail = (id) => {
    id && push(`/providers/${id}/show/summary`);
  };
  const openCreate = () => {
    push(`#providers/create`);
  };

  const formatFilter = useCallback(
    ({ lastContact, ...data }) => ({
      ...data,
      lastContact: formatTimeFilterSubmit(lastContact),
    }),
    [],
  );

  return (
    <div>
      <List
        {...props}
        resource="providers"
        hasSearch={false}
        filter={<Filter format={formatFilter} />}
        defaultOptions={{ prefixUrl: PREFIX_URL_API }}
        widthTable="1200px"
        hasCreate={false}
        extraAction={<CreateButton gotoCreatePage={openCreate} />}
        redirects={{
          edit: 'modal',
          create: 'modal',
        }}
      >
        <RestFieldItem
          sorter
          width="20%"
          source="name"
          header="providers.provider"
          format={(data, record) => (
            <UserInfo
              record={record}
              prefixLink="providers"
              suffixLink="show/summary"
              roleProp="user.email"
              nameProp="name"
              avatarProp="logo"
            />
          )}
        />
        <RestFieldItem
          sorter
          source="governmentReferenceId"
          header="centres.PRCode"
        />
        <RestFieldItem
          sorter
          source="companyName"
          header="companies.company"
          format={(data, record) => <span>{record?.company?.name}</span>}
        />
        <RestFieldItem
          sorter
          source="totalCentres"
          header="providers.totalCentres"
        />
        <RestFieldItem
          sorter
          source="totalPlaces"
          header="providerDetail.totalPlaces"
        />
        <RestFieldItem
          sorter
          source="businessUserName"
          header="providers.businessUser"
          format={(data, record) => (
            <div>
              <strong>
                {`${record?.user?.firstName || ''} ${
                  record?.user?.lastName || ''
                }`}
              </strong>
              <p>{record?.user?.phoneNumber}</p>
            </div>
          )}
        />
        <RestFieldItem
          sorter
          source="lastContactTime"
          header="common.lastActivity"
          format={(data, record) =>
            formatDateTimeTable(record?.lastContact?.createdAt)
          }
        />
        <RestFieldItem
          source="isActiveOnACECQA"
          header="centres.acecqa"
          format={formatOnActiveACECQA}
        />

        <ActionGroup widthAction={120}>
          <EditButton source="show" isView onClickCustom={redirectDetail} />
          <EditButton />
        </ActionGroup>
      </List>
    </div>
  );
};

export default ProviderList;
