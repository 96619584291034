import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { validateRegex } from 'utils/validateUtils';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestAvatarInput from 'components/RestInput/RestAvatarInput';
import { Switch } from 'antd';
import GeneratePassword from './GeneratePassword';

const GeneralUserForm = ({ isEdit }) => (
  <div>
    <div className="flex justify-center">
      <RestAvatarInput
        style={{
          width: 140,
          height: 140,
          borderRadius: '50%',
        }}
        className="avatar-section"
        cropDimension={{ width: 300, height: 300 }}
        hasCrop
        source="avatar"
      />
    </div>

    <RestInputItem
      required
      source="firstName"
      header="users.firstName"
      placeholder="users.firstName"
    />
    <RestInputItem
      required
      source="lastName"
      header="users.lastName"
      placeholder="users.lastName"
    />

    <RestInputItem
      required
      source="email"
      header="users.email"
      placeholder="users.email"
      rules={[
        {
          type: 'email',
          message: i18next.t('input.email.validateMsg.invalid'),
        },
      ]}
    />

    <RestInputItem
      source="phoneNumber"
      header="users.phoneNumber"
      placeholder="users.phoneNumber"
      rules={[
        {
          pattern: validateRegex.phone,
          message: i18next.t('input.phoneNumber.validateMsg.invalid'),
        },
      ]}
    />

    <RestInputItem
      source="jobTitle"
      header="users.jobTitle"
      placeholder="users.jobTitle"
    />

    <RestInputItem
      source="address"
      header="users.address"
      placeholder="users.address"
    />

    {isEdit && (
      <RestInputItem
        source="isBlocked"
        header="users.blocked"
        ContentComponent={Switch}
        valuePropName="checked"
        ruleType="boolean"
      />
    )}
    {isEdit && <GeneratePassword />}
  </div>
);

GeneralUserForm.propTypes = {
  isEdit: PropTypes.bool,
};

export default GeneralUserForm;
