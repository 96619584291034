import * as Sentry from '@sentry/react';
import { pick } from 'lodash-es';

export const handlerUserLogged = (user) => {
  if (process.env.NODE_ENV === 'development') return;
  Sentry.setUser(pick(user, ['email', 'firstName', 'lastName', 'phoneNumber']));
};

export const handleRequestError = ({ error, apiUrl, payload }) => {
  if (process.env.NODE_ENV === 'development') return;
  Sentry.captureMessage(`Error ${error.code}: ${error.message}`, {
    extra: { payload, apiUrl, error },
  });
};
