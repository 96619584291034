import { createSlice } from '@reduxjs/toolkit';
import {
  clearData,
  retrieveReference,
  retrieveReferenceInputData,
  retrieveReferenceList,
} from './actions';

export const INITIAL_STATE = {
  loading: false,
  itemLoadings: {},
  error: null,
  inputData: {},
  inputIds: [],
  currentId: null,
  filter: {},
  page: 1,
  pageSize: 10,
  total: 0,
  numberOfPages: 1,
  sort: '',
};

const initialState = {
  errorRequest: false,
};

const { reducer } = createSlice({
  name: 'Reference',
  initialState,
  reducers: {
    [clearData]: (state, { payload: { resource, filter = {} } }) => {
      state[resource] = {
        ...state[resource],
        ...filter,
        loading: false,
        data: [],
      };
    },
  },
  extraReducers: {
    [retrieveReferenceInputData.pending]: (
      state,
      {
        meta: {
          arg: { data, options = {}, resource },
        },
      },
    ) => {
      state[resource] = options.isRefresh
        ? {
            ...INITIAL_STATE,
            loading: true,
            ids: [],
            data: {},
            ...data,
          }
        : {
            ...state?.[resource],
            loading: true,
            page: state?.[resource]?.page + 1,
            offset: (state?.[resource]?.offset || 0) + 10,
          };
    },
    [retrieveReferenceInputData.fulfilled]: (
      state,
      { payload: { resource, referenceData } },
    ) => {
      state[resource] = {
        ...state[resource],
        inputIds: [
          ...(state?.[resource]?.inputIds || []),
          ...(referenceData?.ids || []),
        ],
        inputData: {
          ...state?.[resource]?.inputData,
          ...referenceData?.data,
        },
        total: referenceData?.total,
        numberOfPages: referenceData?.numberOfPages,
        loading: false,
      };
    },
    [retrieveReferenceInputData.rejected]: (
      state,
      { payload: { resource, error } },
    ) => {
      state[resource] = {
        ...state[resource],
        loading: false,
        error,
      };
    },
    [retrieveReference.pending]: (
      state,
      {
        meta: {
          arg: { resource, filter = {} },
        },
      },
    ) => {
      state[resource] = {
        ...state[resource],
        ...filter,
        loading: true,
        ids: state[resource] ? state[resource].ids : [],
        data: state[resource] ? state[resource].data : {},
        inputIds: state[resource] ? state[resource].ids : [],
        inputData: state[resource] ? state[resource].data : {},
      };
    },
    [retrieveReferenceList.fulfilled]: (
      state,
      { payload: { resource, data } },
    ) => {
      state[resource] = {
        ...state[resource],
        data: data.data,
        ids: data.ids,
        loading: false,
      };
    },
    [retrieveReferenceList.rejected]: (state, { payload }) => {
      const { resource, error } = payload;
      if (payload && state[resource]) {
        state.error = error;
        state[resource].loading = false;
      }
    },
  },
});

export default reducer;
