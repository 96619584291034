import React from 'react';
import Edit from 'containers/rest/Edit';
import moment from 'moment';
import { PREFIX_URL_API } from 'configs/localData/constant';
import Form from '../components/Form';

const CentresEdit = (props) => {
  const formatOnSubmit = ({ dateApplied, ...data }) => ({
    ...data,
    dateApplied: dateApplied
      ? moment(dateApplied).format('DD-MM-YYYY')
      : dateApplied,
  });

  return (
    <Edit
      {...props}
      resource="centres"
      isClearCurrent={false}
      defaultOptions={{
        isRequestApi: false,
        isTrimStr: true,
        prefixUrl: PREFIX_URL_API,
      }}
      formatOnSubmit={formatOnSubmit}
    >
      <Form />
    </Edit>
  );
};

export default CentresEdit;
