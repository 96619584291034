import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalCustom from 'components/common/ModalCustom';
import i18next from 'i18next';
import { Form } from 'antd';
import { useDispatch } from 'react-redux';
import { getAllAppSettings, updateLabelTable } from 'redux/appSettings/actions';
import FormNormalStyles from 'containers/Centres/components/Modals/styles';
import RestInputItem from 'components/RestInput/RestInputItem';

const LabelFormModal = ({ visible, onCancel, keys, title }) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const handleCancel = () => {
    onCancel();
    form.resetFields();
  };

  const onOk = () => {
    form.validateFields().then((values) => {
      setLoading(true);
      dispatch(updateLabelTable({ key: keys, data: values }))
        .then(({ payload }) => {
          if (payload?.success) {
            dispatch(
              getAllAppSettings({
                data: { orderBy: '-createdAt' },
                options: { offset: 0, limit: 10, isRefresh: true },
              }),
            );
            handleCancel();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <ModalCustom
      title={i18next.t('serviceTypes.editTableLabel')}
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      loading={loading}
      afterClose={() => form.resetFields()}
    >
      <FormNormalStyles>
        <Form form={form} layout="vertical" scrollToFirstError>
          <RestInputItem
            required
            source="value"
            header="serviceTypes.tableLabel"
            isValidatorEmpty
            defaultValue={title}
          />
        </Form>
      </FormNormalStyles>
    </ModalCustom>
  );
};

LabelFormModal.propTypes = {
  onCancel: PropTypes.func,
  visible: PropTypes.bool,
  title: PropTypes.string,
  keys: PropTypes.string,
};

export default LabelFormModal;
