import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { centresSelectors } from 'redux/centres/selectors';
import { RestInputContext } from 'components/RestInput/RestInputContext';

import ListCheckboxSelect from './ListCheckboxSelect';

const CentreListMultiSelect = ({ retrieveList }) => {
  const { record } = useContext(RestInputContext);
  const data = useSelector(centresSelectors.getDataArr);
  const paging = useSelector(centresSelectors.getFilters);
  const loading = useSelector(centresSelectors.getLoading);

  const defaultValue = useMemo(
    () => record?.centreIds || record?.centres?.map((item) => item?.id),
    [record],
  );

  return (
    <ListCheckboxSelect
      retrieveList={retrieveList}
      name="centreIds"
      defaultValue={defaultValue}
      data={data}
      paging={paging}
      loading={loading}
      selectedResourceTitles="users.selectedCentresTotal"
      selectedResourceTitle="users.selectedCentreTotal"
    />
  );
};

CentreListMultiSelect.propTypes = {
  retrieveList: PropTypes.func,
};

export default CentreListMultiSelect;
