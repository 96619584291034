import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

const TitleTabCount = ({ title, count }) => (
  <div>
    {i18next.t(title)}
    {count ? ` (${count})` : ' (0)'}
  </div>
);

TitleTabCount.propTypes = {
  title: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default TitleTabCount;
