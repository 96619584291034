import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSummariesTasksApi } from 'api/tasks';
import { makeActions } from 'redux/crudCreator/actions';
import { apiWrapper } from 'utils/reduxUtils';

export const MODEL_NAME = 'tasks';
export const tasksActions = makeActions(MODEL_NAME);

export const getAllTasks = tasksActions.getAll;
export const editTasks = tasksActions.edit;
export const createTasks = tasksActions.create;
export const getByIdTasks = tasksActions.getDataById;
export const delTasks = tasksActions.del;
export const setCurrentDataTasks = tasksActions.setCurrentData;
export const clearCurrentTasks = tasksActions.clearCurrent;

export const getSummariesTasks = createAsyncThunk(
  'tasks/getSummariesTasks',
  async (payload = {}, thunkAPI) => {
    try {
      const response = await apiWrapper({}, getSummariesTasksApi, payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
