import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { useHistory } from 'react-router';
import CreateButton from 'components/RestActions/CreateButton';
import FilterBrandsOfCompany from '../components/FilterBrandsOfCompany';

const HeaderFilter = ({ retrieveList }) => {
  const { push } = useHistory();

  const gotoCreatePage = () => {
    push({ hash: `#brands/createOfCompany` });
  };

  return (
    <Row gutter={16}>
      <Col span={20}>
        <FilterBrandsOfCompany retrieveList={retrieveList} />
      </Col>
      <Col span={4}>
        <CreateButton
          className="d-flex justify-end"
          gotoCreatePage={gotoCreatePage}
        />
      </Col>
    </Row>
  );
};

HeaderFilter.propTypes = {
  retrieveList: PropTypes.func,
};

export default HeaderFilter;
