import React from 'react';
import PropTypes from 'prop-types';
import HeaderNotifyIOS from './HeaderNotifyIOS';
import { usePreviewNotify } from './context';
import HeaderNotifyAndroid from './HeaderNotifyAndroid';

const PhonePreview = ({ platform = 'IOS' }) => {
  const { title, description, image } = usePreviewNotify();

  return (
    <div className="phone-preview-wrapper">
      <div align="center">
        <div className="notify-preview__phone">
          <img
            alt="kindi"
            src="/static/images/iphone-top-bg.png"
            className="notify-preview__phone-img"
          />

          <div className="notify-preview__phone_info">
            <div className="notify-preview__noti_info">
              {platform === 'IOS' ? (
                <HeaderNotifyIOS />
              ) : (
                <HeaderNotifyAndroid />
              )}

              <div className="notify-preview__noti-content flex justify-between">
                <div className="overflow-hidden">
                  <div className="notify-preview__noti-title ellipsis-t">
                    {title || ''}
                  </div>
                  <div className="notify-preview__noti-desc ellipsis-t-2">
                    {description || ''}
                  </div>
                </div>

                <div className="notify-preview__noti-image">
                  {image ? <img alt="kindi" src={image} /> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="notify-preview__platform">{platform}</div>
    </div>
  );
};

PhonePreview.propTypes = {
  platform: PropTypes.string,
};

export default PhonePreview;
