import React, { useContext, useEffect } from 'react';
import { Col, Form, Row } from 'antd';
import i18next from 'i18next';
import ReferenceInput from 'containers/rest/ReferenceInput';
import RestSelect from 'components/RestInput/RestSelect';
import { PREFIX_URL } from 'configs/localData/constant';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { useSelector } from 'react-redux';

const CompanyBrandForm = () => {
  const { form } = useContext(RestInputContext);
  const currentData = useSelector((state) => state.centres.currentData) || {};

  const handleSelectCompany = (id) => {
    if (id) {
      form.setFieldsValue({
        brandId: null,
        governmentReferenceId: null,
      });
    }
  };

  useEffect(() => {
    if (currentData?.company?.id) {
      form.setFieldsValue({ companyId: currentData?.company?.id });
    }
    // eslint-disable-next-line
  }, [currentData]);

  return (
    <div>
      <Row gutter={16}>
        <Col md={12} xs={24}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues.brandId !== curValues.brandId ||
              prevValues.governmentReferenceId !==
                curValues.governmentReferenceId
            }
          >
            {({ getFieldValue }) => (
              <ReferenceInput
                prefixUrl={PREFIX_URL.superAdmin}
                resource="companies"
                source="companyId"
                searchKey="company.name"
                initialFilter={
                  getFieldValue('governmentReferenceId')
                    ? {
                        filter: {
                          id: { $in: [getFieldValue('companyId')] },
                        },
                      }
                    : { orderBy: 'name' }
                }
              >
                <RestSelect
                  rules={[
                    {
                      required: !!getFieldValue('brandId'),
                      message: i18next.t('input.company.customRequired'),
                    },
                  ]}
                  dependencies={['brandId']}
                  source="companyId"
                  onChange={handleSelectCompany}
                  titleProp="name"
                  valueProp="id"
                  disabled={!!getFieldValue('governmentReferenceId')}
                  header="centres.company"
                  placeholder="input.company.placeholder"
                  isFilterOption={false}
                />
              </ReferenceInput>
            )}
          </Form.Item>
        </Col>

        <Col md={12} xs={24}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues.governmentReferenceId !==
                curValues.governmentReferenceId ||
              prevValues.companyId !== curValues.companyId
            }
          >
            {({ getFieldValue }) => (
              <ReferenceInput
                prefixUrl={PREFIX_URL.superAdmin}
                source="brandId"
                resource="brands"
                searchKey="brand.name"
                initialFilter={{
                  filter: {
                    companyId: getFieldValue('companyId'),
                  },
                  orderBy: 'name',
                }}
              >
                <RestSelect
                  source="brandId"
                  titleProp="name"
                  valueProp="id"
                  header="centres.brand"
                  placeholder="input.brand.placeholder"
                  isFilterOption={false}
                  disabled={getFieldValue('companyId') === null}
                />
              </ReferenceInput>
            )}
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default CompanyBrandForm;
