import styled from 'styled-components';

const PreviewNotiStyles = styled.div`
  .phone-preview-wrapper + .phone-preview-wrapper {
    margin-top: 40px;
  }
  .notify-preview {
    &__title {
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
      color: ${({ theme }) => theme.palette.primary};
      text-align: center;
      margin-bottom: 20px;
    }

    &__phone {
      position: relative;
      display: table-cell;
    }

    &__phone-img {
      object-fit: contain;
      max-width: 100%;
      width: 426px;
    }

    &__phone_info {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      padding: 66px 37px 0 37px;
    }

    &__noti_info {
      text-align: left;
      display: flex;
      flex-direction: column;
      background: rgba(245, 245, 245, 0.6);
      backdrop-filter: blur(33.2019px);
      border-radius: 13px;
      padding: 12px;
      height: 118px;
    }

    &__noti-header {
      margin-bottom: 8px;
      .app-logo {
        background: #fff;
        padding: 2px;
        width: 20px;
        height: 20px;
        object-fit: contain;
      }
    }

    &__noti-logo-ios {
      .app-name {
        margin-left: 5px;
        font-size: 13px;
        line-height: 16px;
        color: #838383;
        text-transform: uppercase;
      }
    }
    &__noti-time-ios {
      text-transform: lowercase;
      color: #838383;
    }

    &__noti-title {
      font-weight: bold;
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 2px;
    }

    &__noti-desc {
      font-size: 16px;
      line-height: 20px;
      white-space: pre-line;
    }

    &__noti-image {
      img {
        width: 34px;
        height: 34px;
        object-fit: cover;
        border-radius: 6px;
      }
    }

    &__noti-header-android {
      font-size: 11px;
      line-height: 13px;
      color: #212121;
      display: flex;
      align-items: center;

      .dot {
        width: 3px;
        height: 3px;
        background-color: #000;
        border-radius: 50%;
      }
      span {
        margin-left: 5px;
      }
      .noti-time {
        text-transform: lowercase;
      }
      .anticon {
        font-size: 6px;
        color: #212121;
      }
    }

    &__platform {
      font-size: 18px;
      line-height: 23px;
      color: ${({ theme }) => theme.text.mainL2};
      margin-top: 12px;
      text-align: center;
    }
  }
`;

export default PreviewNotiStyles;
