import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import List from 'antd/lib/list';
import { useParams } from 'react-router';
import { getEmailTemplates } from 'redux/EDM/actions';

import TemplateCard from '../TemplateCard';
import EditModal from '../../CustomHtml/EditModal';

const TemplateList = ({ initialFilter, tab }) => {
  const dispatch = useDispatch();
  const { model } = useParams();

  const editModalRef = useRef();

  const { loading, data, resourceData } = useSelector(
    (state) => state.EDM.emailTemplates,
  );

  const getTemplates = (filter) => {
    dispatch(
      getEmailTemplates({
        data: { ...initialFilter, ...filter },
        options: { isRefresh: true },
      }),
    );
  };

  useEffect(() => {
    if (tab === model) {
      getTemplates();
    }
  }, [model]); //eslint-disable-line

  const onChangePagination = (page, pageSize) => {
    getTemplates({ limit: pageSize, offset: pageSize * (page - 1) });
  };

  const handleOpenEditModal = (item) => {
    editModalRef.current && editModalRef.current.open(item);
  };

  return (
    <div className="template-list-wrapper">
      <List
        loading={loading}
        grid={{
          type: 'flex',
          gutter: [20, 20],
          xs: 1,
          sm: 1,
          md: 1,
          lg: 1,
          xl: 1,
          xxl: 2,
        }}
        dataSource={data}
        pagination={{
          onChange: onChangePagination,
          pageSize: resourceData.limit,
          total: resourceData.total,
          current: resourceData.offset / resourceData.limit + 1,
          hideOnSinglePage: true,
        }}
        renderItem={(item) => (
          <List.Item>
            <TemplateCard
              item={item}
              openEditModal={() => handleOpenEditModal(item)}
            />
          </List.Item>
        )}
      />
      <EditModal ref={editModalRef} />
    </div>
  );
};

TemplateList.propTypes = {
  initialFilter: PropTypes.object,
  tab: PropTypes.string,
};

export default TemplateList;
