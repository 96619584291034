import { PREFIX_URL_API } from 'configs/localData/constant';
import { get, post, put } from './utils';

export async function getNotificationCountApi() {
  return get('/notifications/count');
}

export const sendNotificationApi = (data) =>
  post(`/notifications`, data, {}, PREFIX_URL_API);

export const sendTestNotificationApi = (data) =>
  post(`/notifications/test`, data, {}, PREFIX_URL_API);

export const getLatestEmailsSentNotificationApi = (data) =>
  get(`/notifications/test/last-email`, data, {}, PREFIX_URL_API);

export const checkNotExistEmailsApi = (data) =>
  get(`/notifications/test/not-exists-emails`, data, {}, PREFIX_URL_API);

export const editNotificationApi = (id, data) =>
  put(`/notifications/${id}`, data, {}, PREFIX_URL_API);
