import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { getAllClickThroughTracker } from 'redux/clickThroughTracker/actions';
import PageTitle from 'components/common/PageTitle';
import i18next from 'i18next';
import { getFilterFromUrl, getSearchUrl } from 'utils/tools';
import { useHistory, useLocation } from 'react-router';
import { LIMIT_ITEM } from 'configs/localData/constant';
import ClickThroughTrackerListTable from '../components/ClickThroughTrackerTable';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_CLICK_THROUGH_TRACKER,
} from '../components/LocalData/constant';
import Filter from '../components/Filter';

const ThroughTrackerList = () => {
  const dispatch = useDispatch();
  const { location, push } = useHistory();
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);
  const filterRef = useRef(filter);

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, keepFilter }) => {
      dispatch(
        getAllClickThroughTracker({
          data: {
            ...filter,
          },
          options: {
            keepFilter,
            isRefresh,
          },
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    retrieveList({
      filter: {
        sorts: filterRef.current.sorts || [DEFAULT_SORT_CLICK_THROUGH_TRACKER],
        page: filterRef.current.page || DEFAULT_CURRENT_PAGE,
        size: filterRef.current.size || LIMIT_ITEM,
        filter: filterRef.current.filter,
      },
      isRefresh: true,
      keepFilter: true,
    });
  }, [retrieveList]);

  const pushQuery = useCallback(
    (searchStr) => {
      push(`${location.pathname}?${getSearchUrl(searchStr)}`);
    },
    [location.pathname, push],
  );

  return (
    <div>
      <PageTitle>{i18next.t('clickThroughTracker.header')}</PageTitle>
      <Filter retrieveList={retrieveList} pushQuery={pushQuery} />
      <ClickThroughTrackerListTable
        retrieveList={retrieveList}
        pushQuery={pushQuery}
      />
    </div>
  );
};

export default ThroughTrackerList;
