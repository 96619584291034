import styled from 'styled-components';

export const ServiceStyle = styled.div`
  .bg-service {
    background-color: #fff;
    margin-top: -20px;
  }
  .header-style {
    margin-bottom: -20px;
  }
`;
