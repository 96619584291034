import React, { useCallback, useRef } from 'react';
import moment from 'moment';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import {
  SwapOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { pick } from 'lodash-es';
import { CENTRE_STATUS_VALUE } from 'configs/localData';
import { editClaimCentreRequests } from 'redux/claimCentreRequests/actions';
import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import ConfirmButtonIcon from 'components/RestActions/ConfirmButtonIcon';
import CustomButtonIcon from 'components/form/CustomButtonIcon';
import useFormatFunc from '../../DisputeCentres/List/useFormat';
import Filter from '../components/Filter';
import ConfirmClaimCentreModal from '../components/ConfirmClaimCentreModal';

const DisputeCentresList = (props) => {
  const useFormat = useFormatFunc();

  const dispatch = useDispatch();

  const confirmModalRef = useRef();

  const handleConfirm = useCallback(
    ({ record = {}, status, options }) => {
      dispatch(
        editClaimCentreRequests({
          data: { id: record.id, status },
          options: {
            isShowSuccessNoti: true,
            successDescription: i18next.t('success.description'),
            ...options,
          },
        }),
      );
    },
    [dispatch],
  );

  const checkVisibleAction = useCallback((record = {}, type) => {
    if (record?.isAdminEmailUsed) return false;
    if (type === CENTRE_STATUS_VALUE.inProcessing)
      return record.status === CENTRE_STATUS_VALUE.pending;
    return record.status === CENTRE_STATUS_VALUE.inProcessing;
  }, []);

  const formatFilter = useCallback(
    ({ createdAt, ...data }) => ({
      ...data,
      createdAt: createdAt
        ? {
            $gt: createdAt[0]
              ? moment(createdAt[0]).startOf('day').toISOString()
              : undefined,
            $lt: createdAt[1]
              ? moment(createdAt[1]).endOf('day').toISOString()
              : undefined,
          }
        : undefined,
    }),
    [],
  );

  const handleClickBtnProgress = (record) => {
    confirmModalRef?.current &&
      confirmModalRef.current.openModal(pick(record, ['id', 'centre']));
    handleConfirm({
      record,
      status: CENTRE_STATUS_VALUE.inProcessing,
      options: { isShowSuccessNoti: false },
    });
  };

  return (
    <div>
      <List
        {...props}
        resource="claimCentreRequests"
        hasCreate={false}
        hasSearch={false}
        filter={<Filter format={formatFilter} />}
        initialFilter={{ orderBy: '-createdAt' }}
        redirects={{
          edit: 'screen',
        }}
      >
        <RestFieldItem
          source="name"
          header="centreRequests.centreName"
          format={useFormat.formatCentre}
          width="20%"
        />
        <RestFieldItem
          source="centre.claimStatus"
          header="centres.centreStatus"
          format={useFormat.formatCentreStatus}
        />
        <RestFieldItem
          source="userId"
          header="centreRequests.sender"
          format={useFormat.formatUser}
          width="20%"
        />
        <RestFieldItem
          source="sender.phoneNumber"
          header="centreRequests.phone"
        />
        <RestFieldItem
          source="status"
          header="centreRequests.status"
          format={useFormat.formatStatus}
        />
        <RestFieldItem
          source="createdAt"
          header="centreRequests.time"
          format={useFormat.formatTime}
        />
        <ActionGroup
          widthAction={140}
          elementProps={{ style: { justifyContent: 'flex-end' } }}
        >
          <CustomButtonIcon
            title="button.process"
            checkVisibleButton={(record) =>
              checkVisibleAction(record, CENTRE_STATUS_VALUE.inProcessing)
            }
            icon={SwapOutlined}
            iconProps={{ style: { color: 'orange', fontSize: 25 } }}
            handleClick={handleClickBtnProgress}
          />
          <ConfirmButtonIcon
            IconComponent={CloseCircleOutlined}
            title="button.decline"
            alertText="claimCentreRequests.alertDecline"
            iconProps={{ style: { color: 'red', fontSize: 25 } }}
            confirm={(record) =>
              handleConfirm({ record, status: CENTRE_STATUS_VALUE.declined })
            }
            checkVisibleButton={(record) =>
              checkVisibleAction(record, 'declined')
            }
          />
          <ConfirmButtonIcon
            IconComponent={CheckCircleOutlined}
            title="button.approve"
            alertText="claimCentreRequests.alertApprove"
            iconProps={{ style: { color: 'green', fontSize: 25 } }}
            confirm={(record) =>
              handleConfirm({ record, status: CENTRE_STATUS_VALUE.approved })
            }
            checkVisibleButton={(record) =>
              checkVisibleAction(record, 'approved')
            }
          />
          <EditButton
            source="show"
            isView
            iconProps={{ style: { fontSize: 25 } }}
          />
        </ActionGroup>
      </List>

      <ConfirmClaimCentreModal
        ref={confirmModalRef}
        handleConfirm={handleConfirm}
      />
    </div>
  );
};

export default DisputeCentresList;
