import styled from 'styled-components';

const ModalStyles = styled.div`
  font-size: 16px;
`;

export default ModalStyles;

export const FooterActionStyles = styled.div`
  text-align: center;
  .approve-button {
    background: ${({ theme }) => theme.color.green} !important;
    border-color: ${({ theme }) => theme.color.green} !important;
    color: #fff;
    text-align: center;
  }
`;
