import React, { useCallback } from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Tabs } from 'antd';

import BrandsOfCompanyList from 'containers/Brands/List/BrandsOfCompanyList';
import ActivitiesTitleTab from 'containers/Providers/components/ProviderTabs/ActivitiesTitleTab';
import CentresTab from './CentresTab';
import SummaryTab from '../SummaryTab';
import ProvidersTab from '../ProvidersTab';
import UserTab from '../UserTab';
import EnquiriesTab from '../EnquiryTab';
import ApplicationTab from '../ApplicationTab';
import ActivitiesNotesTab from '../ActivitiesNotesTab';

const { TabPane } = Tabs;

const CompanyTabs = () => {
  const { push } = useHistory();

  const { id, modelDetail } = useParams();
  const currentData =
    useSelector((state) => state.companies?.currentData) || {};

  const TABS = [
    {
      key: 'summary',
      title: i18next.t('companies.tabs.summary'),
      TabComponent: SummaryTab,
    },
    {
      key: 'providers',
      title: i18next.t('companies.tabs.providers'),
      TabComponent: ProvidersTab,
    },
    {
      key: 'centres',
      title: i18next.t('centres.header'),
      TabComponent: CentresTab,
    },
    {
      key: 'brands',
      title: i18next.t('brands.title'),
      TabComponent: BrandsOfCompanyList,
    },
    {
      key: 'users',
      title: i18next.t('users.header'),
      TabComponent: UserTab,
    },
    {
      key: 'enquiries',
      title: i18next.t('companies.tabs.enquiries'),
      TabComponent: EnquiriesTab,
    },
    {
      key: 'applications',
      title: i18next.t('companies.tabs.applications'),
      TabComponent: ApplicationTab,
    },
    {
      key: 'activitiesNotes',
      title: <ActivitiesTitleTab />,
      TabComponent: ActivitiesNotesTab,
    },
  ];

  const onChange = useCallback(
    (key) => {
      push(`/companies/${id}/show/${key}`);
    },
    [push, id],
  );

  return (
    <div>
      <Tabs
        destroyInactiveTabPane
        activeKey={modelDetail || 'summary'}
        onChange={onChange}
      >
        {TABS.map((tab) => (
          <TabPane tab={tab.title} key={tab.key}>
            <tab.TabComponent record={currentData} />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default CompanyTabs;
