import React from 'react';
import { Button, notification, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import I18n from 'i18next';
import PropTypes from 'prop-types';
import { getUrl, uploadMedia } from 'api/uploadMedia';
import { logoutSuccess } from 'redux/auth/slice';
import { useDispatch } from 'react-redux';

const uploadUrl = `${process.env.REACT_APP_BASE_API_URL}/api/v1/uploadFile`;

export const CustomUpload = ({ onChange }) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const showNotiError = (description) => {
    notification.error({
      message: I18n.t('error.title'),
      description: I18n.t(description),
      position: 'tr',
    });
  };

  const onChangePreview = async ({ croppedFile }) => {
    try {
      setLoading(true);

      const responseS3 = await getUrl(croppedFile.name, croppedFile.type);
      if (responseS3) {
        try {
          const rest = await uploadMedia(responseS3.uploadUrl, croppedFile);
          if (rest?.status === 200) {
            setLoading(false);
            onChange && onChange(responseS3.url);
          } else {
            setLoading(false);
            showNotiError('error.errorUploadFile1');
          }
          return responseS3;
        } catch (error) {
          showNotiError('error.errorUploadFile2');
          return error;
        }
      }
      return responseS3;
    } catch (error) {
      setLoading(false);
      if (error.code === 401) {
        dispatch(logoutSuccess());
        showNotiError('error.error401');
      } else {
        showNotiError('error.description');
      }
      return error;
    }
  };

  const uploadProps = {
    accept: 'image/*',
    showUploadList: false,
    action: uploadUrl,
    beforeUpload: (file) => {
      onChangePreview({ croppedFile: file });
      return false;
    },
  };

  return (
    <Upload {...uploadProps}>
      <Button loading={loading} icon={<UploadOutlined />} />
    </Upload>
  );
};

CustomUpload.propTypes = {
  onChange: PropTypes.func,
};
