import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Tooltip, Popconfirm, Button } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

import { ButtonWrapper } from './styles';

const ConfirmButtonIcon = ({
  record,
  IconComponent,
  title,
  disabled,
  alertText,
  confirm,
  iconProps,
  isShowTooltip,
}) => {
  const handleConfirm = () => {
    confirm(record);
  };

  return (
    <Popconfirm
      placement="topLeft"
      title={i18next.t(alertText)}
      onConfirm={handleConfirm}
      okText="Yes"
      cancelText="No"
    >
      <Tooltip title={isShowTooltip ? i18next.t(title) : ''}>
        <ButtonWrapper>
          <Button
            type="primary"
            disabled={disabled}
            icon={<IconComponent {...iconProps} />}
          />
        </ButtonWrapper>
      </Tooltip>
    </Popconfirm>
  );
};

ConfirmButtonIcon.propTypes = {
  record: PropTypes.object,
  IconComponent: PropTypes.any,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  confirm: PropTypes.func,
  alertText: PropTypes.string,
  iconProps: PropTypes.object,
  isShowTooltip: PropTypes.bool,
};

ConfirmButtonIcon.defaultProps = {
  title: 'button.charge',
  confirm: () => null,
  iconProps: {},
  IconComponent: CheckCircleOutlined,
};

export default ConfirmButtonIcon;
