import React from 'react';
import { useSelector } from 'react-redux';
import TitleTabCount from './TitleTabCount';

const ActivitiesTitleTab = () => {
  const count = useSelector((state) => state.activities.summaries?.count);

  return <TitleTabCount title="activitiesAndNotes.title" count={count} />;
};

export default ActivitiesTitleTab;
