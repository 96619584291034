import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  retrieveReference as retrieveReferenceAction,
  retrieveReferenceInputData as retrieveReferenceInputDataAction,
} from 'redux/referenceData/actions';
import { getRecordData } from 'utils/tools';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { isEqual, debounce } from 'lodash-es';
import {
  getEnabledLoadMoreReference,
  getReferenceInputResource,
  getReferenceLoading,
} from '../../../redux/referenceData/selectors';

const ReferenceInput = (props) => {
  const {
    children,
    source,
    setFieldsValue,
    searchKey,
    resource,
    initialFilter,
    style,
    formatSource,
    onClear,
    prefixUrl,
    mappedBy,
    filterKey,
    customApiResource,
    searchOutsideFilter,
    notLikeFilter,
  } = props;

  const initialFilterRef = useRef();
  const { record } = useContext(RestInputContext);
  const dispatch = useDispatch();
  const resourceData = useSelector((state) =>
    getReferenceInputResource(state, props),
  );

  const loadingData = useSelector((state) => getReferenceLoading(state, props));

  const enabledLoadMore = useSelector((state) =>
    getEnabledLoadMoreReference(state, props),
  );

  const retrieveReference = (data) =>
    dispatch(
      retrieveReferenceAction({
        resource: props.resource,
        ids: Array.isArray(data) ? data : [data],
        mappedBy,
        filterKey,
        customApiResource,
        prefixUrl,
      }),
    );
  const retrieveList = (filter, isRefresh) => {
    dispatch(
      retrieveReferenceInputDataAction({
        resource,
        data: {
          ...initialFilter,
          ...filter,
          filter: {
            ...initialFilter?.filter,
            ...filter?.filter,
          },
        },
        options: {
          isRefresh,
          customApiResource,
          prefixUrl,
          primaryKey: mappedBy,
        },
      }),
    );
  };

  const onSearch = (value) => {
    if (searchKey) {
      if (searchKey === 'q' || searchOutsideFilter) {
        retrieveList({ outsideFilter: { [searchKey]: value?.trim() } }, true);
      } else
        retrieveList(
          { filter: { [searchKey]: notLikeFilter ? value : { $like: value } } },
          true,
        );
    }
  };

  const onClearData = () => {
    retrieveList({ filter: {} }, true);
    onClear && onClear();
    // eslint-disable-next-line
  };

  const retrieveListWaypoint = () => {
    if (enabledLoadMore) {
      retrieveList();
    }
  };

  useEffect(() => {
    if (getRecordData(record, source)) {
      retrieveReference(formatSource(getRecordData(record, source)));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!initialFilter || !isEqual(initialFilterRef.current, initialFilter)) {
      initialFilterRef.current = initialFilter;
      retrieveList(initialFilter || { limit: 10, offset: 0, filter: {} }, true);
    }

    // eslint-disable-next-line
  }, [initialFilter]);

  const newChildren = React.cloneElement(children, {
    onSearch: debounce(onSearch, 600),
    onClear: onClearData,
    onBlur: debounce(onClearData, 600),
    onEnter: () => retrieveListWaypoint(),
    searchKey,
    record,
    loading: loadingData,
    source,
    setFieldsValue,
    resourceData,
    resource,
    style,
    enabledLoadMore,
  });
  return newChildren;
};

ReferenceInput.propTypes = {
  resource: PropTypes.string.isRequired,
  resourceData: PropTypes.array,
  record: PropTypes.object,
  retrieveList: PropTypes.func,
  children: PropTypes.node,
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  retrieveReference: PropTypes.func,
  setFieldsValue: PropTypes.func,
  searchKey: PropTypes.string,
  enabledLoadMore: PropTypes.bool,
  loadingData: PropTypes.bool,
  initialFilter: PropTypes.object,
  style: PropTypes.object,
  formatSource: PropTypes.func,
  onClear: PropTypes.func,
  filterKey: PropTypes.string,
  searchOutsideFilter: PropTypes.bool,
  notLikeFilter: PropTypes.bool,
};

ReferenceInput.defaultProps = {
  formatSource: (data) => data,
};

export default ReferenceInput;
