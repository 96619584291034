import React from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { PREFIX_URL } from 'configs/localData/constant';
import { Col, Row } from 'antd';

import FilterCustom from 'components/common/FilterCustom';
import RestInputItem from 'components/RestInput/RestInputItem';
import ReferenceInput from 'containers/rest/ReferenceInput';
import RestSelect from 'components/RestInput/RestSelect';
import RestRangePickerInput from 'components/RestInput/RestRangePickerInput';
import { formatTimeFilterSubmit } from 'utils/dataUtils';

const FilterProvider = ({ retrieveList }) => {
  const { id } = useParams();

  const onSubmitFilter = ({ lastActivityTime, ...values }) => {
    retrieveList({
      filter: {
        offset: 0,
        filter: {
          ...values,
          lastActivityTime: formatTimeFilterSubmit(lastActivityTime),
        },
      },
      isRefresh: true,
    });
  };

  const onClearFilter = () => {
    retrieveList({
      filter: {
        offset: 0,
      },
      isRefresh: true,
    });
  };

  return (
    <FilterCustom
      onSubmitFilter={onSubmitFilter}
      onClearFilter={onClearFilter}
      responsiveFilter={{
        xxl: 20,
        xl: 18,
        lg: 24,
        md: 24,
        xs: 24,
      }}
      responsiveAction={{
        xxl: 4,
        xl: 6,
        lg: 24,
        md: 24,
        xs: 24,
      }}
    >
      <Row gutter={16}>
        <Col xl={4} md={12} xs={12}>
          <ReferenceInput
            prefixUrl={PREFIX_URL.superAdmin}
            source="id"
            resource={`companies/${id}/providers`}
            searchKey="name"
            initialFilter={{ orderBy: 'name' }}
          >
            <RestSelect
              source="id"
              placeholder="providers.providerName"
              isShowTooltip
              titleProp="name"
              valueProp="id"
              isFilterOption={false}
            />
          </ReferenceInput>
        </Col>
        <Col xl={4} md={12} xs={12}>
          <ReferenceInput
            prefixUrl={PREFIX_URL.superAdmin}
            source="centreId"
            resource={`companies/${id}/centresOfCompany`}
            searchKey="name"
            initialFilter={{ orderBy: 'name' }}
          >
            <RestSelect
              source="centreId"
              placeholder="centres.centreName"
              isShowTooltip
              titleProp="name"
              valueProp="id"
              isFilterOption={false}
            />
          </ReferenceInput>
        </Col>
        <Col xl={4} md={12} xs={12}>
          <RestInputItem
            source={['governmentReferenceId', '$like']}
            placeholder="centres.PRCode"
            isShowTooltip
          />
        </Col>
        <Col xl={4} md={12} xs={12}>
          <RestInputItem
            source="email"
            placeholder="input.email.name"
            isShowTooltip
          />
        </Col>
        <Col xl={4} md={12} xs={12}>
          <RestInputItem
            source="phoneNumber"
            placeholder="companies.phone"
            isShowTooltip
          />
        </Col>
        <Col xl={4} md={12} xs={12}>
          <RestRangePickerInput
            source="lastActivityTime"
            placeholder="common.time"
            isShowTooltip
            sourceGt="lastActivityTime.$gt"
            sourceLt="lastActivityTime.$lt"
          />
        </Col>
      </Row>
    </FilterCustom>
  );
};
FilterProvider.propTypes = {
  retrieveList: PropTypes.func,
};

export default FilterProvider;
