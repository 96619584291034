import React, { forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import RestAvatarInput from 'components/RestInput/RestAvatarInput';
import { useParams } from 'react-router';
import { getPrefixKeyUploadCentre } from 'utils/fileUtils';
import CentreModal from '.';

const UploadBusinessLogoModal = forwardRef(
  ({ visible, record, onCancel }, ref) => {
    const { id } = useParams();
    const [visibleModal, setVisibleModal] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const onCancelModal = () => setVisibleModal(false);

    useImperativeHandle(
      ref,
      () => ({
        toggleModal: () => {
          setVisibleModal(true);
        },
      }),
      [],
    );
    const formatOnSubmit = (values) => ({
      logo: {
        url: values?.logo?.url ? values?.logo?.url : values?.logo,
      },
    });
    return (
      <CentreModal
        title="centreDetail.editBrandLogo"
        visible={visible || visibleModal}
        onCancel={onCancel || onCancelModal}
        record={record}
        formatOnSubmit={formatOnSubmit}
        isDisabled={isDisabled}
      >
        <div className="flex-center">
          <RestAvatarInput
            setIsDisabled={setIsDisabled}
            style={{
              width: 180,
              height: 180,
            }}
            cropDimension={{ width: 300, height: 300 }}
            hasCrop
            source="logo"
            isValueObject
            customSource="logo.url"
            prefixKey={getPrefixKeyUploadCentre(id)}
          />
        </div>
      </CentreModal>
    );
  },
);

UploadBusinessLogoModal.propTypes = {
  record: PropTypes.object,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default UploadBusinessLogoModal;
