import React from 'react';
import PropTypes from 'prop-types';
import FormTreeSelect from '../../form/FormTreeSelect';
import { getRecordData } from '../../../utils/tools';
import { RestInputContext } from '../RestInputContext';

const RestTreeSelect = ({ defaultValue, formatValue, ...props }) => {
  const getDefaultValue = (record) => {
    if (formatValue) return formatValue(record);

    if (
      getRecordData(record, props.source) !== undefined &&
      getRecordData(record, props.source) !== null
    ) {
      return getRecordData(record, props.source);
    }
    return defaultValue;
  };
  return (
    <RestInputContext.Consumer>
      {({ record, form }) => (
        <FormTreeSelect
          {...props}
          form={form}
          defaultValue={getDefaultValue(record)}
        />
      )}
    </RestInputContext.Consumer>
  );
};

RestTreeSelect.propTypes = {
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  record: PropTypes.object,
  defaultValue: PropTypes.any,
  mode: PropTypes.string,
  valueProp: PropTypes.string,
  formatValue: PropTypes.func,
};

export default RestTreeSelect;
