import React from 'react';
import { Col, Row } from 'antd';
import ReferenceInput from 'containers/rest/ReferenceInput';
import { PREFIX_URL } from 'configs/localData/constant';
import RestSelect from 'components/RestInput/RestSelect';
import { FEATURED_STATUS } from 'configs/localData';
import i18next from 'i18next';

const Filter = (props) => (
  <div {...props}>
    <Row gutter={24}>
      <Col lg={6} xs={12}>
        <ReferenceInput
          prefixUrl={PREFIX_URL.superAdmin}
          resource="brands"
          source="id"
          searchKey="name"
        >
          <RestSelect
            titleProp="name"
            valueProp="id"
            placeholder="brands.brand"
            isFilterOption={false}
          />
        </ReferenceInput>
      </Col>
      <Col lg={6} xs={12}>
        <ReferenceInput
          prefixUrl={PREFIX_URL.superAdmin}
          resource="companies"
          source="companyId"
          searchKey="name"
        >
          <RestSelect
            titleProp="name"
            valueProp="id"
            placeholder="centres.companyTitle"
            isFilterOption={false}
          />
        </ReferenceInput>
      </Col>
      <Col lg={6} xs={12}>
        <RestSelect
          titleProp="text"
          valueProp="value"
          source="isFeatured"
          resourceData={FEATURED_STATUS}
          placeholder="centres.featured"
          formatText={(text) => i18next.t(text)}
        />
      </Col>
    </Row>
  </div>
);

export default Filter;
