import { Card } from 'antd';
import styled from 'styled-components';

const ProviderInfoStyles = styled(Card)`
  .edit-wrapper {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
  }
  .edit-div-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .avatar-provider {
    display: flex;
    justify-content: center;
    margin: 56px 0 32px 0;
    .ant-avatar {
      width: 120px !important;
      height: 120px !important;
    }
  }
  .content-info-container {
    .provider-info {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 32px;
      .title-info {
        font-weight: bold;
        margin-bottom: 16px;
      }
    }
  }
  .title-info-other {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 15px;
  }
  .phone-item-info {
    > span {
      display: block;
    }
    > span:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .value-info-item {
    word-break: break-word;
  }

  .content-info-other {
    > .item-info {
      display: flex;
      align-items: center !important;
      font-size: 18px;
      .ant-tag {
        font-size: 18px;
      }
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      & > .anticon {
        font-size: 16px;
        color: ${({ theme }) => theme.palette.primary};
        line-height: 30px;
        margin-right: 20px;
      }
    }
  }

  .item-info__subscription {
    max-width: calc(100% - 36px);
  }

  .address-section {
    .address-group > span {
      display: block;
    }
  }
  .provider-name-style {
    font-size: 24px;
    font-weight: bold;
    line-height: 2;
  }
  .link-item {
    word-break: break-word;
    color: ${({ theme }) => theme.text.primary};
  }

  .link-class {
    &:hover,
    &:active {
      color: ${({ theme }) => theme.palette.primary} !important;
    }
  }
`;

export default ProviderInfoStyles;
