import { crudSelectorsCustom } from 'redux/crudCreatorCustom/selector';
import { createSelector } from 'reselect';
import { MODEL_NAME } from './actions';

export const centresOfCompanySelectors = crudSelectorsCustom(MODEL_NAME);

const getRawResourceDataCentresOfCompany = (state) =>
  state.centresOfCompany?.resourceData;

const getRawCentresOfCompanyLoading = (state) =>
  state.centresOfCompany?.loading;

export const getResourceDataCentresOfCompany = createSelector(
  [getRawResourceDataCentresOfCompany],
  (data) => data,
);

export const getLoadingCentresOfCompany = createSelector(
  [getRawCentresOfCompanyLoading],
  (data) => data,
);
