import React, { useEffect, useMemo } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import RestShow from 'containers/rest/Show';
import { PREFIX_URL_API } from 'configs/localData/constant';
import {
  getAppSettingsCentre,
  getSummaryDetailCentre,
} from 'redux/centres/actions';
import { CentreProvider } from './context';
import CentreDetail from '../components/CentreDetail';

const CentresShow = (props) => {
  const id = props?.match?.params?.id;

  const currentData = useSelector((state) => state.centres.currentData) || {};

  const dispatch = useDispatch();

  const customBreadcrumb = useMemo(
    () => [
      {
        title: i18next.t('centres.header'),
        path: '/centres',
      },
      {
        title: i18next.t('centres.titleDetail'),
      },
      {
        title: currentData?.name || i18next.t('error.waitingUpdate'),
      },
    ],
    [currentData.name],
  );

  useEffect(() => {
    if (id) {
      dispatch(getSummaryDetailCentre(id));
      dispatch(getAppSettingsCentre(id));
    }
  }, [id, dispatch]);

  return (
    <CentreProvider>
      <RestShow
        {...props}
        resource="centres"
        customBreadcrumb={customBreadcrumb}
        defaultOptions={{ prefixUrl: PREFIX_URL_API }}
        header="centres.titleDetail"
        isResetStyle
      >
        <CentreDetail centreId={id} />
      </RestShow>
    </CentreProvider>
  );
};

CentresShow.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
};

export default CentresShow;
