import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import i18next from 'i18next';
import { get } from 'lodash-es';
import { imageUploadEditorHandler } from 'api/uploadMedia';

import FormItem from '../../form/FormItem';
import { RestInputContext } from '../RestInputContext';
import RestEditorWrapper from './styles';

const RestEditor = ({
  defaultValue,
  source,
  header,
  editorRef,
  height,
  placeholder,
  prefixKey,
  required,
  onChange,
}) => {
  const { record } = useContext(RestInputContext);

  const initialValue = useMemo(() => defaultValue || get(record, source), []); //eslint-disable-line

  return (
    <RestEditorWrapper>
      <FormItem
        label={i18next.t(header)}
        name={source}
        trigger="onEditorChange"
        initialValue={initialValue}
        className="form-item-editor"
        required={required}
      >
        <Editor
          ref={editorRef}
          onEditorChange={onChange}
          initialValue={initialValue}
          toolbar="undo redo | forecolor backcolor | styleselect | bold italic | emoticons | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link media image"
          plugins="paste emoticons code link advlist lists autolink image imagetools media quickbars"
          init={{
            images_upload_handler: (blobInfo, success, failure) =>
              imageUploadEditorHandler(blobInfo, success, failure, prefixKey),
            statusbar: false,
            placeholder: i18next.t(placeholder),
            height,
            quickbars_selection_toolbar:
              'bold italic | h2 h3 blockquote | alignleft aligncenter alignright alignjustify',
            quickbars_insert_toolbar: false,
            quickbars_image_toolbar:
              'imageoptions alignleft aligncenter alignright',
            menubar: 'edit insert format tools',
            menu: {
              format: {
                title: 'Format',
                items:
                  'bold italic underline strikethrough superscript code | formats blockformats fontSizes align lineheight |  removeformat',
              },
            },
            media_alt_source: false,
            media_poster: false,
          }}
        />
      </FormItem>
    </RestEditorWrapper>
  );
};

RestEditor.propTypes = {
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  header: PropTypes.string,
  height: PropTypes.number,
  placeholder: PropTypes.string,
  editorRef: PropTypes.any,
  defaultValue: PropTypes.string,
  prefixKey: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
};

RestEditor.defaultProps = {
  height: 400,
  placeholder: 'input.enterHere',
};

export default RestEditor;
