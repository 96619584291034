import React from 'react';
import { PREFIX_URL } from 'configs/localData/constant';

import Create from 'containers/rest/Create';
import Form from '../components/Form';

const CompanyCreate = (props) => (
  <Create
    {...props}
    resource="companies"
    defaultOptions={{
      isTrimStr: true,
      prefixUrl: PREFIX_URL.superAdmin,
    }}
  >
    <Form />
  </Create>
);

export default CompanyCreate;
