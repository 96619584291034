import React from 'react';
import { Tabs } from 'antd';
import i18next from 'i18next';
import { PropTypes } from 'prop-types';
import HeaderSection from 'components/common/HeaderSection';
import { TopSearchStatisticStyles } from '../../styles';
import SearchBySuburb from './SearchBySuburb';
import SearchByPostcode from './SearchByPostcode';
import { eventTime } from '../../../Events/index';

const { TabPane } = Tabs;

const TABS = [
  {
    key: 'suburb',
    text: 'searchStatistics.searchByLocation.bySuburb',
    Component: SearchBySuburb,
  },
  {
    key: 'postcode',
    text: 'searchStatistics.searchByLocation.byPostcode',
    Component: SearchByPostcode,
  },
];

const SearchByLocation = ({ retrieveList }) => {
  const onChange = (key) => {
    retrieveList({
      filter: {
        limit: 5,
        filter: {
          eventName: key === 'suburb' ? 'suburb' : 'postcode',
          eventTime,
        },
      },
      keepFilter: true,
      isRefresh: true,
    });
  };
  return (
    <TopSearchStatisticStyles>
      <HeaderSection title="searchStatistics.searchByLocation.title" />
      <Tabs defaultActiveKey onChange={onChange}>
        {TABS?.map((item) => (
          <TabPane tab={i18next.t(item.text)} key={String(item?.key)}>
            <item.Component />
          </TabPane>
        ))}
      </Tabs>
    </TopSearchStatisticStyles>
  );
};

SearchByLocation.propTypes = {
  retrieveList: PropTypes.func,
};

export default SearchByLocation;
