import React, { useCallback, useEffect } from 'react';
import { Col, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { getSearchStatistics } from 'redux/searchStatistics/actions';
import { getTotalSearchStatistic } from 'redux/totalSearchStatistic/actions';
import PageTitle from 'components/common/PageTitle';
import i18next from 'i18next';
import SearchByLocation from './components/SearchByLocation';
import { eventTime } from '../Events/index';
import TotalSearchTable from './components/TotalSearchTable';

const SearchStatistics = () => {
  const dispatch = useDispatch();
  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, keepFilter }) => {
      dispatch(
        getSearchStatistics({
          data: {
            ...filter,
            filter: {
              ...filter.filter,
            },
          },
          options: {
            keepFilter,
            isRefresh,
            isOrderBy: false,
          },
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    retrieveList({
      filter: {
        limit: 5,
        filter: {
          eventName: 'suburb',
          eventTime,
        },
      },
      keepFilter: true,
      isRefresh: true,
    });
  });

  const fetchTotalSearchData = useCallback(
    ({ filter = {} }) => {
      dispatch(
        getTotalSearchStatistic({
          data: {
            ...filter,
            filter: {
              ...filter.filter,
            },
          },
        }),
      );
    },
    [dispatch],
  );
  useEffect(() => {
    fetchTotalSearchData({
      filter: {
        filter: {
          eventTime,
        },
      },
    });
  });

  return (
    <>
      <PageTitle>{i18next.t('searchStatistics.header')}</PageTitle>
      <Row gutter={[30, 30]}>
        <Col lg={12} sm={24}>
          <TotalSearchTable fetchTotalSearchData={fetchTotalSearchData} />
        </Col>
        <Col lg={12} sm={24}>
          <SearchByLocation retrieveList={retrieveList} />
        </Col>
      </Row>
    </>
  );
};

export default SearchStatistics;
