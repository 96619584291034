import React, { useMemo, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getSubscriptionsArr } from 'redux/centres/selectors';
import { getFilterFromUrl } from 'utils/tools';
import { useLocation, useParams } from 'react-router';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { Col, Row } from 'antd';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import ReferenceInput from 'containers/rest/ReferenceInput';
import { PREFIX_URL_API } from 'configs/localData/constant';

const FilterCenterForm = ({
  retrieveList,
  idProp = 'providerId',
  brandProps,
  cityProps,
}) => {
  const { id } = useParams();
  const { search } = useLocation();
  const { form } = useContext(RestInputContext);

  const centreTypes = useSelector((state) => state.config.data?.CentreType);
  const subscriptionsArr = useSelector(getSubscriptionsArr);
  const NQSRating = useSelector((state) => state.config.data?.NQSRType);
  const resourceFilter = getFilterFromUrl(search);

  useEffect(() => {
    form.setFieldsValue(resourceFilter.filter);
    retrieveList({
      filter: {
        offset: 0,
        outsideFilter: resourceFilter?.filter,
      },
      isRefresh: true,
      keepOrderBy: true,
    });
  }, []); //eslint-disable-line

  const initialFilterBrands = useMemo(
    () => ({ orderBy: 'name', filter: { [idProp]: id } }),
    [], // eslint-disable-line
  );

  return (
    <Row gutter={16}>
      <Col xl={5} md={12} xs={12}>
        <RestInputItem
          source="centreName"
          placeholder="centres.centreName"
          isShowTooltip
        />
      </Col>
      <Col xl={5} md={12} xs={12}>
        <RestSelect
          resourceData={centreTypes || []}
          header="centres.centreServiceType"
          source="type"
          placeholder="centres.centreServiceType"
          isShowTooltip
        />
      </Col>
      <Col xl={5} md={12} xs={12}>
        <ReferenceInput
          prefixUrl={PREFIX_URL_API}
          source="brandId"
          resource="providers/brands"
          searchKey="name"
          initialFilter={initialFilterBrands}
          {...brandProps}
        >
          <RestSelect
            source="brandId"
            placeholder="centres.brandTitle"
            isShowTooltip
            titleProp="name"
            valueProp="id"
            isFilterOption={false}
          />
        </ReferenceInput>
      </Col>
      <Col xl={5} md={12} xs={12}>
        <ReferenceInput
          source="city"
          resource="suburbs"
          searchKey="city"
          filterKey="city"
          mappedBy="city"
          initialFilter={{ orderBy: 'city' }}
          prefixUrl={PREFIX_URL_API}
          {...cityProps}
        >
          <RestSelect
            header="input.suburb.name"
            titleProp="city"
            valueProp="city"
            placeholder="input.suburb.name"
            isFilterOption={false}
            isShowTooltip
          />
        </ReferenceInput>
      </Col>
      <Col xl={5} md={12} xs={12}>
        <RestSelect
          source="CRMPackageId"
          placeholder="centres.subscription"
          resourceData={subscriptionsArr}
          valueProp="id"
          titleProp="name"
          isShowTooltip
        />
      </Col>
      <Col xl={5} md={12} xs={12}>
        <RestSelect
          source="NQSROverallTypeId"
          placeholder="centres.NQSRating"
          resourceData={NQSRating || []}
          valueProp="id"
          titleProp="name"
          isShowTooltip
        />
      </Col>
    </Row>
  );
};

FilterCenterForm.propTypes = {
  retrieveList: PropTypes.func,
  idProp: PropTypes.string,
  brandProps: PropTypes.object,
  cityProps: PropTypes.object,
};

export default FilterCenterForm;
