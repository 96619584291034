/* eslint-disable react/jsx-curly-newline */
import React, { useCallback } from 'react';
import { EyeOutlined, UserAddOutlined } from '@ant-design/icons';

import { formatResponseTime } from 'utils/textUtils';
import useRouter from 'hooks/useRouter';

import {
  formatCentreName,
  formatCentreStatus,
  formatEnquiryStatus,
  formatEnquiryType,
  formatUser,
  formatSubscription,
  formatNightDayTable,
} from 'utils/formatFieldUtils';
import { formatTimeFilterSubmit } from 'utils/dataUtils';
import { convertObjToSearchStr } from 'utils/tools';

import List from 'containers/rest/List';
import ActionGroup from 'components/RestActions/ActionGroup';
import CustomButtonIcon from 'components/form/CustomButtonIcon';
import RestFieldItem from 'components/RestField/RestFieldItem';

import { markContactEnquiry } from './utils';
import Filter from '../components/Filter';

const InboxesList = (props) => {
  const { push } = useRouter();

  const handleAddUser = (record) => {
    push({
      pathname: 'users/create',
      search: convertObjToSearchStr({
        centreIds: record?.centre?.id ? [record.centre.id] : undefined,
        email: record?.centre?.adminEmail,
      }),
    });
  };

  const formatFilter = useCallback(
    ({ firstMessageDate, outsideFilter, ...data }) => {
      const time = formatTimeFilterSubmit(firstMessageDate);
      return {
        ...data,
        outsideFilter: {
          ...outsideFilter,
          firstMessageStartDate: time?.$gt,
          firstMessageEndDate: time?.$lt,
        },
      };
    },
    [],
  );

  const handleClickView = (record) => {
    record?.centre?.id &&
      push(
        `/centres/${record?.centre?.id}/show/enquiries/?inboxId=${record?.id}`,
      );
  };

  return (
    <List
      {...props}
      resource="inboxes"
      defaultOptions={{ prefixUrl: 'super-admin' }}
      widthTable={2000}
      hasSearch={false}
      hasCreate={false}
      filter={<Filter format={formatFilter} />}
      initialFilter={{ orderBy: '-firstMessageDate' }}
    >
      <RestFieldItem
        source="isContacted"
        header="common.action"
        format={markContactEnquiry}
        width={80}
      />
      <RestFieldItem
        sorter
        source="name"
        header="centres.centreName"
        format={(data, record) => formatCentreName(data, record?.centre)}
        width={250}
      />
      <RestFieldItem
        source="centre.claimStatus"
        header="centres.centreStatus"
        format={formatCentreStatus}
      />
      <RestFieldItem
        source="centre.CRMPackage.name"
        header="centres.subscription"
        format={formatSubscription}
      />
      <RestFieldItem source="centre.phone" header="centres.centrePhone" />
      <RestFieldItem
        source="enquiryType.name"
        header="inboxes.enquiryType"
        format={formatEnquiryType}
        width={160}
      />
      <RestFieldItem
        source="centreEnquiryStatus"
        header="inboxes.enquiryStatus"
        format={formatEnquiryStatus}
      />
      <RestFieldItem
        source="user"
        header="sender.sender"
        format={formatUser}
        width={250}
      />
      <RestFieldItem source="user.phoneNumber" header="sender.senderPhone" />
      <RestFieldItem
        sorter
        source="firstMessageDate"
        header="inboxes.enquiryDate"
        width={180}
        format={(data, record) => formatNightDayTable(record?.createdAt)}
      />
      <RestFieldItem
        sorter
        source="createdAt"
        header="inboxes.lastUpdate"
        width={180}
        format={(data, record) =>
          formatNightDayTable(record?.latestMessage?.createdAt)
        }
      />
      <RestFieldItem
        sorter
        source="responseTime"
        header="common.responseTime"
        format={formatResponseTime}
      />
      <ActionGroup widthAction={120}>
        <CustomButtonIcon
          title="button.view"
          handleClick={handleClickView}
          IconComponent={EyeOutlined}
        />
        <CustomButtonIcon
          IconComponent={UserAddOutlined}
          handleClick={handleAddUser}
          title="add user"
        />
      </ActionGroup>
    </List>
  );
};

export default InboxesList;
