import styled from 'styled-components';

const CentresListCustomStyles = styled.div`
  .no-data {
    padding: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .loading-centres {
    display: flex;
    justify-content: center;
  }
  .row-locations-list {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
  }
  .ant-progress-circle.ant-progress-status-success .ant-progress-text {
    color: #000;
  }
  .ant-progress-status-success
    .ant-progress-inner:not(.ant-progress-circle-gradient)
    .ant-progress-circle-path {
    stroke: #1890ff;
  }
`;

export default CentresListCustomStyles;
