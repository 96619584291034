import React, { useState, useMemo } from 'react';
import i18next from 'i18next';
import Form, { useForm } from 'antd/lib/form/Form';
import Modal from 'antd/lib/modal';

import CardSection from 'components/common/CardSection';
import RestSelect from 'components/RestInput/RestSelect';
import ReferenceInput from 'containers/rest/ReferenceInput';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import RestInputItem from 'components/RestInput/RestInputItem';
import { PREFIX_URL_API } from 'configs/localData/constant';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { sendIndividualEmail } from 'redux/EDM/actions';
import { useEmailTemplate } from '../../context';

const IndividualCentre = () => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const { template, setCentreId } = useEmailTemplate();

  const [loading, setLoading] = useState();

  const initialFilterCentre = useMemo(
    () => ({ orderBy: 'name', filter: { isActive: true } }),
    [],
  );

  const onSelect = (value) => {
    setCentreId(value);
  };
  const handleSendMail = (value) => {
    setLoading(true);

    dispatch(
      sendIndividualEmail({
        ...value,
        emailTemplateKey: template.key,
      }),
    ).finally(() => {
      setLoading(false);
    });
  };

  const onFinish = (value) => {
    if (template.key) {
      Modal.confirm({
        title: i18next.t('EDM.confirmTitleIndividualCentre'),
        content: i18next.t('EDM.confirmContentIndividualCentre', {
          email: value.receiverEmail,
        }),
        onOk() {
          handleSendMail(value);
        },
      });
    } else {
      Modal.warning({
        title: i18next.t('EDM.confirmTitleIndividualCentre'),
        content: i18next.t('EDM.confirmContentSelectTemplate'),
      });
    }
  };

  return (
    <CardSection title="marketings.individualCentre">
      <Form form={form} onFinish={onFinish}>
        <RestInputContext.Provider value={{ form }}>
          <RestInputItem
            source="receiverEmail"
            placeholder="input.recipientEmailAddress.name"
            required
            rules={[
              {
                type: 'email',
                message: i18next.t('error.email'),
              },
            ]}
          />

          <ReferenceInput
            prefixUrl={PREFIX_URL_API}
            source="centreId"
            resource="centres"
            searchKey="q"
            initialFilter={initialFilterCentre}
          >
            <RestSelect
              required
              titleProp="name"
              valueProp="id"
              placeholder="input.centre.placeholder"
              isFilterOption={false}
              onChange={onSelect}
            />
          </ReferenceInput>
        </RestInputContext.Provider>
        <div className="text-right">
          <Button htmlType="submit" type="primary" loading={loading}>
            {i18next.t('button.send')}
          </Button>
        </div>
      </Form>
    </CardSection>
  );
};

export default IndividualCentre;
