import React from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import EditButton from 'components/RestActions/EditButton';
import DeleteButton from 'components/RestActions/DeleteButton';
import { delArticles } from 'redux/articles/actions';
import { PREFIX_URL_API } from 'configs/localData/constant';

const GroupActions = ({ id }) => {
  const { push, location } = useHistory();

  const dispatch = useDispatch();

  const deleteItem = () => {
    id &&
      dispatch(
        delArticles({ data: { id }, options: { prefixUrl: PREFIX_URL_API } }),
      );
  };

  const onEdit = () => {
    push(`${location.search}#articles/${id}/edit`);
  };

  return (
    <div className="actions-articles flex-center">
      <EditButton onClickCustom={onEdit} />

      <DeleteButton
        resource="articles"
        deleteItem={deleteItem}
        customMessage="articles.customDescDelete"
      />
    </div>
  );
};

GroupActions.propTypes = {
  id: PropTypes.string,
};

export default GroupActions;
