import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import {
  PhoneIcon,
  MailIcon,
  MousePointerIcon,
} from 'components/common/SVGIcon';
import { getATagHref } from 'utils/tools';

const GeneralInfo = ({ phone, mobilePhone, adminEmail, website }) => (
  <div className="item-info-other">
    <div className="title-info-other">{i18next.t('centres.contactInfo')}</div>
    <div className="content-info-other">
      <div className="email-text item-info">
        <PhoneIcon />
        <span className="phone-item-info value-info-item">
          {phone && <span>{phone}</span>}
          {mobilePhone && <span>{mobilePhone}</span>}
        </span>
      </div>
      <div className="email-text item-info">
        <MailIcon />
        <span className="value-info-item">
          {adminEmail || i18next.t('error.waitingUpdate')}
        </span>
      </div>
      <div className="item-info">
        <MousePointerIcon />
        <span className="value-info-item">
          {website ? (
            <a
              href={getATagHref(website)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {website}
            </a>
          ) : (
            i18next.t('error.waitingUpdate')
          )}
        </span>
      </div>
    </div>
  </div>
);

GeneralInfo.propTypes = {
  phone: PropTypes.string,
  mobilePhone: PropTypes.string,
  adminEmail: PropTypes.string,
  website: PropTypes.string,
};

export default GeneralInfo;
