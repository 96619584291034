import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { editCentreService } from 'redux/centres/actions';
import { Switch } from 'antd';

const SwitchToggle = ({ id, checked, source }) => {
  const dispatch = useDispatch();

  const onChange = useCallback(
    (checked) => {
      id && dispatch(editCentreService({ data: { id, [source]: checked } }));
    },
    [id, dispatch, source],
  );

  return <Switch checked={checked} onChange={onChange} />;
};

SwitchToggle.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool,
  source: PropTypes.string,
};

export default SwitchToggle;
