import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@ant-design/icons';
import { ReactComponent as ICCustomer } from '../../../assets/icons/ic-customer.svg';
import { ReactComponent as Dashboard } from '../../../assets/icons/ic-dashboard.svg';
import { ReactComponent as ICSettingFill } from '../../../assets/icons/ic-setting.svg';
import { ReactComponent as Reservations } from '../../../assets/icons/ic-severvations.svg';
import { ReactComponent as Transactions } from '../../../assets/icons/ic-trasactions.svg';
import { ReactComponent as Users } from '../../../assets/icons/ic-user.svg';
import { ReactComponent as Bookings } from '../../../assets/icons/group-3.svg';
import { ReactComponent as CheckIn } from '../../../assets/icons/ic-user-checkin.svg';
import { ReactComponent as Delivery } from '../../../assets/icons/delivery.svg';
import { ReactComponent as Pharmacy } from '../../../assets/icons/pharmacy.svg';
import { ReactComponent as Preparing } from '../../../assets/icons/preparing.svg';
import { ReactComponent as Sending } from '../../../assets/icons/sending.svg';
import { ReactComponent as ICEdit } from '../../../assets/icons/ic-edit.svg';
import { ReactComponent as KindiCare } from '../../../assets/icons/ic-kindi-care.svg';
import { ReactComponent as ICPhone } from '../../../assets/icons/ic-phone.svg';
import { ReactComponent as ICMousePointer } from '../../../assets/icons/ic-mouse-pointer.svg';
import { ReactComponent as ICMail } from '../../../assets/icons/ic-mail.svg';
import { ReactComponent as ICCentreType } from '../../../assets/icons/ic-centre-type.svg';
import { ReactComponent as ICCentre } from '../../../assets/icons/ic-centre.svg';
import { ReactComponent as ICBookTour } from '../../../assets/icons/ic-book-tour.svg';
import { ReactComponent as ICTotalMoreInfo } from '../../../assets/icons/ic-total-more-info.svg';
import { ReactComponent as ICTotalEnquiries } from '../../../assets/icons/ic-total-enquiries.svg';
import { ReactComponent as ICTotalCheckVacancies } from '../../../assets/icons/ic-total-check-vacancies.svg';
import { ReactComponent as ICTotalOpenApplication } from '../../../assets/icons/ic-total-open-application.svg';
import { ReactComponent as ICTotalWaitlist } from '../../../assets/icons/ic-total-waitlist.svg';
import { ReactComponent as ICTotalEarning } from '../../../assets/icons/ic-total-earning.svg';
import { ReactComponent as ICTotalWaitlistValue } from '../../../assets/icons/ic-waitlist-value.svg';
import { ReactComponent as ICWaitlist } from '../../../assets/icons/ic-waitlist.svg';
import { ReactComponent as ICAttachment } from '../../../assets/icons/ic-attachment.svg';
import { ReactComponent as ICFileInbox } from '../../../assets/icons/ic-file-inbox.svg';
import { ReactComponent as ICRefresh } from '../../../assets/icons/ic-refresh.svg';
import { ReactComponent as ICSend } from '../../../assets/icons/ic-send.svg';
import { ReactComponent as ICMoon } from '../../../assets/icons/ic-moon.svg';
import { ReactComponent as ICSun } from '../../../assets/icons/ic-sun.svg';
import { ReactComponent as ICBrand } from '../../../assets/icons/ic-brand.svg';
import { ReactComponent as ICMarketing } from '../../../assets/icons/ic-marketing.svg';
import { ReactComponent as ICDraft } from '../../../assets/icons/ic-draft.svg';
import { ReactComponent as ICCalendar } from '../../../assets/icons/ic-calendar.svg';
import { ReactComponent as ICCheck } from '../../../assets/icons/ic-check.svg';
import { ReactComponent as ICTwinkle } from '../../../assets/icons/ic-twinkle.svg';
import { ReactComponent as ICCloseSquare } from '../../../assets/icons/ic-close-square.svg';
import { ReactComponent as ICFeeInformation } from '../../../assets/icons/ic-fee-information.svg';
import { ReactComponent as ICFilter } from '../../../assets/icons/ic-filter.svg';
import { ReactComponent as ICExcel } from '../../../assets/icons/ic-excel.svg';
import { ReactComponent as ICSettingOutline } from '../../../assets/icons/ic-setting-outline.svg';
import { ReactComponent as ICContactOutline } from '../../../assets/icons/ic-contact-outline.svg';
import { ReactComponent as ICPreferredContactMethodOutline } from '../../../assets/icons/ic-preferred-contact-method.svg';
import { ReactComponent as ICCompanies } from '../../../assets/icons/ic-companies.svg';
import { ReactComponent as ICInsights } from '../../../assets/icons/ic-insights.svg';
import { ReactComponent as ICSales } from '../../../assets/icons/ic-sales.svg';
import { ReactComponent as ICTotalCentre } from '../../../assets/icons/ic-total-centre.svg';
import { ReactComponent as ICTotalApplication } from '../../../assets/icons/ic-total-application.svg';
import { ReactComponent as ICUserContact } from '../../../assets/icons/ic-users.svg';
import { ReactComponent as ICUserRole } from '../../../assets/icons/ic-user-role.svg';
import { ReactComponent as ICIncomingCallCircleShadow } from '../../../assets/icons/ic-incoming-call-circle-shadow.svg';
import { ReactComponent as ICOutgoingCallCircleShadow } from '../../../assets/icons/ic-outgoing-call-circle-shadow.svg';
import { ReactComponent as ICNoteCircleShadow } from '../../../assets/icons/ic-note-circle-shadow.svg';
import { ReactComponent as ICEmailCircleShadow } from '../../../assets/icons/ic-email-circle-shadow.svg';
import { ReactComponent as ICOtherCircleShadow } from '../../../assets/icons/ic-other-circle-shadow.svg';
import { ReactComponent as ICProvider } from '../../../assets/icons/ic-provider.svg';
import { ReactComponent as ICFilterFill } from '../../../assets/icons/ic-filter-fill.svg';

import { ReactComponent as ICChild } from '../../../assets/icons/ic-child.svg';
import { ReactComponent as ICService } from '../../../assets/icons/ic-service.svg';
import { ReactComponent as ICTotalPlace } from '../../../assets/icons/ic-total-place.svg';
import { ReactComponent as ICLayoutList } from '../../../assets/icons/ic-layout-list.svg';
import { ReactComponent as ICLayoutBoard } from '../../../assets/icons/ic-layout-board.svg';
import { ReactComponent as ICIncognito } from '../../../assets/icons/ic-incognito.svg';
import { ReactComponent as ICRole } from '../../../assets/icons/ic-role.svg';
import { ReactComponent as ICJobTitle } from '../../../assets/icons/ic-job-title.svg';
import { ReactComponent as ArrowDown } from '../../../assets/icons/ic-arrow-down.svg';
import { ReactComponent as ICTotalProvider } from '../../../assets/icons/ic-total-provider.svg';
import { ReactComponent as ICCompany } from '../../../assets/icons/ic-company.svg';
import { ReactComponent as SaleStage } from '../../../assets/icons/ic-sale-stages.svg';
import { ReactComponent as PrNumber } from '../../../assets/icons/ic-pr-number.svg';
import { ReactComponent as SENumber } from '../../../assets/icons/ic-se-number.svg';
import { ReactComponent as Attactment } from '../../../assets/icons/attactment.svg';
import { ReactComponent as TrashWeb } from '../../../assets/icons/ic-trash-web.svg';
import { ReactComponent as ICLink } from '../../../assets/icons/ic-link.svg';
import { ReactComponent as ICEditListingStrength } from '../../../assets/icons/ic-edit-listing-strength.svg';
import { ReactComponent as ICShootingStar } from '../../../assets/icons/ic-shooting-star.svg';
import { ReactComponent as ICStarResult } from '../../../assets/icons/ic-star-result.svg';
import { ReactComponent as ICCheckDone } from '../../../assets/icons/ic-check-done.svg';
import { ReactComponent as ICCheckcircleUnCheck } from '../../../assets/icons/ic-checkcircle-uncheck.svg';
import { ReactComponent as ICCheckcircle } from '../../../assets/icons/ic-checkcircle.svg';
import { ReactComponent as ICReturn } from '../../../assets/icons/ic-return.svg';
import { ReactComponent as ICTotalCompany } from '../../../assets/icons/ic-total-company.svg';
import { ReactComponent as ICInfo } from '../../../assets/icons/ic-info.svg';
import { ReactComponent as ICChildcareServiceType } from '../../../assets/icons/ic-childcare-service-type.svg';
import { ReactComponent as ICACN } from '../../../assets/icons/ic-acn.svg';
import { ReactComponent as ICABN } from '../../../assets/icons/ic-abn.svg';
import { ReactComponent as ICSalesStage } from '../../../assets/icons/ic-panel.svg';
import { ReactComponent as ICMousePointerPrimary } from '../../../assets/icons/u_pointer.svg';
import { ReactComponent as ICBrief } from '../../../assets/icons/ic-brief.svg';
import { ReactComponent as ICSummaryBlue } from '../../../assets/icons/ic-summary-blue.svg';
import { ReactComponent as ICSummaryRed } from '../../../assets/icons/ic-summary-red.svg';
import { ReactComponent as ICSummaryChecked } from '../../../assets/icons/ic-summary-checked.svg';

const IconWrapper = ({ SVGComponent, svgProps, ...props }) => (
  <Icon
    {...props}
    component={() => <SVGComponent {...svgProps} fill="none" />}
  />
);

IconWrapper.propTypes = {
  SVGComponent: PropTypes.any,
  svgProps: PropTypes.object,
};

IconWrapper.defaultProps = {
  svgProps: {},
};

/** SVG Icon */
export const CustomerIcon = (props) => (
  <Icon {...props} component={ICCustomer} />
);

export const DashBoardIcon = (props) => (
  <Icon {...props} component={Dashboard} />
);

export const SettingFillIcon = (props) => (
  <Icon {...props} component={ICSettingFill} />
);

export const ReservationsIcon = (props) => (
  <Icon {...props} component={Reservations} />
);

export const TransactionsIcon = (props) => (
  <Icon {...props} component={Transactions} />
);

export const UsersIcon = (props) => <Icon {...props} component={Users} />;

export const BookingsIcon = (props) => <Icon {...props} component={Bookings} />;

export const CheckInIcon = (props) => <Icon {...props} component={CheckIn} />;

export const DeliveryIcon = (props) => <Icon {...props} component={Delivery} />;

export const PharmacyIcon = (props) => <Icon {...props} component={Pharmacy} />;

export const PreparingIcon = (props) => (
  <Icon {...props} component={Preparing} />
);

export const SendingIcon = (props) => <Icon {...props} component={Sending} />;

export const EditIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICEdit} />
);

export const KindiCareIcon = (props) => (
  <IconWrapper {...props} SVGComponent={KindiCare} />
);

export const PhoneIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPhone} />
);

export const MousePointerIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICMousePointer} />
);

export const MailIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICMail} />
);

export const CentreIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCentre} />
);

export const CentreTypeIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCentreType} />
);

export const TotalEnquiriesIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalEnquiries} />
);

export const TotalCheckVacanciesIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalCheckVacancies} />
);

export const BookTourIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBookTour} />
);

export const TotalMoreInfoIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalMoreInfo} />
);

export const TotalOpenApplicationIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalOpenApplication} />
);

export const WaitlistIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICWaitlist} />
);

export const TotalWaitlistIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalWaitlist} />
);

export const TotalEarningIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalEarning} />
);

export const TotalWaitlistValueIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalWaitlistValue} />
);

export const AttachmentIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICAttachment} />
);

export const FileInboxIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICFileInbox} />
);

export const RefreshIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICRefresh} />
);

export const SendIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICSend} />
);

export const MoonIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICMoon} />
);

export const SunIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICSun} />
);

export const BrandIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBrand} />
);

export const MarketingIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICMarketing} />
);

export const DraftIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICDraft} />
);

export const CalendarIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCalendar} />
);

export const CheckIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCheck} />
);

export const TwinkleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTwinkle} />
);

export const CloseSquareIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCloseSquare} />
);
export const FeeInformationIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICFeeInformation} />
);

export const FilterIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICFilter} />
);

export const ExcelIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICExcel} />
);

export const SettingOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICSettingOutline} />
);

export const ContactOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICContactOutline} />
);

export const PreferredContactMethodOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPreferredContactMethodOutline} />
);

export const CompaniesIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCompanies} />
);

export const InsightsIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICInsights} />
);

export const SalesIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICSales} />
);

export const TotalApplicationIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalApplication} />
);

export const TotalCentreIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalCentre} />
);

export const UserContactIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICUserContact} />
);

export const UserRoleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICUserRole} />
);

export const IncomingCallCircleShadowIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICIncomingCallCircleShadow} />
);

export const OutgoingCallCircleShadowIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICOutgoingCallCircleShadow} />
);

export const NoteCircleShadowIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICNoteCircleShadow} />
);

export const EmailCircleShadowIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICEmailCircleShadow} />
);

export const OtherCircleShadowIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICOtherCircleShadow} />
);

export const ProviderIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICProvider} />
);

export const FilterFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICFilterFill} />
);

export const ChildIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICChild} />
);

export const ServiceIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICService} />
);

export const TotalPlaceIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalPlace} />
);

export const LayoutBoardIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICLayoutBoard} />
);

export const LayoutListIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICLayoutList} />
);

export const IncognitoIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICIncognito} />
);

export const RoleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICRole} />
);

export const JobTitleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICJobTitle} />
);

export const ArrowDownIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ArrowDown} />
);

export const TotalProviderIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalProvider} />
);

export const CompanyIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCompany} />
);

export const SaleStageIcon = (props) => (
  <IconWrapper {...props} SVGComponent={SaleStage} />
);

export const PrNumberIcon = (props) => (
  <IconWrapper {...props} SVGComponent={PrNumber} />
);

export const SENumberIcon = (props) => (
  <IconWrapper {...props} SVGComponent={SENumber} />
);

export const AttactmentIcon = (props) => (
  <IconWrapper {...props} SVGComponent={Attactment} />
);

export const TrashIcon = (props) => (
  <IconWrapper {...props} SVGComponent={TrashWeb} />
);

export const LinkIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICLink} />
);

export const EditListingStrengthIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICEditListingStrength} />
);

export const ShootingStarIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICShootingStar} />
);

export const StarResultIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICStarResult} />
);

export const CheckDoneIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCheckDone} />
);

export const CheckCircleUncheckIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCheckcircleUnCheck} />
);

export const CheckCircleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCheckcircle} />
);

export const ReturnIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICReturn} />
);
export const TotalCompanyIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTotalCompany} />
);

export const InfoIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICInfo} />
);

export const ChildcareServiceTypeIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICChildcareServiceType} />
);

export const ACNIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICACN} />
);

export const ABNIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICABN} />
);

export const SalesStageIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICSalesStage} />
);

export const MousePointerPrimaryIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICMousePointerPrimary} />
);

export const BriefIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBrief} />
);

export const SummaryBlueIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICSummaryBlue} />
);

export const SummaryRedIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICSummaryRed} />
);

export const SummaryCheckedIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICSummaryChecked} />
);
