import React, { useCallback, useRef } from 'react';
import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import { formatDateTime } from 'utils/textUtils';
import { formatTimeFilterSubmit } from 'utils/dataUtils';
import CustomButtonIcon from 'components/form/CustomButtonIcon';
import ActionGroup from 'components/RestActions/ActionGroup';
import { EyeOutlined } from '@ant-design/icons';
import { DISPUTE_STATUS_TYPES } from 'configs/localData';
import i18next from 'i18next';
import { Tag } from 'antd';
import { Link } from 'react-router-dom';
import Filter from '../components/Filter';
import DisputeReviewModal from '../DisputeReviewModal';

const DisputeReviewList = (props) => {
  const disputeReviewModalRef = useRef();

  const openDisputeReviewModal = (record) => {
    disputeReviewModalRef.current.open(record?.id);
  };

  const disputeStatusFormat = (data) => {
    const item = DISPUTE_STATUS_TYPES.find((status) => status.value === data);
    return item.value ? (
      <Tag color={item.color}>{i18next.t(item.text)}</Tag>
    ) : null;
  };
  const formatFilter = useCallback(
    ({ disputeAt, ...data }) => ({
      ...data,
      disputeAt: formatTimeFilterSubmit(disputeAt),
    }),
    [],
  );

  return (
    <>
      <List
        {...props}
        resource="disputeReviewRequests"
        hasSearch={false}
        hasCreate={false}
        widthTable={800}
        initialFilter={{ orderBy: '-disputedAt' }}
        filter={<Filter format={formatFilter} />}
        defaultOptions={{
          prefixUrl: 'super-admin',
          customApiResource: 'review-dispute-request',
        }}
      >
        <RestFieldItem
          source="comment"
          header="disputeReviewRequests.comment"
          width="30%"
        />
        <RestFieldItem
          source="centre.name"
          header="disputeReviewRequests.centreName"
          width="30%"
          format={(data, record) => (
            <Link
              to={`centres/${record?.centre?.id}/show`}
              className="text-black"
            >
              {data}
            </Link>
          )}
        />
        <RestFieldItem
          source="disputation.reason"
          header="disputeReviewRequests.reason.title"
          width="20%"
        />
        <RestFieldItem
          sorter
          source="disputedAt"
          header="disputeReviewRequests.date"
          format={(data, record) =>
            formatDateTime(record?.disputation?.disputedAt)
          }
          width="20%"
        />
        <RestFieldItem
          source="disputation.status"
          header="disputeReviewRequests.status"
          format={disputeStatusFormat}
          width="15%"
        />
        <ActionGroup widthAction={100}>
          <CustomButtonIcon
            title="button.view"
            IconComponent={EyeOutlined}
            handleClick={openDisputeReviewModal}
          />
        </ActionGroup>
      </List>
      <DisputeReviewModal ref={disputeReviewModalRef} />
    </>
  );
};

export default DisputeReviewList;
