import styled from 'styled-components';

const EventsStyles = styled.div`
  .chart-by-time-box {
    border-radius: 16px;
    background: ${({ theme }) => theme.background.content};
  }

  .chart-by-time-wrapper,
  .legend-actions {
    padding: 20px;
  }

  .legend-actions {
    overflow-x: hidden;
    width: 100%;
    .ant-input-affix-wrapper {
      border: none;
      height: 45px;
      background: #f2f8fd;
      border-radius: 8px;
    }
    .ant-input-affix-wrapper > input.ant-input {
      background: #f2f8fd;
    }
  }

  .list-legend {
    height: 400px;
    overflow-y: scroll;
  }

  .chart-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 10px;
  }

  .legend-item {
    display: flex;
    align-items: center;
    &__line {
      border-radius: 8px;
      width: 36px;
      height: 6px;
      margin-right: 16px;
    }
  }

  .legend-col {
    display: flex;
    align-items: center;
    border-left: 1px solid ${({ theme }) => theme.border.light};
  }

  .section-groups > div:not(:last-child) {
    margin-bottom: 24px;
  }
  .ant-table-column-title {
    text-transform: uppercase;
  }

  .recharts-tooltip-wrapper {
    z-index: 6;
  }

  .ant-input-search-button {
    border-left: 1px solid #ffffff;
  }

  .anticon-search {
    color: #db147f;
  }

  .circle {
    width: 4px;
    height: 4px;
    background: #cbd2d9;
    border-radius: 50%;
    margin-left: 4px;
    margin-top: 9px;
  }

  .btn-select,
  .btn-clear {
    text-decoration: underline;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
  }
  .btn-clear {
    color: red;
  }
`;

export default EventsStyles;
