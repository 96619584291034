import React from 'react';
import { useSelector } from 'react-redux';

import SummaryView from 'components/common/SummaryCardDetail/SummaryView';
import { TotalCentreIcon } from 'components/common/SVGIcon';

const SummariesCentres = () => {
  const summaries =
    useSelector((state) => state.providers.summaries?.centresSummary) || {};

  const data = [
    {
      title: 'providerDetail.totalPlaces',
      value: summaries.totalPlace,
    },
    {
      title: 'providerDetail.enrolmentCoverage',
      value: summaries.enrolmentCoverage,
      isPercent: true,
    },
    {
      title: 'providerDetail.averageEnquiryResponseTime',
      value: summaries.averageEnquiryResponseTime,
      isTime: true,
    },
  ];

  return (
    <SummaryView
      IconComponent={TotalCentreIcon}
      title="providers.totalCentres"
      total={summaries?.totalCentres}
      data={data}
      color="#DB147F"
    />
  );
};

export default SummariesCentres;
