import React, { useState } from 'react';
import i18next from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Button } from 'antd';
import { resetPassword as resetPasswordAction } from 'redux/auth/actions';
import { useHistory } from 'react-router';
import {
  LockOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from '@ant-design/icons';

import { validateRegex } from 'utils/validateUtils';
import MaterialInput from 'components/common/MaterialInput';
import Text from 'components/common/Text';
import ResetPasswordStyleWrapper from './styles';
import logo from '../../assets/images/kindiCare_logo.svg';

const FormItem = Form.Item;

const ResetPassword = () => {
  const [form] = Form.useForm();
  const { getFieldValue, validateFields, setFields } = form;
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [state, setState] = useState({
    redirectToReferrer: isAuthenticated,
    isShowConfirmPassword: false,
    isShowPassword: false,
  });

  const resetPassword = (password, resetPasswordToken) => {
    dispatch(resetPasswordAction({ password, resetPasswordToken }));
  };

  const handleLogin = () => {
    const resetPasswordToken = history.location.search.replace('?token=', '');
    validateFields().then((values) => {
      if (values) {
        const { confirmPassword, password } = values;
        if (confirmPassword === password) {
          resetPassword(password, resetPasswordToken);
        } else {
          setFields({
            confirmPassword: {
              value: confirmPassword,
              errors: [
                new Error(i18next.t('input.confirmPassword.validateMsg.match')),
              ],
            },
          });
        }
      }
    });
  };

  const showPassword = (key) => () => {
    const currentData = state[key];
    setState({ ...state, [key]: !currentData });
  };

  const handleValidatePassword = (rule, value) => {
    if (!value || getFieldValue('password') === value) {
      return Promise.resolve();
    }
    return Promise.reject(i18next.t('input.confirmPassword.validateMsg.match'));
  };

  const { isShowPassword, isShowConfirmPassword } = state;

  return (
    <ResetPasswordStyleWrapper className="isoSignInPage">
      <div className="isoLoginContentWrapper">
        <div className="isoLoginContent">
          <div className="div-logo">
            <img alt="" src={logo} className="logo" />
          </div>
          <div className="title">
            <Text type="h2" align="center">
              {i18next.t('login.resetPassword')}
            </Text>
          </div>
          <div className="isoSignInForm">
            <Form form={form} onFinish={handleLogin}>
              <FormItem
                name="password"
                rules={[
                  {
                    required: true,
                    message: i18next.t('input.password.validateMsg.required'),
                  },
                  {
                    pattern: validateRegex.password,
                    message: i18next.t('input.password.validateMsg.pattern'),
                  },
                ]}
              >
                <MaterialInput
                  type={isShowPassword ? undefined : 'password'}
                  placeholder={i18next.t('login.password')}
                  prefix={<LockOutlined />}
                  suffix={
                    isShowPassword ? (
                      <EyeInvisibleOutlined
                        onClick={showPassword('isShowPassword')}
                      />
                    ) : (
                      <EyeOutlined onClick={showPassword('isShowPassword')} />
                    )
                  }
                />
              </FormItem>
              <FormItem
                name="confirmPassword"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: i18next.t(
                      'input.confirmPassword.validateMsg.required',
                    ),
                  },
                  { validator: handleValidatePassword },
                ]}
              >
                <MaterialInput
                  type={isShowConfirmPassword ? undefined : 'password'}
                  placeholder={i18next.t('input.confirmPassword.placeholder')}
                  prefix={<LockOutlined />}
                  suffix={
                    isShowConfirmPassword ? (
                      <EyeInvisibleOutlined
                        onClick={showPassword('isShowConfirmPassword')}
                      />
                    ) : (
                      <EyeOutlined
                        onClick={showPassword('isShowConfirmPassword')}
                      />
                    )
                  }
                />
              </FormItem>
              <div className="buttonWrapper">
                <Button type="primary" htmlType="submit">
                  {i18next.t('button.reset')}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </ResetPasswordStyleWrapper>
  );
};

export default ResetPassword;
