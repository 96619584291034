import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './auth/slice';
import modal from './modal/slice';
// import here
import clientUsers from './clientUsers/slice';
import businessUsers from './businessUsers/slice';
import tasks from './tasks/slice';
import activities from './activities/slice';
import companies from './companies/slice';
import brands from './brands/slice';
import marketings from './marketings/slice';
import applications from './applications/slice';
import CRMPackages from './CRMPackages/slice';
import inboxes from './inboxes/slice';
import changeEmailRequests from './changeEmailRequests/slice';
import claimCentreRequests from './claimCentreRequests/slice';
import disputeCentreRequests from './disputeCentres/slice';
import customers from './customers/slice';
import centreServices from './centreServices/slice';
import serviceTypes from './serviceTypes/slice';
import sessionTypes from './sessionTypes/slice';
import features from './features/slice';
import NQSRTypes from './NQSRTypes/slice';
import appSettings from './appSettings/slice';
import centres from './centres/slice';
import users from './users/slice';
import notifications from './notifications/slice';
import reference from './referenceData/slice';
import config from './config/slice';
import banners from './banners/slice';
import EDM from './EDM/slice';
import LGA from './LGA/slice';
import regions from './regions/slice';
import articles from './articles/slice';
import categories from './categories/slice';
import automatedResponses from './automatedResponses/slice';
import disputeReviewRequests from './disputeReviews/slice';
import statistic from './statistic/slice';
import suburbs from './suburbs/slice';
import providers from './providers/slice';
import superAdminUsers from './superAdminUsers/slice';
import centresOfProvider from './centresOfProvider/slice';
import usersOfProvider from './usersOfProvider/slice';
import centresOfCompany from './centresOfCompany/slice';
import brandsOfCompany from './brandsOfCompany/slice';
import centresOfUser from './centresOfUser/slice';
import providersOfUser from './providersOfUser/slice';
import companiesOfUser from './companiesOfUser/slice';
import unownedCentresOfUser from './unownedCentresOfUser/slice';
import unownedProvidersOfUser from './unownedProvidersOfUser/slice';
import unownedCompaniesOfUser from './unownedCompaniesOfUser/slice';
import providersOfCompany from './providersOfCompany/slice';
import usersOfCompany from './usersOfCompany/slice';
import centresOfBrand from './centresOfBrand/slice';
import enquiriesOfCompany from './enquiriesOfCompany/slice';
import applicationsOfCompany from './applicationsOfCompany/slice';
import searchStatistics from './searchStatistics/slice';
import totalSearchStatistic from './totalSearchStatistic/slice';
import providersLevelOfCompany from './providersLevelOfCompany/slice';
import childcareServiceTypes from './childcareServiceType/slice';
import serviceAgeGroups from './serviceAgeGroups/slice';
import clickThroughTracker from './clickThroughTracker/slice';
import jobApplicationsTracker from './jobApplicationsTracker/slice';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    automatedResponses,
    activities,
    modal,
    config,
    reference,
    // TODO: add reducer here
    clientUsers,
    businessUsers,
    CRMPackages,
    inboxes,
    changeEmailRequests,
    claimCentreRequests,
    disputeCentreRequests,
    customers,
    centreServices,
    serviceTypes,
    serviceAgeGroups,
    sessionTypes,
    features,
    NQSRTypes,
    appSettings,
    centres,
    users,
    notifications,
    banners,
    EDM,
    LGA,
    regions,
    applications,
    articles,
    categories,
    brands,
    companies,
    marketings,
    disputeReviewRequests,
    statistic,
    suburbs,
    providers,
    'super-admin-users': superAdminUsers,
    tasks,
    centresOfProvider,
    usersOfProvider,
    centresOfCompany,
    centresOfUser,
    providersOfUser,
    companiesOfUser,
    unownedCentresOfUser,
    unownedProvidersOfUser,
    unownedCompaniesOfUser,
    providersOfCompany,
    usersOfCompany,
    brandsOfCompany,
    centresOfBrand,
    enquiriesOfCompany,
    applicationsOfCompany,
    searchStatistics,
    providersLevelOfCompany,
    childcareServiceTypes,
    totalSearchStatistic,
    clickThroughTracker,
    jobApplicationsTracker,
  });
