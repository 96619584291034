import { makeActions } from 'redux/crudCreator/actions';

export const MODEL_NAME = 'sessionTypes';
export const sessionTypesActions = makeActions(MODEL_NAME);

export const getAllSessionTypes = sessionTypesActions.getAll;
export const editSessionTypes = sessionTypesActions.edit;
export const createSessionTypes = sessionTypesActions.create;
export const getByIdSessionTypes = sessionTypesActions.getDataById;
export const delSessionTypes = sessionTypesActions.del;
