import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash-es';
import i18next from 'i18next';
import { SearchOutlined } from '@ant-design/icons';
import Input from 'antd/lib/input';
import { useDispatch } from 'react-redux';
import { getAllSuperAdminUsers } from 'redux/superAdminUsers/actions';
import { PREFIX_URL_API } from 'configs/localData/constant';
import AssigneeList from './AssigneeList';
import AssigneePopoverStyles from './styles';

const AssigneePopover = ({ handleSelectedAssignee }) => {
  const dispatch = useDispatch();

  const retrieveList = useCallback(
    (filter) => {
      dispatch(
        getAllSuperAdminUsers({
          data: filter,
          options: { isRefresh: true, prefixUrl: PREFIX_URL_API },
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    retrieveList();
  }, [retrieveList]);

  const debounceGetList = debounce(retrieveList, 600);

  const onChange = (e) => {
    debounceGetList({ q: e.target?.value });
  };

  return (
    <AssigneePopoverStyles>
      <Input
        className="assignee-search-input"
        prefix={<SearchOutlined />}
        placeholder={i18next.t('input.searchMember.placeholder')}
        onChange={onChange}
      />

      <AssigneeList handleSelectedAssignee={handleSelectedAssignee} />
    </AssigneePopoverStyles>
  );
};

AssigneePopover.propTypes = {
  handleSelectedAssignee: PropTypes.func,
};

export default AssigneePopover;
