import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import Form from 'antd/lib/form';
import Button from 'antd/lib/button';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { Space } from 'antd';

const FilterColumnCustom = ({
  children,
  onSubmitFilter = () => null,
  onClearFilter = () => null,
}) => {
  const [form] = Form.useForm();

  const onFilter = () => {
    form
      .validateFields()
      .then((values) => {
        onSubmitFilter(values);
      })
      .catch(() => {});
  };

  const onClear = () => {
    form.resetFields();
    onClearFilter();
  };

  return (
    <div>
      <Form form={form} autoComplete="off">
        <RestInputContext.Provider
          value={{
            form,
            allowPressEnter: true,
            handleSubmit: onFilter,
          }}
        >
          <div>{children}</div>
          <Space className="flex-center-end">
            <Button size="small" onClick={onClear}>
              {i18next.t('button.reset')}
            </Button>
            <Button type="primary" size="small" onClick={onFilter}>
              {i18next.t('button.search')}
            </Button>
          </Space>
        </RestInputContext.Provider>
      </Form>
    </div>
  );
};

FilterColumnCustom.propTypes = {
  children: PropTypes.node,
  onSubmitFilter: PropTypes.func,
  onClearFilter: PropTypes.func,
};

export default FilterColumnCustom;
