import React, { useMemo } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { range } from 'lodash-es';
import { Col, DatePicker, Row, TimePicker } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { DEVICE_TIME_ZONE } from 'configs/localData/constant';
import momentTZ from 'moment-timezone';
import moment from 'moment';
import RestSelect from 'components/RestInput/RestSelect';
import CustomTZSelect from './CustomTZSelect';

const scheduleTypes = [
  {
    type: 'now',
    text: 'notifications.now',
    isSchedule: false,
  },
  {
    type: 'scheduled',
    text: 'notifications.scheduled',
    isSchedule: true,
  },
];

const ScheduleForm = ({ record, form }) => {
  const [isSchedule, setIsSchedule] = React.useState(!!record?.startedAt);

  const startedAtMoment = useMemo(
    () =>
      record?.startedAt
        ? momentTZ
            .utc(record?.startedAt)
            .tz(record?.timezone || DEVICE_TIME_ZONE)
        : null,
    [], // eslint-disable-line
  );

  const handleSelectChange = (value) => {
    setIsSchedule(value);
  };

  const disabledDate = (current) => {
    const timezone = form.getFieldValue('timezone');
    return (
      current &&
      moment(current).isBefore(
        moment().tz(timezone).format('YYYY-MM-DD'),
        'days',
      )
    );
  };

  const disabledHours = () => {
    const values = form.getFieldsValue();
    const currentDateWithTZ = moment().tz(values.timezone);
    const currentDateTZFormat = currentDateWithTZ.format('YYYY-MM-DD');

    if (moment(values.date).isBefore(currentDateTZFormat, 'days')) {
      return range(1, 24);
    }
    if (moment(values.date).isSame(currentDateTZFormat, 'days')) {
      return range(0, currentDateWithTZ.hours());
    }

    return [];
  };

  const disabledMinutes = (value) => {
    const values = form.getFieldsValue();
    const currentDateWithTZ = moment().tz(values.timezone);
    const currentDateTZFormat = currentDateWithTZ.format('YYYY-MM-DD');

    if (moment(values.date).isBefore(currentDateTZFormat, 'days')) {
      return range(0, 60);
    }
    if (moment(values.date).isSame(currentDateTZFormat, 'days')) {
      if (currentDateWithTZ.hours() > value) return range(0, 60);
      if (currentDateWithTZ.hours() === value)
        return range(0, currentDateWithTZ.minutes() + 1);
      return [];
    }

    return [];
  };

  return (
    <div>
      <RestSelect
        required
        resourceData={scheduleTypes}
        source="scheduleType"
        titleProp="text"
        valueProp="isSchedule"
        header="notifications.scheduleHeader"
        formatText={(text) => i18next.t(text)}
        isFilterOption={false}
        defaultValue={!!record?.startedAt}
        onChange={(value) => handleSelectChange(value, form)}
      />
      {isSchedule && (
        <div className="datetime-selector">
          <Row gutter={[10, 10]}>
            <Col xl={6} lg={6} md={12} xs={12}>
              <FormItem
                initialValue={startedAtMoment}
                name="date"
                dependencies={['timezone']}
                rules={[
                  {
                    required: true,
                    message: i18next.t('error.required'),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        !value ||
                        moment(value).isSameOrAfter(
                          momentTZ()
                            .tz(getFieldValue('timezone'))
                            .format('YYYY-MM-DD'),
                          'days',
                        )
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        i18next.t(
                          'input.startedAt.validateMsg.greaterCurrentDate',
                        ),
                      );
                    },
                  }),
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  allowClear={false}
                  disabledDate={disabledDate}
                />
              </FormItem>
            </Col>
            <Col xl={6} lg={6} md={12} xs={12}>
              <FormItem
                initialValue={startedAtMoment}
                name="time"
                dependencies={['timezone']}
                rules={[
                  {
                    required: true,
                    message: i18next.t('error.required'),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, time) {
                      const date = getFieldValue('date');

                      if (date && time) {
                        const currentDateTZFormat = moment()
                          .tz(getFieldValue('timezone'))
                          .format('YYYY-MM-DD HH:mm');

                        const dateTime = date.set({
                          hour: moment(time).hours(),
                          minute: moment(time).minutes(),
                          second: 0,
                          millisecond: 0,
                        });

                        if (
                          moment(dateTime).isSameOrBefore(
                            currentDateTZFormat,
                            'minutes',
                          )
                        ) {
                          return Promise.reject(
                            i18next.t(
                              'input.startedAt.validateMsg.greaterCurrentTime',
                            ),
                          );
                        }
                        return Promise.resolve();
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <TimePicker
                  minuteStep={15}
                  disabledHours={disabledHours}
                  disabledMinutes={disabledMinutes}
                  allowClear={false}
                  format="hh:mm A"
                  showNow={false}
                />
              </FormItem>
            </Col>
            <Col xl={12} lg={12} md={24} xs={24}>
              <FormItem
                name="timezone"
                initialValue={record?.timezone || DEVICE_TIME_ZONE}
                rules={[
                  {
                    required: true,
                    message: i18next.t('error.required'),
                  },
                ]}
              >
                <CustomTZSelect />
              </FormItem>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

ScheduleForm.propTypes = {
  record: PropTypes.object,
  form: PropTypes.object,
};

export default ScheduleForm;
