import PageTitle from 'components/common/PageTitle';
import i18next from 'i18next';
import React, { useCallback, useEffect, useRef } from 'react';
import SummariesSection from 'containers/JobApplicationsTracker/components/SummariesSection';
import { useHistory, useLocation } from 'react-router';
import { getFilterFromUrl, getSearchUrl } from 'utils/tools';
import {
  DEFAULT_CURRENT_PAGE,
  LIMIT_ITEM,
  SORT_OPTIONS,
} from 'containers/JobApplicationsTracker/constants';
import FilterContainer from 'containers/JobApplicationsTracker/components/FilterContainer';
import Table from 'containers/JobApplicationsTracker/components/Table';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllJobApplicationsTrackerData,
  getJobApplicationsTrackerConfig,
} from 'redux/jobApplicationsTracker/actions';
import {
  getIsLoadingGetAllData,
  getSummariesData,
} from 'redux/jobApplicationsTracker/selectors';

const JobApplicationsTrackerList = () => {
  const dispatch = useDispatch();
  const { location, push } = useHistory();
  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);
  const filterRef = useRef(filter);

  const isLoading = useSelector(getIsLoadingGetAllData);
  const summariesData = useSelector(getSummariesData);

  const fetchDataList = useCallback(
    ({ filter = {}, isRefresh, keepFilter }) => {
      dispatch(
        getAllJobApplicationsTrackerData({
          data: {
            ...filter,
          },
          options: {
            keepFilter,
            isRefresh,
          },
        }),
      );
    },
    [dispatch],
  );

  const fetchConfig = useCallback(
    () => dispatch(getJobApplicationsTrackerConfig()),
    [dispatch],
  );

  useEffect(() => {
    fetchConfig();
    fetchDataList({
      filter: {
        sorts: filterRef.current.sorts || [SORT_OPTIONS.APPLY_TIME_DESC],
        page: filterRef.current.page || DEFAULT_CURRENT_PAGE,
        size: filterRef.current.size || LIMIT_ITEM,
        filter: filterRef.current.filter,
      },
      isRefresh: true,
      keepFilter: true,
    });
  }, [fetchConfig, fetchDataList]);

  const pushQueryUrl = useCallback(
    (searchStr) => {
      push(`${location.pathname}?${getSearchUrl(searchStr)}`);
    },
    [location.pathname, push],
  );

  return (
    <div>
      <PageTitle>{i18next.t('jobApplicationsTracker.header')}</PageTitle>
      <SummariesSection summariesData={summariesData} />
      <FilterContainer
        fetchDataList={fetchDataList}
        pushQueryUrl={pushQueryUrl}
      />
      <Table
        isLoading={isLoading}
        fetchDataList={fetchDataList}
        pushQueryUrl={pushQueryUrl}
      />
    </div>
  );
};

export default JobApplicationsTrackerList;
