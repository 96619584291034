import React from 'react';
import RestShow from 'containers/rest/Show';
import RestFieldItem from 'components/RestField/RestFieldItem';

const AppSettingsShow = (props) => (
  <RestShow {...props} hasEdit resource="appSettings">
    <RestFieldItem source="key" header="appSettings.key" />
  </RestShow>
);

export default AppSettingsShow;
