import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Input } from 'antd';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestMultiPhotos from 'components/RestInput/RestMultiPhotos';

const FeaturesForm = ({ isEdit, ...props }) => (
  <div {...props}>
    <RestInputItem
      required
      source="name"
      header="features.name"
      placeholder="features.name"
      isValidatorEmpty
    />
    <RestInputItem
      source="description"
      header="features.description"
      placeholder="features.description"
      ContentComponent={Input.TextArea}
      rows={6}
    />
    {isEdit && (
      <RestInputItem
        source="isActive"
        header="features.isActive"
        placeholder="features.isActive"
        ContentComponent={Switch}
        ruleType="boolean"
        valuePropName="checked"
      />
    )}

    <RestMultiPhotos
      source="icon"
      header="features.image"
      placeholder="features.image"
      required
      multiple={false}
      objectFit="contain"
      width="auto"
      resource="features"
    />
  </div>
);

FeaturesForm.propTypes = {
  isEdit: PropTypes.bool,
};

export default FeaturesForm;
