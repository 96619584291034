import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import { isInvalidEmail } from 'utils/dataUtils';

const TagEmailRender = ({ value, ...props }) => (
  <Tag {...props} {...(isInvalidEmail(value) && { color: 'error' })}>
    {value}
  </Tag>
);

TagEmailRender.propTypes = {
  value: PropTypes.string,
};

export default TagEmailRender;
