import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { debounce } from 'lodash-es';
import { CloseCircleFilled, SearchOutlined } from '@ant-design/icons';

const SearchInput = ({ getListAndSummary }) => {
  const ref = useRef(null);

  const handleSearch = () => {
    getListAndSummary({ q: ref.current.state.value });
  };

  const handleClear = () => {
    ref.current.state.value = '';
    getListAndSummary({ q: '' });
  };

  return (
    <div className="search-input" style={{ padding: '0 14px' }}>
      <Input
        ref={ref}
        onChange={debounce(handleSearch, 600)}
        suffix={<SearchOutlined />}
        {...(ref.current?.state?.value &&
          ref.current?.state?.value?.trim() !== '' && {
            suffix: <CloseCircleFilled onClick={handleClear} />,
          })}
        placeholder="Search Enquiries"
      />
    </div>
  );
};

SearchInput.propTypes = {
  getListAndSummary: PropTypes.func,
};

SearchInput.defaultProps = {
  getListAndSummary: () => null,
};

export default SearchInput;
