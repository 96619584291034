import styled from 'styled-components';

export const ProgressWrapperStyles = styled.div`
  height: auto;
  .search-img {
    margin-right: 12px;
  }
  .top-search-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0px 10px 0px;
  }
  .progress {
    width: 100%;
  }
  .top-search-title {
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2d1f21;
  }
  .ant-progress-inner {
    height: 10px;
    transform: rotate(180deg);
    background-color: #fff !important;
  }
`;
