import React from 'react';
import PropTypes from 'prop-types';
import { Space } from 'antd';
import GroupActionsStyles from './styles';

const GroupActions = ({ children, align = 'center' }) => (
  <GroupActionsStyles className={`group-action__${align}`}>
    <Space size={5}>{children}</Space>
  </GroupActionsStyles>
);

GroupActions.propTypes = {
  align: PropTypes.oneOf(['center', 'left', 'right']),
  children: PropTypes.node,
};

export default GroupActions;
