import styled from 'styled-components';

const ViewModeSwitcherStyles = styled.div`
  .group-tab {
    .ant-radio-button-wrapper {
      background: transparent;
      border: none;
      font-size: 16px;
      padding: 0;
      box-shadow: none;

      .icon-tab {
        margin-right: 10px;
        font-size: 20px;
      }
      &::before {
        width: 0 !important;
      }

      &:not(:first-child) {
        padding-left: 20px;
      }
    }
  }
`;

export default ViewModeSwitcherStyles;
