import styled from 'styled-components';

export const SummaryDetailCardStyles = styled.div`
  .col-title-item {
    display: flex;
    font-size: 16px;
    .anticon {
      font-size: 30px;
      margin-right: 10px;
      g {
        display: contents;
      }
    }
  }
  .col-value-item {
    font-size: 16px;
    font-weight: bold;
    word-break: break-word;
  }
  .ant-divider.ant-divider-horizontal {
    margin: 15px 0 !important;
  }
`;

export const SummaryViewStyles = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 16px;
  height: 100%;
  .title-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .anticon {
      margin-right: 14px;
      font-size: 55px;
    }
    &__left {
      display: flex;
      align-items: center;
    }
    &__name {
      word-break: break-word;
      font-weight: bold;
      font-size: 18px;
      line-height: 23px;
    }
    &__total {
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
    }
  }

  .ant-divider.ant-divider-horizontal {
    margin: 15px 0 !important;
  }
`;
