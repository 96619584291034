import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { providersSelectors } from 'redux/providers/selector';
import ListCheckboxSelect from './ListCheckboxSelect';

const ProviderListSelect = ({ retrieveList }) => {
  const currentData =
    useSelector((state) => state.providers?.currentData) || {};
  const data = [currentData];
  const loading = useSelector(providersSelectors.getLoading);

  return (
    <ListCheckboxSelect
      retrieveList={retrieveList}
      name="providerIds"
      defaultValue={[currentData?.id]}
      data={data}
      messageRequired="users.selectProviderRequired"
      selectedResourceTitles="users.selectedProvidersTotal"
      selectedResourceTitle="users.selectedProviderTotal"
      loading={loading}
    />
  );
};

ProviderListSelect.propTypes = {
  retrieveList: PropTypes.func,
};

export default ProviderListSelect;
