import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import Button from 'antd/lib/button';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { PaginationCustomStyles } from './styles';

const PagingCustom = ({ retrieveList, paging = {} }) => {
  const total = paging.count || paging.total || 0;

  const nextCount = paging.offset + paging.limit;

  const onPreviousPage = () => {
    retrieveList({
      filter: {
        offset: paging.offset - paging.limit,
      },
      isRefresh: true,
      keepFilter: true,
    });
  };

  const onNextPage = () => {
    retrieveList({
      filter: {
        offset: paging.offset + paging.limit,
      },
      isRefresh: true,
      keepFilter: true,
    });
  };

  return (
    <PaginationCustomStyles className="paging-custom">
      <div className="paging-custom-number">
        {`${total ? paging.offset + 1 : 0} - ${
          total > nextCount ? nextCount : total
        } ${i18next.t('common.of')} ${total}`}
      </div>

      <div className="paging-custom-action">
        <Button
          onClick={onPreviousPage}
          disabled={paging.offset < 1}
          icon={<LeftOutlined />}
        />

        <Button
          onClick={onNextPage}
          disabled={nextCount >= total}
          icon={<RightOutlined />}
        />
      </div>
    </PaginationCustomStyles>
  );
};

PagingCustom.propTypes = {
  retrieveList: PropTypes.func,
  paging: PropTypes.object,
};

export default PagingCustom;
