import React, {
  useMemo,
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Button, Spin } from 'antd';
import { Waypoint } from 'react-waypoint';
import i18next from 'i18next';

import { useSelector } from 'react-redux';

import { CheckOutlined } from '@ant-design/icons';
import { RefreshIcon } from 'components/common/SVGIcon';
import EmptyData from 'components/common/EmptyData';
import {
  getMessagesLoadingSelector,
  enabledLoadMoreMessagesSelector,
} from 'redux/inboxes/selectors';

import MessageItem from './MessageItem';
import { MessageBodyStyles } from './styles';

const MessageBody = ({
  listMessages,
  retrieveList,
  isShowAvatar,
  centreId,
  inboxId,
}) => {
  const containerRef = useRef(null);

  const lengthMessages = listMessages?.length;

  const loading = useSelector(getMessagesLoadingSelector);
  const enabledLoadMore = useSelector(enabledLoadMoreMessagesSelector);

  const [isScrollDowned, setIsScrollDowned] = useState(false);

  const isSeen = useMemo(
    () => centreId === listMessages?.[0]?.senderId && listMessages?.[0]?.isRead,
    [listMessages, centreId],
  );

  const scrollToBottom = useCallback(() => {
    const msgEnd = document.getElementById('message-end-div');
    msgEnd &&
      document
        .getElementById('message-body')
        .scrollTo({ top: msgEnd.offsetTop });
  }, []);

  const handleEnterWaypoint = useCallback(() => {
    if (isScrollDowned) {
      retrieveList({}, false).then(({ payload: { data } }) => {
        const msgEnd = document.getElementById(data?.results?.[0]?.id);

        document
          .getElementById('message-body')
          .scrollTo({ top: msgEnd.offsetTop - 100 });
      });
    }
  }, [retrieveList, isScrollDowned]);

  const handleReloadNewMessage = useCallback(() => {
    retrieveList({}, true).then(() => {
      scrollToBottom();
    });
  }, [retrieveList, scrollToBottom]);

  const checkReceived = useCallback((id) => centreId === id, [centreId]);

  useEffect(() => {
    setIsScrollDowned(false);
    inboxId &&
      retrieveList({}, true).then(() => {
        scrollToBottom();
        setIsScrollDowned(true);
      });
  }, [inboxId]); // eslint-disable-line

  const containerHeight = useMemo(
    () =>
      `calc(100vh - ${
        containerRef.current?.getBoundingClientRect().top + 78 + 10
      }px)`,
    [],
  );

  return (
    <MessageBodyStyles
      id="message-body"
      ref={containerRef}
      style={{ height: containerHeight }}
    >
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spin />
        </div>
      )}
      {lengthMessages > 0 && (
        <>
          {enabledLoadMore && <Waypoint onEnter={handleEnterWaypoint} />}
          <div className="messages-list" id="messages-content">
            {isSeen && (
              <div className="seen-section">
                <CheckOutlined />
                <span>{i18next.t('inboxes.seen')}</span>
              </div>
            )}
            {listMessages?.map((message) => (
              <MessageItem
                key={message.id}
                message={message}
                checkReceived={checkReceived}
                isShowAvatar={isShowAvatar}
              />
            ))}
          </div>
          {!loading && (
            <Button
              className="reload-new-message"
              icon={<RefreshIcon />}
              onClick={handleReloadNewMessage}
            >
              {i18next.t('inboxes.reloadNewMessage')}
            </Button>
          )}
        </>
      )}
      {!loading && lengthMessages < 1 && <EmptyData />}
      <div id="message-end-div" />
    </MessageBodyStyles>
  );
};

MessageBody.propTypes = {
  listMessages: PropTypes.array,
  retrieveList: PropTypes.func,
  isShowAvatar: PropTypes.bool,
  centreId: PropTypes.string,
  inboxId: PropTypes.string,
};

MessageBody.defaultProps = {
  isShowAvatar: true,
  listMessages: [],
  retrieveList: () => null,
};

export default MessageBody;
