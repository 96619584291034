import styled from 'styled-components';

const EnquirySettingModalStyles = styled.div`
  .enquiry-setting-item {
    font-size: 16px;
    &__title {
      font-weight: bold;
    }

    &__description {
      color: ${({ theme }) => theme.text.mainL2};
    }
  }
`;

export default EnquirySettingModalStyles;
