import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { Select, Spin } from 'antd';
import { Waypoint } from 'react-waypoint';
import { map, isObject, isEmpty, get } from 'lodash-es';
import { getRecordData, onSearch as onChangeSearch } from 'utils/tools';
import FormItem from '../FormItem';
import { SelectWrapper } from './style';

const { Option } = Select;

const FormSelect = ({
  allowClear,
  header,
  required,
  placeholder,
  resourceData,
  valueProp,
  titleProp,
  onSearch,
  onClear,
  onChange,
  selectProps,
  formatText,
  onEnter,
  isFilterOption,
  isShowSearch,
  searchKey,
  setFieldsValue,
  loading,
  enabledLoadMore,
  onChangeGetSelectedItem,
  customOptions,
  ...props
}) => {
  const { source, children, className, disabled, format, curData } = props;

  const onSelectOption = useCallback(
    (inputValue, option) => {
      if (
        onChangeSearch(
          isObject(option.children)
            ? getRecordData(
                option.children.props && option.children.props.record,
                searchKey,
              )
            : option.children,
          inputValue,
        )
      ) {
        return option.value;
      }
      return null;
    },
    [searchKey],
  );

  const dataOption = useMemo(() => {
    const resources = curData ? [...resourceData, curData] : resourceData;
    return format ? format(resources) : resources;
  }, [resourceData, format, curData]);

  const optionWaypoint = useMemo(
    () => (
      <Option
        className="loading-select-option"
        disabled
        value="waypointTracking"
        key="waypoint"
      >
        <Waypoint onEnter={onEnter} />
      </Option>
    ),
    [onEnter],
  );

  const optionLoading = useMemo(
    () => (
      <Option
        className="loading-select-option"
        disabled
        value="loadingTracking"
        key="loading"
      >
        <div className="loading-select">
          <Spin />
        </div>
      </Option>
    ),
    [],
  );

  const handleChange = (value) => {
    if (!onChangeGetSelectedItem) return;
    const findItem = resourceData?.find(
      (item) => get(item, valueProp) === value,
    );

    onChangeGetSelectedItem(value, findItem);
  };

  return (
    <FormItem
      {...props}
      placeholder={i18n.t(placeholder)}
      header={i18n.t(header)}
      required={required}
      name={source}
      autoComplete="off"
    >
      <SelectWrapper
        {...selectProps}
        disabled={disabled}
        placeholder={i18n.t(placeholder)}
        filterOption={isFilterOption ? onSelectOption : false}
        showSearch={isShowSearch}
        allowClear={allowClear}
        className={className}
        {...(isShowSearch && {
          onSearch: (value) => onSearch(value),
        })}
        {...(allowClear && { onClear })}
        onChange={onChange || handleChange}
      >
        {map(dataOption, (data, index) =>
          children ? (
            <Option
              key={String(index)}
              value={valueProp ? getRecordData(data, valueProp) : data}
            >
              {React.cloneElement(children, {
                key: String(index),
                record: data,
                valueProp,
                titleProp,
              })}
            </Option>
          ) : (
            <Option
              key={String(index)}
              value={valueProp ? getRecordData(data, valueProp) : data}
            >
              {formatText(
                titleProp ? getRecordData(data, titleProp) : data,
                data,
              )}
            </Option>
          ),
        )}
        {customOptions && customOptions}
        {!isEmpty(dataOption) && enabledLoadMore && optionWaypoint}
        {loading && optionLoading}
      </SelectWrapper>
    </FormItem>
  );
};

FormSelect.propTypes = {
  allowClear: PropTypes.bool,
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  header: PropTypes.any,
  required: PropTypes.bool,
  requiredMessage: PropTypes.node,
  icon: PropTypes.string,
  placeholder: PropTypes.string,
  form: PropTypes.object,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  resourceData: PropTypes.any,
  valueProp: PropTypes.string,
  titleProp: PropTypes.string,
  children: PropTypes.node,
  rules: PropTypes.array,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  format: PropTypes.func,
  searchKey: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.bool,
  selectProps: PropTypes.object,
  formatText: PropTypes.func,
  record: PropTypes.object,
  onEnter: PropTypes.func,
  isFilterOption: PropTypes.bool,
  isShowSearch: PropTypes.bool,
  setFieldsValue: PropTypes.func,
  onClear: PropTypes.func,
  enabledLoadMore: PropTypes.bool,
  onChangeGetSelectedItem: PropTypes.func,
  curData: PropTypes.object,
  customOptions: PropTypes.node,
};

FormSelect.defaultProps = {
  required: false,
  requiredMessage: 'error.required',
  rules: [],
  placeholder: 'placeholder.undefined',
  onSearch: () => {},
  onClear: () => {},
  formatText: (data) => data,
  selectProps: {},
  allowClear: true,
  isFilterOption: true,
  isShowSearch: true,
};

export default FormSelect;
