// import crud action
import { clientUsersActions as clientUsers } from './clientUsers/actions';
import { businessUsersActions as businessUsers } from './businessUsers/actions';
import { tasksActions as tasks } from './tasks/actions';
import { activitiesActions as activities } from './activities/actions';
import { companiesActions as companies } from './companies/actions';
import { brandsActions as brands } from './brands/actions';
import { marketingsActions as marketings } from './marketings/actions';
import { applicationsActions as applications } from './applications/actions';
import { CRMPackagesActions as CRMPackages } from './CRMPackages/actions';
import { inboxesActions as inboxes } from './inboxes/actions';
import { changeEmailRequestsActions as changeEmailRequests } from './changeEmailRequests/actions';
import { claimCentreRequestsActions as claimCentreRequests } from './claimCentreRequests/actions';
import { disputeCentresActions as disputeCentreRequests } from './disputeCentres/actions';
import { centreServicesActions as centreServices } from './centreServices/actions';
import { serviceTypesActions as serviceTypes } from './serviceTypes/actions';
import { sessionTypesActions as sessionTypes } from './sessionTypes/actions';
import { featuresActions as features } from './features/actions';
import { NQSRTypesActions as NQSRTypes } from './NQSRTypes/actions';
import { appSettingsActions as appSettings } from './appSettings/actions';
import { centresActions as centres } from './centres/actions';
import { usersActions as users } from './users/actions';
import { notificationsActions as notifications } from './notifications/actions';
import { customersActions as customers } from './customers/actions';
import { LGAActions as LGA } from './LGA/actions';
import { regionsActions as regions } from './regions/actions';
import { articlesActions as articles } from './articles/actions';
import { suburbsActions as suburbs } from './suburbs/actions';
import { disputeReviewActions as disputeReviewRequests } from './disputeReviews/actions';
import { providersActions as providers } from './providers/actions';
import { superAdminUsersActions as superAdminUsers } from './superAdminUsers/actions';
import { childcareServiceTypesActions as childcareServiceTypes } from './childcareServiceType/actions';
import { serviceAgeGroupsActions as serviceAgeGroups } from './serviceAgeGroups/actions';
import { clickThroughTrackerActions as clickThroughTracker } from './clickThroughTracker/actions';

export default {
  // actions here
  clientUsers,
  businessUsers,
  tasks,
  activities,
  companies,
  brands,
  marketings,
  applications,
  CRMPackages,
  inboxes,
  changeEmailRequests,
  claimCentreRequests,
  disputeCentreRequests,
  customers,
  centreServices,
  serviceTypes,
  serviceAgeGroups,
  sessionTypes,
  features,
  NQSRTypes,
  appSettings,
  centres,
  users,
  notifications,
  LGA,
  regions,
  articles,
  suburbs,
  disputeReviewRequests,
  providers,
  superAdminUsers,
  childcareServiceTypes,
  clickThroughTracker,
};
