import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { makeBreadCrumbFromPath } from 'utils/tools';
import i18next from 'i18next';
import CustomBreadcrumb from '../../common/Breadcrumb';
import RestCreateForm from '../RestCreateForm';
import Layout from '../../common/Layout';
import Box from '../../common/Box';
import PageTitle from '../../common/PageTitle';

const RestCreateComponent = (props) => {
  const { showModal, header, noCardWrapper, breadcrumbCustom, isResetStyle } =
    props;
  const location = useLocation();
  const BREADCRUMB_LIST = makeBreadCrumbFromPath(location);

  const content = (
    <div className="content-create-wrapper" style={{ width: '100%' }}>
      <RestCreateForm {...props} />
    </div>
  );

  return showModal || noCardWrapper ? (
    content
  ) : (
    <Layout bordered={false}>
      <PageTitle>
        <CustomBreadcrumb data={breadcrumbCustom || BREADCRUMB_LIST} />
        {header && <div className="header-title">{i18next.t(header)}</div>}
      </PageTitle>
      <Box isResetStyle={isResetStyle}>{content}</Box>
    </Layout>
  );
};

RestCreateComponent.propTypes = {
  onBack: PropTypes.func,
  showModal: PropTypes.bool,
  header: PropTypes.any,
  location: PropTypes.object,
  noCardWrapper: PropTypes.bool,
  breadcrumbCustom: PropTypes.array,
  isResetStyle: PropTypes.bool,
};
RestCreateComponent.defaultProps = {
  noCardWrapper: false,
};
export default RestCreateComponent;
