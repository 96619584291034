import { PREFIX_URL } from 'configs/localData/constant';
import React from 'react';
import Edit from 'containers/rest/Edit';
import Form from '../components/Form';

const CompanyEdit = (props) => {
  const formatOnSubmit = ({ userId, ...data }) => ({
    ...data,
    userId: userId || null,
  });
  return (
    <Edit
      {...props}
      resource="companies"
      defaultOptions={{
        prefixUrl: PREFIX_URL.superAdmin,
      }}
      formatOnSubmit={formatOnSubmit}
    >
      <Form />
    </Edit>
  );
};

export default CompanyEdit;
