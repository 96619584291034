import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';

import { getSummariesCentresOfProvider } from 'redux/providers/actions';
import { getCentresOfProvider } from 'redux/centresOfProvider/actions';
import { resetResourceFilterCentresOfProviders } from 'redux/centresOfProvider/slice';
import { centresOfProviderSelectors } from 'redux/centresOfProvider/selectors';

import SummariesCentre from './SummariesCentre';
import HeaderFilter from './HeaderFilter';
import CentresListTable from './CentresListTable';
import CentresListBoard from './CentresListBoard';

const CentresTab = () => {
  const { push } = useHistory();

  const { id: providerId } = useParams();

  const query = useSelector((state) => state.router?.location?.query);

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(query?.t || 'board');

  const data = useSelector(centresOfProviderSelectors.getDataArr);

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, keepFilter, keepOrderBy }) => {
      dispatch(
        getCentresOfProvider({
          id: providerId,
          data: filter,
          options: {
            keepFilter,
            keepOrderBy,
            isRefresh,
            isOrderBy: false,
          },
        }),
      );
    },
    [providerId, dispatch],
  );

  const getSummaries = (filter) => {
    dispatch(
      getSummariesCentresOfProvider({
        id: providerId,
        data: filter,
      }),
    );
  };

  useEffect(() => {
    getSummaries({ filter: {} });
    return () => {
      dispatch(resetResourceFilterCentresOfProviders());
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    retrieveList({
      filter: {
        limit: 10,
        offset: 0,
      },
      keepFilter: true,
      isRefresh: true,
    });
  }, [activeTab, retrieveList]);

  const onChange = (e) => {
    setActiveTab(e.target.value);
    push(`/providers/${providerId}/show/centres?t=${e.target.value}`);
  };

  const summaries = useSelector(
    (state) => state.providers.centresSummary || {},
  );

  return (
    <div>
      <SummariesCentre summaries={summaries} />
      <HeaderFilter
        activeTab={activeTab}
        retrieveList={retrieveList}
        onChange={onChange}
      />
      <div className="container-wrapper-centre">
        {activeTab === 'list' ? (
          <CentresListTable retrieveList={retrieveList} resourceData={data} />
        ) : (
          <CentresListBoard retrieveList={retrieveList} resourceData={data} />
        )}
      </div>
    </div>
  );
};

export default CentresTab;
