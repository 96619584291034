import { Radio } from 'antd';
import styled from 'styled-components';

export const ApproveButton = styled(Radio.Button)`
  && {
    color: green;
    border-color: green;
    margin-right: 16px;
    border-radius: 10px;
  }
`;
export const DenyButton = styled(Radio.Button)`
  && {
    color: red;
    border-color: red;
    border-radius: 10px;
    border-width: 1px;
  }
`;
export const ButtonSwapper = styled.div`
  .ant-radio-button-wrapper:not(:first-child)::before {
    width: 0;
  }
`;
