import React from 'react';
import PropTypes from 'prop-types';
import { Calendar } from 'antd';
import HeaderCalender from './HeaderCalender';

const CalendarPicker = ({ value, onChange }) => (
  <Calendar
    headerRender={HeaderCalender}
    value={value}
    fullscreen={false}
    onChange={onChange}
  />
);

CalendarPicker.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default CalendarPicker;
