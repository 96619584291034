import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import i18next from 'i18next';
import { SeeMoreStyles } from './styles';

const SeeMore = ({ onClick }) => (
  <SeeMoreStyles>
    <Typography.Link onClick={onClick}>
      {i18next.t('common.seeMore')}
    </Typography.Link>
  </SeeMoreStyles>
);

SeeMore.propTypes = {
  onClick: PropTypes.func,
};

export default SeeMore;
