import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Switch } from 'antd';
import { useParams } from 'react-router';
import i18next from 'i18next';

import { validateRegex } from 'utils/validateUtils';
import RestInputItem from 'components/RestInput/RestInputItem';

import { FormStyles } from './styles';

const Form = ({ isEdit }) => {
  const { model } = useParams();

  return (
    <FormStyles>
      <Row gutter={16}>
        <Col lg={10} md={8} xs={12}>
          <RestInputItem
            required
            source="key"
            header="input.EDMKey.name"
            placeholder="input.EDMKey.placeholder"
            disabled={isEdit}
            rules={[
              {
                pattern: validateRegex.environmentVariable,
                message: i18next.t(
                  'input.environmentVariable.validateMsg.pattern',
                ),
              },
            ]}
          />
        </Col>
        <Col lg={10} md={8} xs={12}>
          <RestInputItem
            required
            source="subject"
            header="input.subject.name"
            placeholder="input.subject.placeholder"
          />
        </Col>
        <Col lg={4} md={8} xs={12}>
          <RestInputItem
            source="isSystem"
            header="common.isSystem"
            ContentComponent={Switch}
            defaultValue={model === 'system'}
            valuePropName="checked"
          />
        </Col>
      </Row>
    </FormStyles>
  );
};

Form.propTypes = {
  isEdit: PropTypes.bool,
};

export default Form;
