import React from 'react';
import PropTypes from 'prop-types';
import { getATagHref } from 'utils/tools';
import i18next from 'i18next';

const CustomiseButtonItem = ({ titleBtn, customItem }) => (
  <div className="customise-button-item">
    {customItem?.value ? (
      <a
        href={getATagHref(customItem.value)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {i18next.t(titleBtn)}
      </a>
    ) : (
      <span>{i18next.t(titleBtn)}</span>
    )}
  </div>
);

CustomiseButtonItem.propTypes = {
  customItem: PropTypes.object,
  titleBtn: PropTypes.string,
};

export default CustomiseButtonItem;
