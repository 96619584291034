/* eslint-disable react/prop-types */
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Col, Divider, Rate, Row } from 'antd';
import logoGoogle from 'assets/images/google-logo.png';
import logoKindi from 'assets/images/kindi-logo.png';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { formatDate } from 'utils/textUtils';
import { HeaderCommentStyles } from './styles';

const Reviews = ({ record }) => (
  <>
    <Row gutter={[0, 10]}>
      <Col md={4} sm={6} xs={24}>
        <strong>{i18next.t('disputeReviewRequests.disputeComment')}</strong>
      </Col>
      <Col md={2} sm={3} xs={6}>
        {record?.avatar?.url ? (
          <Avatar src={record?.avatar?.url} style={{ width: 60, height: 60 }} />
        ) : (
          <Avatar icon={<UserOutlined />} size={60} />
        )}
      </Col>
      <Col md={18} sm={15} xs={18}>
        <HeaderCommentStyles className="header-comment-item">
          <div className="title-header">
            <div className="name-rating">
              <span className="name-user">{record?.authorName}</span>
              <Rate disabled allowHalf value={record?.rating} />
            </div>
            <img
              className="image-logo"
              src={record?.isInternalRating ? logoKindi : logoGoogle}
              alt=""
            />
          </div>
          <div className="time-comment">
            {formatDate(record?.createdAt, 'MMM D, YYYY')}
          </div>
          <div>{record?.comment}</div>
        </HeaderCommentStyles>
      </Col>
    </Row>
    <Row className="mt-24" gutter={[0, 10]}>
      <Col md={4} sm={6} xs={24}>
        <strong>{i18next.t('disputeReviewRequests.reasonForDispute')}</strong>
      </Col>
      <Col md={20} sm={18} xs={24}>
        <HeaderCommentStyles>{record?.disputation?.reason}</HeaderCommentStyles>
      </Col>
    </Row>
    <Row className="mt-24" gutter={[0, 10]}>
      <Col md={4} sm={6} xs={24}>
        <strong>{i18next.t('disputeReviewRequests.comment')}</strong>
      </Col>
      <Col md={20} sm={18} xs={24}>
        <HeaderCommentStyles>
          {record?.disputation?.comment}
        </HeaderCommentStyles>
      </Col>
    </Row>
    <Divider />
  </>
);
Reviews.propsType = {
  record: PropTypes.object,
};
export default Reviews;
