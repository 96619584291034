import i18next from 'i18next';
import React from 'react';
import CompanyListSelect from './CompanyListSelect';

const CompanyForm = () => (
  <div>
    <div className="title-session">{i18next.t('users.selectCompanies')}</div>
    <CompanyListSelect />
  </div>
);

export default CompanyForm;
