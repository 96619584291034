import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import RestInputItem from 'components/RestInput/RestInputItem';
import { Input, InputNumber, Switch } from 'antd';
import { validateRegex } from 'utils/validateUtils';
import i18next from 'i18next';
import RestEditor from 'components/RestInput/RestEditor';
import RestSelect from 'components/RestInput/RestSelect';
import {
  APP_SETTING_TYPES,
  APP_SETTING_TYPES_CONSTANT,
} from 'configs/localData';
import { RestInputContext } from 'components/RestInput/RestInputContext';

const AppSettingsForm = ({ isEdit, form, ...props }) => {
  const { record } = useContext(RestInputContext);
  const [type, setType] = useState(
    record?.type || APP_SETTING_TYPES_CONSTANT.text,
  );

  const valueComponent = useMemo(() => {
    switch (type) {
      case APP_SETTING_TYPES_CONSTANT.html:
        return (
          <RestEditor required source="value" header="appSettings.value" />
        );

      case APP_SETTING_TYPES_CONSTANT.number:
        return (
          <RestInputItem
            required
            source="value"
            header="appSettings.value"
            placeholder="appSettings.value"
            ruleType="number"
            ContentComponent={InputNumber}
          />
        );
      default:
        return (
          <RestInputItem
            required
            source="value"
            header="appSettings.value"
            placeholder="appSettings.value"
            ContentComponent={Input.TextArea}
            rows={8}
          />
        );
    }
  }, [type]);

  const onChangeType = (value) => {
    form.setFieldsValue({ value: record?.value });
    setType(value);
  };

  return (
    <div {...props}>
      <RestInputItem
        required
        source="key"
        header="appSettings.key"
        placeholder="appSettings.key"
        rules={[
          {
            pattern: validateRegex.environmentVariable,
            message: i18next.t('input.environmentVariable.validateMsg.pattern'),
          },
        ]}
      />
      <RestSelect
        resourceData={APP_SETTING_TYPES}
        required
        header="appSettings.type"
        source="type"
        valueProp="value"
        titleProp="text"
        formatText={(text) => i18next.t(text)}
        onChange={onChangeType}
      />
      {valueComponent}
      {isEdit && (
        <RestInputItem
          source="isActive"
          header="appSettings.isActive"
          placeholder="appSettings.isActive"
          ContentComponent={Switch}
          valuePropName="checked"
          ruleType="boolean"
        />
      )}
    </div>
  );
};

AppSettingsForm.propTypes = {
  isEdit: PropTypes.bool,
  form: PropTypes.object,
};

export default AppSettingsForm;
