import React from 'react';
import CentreInfoForm from './CentreInfoForm';
import CentreLocationForm from './CentreLocationForm';
import CentreContactForm from './CentreContactForm';
import FormStyles from './styles';

const CentresForm = () => (
  <FormStyles>
    <CentreInfoForm />

    <CentreLocationForm />

    <CentreContactForm />
  </FormStyles>
);

export default CentresForm;
