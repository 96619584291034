import { makeActions } from 'redux/crudCreator/actions';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getByKeyAppSettingsOfCentreApi,
  updateAppSettingsOfCentreApi,
  updateLabelTableApi,
} from 'api/appSettings';
import { apiWrapper } from 'utils/reduxUtils';
import { updateConfig } from 'redux/config/slice';

export const MODEL_NAME = 'appSettings';
export const appSettingsActions = makeActions(MODEL_NAME);
export const getAllAppSettings = appSettingsActions.getAll;
export const editAppSettings = appSettingsActions.edit;
export const createAppSettings = appSettingsActions.create;
export const getByIdAppSettings = appSettingsActions.getDataById;

export const getByKeyAppSettingsOfCentre = createAsyncThunk(
  'appSettings/getByKeyAppSettingsOfCentre',
  async (payload, thunkApi) => {
    try {
      const response = await apiWrapper(
        {},
        getByKeyAppSettingsOfCentreApi,
        payload.centreId,
        payload.key,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateAppSettingsOfCentre = createAsyncThunk(
  'appSettings/updateAppSettingsOfCentre',
  async (payload, thunkApi) => {
    try {
      const response = await apiWrapper(
        {},
        updateAppSettingsOfCentreApi,
        payload.centreId,
        payload.key,
        payload.data,
      );
      return { data: response, success: true };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateLabelTable = createAsyncThunk(
  'appSettings/updateLabelTable',
  async (payload = {}, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        updateLabelTableApi,
        payload.key,
        payload.data,
      );
      thunkAPI.dispatch(updateConfig(response));
      return { data: response, success: true };
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  },
);
