import React, { useEffect } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { isEmpty } from 'lodash-es';
import Skeleton from 'antd/lib/skeleton';
import {
  clearCurrentDataTemplate,
  getByKeyEmailTemplate,
} from 'redux/EDM/actions';
import Breadcrumb from 'components/common/Breadcrumb';
import PageTitle from 'components/common/PageTitle';
import { DesignStyles } from './styles';
import EditContent from './EditContent';

const Edit = () => {
  const dispatch = useDispatch();

  const { key, model } = useParams();

  const BREADCRUMB = [
    {
      path: `/EDM/${model}`,
      title: i18next.t('marketings.title'),
    },
    {
      title: i18next.t('marketings.editDesignEDM'),
    },
  ];

  const currentData = useSelector((state) => state.EDM.currentData);

  useEffect(() => {
    key && dispatch(getByKeyEmailTemplate(key));
    return () => {
      dispatch(clearCurrentDataTemplate());
    };
  }, []); // eslint-disable-line

  return (
    <DesignStyles>
      <Breadcrumb data={BREADCRUMB} />
      <PageTitle>{i18next.t('marketings.editDesignEDM')}</PageTitle>
      <Skeleton loading={isEmpty(currentData)} active>
        <EditContent currentData={currentData} />
      </Skeleton>
    </DesignStyles>
  );
};

export default Edit;
