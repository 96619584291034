import { flatten, union } from 'lodash-es';
import { apiWrapper } from 'utils/reduxUtils';
import { getAllApi } from 'api/crud';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  convertRequestParams,
  convertResponseData,
  PRIMARY_KEY,
} from '../crudCreator/dataProvider';

const debouncedIds = {};
const mappeds = {};
const tasks = {};

const addIds = (resource, ids) => {
  if (!debouncedIds[resource]) {
    debouncedIds[resource] = [];
  }
  debouncedIds[resource] = flatten(union(debouncedIds[resource], ids));
};

const addMappedBy = (resource, filterKey) => {
  if (!mappeds[resource]) {
    mappeds[resource] = [];
  }
  mappeds[resource] = filterKey;
};

function finalize(resource, options, dispatch) {
  dispatch(retrieveReferenceList({ resource, options }));
  delete tasks[resource];
  delete debouncedIds[resource];
  delete mappeds[resource];
}

export const retrieveReferenceInputData = createAsyncThunk(
  'reference/retrieveReferenceInputData',
  async ({ resource, data, options = {} }, thunkAPI) => {
    try {
      const { filter, outsideFilter, limit, offset } =
        thunkAPI.getState().reference?.[resource];
      const {
        filter: filterData,
        outsideFilter: outsideFilterData,
        ...restData
      } = data;

      const params = {
        limit: limit || 10,
        offset: offset || 0,
        ...outsideFilter,
        ...restData,
        ...outsideFilterData,
        filter: {
          ...filter,
          ...filterData,
        },
      };
      const convertRequest = convertRequestParams('GET_ALL', params);
      const response = await apiWrapper(
        { isShowProgress: false },
        getAllApi,
        options.customApiResource || resource,
        convertRequest,
        options.prefixUrl,
      );
      const result = convertResponseData('GET_ALL', response, options);
      return {
        resource,
        referenceData: {
          ...result,
          numberOfPages: Math.ceil(result.total / (limit || 10)),
        },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ resource, error });
    }
  },
);

export const retrieveReferenceList = createAsyncThunk(
  'reference/retrieveReferenceList',
  async ({ resource, options = {} }, thunkAPI) => {
    try {
      const params = {
        limit: 100,
        offset: 0,
        filter: JSON.stringify({
          [mappeds[resource] || PRIMARY_KEY]: {
            $in: debouncedIds[resource],
          },
        }),
      };
      const response = await apiWrapper(
        { isShowProgress: false },
        getAllApi,
        options.customApiResource || resource,
        params,
        options?.prefixUrl,
      );
      const result = convertResponseData('GET_ALL', response, options);
      return { resource, data: result };
    } catch (error) {
      return thunkAPI.rejectWithValue({ resource, error });
    }
  },
);

export const retrieveReference = createAsyncThunk(
  'reference/retrieveReference',
  // eslint-disable-next-line
  async (
    { resource, ids, mappedBy, filterKey, customApiResource, prefixUrl },
    thunkAPI,
  ) => {
    try {
      if (tasks[resource]) {
        clearTimeout(tasks[resource]);
        tasks[resource] = null;
      }

      addIds(resource, ids);
      addMappedBy(resource, filterKey);

      tasks[resource] = setTimeout(() => {
        finalize(
          resource,
          { primaryKey: mappedBy, customApiResource, prefixUrl },
          thunkAPI.dispatch,
        );
      }, 50);

      return null;
    } catch (error) {
      return thunkAPI.rejectWithValue({ resource, error });
    }
  },
);

export const clearData = createAction('clearData');
