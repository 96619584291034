import {
  PREFIX_API_VER_2,
  PREFIX_URL,
  PREFIX_URL_API,
} from 'configs/localData/constant';
import { get, put, post, del } from './utils';

export const getRatingsCentreApi = (id, data) =>
  get(`/centres/${id}/ratings`, data, {}, PREFIX_URL_API);

export const getSummaryDetailCentreApi = (id) =>
  get(`/centres/${id}/summary`, null, {}, PREFIX_URL_API);

export const changeSubscriptionCentreApi = (id, data) =>
  put(`/centres/${id}/subscription`, data, {}, PREFIX_URL_API);

export const getAppSettingsCentreApi = (id) =>
  get(`/centres/${id}/app-settings`, null, {}, PREFIX_URL_API);

export const editAppSettingsByKeyCentreApi = (id, key, data) =>
  put(`/centres/${id}/app-settings/${key}`, data, {}, PREFIX_URL_API);

export const getTotalCentresWithQueryApi = (data) =>
  get('/centres', data, {}, PREFIX_URL_API);

export const syncGoogleReviewOfCentreApi = (id) =>
  post(`/centres/${id}/google-reviews`, null, {}, PREFIX_URL_API);

export const changeFinishedStepApi = (id, data) =>
  put(`/centres/${id}/finishedStep`, data, {}, PREFIX_URL.superAdmin);

export const delAllGoogleReviewApi = (id) =>
  del(`/centres/${id}/google-reviews`, null, {}, PREFIX_URL.superAdmin);

export const getAllSocialMediaChannelsApi = (data) =>
  get(
    `/centres/details/cta-buttons`,
    data,
    {},
    PREFIX_URL.superAdmin,
    PREFIX_API_VER_2,
  );

export const editSocialMediaChannelsApi = (id, data) =>
  put(
    `/centres/details/cta-buttons?centreId=${id}`,
    data,
    {},
    PREFIX_URL.superAdmin,
    PREFIX_API_VER_2,
  );

export const getKindiCareRatingSummaryApi = (id, data) =>
  get(
    `/centres/details/rating-summary?centreId=${id}`,
    data,
    {},
    PREFIX_URL.superAdmin,
    PREFIX_API_VER_2,
  );

export const editCentreInformationApi = (id, data) =>
  put(
    `/centres/details/basic-info?centreId=${id}`,
    data,
    {},
    PREFIX_URL_API,
    PREFIX_API_VER_2,
  );
