import { get } from 'api/utils';
import { PREFIX_API_VER_2, PREFIX_URL } from 'configs/localData/constant';

export const getAllJobApplicationsTrackerApi = (params) =>
  get(
    `/job-seekers/posts/applications`,
    params,
    {},
    PREFIX_URL.superAdmin,
    PREFIX_API_VER_2,
  );

export const getJobApplicationsTrackerConfigApi = (params) =>
  get(`/config`, null, params, PREFIX_URL.superAdmin, PREFIX_API_VER_2);
