import React from 'react';
import { Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { PREFIX_URL } from 'configs/localData/constant';

import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import ReferenceInputWrapper from 'containers/rest/ReferenceInput';

const ProviderFilterForm = () => (
  <Row gutter={16} type="flex">
    <Col md={8} xs={8}>
      <ReferenceInputWrapper
        prefixUrl={PREFIX_URL.superAdmin}
        resource="companies"
        source="companyId"
        searchKey="name"
        initialFilter={{ orderBy: 'name' }}
      >
        <RestSelect
          titleProp="name"
          valueProp="id"
          placeholder="centres.companyTitle"
          isFilterOption={false}
        />
      </ReferenceInputWrapper>
    </Col>
    <Col md={8} xs={8}>
      <RestInputItem
        source={['name', '$like']}
        placeholder="input.searchProvider"
        isShowTooltip
        inputProps={{ prefix: <SearchOutlined /> }}
      />
    </Col>
    <Col md={8} xs={8}>
      <RestInputItem
        source={['governmentReferenceId', '$like']}
        placeholder="input.PRCode"
        isShowTooltip
        inputProps={{ prefix: <SearchOutlined /> }}
      />
    </Col>
  </Row>
);

export default ProviderFilterForm;
