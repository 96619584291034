import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import { Carousel, Modal } from 'antd';
import PropTypes from 'prop-types';
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import { getYoutubeEmbedURL } from 'utils/tools';
import { ModalContentStyles } from '../GridPhotos/styles';

const ModalPreview = forwardRef(({ images, visible, handleCancel }, ref) => {
  const carouselRef = useRef(null);

  useImperativeHandle(ref, () => ({
    goToImage: (index) => {
      carouselRef.current.goTo(index);
    },
  }));

  const getImage = (item) => {
    if (item?.type?.includes('video')) {
      const urlVideo = getYoutubeEmbedURL(item?.url);
      return (
        <iframe
          title="iframe-video-item"
          className="iframe-video"
          frameBorder="0"
          allowFullScreen="allowfullscreen"
          src={urlVideo}
          width={600}
          height={400}
          style={{ maxWidth: '100%' }}
        />
      );
    }
    return <img alt="kindi" style={{ width: '100%' }} src={item?.url} />;
  };

  return (
    <Modal
      style={{ top: 80 }}
      visible={visible}
      footer={null}
      onCancel={handleCancel}
      width={800}
      destroyOnClose
    >
      <ModalContentStyles>
        {images?.length > 1 && (
          <LeftCircleFilled
            className="left-arrow"
            onClick={() => carouselRef.current.prev()}
          />
        )}
        {images?.length > 1 && (
          <RightCircleFilled
            className="right-arrow"
            onClick={() => carouselRef.current.next()}
          />
        )}
        <Carousel draggable ref={carouselRef}>
          {images &&
            images.map((item, index) => (
              <div key={String(index)}>{getImage(item)}</div>
            ))}
        </Carousel>
      </ModalContentStyles>
    </Modal>
  );
});

ModalPreview.propTypes = {
  images: PropTypes.array,
  visible: PropTypes.bool,
  handleCancel: PropTypes.func,
};

ModalPreview.defaultProps = {
  images: [],
  handleCancel: () => null,
};

export default ModalPreview;
