import { createSelector } from 'reselect';
import { CRUDSelectors } from '../crudCreator/selectors';
import { MODEL_NAME } from './actions';

const getResourceDataMessages = (state) => state.inboxes.messages?.resourceData;

const getMessages = (state) => state.inboxes.messages?.data;

const getMessagesLoading = (state) => state.inboxes.messagesLoading;

const getResourceDataInboxesOfCentre = (state) =>
  state.inboxes.inboxesOfCentre?.resourceData;

const getInboxesOfCentre = (state) => state.inboxes.inboxesOfCentre?.data;

const getInboxesOfCentreLoading = (state) =>
  state.inboxes.inboxesOfCentreLoading;

const getSummariesInboxesOfCentre = (state) =>
  state.inboxes.inboxesOfCentre?.summary;

export const getResourceDataInboxesOfCentreSelector = createSelector(
  [getResourceDataInboxesOfCentre],
  (data) => data || {},
);

export const getInboxesOfCentreSelector = createSelector(
  [getInboxesOfCentre],
  (data) => data || [],
);

export const getInboxesOfCentreLoadingSelector = createSelector(
  [getInboxesOfCentreLoading],
  (data) => data,
);

export const enabledLoadMoreInboxesSelector = createSelector(
  [getInboxesOfCentreLoading, getResourceDataInboxesOfCentre],
  (loading, resources) => {
    const { offset, limit, numberOfPages } = resources || {};
    return !loading && offset / limit + 1 < numberOfPages;
  },
);

export const getInboxesSummarySelector = createSelector(
  [getSummariesInboxesOfCentre],
  (summary) => summary || {},
);

export const getResourceDataMessagesSelector = createSelector(
  [getResourceDataMessages],
  (data) => data || {},
);

export const getMessagesSelector = createSelector(
  [getMessages],
  (data) => data || [],
);

export const getMessagesLoadingSelector = createSelector(
  [getMessagesLoading],
  (data) => data,
);

export const enabledLoadMoreMessagesSelector = createSelector(
  [getMessagesLoading, getResourceDataMessages],
  (loading, resources) => {
    const { offset, limit, numberOfPages } = resources || {};
    return !loading && offset / limit + 1 < numberOfPages;
  },
);

export const inboxesSelectors = new CRUDSelectors(MODEL_NAME);
