import React from 'react';
import PropTypes from 'prop-types';

import NormalHour from './NormalHour';
import SchoolTerm from './SchoolTerm';
import HolidayHour from './HolidayHour';
import HoursTabStyles from './styles';

const HoursTab = ({ record }) => (
  <HoursTabStyles>
    <NormalHour openTime={record.openTime} />
    <SchoolTerm openTime={record.openTime} />
    <HolidayHour openTime={record.openTime} />
  </HoursTabStyles>
);
HoursTab.propTypes = {
  record: PropTypes.object,
};

HoursTab.defaultProps = {
  record: {},
};

export default HoursTab;
