import React from 'react';
import PropTypes from 'prop-types';
import { Input, Switch } from 'antd';
import i18next from 'i18next';
import { useParams } from 'react-router';

import { validateRegex } from 'utils/validateUtils';
import RestInputItem from 'components/RestInput/RestInputItem';

const Form = ({ isEdit }) => {
  const { model } = useParams();

  return (
    <div>
      <RestInputItem
        required
        source="key"
        header="input.EDMKey.name"
        placeholder="input.EDMKey.placeholder"
        disabled={isEdit}
        rules={[
          {
            pattern: validateRegex.environmentVariable,
            message: i18next.t('input.environmentVariable.validateMsg.pattern'),
          },
        ]}
      />
      <RestInputItem
        required
        source="subject"
        header="input.subject.name"
        placeholder="input.subject.placeholder"
      />
      <RestInputItem
        source="isSystem"
        header="common.isSystem"
        ContentComponent={Switch}
        defaultValue={model === 'system'}
        valuePropName="checked"
      />
      <RestInputItem
        required
        source="value"
        header="input.html.name"
        placeholder="input.html.placeholder"
        ContentComponent={Input.TextArea}
        autoSize={{ minRows: 10, maxRows: 30 }}
      />
    </div>
  );
};

Form.propTypes = {
  isEdit: PropTypes.bool,
};

export default Form;
