import { PREFIX_API_VER_2, PREFIX_URL } from 'configs/localData/constant';
import { get, put } from './utils';

export const getAllClickThroughTrackerApi = (data) =>
  get(`/click-throughs`, data, {}, PREFIX_URL.superAdmin, PREFIX_API_VER_2);

export const editClickThroughTrackerApi = (id, data) =>
  put(
    `/click-throughs?id=${id}`,
    data,
    {},
    PREFIX_URL.superAdmin,
    PREFIX_API_VER_2,
  );
