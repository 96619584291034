import React from 'react';
import Create from 'containers/rest/Create';
import moment from 'moment';
import { PREFIX_URL_API } from 'configs/localData/constant';
import Form from '../components/Form';

const CentresCreate = (props) => {
  const formatOnSubmit = ({ dateApplied, displayAddress, ...data }) => ({
    ...data,
    dateApplied: dateApplied
      ? moment(dateApplied).format('DD-MM-YYYY')
      : dateApplied,
  });

  return (
    <Create
      {...props}
      resource="centres"
      defaultOptions={{
        isTrimStr: true,
        prefixUrl: PREFIX_URL_API,
      }}
      formatOnSubmit={formatOnSubmit}
      okText="button.createCentre"
    >
      <Form />
    </Create>
  );
};

export default CentresCreate;
