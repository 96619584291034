import styled from 'styled-components';

const SubscriptionDropdownStyles = styled.div`
  .ant-select {
    width: 100%;
  }
  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
    color: ${({ theme }) => theme.subscription.tagColor} !important;
    background: ${({ theme }) => theme.subscription.tagBg} !important;
    border-radius: 8px !important;
    font-size: 16px;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    color: ${({ theme }) => theme.subscription.tagColor} !important;
  }
`;

export default SubscriptionDropdownStyles;
