import { makeActions } from 'redux/crudCreator/actions';

export const MODEL_NAME = 'brands';
export const brandsActions = makeActions(MODEL_NAME);

export const getAllBrands = brandsActions.getAll;
export const editBrands = brandsActions.edit;
export const createBrands = brandsActions.create;
export const getByIdBrands = brandsActions.getDataById;
export const delBrands = brandsActions.del;
