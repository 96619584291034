import React, { useContext, useState } from 'react';
import i18next from 'i18next';
import { Typography, Button, Form } from 'antd';
import { randomPassword } from 'utils/tools';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { GeneratePasswordStyles } from './styles';

const { Paragraph } = Typography;

const GeneratePassword = () => {
  const { form } = useContext(RestInputContext);
  const [password, setPassword] = useState();

  const handleGeneratePassword = () => {
    const value = randomPassword();
    setPassword(value);
    form.setFieldsValue({ password: value });
  };

  return (
    <GeneratePasswordStyles className="generate-password">
      <Form.Item
        className="generate-password-input"
        name="password"
        label={i18next.t('input.password.label')}
      >
        <Paragraph copyable={!!password}>{password}</Paragraph>
      </Form.Item>
      <Button
        className="generate-button"
        type="primary"
        onClick={handleGeneratePassword}
      >
        {i18next.t('button.generate')}
      </Button>
    </GeneratePasswordStyles>
  );
};

export default GeneratePassword;
