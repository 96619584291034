import { pick } from 'lodash-es';
import {
  deleteNodeFromTree,
  insertNodeIntoTree,
  updateNodeInTree,
} from './treeUtils';

export const formatTreeDataCategories = (data, parent) =>
  data?.map((item) => {
    const countSub = item.subCategories?.length;
    return {
      id: item.id,
      key: item.id,
      parentId: item.parentId,
      parent,
      title: item.name,
      name: item.name,
      value: item.id,
      children: countSub
        ? formatTreeDataCategories(
            item.subCategories,
            pick(item, ['id', 'name']),
          )
        : undefined,
      isLeaf: !item.parentId || countSub ? undefined : true,
    };
  });

export const formatTreeDataWhenAddNewCategory = (item, data, parent) => {
  const restTreeNodeData = { children: data };

  insertNodeIntoTree(restTreeNodeData, { ...item, parent });

  return restTreeNodeData?.children;
};

export const formatTreeDataWhenEditCategory = (item, data) => {
  const restTreeNodeData = { children: data };

  updateNodeInTree(restTreeNodeData, item);

  return restTreeNodeData?.children;
};

export const formatTreeDataWhenDelCategory = (id, data) => {
  const restTreeNodeData = { children: data };

  deleteNodeFromTree(restTreeNodeData, id);

  return restTreeNodeData?.children;
};
