import React from 'react';
import { Switch } from 'antd';
import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import { LABEL_TABLE_KEY } from 'configs/localData/constant';
import { formatLabelTable } from 'utils/textUtils';
import { useSelector } from 'react-redux';

const SessionTypesList = (props) => {
  const appSettings = useSelector((state) => state.config?.data?.AppSetting);

  return (
    <List
      {...props}
      header=""
      hasCreate={false}
      resource="sessionTypes"
      widthTable="1000px"
      hasSearch={false}
      defaultOptions={{
        prefixUrl: 'super-admin',
        customApiResource: 'session-types',
        isGetConfig: true,
      }}
    >
      <RestFieldItem
        source="name"
        header={formatLabelTable(LABEL_TABLE_KEY.sessionType, appSettings)}
      />
      <RestFieldItem
        source="isActive"
        header="sessionTypes.isActive"
        valueProp="checked"
        component={<Switch />}
      />
      <ActionGroup widthAction={100}>
        <EditButton />
      </ActionGroup>
    </List>
  );
};

export default SessionTypesList;
