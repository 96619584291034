import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProvidersOfCompanyApi } from 'api/companies';
import { convertRequestParams } from 'redux/crudCreator/dataProvider';
import { apiWrapper } from 'utils/reduxUtils';

export const getProvidersOfCompany = createAsyncThunk(
  'providersOfCompany/getProvidersOfCompany',
  async (payload = {}, thunkApi) => {
    try {
      const { limit, offset, filter, includes, orderBy } =
        thunkApi.getState().providersOfCompany?.resourceData || {};

      const { outsideFilter: outsideFilterPayload, ...data } = payload.data;

      const convertRequest = convertRequestParams(
        'GET_ALL',
        {
          limit,
          offset,
          filter,
          includes,
          orderBy,
          ...data,
          ...outsideFilterPayload,
        },
        'providersOfCompany',
        payload.options,
      );

      const response = await apiWrapper(
        {},
        getProvidersOfCompanyApi,
        payload.id,
        convertRequest,
      );

      return {
        data: {
          numberOfPages: Math.ceil(response.total / limit),
          ...response,
        },
        options: payload.options,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ id: payload.data.id, data: error });
    }
  },
);
