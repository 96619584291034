import React from 'react';
import List from 'containers/rest/List';
import { Switch } from 'antd';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import { PREFIX_URL, LABEL_TABLE_KEY } from 'configs/localData/constant';

import { formatLabelTable } from 'utils/textUtils';
import { useDispatch, useSelector } from 'react-redux';
import { editServiceAgeGroups } from 'redux/serviceAgeGroups/actions';

const ServiceAgeGroupsList = (props) => {
  const dispatch = useDispatch();
  const appSettings = useSelector((state) => state.config?.data?.AppSetting);

  const labelAgeGroup = formatLabelTable(LABEL_TABLE_KEY.ageGroup, appSettings);

  const handleToggleIsActive = (isActive, record) => {
    dispatch(
      editServiceAgeGroups({
        data: {
          id: record?.id,
          name: record?.name,
          type: record?.type,
          isActive,
        },
        options: {
          isCheckError: false,
          isBack: false,
          isGetConfig: true,
          prefixUrl: PREFIX_URL.superAdmin,
          isChangeToEdit: true,
        },
      }),
    );
  };

  return (
    <List
      {...props}
      resource="serviceAgeGroups"
      widthTable="1000px"
      header={null}
      hasCreate={false}
      hasSearch={false}
      defaultOptions={{
        isGetConfig: true,
        prefixUrl: PREFIX_URL.superAdmin,
      }}
    >
      <RestFieldItem source="name" header={labelAgeGroup} />
      <RestFieldItem
        source="isActive"
        header="serviceAgeGroups.isActive"
        valueProp="checked"
        component={<Switch />}
        customOnChange={handleToggleIsActive}
      />
      <ActionGroup widthAction={100}>
        <EditButton />
      </ActionGroup>
    </List>
  );
};

export default ServiceAgeGroupsList;
