import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import {
  DeleteOutlined,
  EyeOutlined,
  LoadingOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

const UploadImageItem = ({
  defaultSourceKey,
  onSetDefault,
  onMouseEnter,
  onMouseLeave,
  item,
  onPreviewUI,
  deleteImage,
  isDefault,
  isSetFeature,
  onSetFeature,
  index,
  isFeature,
}) => (
  <div
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    className="uploadImage"
  >
    {item.status === 'error' && (
      <ExclamationCircleOutlined className="loading error" />
    )}
    {item.status !== 'error' && item.status !== 'done' && !item.url && (
      <LoadingOutlined className="loading" />
    )}
    <img className="image" src={item.url || item?.response} alt="" />
    <div className="overlay">
      <EyeOutlined
        onClick={() => onPreviewUI({ ...item, url: item.url || item.response })}
      />
      <DeleteOutlined onClick={() => deleteImage(item, isSetFeature)} />
    </div>
    {defaultSourceKey ? (
      <div
        role="presentation"
        onClick={() => onSetDefault(item)}
        className={`lbSetDefault  ${isDefault ? 'active' : ''}`}
      >
        {i18next.t('button.setDefault')}
      </div>
    ) : null}
    {isSetFeature ? (
      <div
        role="presentation"
        onClick={() => onSetFeature(item, index)}
        className={`lbSetDefault  ${isFeature ? 'active' : ''}`}
      >
        {i18next.t('button.setFeature')}
      </div>
    ) : null}
  </div>
);
UploadImageItem.propTypes = {
  item: PropTypes.object,
  onPreviewUI: PropTypes.func,
  deleteImage: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onSetDefault: PropTypes.func,
  defaultSourceKey: PropTypes.string,
  isDefault: PropTypes.bool,
  isSetFeature: PropTypes.bool,
  onSetFeature: PropTypes.func,
  index: PropTypes.number,
  isFeature: PropTypes.bool,
};

export default UploadImageItem;
