import React from 'react';
import Create from 'containers/rest/Create';
import Form from '../components/Form';

const AppSettingsCreate = (props) => (
  <Create
    {...props}
    resource="appSettings"
    defaultOptions={{ isTrimStr: true }}
  >
    <Form />
  </Create>
);

export default AppSettingsCreate;
