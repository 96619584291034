import React from 'react';
import List from 'containers/rest/List';
import { PREFIX_URL_API } from 'configs/localData/constant';
import { formatKindiCareFee } from 'utils/formatFieldUtils';

import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import DeleteButton from 'components/RestActions/DeleteButton';
import Filter from '../components/Filter';

const MarketingsList = (props) => (
  <List
    {...props}
    resource="marketings"
    filter={<Filter />}
    hasSearch={false}
    defaultOptions={{ prefixUrl: PREFIX_URL_API }}
    widthTable="1000px"
  >
    <RestFieldItem source="name" header="common.name" />

    <RestFieldItem source="type" header="common.type" />

    <RestFieldItem
      source="amount"
      header="common.KindiCareFee"
      format={formatKindiCareFee}
    />

    <RestFieldItem source="stripePriceId" header="marketings.stripePriceId" />

    <ActionGroup widthAction={120}>
      <EditButton />
      <DeleteButton />
    </ActionGroup>
  </List>
);

export default MarketingsList;
