import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  button {
    border: 0px !important;
    color: ${({ theme }) => theme.text.primary} !important;
    height: 32px !important;
    width: 32px;
    padding: 0 !important;
    background: transparent !important;
    box-shadow: none !important;
    &:hover {
      background: transparent;
      transform: scale(1.1, 1.1);
      color: ${({ theme }) => theme.palette.primary} !important;
    }
    &:focus {
      background: transparent;
      transform: scale(1.1, 1.1);
      color: ${({ theme }) => theme.palette.primary} !important;
    }
    .anticon {
      font-size: 20px;
    }
    &[disabled] > i {
      color: ${({ theme }) => theme.background.disabled};
    }
  }
`;
