import React from 'react';
import { Row, Col } from 'antd';
import i18next from 'i18next';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import { GENDER } from 'configs/localData';

const Filter = (props) => (
  <Row {...props} gutter={16}>
    <Col xl={4} lg={4} md={12} xs={24}>
      <RestInputItem
        source={['name', '$like']}
        placeholder="customers.name"
        isShowTooltip
      />
    </Col>
    <Col xl={5} lg={4} md={12} xs={24}>
      <RestInputItem
        source="email"
        placeholder="customers.email"
        isShowTooltip
      />
    </Col>
    <Col xl={5} lg={4} md={12} xs={24}>
      <RestSelect
        source="gender"
        placeholder="customers.gender"
        resourceData={GENDER}
        valueProp="value"
        titleProp="text"
        formatText={(data) => i18next.t(data)}
        isShowTooltip
        isShowSearch={false}
      />
    </Col>
    <Col xl={5} lg={4} md={12} xs={24}>
      <RestInputItem
        source={['phone', '$like']}
        placeholder="customers.phone"
        isShowTooltip
      />
    </Col>
    <Col xl={5} lg={4} md={12} xs={24}>
      <RestInputItem
        source={['address', '$like']}
        placeholder="customers.address"
        isShowTooltip
      />
    </Col>
  </Row>
);

export default Filter;
