import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useSelector } from 'react-redux';

import TableCustom from 'components/common/TableCustom';
import UserInfo from 'components/RestField/UserInfo';
import { CRUDSelectorsCustom } from 'redux/crudCreatorCustom/selectors';
import CentresField from 'components/CentresViewAll/CentresField';
import CentresModal from 'components/CentresViewAll/CentresModal';
import { getCentresOfBrand } from 'redux/centresOfBrand/actions';
import GroupActionsBrand from './GroupActionsBrand';

const formatBrandName = (data, record) => (
  <UserInfo record={record} isLink={false} avatarProp="logo" nameProp="name" />
);

const BrandsTable = ({ retrieveList, resource }) => {
  const centresBrandModalRef = useRef();

  const brandsSelectorsCustom = new CRUDSelectorsCustom(resource);
  const loading = useSelector(brandsSelectorsCustom.getLoading);
  const resourceFilter =
    useSelector(brandsSelectorsCustom.getResourceData) || {};
  const data = useSelector(brandsSelectorsCustom.getDataArr);

  const openCentresUserModal = useCallback((record) => {
    centresBrandModalRef.current.open(record);
  }, []);

  const formatCentreName = useCallback(
    (data, record) => (
      <CentresField
        data={data}
        handleClickViewAll={() => openCentresUserModal(record)}
      />
    ),
    [openCentresUserModal],
  );

  const columns = [
    {
      title: i18next.t('brands.brand'),
      dataIndex: 'name',
      sorter: true,
      render: formatBrandName,
    },
    {
      title: i18next.t('providers.numberOfCentres'),
      dataIndex: 'numberOfCentres',
      sorter: true,
      render: (data, record) => record?.centres?.totalCentres || 0,
    },
    {
      title: i18next.t('centres.header'),
      dataIndex: 'centres',
      render: formatCentreName,
    },
    {
      dataIndex: 'id',
      fixed: 'right',
      width: 100,
      render: (id, record) => <GroupActionsBrand id={id} record={record} />,
    },
  ];

  const onChange = (e, filters, sorter) => {
    const formatSort =
      sorter && sorter.field && sorter.order
        ? `${sorter.order === 'descend' ? '-' : ''}${sorter.field}`
        : null;

    const restFilter = {
      offset: (e.current - 1) * e.pageSize,
      limit: e.pageSize,
      orderBy: formatSort,
      filter: resourceFilter.filter,
      outsideFilter: resourceFilter.outsideFilter,
    };

    retrieveList({
      filter: restFilter,
      isRefresh: true,
    });
  };

  return (
    <div>
      <TableCustom
        xScroll={1000}
        onChange={onChange}
        data={data}
        columns={columns}
        loading={loading}
        pagination={{
          pageSize: resourceFilter.limit,
          current: resourceFilter.offset / resourceFilter.limit + 1 || 1,
          total: resourceFilter.total,
        }}
      />
      <CentresModal
        ref={centresBrandModalRef}
        title="brands.centresOfBrand"
        resource="centresOfBrand"
        retrieveListAction={getCentresOfBrand}
      />
    </div>
  );
};

BrandsTable.propTypes = {
  retrieveList: PropTypes.func,
  resource: PropTypes.string,
};

BrandsTable.defaultProps = {
  retrieveList: () => null,
};

export default BrandsTable;
