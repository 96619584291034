import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { SettingFillIcon } from 'components/common/SVGIcon';

const SettingEnquiryBtn = ({ openSettingModal }) => (
  <Button
    className="btn-second-primary-fill"
    icon={<SettingFillIcon />}
    onClick={openSettingModal}
  />
);

SettingEnquiryBtn.propTypes = {
  openSettingModal: PropTypes.func,
};

export default SettingEnquiryBtn;
