import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import CentreModal from '.';

const DescriptionsModal = forwardRef(({ visible, onCancel, record }, ref) => {
  const [visibleModal, setVisibleModal] = useState(false);

  const onCancelModal = () => setVisibleModal(false);

  useImperativeHandle(
    ref,
    () => ({
      toggleModal: () => {
        setVisibleModal(true);
      },
    }),
    [],
  );

  return (
    <CentreModal
      title="centreDetail.titleCentreDescriptionModal"
      visible={visible || visibleModal}
      onCancel={onCancel || onCancelModal}
    >
      <Form.Item
        name="shortDescription"
        label={i18next.t('centreDetail.centreDescription')}
        initialValue={record?.shortDescription}
      >
        <Input.TextArea
          placeholder={i18next.t('centreDetail.centreDescription')}
          autoSize={{ minRows: 10, maxRows: 20 }}
        />
      </Form.Item>
    </CentreModal>
  );
});

DescriptionsModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  record: PropTypes.object,
};

DescriptionsModal.defaultProps = {
  record: {},
};

export default DescriptionsModal;
