import React from 'react';
import Create from 'containers/rest/Create';
import Form from '../components/Form';
import { PREFIX_URL_API } from '../../../configs/localData/constant';

const CompaniesCreate = (props) => (
  <Create
    {...props}
    defaultOptions={{ prefixUrl: PREFIX_URL_API }}
    resource="companies"
  >
    <Form />
  </Create>
);

export default CompaniesCreate;
