import React from 'react';
import Edit from 'containers/rest/Edit';
import Form from '../components/Form';

const AppSettingsEdit = (props) => (
  <Edit {...props} resource="appSettings" defaultOptions={{ isTrimStr: true }}>
    <Form isEdit />
  </Edit>
);

export default AppSettingsEdit;
