import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSummariesActivitiesApi } from 'api/activities';
import { makeActions } from 'redux/crudCreator/actions';
import { apiWrapper } from 'utils/reduxUtils';

export const MODEL_NAME = 'activities';
export const activitiesActions = makeActions(MODEL_NAME);

export const getAllActivities = activitiesActions.getAll;
export const editActivities = activitiesActions.edit;
export const createActivities = activitiesActions.create;
export const getByIdActivities = activitiesActions.getDataById;
export const delActivities = activitiesActions.del;
export const setCurrentDataActivities = activitiesActions.setCurrentData;
export const clearCurrentActivities = activitiesActions.clearCurrent;

export const getSummariesActivities = createAsyncThunk(
  'activities/getSummariesActivities',
  async (payload = {}, thunkAPI) => {
    try {
      const response = await apiWrapper({}, getSummariesActivitiesApi, payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
