import moment from 'moment';
import PropTypes from 'prop-types';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCentreTypesConst } from 'redux/config/selectors';
import CentreModal from '.';
import Form from '../Form';

const CentreInformationModal = forwardRef(({ record }, ref) => {
  const centreTypeConst = useSelector(getCentreTypesConst);
  const checkCentreTypeComingSoon = (type) =>
    type === centreTypeConst?.CENTRE_COMING_SOON;

  const [visible, setVisible] = useState(false);

  const onCancel = () => setVisible(false);

  useImperativeHandle(
    ref,
    () => ({
      toggleModal: () => {
        setVisible(true);
      },
    }),
    [],
  );

  const formatOnSubmit = ({
    LGA,
    region,
    brandId,
    companyId,
    dateApplied,
    governmentReferenceId,
    type,
    ...values
  }) => ({
    ...values,
    LGA: LGA || null,
    region: region || null,
    companyId: companyId || null,
    brandId: brandId || null,
    type,
    governmentReferenceId: checkCentreTypeComingSoon(type)
      ? governmentReferenceId || null
      : governmentReferenceId,
    dateApplied: dateApplied
      ? moment(dateApplied).format('DD-MM-YYYY')
      : dateApplied,
  });

  return (
    <CentreModal
      title="centreDetail.titleCentreDetailInformationModal"
      visible={visible}
      onCancel={onCancel}
      record={record}
      width={1100}
      formatOnSubmit={formatOnSubmit}
    >
      <Form />
    </CentreModal>
  );
});

CentreInformationModal.propTypes = {
  record: PropTypes.object,
};

export default CentreInformationModal;
