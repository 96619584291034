import React from 'react';
import RestInputItem from 'components/RestInput/RestInputItem';
import i18next from 'i18next';
import { InputNumber } from 'antd';
import RestSelect from 'components/RestInput/RestSelect';
import { BILLING_PERIODS } from 'configs/localData';
import FormListSingle from 'components/form/FormListSingle';
import StripeTaxRateForm from './StripeTaxRateForm';

const MarketingsForm = () => (
  <div>
    <RestInputItem
      required
      source="name"
      header="common.name"
      placeholder="common.name"
      isValidatorEmpty
    />

    <RestInputItem
      required
      source="type"
      header="common.type"
      placeholder="common.type"
      isValidatorEmpty
    />

    <RestInputItem
      required
      source="stripePriceId"
      header="marketings.stripePriceId"
      placeholder="marketings.stripePriceId"
    />

    <RestSelect
      required
      source="billingPeriod"
      header="marketings.billingPeriod"
      resourceData={BILLING_PERIODS}
      valueProp="value"
      titleProp="text"
      formatText={(text) => i18next.t(text)}
    />

    <RestInputItem
      required
      source="quantity"
      header="common.quantity"
      ContentComponent={InputNumber}
      placeholder="common.quantity"
      min={1}
    />

    <RestInputItem
      required
      source="amount"
      header="common.amount"
      placeholder="common.amount"
      ContentComponent={InputNumber}
      min={0}
    />

    <FormListSingle
      source="stripeTaxRateIds"
      title="marketings.stripeTaxRateIds"
    >
      <StripeTaxRateForm />
    </FormListSingle>
  </div>
);

export default MarketingsForm;
