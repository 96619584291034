import React from 'react';
import IndividualCentre from './IndividualCentre';
import GroupCentres from './GroupCentres';
import SendWrapperStyles from './styles';
import IndividualContact from './IndividualContact';

const SendWrapper = () => (
  <SendWrapperStyles>
    <IndividualCentre />
    <IndividualContact />
    <GroupCentres />
  </SendWrapperStyles>
);

export default SendWrapper;
