import i18next from 'i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { formatMoney } from 'utils/textUtils';
import { CURRENCY_UNIT_SYMBOL } from 'configs/localData/constant';
import CRMPackagePriceStyles from './styles';

const CRMPackagePrice = ({ price, unit }) => (
  <CRMPackagePriceStyles>
    {!price ? (
      i18next.t('text.free')
    ) : (
      <div className="price-detail">
        <span className="price-detail__sign">{CURRENCY_UNIT_SYMBOL}</span>
        <span className="price-detail__amount">{formatMoney(price)}</span>
        <span className="price-detail__unit">
          {`${i18next.t('text.perCentre')} / ${i18next.t('text.per')} ${
            unit || 'month'
          }`}
        </span>
      </div>
    )}
  </CRMPackagePriceStyles>
);

CRMPackagePrice.propTypes = {
  price: PropTypes.number,
  unit: PropTypes.string,
};

export default CRMPackagePrice;
