import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  .profile-info {
    display: flex;
    .info-section {
      flex-grow: 2;
      padding-top: 10px;
      padding-left: 56px;
      .header-profile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .button-edit {
          background: transparent;
          border: none;
          .anticon {
            font-size: 25px;
          }
          &:hover,
          &:focus {
            transform: scale(1.1, 1.1);
          }
          &:hover > .anticon,
          &:focus > .anticon {
            color: ${({ theme }) => theme.palette.primary};
          }
        }
      }
      .profile-info-title {
        text-transform: uppercase;
        font-size: 18px;
        color: ${({ theme }) => theme.text.secondary};
      }
      .name-section {
        margin-top: 15px;
        font-size: 30px;
        margin-bottom: 10px;
        font-weight: 600;
      }
      .phone-section,
      .email-section {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }
    .avatar-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px 24px;
      .account-info {
        padding: 10px 20px;
        font-size: 18px;
        text-align: center;
        .username {
          font-weight: 600;
        }
      }
    }
  }

  @media only screen and (max-width: 992px) {
    .profile-info {
      display: flex;
      flex-direction: column;
      .info-section {
        padding-left: 0px;
      }
      .form-section,
      .avatar-section {
        padding: 0;
      }
    }
    .action-section {
      padding: 0;
      margin-top: 10px;
    }
  }
`;
