import React, { useCallback } from 'react';
import { Select, Modal } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useCompany } from 'containers/Company/Show/context';
import SalesStageDropdownStyles from 'containers/Providers/components/SalesStageDropdown/styles';

const { confirm } = Modal;

const SalesStageDropdown = ({ id, saleStage }) => {
  const saleStages = useSelector((state) => state.config.data?.salesStage);

  const { updateCompany } = useCompany();

  const onChange = useCallback(
    (value, option) => {
      confirm({
        title: i18next.t('providerDetail.changeSaleStage'),
        content: i18next.t('providerDetail.confirmChangeSaleStage', {
          name: option?.children?.props?.children,
        }),
        onOk() {
          return new Promise((resolve, reject) => {
            if (id) {
              resolve(
                updateCompany({
                  id,
                  salesStagesId: value,
                }),
              );
            } else reject();
          });
        },
      });
    },
    [id], // eslint-disable-line
  );

  return (
    <SalesStageDropdownStyles>
      <Select
        placeholder={i18next.t('input.salesStage.placeholder')}
        optionFilterProp="children"
        onChange={onChange}
        value={saleStage}
        className="w-full"
      >
        {saleStages?.map((item) => (
          <Select.Option key={item?.id} value={item?.id}>
            <span className="text-16">{item?.name}</span>
          </Select.Option>
        ))}
      </Select>
    </SalesStageDropdownStyles>
  );
};

SalesStageDropdown.propTypes = {
  saleStage: PropTypes.string,
  id: PropTypes.string,
};

export default SalesStageDropdown;
