import { Menu } from 'antd';
import styled from 'styled-components';

const TitleTreeStyles = styled.div`
  .dropdown-icon {
    font-size: 20px;
    padding: 5px;
  }
`;

export default TitleTreeStyles;

export const MenuItemStyles = styled(Menu)`
  .ant-btn {
    border: none;
    box-shadow: none;
    width: 100%;
    background: transparent !important;
  }
`;
