import { createSelector } from 'reselect';

const getRawResourceDataProvidersOfCompany = (state) =>
  state.providersOfCompany?.resourceData;

const getRawProvidersOfCompanyLoading = (state) =>
  state.providersOfCompany?.loading;

const getRawDataProvidersOfCompany = (state) => state.providersOfCompany?.data;

export const getResourceDataProvidersOfCompany = createSelector(
  [getRawResourceDataProvidersOfCompany],
  (data) => data,
);

export const getLoadingProvidersOfCompany = createSelector(
  [getRawProvidersOfCompanyLoading],
  (data) => data,
);

export const getDataProvidersOfCompany = createSelector(
  [getRawDataProvidersOfCompany],
  (data) => data,
);

export const enabledLoadMoreProvidersOfCompanySelector = createSelector(
  [getRawProvidersOfCompanyLoading, getRawResourceDataProvidersOfCompany],
  (loading, resources) => {
    const { offset, limit, numberOfPages } = resources || {};

    return !loading && offset / limit + 1 < numberOfPages;
  },
);
