import styled from 'styled-components';

const ContactEnquiryStyles = styled.div`
  background: #fff;
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .contact-enquiry {
    &__header {
      padding: 12px 12px 8px 12px;
      border-bottom: 1px solid ${({ theme }) => theme.border.light};
    }
    &__body {
      overflow-y: auto;
      overflow-x: hidden;
    }
    &__section {
      padding: 16px 12px;
      border-bottom: 1px solid ${({ theme }) => theme.border.light};
    }
    &__section-title {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 12px;
    }
  }

  .item-info + .item-info {
    margin-top: 10px;
  }

  .item-info {
    display: flex;
    font-size: 16px;
    line-height: 20px;
    .anticon {
      margin-right: 10px;
      line-height: 26px;
      color: ${({ theme }) => theme.text.mainAC};
    }
    &__value {
      word-break: break-word;
    }
  }

  .yes-value {
    color: #fb8429;
  }
  .no-value {
    color: #36bf57;
  }

  .section-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }

  .title-info-item {
    margin-right: 10px;
    &:after {
      content: ':';
    }
  }

  .children-item {
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(39, 40, 51, 0.08);
    border-radius: 8px;
    margin-bottom: 12px;
    padding: 12px;
    &__gender {
      margin-left: 8px;
    }
    &__content-item {
      color: ${({ theme }) => theme.text.mainL2};
    }
    &__info > div {
      margin-bottom: 8px;
    }
  }

  .ant-divider {
    margin: 20px 0 !important;
  }

  .service-info > div:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export default ContactEnquiryStyles;
