import React from 'react';
import { Col, Row } from 'antd';
import i18next from 'i18next';
import { NOTIFICATION_STATUS } from 'configs/localData';
import RestInputItem from '../../../../components/RestInput/RestInputItem/index';
import RestSelect from '../../../../components/RestInput/RestSelect';
import RestRangePickerInput from '../../../../components/RestInput/RestRangePickerInput';

const Filter = () => (
  <div>
    <Row gutter={16}>
      <Col lg={8} md={12}>
        <RestInputItem
          source={['title', '$like']}
          placeholder="notifications.header"
        />
      </Col>
      <Col lg={8} md={12}>
        <RestSelect
          source="status"
          placeholder="notifications.status"
          resourceData={NOTIFICATION_STATUS}
          titleProp="text"
          valueProp="value"
          formatText={(text) => i18next.t(text)}
        />
      </Col>

      <Col lg={8} md={12}>
        <RestRangePickerInput
          source="createdAt"
          isShowTooltip
          sourceGt="createdAt.$gt"
          sourceLt="createdAt.$lt"
        />
      </Col>
    </Row>
  </div>
);

export default Filter;
