import React from 'react';
import RestInputItem from 'components/RestInput/RestInputItem';

const BusinessUsersForm = (props) => (
  <div {...props}>
    <RestInputItem source="firstName" header="businessUsers.firstName" />
    <RestInputItem source="jobTitle" header="businessUsers.jobTitle" />
    <RestInputItem source="role" header="businessUsers.role" />
    <RestInputItem source="centres" header="businessUsers.centres" />
    <RestInputItem source="lastActiveAt" header="businessUsers.lastActiveAt" />
    <RestInputItem source="createdAt" header="businessUsers.createdAt" />
    <RestInputItem
      source="isBlockCRMApp"
      header="businessUsers.isBlockCRMApp"
    />
    <RestInputItem source="isBlocked" header="businessUsers.isBlocked" />
  </div>
);

export default BusinessUsersForm;
