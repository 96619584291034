import React, { useCallback, useMemo, useEffect } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { getAllArticles } from 'redux/articles/actions';
import { articlesSelectors } from 'redux/articles/selectors';

import { customUrlParams, getFilterFromUrl } from 'utils/tools';
import { formatContent } from 'utils/formatFieldUtils';

import TableCustom from 'components/common/TableCustom';
import RestImageField from 'components/RestField/ImageField';
import { PREFIX_URL_API } from 'configs/localData/constant';
import SwitchAction from './SwitchAction';
import GroupActions from './GroupActions';
import ArticlesTableStyles from './styles';

const ArticleTable = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const location = useLocation();

  const resourceFilter = useSelector(articlesSelectors.getFilters) || {};

  const loading = useSelector((state) => state.articles.loading);

  const resourceData = useSelector(articlesSelectors.getDataArr);

  const columns = useMemo(
    () => [
      {
        title: i18next.t('common.thumbnail'),
        dataIndex: 'thumbnail',
        render: (text, record) => (
          <RestImageField record={record} source="thumbnail" />
        ),
      },
      {
        title: i18next.t('common.heading'),
        dataIndex: 'heading',
      },
      {
        title: i18next.t('common.subHeading'),
        dataIndex: 'subheading',
      },
      {
        title: i18next.t('articles.sponsored'),
        dataIndex: 'isSponsored',
        render: (value, record) => (
          <SwitchAction value={value} id={record?.id} source="isSponsored" />
        ),
      },
      {
        title: i18next.t('articles.recommended'),
        dataIndex: 'isRecommended',
        render: (value, record) => (
          <SwitchAction value={value} id={record?.id} source="isRecommended" />
        ),
      },
      {
        title: i18next.t('common.content'),
        dataIndex: 'content',
        render: formatContent,
      },

      {
        title: '',
        dataIndex: 'id',
        width: 120,
        fixed: 'right',
        render: (id) => <GroupActions id={id} />,
      },
    ],
    [],
  );

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh = true }) => {
      dispatch(
        getAllArticles({
          data: filter,
          options: { isRefresh, prefixUrl: PREFIX_URL_API },
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    const defaultFilter = getFilterFromUrl(location.search);
    if (defaultFilter?.filter?.categoryId) {
      retrieveList({
        filter: {
          ...defaultFilter,
          limit: defaultFilter.limit || 10,
          offset: defaultFilter.offset || 0,
        },
        isRefresh: true,
      });
    }
  }, [location.search, retrieveList]);

  const onChange = useCallback(
    (e, filters, sorter) => {
      const formatSort =
        sorter && sorter.field && sorter.order
          ? `${sorter.order === 'descend' ? '-' : ''}${sorter.field}`
          : null;

      const urlSearchParams = customUrlParams(
        {
          offset: (e.current - 1) * e.pageSize,
          limit: e.pageSize,
          orderBy: formatSort,
        },
        location.search,
      );

      history.push(`?${urlSearchParams.toString()}`);
    },
    [location.search, history],
  );

  return (
    <ArticlesTableStyles>
      <TableCustom
        isSorter
        onChange={onChange}
        data={resourceData}
        columns={columns}
        loading={loading}
        pagination={{
          pageSize: resourceFilter.limit,
          current: resourceFilter.offset / resourceFilter.limit + 1,
          total: resourceFilter.count,
        }}
      />
    </ArticlesTableStyles>
  );
};

export default ArticleTable;
