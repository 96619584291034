import React from 'react';
import RestShow from 'containers/rest/Show';
import RestFieldItem from 'components/RestField/RestFieldItem';

const ClientUsersShow = (props) => (
  <RestShow {...props} hasEdit resource="clientUsers">
    <RestFieldItem source="firstName" header="clientUsers.firstName" />
    <RestFieldItem source="phoneNumber" header="clientUsers.phoneNumber" />
    <RestFieldItem source="lastActiveAt" header="clientUsers.lastActiveAt" />
    <RestFieldItem source="createdAt" header="clientUsers.createdAt" />
    <RestFieldItem source="isBlocked" header="clientUsers.isBlocked" />
  </RestShow>
);

export default ClientUsersShow;
