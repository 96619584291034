import React from 'react';
import { useSelector } from 'react-redux';

import { TotalEnquiriesIcon } from 'components/common/SVGIcon';
import SummaryView from 'components/common/SummaryCardDetail/SummaryView';

const SummaryEnquiries = () => {
  const summaries =
    useSelector((state) => state.companies.summaries?.enquiriesSummary) || {};

  const data = [
    {
      title: 'enquiryTypes.moreInformation',
      value: summaries.moreInformation,
    },
    {
      title: 'enquiryTypes.checkForVacancies',
      value: summaries.checkForVacacies,
    },
    {
      title: 'enquiryTypes.bookATourOrVisit',
      value: summaries.bookATourOrVisit,
    },
    {
      title: 'enquiryTypes.feeInformation',
      value: summaries.feeInformation,
    },
  ];

  return (
    <SummaryView
      IconComponent={TotalEnquiriesIcon}
      title="summaries.totalEnquiries"
      total={summaries?.totalEnquiries}
      data={data}
      color="#32A4FC"
    />
  );
};

export default SummaryEnquiries;
