import React, { useEffect, useCallback } from 'react';
import { isEmpty } from 'lodash-es';
import { Button, Skeleton, notification, Modal } from 'antd';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  getRatingsSelector,
  getRatingsLoadingSelector,
  enabledLoadMoreRatingsSelector,
} from 'redux/centres/selectors';
import { delAllGoogleReviews, getRatingsCentre } from 'redux/centres/actions';
import { syncGoogleReviewOfCentreApi } from 'api/centres';
import EmptyData from 'components/common/EmptyData';
import CardSection from 'components/common/CardSection';
import CommentItem from './CommentItem';

const ReviewsSection = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const placeId = useSelector((state) => state.centres.currentData?.placeId);

  const ratings = useSelector(getRatingsSelector);

  const loading = useSelector(getRatingsLoadingSelector);

  const enabledLoadMore = useSelector(enabledLoadMoreRatingsSelector);

  const hasGoogleReview = ratings?.find((item) => !item?.isInternalRating);

  const retrieveList = useCallback(
    (isRefresh, filter) => {
      id &&
        dispatch(
          getRatingsCentre({
            data: { id, orderBy: '-createdAt', ...filter },
            options: { isRefresh },
          }),
        );
    },
    [id, dispatch],
  );

  useEffect(() => {
    retrieveList(true, { limit: 10, offset: 0 });
  }, [id, retrieveList]);

  const handleViewMoreReview = useCallback(() => {
    retrieveList();
  }, [retrieveList]);

  const syncGoogleReview = () => {
    syncGoogleReviewOfCentreApi(id)
      .then(() => {
        retrieveList(true, { limit: 10, offset: 0 });
      })
      .catch((error) => {
        if (error?.statusCode) {
          notification.error({
            message: i18next.t('error.title'),
            description: error.message || i18next.t('error.description'),
          });
        }
      });

    Modal.info({
      title: i18next.t('button.syncGoogleReview'),
      content: i18next.t('centres.syncGoogleReviewAlert'),
    });
  };

  const deleteAllGoogleReviews = () => {
    Modal.confirm({
      title: i18next.t('button.deleteAllGoogleReviews'),
      content: i18next.t('centres.confirmDeleteAllGoogleReviews'),
      onOk: () => {
        dispatch(delAllGoogleReviews({ id }));
      },
    });
  };

  return (
    <CardSection
      title="centreDetail.reviews"
      extraAction={
        <div>
          {hasGoogleReview && (
            <Button type="primary" ghost onClick={deleteAllGoogleReviews}>
              {i18next.t('button.deleteAllGoogleReviews')}
            </Button>
          )}
          {!!placeId && (
            <Button type="primary" onClick={syncGoogleReview} className="ml-12">
              {i18next.t('button.syncGoogleReview')}
            </Button>
          )}
        </div>
      }
    >
      {!loading && isEmpty(ratings) ? (
        <EmptyData />
      ) : (
        <div>
          {ratings.map((item, index) => (
            <CommentItem key={String(index)} item={item} />
          ))}
        </div>
      )}
      {loading && <Skeleton active avatar />}
      {enabledLoadMore && (
        <Button type="link" onClick={handleViewMoreReview}>
          {i18next.t('button.viewMoreReviews')}
        </Button>
      )}
    </CardSection>
  );
};

export default ReviewsSection;
