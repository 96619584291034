import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import i18next from 'i18next';

import { PREFIX_URL } from 'configs/localData/constant';
import { validateRegex } from 'utils/validateUtils';

import RestSelect from 'components/RestInput/RestSelect';
import RestInputItem from 'components/RestInput/RestInputItem';
import ReferenceInput from 'containers/rest/ReferenceInput';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { useSelector } from 'react-redux';

const SEPRNumber = ({ isComingSoon }) => {
  const { form } = useContext(RestInputContext);
  const currentCentre = useSelector((state) => state.centres.currentData);

  const handleSelectPRNumber = (value, item) => {
    form.setFieldsValue({
      companyId: item?.companyId,
      brandId: null,
      businessName: item?.name,
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      companyId: currentCentre?.provider?.companyId,
      businessName: currentCentre?.provider?.name,
    });
  }, [currentCentre]); // eslint-disable-line

  return (
    <Row gutter={16}>
      <Col span={12}>
        <RestInputItem
          required={!isComingSoon}
          source="governmentCentreServiceId"
          header="centres.SENumber"
          placeholder="centres.SENumber"
          dependencies={['type']}
          rules={[
            {
              pattern: validateRegex.SENumber,
              message: i18next.t('input.SENumber.validateMsg.invalid'),
            },
          ]}
        />
      </Col>
      <Col span={12}>
        <ReferenceInput
          prefixUrl={PREFIX_URL.superAdmin}
          resource="providers"
          source="governmentReferenceId"
          searchKey="governmentReferenceId"
          initialFilter={{ orderBy: 'governmentReferenceId' }}
        >
          <RestSelect
            source="governmentReferenceId"
            required={!isComingSoon}
            dependencies={['type']}
            rules={[
              {
                pattern: validateRegex.PRNumber,
                message: i18next.t('input.PRNumber.validateMsg.invalid'),
              },
            ]}
            onChangeGetSelectedItem={handleSelectPRNumber}
            titleProp="governmentReferenceId"
            valueProp="governmentReferenceId"
            header="centres.PRNumber"
            placeholder="centres.PRNumber"
            isFilterOption={false}
          />
        </ReferenceInput>
      </Col>
    </Row>
  );
};

SEPRNumber.propTypes = {
  isComingSoon: PropTypes.bool,
};

export default SEPRNumber;
