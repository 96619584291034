import { isEmpty, isPlainObject } from 'lodash-es';

const makeObjFile = (value) => ({
  uid: value,
  name: value,
  status: 'done',
  url: value,
  id: value,
});

export const makeFileList = (values) => {
  if (isEmpty(values)) return [];

  if (Array.isArray(values))
    return values.map((value, idx) =>
      value && value.url ? { uid: String(idx), ...value } : makeObjFile(value),
    );

  if (isPlainObject(values))
    return [
      {
        ...makeObjFile(values.url),
        ...values,
      },
    ];

  return [makeObjFile(values)];
};

export const formatNewFileListWithFeature = (fileList, indexFeatured) => {
  const data = fileList?.map((file, idx) => {
    if (indexFeatured === idx) return { ...file, featured: true };
    return { ...file, featured: false };
  });

  const fileListFormValue = data?.map((file) => ({
    key: file.name || file.key,
    url: file.response || file.url,
    featured: file.featured,
    type: file.type,
    source: file.source,
  }));

  return { fileList: data, fileListFormValue };
};

const getuid = () => new Date().getTime().toString();

export const formatNewFileListWithAttachVideoUrl = (fileList, url) => {
  const data = [
    ...fileList,
    { uid: getuid(), url, type: 'video', source: 'youtube' },
  ];

  const fileListFormValue = data?.map((file) => ({
    key: file.name || file.key,
    url: file.response || file.url,
    featured: file.featured,
    type: file.type,
    source: file.source,
  }));

  return { fileList: data, fileListFormValue };
};
