import { createSelector } from 'reselect';
import { CRUDSelectors } from '../crudCreator/selectors';
import { MODEL_NAME } from './actions';

const getSummary = (state) => state.centres.additionData?.centreUsersSummary;

const getResourceDataRatings = (state) => state.centres.ratings?.resourceData;

const getRatings = (state) => state.centres.ratings?.data;

const getRatingsLoading = (state) => state.centres.ratingsLoading;

const getRawSubscriptions = (state) => state.config?.data?.CRMPackage;

const getSocialMediaChannels = (state) => state.centres.socialMedia;

const getTotalSocialMediaChannels = (state) => state.centres.totalSocialMedia;

const getRawKindiCareRatingSummarySelector = (state) =>
  state?.centres?.currentData?.kindiRatingSummary;

export const getSummarySelector = createSelector(
  [getSummary],
  (data) => data || {},
);

export const getResourceDataRatingsSelector = createSelector(
  [getResourceDataRatings],
  (data) => data || {},
);

export const getRatingsSelector = createSelector(
  [getRatings],
  (data) => data || [],
);

export const getRatingsLoadingSelector = createSelector(
  [getRatingsLoading],
  (data) => data,
);

export const enabledLoadMoreRatingsSelector = createSelector(
  [getRatingsLoading, getResourceDataRatings],
  (loading, resources) => {
    const { offset, limit, numberOfPages } = resources || {};
    return !loading && offset / limit + 1 < numberOfPages;
  },
);

export const getSubscriptionsArr = createSelector(
  [getRawSubscriptions],
  (data) => data || [],
);

export const getKindiCareRatingSummarySelector = createSelector(
  [getRawKindiCareRatingSummarySelector],
  (data) => data || [],
);

export const getSocialMediaChannelsData = createSelector(
  [getSocialMediaChannels],
  (data) => data,
);

export const getTotalSocialMediaChannelsValue = createSelector(
  [getTotalSocialMediaChannels],
  (data) => data,
);

export const centresSelectors = new CRUDSelectors(MODEL_NAME);
