import React from 'react';
import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import Logo from 'components/common/Logo';
import DeleteButton from 'components/RestActions/DeleteButton';
import { PREFIX_URL } from 'configs/localData/constant';
import { Switch, Modal } from 'antd';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { editBrands } from 'redux/brands/actions';
import { Link } from 'react-router-dom';
import Filter from '../components/Filter';

const BrandsList = (props) => {
  const dispatch = useDispatch();

  const handleFeatureBrand = (value, record) => {
    Modal.confirm({
      title: i18next.t('users.confirm'),
      content: record?.isFeatured
        ? i18next.t('brands.confirmTurnOffBrand')
        : i18next.t('brands.confirmTurnOnBrand'),
      onOk() {
        return dispatch(
          editBrands({
            data: { id: record?.id, isFeatured: value },
            options: { prefixUrl: PREFIX_URL.superAdmin },
          }),
        );
      },
    });
  };

  return (
    <List
      {...props}
      widthTable="776px"
      filter={<Filter />}
      noCardWrapper
      hasSearch={false}
      defaultOptions={{ prefixUrl: PREFIX_URL.superAdmin }}
      resource="brands"
    >
      <RestFieldItem
        source="logo"
        header="brands.brand"
        format={(logo, record) => <Logo logo={logo} name={record.name} />}
      />
      <RestFieldItem
        source="company"
        header="brands.company"
        format={(data) => (
          <Link to={`companies/${data?.id}/show/summary`}>
            <Logo logo={data.logo} name={data.name} />
          </Link>
        )}
      />
      <RestFieldItem
        source="isFeatured"
        header="centres.featured"
        valueProp="checked"
        component={<Switch />}
        width={150}
        customOnChange={handleFeatureBrand}
      />
      <ActionGroup widthAction={80}>
        <EditButton />
        <DeleteButton />
      </ActionGroup>
    </List>
  );
};

export default BrandsList;
