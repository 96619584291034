import React, { useCallback } from 'react';
import i18next from 'i18next';
import { formatMoney } from 'utils/tools';
import GroupAction from './components/GroupActions';
import SwitchToggle from './components/SwitchToggle';

const useFormat = () => {
  const formatCostPerDay = useCallback(
    (data) => `${i18next.t('currencyUnit.sign')}${formatMoney(data)}`,
    [],
  );

  const formatVacancy = useCallback(
    (data, record) => (
      <SwitchToggle checked={data} source="vacancy_cod" id={record.id} />
    ),
    [],
  );

  const formatIsActive = useCallback(
    (data, record) => (
      <SwitchToggle checked={data} source="isActive" id={record.id} />
    ),
    [],
  );

  const formatGroupAction = useCallback(
    (id, record) => <GroupAction id={id} record={record} />,
    [],
  );

  const formatCapacity = useCallback((data) => formatMoney(data), []);

  const formatRoomName = useCallback(
    (data) => <div className="ellipsis-t-2">{data}</div>,
    [],
  );

  return {
    formatCostPerDay,
    formatVacancy,
    formatIsActive,
    formatGroupAction,
    formatCapacity,
    formatRoomName,
  };
};

export default useFormat;
