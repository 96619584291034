import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { getUrlIfyMessage } from 'utils/dataUtils';
import ReadMoreStyles from './styles';

const ELLIPSES = '…';

class ReadMore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showingAll: false,
    };
  }

  toggleReadMore = () => {
    this.setState((state) => ({
      showingAll: !state.showingAll,
    }));
  };

  getReadMoreParts = ({ text, readMoreCharacterLimit }) => {
    let teaserText;
    let remainingText;

    if (text) {
      if (text.length > readMoreCharacterLimit) {
        teaserText = text.slice(0, -(text.length - readMoreCharacterLimit));
        remainingText = text.slice(readMoreCharacterLimit);
      } else teaserText = text;
    }

    return {
      teaserText,
      remainingText,
    };
  };

  getText = ({ showingAll, text, readMoreCharacterLimit, numberOfLines }) => {
    const { teaserText, remainingText } = this.getReadMoreParts({
      text,
      numberOfLines,
      readMoreCharacterLimit,
    });
    if (!showingAll && text?.length > readMoreCharacterLimit) {
      return (
        <span className="text-pre-wrap">
          <span
            className="teaser-text"
            dangerouslySetInnerHTML={{ __html: getUrlIfyMessage(teaserText) }}
          />
          <span
            className="read-more__text--remaining read-more__text--hide"
            dangerouslySetInnerHTML={{
              __html: getUrlIfyMessage(remainingText),
            }}
          />
          {ELLIPSES}
        </span>
      );
    }

    return (
      <span className="text-pre-wrap">
        <span
          className="teaser-text"
          dangerouslySetInnerHTML={{ __html: getUrlIfyMessage(teaserText) }}
        />
        <span
          className="read-more__text--remaining read-more__text--show"
          dangerouslySetInnerHTML={{ __html: getUrlIfyMessage(remainingText) }}
        />
      </span>
    );
  };

  getActionButton = ({ showingAll, showLessButton }) => {
    if (showingAll && !showLessButton) {
      return null;
    }

    const { showLessText, readMoreText } = this.props;

    const buttonText = showingAll
      ? i18next.t(showLessText)
      : i18next.t(readMoreText);

    return (
      <button
        onClick={this.toggleReadMore}
        className="read-more__button"
        type="button"
      >
        {buttonText}
      </button>
    );
  };

  render() {
    const { text, readMoreCharacterLimit, showLessButton, numberOfLines } =
      this.props;

    const isShowReadMore = text?.length <= readMoreCharacterLimit;
    const { showingAll } = this.state;
    const textToDisplay = this.getText({
      showingAll,
      text,
      readMoreCharacterLimit,
      numberOfLines,
    });
    const actionButton = this.getActionButton({
      showingAll,
      showLessButton,
      isShowReadMore,
    });

    return (
      <ReadMoreStyles className="read-more">
        {textToDisplay} {!isShowReadMore ? actionButton : null}
      </ReadMoreStyles>
    );
  }
}

ReadMore.propTypes = {
  numberOfLines: PropTypes.number,
  readMoreCharacterLimit: PropTypes.number,
  showLessButton: PropTypes.bool,
  text: PropTypes.string,
  showLessText: PropTypes.string,
  readMoreText: PropTypes.string,
};

ReadMore.defaultProps = {
  numberOfLines: 2,
  readMoreCharacterLimit: 255,
  showLessButton: true,
  showLessText: 'button.showLess',
  readMoreText: 'button.readMore',
};

export default ReadMore;
