import ModalCustom from 'components/common/ModalCustom';
import styled from 'styled-components';

export const FavoritesStyles = styled.div`
  .template-list-wrapper {
    .ant-list-grid .ant-col > .ant-list-item {
      margin-bottom: 0 !important;
    }
    .ant-col,
    .ant-list-item,
    .template-item {
      height: 100%;
    }
    .ant-pagination a {
      text-decoration: unset;
    }
  }

  .email-preview {
    background: ${({ theme }) => theme.background.content};
    border-radius: 12px;
    padding: 12px;

    .ant-divider {
      margin: 10px 0 !important;
    }

    &__title {
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
      color: ${({ theme }) => theme.palette.primary};
    }

    &__zoom-btn {
      border: none;
      .anticon {
        font-size: 20px;
      }
    }

    &__content {
      overflow-x: hidden;
    }
  }
`;

export const TemplateModalStyles = styled(ModalCustom)`
  .ant-modal-body {
    max-height: unset !important;
    overflow-y: unset !important;
  }

  .ant-empty {
    padding: 20px 0;
  }
`;
