import React from 'react';
import PropTypes from 'prop-types';
import { Comment, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import ReadMore from 'components/common/ReadMore';
import HeaderComment from './HeaderComment';

import { CommentItemStyles } from './styles';
import ReliesSection from '../ReliesSection';

const CommentItem = ({ item }) => (
  <CommentItemStyles>
    <Comment
      author={
        <HeaderComment
          authorName={
            item.authorName || `${item.user?.lastName} ${item.user?.firstName}`
          }
          rating={item.rating}
          createdAt={item.createdAt}
          isInternalRating={item.isInternalRating}
        />
      }
      avatar={
        <Avatar
          size={60}
          src={item.avatar?.url || item.user?.avatar}
          alt=""
          icon={<UserOutlined />}
        />
      }
      content={
        <p className="comment-message">
          <ReadMore text={item.comment} />
        </p>
      }
    >
      {item.isInternalRating ? (
        <ReliesSection
          id={item?.id}
          latestReplyReview={item?.latestReplyReview}
          totalReplies={item?.totalReplies}
        />
      ) : null}
    </Comment>
  </CommentItemStyles>
);

CommentItem.propTypes = {
  item: PropTypes.object,
};

CommentItem.defaultProps = {
  item: {},
};

export default CommentItem;
