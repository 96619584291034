import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  editClickThroughTrackerApi,
  getAllClickThroughTrackerApi,
} from 'api/clickThroughTracker';
import { makeActions } from 'redux/crudCreator';
import { apiWrapper } from 'utils/reduxUtils';

export const MODEL_NAME = 'clickThroughTracker';
export const clickThroughTrackerActions = makeActions(MODEL_NAME);

export const getAllClickThroughTracker = createAsyncThunk(
  `${MODEL_NAME}/getAllClickThroughTracker`,
  async (payload = {}, thunkApi) => {
    try {
      const { size, page, filter, sorts } =
        thunkApi.getState().clickThroughTracker?.resourceData || {};

      const response = await apiWrapper({}, getAllClickThroughTrackerApi, {
        size,
        page,
        filter,
        sorts,
        ...payload.data,
      });

      return {
        data: {
          page: Math.ceil(response.total / size),
          ...response,
        },
        options: payload.options,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ id: payload.data.id, data: error });
    }
  },
);

export const editAllClickThroughTracker = createAsyncThunk(
  `${MODEL_NAME}/editAllClickThroughTracker`,
  async (payload = {}, thunkAPI) => {
    try {
      const { data } = payload;
      const { id, ...restData } = data;
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        editClickThroughTrackerApi,
        id,
        restData,
      );
      return { data: response, isSuccess: true };
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error, id: payload.data.id });
    }
  },
);
