import { crudSelectorsCustom } from 'redux/crudCreatorCustom/selector';
import { createSelector } from 'reselect';
import { MODEL_NAME } from './actions';

export const centresOfProviderSelectors = crudSelectorsCustom(MODEL_NAME);

const getRawResourceDataCentresOfProvider = (state) =>
  state.centresOfProvider?.resourceData;

const getRawCentresOfCompanyLoading = (state) =>
  state.centresOfProvider?.loading;

export const getResourceDataCentresOfProvider = createSelector(
  [getRawResourceDataCentresOfProvider],
  (data) => data,
);

export const getLoadingCentresOfProvider = createSelector(
  [getRawCentresOfCompanyLoading],
  (data) => data,
);
