import styled from 'styled-components';

const FormStyles = styled.div`
  .user-avatar-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title-session {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 15px;
    margin-top: 20px;
  }
`;

export default FormStyles;

export const ListCheckboxSelectStyles = styled.div`
  margin-top: -7px;
  .filterButton {
    padding: 7px 13px;
  }
  .ant-divider {
    margin: 10px 0 0 0 !important;
  }
  .centre-list {
    &__list {
      margin-top: 20px;
    }
    &__selected-total {
      background: #f7f8f9;
      color: ${({ theme }) => theme.palette.primary};
      padding: 10px;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .checkbox-all {
    .ant-checkbox + span {
      line-height: 27px;
    }
  }

  .ant-checkbox-inner,
  .ant-radio-inner {
    width: 20px !important;
    height: 20px !important;
  }

  .ant-radio-inner::after {
    width: 12px !important;
    height: 12px !important;
  }

  .ant-checkbox-wrapper,
  &__select-one-centre,
  .ant-radio-wrapper {
    font-size: 16px;
  }

  .ant-radio-wrapper .ant-radio,
  .ant-checkbox-wrapper .ant-checkbox {
    margin-right: 10px;
  }

  .form-item-centreId .ant-form-item-control-input {
    display: none;
  }

  .user-centre-item + .user-centre-item {
    margin-top: 20px;
  }

  .user-center-item {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const PaginationCustomStyles = styled.div`
  display: flex;
  align-items: center;
  .paging-custom-action {
    margin-left: 10px;
    button {
      border: none;
      font-size: 10px;
    }

    .anticon {
      font-size: 14px !important;
    }
    button:disabled {
      background: transparent !important;
      color: ${({ theme }) => theme.background.disabledBtn} !important;
    }
  }
`;

export const GeneratePasswordStyles = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 20px;
  .generate-password-input {
    margin-bottom: 0 !important;
  }
  .ant-typography {
    min-height: 40px;
    background: ${({ theme }) => theme.background.input};
    border-radius: 4px;
    padding: 0px 10px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ant-typography-copy {
    font-weight: 500;
  }
  .generate-password-input {
    width: 100%;
  }
  .generate-button {
    margin-left: 10px;
  }
`;
