import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DatePicker } from 'antd';
import i18next from 'i18next';
import FormItemUI from '../FormItem';
import { DateTimePickerWrapper } from './styles';

const FormDatePicker = ({
  isShowTime,
  formatDate,
  allowClear,
  placeholder,
  ...props
}) => {
  const { required, defaultValue, initialValue, source, disabled } = props;
  const getValueFromEvent = (value) => {
    const e = value?.toISOString();
    props.formOptions &&
      props.formOptions.getValueFromEvent &&
      props.formOptions.getValueFromEvent(e);
    return e;
  };
  return (
    <DateTimePickerWrapper>
      <FormItemUI
        {...props}
        formOptions={{
          getValueFromEvent,
          normalize: (value) => value && moment(value),
        }}
        ruleType="object"
        defaultValue={
          defaultValue || initialValue
            ? moment(defaultValue || initialValue)
            : undefined
        }
        className="title"
        required={required}
        name={source}
      >
        <DatePicker
          allowClear={allowClear}
          disabled={disabled}
          format={formatDate}
          className="viewDatePicker"
          placeholder={i18next.t(placeholder)}
        />
      </FormItemUI>
    </DateTimePickerWrapper>
  );
};

FormDatePicker.propTypes = {
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  header: PropTypes.any,
  required: PropTypes.bool,
  requiredMessage: PropTypes.node,
  icon: PropTypes.string,
  form: PropTypes.object,
  defaultValue: PropTypes.any,
  initialValue: PropTypes.object,
  formOptions: PropTypes.object,
  disabled: PropTypes.bool,
  isShowTime: PropTypes.bool,
  formatDate: PropTypes.string,
  allowClear: PropTypes.bool,
  placeholder: PropTypes.string,
};

FormDatePicker.defaultProps = {
  isShowTime: false,
  formOptions: {},
  formatDate: 'DD MMM YYYY',
  allowClear: true,
};

export default FormDatePicker;
