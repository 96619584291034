import React, { useState, useCallback } from 'react';
import { Row, Col } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';

import CardSection from 'components/common/CardSection';
import { AdditionalStyles } from './styles';
import AdditionalDetailModal from '../Modals/AdditionalDetailModal';

const AdditionalDetails = ({ adminEmail, region, LGA }) => {
  const [visibleModal, setVisibleModal] = useState(false);

  const onClickButton = useCallback(() => {
    setVisibleModal(true);
  }, []); // eslint-disable-line

  const onCancel = useCallback(() => {
    setVisibleModal(false);
  }, []); // eslint-disable-line

  return (
    <>
      <CardSection
        isEdit
        onClickButton={onClickButton}
        title="centreDetail.additionalDetails"
        permissionKey="myCentres"
        action="isEdit"
      >
        <AdditionalStyles>
          <Row gutter={[20, 20]} className="row-centre-information">
            <Col className="col-title" xxl={8} xl={8} lg={10} md={10} xs={24}>
              {i18next.t('centres.region')}
            </Col>
            <Col className="col-value" xxl={16} xl={16} lg={14} md={14} xs={24}>
              {region || i18next.t('error.waitingUpdate')}
            </Col>

            <Col className="col-title" xxl={8} xl={8} lg={10} md={10} xs={24}>
              {i18next.t('centres.LGA')}
            </Col>
            <Col className="col-value" xxl={16} xl={16} lg={14} md={14} xs={24}>
              {LGA || i18next.t('error.waitingUpdate')}
            </Col>
          </Row>
        </AdditionalStyles>
      </CardSection>
      <AdditionalDetailModal
        visible={visibleModal}
        onCancel={onCancel}
        record={{ adminEmail, region, LGA }}
      />
    </>
  );
};

AdditionalDetails.propTypes = {
  adminEmail: PropTypes.string,
  region: PropTypes.string,
  LGA: PropTypes.string,
};

export default AdditionalDetails;
