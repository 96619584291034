export const EMAIL_EDITOR_OPTIONS = {
  fonts: {
    showDefaultFonts: true,
    customFonts: [
      {
        label: 'Mulish',
        value: "'Mulish',sans-serif",
        url: 'https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap',
      },
    ],
  },
};
