import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { Avatar, Button } from 'antd';
import { ROLE, GENDERS } from 'configs/localData';
import { UserOutlined, EditOutlined } from '@ant-design/icons';
import ProfileDetailStyles from './styles';

const ProfileDetail = ({ isProfile, record, handleClick, currentRole }) => {
  const genderItem = GENDERS.find((gender) => gender.value === record?.gender);

  return (
    <ProfileDetailStyles>
      <div className="profile-info">
        <div className="avatar-section">
          <Avatar src={record?.avatar} icon={<UserOutlined />} size={150} />
          <div className="account-info">
            {isProfile && (
              <div className="role">
                {i18n.t(ROLE.find((role) => currentRole === role.value)?.text)}
              </div>
            )}
          </div>
        </div>
        <div className="info-section">
          <div className="header-profile">
            <div className="profile-info-title">
              {i18n.t('profile.profileInfo')}
            </div>
            {isProfile && (
              <Button
                onClick={handleClick}
                icon={<EditOutlined />}
                className="button-edit"
              />
            )}
          </div>

          <div className="name-section">
            {`${record.firstName || ''} ${record.lastName || ''}`}
            {genderItem?.icon && (
              <genderItem.icon
                style={{
                  marginLeft: '10px',
                  color: genderItem?.color,
                }}
              />
            )}
          </div>
          <div className="email-section">
            {`${i18n.t('profile.email')}: ${
              record?.email || i18n.t('error.waitingUpdate')
            }`}
          </div>
          <div className="phone-section">
            {`${i18n.t('profile.phone')}: ${
              record?.phoneNumber || i18n.t('error.waitingUpdate')
            }`}
          </div>
        </div>
      </div>
    </ProfileDetailStyles>
  );
};

ProfileDetail.propTypes = {
  record: PropTypes.object,
  isProfile: PropTypes.bool,
  handleClick: PropTypes.func,
  currentRole: PropTypes.string,
};

ProfileDetail.defaultProps = {
  isProfile: false,
  handleClick: () => {},
};

export default ProfileDetail;
