import React from 'react';
import PropTypes from 'prop-types';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import { PREFIX_URL } from 'configs/localData/constant';

import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import ReferenceInput from 'containers/rest/ReferenceInput';
import { useParams } from 'react-router';
import FilterCustom from 'components/common/FilterCustom';

const FilterBrandsOfCompany = ({ retrieveList }) => {
  const { id: companyId } = useParams();

  const onSubmitFilter = (values) => {
    retrieveList({
      filter: {
        offset: 0,
        filter: values,
      },
      isRefresh: true,
      keepOrderBy: true,
    });
  };

  const onClearFilter = () => {
    retrieveList({
      filter: {
        offset: 0,
      },
      isRefresh: true,
      keepOrderBy: true,
    });
  };

  return (
    <FilterCustom
      onSubmitFilter={onSubmitFilter}
      onClearFilter={onClearFilter}
      responsiveFilter={{
        xxl: 20,
        xl: 18,
        lg: 24,
        md: 24,
        xs: 24,
      }}
      responsiveAction={{
        xxl: 4,
        xl: 6,
        lg: 24,
        md: 24,
        xs: 24,
      }}
    >
      <Row gutter={16}>
        <Col xl={12} md={12} xs={12}>
          <RestInputItem
            source={['brand.name', '$like']}
            placeholder="brands.brandName"
            isShowTooltip
          />
        </Col>
        <Col xl={12} md={12} xs={12}>
          <ReferenceInput
            prefixUrl={PREFIX_URL.superAdmin}
            source="centreId"
            resource={`companies/${companyId}/centres`}
            searchKey="name"
            initialFilter={{ orderBy: 'name' }}
          >
            <RestSelect
              source="centreId"
              placeholder="centres.centreName"
              isShowTooltip
              titleProp="name"
              valueProp="id"
              isFilterOption={false}
            />
          </ReferenceInput>
        </Col>
      </Row>
    </FilterCustom>
  );
};

FilterBrandsOfCompany.propTypes = {
  retrieveList: PropTypes.func,
};

export default FilterBrandsOfCompany;
