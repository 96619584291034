import React from 'react';
import RestShow from 'containers/rest/Show';
import RestFieldItem from 'components/RestField/RestFieldItem';

const MarketingsShow = (props) => (
  <RestShow {...props} hasEdit resource="marketings">
    <RestFieldItem source="name" header="marketings.name" />
    <RestFieldItem source="type" header="marketings.type" />
    <RestFieldItem source="amount" header="marketings.amount" />
    <RestFieldItem source="quantity" header="marketings.quantity" />
  </RestShow>
);

export default MarketingsShow;
