import styled from 'styled-components';

const AutomatedDetailStyles = styled.div`
  .automated-info {
    &__des {
      margin-top: 10px;
      margin-bottom: 16px;
    }
  }
  .message-box {
    &__content {
      background: #f7f8f9;
      border-radius: 8px;
      padding: 20px 12px;
      margin-bottom: 20px;
      white-space: pre-line;
      word-break: break-word;
      a {
        text-decoration: underline;
      }
    }
  }

  .automated-info,
  .automated-preview {
    padding: 0 20px;
  }

  .automated-preview {
    &__phone {
      position: relative;
      display: table-cell;
    }
    &__phone-img {
      object-fit: contain;
      max-width: 100%;
      width: 280px;
    }
    &__phone_info {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      padding: 46px 15px 26px 15px;
      text-align: left;
      display: flex;
      flex-direction: column;
    }
    &__phone-header {
      padding: 0 10px 10px;
      border-bottom: 1px solid #f2f2f2;
      display: flex;
      align-items: center;
      .anticon {
        margin-right: 10px;
      }

      .centre-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &__phone-body {
      flex: 1;
      padding: 20px 10px 0 10px;
      overflow: auto;
    }

    &__message-item {
      display: flex;
      align-items: flex-end;
      .ant-avatar {
        margin-right: 10px;
        min-width: 32px;
      }
      .message-content {
        background: #ededed;
        border-radius: 8px;
        padding: 5px 10px;
        min-width: 30%;
        min-height: 36px;
        white-space: pre-line;
        word-break: break-word;
        a {
          text-decoration: underline;
        }
      }
    }
    &__phone-footer {
      display: flex;
      align-items: center;
      padding: 10px 10px 0 10px;
    }
    &__input {
      flex: 1;
      background: #f2f3f4;
      border-radius: 16px;
      padding: 5px 12px;
      color: rgba(0, 0, 0, 0.2);
    }
    &__send {
      margin-left: 5px;
      color: ${({ theme }) => theme.text.mainL2};
    }
  }
`;

export default AutomatedDetailStyles;
