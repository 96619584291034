import { PREFIX_URL_API } from 'configs/localData/constant';
import { post, put, del } from './utils';

export const createCentreServiceApi = (data) =>
  post(`/centreServices`, data, {}, PREFIX_URL_API);

export const editCentreServiceApi = (id, data) =>
  put(`/centreServices/${id}`, data, {}, PREFIX_URL_API);

export const delCentreServiceApi = (id) =>
  del(`/centreServices/${id}`, null, {}, PREFIX_URL_API);

export const changeSpecialOfferApi = (id, data) =>
  put(`/centres/${id}/marketing-features`, data, {}, PREFIX_URL_API);
