import React from 'react';
import i18next from 'i18next';

import ServicesTable from './components/TableServices';
import ServicesTabStyles from './styles';
import CreateButton from './components/CreateButton';
import { ServiceModalProvider } from './Modals/context';
import EditServiceModal from './Modals/EditServiceModal';

const ServicesCentreTab = () => (
  <ServicesTabStyles>
    <ServiceModalProvider>
      <div className="header-title">
        <div className="title-name">{i18next.t('centres.services')}</div>
        <CreateButton />
      </div>
      <ServicesTable />
      <EditServiceModal />
    </ServiceModalProvider>
  </ServicesTabStyles>
);

export default ServicesCentreTab;
