import styled from 'styled-components';

const CentreTabsStyles = styled.div`
  @media only screen and (max-width: 1500px) and (min-width: 993px) {
    .pagination-table-col {
      width: 65%;
    }
    .actions-table-col {
      width: 35%;
    }
  }

  @media only screen and (max-width: 1500px) and (min-width: 1300px) {
    .row-filter-container {
      .col-filter-content {
        width: 80%;
      }
      .col-actions {
        width: 20%;
      }
    }
  }

  @media only screen and (max-width: 1300px) and (min-width: 993px) {
    .row-filter-container {
      .col-filter-content {
        width: 75%;
      }
      .col-actions {
        width: 25%;
      }
    }
  }

  .tab-child {
    padding: 15px;
    background: #fff;
    position: relative;
  }

  .div-button-action {
    display: flex;
    justify-content: flex-end;
    button {
      background: transparent;
      border: none;
      .anticon {
        font-size: 20px;
      }
      &:hover {
        transform: scale(1.1, 1.1);
      }
      &:hover > .anticon {
        color: ${({ theme }) => theme.palette.primary};
      }
    }
  }
  .div-button-edit {
    position: absolute;
    right: 10px;
  }
  .div-button-group {
    display: flex;
    justify-content: flex-end;
    .submit-button {
      margin-left: 15px;
    }
  }
`;

export default CentreTabsStyles;
