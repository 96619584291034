import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Popconfirm, Button } from 'antd';

import { ButtonWrapper } from './styles';

const ConfirmButton = ({
  record,
  title,
  disabled,
  alertText,
  confirm,
  className,
}) => {
  const handleConfirm = () => {
    confirm(record);
  };

  return (
    <Popconfirm
      placement="topLeft"
      title={i18next.t(alertText)}
      onConfirm={handleConfirm}
      okText="Yes"
      cancelText="No"
    >
      <ButtonWrapper>
        <Button disabled={disabled} className={className}>
          {i18next.t(title)}
        </Button>
      </ButtonWrapper>
    </Popconfirm>
  );
};

ConfirmButton.propTypes = {
  record: PropTypes.object,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  confirm: PropTypes.func,
  alertText: PropTypes.string,
  className: PropTypes.string,
};

ConfirmButton.defaultProps = {
  title: 'button.charge',
  confirm: () => null,
};

export default ConfirmButton;
