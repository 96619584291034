import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { centresOfUserSelectors } from 'redux/centresOfUser/selectors';
import TableCustom from 'components/common/TableCustom';

import { getQueryParamsWhenChangeTable } from 'utils/tableUtils';
import { delCentresOfUser } from 'redux/centresOfUser/actions';
import { checkLastUser, unSubcriptionCentre } from 'redux/users/actions';
import { ButtonWrapper } from 'components/RestActions/DeleteButton/styles';
import { Button, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { confirmDeleteForLastUser } from '../ProvidersOfUserList/ProvidersOfUserTable';

const CentresOfUserTable = ({ retrieveList }) => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const resourceFilter =
    useSelector(centresOfUserSelectors.getResourceData) || {};

  const loading = useSelector(centresOfUserSelectors.getLoading);

  const data = useSelector(centresOfUserSelectors.getDataArr);

  const deleteCentreUser = (centreId) => {
    dispatch(
      delCentresOfUser({
        id,
        centreId,
      }),
    );
  };

  const unSubcription = (record) => () => {
    dispatch(unSubcriptionCentre({ centreIds: [record?.id] }));
  };

  const onCancel = () => {};

  const deleteItem = (centreId, record) => {
    dispatch(
      delCentresOfUser({
        id,
        centreId,
      }),
    ).then(({ payload }) => {
      payload?.centreId &&
        confirmDeleteForLastUser(
          onCancel,
          record,
          'centreUser',
          'centreUser.confirmUnsubcription',
          'name',
          true,
          unSubcription(record),
        );
    });
  };

  const deleteCentreOfUser = (centreId, record) => {
    dispatch(checkLastUser(`centreId=${centreId}&userId=${id}`)).then(
      ({ payload }) => {
        if (!payload?.lastUser) {
          confirmDeleteForLastUser(
            deleteCentreUser,
            record,
            'centreUser',
            'centreUser.confirmDelete',
            'name',
            payload?.lastUser,
          );
        } else {
          confirmDeleteForLastUser(
            deleteItem,
            record,
            'centreUser',
            'centreUser.confirmDeleteLastUser',
            'name',
            payload?.lastUser,
          );
        }
      },
    );
  };

  const columns = [
    {
      title: i18next.t('centres.name'),
      dataIndex: 'name',
      width: '30%',
      sorter: true,
      render: (data, record) => (
        <Link
          to={`/centres/${record.id}/show/summary`}
          className="link-custom-primary"
        >
          <span className="fw-bold">{data}</span>
        </Link>
      ),
    },
    {
      title: i18next.t('centres.centreType'),
      dataIndex: 'type',
    },
    {
      title: i18next.t('centres.suburb'),
      dataIndex: 'city',
    },
    {
      title: i18next.t('centres.state'),
      dataIndex: 'state',
    },
    {
      title: '',
      dataIndex: 'id',
      width: 80,
      fixed: 'right',
      render: (id, record) => (
        <ButtonWrapper>
          <Tooltip title={i18next.t('button.delete')}>
            <Button
              icon={<DeleteOutlined />}
              onClick={() => deleteCentreOfUser(id, record)}
            />
          </Tooltip>
        </ButtonWrapper>
      ),
    },
  ];

  const onChange = (e, filters, sorter) => {
    const queryParams = getQueryParamsWhenChangeTable(e, filters, sorter);

    retrieveList({
      filter: queryParams,
      isRefresh: true,
      keepFilter: true,
    });
  };

  return (
    <TableCustom
      xScroll={800}
      onChange={onChange}
      data={data}
      columns={columns}
      loading={loading}
      isResetStyle
      pagination={{
        pageSize: resourceFilter.limit,
        current: resourceFilter.offset / resourceFilter.limit + 1 || 1,
        total: resourceFilter.total,
      }}
    />
  );
};

CentresOfUserTable.propTypes = {
  retrieveList: PropTypes.func,
};

CentresOfUserTable.defaultProps = {
  retrieveList: () => null,
};

export default CentresOfUserTable;
