import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { compose } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import reducers from './reducers';

export const history = createBrowserHistory();
const middleware = [
  // ...getDefaultMiddleware(),
  routerMiddleware(history),
];

// eslint-disable-next-line
const composedEnhancers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = configureStore({
  reducer: reducers(history),
  middleware: [...middleware, ...getDefaultMiddleware()],
});

export default store;
