import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';

import {
  getCentresOfCompany,
  getSummariesCentresOfCompany,
} from 'redux/centresOfCompany/actions';
import { resetResourceFilterCentresOfCompany } from 'redux/centresOfCompany/slice';

import SummariesCentre from 'containers/Providers/components/CentresTab/SummariesCentre';
import HeaderFilter from 'containers/Providers/components/CentresTab/HeaderFilter';
import CentresListTable from 'containers/Providers/components/CentresTab/CentresListTable';
import CentresListBoard from 'containers/Providers/components/CentresTab/CentresListBoard';

const CentresTab = () => {
  const { push } = useHistory();

  const { id: companyId } = useParams();

  const query = useSelector((state) => state.router?.location?.query);

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(query?.t || 'board');

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, keepFilter, keepOrderBy }) => {
      dispatch(
        getCentresOfCompany({
          companyId,
          data: filter,
          options: {
            keepFilter,
            keepOrderBy,
            isRefresh,
            isOrderBy: false,
          },
        }),
      );
    },
    [], // eslint-disable-line
  );

  const getSummaries = (filter) => {
    dispatch(
      getSummariesCentresOfCompany({
        companyId,
        data: filter,
      }),
    );
  };

  useEffect(() => {
    getSummaries({ filter: {} });
    return () => {
      dispatch(resetResourceFilterCentresOfCompany());
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    retrieveList({
      filter: {
        limit: 10,
        offset: 0,
      },
      keepFilter: true,
      isRefresh: true,
    });
  }, [activeTab]); // eslint-disable-line

  const onChange = (e) => {
    setActiveTab(e.target.value);
    push({
      search: `?t=${e.target.value}`,
    });
  };

  const summaries = useSelector(
    (state) => state.centresOfCompany.summaries || {},
  );

  return (
    <div>
      <SummariesCentre summaries={summaries} />
      <HeaderFilter
        activeTab={activeTab}
        retrieveList={retrieveList}
        onChange={onChange}
        brandProps={{
          resource: `companies/${companyId}/centres/brand`,
          initialFilter: { orderBy: 'name' },
        }}
        cityProps={{
          resource: `companies/${companyId}/centres/city`,
          initialFilter: { orderBy: 'city' },
        }}
      />
      <div className="container-wrapper-centre">
        {activeTab === 'list' ? (
          <CentresListTable
            retrieveList={retrieveList}
            resource="centresOfCompany"
          />
        ) : (
          <CentresListBoard
            retrieveList={retrieveList}
            resource="centresOfCompany"
          />
        )}
      </div>
    </div>
  );
};

export default CentresTab;
