import React from 'react';
import Edit from 'containers/rest/Edit';
import { PREFIX_URL } from 'configs/localData/constant';
import Form from '../components/Form';

const BrandsEdit = (props) => (
  <Edit
    {...props}
    resource="brands"
    defaultOptions={{
      prefixUrl: PREFIX_URL.superAdmin,
    }}
  >
    <Form />
  </Edit>
);

export default BrandsEdit;
