import React, { useState } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import Avatar from 'antd/lib/avatar';
import Popover from 'antd/lib/popover';
import { CloseCircleFilled } from '@ant-design/icons';

import { CalendarIcon } from 'components/common/SVGIcon';
import { formatDueDateField } from 'utils/formatFieldUtils';
import DueDateOverlay from '../DueDateOverlay';

const AddDueDate = ({ onChange, value }) => {
  const [visible, setVisible] = useState(false);

  const onRemove = () => {
    onChange(null);
  };

  return (
    <div className="add-assignee-wrapper add-due-date-wrapper">
      <Popover
        placement="bottomLeft"
        content={
          <DueDateOverlay
            closeOverlay={() => setVisible(false)}
            value={value}
            onChange={onChange}
          />
        }
        trigger="click"
        overlayStyle={{ zIndex: 1000 }}
        visible={visible}
        onVisibleChange={(e) => setVisible(e)}
      >
        <div className="add-assignee cursor-pointer">
          <div className="add-assignee__avatar-wrapper">
            <Avatar size={40} icon={<CalendarIcon />} />
          </div>
          {value ? (
            formatDueDateField(value)
          ) : (
            <div className="text-uppercase fw-600 add-assignee__no-assignee">
              {i18next.t('error.noActivityDate')}
            </div>
          )}
        </div>
      </Popover>
      {value && (
        <CloseCircleFilled
          className="add-due-date__remove-icon"
          onClick={onRemove}
        />
      )}
    </div>
  );
};

AddDueDate.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default AddDueDate;
