import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getYoutubeEmbedURL } from 'utils/tools';

const PhotoItem = ({ onPreview, url, type, subCount }) => {
  const image = useMemo(() => {
    if (type?.includes('video')) {
      const urlVideo = getYoutubeEmbedURL(url);
      return (
        <div
          className="iframe-video-wrapper"
          onClick={onPreview}
          role="presentation"
        >
          <iframe
            title={urlVideo}
            className="iframe-video"
            frameBorder="0"
            allowFullScreen="allowfullscreen"
            src={urlVideo}
          />
        </div>
      );
    }
    return (
      <img
        src={url}
        alt=""
        className="image-photo-item"
        role="presentation"
        onClick={onPreview}
      />
    );
  }, [onPreview, url, type]);

  return (
    <div className="photo-item-wrapper">
      <div className="photo-item">
        <div className="photo-item-main">
          {image}
          {subCount && (
            <div
              className="photo-overlay"
              role="presentation"
              onClick={onPreview}
            >
              <span className="pics-number">{`+ ${subCount}`}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

PhotoItem.propTypes = {
  onPreview: PropTypes.func,
  url: PropTypes.string,
  type: PropTypes.string,
  subCount: PropTypes.number,
};

PhotoItem.defaultProps = {
  onPreview: () => null,
};

export default PhotoItem;
