import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  HOLIDAY_DAYS_OF_WEEKEND,
  HOLIDAY_EXCEPT_WEEKEND,
  HOLIDAY_MON_TO_FRI,
} from 'configs/localData';
import { Row, Col, Checkbox } from 'antd';
import i18next from 'i18next';

import { responsiveColHour } from '../utils';
import CardHour from '../CardHour';

const HolidayHourForm = ({ openTime, isDifferent, isEdit }) => {
  const [different, setDifferent] = useState(isDifferent);

  useEffect(() => {
    setDifferent(isDifferent);
  }, [isDifferent]);

  const onChange = (e) => {
    setDifferent(e.target.checked);
  };

  return (
    <div className="hour-section">
      {isEdit && (
        <Checkbox
          onChange={onChange}
          checked={different}
          className="hour-section__checkbox-different"
        >
          <span className="hour-section__checkbox-different__bold">
            {i18next.t('daysOfWeek.different')}
          </span>{' '}
          <span className="hour-section__checkbox-different__light">
            {i18next.t('daysOfWeek.descriptionDifferent')}
          </span>
        </Checkbox>
      )}

      <Row gutter={[24, 24]} style={{ marginBottom: 20 }}>
        {different ? (
          HOLIDAY_EXCEPT_WEEKEND.map((obj) => (
            <Col {...responsiveColHour} key={obj.value}>
              <CardHour
                isEdit={isEdit}
                itemConst={obj}
                item={openTime?.[obj.value]}
              />
            </Col>
          ))
        ) : (
          <Col {...responsiveColHour}>
            <CardHour
              isEdit={isEdit}
              item={openTime?.[HOLIDAY_MON_TO_FRI.valueMap]}
              itemConst={HOLIDAY_MON_TO_FRI}
            />
          </Col>
        )}
        {HOLIDAY_DAYS_OF_WEEKEND.map((obj) => (
          <Col {...responsiveColHour} key={obj.value}>
            <CardHour
              isEdit={isEdit}
              itemConst={obj}
              item={openTime?.[obj.value]}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

HolidayHourForm.propTypes = {
  openTime: PropTypes.object,
  isDifferent: PropTypes.bool,
  isEdit: PropTypes.bool,
};

HolidayHourForm.defaultProps = {
  openTime: {},
};

export default HolidayHourForm;
