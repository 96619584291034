import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import { flatMap, map } from 'lodash-es';
import { withNamespaces } from 'react-i18next';
import { getCurrentUser } from 'redux/auth/actions';
import { getConfig } from 'redux/config/actions';
import Profile from 'pages/Profile';
import PrivateLayout from 'layout/PrivateLayout';
import ClientUsers from 'pages/ClientUsers';
import BusinessUsers from 'pages/BusinessUsers';
import Companies from 'pages/Companies';
import Brands from 'pages/Brands';
import Applications from 'pages/Applications';
import CRMPackages from 'pages/CRMPackages';
import Inboxes from 'pages/Inboxes';
import ChangeEmailRequests from 'pages/ChangeEmailRequests';
import ClaimCentreRequests from 'pages/ClaimCentreRequests';
import DisputeCentres from 'pages/DisputeCentres';
import CentreServices from 'pages/CentreServices';
import ServiceTypes from 'pages/ServiceTypes';
import Features from 'pages/Features';
import NQSRTypes from 'pages/NQSRTypes';
import AppSettings from 'pages/AppSettings';
import Settings from 'pages/Settings';
import Customers from 'pages/Customers';
import Centres from 'pages/Centres';
import Users from 'pages/Users';
import EDM from 'pages/EDM';
import Articles from 'pages/Articles';
import Notifications from 'pages/Notifications';
import { getAllCategories } from 'redux/categories/actions';
import BrandCompany from 'pages/Brand&Company';
import AutomatedResponsesPage from 'pages/AutomatedResponses';
import DesignEDMCreate from 'containers/EDM/DesignEDM/Create';
import DesignEDMEdit from 'containers/EDM/DesignEDM/Edit';
import DisputeReviews from 'pages/DisputeReviews';
import DashboardEvents from 'containers/Dashboard/Events';
import Providers from 'pages/Providers';
import Company from 'pages/Company';
import SearchStatistics from 'pages/SearchStatistics';
import clickThroughTracker from 'pages/ClickThroughTracker';
import JobApplicationsTracker from 'pages/JobApplicationsTracker';

const routes = [
  {
    path: '/clientUsers',
    routes: [
      {
        path: '/',
        component: ClientUsers.List,
      },
      {
        path: '/create',
        component: ClientUsers.Create,
      },
      {
        path: '/:id/edit',
        component: ClientUsers.Edit,
      },
    ],
  },

  {
    path: '/businessUsers',
    routes: [
      {
        path: '/',
        component: BusinessUsers.List,
      },
      {
        path: '/create',
        component: BusinessUsers.Create,
      },
      {
        path: '/:id/edit',
        component: BusinessUsers.Edit,
      },
    ],
  },

  {
    path: '/companies',
    routes: [
      {
        path: '/',
        component: Company.List,
      },
      {
        path: '/:id/show/:modelDetail',
        component: Company.Show,
      },
    ],
  },
  {
    path: '/providers',
    routes: [
      {
        path: '/',
        component: Providers.List,
      },
      {
        path: '/:id/show/:modelDetail',
        component: Providers.Show,
      },
    ],
  },
  {
    path: '/disputeReviewRequests',
    routes: [
      {
        path: '/',
        component: DisputeReviews.List,
      },
    ],
  },
  {
    path: '/notifications',
    routes: [
      {
        path: '/',
        component: Notifications.List,
      },
      {
        path: '/create',
        component: Notifications.Create,
      },
      {
        path: '/:id/show',
        component: Notifications.Show,
      },
      {
        path: '/:id/edit',
        component: Notifications.Edit,
      },
    ],
  },

  {
    path: '/articles',
    routes: [
      {
        path: '/',
        component: Articles.List,
      },
    ],
  },

  {
    path: '/companies',
    routes: [
      {
        path: '/create',
        component: Companies.Create,
      },
      {
        path: '/:id/edit',
        component: Companies.Edit,
      },
    ],
  },

  {
    path: '/brands',
    routes: [
      {
        path: '/',
        component: BrandCompany,
      },
      {
        path: '/create',
        component: Brands.Create,
      },
      {
        path: '/:id/edit',
        component: Brands.Edit,
      },
      {
        path: '/createOfCompany',
        component: Brands.CreateBrandOfCompany,
      },
      {
        path: '/:id/editOfCompany',
        component: Brands.EditBrandOfCompany,
      },
    ],
  },

  {
    path: '/applications',
    routes: [
      {
        path: '/',
        component: Applications.List,
      },
    ],
  },
  {
    path: '/CRMPackages',
    routes: [
      {
        path: '/',
        component: CRMPackages.List,
      },
    ],
  },
  {
    path: '/EDM/:model',
    routes: [
      {
        path: '/',
        component: EDM,
      },
      {
        path: '/design/create',
        component: DesignEDMCreate,
        exact: true,
      },
      {
        path: '/design/:key/edit',
        component: DesignEDMEdit,
      },
    ],
  },
  {
    path: '/inboxes',
    routes: [
      {
        path: '/',
        component: Inboxes.List,
      },
    ],
  },

  {
    path: '/changeEmailRequests',
    routes: [
      {
        path: '/',
        component: ChangeEmailRequests.List,
      },
      {
        path: '/:id/show',
        component: ChangeEmailRequests.Show,
      },
    ],
  },

  {
    path: '/claimCentreRequests',
    routes: [
      {
        path: '/',
        component: ClaimCentreRequests.List,
      },
      {
        path: '/:id/show',
        component: ClaimCentreRequests.Show,
      },
    ],
  },

  {
    path: '/disputeCentreRequests',
    routes: [
      {
        path: '/',
        component: DisputeCentres.List,
      },
      {
        path: '/:id/show',
        component: DisputeCentres.Show,
      },
    ],
  },
  {
    path: '/centreServices',
    routes: [
      {
        path: '/',
        component: CentreServices.List,
      },
      {
        path: '/create',
        component: CentreServices.Create,
      },
      {
        path: '/:id/edit',
        component: CentreServices.Edit,
      },
    ],
  },
  {
    path: '/customers',
    routes: [
      {
        path: '/',
        component: Customers.List,
      },
      {
        path: '/create',
        component: Customers.Create,
      },
      {
        path: '/:id/show',
        component: Customers.Show,
      },
    ],
  },
  {
    path: '/serviceTypes',
    routes: [
      {
        path: '/',
        component: ServiceTypes.List,
      },
      {
        path: '/create',
        component: ServiceTypes.Create,
      },
      {
        path: '/:id/edit',
        component: ServiceTypes.Edit,
      },
    ],
  },

  {
    path: '/features',
    routes: [
      {
        path: '/',
        component: Features.List,
      },
      {
        path: '/create',
        component: Features.Create,
      },
      {
        path: '/:id/edit',
        component: Features.Edit,
      },
    ],
  },

  {
    path: '/NQSRTypes',
    routes: [
      {
        path: '/',
        component: NQSRTypes.List,
      },
      {
        path: '/create',
        component: NQSRTypes.Create,
      },
      {
        path: '/:id/edit',
        component: NQSRTypes.Edit,
      },
    ],
    roles: ['superadmin'],
  },

  {
    path: '/appSettings',
    routes: [
      {
        path: '/',
        component: AppSettings.List,
      },
      {
        path: '/create',
        component: AppSettings.Create,
      },
      {
        path: '/:id/edit',
        component: AppSettings.Edit,
      },
    ],
    roles: ['superadmin'],
  },

  {
    path: '/centres',
    routes: [
      {
        path: '/',
        component: Centres.List,
      },
      {
        path: '/create',
        component: Centres.Create,
      },
      {
        path: '/:id/automatedResponses',
        component: AutomatedResponsesPage,
        exact: true,
      },
      {
        path: '/:id/show/:modelDetail',
        component: Centres.Show,
        exact: true,
      },
      {
        path: '/:id/show',
        component: Centres.Show,
        exact: true,
      },
    ],
    roles: ['superadmin'],
  },

  {
    path: '/users',
    routes: [
      {
        path: '/',
        component: Users.List,
      },
      {
        path: '/create',
        component: Users.Create,
      },
      {
        path: '/:id/show',
        component: Users.Show,
        exact: true,
      },
      {
        path: '/:id/show/addMoreCentres',
        component: Users.AddMoreCentres,
        exact: true,
      },
      {
        path: '/:id/show/addMoreProviders',
        component: Users.AddMoreProviders,
        exact: true,
      },
      {
        path: '/:id/show/addMoreCompanies',
        component: Users.AddMoreCompanies,
        exact: true,
      },
      {
        path: '/:modelDetail',
        component: Users.List,
      },
    ],
    roles: ['superadmin'],
  },

  {
    path: '/profile',
    component: Profile,
    exact: true,
    title: 'profile.title',
    roles: ['superadmin'],
  },
  {
    path: '/settings/:model',
    component: Settings,
    exact: true,
    title: 'settings.title',
  },
  {
    path: '/searchStatistics',
    exact: true,
    routes: [
      {
        path: '/',
        component: SearchStatistics.List,
      },
      {
        path: '/:model',
        component: SearchStatistics.Detail,
      },
    ],
  },
  {
    path: '/',
    component: DashboardEvents,
    exact: true,
    title: 'dashboard.title',
    roles: ['superadmin'],
  },
  {
    path: '/clickThroughTracker',
    routes: [
      {
        path: '/',
        component: clickThroughTracker.List,
      },
    ],
  },
  {
    path: '/JobApplicationsTracker',
    routes: [
      {
        path: '/',
        component: JobApplicationsTracker.List,
      },
    ],
  },
];

const PrivateRoute = ({ title, roles, ...rest }) => {
  const role = useSelector((state) => state.auth.role);

  return role ? <Route {...rest} /> : <Route render={null} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
  title: PropTypes.string,
  roles: PropTypes.array,
};

const wrappedRoutes = map(
  flatMap(routes, (route) => {
    if (route.routes) {
      return map(route.routes, (subRoute) => ({
        roles: route.roles,
        exact: subRoute.path === '/',
        ...subRoute,
        path: route.path + subRoute.path,
        component: withNamespaces()(subRoute.component || route.component),
      }));
    }
    return route;
  }),
  (route) => <PrivateRoute {...route} key={route.path} />,
);

const PrivateRoutes = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { role, isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (role) dispatch(getConfig());
    // eslint-disable-next-line
  }, [role]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getCurrentUser());
      dispatch(getAllCategories());
    } else {
      push('/login');
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  return (
    <PrivateLayout>
      <Switch>{wrappedRoutes}</Switch>
    </PrivateLayout>
  );
};

export default PrivateRoutes;
