import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import EmailEditor from 'react-email-editor';

import Form, { useForm } from 'antd/lib/form/Form';
import Button from 'antd/lib/button';

import { editEmailTemplate } from 'redux/EDM/actions';
import { handleApiError } from 'utils/validateUtils';
import { EDM_TAB_KEYS_CONST } from 'configs/localData/constant';

import EDMForm from './Form';
import { EMAIL_EDITOR_OPTIONS } from './constant';

const EditContent = ({ currentData }) => {
  const [form] = useForm();

  const emailEditorRef = useRef(null);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { push } = useHistory();

  const onLoad = () => {
    if (emailEditorRef?.current) {
      emailEditorRef.current.editor.loadDesign(currentData.editorDesign);
    } else {
      setTimeout(
        () =>
          emailEditorRef.current.editor &&
          emailEditorRef.current.editor.loadDesign(currentData.editorDesign),
        3000,
      );
    }
  };

  const onFinish = ({ key, ...values }) => {
    emailEditorRef.current.editor.exportHtml((data) => {
      setLoading(true);
      dispatch(
        editEmailTemplate({
          key,
          data: { ...values, value: data.html, editorDesign: data.design },
        }),
      )
        .then(({ payload }) => {
          if (payload?.id) {
            push(
              `/EDM/${
                payload?.isSystem
                  ? EDM_TAB_KEYS_CONST.system
                  : EDM_TAB_KEYS_CONST.favorites
              }`,
            );
          } else {
            handleApiError(payload, form);
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    });
  };

  useEffect(() => {
    form.setFieldsValue(currentData);
  }, []); // eslint-disable-line

  return (
    <div className="box-wrapper box-design">
      <Form form={form} onFinish={onFinish} scrollToFirstError>
        <div className="flex justify-between mb-20">
          <EDMForm isEdit />
          <Button
            className="btn-submit"
            loading={loading}
            htmlType="submit"
            type="primary"
          >
            {i18next.t('button.save')}
          </Button>
        </div>
        <EmailEditor
          ref={emailEditorRef}
          onLoad={onLoad}
          options={EMAIL_EDITOR_OPTIONS}
        />
      </Form>
    </div>
  );
};

EditContent.propTypes = {
  currentData: PropTypes.object,
};

export default EditContent;
