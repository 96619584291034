import React, { useState } from 'react';
import { DashOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Modal } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { delCategories } from 'redux/categories/actions';
import TitleTreeStyles, { MenuItemStyles } from './styles';

const { confirm } = Modal;

const TitleTree = ({ item, handleEditCategory, handleAddCategory }) => {
  const dispatch = useDispatch();

  const [visibleDropDown, setVisibleDropDown] = useState();

  function showPromiseConfirm() {
    confirm({
      title: i18next.t('categories.confirmTitleDelete'),
      content: i18next.t('categories.confirmContentDelete', {
        name: item?.name,
      }),
      onOk() {
        if (item?.id) {
          return dispatch(delCategories(item.id));
        }
        return false;
      },
      onCancel() {},
    });
  }

  const onEditCategory = (e) => {
    setVisibleDropDown(false);
    e.stopPropagation();
    handleEditCategory(item);
  };

  const onDelCategory = (e) => {
    setVisibleDropDown(false);
    e.stopPropagation();
    showPromiseConfirm();
  };

  const onAddSubCategory = (e) => {
    setVisibleDropDown(false);
    e.stopPropagation();
    handleAddCategory(item);
  };

  const menu = (
    <MenuItemStyles>
      {!item.parentId && (
        <Menu.Item key="add">
          <Button className="add-category" onClick={onAddSubCategory}>
            {i18next.t('button.addSubCategory')}
          </Button>
        </Menu.Item>
      )}
      <Menu.Item key="edit">
        <Button className="edit-category" onClick={onEditCategory}>
          {i18next.t('button.edit')}
        </Button>
      </Menu.Item>
      <Menu.Item key="del">
        <Button className="delete-category" onClick={onDelCategory}>
          {i18next.t('button.delete')}
        </Button>
      </Menu.Item>
    </MenuItemStyles>
  );

  return (
    <TitleTreeStyles className="flex-center-between">
      <span className="title-tree__name ellipsis-t">{item.title}</span>
      <div className="title-tree__action">
        <Dropdown
          overlay={menu}
          trigger={['click']}
          visible={visibleDropDown}
          onVisibleChange={(visible) => {
            setVisibleDropDown(visible);
          }}
        >
          <DashOutlined
            className="dropdown-icon"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        </Dropdown>
      </div>
    </TitleTreeStyles>
  );
};

TitleTree.propTypes = {
  item: PropTypes.object,
  handleEditCategory: PropTypes.func,
  handleAddCategory: PropTypes.func,
};

export default TitleTree;
