import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash-es';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

const CentresProviderField = ({ data, handleClickViewAll }) => {
  const onClick = () => {
    handleClickViewAll({ id: data?.id });
  };
  return isEmpty(data?.firstCentre) ? null : (
    <div>
      <Link
        to={`/centres/${data?.firstCentre?.id}/show`}
        className="d-block link-custom-primary centre-name"
      >
        {data?.firstCentre?.name}
      </Link>
      {Number(data?.totalCentres) > 1 && (
        <Button
          size="small"
          type="dashed"
          onClick={onClick}
          role="presentation"
        >
          {i18next.t('button.viewAll')}
        </Button>
      )}
    </div>
  );
};

CentresProviderField.propTypes = {
  data: PropTypes.object,
  handleClickViewAll: PropTypes.func,
};

export default CentresProviderField;
