import React from 'react';
import { Row, Col } from 'antd';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import { ACTIVE_TYPES, STATUS_SPECIAL_OFFER } from 'configs/localData';
import { getSubscriptionsArr } from 'redux/centres/selectors';
import ReferenceInput from 'containers/rest/ReferenceInput';
import RestRangePickerInput from 'components/RestInput/RestRangePickerInput';
import { formatLabelTable } from 'utils/textUtils';
import { orderBy } from 'lodash-es';
import {
  LABEL_TABLE_KEY,
  PREFIX_URL,
} from '../../../../configs/localData/constant';

const Filter = () => {
  const NQSRating = useSelector((state) => state.config.data?.NQSRType);
  const NQSRatingSorted = orderBy(NQSRating, 'sortOrder', 'desc');

  const stateOfAustralia = useSelector(
    (state) => state.config.data?.stateOfAustralia,
  );

  const subscriptionsArr = useSelector(getSubscriptionsArr);

  const appSettings = useSelector((state) => state.config?.data?.AppSetting);

  return (
    <Row gutter={16} type="flex">
      <Col lg={6} md={12} xs={24}>
        <RestInputItem
          source={['name', '$like']}
          placeholder="centres.name"
          isShowTooltip
        />
      </Col>
      <Col lg={6} md={12} xs={24}>
        <RestInputItem
          source={['adminEmail', '$like']}
          placeholder="centres.email"
          isShowTooltip
        />
      </Col>
      <Col lg={6} xs={12}>
        <ReferenceInput
          prefixUrl={PREFIX_URL.superAdmin}
          resource="companies"
          source="companyId"
          searchKey="name"
        >
          <RestSelect
            titleProp="name"
            valueProp="id"
            placeholder="centres.companyTitle"
            isFilterOption={false}
          />
        </ReferenceInput>
      </Col>
      <Col lg={6} xs={12}>
        <ReferenceInput
          prefixUrl={PREFIX_URL.superAdmin}
          source="brandId"
          resource="brands"
          searchKey="name"
        >
          <RestSelect
            titleProp="name"
            valueProp="id"
            placeholder="centres.brandTitle"
            isFilterOption={false}
          />
        </ReferenceInput>
      </Col>
      <Col lg={6} xs={12}>
        <RestSelect
          source="state"
          placeholder="centres.state"
          resourceData={stateOfAustralia || []}
          isShowTooltip
        />
      </Col>
      <Col lg={6} xs={12}>
        <RestSelect
          source="NQSROverallTypeId"
          placeholder="centres.NQSRating"
          resourceData={NQSRatingSorted || []}
          valueProp="id"
          titleProp="name"
          isShowTooltip
        />
      </Col>
      <Col lg={6} xs={12}>
        <RestSelect
          source={['outsideFilter', 'CRMPackageId']}
          placeholder="centres.subscription"
          resourceData={subscriptionsArr}
          valueProp="id"
          titleProp="name"
          isShowTooltip
        />
      </Col>
      <Col lg={6} xs={12}>
        <RestSelect
          source="isACECQA"
          placeholder="centres.acecqa"
          resourceData={ACTIVE_TYPES}
          valueProp="value"
          titleProp="text"
          formatText={(data) => i18next.t(data)}
          ruleType="boolean"
          isShowSearch={false}
          isShowTooltip
        />
      </Col>
      <Col lg={6} xs={12}>
        <RestInputItem
          source={['governmentReferenceId', '$like']}
          placeholder="centres.PRCode"
          isShowTooltip
        />
      </Col>
      <Col lg={6} xs={12}>
        <RestInputItem
          source={['governmentCentreServiceId', '$like']}
          placeholder="centres.SECode"
          isShowTooltip
        />
      </Col>
      <Col lg={6} xs={12}>
        {/* <RestSelect
          source="isActive" */}
        <ReferenceInput
          prefixUrl={PREFIX_URL.superAdmin}
          source="primaryChildCareServiceId"
          resource="primary-child-care-services"
          searchKey="name"
        >
          <RestSelect
            source="primaryChildCareServiceId"
            placeholder={formatLabelTable(
              LABEL_TABLE_KEY.childCareService,
              appSettings,
            )}
            isShowTooltip
            titleProp="name"
            valueProp="id"
            isFilterOption={false}
          />
        </ReferenceInput>
      </Col>
      <Col lg={6} xs={12}>
        <RestSelect
          source="isActive"
          placeholder="centres.isActive"
          resourceData={ACTIVE_TYPES}
          valueProp="value"
          titleProp="text"
          formatText={(data) => i18next.t(data)}
          ruleType="boolean"
          isShowTooltip
          isShowSearch={false}
        />
      </Col>
      <Col lg={6} xs={12}>
        <RestSelect
          titleProp="text"
          valueProp="value"
          source="isFeatured"
          resourceData={STATUS_SPECIAL_OFFER}
          placeholder="centres.featured"
          formatText={(text) => i18next.t(text)}
        />
      </Col>
      <Col lg={6} xs={12}>
        <RestSelect
          titleProp="text"
          valueProp="value"
          source="isSpecialOffer"
          resourceData={STATUS_SPECIAL_OFFER}
          placeholder="centres.isSpecialOffer"
          formatText={(text) => i18next.t(text)}
        />
      </Col>

      <Col lg={6} xs={12}>
        <RestRangePickerInput
          source="createdAt"
          placeholder="common.time"
          isShowTooltip
          sourceGt={['createdAt', '$gt']}
          sourceLt={['createdAt', '$lt']}
        />
      </Col>
    </Row>
  );
};

export default Filter;
