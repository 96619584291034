import { Col, Row } from 'antd';
import FilterCustom from 'components/common/FilterCustom';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestRangePickerInput from 'components/RestInput/RestRangePickerInput';
import RestSelect from 'components/RestInput/RestSelect';
import { APPLICATION_STATUS } from 'configs/localData';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { getServiceTypes } from 'redux/config/selectors';
import { formatTimeFilterSubmit } from 'utils/dataUtils';
import { LABEL_TABLE_KEY } from 'configs/localData/constant';
import { formatLabelTable } from 'utils/textUtils';

const FilterApplications = ({ retrieveList }) => {
  const serviceTypes = useSelector(getServiceTypes);
  const appSettings = useSelector((state) => state.config?.data?.AppSetting);

  const onSubmitFilter = ({ updatedAt, ...values }) => {
    retrieveList({
      filter: {
        offset: 0,
        filter: {
          ...values,
          updatedAt: formatTimeFilterSubmit(updatedAt),
        },
      },
      isRefresh: true,
    });
  };

  const onClearFilter = () => {
    retrieveList({
      filter: {
        offset: 0,
      },
      isRefresh: true,
    });
  };
  return (
    <FilterCustom
      onSubmitFilter={onSubmitFilter}
      onClearFilter={onClearFilter}
      responsiveFilter={{
        xxl: 20,
        xl: 18,
        lg: 24,
        md: 24,
        xs: 24,
      }}
      responsiveAction={{
        xxl: 4,
        xl: 6,
        lg: 24,
        md: 24,
        xs: 24,
      }}
    >
      <Row gutter={16}>
        <Col lg={5} md={12} xs={24}>
          <RestInputItem
            source="centre"
            placeholder="inboxes.searchCentre"
            isShowTooltip
          />
        </Col>
        <Col lg={5} md={12} xs={24}>
          <RestInputItem
            source="parent"
            placeholder="applications.searchAppUser"
            isShowTooltip
          />
        </Col>
        <Col lg={4} md={12} xs={24}>
          <RestSelect
            source="serviceTypeId"
            placeholder={formatLabelTable(
              LABEL_TABLE_KEY.serviceType,
              appSettings,
            )}
            isShowTooltip
            resourceData={serviceTypes}
            valueProp="id"
            titleProp="name"
          />
        </Col>
        <Col lg={5} md={12} xs={24}>
          <RestSelect
            source="status"
            placeholder="applications.applicationStatus"
            isShowTooltip
            resourceData={APPLICATION_STATUS}
            formatText={(text) => i18next.t(text)}
            valueProp="value"
            titleProp="text"
          />
        </Col>
        <Col lg={5} md={12} xs={24}>
          <RestRangePickerInput
            source="updatedAt"
            placeholder="common.time"
            isShowTooltip
            sourceGt="updatedAt.$gt"
            sourceLt="updatedAt.$lt"
          />
        </Col>
      </Row>
    </FilterCustom>
  );
};

FilterApplications.propTypes = {
  retrieveList: PropTypes.func,
};
export default FilterApplications;
