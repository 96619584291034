import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import RestShow from 'containers/rest/Show';
import DetailRequest from '../components/DetailRequest';
import GroupActions from '../components/GroupActions';
import ShowStyles from './styles';

const DisputeCentresShow = (props) => {
  const { match } = props;
  const customBreadcrumb = [
    {
      title: i18next.t('disputeCentreRequests.header'),
      path: '/disputeCentreRequests',
    },
    {
      title: `#${match.params?.id}`,
      path: `/disputeCentreRequests/${match.params?.id}/show`,
    },
  ];

  return (
    <ShowStyles>
      <RestShow
        {...props}
        resource="disputeCentreRequests"
        customBreadcrumb={customBreadcrumb}
        extraAction={<GroupActions />}
      >
        <DetailRequest />
      </RestShow>
    </ShowStyles>
  );
};

DisputeCentresShow.propTypes = {
  match: PropTypes.object,
};

DisputeCentresShow.defaultProps = {
  match: {},
};

export default DisputeCentresShow;
