import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import { useSelector } from 'react-redux';
import { tasksSelectors } from 'redux/tasks/selectors';
import { ACTIVITY_PROVIDER_TYPES } from 'configs/localData';

import { formatDueDateField } from 'utils/formatFieldUtils';
import useDataFilterActivityTask from 'hooks/useDataFilterActivityTask';

import TableCustom from 'components/common/TableCustom';
import { FilterFillIcon } from 'components/common/SVGIcon';
import { onChangeTable } from 'containers/Providers/utils';
import GroupActions from './GroupActions';

const getActivityType = (type) => {
  const rest = ACTIVITY_PROVIDER_TYPES.find((item) => item.value === type);

  return rest ? (
    <span style={{ color: rest.color }}>{i18next.t(rest.text)}</span>
  ) : (
    type
  );
};

const ActivitiesNotesTable = ({ retrieveList, resourceFilter }) => {
  const resourceData = useSelector(tasksSelectors.getDataArr);
  const loading = useSelector(tasksSelectors.getLoading);

  const { activityProviderTypeFilter, taskStatusFilter } =
    useDataFilterActivityTask();

  const columns = [
    {
      title: i18next.t('tasks.taskSubject'),
      dataIndex: 'subject',
    },
    {
      title: i18next.t('tasks.assignee'),
      dataIndex: 'assigneeUser',
      render: (data) => `${data?.firstName || ''} ${data?.lastName || ''}`,
    },
    {
      title: i18next.t('activitiesAndNotes.activityType'),
      dataIndex: 'type',
      render: getActivityType,
      filters: activityProviderTypeFilter,
      filterIcon: <FilterFillIcon />,
      width: 160,
    },
    {
      title: i18next.t('common.status'),
      dataIndex: 'statusId',
      render: (data, record) => record?.status?.name,
      filters: taskStatusFilter,
      filterIcon: <FilterFillIcon />,
      width: 160,
    },
    {
      title: i18next.t('tasks.dueDate'),
      dataIndex: 'dueDate',
      render: formatDueDateField,
      sorter: true,
      width: 130,
    },
    {
      title: '',
      dataIndex: 'id',
      width: 120,
      fixed: 'right',
      render: (id, record) => <GroupActions id={id} record={record} />,
    },
  ];

  const onChange = (e, filters, sorter) => {
    onChangeTable(e, filters, sorter, retrieveList);
  };

  return (
    <TableCustom
      isSorter
      isResetStyle
      xScroll={1000}
      onChange={onChange}
      data={resourceData}
      columns={columns}
      loading={loading}
      pagination={{
        pageSize: resourceFilter.limit,
        current: resourceFilter.offset / resourceFilter.limit + 1 || 1,
        total: resourceFilter.count,
      }}
    />
  );
};

ActivitiesNotesTable.propTypes = {
  retrieveList: PropTypes.func,
  resourceFilter: PropTypes.object,
};

export default ActivitiesNotesTable;
