import React, { createElement, useContext, useEffect } from 'react';
import {
  DEFAULT_ORDER_DROPDOWN,
  FILTER_NAME,
  I18N_PREFIX_KEY,
} from 'containers/JobApplicationsTracker/constants';
import { Col, Row } from 'antd';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { useLocation } from 'react-router';
import { getFilterFromUrl } from 'utils/tools';
import { useSelector } from 'react-redux';
import { merge } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getJobApplicationsTrackerConfigSelector } from 'redux/jobApplicationsTracker/selectors';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import { toUpperCaseFirstChar } from 'utils/dataUtils';
import ReferenceInput from 'containers/rest/ReferenceInput';
import { PREFIX_URL } from 'configs/localData/constant';
import i18next from 'i18next';
import RestRangePickerInput from 'components/RestInput/RestRangePickerInput';

const FilterForm = () => {
  const { form } = useContext(RestInputContext);
  const { search } = useLocation();
  const configData = useSelector(getJobApplicationsTrackerConfigSelector);

  const filterFields = [
    {
      componentType: RestInputItem,
      placeholder: `${I18N_PREFIX_KEY}.jobReference`,
      isShowTooltip: true,
      columnWidth: 4,
      source: FILTER_NAME.jobReference,
      allowClear: true,
    },
    {
      componentType: RestInputItem,
      placeholder: `${I18N_PREFIX_KEY}.workplace.placeholder`,
      isShowTooltip: true,
      columnWidth: 3,
      source: FILTER_NAME.workplace,
      allowClear: true,
    },
    {
      componentType: RestSelect,
      source: FILTER_NAME.workplaceType,
      placeholder: `${I18N_PREFIX_KEY}.workplace.type`,
      isShowTooltip: true,
      columnWidth: 3,
      valueProp: 'value',
      titleProp: 'name',
      formatText: (text) => toUpperCaseFirstChar(text),
    },
    {
      componentType: ReferenceInput,
      source: FILTER_NAME.company,
      columnWidth: 3,
      prefixUrl: PREFIX_URL.superAdmin,
      resource: 'companies',
      searchKey: 'name',
      initialFilter: DEFAULT_ORDER_DROPDOWN,
      children: {
        componentType: RestSelect,
        props: {
          titleProp: 'name',
          valueProp: 'id',
          placeholder: `${I18N_PREFIX_KEY}.company`,
          isFilterOption: false,
          isShowTooltip: true,
        },
      },
    },
    {
      componentType: ReferenceInput,
      source: FILTER_NAME.brand,
      columnWidth: 3,
      prefixUrl: PREFIX_URL.superAdmin,
      resource: 'brands',
      searchKey: 'name',
      initialFilter: DEFAULT_ORDER_DROPDOWN,
      children: {
        componentType: RestSelect,
        props: {
          titleProp: 'name',
          valueProp: 'id',
          placeholder: `${I18N_PREFIX_KEY}.brand`,
          isFilterOption: false,
          isShowTooltip: true,
        },
      },
    },
    {
      componentType: RestSelect,
      source: FILTER_NAME.state,
      placeholder: `${I18N_PREFIX_KEY}.state`,
      isShowTooltip: true,
      columnWidth: 4,
      valueProp: 'value',
      titleProp: 'name',
    },
    {
      componentType: RestInputItem,
      source: FILTER_NAME.jobTitle,
      placeholder: `${I18N_PREFIX_KEY}.jobTitle`,
      isShowTooltip: true,
      columnWidth: 4,
      allowClear: true,
    },
    {
      componentType: RestSelect,
      source: FILTER_NAME.jobRole,
      placeholder: `${I18N_PREFIX_KEY}.jobRole`,
      isShowTooltip: true,
      columnWidth: 4,
      valueProp: 'value',
      titleProp: 'name',
    },
    {
      componentType: RestSelect,
      source: FILTER_NAME.jobType,
      placeholder: `${I18N_PREFIX_KEY}.jobType`,
      isShowTooltip: true,
      columnWidth: 3,
      valueProp: 'value',
      titleProp: 'name',
    },
    {
      componentType: RestSelect,
      source: FILTER_NAME.jobListingStatus,
      placeholder: `${I18N_PREFIX_KEY}.jobListingStatus.title`,
      isShowTooltip: true,
      columnWidth: 3,
      valueProp: 'value',
      titleProp: 'name',
    },
    {
      componentType: RestInputItem,
      source: FILTER_NAME.applicant,
      placeholder: `${I18N_PREFIX_KEY}.applicantOrEmail.placeholder`,
      isShowTooltip: true,
      columnWidth: 3,
      allowClear: true,
    },
    {
      componentType: RestSelect,
      source: FILTER_NAME.status,
      placeholder: `${I18N_PREFIX_KEY}.applicationStatus.placeholder`,
      isShowTooltip: true,
      columnWidth: 3,
      valueProp: 'value',
      titleProp: 'name',
      formatText: (text) => i18next.t(text),
    },
    {
      componentType: RestRangePickerInput,
      placeholder: `${I18N_PREFIX_KEY}.appliedTime.placeholder`,
      isShowTooltip: true,
      allowClear: true,
      source: FILTER_NAME.appliedTime,
      sourceGt: 'appliedTime.from',
      sourceLt: 'appliedTime.to',
      columnWidth: 4,
    },
    {
      componentType: RestRangePickerInput,
      placeholder: `${I18N_PREFIX_KEY}.lastUpdated.placeholder`,
      isShowTooltip: true,
      source: FILTER_NAME.lastUpdated,
      sourceGt: 'lastUpdated.from',
      sourceLt: 'lastUpdated.to',
      columnWidth: 4,
    },
  ];

  useEffect(() => {
    const { filter } = getFilterFromUrl(search);
    const { appliedTime, lastUpdated, ...restFilter } = filter?.filter || {};
    form.setFieldsValue({
      appliedTime: Object.values(appliedTime || {}).map((item) => moment(item)),
      lastUpdated: Object.values(lastUpdated || {}).map((item) => moment(item)),
      ...restFilter,
    });
  }, [form, search]);

  const renderFilterForm = () =>
    filterFields.map((field, index) => {
      const { componentType, columnWidth, children, ...props } = field;
      const externalProps = {};
      switch (props?.source) {
        case 'state':
          externalProps.resourceData = configData?.states;
          break;
        case 'jobRole':
          externalProps.resourceData = configData?.jobRoles;
          break;
        case 'jobType':
          externalProps.resourceData = configData?.jobTypes;
          break;
        case 'jobListingStatus':
          externalProps.resourceData = configData?.jobListingStatus;
          break;
        case 'workplaceType':
          externalProps.resourceData = configData?.workplaceType;
          break;
        case 'status':
          externalProps.resourceData = configData?.applicationStatus;
          break;
        default:
          break;
      }

      if (componentType) {
        return (
          <Col lg={columnWidth} md={12} xs={12} key={index}>
            {createElement(
              componentType,
              merge(props, externalProps),
              children &&
                createElement(children.componentType, children?.props),
            )}
          </Col>
        );
      }
      return null;
    });

  return <Row gutter={16}>{renderFilterForm()}</Row>;
};

FilterForm.propTypes = {
  source: PropTypes.string,
};

export default FilterForm;
