import { SearchOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import RestInputItem from 'components/RestInput/RestInputItem';
import React from 'react';

const ProviderFilterForm = () => (
  <Row gutter={16} type="flex">
    <Col md={12} xs={12}>
      <RestInputItem
        source={['name', '$like']}
        placeholder="input.searchProvider"
        isShowTooltip
        inputProps={{ prefix: <SearchOutlined /> }}
      />
    </Col>
    <Col md={12} xs={12}>
      <RestInputItem
        source={['governmentReferenceId', '$like']}
        placeholder="input.PRCode"
        isShowTooltip
        inputProps={{ prefix: <SearchOutlined /> }}
      />
    </Col>
  </Row>
);

export default ProviderFilterForm;
