import React, { useState } from 'react';
import i18next from 'i18next';
import { Form } from 'antd';
import { useDispatch } from 'react-redux';
import { editCentreService } from 'redux/centres/actions';
import { differenceTwoObj } from 'utils/tools';
import { isEmpty } from 'lodash-es';
import ModalCustom from 'components/common/ModalCustom';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import ServiceForm from './ServiceForm';
import { useServiceModal } from './context';

const EditServiceModal = () => {
  const [form] = Form.useForm();

  const { validateFields, resetFields } = form;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { visibleEditModal, record, toggleAndSetRecord } = useServiceModal();

  const onOk = () => {
    validateFields().then(async (values) => {
      const rest = differenceTwoObj(values, record);

      const { roomName, ...restData } = rest;

      if (!isEmpty(rest) && record.id) {
        setLoading(true);
        const response = await dispatch(
          editCentreService({
            data: {
              id: record.id,
              ...(typeof roomName !== 'undefined' && {
                roomName: roomName?.trim() || null,
              }),
              ...restData,
            },
          }),
        );
        setLoading(false);
        if (response?.payload?.data?.id) handleCancel();
      } else {
        handleCancel();
      }
    });
  };

  const handleCancel = () => {
    toggleAndSetRecord({});
    setTimeout(() => {
      resetFields();
    }, 300);
  };

  return (
    <ModalCustom
      title={i18next.t('centreServices.editPage')}
      visible={visibleEditModal}
      onCancel={handleCancel}
      onOk={onOk}
      loading={loading}
    >
      <Form form={form} layout="vertical">
        <RestInputContext.Provider value={{ form, record }}>
          <ServiceForm isEdit />
        </RestInputContext.Provider>
      </Form>
    </ModalCustom>
  );
};

export default EditServiceModal;
