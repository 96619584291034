import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FileItemView from './FileItemView';
import ListFileMessageStyles, { ModalViewStyles } from './styles';

const ListFileMessage = ({ files }) => {
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  const onPreviewUI = (file) => {
    setIsPreviewVisible(true);
    setPreviewImage(file?.url);
  };

  const handleCancel = () => {
    setIsPreviewVisible(false);
  };

  return (
    <ListFileMessageStyles className="list-files-container">
      <div className="list-file-view">
        {files?.map((itemFile, index) => (
          <FileItemView
            key={String(index)}
            item={itemFile}
            onPreviewUI={onPreviewUI}
          />
        ))}
      </div>
      <ModalViewStyles
        visible={isPreviewVisible}
        footer={null}
        onCancel={handleCancel}
        width={800}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </ModalViewStyles>
    </ListFileMessageStyles>
  );
};

ListFileMessage.propTypes = {
  files: PropTypes.array,
};

export default ListFileMessage;
