import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import ItemInfoStyles from './styles';

const InfoItem = ({ IconCPN, value, iconProps, className }) => (
  <ItemInfoStyles className={`info-item-common ${className || ''}`}>
    <IconCPN {...iconProps} />
    <span className="info-item-common__value">
      {value || i18next.t('error.waitingUpdate')}
    </span>
  </ItemInfoStyles>
);

InfoItem.propTypes = {
  IconCPN: PropTypes.any,
  value: PropTypes.any,
  iconProps: PropTypes.object,
  className: PropTypes.string,
};

export default InfoItem;
