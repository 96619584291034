import React, { useCallback } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { formatMoney } from 'utils/tools';
import CardSection from '../CardSection';

import { SummaryDetailCardStyles } from './styles';

const SummaryDetailCard = ({ title, data }) => {
  const formatValue = useCallback(({ value, isPerAnnum }) => {
    if (isPerAnnum)
      return `${i18next.t('currencyUnit.sign')}${formatMoney(
        value,
      )} ${i18next.t('common.perAnnum')}`;

    return formatMoney(value);
  }, []);

  return (
    <CardSection title={title}>
      <SummaryDetailCardStyles>
        <Row className="content-info-card" gutter={[10, 20]}>
          {data?.map((item, index) => (
            <React.Fragment key={String(index)}>
              <Col span={16} className="col-title-item">
                {item.IconComponent && <item.IconComponent />}
                <span className="text-name">{i18next.t(item.title)}</span>
              </Col>
              <Col span={8} className="col-value-item">
                {formatValue({
                  value: item.value,
                  isPerAnnum: item.isPerAnnum,
                })}
              </Col>
            </React.Fragment>
          ))}
        </Row>
      </SummaryDetailCardStyles>
    </CardSection>
  );
};

SummaryDetailCard.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
};

export default SummaryDetailCard;
