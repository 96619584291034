import React from 'react';
import { Form, TimePicker } from 'antd';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import { formatHour, formatHourEdit, FORMAT } from './utils';

const HourItem = ({ isEdit, time, source, placeholder }) =>
  isEdit ? (
    <Form.Item name={source} initialValue={formatHourEdit(time)}>
      <TimePicker
        minuteStep={15}
        format={FORMAT}
        placeholder={i18next.t(placeholder)}
      />
    </Form.Item>
  ) : (
    <div className="value-item">{formatHour(time)}</div>
  );

HourItem.propTypes = {
  isEdit: PropTypes.bool,
  time: PropTypes.any,
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  placeholder: PropTypes.string,
};
export default HourItem;
