import React, { useCallback } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import {
  CloseCircleOutlined,
  SwapOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { CENTRE_STATUS_VALUE } from 'configs/localData';
import { editDisputeCentres } from 'redux/disputeCentres/actions';

import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import ConfirmButtonIcon from 'components/RestActions/ConfirmButtonIcon';
import i18next from 'i18next';
import Filter from '../components/Filter';
import useFormatFunc from './useFormat';

const DisputeCentresList = (props) => {
  const dispatch = useDispatch();

  const handleConfirm = useCallback(
    (record = {}, type) => {
      dispatch(
        editDisputeCentres({
          data: { id: record.id, status: CENTRE_STATUS_VALUE[type] },
          options: { successDescription: i18next.t('success.description') },
        }),
      );
    },
    [dispatch],
  );

  const checkVisibleAction = useCallback((record = {}, type) => {
    if (type === 'inProcessing')
      return record.status === CENTRE_STATUS_VALUE.pending;
    return record.status === CENTRE_STATUS_VALUE.inProcessing;
  }, []);

  const useFormat = useFormatFunc();

  const formatFilter = useCallback(
    ({ createdAt, ...data }) => ({
      ...data,
      createdAt: createdAt
        ? {
            $gt: createdAt[0]
              ? moment(createdAt[0]).startOf('day').toISOString()
              : undefined,
            $lt: createdAt[1]
              ? moment(createdAt[1]).endOf('day').toISOString()
              : undefined,
          }
        : undefined,
    }),
    [],
  );

  return (
    <List
      {...props}
      resource="disputeCentreRequests"
      hasCreate={false}
      hasSearch={false}
      filter={<Filter format={formatFilter} />}
      initialFilter={{ orderBy: '-createdAt' }}
      redirects={{
        edit: 'screen',
      }}
    >
      <RestFieldItem
        source="name"
        header="centreRequests.centreName"
        format={useFormat.formatCentre}
        width="20%"
      />
      <RestFieldItem
        source="centre.claimStatus"
        header="centres.centreStatus"
        format={useFormat.formatCentreStatus}
      />
      <RestFieldItem
        source="userId"
        header="centreRequests.sender"
        format={useFormat.formatUser}
        width="20%"
      />
      <RestFieldItem
        source="sender.phoneNumber"
        header="centreRequests.phone"
      />
      <RestFieldItem
        source="status"
        header="centreRequests.status"
        format={useFormat.formatStatus}
      />
      {/* <RestFieldItem
        source="reason"
        header="centreRequests.reason"
        width="10%"
      /> */}
      {/* <RestFieldItem
        source="message"
        header="centreRequests.message"
        format={useFormat.formatMessage}
        width="15%"
      /> */}
      <RestFieldItem
        source="createdAt"
        header="centreRequests.time"
        format={useFormat.formatTime}
      />
      <ActionGroup
        widthAction={140}
        elementProps={{ style: { justifyContent: 'flex-end' } }}
      >
        <ConfirmButtonIcon
          title="button.process"
          alertText="disputeCentreRequests.alertProcess"
          confirm={(record) => handleConfirm(record, 'inProcessing')}
          checkVisibleButton={(record) =>
            checkVisibleAction(record, 'inProcessing')
          }
          IconComponent={SwapOutlined}
          iconProps={{ style: { color: 'orange', fontSize: 25 } }}
        />
        <ConfirmButtonIcon
          IconComponent={CloseCircleOutlined}
          title="button.decline"
          alertText="disputeCentreRequests.alertDecline"
          iconProps={{ style: { color: 'red', fontSize: 25 } }}
          confirm={(record) => handleConfirm(record, 'declined')}
          checkVisibleButton={(record) =>
            checkVisibleAction(record, 'declined')
          }
        />
        <ConfirmButtonIcon
          IconComponent={CheckCircleOutlined}
          title="button.approve"
          alertText="disputeCentreRequests.alertApprove"
          iconProps={{ style: { color: 'green', fontSize: 25 } }}
          confirm={(record) => handleConfirm(record, 'approved')}
          checkVisibleButton={(record) =>
            checkVisibleAction(record, 'approved')
          }
        />
        <EditButton
          source="show"
          isView
          iconProps={{ style: { fontSize: 25 } }}
        />
      </ActionGroup>
    </List>
  );
};

export default DisputeCentresList;
