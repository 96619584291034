import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const initialValue = {
  message: null,
  setMessage: (value) => value,
};

const AutomatedMessageContext = createContext(initialValue);

export const AutomatedMessageProvider = ({ children }) => {
  const [message, setMessage] = useState();
  const value = {
    message,
    setMessage,
  };

  return (
    <AutomatedMessageContext.Provider value={value}>
      {children}
    </AutomatedMessageContext.Provider>
  );
};

AutomatedMessageProvider.propTypes = {
  children: PropTypes.node,
};

export const useAutomatedMessage = () => {
  const { message, setMessage } = useContext(AutomatedMessageContext);

  return {
    message,
    setMessage,
  };
};
