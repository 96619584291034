import React from 'react';
import PropTypes from 'prop-types';
import { Empty } from 'antd';

const EmptyData = ({ description }) => <Empty description={description} />;

EmptyData.propTypes = {
  description: PropTypes.string,
};
export default EmptyData;
