import React, { useState } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import { Link, useLocation } from 'react-router-dom';
import { Badge, Layout } from 'antd';
import { useSelector } from 'react-redux';

import { BellOutlined, HomeOutlined, UserOutlined } from '@ant-design/icons';
import PrivateLayoutWrapper, { EnviromentStyles } from './styles';
import Header from '../../containers/Header';
import SideBar from '../../containers/SideBar';

const { Content, Footer } = Layout;

const PrivateLayout = ({ children }) => {
  const notSeen = useSelector((state) => state.notification?.notSeen);
  const { pathname } = useLocation();

  const mobileTabs = [
    {
      key: 'home',
      text: 'Home',
      url: '/',
      icon: HomeOutlined,
    },
    {
      key: 'notifications',
      text: 'Notifications',
      url: '/notifications',
      icon: BellOutlined,
      count: notSeen,
    },
    {
      key: 'profile',
      text: 'Profile',
      url: '/profile',
      icon: UserOutlined,
    },
  ];

  const env = window?.location.hostname.split('.')?.[0];

  let checkOnEnvironment;
  if (env?.includes('uat')) {
    checkOnEnvironment = {
      message: I18n.t('environmental.uat'),
      background: '#36BF57',
    };
  } else if (env?.includes('sit')) {
    checkOnEnvironment = {
      message: I18n.t('environmental.sit'),
      background: '#FB8429',
    };
  } else {
    checkOnEnvironment = {
      message: I18n.t('environmental.production'),
      background: '#E62828',
    };
  }

  const [collapsed, setCollapsed] = useState(window.innerWidth <= 767);
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <PrivateLayoutWrapper className="windowView">
      <input
        onChange={() => {}}
        id="collapsedTracker"
        type="checkbox"
        checked={!collapsed}
      />
      <label
        role="presentation"
        htmlFor="collapsedTracker"
        className="overlay"
        onClick={toggle}
      />
      <SideBar toggle={toggle} collapsed={collapsed} />
      <Layout className="mainView">
        <EnviromentStyles
          className="flex-center-between"
          style={{
            backgroundColor: checkOnEnvironment.background,
          }}
        >
          {checkOnEnvironment.message}
        </EnviromentStyles>
        <Header onToggle={toggle} collapsed={collapsed} />
        <Content className="container">
          <div className="content">{children}</div>
        </Content>
        <Footer
          className="footer"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div>{I18n.t('appInfo.name')}</div>
          <div>
            <span>{I18n.t('appInfo.footer')}</span>
          </div>
        </Footer>
        <Footer className="footerMobile">
          {mobileTabs.map((tab) => (
            <Link
              to={tab.url}
              key={tab.key}
              className={tab.url === pathname ? 'active' : 'not-active'}
            >
              {tab.count ? (
                <Badge count={tab.count}>
                  <tab.icon className="tabIcon" />
                </Badge>
              ) : (
                <tab.icon className="tabIcon" />
              )}
            </Link>
          ))}
        </Footer>
      </Layout>
    </PrivateLayoutWrapper>
  );
};

PrivateLayout.propTypes = {
  children: PropTypes.any,
};

export default PrivateLayout;
