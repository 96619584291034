import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { editUsers } from 'redux/users/actions';
import { handleApiError } from 'utils/validateUtils';
import { useParams } from 'react-router';

import ModalCustom from 'components/common/ModalCustom';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import FormNormalStyles from './styles';
import GeneralUserForm from '../Form/GeneralUserForm';

const UserEditModal = forwardRef(({ record }, ref) => {
  const [form] = Form.useForm();

  const { id } = useParams();

  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();

  const { validateFields, resetFields } = form;

  const [loading, setLoading] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      open: () => setVisible(true),
    }),
    [],
  );

  const onOk = () => {
    validateFields()
      .then((values) => {
        setLoading(true);
        dispatch(
          editUsers({
            data: {
              id,
              ...values,
            },
            options: { isBack: false },
          }),
        )
          .then(({ payload: { data } }) => {
            handleApiError(data, form);
            setLoading(false);
            if (data.id) {
              handleCancel();
            }
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch(() => null);
  };

  const handleCancel = () => {
    setVisible(false);
    resetFields();
  };

  return (
    <ModalCustom
      title={i18next.t('users.editPage')}
      onCancel={handleCancel}
      onOk={onOk}
      loading={loading}
      visible={visible}
    >
      <FormNormalStyles>
        <Form form={form} layout="vertical" scrollToFirstError>
          <RestInputContext.Provider value={{ form, record }}>
            <GeneralUserForm isEdit />
          </RestInputContext.Provider>
        </Form>
      </FormNormalStyles>
    </ModalCustom>
  );
});

UserEditModal.propTypes = {
  record: PropTypes.object,
};

export default UserEditModal;
