import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Upload, notification } from 'antd';
import { xor, isEmpty, findIndex, takeRight, isPlainObject } from 'lodash-es';
import i18next from 'i18next';
import { PlusOutlined } from '@ant-design/icons';
import { getRecordData } from 'utils/tools';
import { useDispatch } from 'react-redux';
import CRUDActions from 'redux/crudActions';
import { logoutSuccess } from 'redux/auth/slice';
import { resizeImageFile } from 'utils/fileUtils';
import FormItem from '../FormItem';
import { FormMultiUploadWrapper, ModalViewStyles } from './styles';
import UploadImage from '../../../assets/images/upload.png';
import UploadImageItem from './UploadImageItem';
import { getUrl, uploadMedia } from '../../../api/uploadMedia';

const { Dragger } = Upload;

export const RestUpload = ({
  defaultSourceKey,
  setFileName,
  required,
  handleSubmit,
  isSetFeature,
  width,
  height,
  objectFit,
  setIsDisabledButtonSubmitCustom,
  ...props
}) => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const [defaultImage, setDefaultImage] = useState(null);
  const [indexFeatureImage, setIndexFeatureImage] = useState(0);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  useEffect(() => {
    if (!isEmpty(props.record) && isEmpty(fileList)) {
      const restValue = props.record[props.source];
      if (isSetFeature) {
        const indexFeature = findIndex(restValue, ['featured', true]);
        indexFeature !== -1 && setIndexFeatureImage(indexFeature);
      }
      setFileList(makeFileList(restValue));
    }
    // eslint-disable-next-line
  }, [props.record]);
  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const handlePreview = (file) => {
    setPreviewVisible(true);
    setPreviewImage(file.url || file.thumbUrl);
  };

  const deleteImage = (item, isFeatureDel) => {
    if (isFeatureDel) setIndexFeatureImage(0);
    const results = xor(fileList, [item]);
    setFileList(results);
    results.length === 0 && setDisabled(false);
    props.form &&
      props.form.setFieldsValue({
        [props.source]: results.map((e, index) => {
          const item = {
            key: e.name,
            url: e.response || e.url,
            featured: false,
          };
          if ((isFeatureDel && index === 0) || indexFeatureImage === index) {
            return { ...item, featured: true };
          }
          return item;
        }),
      });
  };
  const onMouseEnter = () => {
    setDisabled(true);
  };

  const onMouseLeave = () => {
    setDisabled(false);
  };

  const onSetDefault = useCallback(
    (item) => {
      defaultSourceKey && setDefaultImage(item.url || item.response);
      defaultSourceKey &&
        props.form &&
        props.form.setFieldsValue({
          [defaultSourceKey]: item.url || item.response,
        });
    },
    [defaultSourceKey, props.form],
  );

  const handleChange = (e) => setFileList(e.fileList);
  const uploadButton =
    fileList && fileList.length === 0 ? (
      <div className="uploadArea">
        <PlusOutlined />
        <div className="ant-upload-text">
          {'Upload '}
          {props.placeholder && i18next.t(props.placeholder)}
        </div>
      </div>
    ) : null;

  useEffect(
    () => {
      setFileList(
        props.defaultValue || getRecordData(props.record, props.source)
          ? makeFileList(
              props.defaultValue || getRecordData(props.record, props.source),
            )
          : [],
      );
      defaultSourceKey &&
        onSetDefault({ url: getRecordData(props.record, defaultSourceKey) });
      setPreviewImage(
        makeFileList(
          props.defaultValue || getRecordData(props.record, props.source),
        ),
      );
    },
    // eslint-disable-next-line
    [],
  );

  const customRequest = async ({ onSuccess, onError, file }) => {
    const compressedFile = await resizeImageFile(file);

    if (compressedFile) {
      try {
        const responseS3 = await getUrl(
          compressedFile.name,
          compressedFile.type,
        );

        const response = await uploadMedia(
          responseS3.uploadUrl,
          compressedFile,
        );
        if (response?.status === 200) {
          onSuccess(responseS3.url, compressedFile);
        } else {
          onError(null, { status: 'failed' });
        }
      } catch (error) {
        onError(null, { status: 'done' });
        if (error.code === 401) {
          dispatch(logoutSuccess());
          notification.error({
            message: i18next.t('error.title'),
            description: i18next.t('error.error401'),
            duration: 2,
            position: 'tr',
          });
        } else
          notification.error({
            message: i18next.t('error.title'),
            description: i18next.t('error.description'),
            position: 'tr',
            duration: 2,
          });
      }
    } else onError(null, { status: 'done' });
  };

  const onSetFeature = useCallback(
    (item, index) => {
      setIndexFeatureImage(index);
      isSetFeature &&
        props.form &&
        props.form.setFieldsValue({
          [props.source]: fileList?.map((e, idx) => {
            const itemFile = {
              key: e.name,
              url: e.response || e.url,
              featured: false,
            };
            if (index === idx) return { ...itemFile, featured: true };
            return itemFile;
          }),
        });
    },
    [isSetFeature, props.form, props.source, fileList],
  );

  const setFieldAfterUploadChange = (results) => {
    const formattedData = results?.map((data, index) => {
      const item = {
        key: data.name,
        url: data.response || data.url,
        featured: false,
      };
      if (indexFeatureImage === index) return { ...item, featured: true };
      return item;
    });

    props.onChange && props.onChange(formattedData);
    if (props.form) {
      props.form.setFieldsValue({
        [props.source]: props.multiple ? formattedData : formattedData[0],
      });
    }
    if (defaultSourceKey && formattedData.indexOf(defaultImage) === -1) {
      onSetDefault(results[0]);
    }
  };

  const onChangeUpload = (e) => {
    if (fileList.length === 0) {
      onSetDefault(e.fileList[0]);
    }

    isDisableButtonSubmit(e.fileList);

    if (!props.multiple) {
      setFileName(e.fileList[e.fileList.length - 1]?.name);
    }
    if (!props.multiple && e.fileList?.length > 1) {
      const results = takeRight(e.fileList);
      setFileList(results);
      setFieldAfterUploadChange(results);
    } else {
      handleChange({ fileList: e.fileList });
      setFieldAfterUploadChange(e.fileList);
    }
  };

  const isDisableButtonSubmit = (newFileList) => {
    let isDisabled =
      newFileList &&
      newFileList.length >= 0 &&
      newFileList?.filter((i) => {
        if (i.url !== undefined) return false;
        if (props.multiple && i.response) return i.status === 'error';
        return i.response === undefined;
      }).length > 0;

    if (!props.multiple && !isDisabled)
      isDisabled = newFileList[newFileList.length - 1]?.status === 'error';

    setIsDisabledButtonSubmitCustom &&
      dispatch(setIsDisabledButtonSubmitCustom(isDisabled));

    props.resource &&
      dispatch(
        CRUDActions[props.resource].setIsDisabledButtonSubmit(isDisabled),
      );
  };

  return (
    <FormMultiUploadWrapper
      className="multi-upload-wrapper"
      width={width}
      height={height}
      objectFit={objectFit}
    >
      {props.form && defaultSourceKey && (
        <FormItem
          className="control-form-item"
          {...props}
          required={required}
          source={defaultSourceKey}
          name={defaultSourceKey}
          defaultValue={
            props.defaultValue || getRecordData(props.record, defaultSourceKey)
          }
        />
      )}
      {props.form && (
        <FormItem
          className="control-form-item"
          {...props}
          required={required}
          name={props.source}
          defaultValue={
            props.defaultValue || getRecordData(props.record, props.source)
          }
        />
      )}
      <Dragger
        customRequest={customRequest}
        accept="image/*"
        multiple={props.multiple}
        disabled={props.disabled || disabled}
        listType="picture-card"
        fileList={fileList}
        showUploadList={false}
        onPreview={handlePreview}
        onChange={onChangeUpload}
      >
        <div className="selectedImage">
          {fileList.map((img, index) => (
            <UploadImageItem
              key={String(index)}
              index={index}
              defaultSourceKey={defaultSourceKey}
              onSetDefault={onSetDefault}
              onSetFeature={onSetFeature}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              deleteImage={deleteImage}
              onPreviewUI={handlePreview}
              item={img}
              alt="upload.png"
              isDefault={
                defaultImage &&
                (defaultImage === img.url || img.response === defaultImage)
              }
              isSetFeature={isSetFeature}
              isFeature={index === indexFeatureImage}
            />
          ))}
          {uploadButton}
          <div className="overlayImage">
            <img src={UploadImage} alt="upload.png" />
          </div>
        </div>
      </Dragger>
      <ModalViewStyles
        visible={previewVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </ModalViewStyles>
    </FormMultiUploadWrapper>
  );
};

const makeObjFile = (value) => ({
  uid: value,
  name: value,
  status: 'done',
  url: value,
  id: value,
});

const makeFileList = (values) => {
  if (isEmpty(values)) return [];

  if (Array.isArray(values))
    return values.map((value) =>
      value && value.url ? { uid: value.url, ...value } : makeObjFile(value),
    );

  if (isPlainObject(values))
    return [
      {
        ...makeObjFile(values.url),
        ...values,
      },
    ];

  return [makeObjFile(values)];
};

RestUpload.propTypes = {
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  record: PropTypes.object,
  defaultValue: PropTypes.any,
  multiple: PropTypes.bool,
  form: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  defaultSourceKey: PropTypes.string,
  setFileName: PropTypes.func,
  resource: PropTypes.string,
  required: PropTypes.bool,
  handleSubmit: PropTypes.func,
  isSetFeature: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string,
  objectFit: PropTypes.string,
  setIsDisabledButtonSubmitCustom: PropTypes.func,
};

RestUpload.defaultProps = {
  multiple: true,
  setFileName: () => null,
  isSetFeature: false,
  height: '100px',
  width: '100px',
  objectFit: 'cover',
};

export default RestUpload;
