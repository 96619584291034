import { PREFIX_URL_API } from 'configs/localData/constant';
import { get, put } from './utils';

export const getAllAutomatedResponsesApi = (centreId) =>
  get(`/centres/${centreId}/automated-responses`, null, {}, PREFIX_URL_API);

export const updateAppSettingsApi = (centreId, key, data) =>
  put(`/centres/${centreId}/app-settings/${key}`, data, {}, PREFIX_URL_API);

export const getByKeyAppSettingsOfCentreApi = (centreId, key) =>
  get(
    `/centres/${centreId}/centre-app-settings/${key}`,
    null,
    {},
    PREFIX_URL_API,
  );

export const updateAppSettingsOfCentreApi = (centreId, key, data) =>
  put(
    `/centres/${centreId}/centre-app-settings/${key}`,
    data,
    {},
    PREFIX_URL_API,
  );

export const updateLabelTableApi = (key, data) =>
  put(`/appSettings/keys/${key}`, data);
