import styled from 'styled-components';

const LegendStyles = styled.div`
  display: flex;
  justify-content: flex-end;
  .legend-item {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .legend-item:hover {
    cursor: pointer;
  }
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
  }
  .text-legend {
    line-height: normal;
  }
`;

export default LegendStyles;

export const LegendColStyles = styled.div`
  .legend-item {
    display: flex;
    align-items: center;
  }
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
  }
  .text-legend {
    line-height: normal;
  }

  .legend-header {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #ffa14e;
    margin-bottom: 20px;
    &__value {
      margin-left: 10px;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
    }
  }
`;
