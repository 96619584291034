import React from 'react';
import { Rate } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { formatDateFromNow } from 'utils/textUtils';

import logoKindi from 'assets/images/kindi-logo.png';
import logoGoogle from 'assets/images/google-logo.png';
import { HeaderCommentStyles } from './styles';

const HeaderComment = ({
  authorName,
  rating,
  createdAt,
  isInternalRating,
  isShowRate,
  isShowLogo,
}) => (
  <HeaderCommentStyles className="header-comment-item">
    <div className="title-header">
      <div className="name-rating">
        <span className="name-user">
          {authorName || i18next.t('error.waitingUpdate')}
        </span>
        {isShowRate && <Rate disabled allowHalf value={rating} />}
      </div>
      {isShowLogo && (
        <img
          className="image-logo"
          src={isInternalRating ? logoKindi : logoGoogle}
          alt=""
        />
      )}
    </div>

    <div className="time-comment">{formatDateFromNow(createdAt)}</div>
  </HeaderCommentStyles>
);

HeaderComment.propTypes = {
  authorName: PropTypes.string,
  rating: PropTypes.number,
  createdAt: PropTypes.string,
  isInternalRating: PropTypes.bool,
  isShowRate: PropTypes.bool,
  isShowLogo: PropTypes.bool,
};

HeaderComment.defaultProps = {
  isShowRate: true,
  isShowLogo: true,
};

export default HeaderComment;
