import styled from 'styled-components';

export const ProvidersModalStyles = styled.div`
  max-height: 500px;
  overflow: auto;
  .provider-item {
    padding: 15px 10px;
    border-radius: 8px;
    &:hover {
      background: ${({ theme }) => theme.background.clearBtn};
    }
  }
`;
