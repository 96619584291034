import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { getBrandsOfCompany } from 'redux/brandsOfCompany/actions';
import BrandsTable from '../components/BrandsTable';
import HeaderFilter from './HeaderFilter';

const BrandsOfCompanyList = () => {
  const { id: companyId } = useParams();
  const dispatch = useDispatch();

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, keepOrderBy }) => {
      dispatch(
        getBrandsOfCompany({
          id: companyId,
          data: filter,
          options: {
            isRefresh,
            keepOrderBy,
            isOrderBy: false,
          },
        }),
      );
    },
    [companyId, dispatch],
  );

  useEffect(() => {
    retrieveList({
      filter: {
        limit: 10,
        offset: 0,
      },
      isRefresh: true,
    });
  }, [retrieveList]);

  return (
    <div>
      <HeaderFilter retrieveList={retrieveList} />
      <BrandsTable retrieveList={retrieveList} resource="brandsOfCompany" />
    </div>
  );
};

export default BrandsOfCompanyList;
