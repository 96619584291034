import styled from 'styled-components';

const ListStyles = styled.div`
  .hidden-tab {
    display: none;
  }
`;

export default ListStyles;

export const HeaderFilterStyles = styled.div`
  .title-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
  }
`;
