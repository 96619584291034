import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';

import { CENTRE_STATUS_VALUE } from 'configs/localData';
import { editDisputeCentres } from 'redux/disputeCentres/actions';
import ConfirmButton from 'components/RestActions/ConfirmButton';

import GroupActionsStyles from './styles';

const GroupActions = () => {
  const record =
    useSelector((state) => state.disputeCentreRequests.currentData) || {};

  const dispatch = useDispatch();

  const handleConfirm = (type) => {
    dispatch(
      editDisputeCentres({
        data: { id: record.id, status: CENTRE_STATUS_VALUE[type] },
        options: { successDescription: i18next.t('success.description') },
      }),
    );
  };

  const isShowProcessBtn = useMemo(
    () => record.status === CENTRE_STATUS_VALUE.pending,
    [record.status],
  );

  const isShowDeclineApproveBtn = useMemo(
    () => record.status === CENTRE_STATUS_VALUE.inProcessing,
    [record.status],
  );

  return (
    <GroupActionsStyles>
      {isShowProcessBtn && (
        <ConfirmButton
          title="button.process"
          alertText="disputeCentreRequests.alertProcess"
          confirm={() => handleConfirm('inProcessing')}
          isShowText
          isShowTooltip={false}
          className="process-btn"
        />
      )}
      {isShowDeclineApproveBtn && (
        <>
          <ConfirmButton
            title="button.decline"
            alertText="disputeCentreRequests.alertDecline"
            confirm={() => handleConfirm('declined')}
            isShowText
            isShowTooltip={false}
            className="decline-btn"
          />
          <ConfirmButton
            title="button.approve"
            alertText="disputeCentreRequests.alertApprove"
            confirm={() => handleConfirm('approved')}
            isShowText
            isShowTooltip={false}
            className="approve-btn"
          />
        </>
      )}
    </GroupActionsStyles>
  );
};

export default GroupActions;
