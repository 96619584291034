import React, { useRef, useEffect } from 'react';
import i18next from 'i18next';
import { Col, Row, Space, Tabs, Button } from 'antd';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';

import PageTitle from 'components/common/PageTitle';
import Breadcrumb from 'components/common/Breadcrumb';

import { clearCurrentOptionTemplate } from 'redux/EDM/actions';
import SendWrapper from './components/SendWrapper';
import FavoritesTab from './components/FavoritesTab';
import CreateModal from './CustomHtml/CreateModal';
import { EmailTemplateProvider } from './context';

const { TabPane } = Tabs;

const EDM = () => {
  const dispatch = useDispatch();

  const { push } = useHistory();

  const { model } = useParams();

  const createModalRef = useRef();

  const BREADCRUMB = [
    {
      path: '/edm',
      title: i18next.t('marketings.title'),
    },
    {
      title: i18next.t('marketings.emailAndEDMCampaigns'),
    },
  ];

  useEffect(
    () => () => {
      dispatch(clearCurrentOptionTemplate());
    },
    [dispatch],
  );

  const openCreateDesignEDM = () => {
    push(`/EDM/${model}/design/create`);
  };

  const openCreateModal = () => {
    createModalRef.current && createModalRef.current.open();
  };

  const operations = (
    <div>
      <Space>
        <Button type="primary" onClick={openCreateModal}>
          {i18next.t('button.customHtml')}
        </Button>
        <Button className="btn-second-primary" onClick={openCreateDesignEDM}>
          {i18next.t('button.design')}
        </Button>
      </Space>
    </div>
  );

  const onChangeTab = (key) => {
    push(`/EDM/${key}`);
  };

  return (
    <EmailTemplateProvider>
      <Breadcrumb data={BREADCRUMB} />
      <PageTitle>{i18next.t('marketings.emailAndEDMCampaigns')}</PageTitle>
      <Row gutter={24}>
        <Col xl={6} lg={8} md={24} xs={24}>
          <SendWrapper />
        </Col>
        <Col xl={18} lg={16} md={24} xs={24}>
          <Tabs
            defaultActiveKey={model}
            tabBarExtraContent={operations}
            onChange={onChangeTab}
          >
            <TabPane tab={i18next.t('marketings.favorites')} key="favorites">
              <FavoritesTab
                initialFilter={{ filter: { isSystem: false } }}
                tab="favorites"
              />
            </TabPane>

            <TabPane tab={i18next.t('marketings.system')} key="system">
              <FavoritesTab
                initialFilter={{ filter: { isSystem: true } }}
                tab="system"
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
      <CreateModal ref={createModalRef} />
    </EmailTemplateProvider>
  );
};

export default EDM;
