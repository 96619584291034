import { FORMAT_DATE_TIME_SUBMIT } from 'configs/localData/constant';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

export const formatUserName = (data, record) => (
  <div>
    <Link
      to={`/providers/${record.id}/show/summary`}
      className="link-custom-primary"
    >
      <strong>{data || ''}</strong>
    </Link>

    <p>{record?.user?.email || ''}</p>
  </div>
);

export const formatPrimaryContact = (data, record) => (
  <div>
    <strong>
      {`${record?.user?.firstName || ''} ${record?.user?.lastName || ''}`}
    </strong>
    <p>{record?.user?.phoneNumber || ''}</p>
  </div>
);

export const formatDueDateSubmit = (value) =>
  value ? moment(value).format(FORMAT_DATE_TIME_SUBMIT) : null;

export const formatOnSubmit = ({ subject, description, ...values }) => ({
  ...values,
  subject: subject?.trim(),
  description: description?.trim(),
});
