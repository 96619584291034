import { Button } from 'antd';
import styled from 'styled-components';

export default styled.div`
  background: white;
  padding: 24px;

  .ant-steps-item-container {
    .custom-dot {
      box-shadow: 0px 4px 20px #ededf6;
      background: #ffffff;
      color: ${({ theme }) => theme.text.mainL5};
    }

    .ant-steps-item-title {
      color: ${({ theme }) => theme.text.mainL5} !important;
      font-weight: bold;
      font-size: 18px;
    }

    .ant-steps-item-icon {
      width: auto !important;
      height: auto !important;
      margin-left: 55px !important;
    }
    .ant-steps-item-tail {
      top: 15px !important;
      &::after {
        height: 1px !important;
        background: repeating-linear-gradient(
          90deg,
          #ffffff,
          #ffffff 5px,
          #ffc5f2 5px,
          #ffc5f2 10px
        ) !important;
      }
    }
  }

  .ant-steps-item-active {
    .custom-dot {
      background: ${({ theme }) => theme.palette.primary};
      color: #fff;
    }
    .ant-steps-item-title {
      color: ${({ theme }) => theme.palette.primary} !important;
    }
  }

  .ant-steps {
    margin: auto;
    max-width: 80%;
  }

  .ant-steps-item-title {
    white-space: nowrap;
  }

  .ant-divider-vertical {
    height: unset;
  }

  .steps-content {
    margin: 24px 0;
    min-height: 370px;
  }

  .steps-action {
    .float-left {
      float: left;
    }
    .float-right {
      float: right;
      button {
        margin: 0 8px;
      }
    }
  }

  .datetime-selector {
    .ant-picker {
      width: 100%;
    }
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
    .css-yk16xz-control {
      border-color: #d9d9d9 !important;
    }
    .css-yk16xz-control,
    .css-1pahdxg-control {
      border-radius: 8px !important;
      min-height: 40px !important;
    }
    .css-1pahdxg-control,
    .css-1pahdxg-control:hover {
      border: 1px solid ${({ theme }) => theme.palette.primary} !important;
      box-shadow: 0 0 0 2px rgb(219 20 127 / 20%) !important;
    }
    .css-9gakcf-option {
      background-color: ${({ theme }) => theme.palette.primary} !important;
    }
    .css-1n7v3ny-option {
      background-color: ${({ theme }) =>
        `${theme.palette.primary}20`} !important;
    }
  }

  .col:first-child {
    border-right: 1px solid #ffeafa;
  }

  @media only screen and (min-width: 1024px) {
    .col {
      padding: 0 24px !important;
    }
  }
  @media only screen and (min-width: 1440px) {
    .col {
      padding: 0 42px !important;
    }
  }
`;

export const TestNotificationFormStyles = styled.div`
  .form-test-noti {
    display: flex;
    align-items: flex-end;
  }

  .ant-select-selection-overflow-item {
    margin: 4px 0;
    .ant-tag {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      border-radius: 8px;
      display: flex;
      align-items: center;

      &:not(.ant-tag-error) {
        color: #2d1f21;
        border: 1px solid #ebecf0;
      }
    }
  }
  .ant-select-selection-item {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #2d1f21;
    border: 1px solid #ebecf0;
    border-radius: 8px;
    display: flex;
    align-items: center;
  }

  .select-form-wrapper {
    flex: 1;
    margin-right: 12px;
  }

  .select-recipients {
    margin-bottom: 0 !important;
  }

  .latest-emails-title {
    margin-top: 23px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: ${({ theme }) => theme.text.mainL5} !important;
  }

  .ant-select-item-option:first-child,
  .ant-select-item-option-selected {
    display: none;
  }
`;

export const EmailTagButton = styled(Button)`
  height: 22px;
  padding: 1px 10px;
  display: flex;
  align-items: center;
  background: #f7f8f9;
  border: 1px solid #cbd2d9;
  color: ${({ theme }) => theme.text.mainL5};
  font-size: 14px;
  line-height: 24px;
  white-space: nowrap;
`;

export const TryAgainContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .icon-wrapper {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    font-size: 40px;
    background-color: rgba(252, 90, 90, 0.15);
    margin-bottom: 24px;
  }

  .anticon {
    position: absolute;
    transform: translate(50%, 50%);
    color: #fc5a5a;
  }

  h3 {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: #171725;
  }
`;
