import { createAsyncThunk } from '@reduxjs/toolkit';
import { responseDisputeReviewApi } from 'api/disputeReview';
import { makeActions } from 'redux/crudCreator/actions';
import { apiWrapper } from 'utils/reduxUtils';

export const MODEL_NAME = 'disputeReviewRequests';
export const disputeReviewActions = makeActions(MODEL_NAME);

export const getAllDisputeReviews = disputeReviewActions.getAll;

export const responseDisputeReview = createAsyncThunk(
  'disputeReviewRequests/responseDisputeReview',
  async (payload = {}, thunkApi) => {
    try {
      const response = await apiWrapper(
        {
          isShowSuccessNoti: true,
        },
        responseDisputeReviewApi,
        payload.id,
        payload.data,
      );

      return response;
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  },
);
