import React from 'react';
import i18next from 'i18next';
import Input from 'antd/lib/input';
import FormItem from 'antd/lib/form/FormItem';

import { ACTIVITY_PROVIDER_TYPES } from 'configs/localData';

import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import FormStatus from './FormStatus';
import { ActivityFormStyles } from '../ActivitiesNotesTab/styles';
import AddAssignee from '../ActivitiesNotesTab/AddAssignee';
import AddDueDate from '../ActivitiesNotesTab/AddDueDate';

const FormActivity = () => (
  <ActivityFormStyles className="form-custom-label">
    <RestSelect
      required
      header="activitiesAndNotes.activityType"
      placeholder="activitiesAndNotes.activityType"
      source="type"
      resourceData={ACTIVITY_PROVIDER_TYPES}
      valueProp="value"
      titleProp="text"
      formatText={(text, item) => (
        <span style={{ color: item.color }}>{i18next.t(text)}</span>
      )}
      isShowSearch={false}
    />
    <RestInputItem
      required
      maxLength={255}
      isValidatorEmpty
      header="common.subject"
      placeholder="common.subject"
      source="subject"
    />
    <FormStatus />
    <RestInputItem
      required
      isValidatorEmpty
      header="common.description"
      placeholder="common.description"
      source="description"
      ContentComponent={Input.TextArea}
      rows={4}
    />
    <FormItem
      name="assigneeUserId"
      label={i18next.t('tasks.assignedTo')}
      rules={[
        {
          required: true,
          message: i18next.t('error.required'),
        },
      ]}
    >
      <AddAssignee />
    </FormItem>

    <FormItem name="dueDate" label={i18next.t('tasks.dueDate')}>
      <AddDueDate />
    </FormItem>
  </ActivityFormStyles>
);

export default FormActivity;
