import i18next from 'i18next';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spin } from 'antd';
import PropTypes from 'prop-types';

import UserInfo from 'components/RestField/UserInfo';
import { CentresUserModalStyles } from 'containers/Users/components/CentresUserModal/styles';
import { getCentresOfProvider } from 'redux/centresOfProvider/actions';
import { resetResourceFilterCentresOfProviders } from 'redux/centresOfProvider/slice';
import { centresOfProviderSelectors } from 'redux/centresOfProvider/selectors';

const CentreOfProviderList = ({ providerId }) => {
  const dispatch = useDispatch();

  const centresData = useSelector(centresOfProviderSelectors.getDataArr);
  const loading = useSelector(centresOfProviderSelectors.getLoading);
  const enableLoadMore = useSelector(
    centresOfProviderSelectors.enabledLoadMore,
  );

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, keepFilter }) => {
      dispatch(
        getCentresOfProvider({
          id: providerId,
          data: filter,
          options: {
            keepFilter,
            isRefresh,
            isOrderBy: false,
          },
        }),
      );
    },
    [providerId, dispatch],
  );

  useEffect(
    () => () => {
      dispatch(resetResourceFilterCentresOfProviders());
    },
    [dispatch],
  );

  useEffect(() => {
    retrieveList({
      filter: {
        limit: 10,
        offset: 0,
      },
      keepFilter: true,
      isRefresh: true,
    });
  }, [providerId, retrieveList]);

  const handleViewMore = () => {
    retrieveList(false);
  };

  return (
    <CentresUserModalStyles>
      <div className="centres-list">
        {centresData?.map((centre, index) => (
          <div key={String(index)} className="centre-item flex-center-between">
            <UserInfo
              record={centre}
              nameProp="name"
              avatarProp="image.url"
              prefixLink="centres"
              size={50}
            />
          </div>
        ))}
        {loading && (
          <div className="flex-center">
            <Spin />
          </div>
        )}
        {enableLoadMore && (
          <Button type="link" onClick={handleViewMore}>
            {i18next.t('button.viewMore')}
          </Button>
        )}
      </div>
    </CentresUserModalStyles>
  );
};
CentreOfProviderList.propTypes = {
  providerId: PropTypes.string,
};
export default CentreOfProviderList;
