const theme = {
  palette: {
    primary: '#DB147F',
    lightPrimary: '#4cb1e8',
    secondary: '#82D4D9',
    loadingBackgroundColor: '#2c3e51cc',
    color: ['#e64c38', '#DB147F', '#f8b51d'],
    dark: '#000444',
  },
  fonts: {
    primary: 'Mulish',
  },
  fontWeight: {
    thin: 100,
    utraLight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    heavy: 800,
    black: 900,
  },
  background: {
    primary: '#DB147F',
    warning: '#f8b51d',
    error: '#ed1558',
    content: '#fff',
    container: '#f4f7f9',
    input: '#efeff0',
    disabled: '#969696',
    headerTable: '#ffff',
    gray: '#f0f0f0',
    clearBtn: '#f7e8f0',
    disabledBtn: '#ABA7A7',
  },
  text: {
    primary: '#1f2933',
    text: '#1f2933',
    lightPrimary: '#3e4c59',
    secondary: '#a4a4a4',
    tabTitle: '#7f817c',
    empty: '#969696',
    highlight: '#5d4ec2',
    disabled: '#969696',
    formLabel: '#9c9d9b',
    headerTable: '#0f100d',
    note: '#878787',
    formIcon: '#41433f',
    disabledBtn: '#FFFFFF',
    mainL2: '#857E7F',
    mainL3: '#ABA7A7',
    mainL6: '#F2F2F2',
    mainL1: '#000000',
    mainL5: '#5C595A',
    mainAC: '#ACB2B8',
  },
  border: {
    default: '#dddddd',
    light: '#f2f2f2',
    primary: '#DB147F',
  },
  borderRadius: {
    default: '8px',
    button: '8px',
    input: '8px',
    checkbox: '4px',
    modal: '10px',
  },
  scrollbar: {
    thumb: '#b7b6c2',
    track: '#f0f3fa',
  },
  color: {
    gray: '#a3a3a3',
    green: '#4fcea2',
    brightGreen: '#52c41a',
    red: '#e64c38',
    blue: '#0992d0',
    orange: '#f5a623',
    darkOrange: '#f67800',
    pink: '#F75D81',
    limeGreen: '#4fcea2',
    lightGreen: '#3ebac2',
    blueShade: '#2d7fd3',
    yellow: '#FFCA28',
    violet: '#665ca7',
    purple: '#ac009f',
    warning: '#FB8429',
    info: '#316DDE',
    blueSecondary: '#32A4FC',
  },
  alert: {
    error: 'red',
  },
  card: {
    header: '#d4d2f450',
    padding: '20px',
  },
  sidebar: {
    activeText: 'white',
    activeBG: '#DB147F',
  },
  drawer: {
    headerTitle: '#0f100d',
    closeIcon: '#91938e',
  },
  table: {
    headerBackground: '#eff2f9',
    headerColor: '#50649c',
    color: '#7286a2',
  },
  subscription: {
    tagColor: '#32A4FC',
    tagBg: '#E9F4FF',
  },
  progress: {
    teal: '#2BADA7',
  },

  folderTree: {
    colorIcon: '#0569FF',
    colorText: '#2F3F60',
  },

  value: {
    green: '#36bf57',
    red: '#e52828',
    tableBackground: '#f1f1f5',
  },
};
module.exports = theme;
