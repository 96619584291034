import { makeActions } from 'redux/crudCreator/actions';

export const MODEL_NAME = 'changeEmailRequests';
export const changeEmailRequestsActions = makeActions(MODEL_NAME);

export const getAllChangeEmailRequests = changeEmailRequestsActions.getAll;
export const editChangeEmailRequests = changeEmailRequestsActions.edit;
export const createChangeEmailRequests = changeEmailRequestsActions.create;
export const getByIdChangeEmailRequests =
  changeEmailRequestsActions.getDataById;
