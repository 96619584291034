import React, { forwardRef, useState, useImperativeHandle } from 'react';
import { PropTypes } from 'prop-types';

import ProvidersModal from '.';
import Form from '../Form';

const ProviderGeneralInfoModal = forwardRef(({ record }, ref) => {
  const [visible, setVisible] = useState(false);

  const onCancel = () => setVisible(false);

  useImperativeHandle(
    ref,
    () => ({
      toggleModal: () => {
        setVisible(true);
      },
    }),
    [],
  );
  const formatOnSubmit = ({ userId, companyId, ...values }) => ({
    ...values,
    companyId: companyId === undefined ? null : companyId,
    userId: userId === undefined ? null : userId,
  });
  return (
    <ProvidersModal
      onCancel={onCancel}
      visible={visible}
      record={record}
      title="providers.editPage"
      width={500}
      formatOnSubmit={formatOnSubmit}
    >
      <Form />
    </ProvidersModal>
  );
});
ProviderGeneralInfoModal.propTypes = {
  record: PropTypes.object,
};
export default ProviderGeneralInfoModal;
