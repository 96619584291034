import React, { useEffect, useCallback, useRef } from 'react';
import { Button, Modal, Space, Switch } from 'antd';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { getInboxesOfCentre, getSummariesInboxes } from 'redux/inboxes/actions';
import { getResourceDataInboxesOfCentreSelector } from 'redux/inboxes/selectors';
import { allowedEnquiries } from 'redux/centres/actions';

import { TwinkleIcon } from 'components/common/SVGIcon';
import { getByKeyAppSettingsOfCentre } from 'redux/appSettings/actions';
import { ENQUIRY_MODE_KEY } from 'configs/localData/constant';
import ListStyles from './styles';
import FilterOther from '../components/FilterOther';
import ChatBoxList from './ChatBoxList';
import { EnquiryProvider } from './context';
import EnquirySettingModal from '../components/EnquirySettingModal';
import SettingEnquiryBtn from './SettingEnquiryBtn';

const getDataFilter = (resourceFilter, filter, isInitial, centreId) => ({
  ...(!isInitial && { q: resourceFilter?.q }),
  ...filter,
  filter: {
    enquiryTypeId: {
      $exists: true,
    },
    centreId,
    archivedAt: { $exists: false },
    ...(!isInitial && { ...resourceFilter?.filter }),
    ...filter?.filter,
  },
});

const List = (props) => {
  const dispatch = useDispatch();

  const { id: centreId } = useParams();

  const { push } = useHistory();

  const settingModalRef = useRef();

  const resourceFilter =
    useSelector(getResourceDataInboxesOfCentreSelector) || {};

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, isInitial }) => {
      dispatch(
        getInboxesOfCentre({
          id: centreId,
          data: getDataFilter(resourceFilter, filter, isInitial, centreId),
          options: {
            isRefresh,
            customApiResource: 'inboxes',
            isOrderBy: false,
          },
        }),
      );
    },
    [resourceFilter], // eslint-disable-line
  );

  const getSummaries = ({ filter, isInitial }) => {
    dispatch(
      getSummariesInboxes({
        data: getDataFilter(resourceFilter, filter, isInitial, centreId),
      }),
    );
  };

  const getListAndSummary = (filter) => {
    retrieveList({
      filter,
      isRefresh: true,
      isInitial: false,
    });
    getSummaries({ filter, isInitial: false });
  };

  useEffect(() => {
    getListAndSummary({ limit: 20, offset: 0 });
    dispatch(getByKeyAppSettingsOfCentre({ centreId, key: ENQUIRY_MODE_KEY }));
  }, []); // eslint-disable-line

  const handleClickAutomateResponse = () => {
    if (centreId) {
      push(`/centres/${centreId}/automatedResponses`);
    } else {
      Modal.warning({
        title: i18next.t('automatedResponses.header'),
        content: i18next.t('automatedResponses.warningRedirect'),
      });
    }
  };

  const handleAllowedEnquiries = (value) => {
    Modal.confirm({
      title: i18next.t('centres.confirm'),
      content: props.record?.isAllowedEnquiries
        ? i18next.t('centres.confirmTurnOffEnquiries')
        : i18next.t('centres.confirmTurnOnEnquiries'),
      onOk() {
        return dispatch(
          allowedEnquiries({
            centreId,
            data: { data: { isAllowed: value } },
          }),
        );
      },
    });
  };

  const openSettingModal = () => {
    if (centreId) {
      settingModalRef.current.open();
    } else {
      Modal.warning({
        title: i18next.t('enquiriesSettings.title'),
        content: i18next.t('enquiriesSettings.warningSelectCentre'),
      });
    }
  };

  return (
    <EnquiryProvider>
      <ListStyles>
        <div className="flex-center-between mb-20">
          <div>
            <span className="mr-10 text-primary">
              {i18next.t('centres.enableEnquiry')}
            </span>
            <Switch
              onChange={handleAllowedEnquiries}
              checked={props?.record?.isAllowedEnquiries}
            />
          </div>
          <Space>
            <Button
              icon={<TwinkleIcon />}
              className="btn-second-primary"
              title="button.automatedResponses"
              onClick={handleClickAutomateResponse}
            >
              {i18next.t('button.automatedResponses')}
            </Button>
            <SettingEnquiryBtn openSettingModal={openSettingModal} />
          </Space>
        </div>

        <FilterOther getListAndSummary={getListAndSummary} />
        <ChatBoxList
          getListAndSummary={getListAndSummary}
          retrieveList={retrieveList}
        />
      </ListStyles>
      <EnquirySettingModal ref={settingModalRef} />
    </EnquiryProvider>
  );
};

List.propTypes = {
  record: PropTypes.object,
};

export default List;
