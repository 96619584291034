import { ACTIVITY_PROVIDER_TYPES } from 'configs/localData';
import i18next from 'i18next';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const useDataFilterActivityTask = () => {
  const taskStatus = useSelector((state) => state.config?.data?.taskStatus);

  const activityProviderTypeFilter = useMemo(
    () =>
      ACTIVITY_PROVIDER_TYPES.map((item) => ({
        value: item.value,
        text: i18next.t(item.text),
      })),
    [],
  );

  const taskStatusFilter = useMemo(
    () =>
      taskStatus?.map((item) => ({
        value: item.id,
        text: item.name,
      })),
    [taskStatus],
  );

  return {
    activityProviderTypeFilter,
    taskStatusFilter,
  };
};

export default useDataFilterActivityTask;
