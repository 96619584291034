import styled from 'styled-components';

const FormStyles = styled.div`
  .form-list {
    &__list {
      padding: 15px;
      border: 2px dashed ${({ theme }) => theme.border.default};
      border-radius: 8px;
    }
    &__list-item {
      position: relative;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .ant-form-item {
        margin-bottom: 0 !important;
      }
      > div {
        flex: 1;
      }
    }
    &__remove-button {
      font-size: 25px;
      margin-left: 15px;
    }
  }
  .add-more-form-item {
    margin-bottom: 0 !important;
  }
`;

export default FormStyles;
