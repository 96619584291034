import React, { useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import TableCustom from 'components/common/TableCustom';
import { formatDateTimeTable } from 'utils/textUtils';
import { useDispatch, useSelector } from 'react-redux';
import { formatUserName } from 'containers/Providers/components/UsersTab/UsersListTable';
import CentresField from 'components/CentresViewAll/CentresField';
import CentresModal from 'components/CentresViewAll/CentresModal';
import { getCentresOfUser } from 'redux/centresOfUser/actions';
import { clearCurrentUser } from 'redux/users/actions';
import {
  getDataUsersOfCompany,
  getLoadingUsersOfCompany,
  getResourceDataUsersOfCompany,
} from 'redux/usersOfCompany/selector';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_CREATED_AT,
  LIMIT_ITEM,
  SORT_ASCEND_VALUE,
  SORT_DESCEND_VALUE,
} from 'configs/localData/constant';
import { useLocation } from 'react-router';
import { getFilterFromUrl } from 'utils/tools';
import GroupActionsUser from './GroupActionsUser';
import ActiveUser from './ActiveUser';

const UsersListTable = ({ retrieveList, pushQuery }) => {
  const dispatch = useDispatch();
  const centresUserModalRef = useRef();

  const loading = useSelector(getLoadingUsersOfCompany);
  const resourceFilter = useSelector(getResourceDataUsersOfCompany) || {};
  const data = useSelector(getDataUsersOfCompany);

  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);

  const getSorterOrder = useCallback(
    (source) => {
      if (filter.sorts?.[0] === source) return SORT_ASCEND_VALUE;
      if (filter.sorts?.[0] === `-${source}`) return SORT_DESCEND_VALUE;
      return '';
    },
    [filter],
  );

  const openCentresUserModal = useCallback((record) => {
    centresUserModalRef.current.open(record);
  }, []);

  const formatCentreName = useCallback(
    (data, record) => (
      <CentresField
        roleName={record?.role?.name}
        data={data}
        handleClickViewAll={() => openCentresUserModal(record)}
      />
    ),
    [openCentresUserModal],
  );

  useEffect(() => {
    dispatch(clearCurrentUser());
  }, [dispatch]);

  const columns = [
    {
      title: i18next.t('common.name'),
      dataIndex: 'userName',
      render: formatUserName,
      sorter: true,
      defaultSortOrder: getSorterOrder('userName'),
      width: 300,
    },
    {
      title: i18next.t('users.jobTitle'),
      dataIndex: 'jobTitle',
      sorter: true,
      defaultSortOrder: getSorterOrder('jobTitle'),
      width: 140,
    },
    {
      title: i18next.t('users.role'),
      dataIndex: ['role', 'description'],
      width: 160,
    },
    {
      title: i18next.t('centres.header'),
      dataIndex: 'centres',
      render: formatCentreName,
      width: 250,
    },
    {
      title: i18next.t('common.lastActiveAt'),
      dataIndex: 'lastActiveAt',
      sorter: true,
      render: formatDateTimeTable,
      defaultSortOrder: getSorterOrder('lastActiveAt'),
      width: 160,
    },
    {
      title: i18next.t('common.createdAt'),
      dataIndex: 'createdAt',
      sorter: true,
      render: formatDateTimeTable,
      defaultSortOrder: getSorterOrder('createdAt'),
      width: 160,
    },
    {
      title: i18next.t('users.activeCRMApp'),
      width: 200,
      dataIndex: 'isAllowedCRMApp',
      render: (_, record) => (
        <ActiveUser
          value={record?.isAllowedCRMApp}
          userId={record?.id}
          isAllowedAction="isAllowedCRMApp"
          disallowTitle="users.disallowLoginAppTitle"
          allowTitle="users.allowLoginAppTitle"
          disallowDesc="users.disallowLoginAppConfirm"
          allowDesc="users.allowLoginAppConfirm"
        />
      ),
    },
    {
      title: i18next.t('users.activeInsights'),
      width: 210,
      dataIndex: 'isAccessInsightFeature',
      render: (_, record) => (
        <ActiveUser
          value={record?.isAccessInsightFeature}
          userId={record?.id}
          isAllowedAction="isAccessInsightFeature"
          disallowTitle="users.disallowActiveInsights"
          allowTitle="users.allowActiveInsights"
          disallowDesc="users.disallowActiveInsigtsConfirm"
          allowDesc="users.allowActiveInsightsConfirm"
        />
      ),
    },
    {
      title: i18next.t('users.activeOnJobsModule'),
      width: 200,
      dataIndex: 'isActiveOnJobModule',
      render: (_, record) => (
        <ActiveUser
          value={record?.isActiveOnJobModule}
          userId={record?.id}
          isAllowedAction="isActiveOnJobModule"
          disallowTitle="users.disallowActiveOnJob"
          allowTitle="users.allowActiveOnJob"
          disallowDesc="users.disallowActiveOnJobConfirm"
          allowDesc="users.allowActiveOnJobConfirm"
        />
      ),
    },
    {
      title: i18next.t('users.blocked'),
      dataIndex: 'isBlocked',
      width: 150,
      render: (_, record) => (
        <ActiveUser
          value={record?.isBlocked}
          userId={record?.id}
          isAllowedAction="isBlocked"
          disallowTitle="users.unBlockUserConfirmTitle"
          allowTitle="users.blockUserConfirmTitle"
          disallowDesc="users.unlockUserConfirmContent"
          allowDesc="users.blockUserConfirmContent"
        />
      ),
    },
    {
      dataIndex: 'id',
      fixed: 'right',
      width: 150,
      render: (id, record) => <GroupActionsUser userId={id} record={record} />,
    },
  ];

  const onChange = (e, filters, sorter) => {
    const formatSort =
      sorter && sorter.field && sorter.order
        ? `${sorter.order === SORT_DESCEND_VALUE ? '-' : ''}${sorter.field}`
        : null;

    const restFilter = {
      size: e.pageSize,
      page: e.current,
      sorts: [formatSort || DEFAULT_SORT_CREATED_AT],
      filter: { ...filter.filter },
    };

    retrieveList({
      filter: restFilter,
      isRefresh: true,
      keepFilter: true,
    });
    pushQuery({
      'sorts[]': restFilter.sorts,
      ...restFilter,
    });
  };

  return (
    <div>
      <TableCustom
        xScroll={1200}
        isSorter
        onChange={onChange}
        data={data}
        columns={columns}
        loading={loading}
        pagination={{
          pageSize: resourceFilter.size || LIMIT_ITEM,
          current: filter.page || DEFAULT_CURRENT_PAGE,
          total: resourceFilter.total,
        }}
      />
      <CentresModal
        ref={centresUserModalRef}
        title="users.centresOfUser"
        resource="centresOfUser"
        retrieveListAction={getCentresOfUser}
      />
    </div>
  );
};

UsersListTable.propTypes = {
  retrieveList: PropTypes.func,
  pushQuery: PropTypes.func,
};

UsersListTable.defaultProps = {
  retrieveList: () => null,
};

export default UsersListTable;
