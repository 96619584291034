import React from 'react';
import { useSelector } from 'react-redux';

import {
  TotalOpenApplicationIcon,
  TotalWaitlistIcon,
  TotalWaitlistValueIcon,
  TotalEarningIcon,
} from 'components/common/SVGIcon';
import SummaryCardDetail from 'components/common/SummaryCardDetail';

const SummaryApplication = () => {
  const summaries =
    useSelector(
      (state) => state.centres.summaryDetail?.centreApplicationsSummary,
    ) || {};

  const data = [
    {
      title: 'centreDetail.openApplication',
      value: summaries.openApplications,
      IconComponent: TotalOpenApplicationIcon,
    },
    {
      title: 'centreDetail.openApplicationValue',
      value: summaries.openApplicationsValue,
      IconComponent: TotalEarningIcon,
      isPerAnnum: true,
    },
    {
      title: 'centreDetail.totalWaitlisted',
      value: summaries.waitListedApplications,
      IconComponent: TotalWaitlistIcon,
    },
    {
      title: 'centreDetail.waitlistValue',
      value: summaries.waitListedApplicationsValue,
      IconComponent: TotalWaitlistValueIcon,
      isPerAnnum: true,
    },
  ];
  return (
    <SummaryCardDetail title="centreDetail.applicationSummary" data={data} />
  );
};

export default SummaryApplication;
