import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { getApplicationsOfCompany } from 'redux/applicationsOfCompany/actions';
import ApplicationsTableList from './ApplicationsTableList';
import FilterApplications from './FilterApplications';

const ApplicationTab = () => {
  const { id: companyId } = useParams();
  const dispatch = useDispatch();

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, keepOrderBy }) => {
      dispatch(
        getApplicationsOfCompany({
          id: companyId,
          data: filter,
          options: {
            isRefresh,
            keepOrderBy,
            isOrderBy: false,
          },
        }),
      );
    },
    [companyId, dispatch],
  );

  useEffect(() => {
    retrieveList({
      filter: {
        limit: 10,
        offset: 0,
        orderBy: '-applicationUpdatedAt',
      },
      isRefresh: true,
    });
  }, [retrieveList]);

  return (
    <div>
      <FilterApplications retrieveList={retrieveList} />
      <ApplicationsTableList
        retrieveList={retrieveList}
        resource="applicationsOfCompany"
      />
    </div>
  );
};

export default ApplicationTab;
