import React from 'react';
import PropTypes from 'prop-types';
import EditButton from 'components/RestActions/EditButton';

const GroupActions = ({ onClickCustom }) => (
  <div>
    <EditButton isView source="show" onClickCustom={onClickCustom} />
  </div>
);

GroupActions.propTypes = {
  onClickCustom: PropTypes.func,
};

export default GroupActions;
