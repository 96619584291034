import React from 'react';
import PropTypes from 'prop-types';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import { findIndex } from 'lodash-es';
import { Row } from 'antd';
import SortableItem from './SortableItem';
import { SortableGridStyles } from './styles';

const SortableGrid = ({
  fileList,
  imageItemProps,
  onChangeSortable,
  responsiveSortable,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = findIndex(fileList, (o) => o.uid === active.id);
      const newIndex = findIndex(fileList, (o) => o.uid === over.id);
      onChangeSortable(arrayMove(fileList, oldIndex, newIndex));
    }
  };

  return (
    <SortableGridStyles>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={fileList?.map((item) => item.uid)}
          strategy={rectSortingStrategy}
        >
          <Row gutter={[10, 10]}>
            {fileList.map((file, index) => (
              <SortableItem
                key={file.uid}
                id={file.uid}
                item={file}
                index={index}
                imageItemProps={imageItemProps}
                responsiveSortable={responsiveSortable}
              />
            ))}
          </Row>
        </SortableContext>
      </DndContext>
    </SortableGridStyles>
  );
};

SortableGrid.propTypes = {
  fileList: PropTypes.array,
  onChangeSortable: PropTypes.func,
  imageItemProps: PropTypes.object,
  responsiveSortable: PropTypes.object,
};

export default SortableGrid;
