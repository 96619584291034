import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import CardSection from 'components/common/CardSection';
import EditModal from './EditModal';
import NoteDetailBody from './NoteDetailBody';

const NoteDetail = () => {
  const editModalRef = useRef();

  const activity = useSelector((state) => state.activities.currentData);

  const openEditModal = () => {
    editModalRef.current && editModalRef.current.open(activity);
  };

  return (
    <CardSection
      title="activitiesAndNotes.noteDetails"
      isEdit={!!activity?.id}
      onClickButton={openEditModal}
    >
      <NoteDetailBody record={activity} />
      <EditModal ref={editModalRef} />
    </CardSection>
  );
};

export default NoteDetail;
