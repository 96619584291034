import React from 'react';
import Create from 'containers/rest/Create';
import { PREFIX_URL_API } from 'configs/localData/constant';
import { convertNameToType } from 'utils/textUtils';
import i18next from 'i18next';
import Form from '../components/Form';

const ServiceAgeGroupsCreate = (props) => (
  <Create
    {...props}
    resource="serviceAgeGroups"
    formatOnSubmit={(data) => ({
      ...data,
      type: convertNameToType(data?.name),
    })}
    defaultOptions={{
      isTrimStr: true,
      isGetConfig: true,
      prefixUrl: PREFIX_URL_API,
    }}
    header={i18next.t('button.addNewItem')}
    okText="button.save"
  >
    <Form />
  </Create>
);

export default ServiceAgeGroupsCreate;
