import React from 'react';
import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import UserInfo from 'components/RestField/UserInfo';
import Filter from '../components/Filter';

const Customers = (props) => (
  <List
    widthTable="1000px"
    {...props}
    hasCreate={false}
    resource="customers"
    hasSearch={false}
    filter={<Filter />}
  >
    <RestFieldItem
      source="name"
      header="customers.name"
      format={(data, record) => (
        <UserInfo
          record={{
            id: record?.id,
            avatar: record?.logo?.url,
            fullName: data,
          }}
          prefixLink="customers"
          suffixLink="show"
        />
      )}
    />
    <RestFieldItem source="gender" header="customers.gender" />
    <RestFieldItem source="phone" header="customers.phone" />
    <RestFieldItem source="address" header="customers.address" />
    <ActionGroup widthAction={80}>
      <EditButton isView />
    </ActionGroup>
  </List>
);

export default Customers;
