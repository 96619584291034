import styled from 'styled-components';

export default styled.div`
  .ant-tabs-tabpane {
    padding-bottom: 20px;
    .ant-pagination-options {
      padding-right: 15px;
    }
  }
  .page-header {
    & > div {
      margin-bottom: 10px;
    }
  }
  .ant-tabs-nav {
    margin-bottom: 20px;
  }
`;
