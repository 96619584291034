import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

import ContentTextStyles from './styles';

const ContentTextEllipsis = ({ content }) => (
  <Tooltip placement="right" title={content}>
    <ContentTextStyles className="message">{content}</ContentTextStyles>
  </Tooltip>
);

ContentTextEllipsis.propTypes = {
  content: PropTypes.string,
};

export default ContentTextEllipsis;
