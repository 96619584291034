import React from 'react';
import Create from 'containers/rest/Create';
import { PREFIX_URL_API } from 'configs/localData/constant';
import Form from '../components/Form';

const CentresCreate = (props) => (
  <Create
    {...props}
    resource="articles"
    defaultOptions={{
      isTrimStr: true,
      prefixUrl: PREFIX_URL_API,
      isGetAll: true,
    }}
  >
    <Form />
  </Create>
);

export default CentresCreate;
