import React from 'react';
import { Switch } from 'antd';

import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import { formatLabelTable } from 'utils/textUtils';
import { LABEL_TABLE_KEY, PREFIX_URL } from 'configs/localData/constant';
import RestImageField from 'components/RestField/ImageField';
import { editChildcareServiceTypes } from 'redux/childcareServiceType/actions';
import { useDispatch, useSelector } from 'react-redux';

const ChildcareServiceTypesList = (props) => {
  const dispatch = useDispatch();
  const handleActive = (isActive, record) =>
    dispatch(
      editChildcareServiceTypes({
        data: {
          id: record?.id,
          name: record?.name,
          logo: record?.logo,
          code: record?.code,
          isActive,
        },
        options: {
          prefixUrl: PREFIX_URL.superAdmin,
          customApiResource: 'primary-child-care-service',
        },
      }),
    );

  const appSettings = useSelector((state) => state.config?.data?.AppSetting);

  return (
    <List
      {...props}
      resource="childcareServiceTypes"
      widthTable="1000px"
      hasSearch={false}
      header=""
      hasCreate={false}
      defaultOptions={{
        isGetConfig: true,
        prefixUrl: PREFIX_URL.superAdmin,
        customApiResource: 'primary-child-care-services',
      }}
    >
      <RestFieldItem
        source="name"
        header={formatLabelTable(LABEL_TABLE_KEY.childCareService, appSettings)}
        format={(data, record) => (
          <div className="flex items-center ">
            <RestImageField
              style={{ width: 32, height: 32 }}
              record={record}
              source="logo.url"
            />
            <span className="ml-16">{data}</span>
          </div>
        )}
      />
      <RestFieldItem
        source="code"
        header="serviceTypes.code"
        format={(data) => data.toUpperCase()}
      />
      <RestFieldItem
        source="isActive"
        header="serviceTypes.isActive"
        valueProp="checked"
        component={<Switch />}
        customOnChange={handleActive}
      />
      <ActionGroup widthAction={100}>
        <EditButton />
      </ActionGroup>
    </List>
  );
};

export default ChildcareServiceTypesList;
