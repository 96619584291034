import React from 'react';
import i18next from 'i18next';
import { ACTIVE_TYPES } from 'configs/localData';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import { Row, Col } from 'antd';

const Filter = () => (
  <Row gutter={16}>
    <Col lg={8} md={12} xs={24}>
      <RestInputItem
        source={['key', '$like']}
        placeholder="appSettings.key"
        isShowTooltip
      />
    </Col>
    <Col lg={8} md={12} xs={24}>
      <RestInputItem
        source={['value', '$like']}
        placeholder="appSettings.value"
        isShowTooltip
      />
    </Col>
    <Col lg={8} md={12} xs={24}>
      <RestSelect
        source="isActive"
        resourceData={ACTIVE_TYPES}
        valueProp="value"
        titleProp="text"
        formatText={(data) => i18next.t(data)}
        isShowTooltip
        isShowSearch={false}
        placeholder="appSettings.isActive"
        ruleType="boolean"
      />
    </Col>
  </Row>
);

export default Filter;
