import styled from 'styled-components';

const ProgressStyle = styled.div`
  .ant-progress-status-success .ant-progress-bg {
    background-color: #1890ff;
  }
  .ant-progress-status-success .ant-progress-text {
    color: #000;
  }
  .ant-progress-circle .ant-progress-text {
    font-size: 12px;
    font-weight: 700;
  }
`;

export default ProgressStyle;
