import { createSlice } from '@reduxjs/toolkit';
import { getTotalSearchStatistic } from './actions';

export const initialState = {
  data: [],
  loading: false,
};

const getTotalSearchStatisticPending = (state) => {
  state.loading = true;
};

const getTotalSearchStatisticFulfilled = (state, { payload }) => {
  state.loading = false;
  state.data = payload;
};

const getTotalSearchStatisticRejected = (state, { payload }) => {
  state.loading = false;
  state.error = payload;
};

const { reducer } = createSlice({
  name: 'totalSearchStatistic',
  initialState,
  extraReducers: {
    [getTotalSearchStatistic.pending]: getTotalSearchStatisticPending,
    [getTotalSearchStatistic.fulfilled]: getTotalSearchStatisticFulfilled,
    [getTotalSearchStatistic.rejected]: getTotalSearchStatisticRejected,
  },
});
export default reducer;
