export const ROLES_BELONG_TO_BUSINESS_CONST = {
  providerAdmin: 'providerAdmin',
  providerStaff: 'providerStaff',
  centreAdmin: 'centreAdmin',
  centreStaff: 'centreStaff',
  companyAdmin: 'companyAdmin',
  companyStaff: 'companyStaff',
};

export const ROLES_CONST = {
  ...ROLES_BELONG_TO_BUSINESS_CONST,
  customer: 'customer',
};

export const ROLES_BELONG_TO_BUSINESS = Object.values(
  ROLES_BELONG_TO_BUSINESS_CONST,
);

export const ROLE_FOR_PROVIDER_USER_CONST = {
  providerAdmin: 'providerAdmin',
  providerStaff: 'providerStaff',
  centreAdmin: 'centreAdmin',
  centreStaff: 'centreStaff',
};

export const ROLE_FOR_PROVIDER_USER = Object.values(
  ROLE_FOR_PROVIDER_USER_CONST,
);
