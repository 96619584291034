import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  delEmailTemplateApi,
  getByKeyEmailTemplateApi,
  getClaimStatusApi,
  getEmailTemplateApi,
  postEmailTemplateApi,
  putEmailTemplateApi,
  sendEmailGroupCentresApi,
  sendEmailIndividualContactApi,
  sendIndividualEmailApi,
  getOptionEmailTemplateApi,
} from 'api/edm';
import i18next from 'i18next';
import { convertRequestParams } from 'redux/crudCreator/dataProvider';
import { apiWrapper } from 'utils/reduxUtils';

export const getEmailTemplates = createAsyncThunk(
  'centres/getEmailTemplates',
  async (payload = {}, thunkApi) => {
    try {
      const { data = {}, options = {} } = payload;

      const { limit, offset, filter } =
        thunkApi.getState().EDM.emailTemplates?.resourceData || {};

      const convertRequest = convertRequestParams('GET_ALL', {
        limit,
        offset,
        filter,
        ...data,
      });

      const response = await apiWrapper(
        {},
        getEmailTemplateApi,
        convertRequest,
      );

      return {
        data: {
          numberOfPages: Math.ceil(response.total / limit),
          ...response,
        },
        options,
      };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const sendIndividualEmail = createAsyncThunk(
  'EDM/getSendIndividualEmail',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowSuccessNoti: true,
          successDescription: i18next.t('EDM.sendSuccess'),
        },
        sendIndividualEmailApi,
        payload,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const sendEmailGroupCentres = createAsyncThunk(
  'EDM/sendEmailGroupCentres',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowSuccessNoti: true,
          successDescription: i18next.t('EDM.sendSuccess'),
        },
        sendEmailGroupCentresApi,
        payload,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getClaimStatus = createAsyncThunk(
  'EDM/getClaimStatus',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(null, getClaimStatusApi, payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const sendEmailIndividualContact = createAsyncThunk(
  'EDM/sendEmailIndividualContact',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowSuccessNoti: true,
          successDescription: i18next.t('EDM.sendSuccess'),
        },
        sendEmailIndividualContactApi,
        payload,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const createEmailTemplate = createAsyncThunk(
  'EDM/createEmailTemplate',
  async (payload, thunkAPI) => {
    try {
      const filter =
        thunkAPI.getState().EDM.emailTemplates?.resourceData?.filter;

      const response = await apiWrapper(
        {
          isShowSuccessNoti: true,
        },
        postEmailTemplateApi,
        payload.data,
      );
      if (payload.options?.isGetAll) {
        thunkAPI.dispatch(
          getEmailTemplates({
            data: { offset: 0, filter },
            options: { isRefresh: true },
          }),
        );
      }
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const editEmailTemplate = createAsyncThunk(
  'EDM/editEmailTemplate',
  async (payload, thunkAPI) => {
    try {
      const { limit, offset, filter } =
        thunkAPI.getState().EDM.emailTemplates?.resourceData || {};

      const response = await apiWrapper(
        {
          isShowSuccessNoti: true,
        },
        putEmailTemplateApi,
        payload.key,
        payload.data,
      );
      if (payload.options?.isGetAll) {
        thunkAPI.dispatch(
          getEmailTemplates({
            data: { limit, offset, filter },
            options: { isRefresh: true },
          }),
        );
      }
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getByKeyEmailTemplate = createAsyncThunk(
  'EDM/editEmailTemplate',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper({}, getByKeyEmailTemplateApi, payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const delEmailTemplate = createAsyncThunk(
  'EDM/delEmailTemplate',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowSuccessNoti: true,
        },
        delEmailTemplateApi,
        payload,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getOptionEmailTemplate = createAsyncThunk(
  'centres/getOptionEmailTemplate',
  async (payload = {}, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {},
        getOptionEmailTemplateApi,
        payload.centreId,
        payload.key,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const clearCurrentDataTemplate = createAction('EDM/clearCurrentData');
export const clearCurrentOptionTemplate = createAction(
  'EDM/clearCurrentOptionTemplate',
);
