import React from 'react';
import { isPlainObject } from 'lodash-es';
import Edit from 'containers/rest/Edit';
import Form from '../components/Form';

const NQSRTypesEdit = (props) => (
  <Edit
    {...props}
    resource="NQSRTypes"
    formatOnSubmit={({ image, ...data }) => ({
      ...data,
      image: isPlainObject(image) ? image.url : image,
    })}
    defaultOptions={{ isTrimStr: true, isGetConfig: true }}
  >
    <Form isEdit />
  </Edit>
);

export default NQSRTypesEdit;
