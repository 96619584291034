import { createSelector } from 'reselect';

const getRawDataUsersOfCompany = (state) => state.usersOfCompany?.data;

const getRawResourceDataUsersOfCompany = (state) =>
  state.usersOfCompany?.resourceData;

const getRawUsersOfCompanyLoading = (state) => state.usersOfCompany?.loading;

export const getDataUsersOfCompany = createSelector(
  [getRawDataUsersOfCompany],
  (data) => data,
);

export const getResourceDataUsersOfCompany = createSelector(
  [getRawResourceDataUsersOfCompany],
  (data) => data,
);

export const getLoadingUsersOfCompany = createSelector(
  [getRawUsersOfCompanyLoading],
  (data) => data,
);
