import { Card } from 'antd';
import styled from 'styled-components';

const CompanyInfoStyles = styled(Card)`
  .general-item {
    font-size: 18px;
    font-weight: 400;
    line-height: 2;
  }
  .edit-wrapper {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
  }
  .edit-div-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .avatar-company {
    display: flex;
    justify-content: center;
    margin: 56px 0 16px 0;
    .ant-avatar {
      width: 120px !important;
      height: 120px !important;
    }
  }
  .content-info-container {
    .company-info {
      font-size: 18px;
      margin-bottom: 32px;
      .title-info {
        font-weight: bold;
        margin-bottom: 18px;
      }
    }
  }
  .title-info-other {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 15px;
  }
  .phone-item-info {
    > span {
      display: block;
    }
    > span:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .value-info-item {
    word-break: break-word;
  }

  .content-info-other {
    > .item-info {
      display: flex;
      font-size: 18px;
      .ant-tag {
        font-size: 18px;
      }
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      & > .anticon {
        font-size: 16px;
        color: ${({ theme }) => theme.palette.primary};
        line-height: 30px;
        margin-right: 20px;
      }
    }
    a {
      color: #3570db;
    }
  }
  .company-name-style {
    font-size: 24px;
    font-weight: bold;
    line-height: 2;
  }

  .company-type {
    background: #f7f8f9;
    border-radius: 100px;
    width: fit-content;
    height: 32px;
    padding: 4px 12px;
    color: #758098;
    font-weight: 700;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

export default CompanyInfoStyles;
