import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { providersOfUserSelectors } from 'redux/providersOfUser/selectors';
import TableCustom from 'components/common/TableCustom';
import { getQueryParamsWhenChangeTable } from 'utils/tableUtils';
import { delProvidersOfUser } from 'redux/providersOfUser/actions';
import { checkLastUser, unSubcriptionCentre } from 'redux/users/actions';
import { getCentreIdsOfProvider } from 'redux/providers/actions';
import { Modal, Button, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { ButtonWrapper } from 'components/RestActions/DeleteButton/styles';

export const confirmDeleteForLastUser = (
  deleteItem,
  record,
  resource,
  customMessage,
  nameProp,
  lastItem,
  onCancel,
) => {
  Modal.confirm({
    title: `${i18next.t('popup.alertDelete')} ${i18next.t(
      `${resource}.titleDelete`,
    )}`,
    content: lastItem
      ? i18next.t(customMessage, {
          name: `${record[nameProp]}`,
        })
      : i18next.t('popup.alertDeleteDes', {
          customMessage: customMessage
            ? i18next.t(customMessage)
            : `${record[nameProp]}`,
        }),
    okText: i18next.t('button.yes'),
    cancelText: i18next.t('button.no'),
    onOk: () => deleteItem(record.id, record),
    onCancel: onCancel || null,
  });
};

const ProvidersOfUserTable = ({ retrieveList }) => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const resourceFilter =
    useSelector(providersOfUserSelectors.getResourceData) || {};

  const loading = useSelector(providersOfUserSelectors.getLoading);

  const data = useSelector(providersOfUserSelectors.getDataArr);

  const deleteProviderUser = (providerId) => {
    dispatch(
      delProvidersOfUser({
        id,
        providerId,
      }),
    );
  };

  const unSubcription = (record) => () => {
    dispatch(getCentreIdsOfProvider(record?.id)).then(({ payload }) => {
      payload && dispatch(unSubcriptionCentre({ centreIds: payload }));
    });
  };

  const onCancel = () => {};

  const deleteItem = (providerId, record) => {
    dispatch(
      delProvidersOfUser({
        id,
        providerId,
      }),
    ).then(({ payload }) => {
      payload?.providerId &&
        confirmDeleteForLastUser(
          onCancel,
          record,
          'providerUser',
          'providerUser.confirmUnsubcription',
          'name',
          true,
          unSubcription(record),
        );
    });
  };

  const deleteProviderOfUser = (providerId, record) => {
    dispatch(checkLastUser(`providerId=${providerId}&userId=${id}`)).then(
      ({ payload }) => {
        if (!payload?.lastUser) {
          confirmDeleteForLastUser(
            deleteProviderUser,
            record,
            'providerUser',
            'providerUser.confirmDelete',
            'name',
            payload?.lastUser,
          );
        } else {
          confirmDeleteForLastUser(
            deleteItem,
            record,
            'providerUser',
            'providerUser.confirmDeleteLastUser',
            'name',
            payload?.lastUser,
          );
        }
      },
    );
  };

  const columns = [
    {
      title: i18next.t('providers.provider'),
      dataIndex: 'name',
      sorter: true,
      render: (data, record) => (
        <Link
          to={`/providers/${record.id}/show/summary`}
          className="link-custom-primary"
        >
          <span className="fw-bold">{data}</span>
        </Link>
      ),
    },
    {
      title: i18next.t('centres.PRCode'),
      dataIndex: 'governmentReferenceId',
      sorter: true,
    },
    {
      title: i18next.t('providers.numberOfCentres'),
      dataIndex: 'totalCentres',
      sorter: true,
    },
    {
      title: '',
      dataIndex: 'id',
      width: 80,
      fixed: 'right',
      render: (id, record) => (
        <ButtonWrapper>
          <Tooltip title={i18next.t('button.delete')}>
            <Button
              icon={<DeleteOutlined />}
              onClick={() => deleteProviderOfUser(id, record)}
            />
          </Tooltip>
        </ButtonWrapper>
      ),
    },
  ];

  const onChange = (e, filters, sorter) => {
    const queryParams = getQueryParamsWhenChangeTable(e, filters, sorter);

    retrieveList({
      filter: queryParams,
      isRefresh: true,
      keepFilter: true,
    });
  };

  return (
    <TableCustom
      xScroll={600}
      onChange={onChange}
      data={data}
      columns={columns}
      loading={loading}
      isResetStyle
      pagination={{
        pageSize: resourceFilter.limit,
        current: resourceFilter.offset / resourceFilter.limit + 1 || 1,
        total: resourceFilter.total,
      }}
    />
  );
};

ProvidersOfUserTable.propTypes = {
  retrieveList: PropTypes.func,
};

ProvidersOfUserTable.defaultProps = {
  retrieveList: () => null,
};

export default ProvidersOfUserTable;
