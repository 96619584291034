import React from 'react';
import { useSelector } from 'react-redux';
import SummaryView from 'components/common/SummaryCardDetail/SummaryView';
import { TotalApplicationIcon } from 'components/common/SVGIcon';

const SummaryApplications = () => {
  const summaries =
    useSelector((state) => state.companies.summaries?.applicationsSummary) ||
    {};

  const data = [
    {
      title: 'centreDetail.openApplicationValue',
      value: summaries.openApplicationValue,
      isPerAnnum: true,
    },
    {
      title: 'centreDetail.totalWaitlisted',
      value: summaries.totalWaitlisted,
    },
    {
      title: 'centreDetail.waitlistValue',
      value: summaries.waitlistedValue,
      isPerAnnum: true,
    },
  ];

  return (
    <SummaryView
      IconComponent={TotalApplicationIcon}
      title="applications.title"
      total={summaries.totalApplications}
      data={data}
      color="#F2994A"
    />
  );
};

export default SummaryApplications;
