import React, { useEffect } from 'react';
import CustomBreadcrumb from 'components/common/Breadcrumb';
import i18next from 'i18next';
import { useParams } from 'react-router-dom';
import PageTitle from 'components/common/PageTitle';
import { Button, Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentDataAutomatedResponse } from 'redux/automatedResponses/slice';
import { getByIdCentres } from 'redux/centres/actions';
import { PREFIX_URL_API } from 'configs/localData/constant';
import { useHistory, useLocation } from 'react-router';
import { ReturnIcon } from 'components/common/SVGIcon';
import AutomatedStyles, { AutomatedDetailWrapperStyles } from './styles';
import AutomatedList from './components/AutomatedList';
import AutomatedDetail from './components/AutomatedDetail';
import AlertSelectCenter from './components/AlertSelectCentre';

const AutomatedResponsesPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const currentCentre = useSelector((state) => state.centres.currentData) || {};

  const { goBack } = useHistory();

  const location = useLocation();

  const isUpdateStep = location?.state?.isUpdateStep11;

  const breadcrumbData = [
    {
      title: i18next.t('centres.header'),
      path: '/centres',
    },
    {
      title: currentCentre?.name || i18next.t('error.waitingUpdate'),
      path: `/centres/${id}/show/summary`,
    },
    {
      title: i18next.t('centreDetail.tabs.enquiries'),
      path: `/centres/${id}/show/enquiries`,
    },

    {
      title: i18next.t('automatedResponses.header'),
    },
  ];

  useEffect(() => {
    dispatch(
      getByIdCentres({
        data: { id },
        options: { prefixUrl: PREFIX_URL_API, isRequestApi: true },
      }),
    );
    return () => {
      dispatch(setCurrentDataAutomatedResponse({}));
    };
  }, [id, dispatch]);

  const onReturn = () => {
    goBack();
  };

  return (
    <AutomatedDetailWrapperStyles>
      <AutomatedStyles>
        <CustomBreadcrumb data={breadcrumbData} />
        <PageTitle>{i18next.t('automatedResponses.header')}</PageTitle>

        <div className="automated__content">
          {id ? (
            <Row>
              <Col xl={6} lg={8} md={24} xs={24}>
                <AutomatedList />
              </Col>
              <Col xl={18} lg={16} md={24} xs={24}>
                <AutomatedDetail />
              </Col>
            </Row>
          ) : (
            <AlertSelectCenter />
          )}
        </div>
      </AutomatedStyles>
      {isUpdateStep && (
        <div className="btn-automated text-center">
          <Button
            onClick={onReturn}
            className="btn-return"
            icon={<ReturnIcon />}
          >
            {i18next.t('button.returnToMyCentre')}
          </Button>
        </div>
      )}
    </AutomatedDetailWrapperStyles>
  );
};

export default AutomatedResponsesPage;
