import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { GENDERS } from 'configs/localData';
import { formatBirthday, formatDateApplication } from 'utils/textUtils';
import i18next from 'i18next';
import { Divider } from 'antd';
import RequiredDays from 'components/common/ServiceApplication/RequiredDays';
import ServiceTypes from 'components/common/ServiceApplication/ServiceTypes';

const getYesNoAndText = (value) => {
  const text = value?.trim();
  return {
    yesOrNo: text ? i18next.t('text.yes') : i18next.t('text.no'),
    text: text || i18next.t('text.NA'),
    className: text ? 'yes-value' : 'no-value',
  };
};

const ChildrenItem = ({ item }) => {
  const genderItem = useMemo(
    () =>
      GENDERS.find(
        (gender) => gender.value === item.child?.gender?.toLowerCase(),
      ),
    [item.childId], // eslint-disable-line
  );

  const allergiesAndNeedObj = useMemo(
    () => ({
      allergies: getYesNoAndText(item.child?.allergies),
      specialNeeds: getYesNoAndText(item.child?.specialNeeds),
    }),
    [item.childId], // eslint-disable-line
  );

  return (
    <div className="children-item">
      <div className="children-item__info">
        <div className="children-item__name section-title">
          {`${item.child?.firstName || ''} ${item.child?.lastName || ''}`}
          {genderItem?.icon && (
            <genderItem.icon
              className="children-item__gender"
              style={{
                color: genderItem.color,
              }}
            />
          )}
        </div>

        <div className="children-item__content-item">
          {formatBirthday(item.child?.dateOfBirth)}
        </div>

        <div className="children-item__content-item flex-center-between flex-wrap">
          <div>
            <span className="title-info-item">
              {i18next.t('children.allergies')}
            </span>
            <span
              className={`value-info-item ${allergiesAndNeedObj.allergies.className}`}
            >
              {allergiesAndNeedObj.allergies.yesOrNo}
            </span>
          </div>

          <div>
            <span className="title-info-item">
              {i18next.t('children.specialNeeds')}
            </span>
            <span
              className={`value-info-item ${allergiesAndNeedObj.specialNeeds.className}`}
            >
              {allergiesAndNeedObj.specialNeeds.yesOrNo}
            </span>
          </div>
        </div>
      </div>

      <Divider />

      <div className="service-info">
        <div className="service-info__title section-title">
          {i18next.t('applications.appliedServices')}
        </div>

        <div className="flex-center-between">
          <span>{`${i18next.t('applications.startDate')}:`}</span>
          <span>{formatDateApplication(item.startDate)}</span>
        </div>

        <RequiredDays type="secondary" requiredDays={item.careDays} />
        <ServiceTypes centreServices={item.centreServices} />
      </div>
    </div>
  );
};

ChildrenItem.propTypes = {
  item: PropTypes.object,
};

export default ChildrenItem;
