import { Button } from 'antd';
import i18next from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { resetResourceFilterCentresOfProviders } from 'redux/centresOfProvider/slice';
import { getUsersOfProvider } from 'redux/usersOfProvider/actions';
import CreateModal from './CreateModal';
import FilterUsers from './FilterUsers';
import UsersListTable from './UsersListTable';

const UsersTab = () => {
  const { id: providerId } = useParams();
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);

  const openCreateModal = () => {
    setVisible(true);
  };

  const onCancel = () => {
    setVisible(false);
    dispatch(resetResourceFilterCentresOfProviders());
  };

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh }) => {
      dispatch(
        getUsersOfProvider({
          providerId,
          data: {
            ...filter,
            filter: {
              ...filter.filter,
            },
          },
          options: {
            isRefresh,
          },
        }),
      );
    },
    [providerId, dispatch],
  );

  useEffect(() => {
    retrieveList({
      filter: {
        limit: 10,
        offset: 0,
        orderBy: '-createdAt',
      },
      isRefresh: true,
    });
  }, [retrieveList]);

  return (
    <div>
      <FilterUsers retrieveList={retrieveList} />
      <div className="d-flex justify-end mb-3">
        <Button type="primary" onClick={openCreateModal}>
          {i18next.t('button.create')}
        </Button>
      </div>
      <UsersListTable retrieveList={retrieveList} />
      <CreateModal
        retrieveList={retrieveList}
        visible={visible}
        onCancel={onCancel}
      />
    </div>
  );
};

export default UsersTab;
