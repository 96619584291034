import styled from 'styled-components';

const EditCustomBtnStyles = styled.div`
  .edit-custom-btn {
    height: 32px;
    padding: 0px;
    width: 32px;
    min-width: 32px !important;
    .anticon {
      font-size: 15px;
      color: #f7f8f9;
    }
  }
`;

export default EditCustomBtnStyles;
