import { UserAddOutlined } from '@ant-design/icons';
import TableCustom from 'components/common/TableCustom';
import CustomButtonIcon from 'components/form/CustomButtonIcon';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import {
  formatCentreName,
  formatNightDayTable,
  formatUserName,
  formatTagCustom,
  formatSubscription,
  formatCentreStatus,
} from 'utils/formatFieldUtils';
import { convertObjToSearchStr, getFilterFromUrl } from 'utils/tools';
import {
  getDataClickThroughTracker,
  getLoadingClickThroughTracker,
  getResourceDataClickThroughTracker,
} from 'redux/clickThroughTracker/selectors';
import {
  LIMIT_ITEM,
  SORT_ASCEND_VALUE,
  SORT_DESCEND_VALUE,
} from 'configs/localData/constant';
import MarkContactAction from '../../List/MarkContactAction';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_SORT_CLICK_THROUGH_TRACKER,
} from '../LocalData/constant';

const ClickThroughTrackerListTable = ({ retrieveList, pushQuery }) => {
  const { push } = useHistory();

  const handleAddUser = (record) => {
    push({
      pathname: 'users/create',
      search: convertObjToSearchStr({
        centreIds: record?.centre?.id ? [record.centre.id] : undefined,
        email: record?.centre?.adminEmail,
      }),
    });
  };

  const { search } = useLocation();
  const { filter } = getFilterFromUrl(search);

  const resourceFilter = useSelector(getResourceDataClickThroughTracker) || {};
  const clickThroughData = useSelector(getDataClickThroughTracker);
  const loading = useSelector(getLoadingClickThroughTracker);

  const getSorterOrder = useCallback(
    (source) => {
      if (filter.sorts?.[0] === source) return SORT_ASCEND_VALUE;
      if (filter.sorts?.[0] === `-${source}`) return SORT_DESCEND_VALUE;
      return '';
    },
    [filter],
  );

  const columns = [
    {
      title: i18next.t('common.action'),
      dataIndex: 'isContacted',
      render: (data, record) => (
        <MarkContactAction
          isContacted={data}
          id={record?.id}
          retrieveList={retrieveList}
        />
      ),
      width: 80,
    },
    {
      title: i18next.t('centres.centreName'),
      dataIndex: 'centreName',
      sorter: true,
      render: (data, record) => formatCentreName(data, record?.centre),
      width: '15%',
      defaultSortOrder: getSorterOrder('centreName'),
    },
    {
      title: i18next.t('centres.centreStatus'),
      dataIndex: ['centre', 'claimStatus'],
      render: formatCentreStatus,
      width: 150,
    },
    {
      title: i18next.t('centres.subscription'),
      dataIndex: ['centre', 'subscription'],
      render: (data, record) => formatSubscription(data?.name, record?.centre),
    },
    {
      title: i18next.t('centres.centrePhone'),
      dataIndex: ['centre', 'phone'],
      width: 200,
    },
    {
      title: i18next.t('clickThroughTracker.clickThroughType'),
      dataIndex: 'clickThroughType',
      render: formatTagCustom,
    },
    {
      title: i18next.t('clickThroughTracker.user'),
      dataIndex: 'username',
      sorter: true,
      render: formatUserName,
      width: '15%',
      defaultSortOrder: getSorterOrder('username'),
    },
    {
      title: i18next.t('users.userPhone'),
      dataIndex: ['user', 'phone'],
      width: 150,
    },
    {
      title: i18next.t('clickThroughTracker.clickThroughDate'),
      dataIndex: 'clickThroughDate',
      width: 200,
      sorter: true,
      render: (data) => formatNightDayTable(data),
      defaultSortOrder: getSorterOrder('clickThroughDate'),
    },
    {
      title: '',
      dataIndex: 'id',
      width: 80,
      fixed: 'right',
      render: (data, record) => (
        <CustomButtonIcon
          IconComponent={UserAddOutlined}
          handleClick={() => handleAddUser(record)}
          title="clickThroughTracker.addUser"
        />
      ),
    },
  ];

  const onChange = (e, filters, sorter) => {
    const formatSort =
      sorter && sorter.field && sorter.order
        ? `${sorter.order === SORT_DESCEND_VALUE ? '-' : ''}${sorter.field}`
        : null;

    const restFilter = {
      size: e.pageSize,
      page: e.current,
      sorts: [formatSort || DEFAULT_SORT_CLICK_THROUGH_TRACKER],
      filter: { ...filter.filter },
    };

    retrieveList({
      filter: restFilter,
      isRefresh: true,
      keepFilter: true,
    });
    pushQuery({
      'sorts[]': restFilter.sorts,
      ...restFilter,
    });
  };
  return (
    <div>
      <TableCustom
        xScroll={1800}
        isSorter
        columns={columns}
        onChange={onChange}
        data={clickThroughData}
        loading={loading}
        pagination={{
          pageSize: resourceFilter.size || LIMIT_ITEM,
          current: filter.page || DEFAULT_CURRENT_PAGE,
          total: resourceFilter.total,
        }}
      />
    </div>
  );
};

ClickThroughTrackerListTable.propTypes = {
  retrieveList: PropTypes.func,
  pushQuery: PropTypes.func,
};

ClickThroughTrackerListTable.defaultProps = {
  retrieveList: () => null,
};

export default ClickThroughTrackerListTable;
