import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { getProvidersOfCompany } from 'redux/providersOfCompany/actions';
import { getSummariesProviderOfCompany } from 'redux/companies/actions';
import { resetResourceFilterProvidersOfCompany } from 'redux/providersOfCompany/slice';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import i18next from 'i18next';
import FilterProvider from './FilterProvider';
import ProvidersListTable from './ProvidersListTable';
import SummariesProvider from './SummariesProvider';
import CreateProviderModal from './CreateProviderModal';

const ProvidersTab = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  const retrieveList = useCallback(
    ({ filter = {}, isRefresh, keepFilter }) => {
      dispatch(
        getProvidersOfCompany({
          id,
          data: {
            ...filter,
            filter: {
              ...filter.filter,
            },
          },
          options: {
            keepFilter,
            isRefresh,
            isOrderBy: false,
          },
        }),
      );
    },
    [], // eslint-disable-line
  );

  const getSummaries = (filter) => {
    dispatch(
      getSummariesProviderOfCompany({
        id,
        data: {
          ...filter,
          filter: {
            ...filter.filter,
          },
        },
      }),
    );
  };

  useEffect(() => {
    getSummaries({ filter: {} });
    return () => {
      dispatch(resetResourceFilterProvidersOfCompany());
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    retrieveList({
      filter: {
        limit: 10,
        offset: 0,
      },
      keepFilter: true,
      isRefresh: true,
    });
  }, []); // eslint-disable-line

  const openCreateModal = () => {
    setVisible(true);
  };
  const onCancel = () => {
    setVisible(false);
  };
  return (
    <div>
      <SummariesProvider />
      <FilterProvider retrieveList={retrieveList} />
      <div className="flex-end flex mb-3">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={openCreateModal}
        >
          {i18next.t('button.create')}
        </Button>
      </div>
      <ProvidersListTable retrieveList={retrieveList} />
      <CreateProviderModal
        getSummaries={getSummaries}
        visible={visible}
        onCancel={onCancel}
      />
    </div>
  );
};

export default ProvidersTab;
