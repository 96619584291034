import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import { CardHourStyles } from './styles';
import HourItem from './HourItem';

const CardHour = ({ item, isEdit, itemConst }) => (
  <CardHourStyles>
    <div className="title-card-hour">{i18next.t(itemConst.text)}</div>
    <div className="info-hours">
      <div className="label-item">{i18next.t('text.fromTo')}</div>
      <Row gutter={14}>
        <Col span={12}>
          <HourItem
            isEdit={isEdit}
            source={['openTime', itemConst.value, 'start']}
            time={item.start}
            placeholder="text.from"
          />
        </Col>
        <Col span={12}>
          <HourItem
            isEdit={isEdit}
            source={['openTime', itemConst.value, 'end']}
            time={item.end}
            placeholder="text.to"
          />
        </Col>
      </Row>
    </div>
  </CardHourStyles>
);

CardHour.propTypes = {
  item: PropTypes.object,
  isEdit: PropTypes.bool,
  itemConst: PropTypes.object,
};

CardHour.defaultProps = {
  item: {},
  itemConst: {},
};

export default CardHour;
