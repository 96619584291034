import React, { useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { Button, Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { updateCurrentUser as updateCurrentUserAction } from 'redux/auth/actions';
import ProfileForm from '../Form';
import ProfileDetail from '../ProfileDetail';
import ProfileInfoStyles from './styles';

const ProfileInfo = ({ currentUser }) => {
  const [form] = Form.useForm();
  const { validateFields, resetFields } = form;
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();
  const currentRole = useSelector((state) => state.auth.role);
  const loading = useSelector((state) => state.auth.loading);
  const updateCurrentUser = (params) =>
    dispatch(updateCurrentUserAction(params));
  const handleSubmit = () => {
    validateFields()
      .then(({ email, firstName, lastName, phoneNumber, ...values }) => {
        updateCurrentUser({
          ...values,
          ...(firstName && { firstName: firstName.trim() }),
          ...(lastName && { lastName: lastName.trim() }),
          phoneNumber: phoneNumber?.trim() || null,
        }).then(({ payload }) => {
          payload && handleCancel();
        });
      })
      .catch(() => {});
  };

  const handleClick = () => {
    setIsEdit(!isEdit);
  };

  const handleCancel = () => {
    resetFields();
    setIsEdit(!isEdit);
  };

  return (
    <ProfileInfoStyles>
      {isEdit ? (
        <Form className="edit-section" form={form}>
          <ProfileForm record={currentUser} form={form} />
          <div className="action-section">
            <Button onClick={handleCancel}>{i18n.t('button.cancel')}</Button>
            <Button
              className="submit-button"
              type="primary"
              onClick={handleSubmit}
              loading={loading}
            >
              {i18n.t('button.save')}
            </Button>
          </div>
        </Form>
      ) : (
        <ProfileDetail
          record={currentUser}
          handleClick={handleClick}
          currentRole={currentRole}
          isProfile
        />
      )}
    </ProfileInfoStyles>
  );
};

ProfileInfo.propTypes = {
  currentUser: PropTypes.object,
};

export default ProfileInfo;
