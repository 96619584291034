import React from 'react';
import RestShow from 'containers/rest/Show';
import RestFieldItem from 'components/RestField/RestFieldItem';

const CentreServicesShow = (props) => (
  <RestShow {...props} hasEdit resource="centreServices">
    <RestFieldItem source="name" header="centreServices.name" />
  </RestShow>
);

export default CentreServicesShow;
