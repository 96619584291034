import React from 'react';
import Edit from 'containers/rest/Edit';
import { useDispatch } from 'react-redux';
import { PREFIX_URL } from 'configs/localData/constant';
import { editBrandOfCompanySuccess } from 'redux/brandsOfCompany/slice';
import Form from '../components/Form';

const EditBrandOfCompany = (props) => {
  const dispatch = useDispatch();

  const successActionAfterEdit = (payload) => {
    dispatch(editBrandOfCompanySuccess(payload));
  };

  return (
    <Edit
      {...props}
      resource="brands"
      defaultOptions={{
        prefixUrl: PREFIX_URL.superAdmin,
        successAction: successActionAfterEdit,
      }}
    >
      <Form isDetailCompany />
    </Edit>
  );
};

export default EditBrandOfCompany;
