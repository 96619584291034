import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import I18n from 'i18next';
import { Layout, Menu } from 'antd';
import { checkRole } from 'utils/tools';
import { useHistory } from 'react-router';
import { ShopOutlined, StarOutlined, UserOutlined } from '@ant-design/icons';

import { EDM_TAB_KEYS_CONST } from 'configs/localData/constant';

import {
  CompaniesIcon,
  DashBoardIcon,
  InsightsIcon,
  MarketingIcon,
  ProviderIcon,
  SalesIcon,
  SettingOutlineIcon,
} from 'components/common/SVGIcon';
import Logo from '../../assets/images/logo.svg';
import FullLogo from '../../assets/images/logo_plus.svg';

const getCurrentTab = (str, key) => {
  const paths = str && str.split('/');
  return paths && paths[key];
};

const SideBar = ({ collapsed }) => {
  const role = useSelector((state) => state.auth.role);
  const history = useHistory();

  const pathnameCurrent = window.location.pathname;

  const url = pathnameCurrent.startsWith('/settings')
    ? getCurrentTab(pathnameCurrent, 2)
    : getCurrentTab(pathnameCurrent, 1);

  const menus = {
    newFeatureMenu: [
      {
        key: 'dashboard',
        text: 'sideBar.dashboard',
        Icon: DashBoardIcon,
        subMenu: [
          {
            key: 'searchStatistics',
            text: 'sideBar.searchStatistics',
            url: '/searchStatistics',
          },
          {
            key: 'dashboardConsumerStatistics',
            text: 'sideBar.consumerStatistics',
            url: '#',
          },
          {
            key: 'dashboardProviderStatistics',
            text: 'sideBar.providerStatistics',
            url: '#',
          },
          {
            key: 'dashboardApplications',
            text: 'sideBar.applications',
            url: '#',
          },
          {
            key: 'dashboardEnquiries',
            text: 'sideBar.enquiries',
            url: '#',
          },
          {
            key: 'dashboardVisitsAndTours',
            text: 'sideBar.visitsAndTours',
            url: '#',
          },
          {
            key: 'dashboardContentStatistics',
            text: 'sideBar.contentStatistics',
            url: '#',
          },
          {
            key: 'dashboardPlatformStatistics',
            text: 'sideBar.platformStatistics',
            url: '#',
          },
        ],
      },
      {
        key: 'companies',
        text: 'sideBar.companies',
        Icon: CompaniesIcon,
        url: '/companies',
      },
      {
        key: 'providers',
        text: 'sideBar.providers',
        Icon: ProviderIcon,
        url: '/providers',
      },
      {
        key: 'centres',
        text: 'sideBar.centres',
        Icon: ShopOutlined,
        url: '/centres',
      },
      {
        key: 'users',
        text: 'sideBar.users',
        Icon: UserOutlined,
        url: '/users',
      },
      {
        key: 'sales',
        text: 'sideBar.sales',
        Icon: SalesIcon,
        subMenu: [
          {
            key: 'applications',
            text: 'sideBar.applications',
            url: '/applications',
          },
          {
            key: 'inboxes',
            text: 'sideBar.enquiries',
            url: '/inboxes',
          },
          {
            key: 'clickThroughTracker',
            text: 'sideBar.clickThroughTracker',
            url: '/clickThroughTracker',
          },
          {
            key: 'jobApplicationsTracker',
            text: 'sideBar.JobApplicationsTracker',
            url: '/jobApplicationsTracker',
          },
        ],
      },
      {
        key: 'marketing',
        text: 'sideBar.marketing',
        Icon: MarketingIcon,
        subMenu: [
          {
            key: 'articles',
            text: 'sideBar.articles',
            url: '/articles',
          },
          {
            key: 'notifications',
            text: 'sideBar.notifications',
            url: '/notifications',
          },
          {
            key: 'EDM',
            text: 'sideBar.emailAndEDMCampaigns',
            url: `/EDM/${EDM_TAB_KEYS_CONST.favorites}`,
          },
        ],
      },
      {
        key: 'customerCare',
        text: 'sideBar.customerCare',
        Icon: StarOutlined,
        subMenu: [
          {
            key: 'claimCentreRequests',
            text: 'sideBar.claimCentres',
            url: '/claimCentreRequests',
          },
          {
            key: 'disputeCentreRequests',
            text: 'sideBar.disputeCentres',
            url: '/disputeCentreRequests',
          },
          {
            key: 'disputeReviewRequests',
            text: 'sideBar.disputeReviews',
            url: '/disputeReviewRequests',
          },
          {
            key: 'brands',
            text: 'sideBar.brands',
            url: '/brands',
          },
        ],
      },
      {
        key: 'insights',
        text: 'sideBar.insights',
        Icon: InsightsIcon,
        subMenu: [
          {
            key: 'dashboardEvents',
            text: 'sideBar.platformActivities',
            url: '/',
          },
        ],
      },
      {
        key: 'settings',
        text: 'sideBar.settings',
        Icon: SettingOutlineIcon,
        subMenu: [
          {
            key: 'appSettings',
            text: 'settings.tabs.appSettings',
            url: '/settings/appSettings',
          },
          {
            key: 'services',
            text: 'settings.tabs.services',
            url: '/settings/services',
          },
          {
            key: 'NQSRTypes',
            text: 'settings.tabs.NQSRTypes',
            url: '/settings/NQSRTypes',
          },
          {
            key: 'features',
            text: 'settings.tabs.features',
            url: '/settings/features',
          },
          {
            key: 'marketings',
            text: 'settings.tabs.marketing',
            url: '/settings/marketings',
          },
          {
            key: 'suburbs',
            text: 'settings.tabs.suburbs',
            url: '/settings/suburbs',
          },
          {
            key: 'CRMPackages',
            text: 'sideBar.CRMPackages',
            url: '/settings/CRMPackages',
          },
        ],
      },
    ],
  };

  const renderMenu = (menuArray) =>
    menuArray.map((menu) => {
      if (!checkRole(menu.roles, role)) {
        return null;
      }
      return menu.subMenu ? (
        <Menu.SubMenu
          key={menu.key}
          icon={<menu.Icon />}
          title={I18n.t(menu.text)}
        >
          {menu.subMenu.map((item) => (
            <Menu.Item key={item.key} onClick={() => history.push(item.url)}>
              <span>{I18n.t(item.text)}</span>
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      ) : (
        <Menu.Item
          key={menu.key}
          title={I18n.t(menu.text)}
          onClick={() => history.push(menu.url)}
          icon={<menu.Icon />}
        >
          {!collapsed && (
            <span className="menu-label">{I18n.t(menu.text)}</span>
          )}
        </Menu.Item>
      );
    });
  return (
    <div className={`sider-wrapper sider-wrapper-${collapsed}`}>
      <Layout.Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        className="sidebar"
        collapsedWidth={64}
      >
        <div className="logo">
          {collapsed && <img alt="" src={Logo} />}
          <img alt="" src={FullLogo} className="fullLogo" />
        </div>
        <Menu
          mode="inline"
          selectedKeys={[url || 'dashboardEvents']}
          defaultSelectedKeys={[url || 'dashboardEvents']}
        >
          {renderMenu(menus.newFeatureMenu)}
        </Menu>
      </Layout.Sider>
    </div>
  );
};

SideBar.propTypes = {
  collapsed: PropTypes.bool,
};

export default SideBar;
