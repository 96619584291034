import React from 'react';
import TagCustom from 'components/common/TagCustom';
import UserInfo from 'components/RestField/UserInfo';
import {
  DASH_PLACEHOLDER_CHAR,
  DEFAULT_TAG_BACKGROUND_COLOR,
  DEFAULT_TAG_BORDER_COLOR,
  DEFAULT_WORKPLACE_TYPE,
  JOB_LISTING_STATUSES,
} from 'containers/JobApplicationsTracker/constants';
import WorkplaceLink from 'containers/JobApplicationsTracker/components/WorkplaceLink';
import { Tooltip } from 'antd';
import { BriefIcon } from 'components/common/SVGIcon';

export const transformApplicationStatus = (status) =>
  status ? (
    <TagCustom
      {...{
        ...status,
        color: status?.textColor,
        isCheckBorderTag: true,
        borderColor: status?.textColor,
      }}
    />
  ) : null;

export const renderApplicantComponent = (allTrackerData) => {
  const { name: fullName, ...restApplicantData } = allTrackerData?.applicant;
  const applicantProps = {
    record: { fullName, ...restApplicantData },
    roleProp: 'email',
    prefixLink: 'users',
    suffixLink: 'show',
  };
  return <UserInfo {...applicantProps} />;
};

export const renderJobReference = (allTrackerData) => {
  const {
    jobPost: { listingStatus, reference },
  } = allTrackerData;
  return listingStatus === 'Deleted' ? (
    DASH_PLACEHOLDER_CHAR
  ) : (
    <span className="ellipsis-t-1">{reference}</span>
  );
};

export const renderJobListingStatus = (jobListingStatus) => {
  const statusFound = JOB_LISTING_STATUSES.find(
    (status) => status.value === jobListingStatus,
  );
  return statusFound ? (
    <TagCustom
      className="fw-bold"
      backgroundColor={statusFound.backgroundColor}
      color={statusFound.color}
      text={statusFound.text}
    />
  ) : null;
};

export const renderNormalData = (data) => (
  <Tooltip title={data} className="ellipsis-t-2">
    {data}
  </Tooltip>
);

export const renderBrand = (allTrackerData) => {
  const brandName = allTrackerData?.organization?.brand;
  return brandName ? (
    <div className="inline-block">
      <Tooltip title={brandName} className="ellipsis-t-2">
        {brandName}
      </Tooltip>
    </div>
  ) : (
    DASH_PLACEHOLDER_CHAR
  );
};

export const renderJobType = (jobTypeData) => (
  <TagCustom
    IconCPN={BriefIcon}
    backgroundColor={DEFAULT_TAG_BACKGROUND_COLOR}
    borderColor={DEFAULT_TAG_BORDER_COLOR}
    text={jobTypeData}
    isCheckBorderTag
  />
);

export const renderWorkplace = (allTrackerData) => {
  const currentWorkplaceType = allTrackerData?.organization?.type;
  const workplaceName = allTrackerData?.organization?.name;
  let id = allTrackerData?.organization?.centreId;
  let workplaceTypePath = 'centres';

  if (currentWorkplaceType !== DEFAULT_WORKPLACE_TYPE) {
    id = allTrackerData?.organization?.companyId;
    workplaceTypePath = 'companies';
  }
  return (
    <WorkplaceLink
      href={`/${workplaceTypePath}/${id}/show/summary`}
      workplaceName={workplaceName}
    />
  );
};
