import React from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import SummaryCardCustom from 'components/common/SummaryCardCustom';
import {
  SummaryBlueIcon,
  SummaryCheckedIcon,
  SummaryRedIcon,
} from 'components/common/SVGIcon';

const SummariesSection = ({ summariesData }) => {
  const [totalJob, totalOpenJob, totalEmployed] = summariesData;

  const summariesBlock = [
    {
      title: i18next.t('jobApplicationsTracker.summaries.totalJobApplications'),
      value: totalJob?.value || 0,
      IconComponent: SummaryBlueIcon,
    },
    {
      title: i18next.t(
        'jobApplicationsTracker.summaries.totalOpenJobApplications',
      ),
      value: totalOpenJob?.value || 0,
      IconComponent: SummaryRedIcon,
    },
    {
      title: i18next.t('jobApplicationsTracker.summaries.totalEmployed'),
      value: totalEmployed?.value || 0,
      IconComponent: SummaryCheckedIcon,
    },
  ];

  return (
    <Row gutter={[25, 25]} className="mb-20">
      {summariesBlock.map((block, index) => (
        <Col lg={8} md={12} xs={24} key={index}>
          <SummaryCardCustom
            IconComponent={block.IconComponent}
            title={block.title}
            count={block.value}
          />{' '}
        </Col>
      ))}
    </Row>
  );
};

SummariesSection.propTypes = {
  summariesData: PropTypes.array,
};

export default SummariesSection;
