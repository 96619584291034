import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import i18next from 'i18next';
import { responseDisputeReview } from 'redux/disputeReviews/actions';
import ModalCustom from 'components/common/ModalCustom';
import Reviews from '../components/Reviews';
import DisputeForm from '../components/Form';

const DisputeReviewModal = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false);
  const [disputeId, setDisputeId] = useState();
  const [loading, setLoading] = useState();
  const [form] = Form.useForm();

  const disputeReviewData = useSelector(
    (state) => state.disputeReviewRequests?.data?.[disputeId],
  );

  useImperativeHandle(
    ref,
    () => ({
      open: (id) => {
        setVisible(true);
        setDisputeId(id);
      },
    }),
    [],
  );

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  const dispatch = useDispatch();

  const onSubmit = () => {
    form
      .validateFields()
      .then(({ message, ...values }) => {
        setLoading(true);
        return dispatch(
          responseDisputeReview({
            id: disputeId,
            data: {
              ...values,
              message: message?.trim(),
            },
          }),
        );
      })
      .then(({ payload }) => {
        setLoading(false);
        if (payload?.success) {
          onCancel();
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };
  return (
    <ModalCustom
      visible={visible}
      okText="button.done"
      onCancel={onCancel}
      onOk={onSubmit}
      title={i18next.t('disputeReviewRequests.title')}
      loading={loading}
      isDisabled={disputeReviewData?.disputation?.status !== 'pending'}
      width={1000}
    >
      <Reviews record={disputeReviewData} />
      <Form form={form}>
        <DisputeForm record={disputeReviewData?.disputation} />
      </Form>
    </ModalCustom>
  );
});

export default DisputeReviewModal;
