import styled from 'styled-components';

export const ReplySectionStyles = styled.div``;

export const FormCommentStyles = styled.div`
  .content-form-reply {
    width: 100%;
    display: flex;
    align-items: center;
    width: 100%;
    display: flex;
    align-items: center;
    background: #f7f8f9;
    padding: 10px;
    border-radius: 15px;
  }
  .cancel-button {
    color: ${({ theme }) => theme.palette.primary};
    border: 1px solid ${({ theme }) => theme.palette.primary};
  }
  textarea {
    font-size: 16px;
    resize: none;
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #f0f3fa;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #b7b6c2;
    }
  }
  .btn-reply {
    color: ${({ theme }) => theme.palette.primary};
    border: none;
    background: transparent;
    .anticon {
      font-size: 20px;
      transform: rotate(45deg);
    }
  }
`;
