import React, { forwardRef, useState, useImperativeHandle } from 'react';
import { PropTypes } from 'prop-types';

import CompaniesModal from '.';
import Form from '../Form';

const CompanyGeneralInfoModal = forwardRef(({ record }, ref) => {
  const [visible, setVisible] = useState(false);

  const onCancel = () => setVisible(false);

  useImperativeHandle(
    ref,
    () => ({
      toggleModal: () => {
        setVisible(true);
      },
    }),
    [],
  );
  const formatOnSubmit = ({ userId, ...data }) => ({
    ...data,
    userId: userId === undefined ? null : userId,
  });

  return (
    <CompaniesModal
      onCancel={onCancel}
      visible={visible}
      record={record}
      title="companies.editPage"
      width={500}
      formatOnSubmit={formatOnSubmit}
    >
      <Form />
    </CompaniesModal>
  );
});
CompanyGeneralInfoModal.propTypes = {
  record: PropTypes.object,
};
export default CompanyGeneralInfoModal;
