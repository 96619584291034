import { createSelector } from 'reselect';

const getRawDataTotalSearchStatistic = (state) =>
  state.totalSearchStatistic?.data;
const getRawTotalSearchStatisticLoading = (state) =>
  state.totalSearchStatistic?.loading;

export const getDataTotalSearchStatistic = createSelector(
  [getRawDataTotalSearchStatistic],
  (data) => data,
);

export const getLoadingTotalSearchStatistic = createSelector(
  [getRawTotalSearchStatisticLoading],
  (data) => data,
);
