import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { convertParamsToObject } from 'utils/url';
import { useLocation } from 'react-router';
import { useEnquiryContext } from './context';
import StatusGroup from '../components/StatusGroup';
import ContactEnquiry from '../components/ContactEnquiry';
import MessageDetail from '../components/MessageDetail';
import MessageList from '../components/MessageList';

const ChatBoxList = ({ getListAndSummary, retrieveList }) => {
  const containerRef = useRef();
  const location = useLocation();

  const inboxId = convertParamsToObject(location.search)?.inboxId;

  const { collapsed } = useEnquiryContext();

  const containerHeight = useMemo(
    () =>
      `calc(100vh - ${
        containerRef.current?.getBoundingClientRect().top + 78 + 10
      }px)`,
    [],
  );

  return (
    <div
      ref={containerRef}
      style={{ height: containerHeight, minHeight: 500 }}
      className="wrapper-relative"
    >
      <div className="wrapper-absolute">
        <Row className="enquiries__row-wrapper" gutter={16} wrap={false}>
          <Col
            xl={collapsed ? 24 : 18}
            lg={collapsed ? 24 : 16}
            md={24}
            xs={24}
            className="enquiries__chat-box-col"
          >
            <div className="enquiries__chat-box">
              <StatusGroup retrieveList={retrieveList} />
              <div className="enquiries__chat-wrapper wrapper-relative">
                <div className="wrapper-absolute">
                  <Row className="enquiries__row-list">
                    <Col
                      xxl={10}
                      xl={10}
                      lg={10}
                      md={10}
                      xs={10}
                      className="enquiries__inbox-col"
                    >
                      <MessageList
                        inboxId={inboxId}
                        retrieveList={retrieveList}
                        getListAndSummary={getListAndSummary}
                      />
                    </Col>
                    <Col
                      xxl={14}
                      xl={14}
                      lg={14}
                      md={14}
                      xs={14}
                      className="enquiries__message-col"
                    >
                      <MessageDetail inboxId={inboxId} />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
          <Col
            xxl={6}
            xl={6}
            lg={8}
            md={0}
            xs={0}
            className={`enquiries__contact-col ${
              collapsed ? 'enquiries__contact-collapsed' : ''
            }`}
          >
            <ContactEnquiry />
          </Col>
        </Row>
      </div>
    </div>
  );
};

ChatBoxList.propTypes = {
  getListAndSummary: PropTypes.func,
  retrieveList: PropTypes.func,
};

export default ChatBoxList;
