import { createSlice } from '@reduxjs/toolkit';
import {
  getAllCustomFail,
  getAllCustomPending,
  getAllCustomSuccess,
  INITIAL_STATE_CUSTOM,
  resetResourceDataCustom,
} from 'redux/crudCreatorCustom/utils';
import { getUsersOfProvider } from './actions';

export const initialState = {
  ...INITIAL_STATE_CUSTOM,
};

const { actions, reducer } = createSlice({
  name: 'usersOfProviders',
  initialState,
  reducers: {
    resetResourceFilterCentresOfProviders: resetResourceDataCustom,
  },
  extraReducers: {
    [getUsersOfProvider.pending]: getAllCustomPending,
    [getUsersOfProvider.fulfilled]: getAllCustomSuccess,
    [getUsersOfProvider.rejected]: getAllCustomFail,
  },
});

export const { resetResourceFilterCentresOfProviders } = actions;

export default reducer;
