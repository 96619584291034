import React, { forwardRef, useState, useImperativeHandle } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';

import ModalCustom from 'components/common/ModalCustom';
import FooterAction from './FooterAction';
import ModalStyles from './styles';

const ConfirmClaimCentreModal = forwardRef(({ handleConfirm }, ref) => {
  const [visible, setVisible] = useState(false);
  const [record, setRecord] = useState({});

  useImperativeHandle(
    ref,
    () => ({
      openModal: (data) => {
        setVisible(true);
        setRecord(data);
      },
    }),
    [],
  );

  const onCancel = () => {
    setVisible(false);
  };

  return (
    <ModalCustom
      visible={visible}
      title={i18next.t('claimCentreRequests.confirmClaimCentreModal.title')}
      onCancel={onCancel}
      footer={
        <FooterAction
          record={record}
          handleConfirm={handleConfirm}
          onCancel={onCancel}
        />
      }
    >
      <ModalStyles>
        {i18next.t('claimCentreRequests.confirmClaimCentreModal.content')}{' '}
        <b>
          {record.centre?.name ||
            i18next.t('claimCentreRequests.confirmClaimCentreModal.thisCentre')}
        </b>
        {' ?'}
      </ModalStyles>
    </ModalCustom>
  );
});

ConfirmClaimCentreModal.propTypes = {
  handleConfirm: PropTypes.func,
};

export default ConfirmClaimCentreModal;
