import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';

import { changePassword } from 'redux/auth/actions';
import ModalCustom from 'components/common/ModalCustom';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import FormChangePassword from './FormChangePassword';

const ModalChangePassword = ({ visible, onCancel }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const handleOnSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        dispatch(
          changePassword({
            currentPassword: values.oldPassword,
            password: values.newPassword,
          }),
        );
        handleOnCancel();
      })
      .catch(() => {});
  };
  const handleOnCancel = () => {
    form.resetFields();
    onCancel();
  };
  return (
    <ModalCustom
      title={i18next.t('profile.titleChangePassword')}
      visible={visible}
      onCancel={handleOnCancel}
      onOk={handleOnSubmit}
    >
      <Form form={form}>
        <RestInputContext.Provider value={{ form }}>
          <FormChangePassword form={form} />
        </RestInputContext.Provider>
      </Form>
    </ModalCustom>
  );
};
ModalChangePassword.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
};
export default ModalChangePassword;
