import React from 'react';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

const PercentValue = ({ record, text, ...props }) => (
  <Tooltip
    title={`vs. ${props.startDate} - ${props.endDate}`}
    color="white"
    overlayInnerStyle={{
      color: '#000000',
      fontSize: '16px',
      letterSpacing: '0.1px',
      width: 'max-content',
    }}
  >
    <div className="d-flex">
      {record.changeType === 'Up' ? (
        <CaretUpOutlined className="common-value-green" />
      ) : (
        <CaretDownOutlined className="common-value-red" />
      )}
      <span
        className={`${
          record.changeType === 'Up'
            ? 'common-value-green border-green'
            : 'common-value-red border-red'
        }`}
      >
        {`${text}`}
      </span>
    </div>
  </Tooltip>
);

PercentValue.propTypes = {
  record: PropTypes.object,
  text: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};
export default PercentValue;
