import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  delCategoriesApi,
  editCategoriesApi,
  getAllCategoriesApi,
  postCategoriesApi,
} from 'api/categories';
import { push } from 'connected-react-router';
import { apiWrapper } from 'utils/reduxUtils';

export const MODEL_NAME = 'categories';

export const getAllCategories = createAsyncThunk(
  'categories/getAllCategories',
  async (payload = {}, thunkAPI) => {
    try {
      const { data } = payload;
      const response = await apiWrapper(
        { isShowSuccessNoti: false },
        getAllCategoriesApi,
        data,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const createCategories = createAsyncThunk(
  'categories/createCategories',
  async (payload = {}, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        postCategoriesApi,
        payload.data,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const editCategories = createAsyncThunk(
  'categories/editCategories',
  async (payload = {}, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        editCategoriesApi,
        payload.id,
        payload.data,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const delCategories = createAsyncThunk(
  'categories/delCategories',
  async (payload = {}, thunkAPI) => {
    try {
      const categoryIdSelected =
        thunkAPI.getState().articles.filter?.categoryId;

      const categoryIdDefault =
        thunkAPI.getState().categories.treeData?.[0]?.id;

      await apiWrapper({ isShowSuccessNoti: true }, delCategoriesApi, payload);

      if (categoryIdSelected === payload) {
        if (categoryIdDefault)
          thunkAPI.dispatch(
            push(`?categoryId=${JSON.stringify(categoryIdDefault)}`),
          );
        else thunkAPI.dispatch(push('/articles'));
      }

      return payload;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
