import React, { useCallback } from 'react';
import { Button, Modal, Space, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  clearCurrentInboxes,
  clearMessages,
  delInboxesOfCentre,
} from 'redux/inboxes/actions';

import { ActionButtonStyles } from './styles';
import SeeContactBtn from './SeeContactBtn';

const { confirm } = Modal;

const ActionButton = ({ id, centreHasNewMessage }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleDeleteInbox = useCallback(async () => {
    if (id) {
      dispatch(delInboxesOfCentre({ id, centreHasNewMessage })).then(
        ({ payload }) => {
          if (payload.firstInboxId) {
            history.replace(`?inboxId=${payload.firstInboxId}`);
          } else if (payload?.id) {
            dispatch(clearCurrentInboxes());
            dispatch(clearMessages());
            history.replace(history.location.pathname);
          }
        },
      );
    }

    // eslint-disable-next-line
  }, [id, centreHasNewMessage]);

  const showConfirmDelete = useCallback(() => {
    confirm({
      title: i18next.t('inboxes.titleConfirmDeleteEnquiry'),
      content: i18next.t('inboxes.confirmDeleteEnquiry'),
      onOk() {
        handleDeleteInbox();
      },
    });
  }, [handleDeleteInbox]);

  return (
    <ActionButtonStyles>
      <Space>
        <Tooltip title={i18next.t('button.delete')}>
          <Button
            size="small"
            shape="circle"
            onClick={showConfirmDelete}
            icon={<DeleteOutlined />}
            className="btn-info-fill delete-button"
          />
        </Tooltip>
        <SeeContactBtn />
      </Space>
    </ActionButtonStyles>
  );
};

ActionButton.propTypes = {
  id: PropTypes.string,
  centreHasNewMessage: PropTypes.bool,
};

export default ActionButton;
