import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTotalSearchStatisticApi } from 'api/statistic';
import { apiWrapper } from 'utils/reduxUtils';
import { convertRequestParams } from 'redux/crudCreator/dataProvider';

export const getTotalSearchStatistic = createAsyncThunk(
  'totalSearchStatistic/getTotalSearchStatistic',
  async (payload = {}, thunkApi) => {
    try {
      const { data } = payload;

      const convertRequest = convertRequestParams(
        'GET_ALL',
        {
          filter: payload.filter,
          ...data,
        },
        'totalSearchStatistic',
        payload.options,
      );

      const response = await apiWrapper(
        {},
        getTotalSearchStatisticApi,
        convertRequest,
      );
      return [...response];
    } catch (error) {
      return thunkApi.rejectWithValue({ id: payload.data.id, data: error });
    }
  },
);
