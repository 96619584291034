import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { delCentreService, getByIdCentres } from 'redux/centres/actions';
import EditButton from 'components/RestActions/EditButton';
import DeleteButton from 'components/RestActions/DeleteButton';
import { PREFIX_URL } from 'configs/localData/constant';
import { useParams } from 'react-router';
import { useServiceModal } from '../Modals/context';

const GroupAction = ({ id, record }) => {
  const { toggleAndSetRecord } = useServiceModal();

  const { id: centreId } = useParams();

  const dispatch = useDispatch();

  const onClickEditBtn = useCallback(() => {
    toggleAndSetRecord(record);
  }, [toggleAndSetRecord, record]);

  const deleteItem = useCallback(async () => {
    await dispatch(delCentreService(id));
    await dispatch(
      getByIdCentres({
        data: { id: centreId },
        options: { isRequestApi: true, prefixUrl: PREFIX_URL.superAdmin },
      }),
    );
  }, [id, centreId, dispatch]);

  return (
    <div className="group-actions">
      <EditButton
        permissionKey="myCentres"
        action="isEdit"
        onClickCustom={onClickEditBtn}
      />
      <DeleteButton
        permissionKey="myCentres"
        action="isEdit"
        deleteItem={deleteItem}
        record={record}
        customMessage="centreServices.customMessageDelete"
        resource="centreServices"
      />
    </div>
  );
};

GroupAction.propTypes = {
  record: PropTypes.object,
  id: PropTypes.string,
};

export default GroupAction;
