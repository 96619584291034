import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUsersOfProviderApi, delUserOfProviderApi } from 'api/providers';
import { getAllCustom } from 'redux/crudCreatorCustom/actions';
import { apiWrapper } from 'utils/reduxUtils';

export const MODEL_NAME = 'usersOfProvider';

export const getUsersOfProvider = getAllCustom(
  MODEL_NAME,
  getUsersOfProviderApi,
  'providerId',
);

export const delUserOfProvider = createAsyncThunk(
  'centres/delUserOfProvider',
  async (payload = {}, thunkAPI) => {
    try {
      const { id, userId } = payload;
      const { total, offset, limit } =
        thunkAPI.getState().companiesOfUser?.resourceData || {};

      await apiWrapper({ isShowSuccessNoti: true }, delUserOfProviderApi, id, {
        userId,
      });

      await thunkAPI.dispatch(
        getUsersOfProvider({
          providerId: id,
          data: {
            offset: offset > 0 && total % offset <= 1 ? offset - limit : offset,
            orderBy: '-createdAt',
          },
          options: { isRefresh: true, keepFilter: true },
        }),
      );

      return { id, userId, success: true };
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error, id: payload });
    }
  },
);
