import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { companiesSelectors } from 'redux/companies/selectors';
import ListCheckboxSelect from './ListCheckboxSelect';

const CompanyListSelect = ({ retrieveList }) => {
  const currentData =
    useSelector((state) => state.companies?.currentData) || {};
  const data = [currentData];
  const loading = useSelector(companiesSelectors.getLoading);

  return (
    <ListCheckboxSelect
      retrieveList={retrieveList}
      name="companyIds"
      defaultValue={[currentData?.id]}
      data={data}
      messageRequired="users.selectCompanyRequired"
      selectedResourceTitles="users.selectedCompaniesTotal"
      selectedResourceTitle="users.selectedCompanyTotal"
      loading={loading}
    />
  );
};

CompanyListSelect.propTypes = {
  retrieveList: PropTypes.func,
};

export default CompanyListSelect;
