import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const initialValue = {
  template: {},
  setTemplate: (data) => data,
  centreId: null,
  setCentreId: (data) => data,
};

const EmailTemplateContext = createContext(initialValue);

export const EmailTemplateProvider = ({ children }) => {
  const [template, setTemplate] = useState({});
  const [centreId, setCentreId] = useState();

  const value = {
    template,
    setTemplate,
    centreId,
    setCentreId,
  };

  return (
    <EmailTemplateContext.Provider value={value}>
      {children}
    </EmailTemplateContext.Provider>
  );
};

EmailTemplateProvider.propTypes = {
  children: PropTypes.node,
};

export const useEmailTemplate = () => {
  const { template, setTemplate, centreId, setCentreId } =
    useContext(EmailTemplateContext);

  return {
    template,
    setTemplate,
    centreId,
    setCentreId,
  };
};
