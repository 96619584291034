import React, {
  useState,
  useMemo,
  useCallback,
  useContext,
  useRef,
} from 'react';
import { Form, Upload, notification } from 'antd';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { remove, takeRight, last } from 'lodash-es';
import { getUrl, uploadMedia } from 'api/uploadMedia';
import { logoutSuccess } from 'redux/auth/slice';
import { getRecordData } from 'utils/tools';
import CRUDActions from 'redux/crudActions';

import { RestInputContext } from 'components/RestInput/RestInputContext';
import { UploadOutlined } from '@ant-design/icons';
import { resizeImageFile } from 'utils/fileUtils';
import UploadImageItem from './UploadImageItem';
import { FormPhotosStyles } from './styles';
import ModalPreview from './ModalPreview';
import {
  makeFileList,
  formatNewFileListWithFeature,
  formatNewFileListWithAttachVideoUrl,
} from './utils';
import URLVideoForm from './URLVideoForm';
import SortableGrid from './SortableGrid';

const RestUploadFile = ({
  source,
  setIsDisabled,
  multiple,
  accept,
  label,
  formatResult,
  onlyShowImg,
  required,
  isSetFeature,
  isAttachURLVideo,
  resource,
  isDrapDrop,
  responsiveSortable,
  prefixKey,
}) => {
  const dispatch = useDispatch();

  const { form, record } = useContext(RestInputContext);

  const modalPreviewRef = useRef();

  const initialValue = useMemo(
    () => getRecordData(record, source),
    [record, source],
  );

  const [fileList, setFileList] = useState(makeFileList(initialValue) || []);

  const customRequest = async ({ onSuccess, onError, file }) => {
    const compressedFile = await resizeImageFile(file);
    if (compressedFile) {
      try {
        const responseS3 = await getUrl(
          `${prefixKey || ''}${compressedFile.name}`,
          compressedFile.type,
        );

        const response = await uploadMedia(
          responseS3.uploadUrl,
          compressedFile,
        );
        if (response?.status === 200) {
          onSuccess(responseS3.url, compressedFile);
        } else {
          onError(null, { status: 'done' });
        }
      } catch (error) {
        onError(null, { status: 'done' });
        if (error.code === 401) {
          dispatch(logoutSuccess());
          notification.error({
            message: i18next.t('error.title'),
            description: i18next.t('error.error401'),
            duration: 2,
            position: 'tr',
          });
        } else
          notification.error({
            message: i18next.t('error.title'),
            description: i18next.t('error.description'),
            position: 'tr',
            duration: 2,
          });
      }
    } else onError(null, { status: 'done' });
  };

  const onChangeUpload = (e) => {
    isDisableButtonSubmit(e.fileList);
    if (multiple) setFileList(e.fileList);
    else setFileList(takeRight(e.fileList));
  };

  const formatMultiFileResult = useCallback(
    (results) =>
      results?.map((data) => {
        const item = {
          key: data.name || data.key,
          url: data.response || data.url,
        };
        if (isSetFeature) item.featured = data.featured;
        if (isAttachURLVideo) {
          item.source = data.source;
          item.type = data.type;
        }

        return item;
      }),
    [isSetFeature, isAttachURLVideo],
  );

  const formatValueFile = useCallback(
    (results) => {
      if (multiple) {
        return formatResult
          ? formatResult(results)
          : formatMultiFileResult(results);
      }

      const newFile = last(results);
      if (newFile) {
        return formatResult
          ? formatResult(newFile)
          : {
              key: newFile.name || newFile.key,
              url: newFile.response || newFile.url,
            };
      }
      return {};
    },
    [formatResult, multiple, formatMultiFileResult],
  );

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return formatValueFile(e);
    }
    return formatValueFile(e?.fileList);
  };

  const isDisableButtonSubmit = (newFileList) => {
    let isDisabled =
      newFileList &&
      newFileList.length >= 0 &&
      newFileList?.filter((i) => {
        if (i.url !== undefined) return false;
        if (multiple && i.response) return i.status === 'error';
        return i.response === undefined;
      }).length > 0;

    if (!multiple && !isDisabled)
      isDisabled = newFileList[newFileList.length - 1]?.status === 'error';

    setIsDisabled && setIsDisabled(isDisabled);

    resource &&
      dispatch(CRUDActions[resource].setIsDisabledButtonSubmit(isDisabled));
  };

  const deleteImage = (index) => {
    const newList = remove(fileList, (obj, idx) => idx !== index);
    setFileList(newList);
    form.setFieldsValue({ [source]: formatValueFile(newList) });
  };

  const onSetFeature = useCallback(
    (index) => {
      const newFileListObj = formatNewFileListWithFeature(fileList, index);
      setFileList(newFileListObj.fileList);
      form.setFieldsValue({
        [source]: newFileListObj.fileListFormValue,
      });
    },
    [source, fileList, form],
  );

  const onPreviewImage = (url) => {
    modalPreviewRef.current && modalPreviewRef.current.toggleModal(url);
  };

  const addURLVideo = (url) => {
    const newFileListObj = formatNewFileListWithAttachVideoUrl(fileList, url);

    setFileList(newFileListObj.fileList);

    form.setFieldsValue({
      [source]: newFileListObj.fileListFormValue,
    });
  };

  const onChangeSortable = (newList) => {
    setFileList(newList);
    form.setFieldsValue({ [source]: formatValueFile(newList) });
  };

  return (
    <FormPhotosStyles>
      <Form.Item
        className="form-item-upload-image"
        label={i18next.t(label)}
        name={source}
        initialValue={initialValue}
        getValueFromEvent={normFile}
        rules={[{ required, message: i18next.t('error.requiredFile') }]}
      >
        <Upload.Dragger
          multiple={multiple}
          accept={accept}
          showUploadList={false}
          name="files"
          fileList={fileList}
          customRequest={customRequest}
          onChange={onChangeUpload}
        >
          <p className="ant-upload-drag-icon">
            <UploadOutlined />
          </p>
          <p className="ant-upload-text">{i18next.t(label)}</p>
        </Upload.Dragger>
      </Form.Item>

      {isAttachURLVideo && (
        <URLVideoForm form={form} addURLVideo={addURLVideo} />
      )}

      {isDrapDrop ? (
        <SortableGrid
          fileList={fileList}
          setFileList={setFileList}
          onChangeSortable={onChangeSortable}
          responsiveSortable={responsiveSortable}
          imageItemProps={{
            deleteImage,
            onlyShowImg,
            isSetFeature,
            onSetFeature,
            onPreviewImage,
            isAttachURLVideo,
          }}
        />
      ) : (
        <div className="file-list-view">
          {fileList?.map((file, index) => (
            <UploadImageItem
              key={String(index)}
              item={file}
              deleteImage={deleteImage}
              index={index}
              onlyShowImg={onlyShowImg}
              isSetFeature={isSetFeature}
              onSetFeature={onSetFeature}
              onPreviewImage={onPreviewImage}
              isAttachURLVideo={isAttachURLVideo}
            />
          ))}
        </div>
      )}
      <ModalPreview ref={modalPreviewRef} />
    </FormPhotosStyles>
  );
};

RestUploadFile.propTypes = {
  source: PropTypes.string,
  setIsDisabled: PropTypes.func,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  label: PropTypes.string,
  formatResult: PropTypes.func,
  onlyShowImg: PropTypes.bool,
  required: PropTypes.bool,
  isSetFeature: PropTypes.bool,
  isAttachURLVideo: PropTypes.bool,
  resource: PropTypes.string,
  isDrapDrop: PropTypes.bool,
  responsiveSortable: PropTypes.object,
  prefixKey: PropTypes.string,
};

RestUploadFile.defaultProps = {
  setIsDisabled: () => null,
  multiple: true,
  accept: 'image/*',
  label: 'text.uploadImage',
};

export default RestUploadFile;
