import React, { useCallback, useState } from 'react';
import i18next from 'i18next';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { createCentreService, getByIdCentres } from 'redux/centres/actions';
import { PREFIX_URL } from 'configs/localData/constant';

import ModalCustom from 'components/common/ModalCustom';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import ServiceForm from './ServiceForm';

const CreateServiceModal = ({ visible, onCancel }) => {
  const [form] = Form.useForm();

  const { id } = useParams();

  const { validateFields, resetFields } = form;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const onOk = () => {
    validateFields().then(async ({ roomName, ...values }) => {
      if (id) {
        setLoading(true);
        const response = await dispatch(
          createCentreService({
            data: {
              centreId: id,
              roomName: roomName?.trim() || null,
              ...values,
            },
          }),
        );
        setLoading(false);
        if (response?.payload?.data?.id) {
          handleOnCancel();
          dispatch(
            getByIdCentres({
              data: { id },
              options: { isRequestApi: true, prefixUrl: PREFIX_URL.superAdmin },
            }),
          );
        }
      }
    });
  };

  const handleOnCancel = useCallback(() => {
    onCancel();
    resetFields();
  }, [onCancel, resetFields]);

  return (
    <ModalCustom
      title={i18next.t('centreServices.createPage')}
      visible={visible}
      onCancel={handleOnCancel}
      onOk={onOk}
      loading={loading}
      okText="button.create"
    >
      <Form form={form} layout="vertical">
        <RestInputContext.Provider value={{ form }}>
          <ServiceForm />
        </RestInputContext.Provider>
      </Form>
    </ModalCustom>
  );
};

CreateServiceModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default CreateServiceModal;
