import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';

import { EditIcon } from 'components/common/SVGIcon';
import EditCustomBtnStyles from './styles';

const EditCustomBtn = ({ onClickButton, disabled }) => (
  <EditCustomBtnStyles className="edit-wrapper">
    <Button
      onClick={onClickButton}
      className="edit-custom-btn"
      type="primary"
      shape="circle"
      icon={<EditIcon />}
      disabled={disabled}
    />
  </EditCustomBtnStyles>
);

EditCustomBtn.propTypes = {
  onClickButton: PropTypes.func,
  disabled: PropTypes.bool,
};

EditCustomBtn.defaultProps = {
  onClickButton: () => null,
};

export default EditCustomBtn;
