import React, { useImperativeHandle, forwardRef, useState } from 'react';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import Form, { useForm } from 'antd/lib/form/Form';

import { editEmailTemplate } from 'redux/EDM/actions';
import { handleApiError } from 'utils/validateUtils';

import ModalCustom from 'components/common/ModalCustom';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { useEmailTemplate } from '../context';
import CustomHtmlForm from './Form';

const EditModal = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const { template, setTemplate } = useEmailTemplate();

  const [form] = useForm();

  const dispatch = useDispatch();

  useImperativeHandle(
    ref,
    () => ({
      open: (data) => {
        setVisible(true);
        setTimeout(() => {
          form.setFieldsValue(data);
        }, 500);
      },
    }),
    [form],
  );

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  const onOk = () => {
    form
      .validateFields()
      .then(({ key, ...values }) => {
        setLoading(true);
        return dispatch(
          editEmailTemplate({ key, data: values, options: { isGetAll: true } }),
        );
      })
      .then(({ payload }) => {
        setLoading(false);
        if (payload?.id) {
          onCancel();
          if (template.id === payload.id) {
            setTemplate({});
          }
        } else {
          handleApiError(payload, form);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <ModalCustom
      visible={visible}
      title={i18next.t('EDM.editModalCustomHtmlTitle')}
      onOk={onOk}
      onCancel={onCancel}
      width={1000}
      loading={loading}
    >
      <Form form={form} layout="vertical" scrollToFirstError>
        <RestInputContext.Provider value={{ form, record: {} }}>
          <CustomHtmlForm isEdit />
        </RestInputContext.Provider>
      </Form>
    </ModalCustom>
  );
});

export default EditModal;
