import { unionBy } from 'lodash-es';

export const INITIAL_STATE_CUSTOM = {
  loading: false,
  data: [],
  resourceData: {
    filter: {},
    outsideFilter: {},
    offset: 0,
    limit: 10,
    total: 0,
    numberOfPages: 1,
  },
};

export const getAllCustomPending = (
  state,
  {
    meta: {
      arg: { data, options = {} },
    },
  },
) => {
  state.loading = true;

  if (options.isRefresh) {
    state.data = [];
    if (options.keepFilter) {
      state.resourceData = {
        ...state.resourceData,
        ...data,
        filter: { ...state.resourceData?.filter, ...data?.filter },
      };
    } else if (options.keepOrderBy) {
      state.resourceData = {
        ...INITIAL_STATE_CUSTOM.resourceData,
        orderBy: state.resourceData.orderBy,
        ...data,
      };
    } else {
      state.resourceData = {
        ...INITIAL_STATE_CUSTOM.resourceData,
        ...data,
      };
    }
  } else {
    state.resourceData.offset =
      state.resourceData.offset + state.resourceData.limit || 0;
  }
};

export const getAllCustomSuccess = (state, { payload: { data } }) => {
  state.data = unionBy([...state.data, ...(data.results || [])], 'id');

  state.resourceData.total = data.total || 0;
  state.resourceData.numberOfPages = data.numberOfPages;

  state.loading = false;
  state.error = null;
};

export const getAllCustomFail = (state, { payload: { data } }) => {
  state.error = data;
  state.loading = false;
};

export const resetResourceDataCustom = (state) => {
  state.resourceData = INITIAL_STATE_CUSTOM.resourceData;
};

export const editCustomSuccess = (state, { payload }) => {
  const matchIdx = state.data?.findIndex(
    (item) => item.id === payload?.data?.id,
  );
  if (matchIdx !== -1) {
    state.data[matchIdx] = payload.data;
  }
};

export const getSummariesCustomFulfilled = (state, { payload }) => {
  state.summaries = payload;
};
