import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import EditButton from 'components/RestActions/EditButton';

const GroupActionEnquiries = ({ id, record }) => {
  const { push } = useHistory();
  const handleClickView = () => {
    push(`/centres/${record?.centre?.id}/show/enquiries?inboxId=${id}`);
  };
  return (
    <div>
      <EditButton isView source="show" onClickCustom={handleClickView} />
    </div>
  );
};

GroupActionEnquiries.propTypes = {
  id: PropTypes.string,
  record: PropTypes.object,
};

export default GroupActionEnquiries;
