import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import ViewModeSwitcher from 'components/common/ViewModeSwitcher';
import FilterCentre from './FilterCentre';
import { HeaderFilterStyles } from './styles';

const HeaderFilter = ({
  activeTab,
  retrieveList,
  onChange,
  idProp,
  brandProps,
  cityProps,
}) => (
  <HeaderFilterStyles>
    <Row gutter={20}>
      <Col xxl={4} xl={5} lg={6} md={24} xs={24}>
        <ViewModeSwitcher onChange={onChange} activeTab={activeTab} />
      </Col>
      <Col xxl={20} xl={19} lg={18} md={24} xs={24}>
        <FilterCentre
          retrieveList={retrieveList}
          idProp={idProp}
          brandProps={brandProps}
          cityProps={cityProps}
        />
      </Col>
    </Row>
  </HeaderFilterStyles>
);

HeaderFilter.propTypes = {
  onChange: PropTypes.func,
  activeTab: PropTypes.string,
  retrieveList: PropTypes.func,
  idProp: PropTypes.string,
  brandProps: PropTypes.object,
  cityProps: PropTypes.object,
};

HeaderFilter.defaultProps = {
  retrieveList: () => null,
};

export default HeaderFilter;
