import { createSlice } from '@reduxjs/toolkit';
import { getBanners, uploadBanners } from './actions';

export const initialState = {
  data: [],
  loading: false,
  uploadBannersLoading: false,
};

const getBannersPending = (state) => {
  state.loading = true;
};

const getBannersFulfilled = (state, { payload }) => {
  state.loading = false;
  state.data = payload;
};

const getBannersRejected = (state, { payload }) => {
  state.loading = false;
  state.error = payload;
};

const uploadBannersPending = (state) => {
  state.uploadBannersLoading = true;
};

const uploadBannersFulfilled = (state, { payload }) => {
  state.uploadBannersLoading = false;
  state.data = payload;
};

const uploadBannersRejected = (state, { payload }) => {
  state.uploadBannersLoading = false;
  state.error = payload;
};

const { actions, reducer } = createSlice({
  name: 'banners',
  initialState,
  reducers: {
    setIsDisabledButtonSubmit: (state, { payload }) => {
      state.isDisabledButtonSubmit = payload;
    },
  },
  extraReducers: {
    [getBanners.pending]: getBannersPending,
    [getBanners.fulfilled]: getBannersFulfilled,
    [getBanners.rejected]: getBannersRejected,
    [uploadBanners.pending]: uploadBannersPending,
    [uploadBanners.fulfilled]: uploadBannersFulfilled,
    [uploadBanners.rejected]: uploadBannersRejected,
  },
});

export const { setIsDisabledButtonSubmit } = actions;

export default reducer;
