import React, { useEffect, useState } from 'react';
import { Select, Modal } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { changeSubscriptionCentre } from 'redux/centres/actions';
import SubscriptionDropdownStyles from './styles';

const { Option } = Select;

const { confirm } = Modal;

const SubscriptionDropdown = ({ id, CRMPackageId, className }) => {
  const dispatch = useDispatch();

  const [value, setValue] = useState(CRMPackageId);

  useEffect(() => {
    CRMPackageId && setValue(CRMPackageId);
  }, [CRMPackageId]);

  const onChange = (value, option) => {
    confirm({
      title: i18next.t('centres.confirmSubscriptionTitle'),
      content: i18next.t('centres.confirmSubscriptionContent', {
        name: option?.children?.props?.children,
      }),
      onOk() {
        return new Promise((resolve, reject) => {
          if (id) {
            resolve(
              dispatch(
                changeSubscriptionCentre({
                  id,
                  data: { CRMPackageId: value },
                  CRMPackage: {
                    id: value,
                    name: option?.children?.props?.children,
                  },
                }),
              ),
            );
          } else reject();
        });
      },
    });
  };
  const CRMPackages = useSelector((state) => state.config.data?.CRMPackage);

  return (
    <SubscriptionDropdownStyles className={className || ''}>
      <Select
        placeholder={i18next.t('input.subscription.placeholder')}
        optionFilterProp="children"
        onChange={onChange}
        value={value}
        dropdownClassName="subscription-dropdown"
      >
        {CRMPackages?.map((item) => (
          <Option key={item?.id} value={item?.id}>
            <span className="text-16">{item?.name}</span>
          </Option>
        ))}
      </Select>
    </SubscriptionDropdownStyles>
  );
};

SubscriptionDropdown.propTypes = {
  CRMPackageId: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
};

export default SubscriptionDropdown;
