import React from 'react';
import { Row, Col } from 'antd';
import i18next from 'i18next';
import { CENTRE_STATUS } from 'configs/localData';

import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import RestRangePickerInput from 'components/RestInput/RestRangePickerInput';

const Filter = () => (
  <Row gutter={16}>
    <Col xl={6} lg={12} md={12} xs={24}>
      <RestInputItem
        source={['outsideFilter', 'centre']}
        placeholder="centreRequests.placeholderCentre"
        isShowTooltip
      />
    </Col>
    <Col xl={6} lg={12} md={12} xs={24}>
      <RestInputItem
        source={['outsideFilter', 'sender']}
        placeholder="centreRequests.placeholderSender"
        isShowTooltip
      />
    </Col>
    <Col xl={4} lg={12} md={12} xs={24}>
      <RestInputItem
        source={['newEmail', '$like']}
        placeholder="centreRequests.newEmail"
        isShowTooltip
      />
    </Col>
    <Col xl={4} lg={6} md={12} xs={24}>
      <RestSelect
        source="status"
        placeholder="centreRequests.status"
        isShowTooltip
        resourceData={CENTRE_STATUS}
        formatText={(text) => i18next.t(text)}
        valueProp="value"
        titleProp="text"
      />
    </Col>
    <Col xl={4} lg={6} md={12} xs={24}>
      <RestRangePickerInput
        source="createdAt"
        placeholder="centreRequests.time"
        isShowTooltip
        sourceGt="createdAt.$gt"
        sourceLt="createdAt.$lt"
      />
    </Col>
  </Row>
);

export default Filter;
