import React from 'react';
import PropTypes from 'prop-types';
import { InputNumber, Switch } from 'antd';

import RestInputItem from 'components/RestInput/RestInputItem';
import RestMultiPhotos from 'components/RestInput/RestMultiPhotos';
import { LABEL_TABLE_KEY } from 'configs/localData/constant';
import { formatLabelTable } from 'utils/textUtils';
import { useSelector } from 'react-redux';

const ServiceTypesForm = ({ isEdit, ...props }) => {
  const appSettings = useSelector((state) => state.config?.data?.AppSetting);

  return (
    <div {...props}>
      <RestInputItem
        required
        source="name"
        header={formatLabelTable(LABEL_TABLE_KEY.serviceType, appSettings)}
        placeholder={formatLabelTable(LABEL_TABLE_KEY.serviceType, appSettings)}
        isValidatorEmpty
      />
      <RestInputItem
        required
        source="externalId"
        header="serviceTypes.code"
        placeholder="serviceTypes.code"
        isValidatorEmpty
      />
      <RestInputItem
        required
        source="weekPerYear"
        header="serviceTypes.weeksOfTheYear"
        placeholder="serviceTypes.weeksOfTheYear"
        isValidatorEmpty
        ContentComponent={InputNumber}
      />
      <RestMultiPhotos
        source="icon"
        header="serviceTypes.image"
        placeholder="serviceTypes.image"
        required
        multiple={false}
        objectFit="contain"
        width="auto"
        resource="features"
      />
      {isEdit && (
        <RestInputItem
          source="isActive"
          header="serviceTypes.isActive"
          placeholder="serviceTypes.isActive"
          ContentComponent={Switch}
          ruleType="boolean"
          valuePropName="checked"
        />
      )}
    </div>
  );
};

ServiceTypesForm.propTypes = {
  isEdit: PropTypes.bool,
};

export default ServiceTypesForm;
