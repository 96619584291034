import { CloseCircleFilled } from '@ant-design/icons';
import { Modal, Select, Space, Tag } from 'antd';
import i18next from 'i18next';
import React from 'react';
import { TryAgainContent } from './styles';

const { Option } = Select;

export const renderOptionLabel = (string, searchValue) => {
  if (searchValue) {
    const index = string.toLowerCase().indexOf(searchValue.toLowerCase());

    if (index !== -1) {
      const { length } = searchValue;

      const prefix = string.substring(0, index);
      const suffix = string.substring(index + length);
      const match = string.substring(index, index + length);

      return (
        <span>
          {prefix}
          <span className="fw-bold">{match}</span>
          {suffix}
        </span>
      );
    }
  }

  return <span>{string}</span>;
};

export const renderCustomOptions = (latestEmailsSent, searchValue) => (
  <>
    {latestEmailsSent.map(
      (item) =>
        item?.includes(searchValue) && (
          <Option key={item} value={item}>
            {renderOptionLabel(item, searchValue)}
          </Option>
        ),
    )}
  </>
);

export const getSendTestPopup = (
  { results, totalEmailsSend },
  onOk,
  recipientEmail,
) => {
  const [countValid, total] = totalEmailsSend?.split('/')?.map(Number);

  if (countValid === 0) {
    Modal.error({
      content: (
        <TryAgainContent>
          <div className="icon-wrapper">
            <CloseCircleFilled size={40} />
          </div>
          <h3>{i18next.t('error.pleaseTryAgain')}</h3>
          <p className="text-center m-0">
            {i18next.t('notifications.sendTestToEmails.notExist')}
          </p>
        </TryAgainContent>
      ),
      maskClosable: true,
      okText: i18next.t('error.tryAgain'),
      okButtonProps: { block: true },
      icon: null,
      wrapClassName: 'modal-f-width-btns',
    });
  } else if (countValid === total) {
    Modal.confirm({
      content: i18next.t('notifications.sendTestToEmails.allExist'),
      okText: i18next.t('button.send'),
      icon: null,
      onOk,
    });
  } else if (countValid < total) {
    Modal.confirm({
      content: (
        <div>
          <p>
            {i18next.t('notifications.sendTestToEmails.bothExistAndNotExist', {
              totalEmailsSend,
            })}
          </p>
          <Space wrap size={5}>
            {results?.map((item) => (
              <Tag key={item}>{item}</Tag>
            ))}
          </Space>
        </div>
      ),
      okText: i18next.t('notifications.sendAnyway'),
      icon: null,
      onOk: (close) => {
        const filteredEmails = recipientEmail.filter(
          (item) => !results?.includes(item),
        );

        onOk(close, filteredEmails);
      },
    });
  }
};
