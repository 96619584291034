import React, { useMemo } from 'react';
import { Form, Input } from 'antd';
import i18next from 'i18next';
import PropTypes from 'prop-types';

const FormCategories = ({ parentCategory }) => {
  const parentContent = useMemo(() => {
    if (parentCategory?.id) {
      return (
        <div>
          <strong>{i18next.t('common.parent')}</strong>
          {`: `}
          <span>{parentCategory?.name}</span>
          <Form.Item name="parentId" />
        </div>
      );
    }

    return null;
  }, [parentCategory]); // eslint-disable-line

  return (
    <div className="empty-after-label">
      {parentContent}

      <Form.Item
        label={i18next.t('common.name')}
        name="name"
        rules={[
          { required: true, message: i18next.t('error.required') },
          {
            whitespace: true,
            message: i18next.t('error.empty'),
          },
        ]}
      >
        <Input placeholder={i18next.t('common.name')} />
      </Form.Item>
    </div>
  );
};

FormCategories.propTypes = {
  parentCategory: PropTypes.object,
};

export default FormCategories;
