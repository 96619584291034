import { PREFIX_URL } from 'configs/localData/constant';
import { del, get } from './utils';

export const getSummariesProviderApi = (data) =>
  get(`/providers/summaries`, data, {}, PREFIX_URL.superAdmin);

export const getCentresOfProviderApi = (id, data) =>
  get(`/providers/${id}/centre`, data, {}, PREFIX_URL.superAdmin);

export const getSummariesCentresOfProviderApi = (id, data) =>
  get(`/providers/${id}/centre/summary`, data, {}, PREFIX_URL.superAdmin);

export const getUsersOfProviderApi = (id, data) =>
  get(`/providers/${id}/users`, data, {}, PREFIX_URL.superAdmin);

export const delUserOfProviderApi = (id, data) =>
  del(`/providers/${id}/users`, data, {}, PREFIX_URL.superAdmin);

export const getCentreIdsOfProviderApi = (id, data) =>
  get(`/providers/${id}/centreId`, data, {}, PREFIX_URL.superAdmin);
