import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import i18next from 'i18next';

const GetStarted = ({ handleGetStarted }) => (
  <div className="content-step">
    <div className="flex-center-between">
      <div style={{ width: '80%' }} className="start-content">
        {i18next.t('listingStrength.getStartedInfo')}
      </div>
      <div>
        <Button
          className="btn-second-primary content-step__btn-update"
          onClick={handleGetStarted}
        >
          {i18next.t('button.getStarted')}
        </Button>
      </div>
    </div>
  </div>
);

GetStarted.propTypes = {
  handleGetStarted: PropTypes.func,
};

export default GetStarted;
