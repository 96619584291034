import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import PropsType from 'prop-types';

const WorkplaceLink = ({ workplaceName, href }) => (
  <div className="inline-block">
    <Tooltip title={workplaceName}>
      <WorkplaceLinkCustomStyle to={href} className="ellipsis-t-2">
        {workplaceName}
      </WorkplaceLinkCustomStyle>
    </Tooltip>
  </div>
);

WorkplaceLink.propTypes = {
  workplaceName: PropsType.string,
  href: PropsType.string,
};

export default WorkplaceLink;

export const WorkplaceLinkCustomStyle = styled(Link)`
  color: ${({ theme }) => theme.text.mainL1};
`;
