/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Slider } from 'antd';
import { COLOR_KINDI_RATING } from 'configs/localData/constant';
import i18next from 'i18next';
import { SliderRatingStyles } from './styles';

const KindiCareRating = ({ formatPrice, isNR }) => {
  const marks = useMemo(
    () => ({
      [formatPrice.min]: {
        style: { color: '#857E7F' },
        label: formatPrice.min
          ? formatPrice.min.toFixed(1)
          : i18next.t('classification.NR'),
      },
      [formatPrice.max]: {
        style: { color: '#857E7F' },
        label: formatPrice.max.toFixed(1),
      },
      [formatPrice.price]: {
        style: {
          color: COLOR_KINDI_RATING.price,
        },
        label: (
          <span className="price-label">
            {isNR
              ? i18next.t('classification.NR')
              : formatPrice.price.toFixed(1)}
          </span>
        ),
      },
      [formatPrice.avg]: {
        style: {
          color: COLOR_KINDI_RATING.avg,
        },
        label: <span className="avg-label">{formatPrice.avg.toFixed(1)}</span>,
      },
    }),
    [formatPrice, isNR],
  );

  const isPriceLower = useMemo(
    () => formatPrice.price < formatPrice.avg,
    [formatPrice],
  );

  return (
    <SliderRatingStyles isPriceLower={isPriceLower}>
      <Slider
        marks={marks}
        range
        min={formatPrice.min}
        max={formatPrice.max}
        value={[formatPrice.min, formatPrice.price, formatPrice.avg]}
        step={0.01}
        disabled
      />
    </SliderRatingStyles>
  );
};

KindiCareRating.propTypes = {
  formatPrice: PropTypes.object,
  isNR: PropTypes.bool,
};

export default KindiCareRating;
