import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiWrapper } from 'utils/reduxUtils';
import {
  getAllAutomatedResponsesApi,
  updateAppSettingsApi,
} from 'api/appSettings';

export const MODEL_NAME = 'centres';

export const getAllAutomatedResponses = createAsyncThunk(
  'automatedResponses/getAllAutomatedResponses',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {},
        getAllAutomatedResponsesApi,
        payload.id,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const updateAutomatedResponses = createAsyncThunk(
  'automatedResponses/updateAutomatedResponses',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        updateAppSettingsApi,
        payload.centreId,
        payload.key,
        payload.data,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
