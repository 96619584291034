import styled from 'styled-components';

export const CentresUserModalStyles = styled.div`
  padding-bottom: 20px;

  .centre-item + .centre-item {
    margin-top: 20px;
  }

  .remove-icon {
    font-size: 20px;
  }
  .add-centres-user {
    display: flex;
    justify-content: space-between;
    .select-form-wrapper {
      flex: 1;
      margin-right: 20px;
    }
  }

  .view-more-button {
    margin-top: 10px;
    padding-left: 0 !important;
  }
`;
