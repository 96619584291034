import styled from 'styled-components';

const GroupActionsStyles = styled.div`
  display: flex;
  align-items: center;

  & > div:not(:first-child) {
    margin-left: 15px;
  }

  .ant-btn {
    color: #fff;
    border: none;
  }
  .process-btn {
    background: ${({ theme }) => theme.color.orange};
  }
  .decline-btn {
    background: ${({ theme }) => theme.color.red};
  }
  .approve-btn {
    background: ${({ theme }) => theme.color.green};
  }
`;

export default GroupActionsStyles;
