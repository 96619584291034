import { createSelector } from 'reselect';

const getRawResourceDataClickThroughTracker = (state) =>
  state.clickThroughTracker?.resourceData;

const getRawClickThroughTrackerLoading = (state) =>
  state.clickThroughTracker?.loading;

const getRawDataClickThroughTracker = (state) =>
  state.clickThroughTracker?.data;

export const getResourceDataClickThroughTracker = createSelector(
  [getRawResourceDataClickThroughTracker],
  (data) => data,
);

export const getLoadingClickThroughTracker = createSelector(
  [getRawClickThroughTrackerLoading],
  (data) => data,
);

export const getDataClickThroughTracker = createSelector(
  [getRawDataClickThroughTracker],
  (data) => data,
);
