import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useSelector } from 'react-redux';

import {
  formatKindiRating,
  formatNQSRating,
  formatSubscription,
} from 'utils/formatFieldUtils';
import TableCustom from 'components/common/TableCustom';
import { CRUDSelectorsCustom } from 'redux/crudCreatorCustom/selectors';
import { formatDateTimeTable } from 'utils/textUtils';
import ProgressCustome from 'components/common/ProgressCustome';
import { Typography } from 'antd';
import { useHistory } from 'react-router';
import GroupActions from './GroupActions';

const CentresListTable = ({ retrieveList, resource = 'centresOfProvider' }) => {
  const { push } = useHistory();

  const centresSelectorsCustom = new CRUDSelectorsCustom(resource);
  const loading = useSelector(centresSelectorsCustom.getLoading);
  const resourceFilter =
    useSelector(centresSelectorsCustom.getResourceData) || {};
  const data = useSelector(centresSelectorsCustom.getDataArr);

  const redirectDetail = (id) => {
    localStorage.removeItem('currentStep');
    id && push(`/centres/${id}/show/summary`);
  };

  const columns = [
    {
      title: i18next.t('centres.name'),
      dataIndex: 'name',
      width: '15%',
      sorter: true,
      render: (data, record) => (
        <Typography.Link
          onClick={() => redirectDetail(record?.id)}
          className="link-custom-primary"
        >
          <span className="fw-bold">{data}</span>
        </Typography.Link>
      ),
    },
    {
      title: i18next.t('centres.brandTitle'),
      dataIndex: 'brandName',
      render: (value, record) => record.brand?.name,
      sorter: true,
    },
    {
      title: i18next.t('centres.suburb'),
      dataIndex: 'city',
    },
    {
      title: i18next.t('centres.subscription'),
      dataIndex: ['CRMPackage', 'name'],
      render: formatSubscription,
      width: 200,
    },
    {
      title: i18next.t('centres.NQSRating'),
      dataIndex: 'NQSRType',
      render: formatNQSRating,
    },
    {
      title: i18next.t('centres.rating'),
      dataIndex: 'kindiCareRating',
      sorter: true,
      render: formatKindiRating,
    },
    {
      title: i18next.t('centres.listingStrength'),
      dataIndex: 'totalListingStrength',
      render: (data) => <ProgressCustome percent={data} className="pr-32" />,
      sorter: true,
      width: 250,
    },
    {
      title: i18next.t('centres.waitlist'),
      dataIndex: 'totalWaitListed',
      sorter: true,
    },
    {
      title: i18next.t('centres.capacity'),
      dataIndex: 'placeAllowed',
      sorter: true,
    },
    {
      title: i18next.t('common.lastActivity'),
      dataIndex: 'lastContactTime',
      render: (data, record) =>
        formatDateTimeTable(record?.activity?.createdAt),
      sorter: true,
    },

    {
      title: '',
      dataIndex: 'id',
      width: 80,
      fixed: 'right',
      render: (id) => <GroupActions onClickCustom={() => redirectDetail(id)} />,
    },
  ];

  const onChange = (e, filters, sorter) => {
    const formatSort =
      sorter && sorter.field && sorter.order
        ? `${sorter.order === 'descend' ? '-' : ''}${sorter.field}`
        : null;

    const restFilter = {
      offset: (e.current - 1) * e.pageSize,
      limit: e.pageSize,
      orderBy: formatSort,
      filter: resourceFilter.filter,
      outsideFilter: resourceFilter.outsideFilter,
    };

    retrieveList({
      filter: restFilter,
      isRefresh: true,
    });
  };

  return (
    <TableCustom
      xScroll={1800}
      isSorter
      onChange={onChange}
      data={data}
      columns={columns}
      loading={loading}
      pagination={{
        pageSize: resourceFilter.limit,
        current: resourceFilter.offset / resourceFilter.limit + 1 || 1,
        total: resourceFilter.total,
      }}
    />
  );
};

CentresListTable.propTypes = {
  retrieveList: PropTypes.func,
  resource: PropTypes.string,
};

CentresListTable.defaultProps = {
  retrieveList: () => null,
};

export default CentresListTable;
