import { createSelector } from 'reselect';

const getRawSummariesData = (state) =>
  state?.jobApplicationsTracker?.data?.summaries;

const getRawApplicationsData = (state) =>
  state?.jobApplicationsTracker?.data?.applications;

const getJobApplicationsTrackerResourceData = (state) =>
  state?.jobApplicationsTracker.resourceData;

const getIsLoadingData = (state) => state?.jobApplicationsTracker?.isLoading;

const getRawConfig = (state) => state?.jobApplicationsTracker?.config;

export const getIsLoadingGetAllData = createSelector(
  [getIsLoadingData],
  (data) => data,
);

export const getSummariesData = createSelector(
  [getRawSummariesData],
  (data) => data || [],
);

export const getJobApplicationsTrackerData = createSelector(
  [getRawApplicationsData],
  (data) => data || [],
);

export const getResourceDataJobApplicationsTracker = createSelector(
  [getJobApplicationsTrackerResourceData],
  (data) => data,
);

export const getJobApplicationsTrackerConfigSelector = createSelector(
  [getRawConfig],
  (data) => data,
);
