import React from 'react';
import PropTypes from 'prop-types';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import { useSelector } from 'react-redux';
import { PREFIX_URL_API } from 'configs/localData/constant';

import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import ReferenceInput from 'containers/rest/ReferenceInput';
import { useParams } from 'react-router';
import FilterCustom from 'components/common/FilterCustom';
import { getRolesBelongToUser } from 'redux/config/selectors';

const FilterUsers = ({ retrieveList }) => {
  const { id } = useParams();

  const roleBelongToUser = useSelector(getRolesBelongToUser);

  const orderBy = useSelector(
    (state) => state.providers.centresOfProvider?.resourceData,
  )?.orderBy;

  const onSubmitFilter = (values) => {
    retrieveList({
      filter: {
        orderBy,
        offset: 0,
        outsideFilter: values,
      },
      isRefresh: true,
    });
  };

  const onClearFilter = () => {
    retrieveList({
      filter: {
        orderBy,
        offset: 0,
      },
      isRefresh: true,
    });
  };

  return (
    <FilterCustom
      onSubmitFilter={onSubmitFilter}
      onClearFilter={onClearFilter}
      responsiveFilter={{
        xxl: 20,
        xl: 18,
        lg: 24,
        md: 24,
        xs: 24,
      }}
      responsiveAction={{
        xxl: 4,
        xl: 6,
        lg: 24,
        md: 24,
        xs: 24,
      }}
    >
      <Row gutter={16}>
        <Col xl={8} md={12} xs={12}>
          <RestInputItem
            source="userName"
            placeholder="users.userName"
            isShowTooltip
          />
        </Col>
        <Col xl={8} md={12} xs={12}>
          <ReferenceInput
            prefixUrl={PREFIX_URL_API}
            source="centreId"
            resource={`providers/${id}/centre`}
            searchKey="centreName"
            searchOutsideFilter
          >
            <RestSelect
              source="centreId"
              placeholder="input.centre.placeholder"
              isShowTooltip
              titleProp="name"
              valueProp="id"
              isFilterOption={false}
            />
          </ReferenceInput>
        </Col>
        <Col xl={8} md={12} xs={12}>
          <RestSelect
            source="roleId"
            placeholder="input.role.placeholder"
            resourceData={roleBelongToUser}
            valueProp="id"
            titleProp="description"
            isShowTooltip
          />
        </Col>
      </Row>
    </FilterCustom>
  );
};

FilterUsers.propTypes = {
  retrieveList: PropTypes.func,
};

export default FilterUsers;
