import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiWrapper } from 'utils/reduxUtils';
import { makeActions } from 'redux/crudCreator/actions';
import {
  changeSpecialOfferApi,
  createCentreServiceApi,
  delCentreServiceApi,
  editCentreServiceApi,
} from 'api/centreServices';
import { convertRequestParams } from 'redux/crudCreator/dataProvider';
import {
  changeFinishedStepApi,
  changeSubscriptionCentreApi,
  delAllGoogleReviewApi,
  editAppSettingsByKeyCentreApi,
  editSocialMediaChannelsApi,
  getAllSocialMediaChannelsApi,
  getAppSettingsCentreApi,
  getKindiCareRatingSummaryApi,
  getRatingsCentreApi,
  getSummaryDetailCentreApi,
  editCentreInformationApi,
} from 'api/centres';
import { notification } from 'antd';
import i18next from 'i18next';

export const MODEL_NAME = 'centres';
export const centresActions = makeActions(MODEL_NAME);

export const getAllCentres = centresActions.getAll;
export const editCentres = centresActions.edit;
export const createCentres = centresActions.create;
export const getByIdCentres = centresActions.getDataById;
export const editCentresInformation = centresActions.edit;

export const createCentreService = createAsyncThunk(
  'centres/createCentreServiceCustom',
  async (payload = {}, thunkAPI) => {
    try {
      const { data } = payload;
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        createCentreServiceApi,
        data,
      );
      return { data: response };
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  },
);

export const editCentreService = createAsyncThunk(
  'centres/editCentreServiceCustom',
  async (payload = {}, thunkAPI) => {
    try {
      const { data } = payload;
      const { id, ...restData } = data;
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        editCentreServiceApi,
        id,
        restData,
      );
      return { data: response };
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error, id: payload.data.id });
    }
  },
);

export const editCentreInformation = createAsyncThunk(
  'centre/editCentreInformation',
  async (payload = {}, thunkAPI) => {
    try {
      const { data, id } = payload;
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        editCentreInformationApi,
        id,
        data,
      );
      return { data: response };
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  },
);

export const delCentreService = createAsyncThunk(
  'centres/delCentreServiceCustom',
  async (payload = {}, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        delCentreServiceApi,
        payload,
      );

      return { data: response, id: payload };
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error, id: payload });
    }
  },
);

export const getRatingsCentre = createAsyncThunk(
  'centres/getRatingsCentre',
  async (payload = {}, thunkApi) => {
    try {
      const { data = {}, options = {} } = payload;

      const { limit, offset, filter } =
        thunkApi.getState().centres.ratings?.resourceData || {};
      const { id, ...restData } = data;

      const convertRequest = convertRequestParams('GET_ALL', {
        limit,
        offset,
        filter,
        ...restData,
      });

      const response = await apiWrapper(
        {},
        getRatingsCentreApi,
        id,
        convertRequest,
      );

      return {
        data: {
          numberOfPages: Math.ceil(response.total / limit),
          ...response,
        },
        options,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ id: payload.data.id, data: error });
    }
  },
);

export const getSummaryDetailCentre = createAsyncThunk(
  'centres/getSummaryDetailCentre',
  async (payload = {}, thunkAPI) => {
    try {
      const response = await apiWrapper({}, getSummaryDetailCentreApi, payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const changeSubscriptionCentre = createAsyncThunk(
  'centres/changeSubscriptionCentre',
  async (payload = {}, thunkAPI) => {
    try {
      const { id, data } = payload;
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        changeSubscriptionCentreApi,
        id,
        data,
      );
      return { data: response };
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  },
);

export const getAppSettingsCentre = createAsyncThunk(
  'centres/getAppSettingsCentre',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper({}, getAppSettingsCentreApi, payload);

      return response?.results;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const editAppSettingsCentre = createAsyncThunk(
  'centres/editAppSettingsCentre',
  async (payload = {}, thunkAPI) => {
    try {
      const { data, id, isShowSuccess = true } = payload;

      const requests = Object.entries(data).map(([key, value]) =>
        apiWrapper({}, editAppSettingsByKeyCentreApi, id, key, {
          value: value?.trim() || null,
        }),
      );

      const results = await Promise.all(requests);

      isShowSuccess &&
        notification.success({
          message: i18next.t('success.title'),
          description: i18next.t('success.description'),
        });

      return { data: results, isSuccess: true };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const changeSpecialOffer = createAsyncThunk(
  'centres/changeSpecialOffer',
  async (payload = {}, thunkAPI) => {
    try {
      const { data } = payload;
      const { id, isSpecialOffer } = data;
      await apiWrapper({ isShowSuccessNoti: true }, changeSpecialOfferApi, id, {
        isSpecialOffer,
      });
      return { id, isSpecialOffer };
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error, id: payload.data.id });
    }
  },
);

export const allowedEnquiries = createAsyncThunk(
  'centres/allowedEnquiries',
  async (payload = {}, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        editAppSettingsByKeyCentreApi,
        payload.centreId,
        'allowed-enquiries',
        payload.data,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  },
);

export const changeFinishedStep = createAsyncThunk(
  'centres/changeFinishedStep',
  async (payload = {}, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isShowSuccessNoti: false },
        changeFinishedStepApi,
        payload.centreId,
        payload.data,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error });
    }
  },
);

export const delAllGoogleReviews = createAsyncThunk(
  'centres/delAllGoogleReviews',
  async (payload = {}, thunkAPI) => {
    try {
      const { id } = payload;
      const response = await apiWrapper(
        { isShowSuccessNoti: true },
        delAllGoogleReviewApi,
        id,
      );

      thunkAPI.dispatch(
        getRatingsCentre({
          data: { id, orderBy: '-createdAt' },
          options: { isRefresh: true },
        }),
      );

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error, id: payload });
    }
  },
);

export const getAllSocialMediaChannels = createAsyncThunk(
  'centres/getAllSocialMediaChannels',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {},
        getAllSocialMediaChannelsApi,
        payload,
      );

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const editSocialMediaChannels = createAsyncThunk(
  'centres/editSocialMediaChannels',
  async (payload = {}, thunkAPI) => {
    try {
      const { data, centreId, isShowSuccess = true } = payload;

      const requests = Object.entries(data).map(([key, value]) =>
        apiWrapper(
          { isShowSuccessNoti: false },
          editSocialMediaChannelsApi,
          centreId,
          {
            value: value || '',
            centreExternalLinkId: key,
          },
        ),
      );

      const results = await Promise.all(requests);

      isShowSuccess &&
        notification.success({
          message: i18next.t('success.title'),
          description: i18next.t('success.description'),
        });

      return { results, isSuccess: true };
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  },
);

export const getKindiCareRatingSummary = createAsyncThunk(
  'centres/getKindiCareRatingSummary',
  async (payload = {}, thunkAPI) => {
    try {
      const { id } = payload;
      return await apiWrapper({}, getKindiCareRatingSummaryApi, id);
    } catch (error) {
      return thunkAPI.rejectWithValue({ data: error, id: payload });
    }
  },
);
