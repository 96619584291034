import React, { useMemo } from 'react';
import i18next from 'i18next';
import { Divider, Space } from 'antd';
import PropTypes from 'prop-types';

import EditCustomBtn from 'components/RestActions/EditCustomBtn';
import CardSectionStyles from './styles';

const CardSection = ({
  title,
  children,
  isEdit,
  onClickButton,
  extraAction,
  disabled,
}) => {
  const editBtn = useMemo(
    () =>
      isEdit && (
        <EditCustomBtn onClickButton={onClickButton} disabled={disabled} />
      ),
    [isEdit, onClickButton, disabled],
  );

  return (
    <CardSectionStyles className="wrapper-info">
      <div className="title-card">
        <span className="name-title">{i18next.t(title)}</span>
        <Space wrap>
          {extraAction}
          {editBtn}
        </Space>
      </div>
      <Divider />
      <div className="content-card-info">{children}</div>
    </CardSectionStyles>
  );
};

CardSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  isEdit: PropTypes.bool,
  onClickButton: PropTypes.func,
  extraAction: PropTypes.node,
  disabled: PropTypes.bool,
};

export default CardSection;
