import React, { useContext, useState } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import i18next from 'i18next';

import RestGoogleAutocomplete from 'components/RestInput/RestGoogleAutocomplete';
import GoogleMap from 'components/GoogleMap';
import { RestInputContext } from 'components/RestInput/RestInputContext';

import AddressFullForm from './AddressFullForm';
import AddressAdditionalForm from './AddressAdditionalForm';

const isValidAddress = (addressObj) =>
  addressObj?.addressL1 &&
  addressObj?.state &&
  addressObj?.postCode &&
  addressObj?.city;

const CentreLocationForm = () => {
  const { form } = useContext(RestInputContext);

  const [isFullAddress, setIsFullAddress] = useState();
  const [geoLocationCustom, setGeoLocationCustom] = useState();
  const [addressMarkerCustom, setAddressMarkerCustom] = useState();
  const [geoAddressObj, setGeoAddressObj] = useState();

  const onSelectAddress = ({ coordinates, address, addressObj }) => {
    setGeoLocationCustom(coordinates);
    setAddressMarkerCustom(address);
    form.setFieldsValue(addressObj);
    if (isValidAddress(addressObj)) {
      form.validateFields(['displayAddress']);
    }
  };

  return (
    <div>
      <Typography.Title level={5} className="centre-form__title-section">
        {i18next.t('centres.centreLocation')}
      </Typography.Title>

      {!isFullAddress && (
        <Row gutter={16}>
          <Col md={12} xs={24}>
            <RestGoogleAutocomplete
              source="displayAddress"
              header="centres.address"
              placeholder="centres.address"
              required
              onSelectAddress={onSelectAddress}
              rules={[
                ({ getFieldsValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      isValidAddress(
                        getFieldsValue([
                          'addressL1',
                          'city',
                          'postCode',
                          'state',
                        ]),
                      )
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(i18next.t('centres.errorDisplayAddress')),
                    );
                  },
                }),
              ]}
            />
          </Col>

          <Col md={12} xs={24}>
            <div className="flex items-center h-full location__question">
              <div>{i18next.t('centres.notCorrectAddress')}</div>
              <Button
                className="address-full-form-button"
                type="link"
                onClick={() => setIsFullAddress(true)}
              >
                {i18next.t('button.tryFullForm')}
              </Button>
            </div>
          </Col>
        </Row>
      )}
      <div className={isFullAddress ? '' : 'd-none'}>
        <AddressFullForm form={form} setGeoAddressObj={setGeoAddressObj} />
      </div>

      <AddressAdditionalForm />

      <GoogleMap
        geoLocationCustom={geoLocationCustom}
        addressMarkerCustom={addressMarkerCustom}
        geoAddressObj={geoAddressObj}
      />
    </div>
  );
};

export default CentreLocationForm;
