import React, { useMemo } from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import {
  getServiceAgeGroups,
  getServiceTypes,
  getSessionTypes,
} from 'redux/config/selectors';
import { sortString, sortNumber, sortBoolean } from 'utils/sortUtils';
import { ACTIVE_TYPES, VACANCY_COD } from 'configs/localData';

import TableCustom from 'components/common/TableCustom';
import { formatLabelTable } from 'utils/textUtils';
import { LABEL_TABLE_KEY } from 'configs/localData/constant';
import useFormatFnc from '../useFormat';
import { filterSelect } from '../utils';

const ServicesTable = () => {
  const useFormat = useFormatFnc();

  const serviceTypes = useSelector(getServiceTypes);

  const sessionTypes = useSelector(getSessionTypes);

  const serviceAgeGroups = useSelector(getServiceAgeGroups);

  const appSettings = useSelector((state) => state.config?.data?.AppSetting);

  const data = useSelector((state) => state.centres.currentData?.services);

  const ageGroupLabel = formatLabelTable(LABEL_TABLE_KEY.ageGroup, appSettings);

  const servicesFilter = useMemo(
    () => serviceTypes?.map((item) => ({ value: item.id, text: item.name })),
    [serviceTypes],
  );

  const vacancyFormat = useMemo(
    () =>
      VACANCY_COD.map((item) => ({
        value: item.value,
        text: i18next.t(item.text),
      })),
    [],
  );

  const activesFormat = useMemo(
    () =>
      ACTIVE_TYPES.map((item) => ({
        value: item.value,
        text: i18next.t(item.text),
      })),
    [],
  );

  const ageGroupFormat = useMemo(
    () =>
      serviceAgeGroups?.map((item) => ({
        value: item.id,
        text: item.name,
      })),
    [serviceAgeGroups],
  );

  const columns = useMemo(
    () => [
      {
        title: i18next.t('centres.name'),
        dataIndex: ['serviceType', 'name'],
        sorter: (a, b) => sortString(a, b, ['serviceType', 'name']),
        filters: servicesFilter,
        onFilter: (value, record) =>
          filterSelect(value, record, 'serviceTypeId'),
      },
      {
        title: ageGroupLabel,
        dataIndex: ['serviceAgeGroup', 'name'],
        sorter: (a, b) => sortString(a, b, ['serviceAgeGroup', 'name']),
        filters: ageGroupFormat,
        onFilter: (value, record) =>
          filterSelect(value, record, 'serviceAgeGroupId'),
      },
      {
        title: i18next.t('centres.room'),
        dataIndex: 'roomName',
        sorter: (a, b) => sortString(a, b, 'roomName'),
        render: useFormat.formatRoomName,
      },
      {
        title: i18next.t('centres.capacity'),
        dataIndex: 'capacity',
        render: useFormat.formatCapacity,
        sorter: (a, b) => sortNumber(a, b, 'capacity'),
        width: 120,
      },
      {
        title: formatLabelTable(LABEL_TABLE_KEY.sessionType, appSettings),
        dataIndex: ['sessionType', 'name'],
        sorter: (a, b) => sortString(a, b, ['sessionType', 'name']),
        filters: sessionTypes,
        onFilter: (value, record) =>
          filterSelect(value, record, 'sessionTypeId'),
      },
      {
        title: i18next.t('centres.costPerSession'),
        dataIndex: 'costPerDay',
        render: useFormat.formatCostPerDay,
        sorter: (a, b) => sortNumber(a, b, 'costPerDay'),
        width: 120,
      },
      {
        title: i18next.t('centres.vacancy'),
        dataIndex: 'vacancy_cod',
        render: useFormat.formatVacancy,
        sorter: (a, b) => sortBoolean(a, b, 'vacancy_cod'),
        filters: vacancyFormat,
        onFilter: (value, record) => filterSelect(value, record, 'vacancy_cod'),
        width: 120,
      },
      {
        title: i18next.t('centres.status'),
        dataIndex: 'isActive',
        render: useFormat.formatIsActive,
        sorter: (a, b) => sortBoolean(a, b, 'isActive'),
        filters: activesFormat,
        onFilter: (value, record) => filterSelect(value, record, 'isActive'),
        width: 120,
      },
      {
        title: '',
        dataIndex: 'id',
        width: 100,
        fixed: 'right',
        render: useFormat.formatGroupAction,
      },
    ],
    [
      useFormat,
      servicesFilter,
      vacancyFormat,
      activesFormat,
      ageGroupFormat,
      sessionTypes,
      ageGroupLabel,
      appSettings,
    ],
  );

  return <TableCustom xScroll={1300} data={data} columns={columns} />;
};

export default ServicesTable;
