import styled from 'styled-components';

export const HeaderCommentStyles = styled.div`
  background-color: #f3f8fc;
  border-radius: 20px;
  padding: 16px;
  .title-header {
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    .name-user {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: ${({ theme }) => theme.text.ç};
      margin-right: 20px;
    }
    .ant-rate {
      color: ${({ theme }) => theme.palette.primary};
    }
  }
  .time-comment {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 3px;
  }
  .image-logo {
    width: 20px;
    height: 100%;
  }
`;
