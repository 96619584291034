import { makeCRUDSlice } from 'redux/crudCreator';
import {
  MODEL_NAME,
  providersActions,
  getSummariesProvider,
  getSummariesCentresOfProvider,
} from './actions';

const getSummariesProviderSuccess = (state, { payload }) => {
  state.summaries = payload;
};

const getSummariesCentresOfProviderSuccess = (state, { payload }) => {
  state.centresSummary = payload;
};

const slice = makeCRUDSlice(MODEL_NAME, providersActions, {
  [getSummariesProvider.fulfilled]: getSummariesProviderSuccess,
  [getSummariesCentresOfProvider.fulfilled]:
    getSummariesCentresOfProviderSuccess,
});

export default slice.reducer;
