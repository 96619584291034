import styled from 'styled-components';

const ListStyles = styled.div`
  @media only screen and (max-width: 1200px) and (min-width: 993px) {
    .row-filter-container {
      display: flex;
      flex-direction: column;
      .col-filter-content {
        max-width: 100%;
        width: 100%;
      }
      .col-actions {
        width: 50%;
        max-width: 50%;
      }
    }
  }
`;

export default ListStyles;
