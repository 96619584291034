import styled from 'styled-components';

const FilterCustomWrapper = styled.div`
  margin-bottom: 10px;
  .row-filter {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    .ant-form-item-control-input-content > input,
    .ant-select-selector,
    .ant-picker {
      border: 1px solid transparent;
      :hover,
      :focus {
        border: 1px solid ${({ theme }) => theme.palette.primary};
      }
    }
    .ant-form-item-label {
      display: none;
    }
    .ant-input-number,
    .ant-picker {
      width: 100%;
    }
    .ant-select-selection__rendered {
      height: 32px;
    }
    .ant-form-item-control {
      line-height: 32px;
    }
  }

  .ant-input-prefix .anticon {
    color: ${({ theme }) => theme.text.mainL3};
  }

  .ant-input-affix-wrapper {
    border-color: transparent !important;
    .ant-input {
      border: none;
    }
  }

  .ant-input-affix-wrapper-focused {
    border: 1px solid ${({ theme }) => theme.border.primary}!important;
  }

  &.grey-input-filter {
    .ant-form-item-control-input-content > input,
    .ant-select-selector,
    .ant-picker,
    .ant-input-affix-wrapper,
    .ant-input-affix-wrapper input {
      background: #edf1f6;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #edf1f6 inset !important;
    }
  }

  .clearButton {
    background: ${({ theme }) => theme.background.content};
    color: ${({ theme }) => theme.palette.primary};
    border: 1px solid ${({ theme }) => theme.palette.primary};
    box-sizing: border-box;
  }
  .row-action-bottom {
    display: flex;
    align-items: center;
    button {
      width: 50%;
      margin-bottom: 10px;
      padding: 7px 12px 7px 11px;
    }
    .filterButton {
      margin-right: 16px;
    }
  }

  .col-export-excel {
    text-align: right;
    .ant-btn {
      border-color: transparent !important;
    }
    .anticon {
      font-size: 22px;
    }
  }
`;

export default FilterCustomWrapper;
