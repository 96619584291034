import React from 'react';
import { Tag } from 'antd';
import moment from 'moment';
import i18next from 'i18next';
import {
  APPLICATION_STATUS,
  BILLING_PERIODS,
  ENQUIRY_CENTRE_STATUS,
  ENQUIRIES_STATUS_CONST,
  ENQUIRY_TYPES_CONST,
  ACTIVE_TYPES,
  MARKETING_TYPES,
  NOTIFICATION_STATUS,
  ACTIVITY_PROVIDER_TYPES,
  CENTRE_STATUS_VALUE,
} from 'configs/localData';
import TagCustom from 'components/common/TagCustom';
import KindiRating from 'components/common/KindiRating';
import { MoonIcon, SunIcon } from 'components/common/SVGIcon';
import RestImageField from 'components/RestField/ImageField';
import UserInfo from 'components/RestField/UserInfo';
import { getFeaturedImage } from 'utils/dataUtils';
import {
  formatDateTimeTable,
  formatDueDate,
  formatMoneyWithCurrency,
} from './textUtils';
import { checkSunTime } from './tools';

export const formatCentreName = (data, record) => (
  <UserInfo
    record={{
      avatar: record?.logo?.url || getFeaturedImage(record?.images),
      ...record,
    }}
    prefixLink="centres"
    suffixLink="show/summary"
    roleProp="adminEmail"
    nameProp="name"
    avatarProp="avatar"
  />
);

export const formatSubscription = (data, record) => {
  if (!data || !record) return null;
  return record?.claimStatus === CENTRE_STATUS_VALUE.claimed ||
    record?.centre?.claimStatus === CENTRE_STATUS_VALUE.claimed ? (
    <TagCustom color="#32A4FC" backgroundColor="#E9F4FF" name={data} />
  ) : null;
};

export const formatCentreStatus = (data) => {
  const restItem = ENQUIRY_CENTRE_STATUS?.find((item) => item.value === data);
  return (
    <Tag color={restItem?.color}>
      {restItem?.text ? i18next.t(restItem.text) : data}
    </Tag>
  );
};

export const formatEnquiryType = (data) => {
  const restItem = ENQUIRY_TYPES_CONST[data];
  return (
    <Tag color={restItem?.color}>
      {restItem?.text ? i18next.t(restItem.text) : data}
    </Tag>
  );
};

export const formatOnActiveACECQA = (data) => {
  const restItem = ACTIVE_TYPES?.find((item) => item.value === data);
  return (
    <Tag color={restItem?.color}>
      {restItem?.text ? i18next.t(restItem.text) : data}
    </Tag>
  );
};

export const formatEnquiryStatus = (data) => {
  const restItem = Object.values(ENQUIRIES_STATUS_CONST).find(
    (item) => item.value === data,
  );
  return restItem ? (
    <TagCustom
      backgroundColor={restItem.backgroundColor}
      color={restItem.fillColor}
      text={restItem.text}
      IconCPN={restItem.IconCPN}
    />
  ) : null;
};

export const formatUser = (data) => {
  const fullName =
    data?.firstName && data?.lastName
      ? `${data?.firstName || ''} ${data?.lastName || ''}`
      : i18next.t('error.waitingUpdate');
  return (
    <UserInfo
      record={{ fullName, ...data }}
      roleProp="email"
      prefixLink="users"
      suffixLink="show"
    />
  );
};

export const formatApplicationStatus = (data) => {
  const restItem =
    APPLICATION_STATUS?.find((item) => item.value === data) || {};

  return restItem ? (
    <Tag
      style={{
        color: restItem.color,
        backgroundColor: restItem.backgroundColor,
        border: 'transparent',
        padding: '2px 10px',
      }}
    >
      {i18next.t(restItem.text)}
    </Tag>
  ) : (
    i18next.t('error.waitingUpdate')
  );
};

export const formatApplicationValue = (value) =>
  `${formatMoneyWithCurrency(value)} ${i18next.t('common.perAnnum')}`;

export const formatKindiRating = (data) => <KindiRating value={data} />;

export const formatContent = (data) => (
  <div className="line-clamp-3" dangerouslySetInnerHTML={{ __html: data }} />
);

export const getSunMoonTime = (time) => {
  if (!time) return null;
  return checkSunTime(time) ? (
    <SunIcon className="text-20 ml-6 text-orange" />
  ) : (
    <MoonIcon className="text-20 ml-6 text-blue-secondary" />
  );
};

export const formatNightDayTable = (time) => (
  <div className="ellipsis-t-1">
    {formatDateTimeTable(time)}
    {getSunMoonTime(time)}
  </div>
);

export const formatMarketingType = (data) => {
  const restItem = MARKETING_TYPES?.find((item) => item.value === data);
  return (
    <Tag color={restItem?.color}>
      {restItem?.text ? i18next.t(restItem.text) : data}
    </Tag>
  );
};

export const formatBillingPeriod = (data) => {
  const restItem = BILLING_PERIODS?.find((item) => item.value === data);
  return restItem?.text ? i18next.t(restItem.text) : data;
};

export const formatKindiCareFee = (data, record) => {
  const restText = BILLING_PERIODS.find(
    (item) => item.value === record.billingPeriod,
  )?.title;

  return `${formatMoneyWithCurrency(data)} / ${
    record?.quantity > 1 ? `${record?.quantity} ` : ''
  }${restText ? i18next.t(restText) : record.billingPeriod}`;
};

export const formatNotificationStatus = (data) => {
  const item = NOTIFICATION_STATUS.find((item) => item.value === data);
  if (!item) return data;

  return (
    <TagCustom
      color={item.color}
      backgroundColor={item.backgroundColor}
      text={item.text}
      IconCPN={item.icon}
    />
  );
};

export const formatDueDateField = (value) => {
  if (!value) return null;

  return (
    <div
      className={`${
        moment().isAfter(moment(value), 'date') ? 'error-color' : ''
      } due-date-label`}
    >
      {formatDueDate(value)}
    </div>
  );
};

export const formatNQSRating = (NQSRType) => (
  <RestImageField
    style={{ objectFit: 'contain', height: 40, width: 'auto' }}
    source="image"
    record={NQSRType}
  />
);

export const formatActivityProviderType = (type) => {
  const rest = ACTIVITY_PROVIDER_TYPES.find((item) => item.value === type);

  return rest ? (
    <span style={{ color: rest.color }}>{i18next.t(rest.text)}</span>
  ) : (
    type
  );
};

export const formatUserName = (data, record) => {
  const fullName = record?.user?.username || i18next.t('error.waitingUpdate');
  return (
    <UserInfo
      record={{ fullName, ...record?.user }}
      roleProp="email"
      prefixLink={`${fullName ? 'users' : null}`}
      suffixLink={`${fullName ? 'show' : null}`}
    />
  );
};

export const disabledDate = (current) => {
  const today = new Date();
  return current && current > today;
};

export const formatTagCustom = (data) =>
  data?.name ? (
    <TagCustom
      name={data?.name}
      color={data?.color?.textColor}
      borderColor={data?.color?.textColor}
      backgroundColor={data?.color?.backgroundColor}
      isCheckBorderTag
    />
  ) : (
    ''
  );
