import { createSlice } from '@reduxjs/toolkit';
import {
  getAllCustomFail,
  getAllCustomPending,
  getAllCustomSuccess,
  getSummariesCustomFulfilled,
  INITIAL_STATE_CUSTOM,
  resetResourceDataCustom,
} from 'redux/crudCreatorCustom/utils';
import { getCentresOfCompany, getSummariesCentresOfCompany } from './actions';

export const initialState = {
  ...INITIAL_STATE_CUSTOM,
};

const { actions, reducer } = createSlice({
  name: 'CentresOfCompany',
  initialState,
  reducers: {
    resetResourceFilterCentresOfCompany: resetResourceDataCustom,
  },
  extraReducers: {
    [getCentresOfCompany.pending]: getAllCustomPending,
    [getCentresOfCompany.fulfilled]: getAllCustomSuccess,
    [getCentresOfCompany.rejected]: getAllCustomFail,
    [getSummariesCentresOfCompany.fulfilled]: getSummariesCustomFulfilled,
  },
});

export const { resetResourceFilterCentresOfCompany } = actions;

export default reducer;
