import styled from 'styled-components';

const ArticlesStyles = styled.div``;

export default ArticlesStyles;

export const CategoriesTreeStyles = styled.div`
  .category-tree-content {
    padding: 15px;
    background: ${({ theme }) => theme.background.content};
    border-radius: 8px;
  }
  .ant-tree-node-content-wrapper {
    display: flex;
    align-items: center;
    .ant-tree-title {
      flex: 1;
    }
  }
  .ant-tree-iconEle {
    font-size: 16px;
    margin-right: 5px;
    color: ${({ theme }) => theme.folderTree.colorIcon};
  }

  .ant-tree-switcher {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
  }

  .ant-tree-node-content-wrapper {
    width: calc(100% - 24px);
  }

  .ant-tree-title {
    overflow: hidden;
  }

  .ant-tree .ant-tree-treenode {
    padding: 8px 0px !important;
  }

  .ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
  .ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
    background: ${({ theme }) => `${theme.palette.primary}20`}!important;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher,
  .ant-tree.ant-tree-directory
    .ant-tree-treenode
    .ant-tree-node-content-wrapper.ant-tree-node-selected {
    color: inherit !important;
  }
`;
